import React from 'react';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { accounts, inProgress } = useMsal();
    const history = useHistory();
    React.useEffect(() => {
      let fullPath = window.location.pathname;
      if (window.location.search) {
        fullPath += window.location.search;
      }
      if (accounts.length === 0 && inProgress === 'none') {
        sessionStorage.setItem('postLoginRedirectUrl', fullPath);
        history.push('/');
      }
    }, [inProgress]);

    if (accounts.length === 0) {
        return null; // Return null or a loading component while authentication is in progress
    }

    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired
};

export default ProtectedRoute;
