import Grid from "@mui/material/Grid";
import header from "./AboutDEASContent/header_about_deas.png";
import Button from "@mui/material/Button";
import learn from "./RevitConnectorContent/learn.svg";
import td from "./HomePageContent/td.svg";
import globe from "./RevitConnectorContent/globe.svg";
import pdf from "./RevitConnectorContent/document.svg";
import josha from "./DEASCoreContent/joshavanreij.png";
import newproject from "./Civil3DConnectorContent/c3d_newproject.png";
import arcadiscontrolroom from "./Civil3DConnectorContent/arcadiscontrolroom.png";
import downloadbutton from "./Civil3DConnectorContent/downloadbutton.png";
import visibilytoogle from "./RevitConnectorContent/visibilitytoogle.png";
import otl from "./Civil3DConnectorContent/otl.png";
import available from "./RevitConnectorContent/available.png";
import brett from "./HomePageContent/brettburling.png";
import australian_tools from "./Civil3DConnectorContent/australian_tools.png";
import Divider from "@mui/material/Divider";
import ScrollToAnchor from "./ScrollToAnchor";

export default function Civil3DConnector() {
  ScrollToAnchor();

  return (
    <Grid paddingRight={"20px"}>
      <Grid container spacing={"1px"} item xs={500}>
        <Grid container spacing={"1px"} item xs={12}>
          <Grid item xs={12} style={{ backgroundImage: `url(${header})`, backgroundSize: "cover", height: "180px" }}>
            <Grid paddingTop={"100px"} paddingLeft={"20px"}>
              <p style={{ color: "#e4610f", fontSize: "25px", fontWeight: "500" }}>Guide - Civil 3D Connector</p>
            </Grid>
          </Grid>

          <Grid item xs={5} paddingRight="10px" paddingTop="20px">
            <p className="standard-text" style={{ alignContent: "flex-end", paddingTop: "10px" }}>
              <span style={{ fontWeight: "600" }}>
                Summary<br></br>
              </span>
              The DEAS Civil3D Connector is a Civil3D add-in that provides access to the DEAS Core functionality from
              within Civil3D. Additionally, it provides a number of functions specifically for Civil3D.
            </p>
          </Grid>

          <Grid item xs={3} paddingLeft={"10px"}></Grid>

          <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
          <Grid item xs={0.25} />

          <Grid item xs={3.5} container justifyContent={"left"} paddingTop="20px">
            <Grid item xs={10} paddingBottom="10px" paddingTop="20px">
              <Button
                variant="contained"
                fullWidth
                className="mainhome-button"
                onClick={() => (window.location.href = "#how-do-i-acces-toolkit")}
              >
                <Grid item xs={2} paddingTop={"5px"}>
                  <img src={learn} className="svg" alt="" />
                </Grid>
                <Grid item xs={8} display="flex" direction="row" alignContent="left">
                  How do I acces a toolkit?
                </Grid>
              </Button>
            </Grid>

            <Grid item xs={10} paddingBottom="1px">
              <Button
                variant="contained"
                fullWidth
                className="mainhome-button"
                onClick={() => (window.location.href = "#toolkits")}
              >
                <Grid item xs={2} paddingTop={"5px"}>
                  <img src={td} className="svg" alt="" />
                </Grid>
                <Grid item xs={8} display="flex" direction="row" alignContent="left">
                  Civil 3D Toolkits
                </Grid>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "500",
                color: "#e4610f",
                height: "80px",
                background: "#f0f0f0",
                paddingTop: "25px",
              }}
            >
              Civil 3D Standards Distribution
            </p>
          </Grid>

          <Grid item xs={8}>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              DEAS enables the distribution of the latest Civil 3D standard documents to your colleagues. No more
              searching, asking, or using out-of-date files.
            </p>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              Currently, the following documents are supported:
            </p>
            <ul className="standard-text">
              <li>
                Civil 3D Template files (<span style={{ color: "#e4610f", fontStyle: "italic" }}>.dwt</span> and{" "}
                <span style={{ color: "#e4610f", fontStyle: "italic" }}>.dwg</span>)
              </li>
            </ul>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              In the future support will be added for more file types such as Material textures and keynote files
            </p>

            <h2>How Do I Share Documents?</h2>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              DEAS distributes documents from a central file store on Autodesk Construction Cloud. When documents are
              uploaded into the correct ACC project they are instantly available to all Civil 3D users globally.
            </p>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              DEAS has been set up so that only content managers (BIM leads or Civil 3D Leads) need access to the ACC
              file store. Please request access by contacting{" "}
              <span style={{ color: "#e4610f" }}>
                <a
                  href="mailto:josha.vanreij@arcadis.com"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#e4610f" }}
                >
                  Josha Van Reij
                </a>
              </span>
              .
            </p>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              For DEAS to correctly process the files it is critical that the files are named using the correct{" "}
              <span style={{ color: "#e4610f" }}>
                <a
                  href="https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/Public%20support%20documents/Forms/AllItems.aspx?id=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FRevit%2FStandards%20distribution%2FDEAS%20%2D%20Revit%20standards%20distribution%2Epdf&parent=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FRevit%2FStandards%20distribution"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#e4610f" }}
                >
                  naming convention
                </a>
              </span>{" "}
              and uploaded into the correct team folder within the ACC project.
            </p>

            <h2>How Do I Access The Documents?</h2>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              Civil 3D users can access the latest documents from within Civil 3D via DEAS custom dialogues. (e.g. on
              opening a new project and selecting a template).
            </p>
            {/* eslint-disable-next-line */}
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              <img src={newproject} width={"60%"} alt="" />
              <br></br>
              <span style={{ fontSize: "12px" }}>The DEAS new project dialogue</span>
            </p>
          </Grid>

          {/*right*/}
          <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
          <Grid item xs={0.25} />
          <Grid item xs={3.5}>
            <p style={{ fontSize: "20px", fontWeight: "500", textAlign: "start", flexWrap: "wrap" }}>
              To access the ACC project contact:
            </p>
            {/* eslint-disable-next-line */}
            <Grid container>
              <Grid container>
                <Grid paddingRight={"10px"}>
                  <img src={josha} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                </Grid>
                <Grid>
                  <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                    van Reij, Josha
                  </Grid>
                  <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>Consultant</Grid>
                </Grid>
              </Grid>
            </Grid>
            <p style={{ fontSize: "20px", fontWeight: "500", textAlign: "start", paddingTop: "50px" }}>Useful Links:</p>
            <Grid item xs={10} paddingBottom="20px">
              <Button
                variant="contained"
                fullWidth
                className="mainhome-button"
                onClick={() =>
                  window.open(
                    "https://accounts.autodesk.com/logon?resume=%2Fas%2FqZL5faghCB%2Fresume%2Fas%2Fauthorization.ping&spentity=null#username",
                    "_blank",
                    "noopener"
                  )
                }
              >
                <Grid item xs={2} paddingTop={"5px"}>
                  <img src={globe} className="svg" alt="" />
                </Grid>
                <Grid item xs={8} display="flex" direction="row" alignContent="left">
                  Autodesk Construction Cloud
                </Grid>
              </Button>
            </Grid>

            <Grid item xs={10} paddingBottom="20px">
              <Button
                variant="contained"
                fullWidth
                className="mainhome-button"
                onClick={() =>
                  window.open(
                    "https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/Public%20support%20documents/Forms/AllItems.aspx?id=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FCivil3D%2FDEAS%20%2D%20Civil3D%20standards%20distribution%2Epdf&parent=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FCivil3D",
                    "_blank",
                    "noopener"
                  )
                }
              >
                <Grid item xs={2} paddingTop={"5px"}>
                  <img src={pdf} className="svg" alt="" />
                </Grid>
                <Grid item xs={8} display="flex" direction="row" alignContent="left">
                  Civil3D naming convention
                </Grid>
              </Button>
            </Grid>
          </Grid>

          {/*Step1*/}
          <Grid item xs={12}>
            <Grid id="how-do-i-acces-toolkit" style={{ paddingBottom: "40px" }} />
            <p
              style={{
                fontSize: "24px",
                fontWeight: "500",
                color: "#e4610f",
                height: "80px",
                background: "#f0f0f0",
                paddingTop: "25px",
              }}
            >
              How Do I Access A Toolkit?
            </p>
          </Grid>

          <Grid item xs={8}>
            <Grid item xs={12}>
              <p style={{ alignContent: "flex-end", color: "#e4610f", fontWeight: "600", fontSize: "18px" }}>Step 1</p>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                In Civil 3D go to the <span style={{ color: "#e4610f", fontStyle: "italic" }}>Arcadis DEAS </span>
                tab and select the <span style={{ color: "#e4610f", fontStyle: "italic" }}>Workspaces</span> button.
              </p>
            </Grid>

            <Grid container justifyContent={"center"} paddingTop={"20px"}>
              <img src={arcadiscontrolroom} width={"50%"} alt="" />
            </Grid>

            {/*Step2*/}
            <Grid item xs={12}>
              <p style={{ alignContent: "flex-end", color: "#e4610f", fontWeight: "600", fontSize: "18px" }}>Step 2</p>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                In the <span style={{ color: "#e4610f", fontStyle: "italic" }}>Workspace Manager</span> window, select
                the toolkit you would like to use, then click the{" "}
                <span style={{ color: "#e4610f", fontStyle: "italic" }}>Install </span> button and wait for it to
                download and install.
              </p>
            </Grid>

            <Grid container justifyContent={"center"} paddingTop={"20px"}>
              <img src={downloadbutton} width={"30%"} alt="" />
            </Grid>

            {/*Step3*/}
            <Grid item xs={12}>
              <p style={{ alignContent: "flex-end", color: "#e4610f", fontWeight: "600", fontSize: "18px" }}>Step 3</p>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                When downloading is complete set the toolkit to be visible by clicking the{" "}
                <span style={{ color: "#e4610f", fontStyle: "italic" }}>Show</span>.
              </p>
            </Grid>

            <Grid container justifyContent={"center"} paddingTop={"20px"}>
              <img src={visibilytoogle} width={"20%"} alt="" />
            </Grid>

            {/*Step4*/}
            <Grid item xs={12} paddingBottom={"50px"}>
              <p style={{ alignContent: "flex-end", color: "#e4610f", fontWeight: "600", fontSize: "18px" }}>Step 4</p>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                Close the dialogue, the toolkit should now be visible in the ribbon.
              </p>
              <h2>How do I Add a Toolkit to DEAS?</h2>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                Providing access for developers to add a toolkit to DEAS is still in development. However, if you (or
                your team) have something that you think should be added we would love to hear about it so please get in
                contact.
              </p>
              <h2>A tool is Not Working as Expected</h2>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                For this, you can refer to the explanation of the tool on this SharePoint or contact the toolkit owner.
                Unfortunately, IT ServiceDesk is not yet set up to provide assistance concerning individual tools.
              </p>
            </Grid>
          </Grid>

          <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
          <Grid item xs={0.25} />

          {/*toolkits*/}
          <Grid item xs={12}>
            <Grid id="toolkits" style={{ paddingBottom: "40px" }} />
            <p
              style={{
                fontSize: "24px",
                fontWeight: "500",
                color: "#e4610f",
                height: "80px",
                background: "#f0f0f0",
                paddingTop: "25px",
              }}
            >
              Civil 3D Toolkits
            </p>
            <p className="standard-text" style={{ alignContent: "flex-end" }}>
              A toolkit is a collection of custom-created buttons that are run from within Civil 3D. DEAS provides the
              framework to distribute and update toolkits.
            </p>
            <h2>What Toolkits Are Available?</h2>
          </Grid>

          <hr></hr>
          {/*australian toolkit*/}
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <p style={{ alignContent: "flex-end", color: "#e4610f", fontWeight: "600", fontSize: "18px" }}>
                Australian Toolkit{" "}
              </p>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                Tools for Australian Civil 3D users
              </p>
              <Grid paddingTop={"20px"}>
                <img src={australian_tools} width="100%" alt="" />
              </Grid>
              <Grid paddingTop={"20px"} className="standard-text">
                Available for version of Civil 3D: 2020, 2021, 2022, 2023, 2024, 2025.
              </Grid>
            </Grid>
            <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
            <Grid item xs={0.25} />
            <Grid item xs={3.5}>
              <p style={{ fontSize: "20px", fontWeight: "500", textAlign: "start", flexWrap: "wrap" }}>
                Toolkit Owner:
              </p>
              <Grid container>
                <Grid container>
                  <Grid paddingRight={"10px"}>
                    <img src={brett} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }}>Burling, Brett</Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>
                      Digital Technologies Lead - Computational Design &<br></br> Software Developer
                    </Grid>
                  </Grid>
                  <Grid paddingTop={"20px"}>
                    <img src={available} width="40%" alt="" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent={"center"} paddingTop={"20px"} paddingBottom={"20px"}>
            <Divider color="#d0d0d0" sx={{ height: 1, width: "100%" }} />
          </Grid>

          {/*josha otl*/}
          <Grid item xs={12} container paddingBottom={"30px"}>
            <Grid item xs={8}>
              <p style={{ alignContent: "flex-end", color: "#e4610f", fontWeight: "600", fontSize: "18px" }}>
                OTL Tools
              </p>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                A collection of tools for connecting Civil 3D models to the Arcadis Object Type Library (OTL).
              </p>
              <Grid paddingTop={"20px"}>
                <img src={otl} width="268px" alt="" />
              </Grid>
              <Grid paddingTop={"20px"} className="standard-text">
                Available for version of Civil 3D: 2020, 2021, 2022, 2023, 2024, 2025.
              </Grid>
            </Grid>
            <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
            <Grid item xs={0.25} />
            <Grid item xs={3.5}>
              <p style={{ fontSize: "20px", fontWeight: "500", textAlign: "start", flexWrap: "wrap" }}>
                Toolkit Owner:
              </p>
              <Grid container>
                <Grid container>
                  <Grid paddingRight={"10px"}>
                    <img src={josha} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      van Reij, Josha
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>Consultant</Grid>
                  </Grid>
                  <Grid paddingTop={"20px"}>
                    <img src={available} width="40%" alt="" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>

      <Grid bgcolor={"#e4610f"}>
        <canvas id="myCanvas" height="30px" style={{ border: "1px solid #e4610f" }}></canvas>
      </Grid>
    </Grid>
  );
}
