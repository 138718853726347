/* eslint-disable react/prop-types */
// @ts-ignore: Object is possibly 'null'.
import { useMsal} from "@azure/msal-react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, {useEffect, useRef, useState} from "react";
import uuid from "react-uuid";
import "./App.css";
import BaseUrl from "./Globals/Environment";
import  TokenReusable from "./ReusableToken"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules,formats } from "./QComponents";
import "./QStyle.css";
import { getCurrentDimension } from "./GetWindowSize";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MenuItem } from "@mui/material";
import MessageCards from "./components/Communications/MessageCards";
import dayjs , { Dayjs } from "dayjs";

const software = ["Revit", "Civil3D",];

const versions = ["2020","2021","2022","2023","2024",];

const countries = [
  {short: "Global", long: "Global" },
  {short:"AU",long:"Australia"},
  {short:"BE",long:"Belgium"},
  {short:"BR",long:"Brazil"},
  {short:"CA",long:"Canada"},
  {short:"CL",long:"Chile"},
  {short:"CN",long:"China"},
  {short:"FR",long:"France"},
  {short:"DE",long:"Germany"},
  {short:"HK",long:"Hong Kong"},
  {short:"IN",long:"India"},
  {short:"IE",long:"Ireland"},
  {short:"IT",long:"Italy"},
  {short:"NL",long:"Netherlands"},
  {short:"PH",long:"Philippines"},
  {short:"PL",long:"Poland"},
  {short:"PT",long:"Portugal"},
  {short:"RO",long:"Romania"},
  {short:"ES",long:"Spain"},
  {short:"GB",long:"United Kingdom"},
  {short:"US",long:"United States"},
];

const gba_option = ["Resilience", "Places", "Mobility", "GEC/GSSC",];

 function Communications() {
 


  const [edit, setEdit] = useState(false);
  const [updatemessage, setupdateMessage] = useState<any>(""); 
  const edit_messageId = updatemessage.id;  
  const edit_title = edit_messageId ? JSON.parse(updatemessage.content)["title"]: "";
  const edit_content = edit_messageId ? JSON.parse(updatemessage.content)["content"]: "";
  const edit_application = updatemessage.application;
  const edit_version = updatemessage.applicationVersion;
  const edit_country = updatemessage.countryCode;
  const edit_gba = updatemessage.gba;
  const edit_startdate = new Date(updatemessage.startDate);
  const edit_enddate = new Date(updatemessage.endDate);
  const edit_uuid = updatemessage.redirectUrl;
  const quillRef = useRef<ReactQuill>(null);

  function getlongname () {
    const shortcountry = edit_country.split(',');
    let longcountry : Array<string>=[];
    shortcountry.forEach((item:any)=>{
      longcountry.push(
        countries.filter((country)=> {
          return country.short === item;
        }
      )[0]["long"] );
    })
    return longcountry.join(',');
  }
  
  const getWidth = () => {
    if (quillRef.current) {
      const width = quillRef.current.getEditingArea().clientWidth;
      return width;
    }
    return undefined;
  };

  const [editstartdate, setEditStartDate] = useState<Dayjs | null>(null);
  const [editenddate, setEditEndDate] = useState<Dayjs | null>(null);

  useEffect(()=> {
    if (edit) {

    setTitle(edit_title);
    setSelectedSoftware(edit_application);
    getWidth();
    setContent(edit_content);
    setTo(getlongname().split(','));
    setGba(edit_gba.split(','));    
    setCheckboxes(edit_version.split(',')); 

      }
    }
  ,[updatemessage]);

  useEffect(() => {
    // Call getWidth whenever the component is mounted or resized
    getWidth();
    window.addEventListener('resize', getWidth);
    return () => {
      // Cleanup the event listener when the component is unmounted
      window.removeEventListener('resize', getWidth);
    };
  }, []);

  //prevent Invalid StartDate
  function existstartdate() {
    if (editstartdate) {
      return editstartdate?.format('YYYY-MM-DD');  
    }
    else { 
    return dayjs(new Date(edit_startdate)).format('YYYY-MM-DD');
    }
  }

  //prevent Invalid EndDate
  function existenddate() {
    if (editenddate) {
      return editenddate?.format('YYYY-MM-DD');  
    }
    else {
      return dayjs(new Date(edit_enddate)).format('YYYY-MM-DD');
    }
  }
  
  const [to, setTo] = useState<string[]>([]);
  const [selectedSoftware, setSelectedSoftware] = useState<string>("");
  const { accounts } = useMsal();
  const [render, setRender] = useState<any>(false);
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");   
  const [startDate, setStartDate]=useState<Date | null>(null);
  const [endDate, setEndDate]=useState<Date | null>(null); 
  const [gba, setGba] = useState<string[]>([]); 
  const [messageID, setMessageId] = useState();
  const [update, setUpdate] = useState<any>(false); 

    function incr_date(date_str) {
      if (date_str) {
    const parts = date_str.split("-");
    const dt = new Date(
      parseInt(parts[0], 10), // year
      parseInt(parts[1], 10) - 1, // month (starts with 0)
      parseInt(parts[2], 10) // date
    );
    dt.setDate(dt.getDate() + 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
      parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
      parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  } } 

  const handleChange = (event: any) => {    
    const {
      target: { value },
    } = event;    
    switch (event.target.name) {
      case "to": 
        setTo(value);        
        break;

      case "gba": 
        setGba(value);        
        break;

      case "content":
        setContent(value);
        break;

      case "title":
        setTitle(value);
        break;

      case "software":
        setSelectedSoftware(value);        
        break;
    }
  }; 
  
  //getwindows dimensions Main Menu
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  useEffect(() => {      
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension); 
     return(() => {
        window.removeEventListener('resize' , updateDimension)          
    })     
    
}, [screenSize]);
const offset=150;
const dHeight=screenSize.height-offset+"px";
const qHeight=screenSize.height-offset-160+"px";

  const [checkboxes, setCheckboxes] = useState<string[]>([]);
  const [ischeckAll, setIsCheckAll] = useState<boolean>(true);  
  const handleSelectAll = (all_event) => {
    if (all_event.target.checked) {
      // If "Select All" checkbox is checked, set all checkboxes as selected
      const allCheckboxValues = versions; // Replace with your checkbox values      
      setCheckboxes(allCheckboxValues);
      setIsCheckAll(true);
    } else {
      // If "Select All" checkbox is unchecked, clear all selections
      setCheckboxes([]);
      setIsCheckAll(false);
    }
  };

    const handleCheckboxChange = (ch_event) => {
    const { value, checked } = ch_event.target;
    if (checked) {
      // Add checkbox value to the selected checkboxes array
      setCheckboxes((prevCheckboxes) => [...prevCheckboxes, value]);
    } else {
      // Remove checkbox value from the selected checkboxes array
      setCheckboxes((prevCheckboxes) => prevCheckboxes.filter((checkbox) => checkbox !== value));
    }     
  };   

  let SelectedVersion: Array<string>=[]; 
  const chk_selectedVersion: Array<string>=[""]; 
  if (ischeckAll) {
    SelectedVersion=versions;
  }   
  else {
  chk_selectedVersion.forEach((item) => {
    SelectedVersion.push(
      versions.filter((vers)=> {
        return vers===item;
      })[0]
    );           
  });
 }

  let applicationName = selectedSoftware;

  function sendMessage() {   
    const image = document.getElementById(
      "avatar-image"
    ) as HTMLImageElement | null;

    let country2: Array<string> = [];
    to.forEach((item) => {
      country2.push(
        countries.filter((country) => {
          return country.long === item;
        })[0]["short"]
      );
    }); 

    let processedContent = content.replace(/(?:\r\n|\r|\n)/g, "<br>");
    const width = getWidth();
    if (width) {
      processedContent = `<div class="message-view ql-snow ql-editor" style="width: ${width}px">${processedContent}</div>`;
    }
    let contentJson = {
      content: processedContent,
      title: title,
    };   

        const myToken=TokenReusable();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + myToken);
        myHeaders.append("Content-Type", "application/json");

        if (edit) {
          const raw = JSON.stringify({
            Author: accounts[0].name?.replace(",", ""),            
            Content: JSON.stringify(contentJson),
            countrycode: country2.join(),
            startdate : existstartdate(), 
            enddate: existenddate(),
            Image: image!.src, 
            applicationVersion: checkboxes.join(),
            application: applicationName,
            gba: gba.join(","),
          });
          
          const requestOptions = {
            method: "PATCH" ,
            headers: myHeaders,
            body: raw,
          };
          fetch(          
            BaseUrl() + "/api/v2/messages/"+edit_uuid, 
            requestOptions
          )
            .then((response) => response.json())          
            .then((result) => {
              console.log(result);
              setUpdate(!update);
              alert("The message has been update!");
              // eslint-disable-next-line react/no-render-return-value            
            })
            .catch((error) => console.log("error", error));
        }
          else {
            const raw = JSON.stringify({
            Author: accounts[0].name?.replace(",", ""),
            Content: JSON.stringify(contentJson),
            countrycode: country2.join(),
            RedirectUrl: uuid(), 
            Image: image!.src,
            startdate:incr_date(startDate?.toISOString()),
            enddate: incr_date(endDate?.toISOString()),
            applicationVersion: checkboxes.join(),
            application: applicationName,
            gba: gba.join(","),
          });           
          const requestOptions = {
          method: "POST" ,
          headers: myHeaders,
          body: raw,
        };
        
        fetch(          
          BaseUrl() + "/api/v2/messages" ,           
          requestOptions
        )
          .then((response) => response.json())          
          .then((result) => {
            console.log(result);
            setRender(!render);
            alert("The message has been sent!");
            // eslint-disable-next-line react/no-render-return-value            
          })
          .catch((error) => console.log("error", error));
        }
  } 

  return (
    <div style={{tableLayout:'auto'}}> 
        
      {/* Grid left menu */}
      <Grid container sx={{ margin: "0!important" }} spacing={2}>
        <Grid
          item
          xs={2.5}
          sx={{
            borderRight: "2px solid #EBF2FA",
          }}
        >
          <Grid container spacing={2}>
             <MessageCards 
             render={render} 
             setrender={setRender}
             update={update}
             setUpdate={setUpdate} 
             messageID={messageID} 
             setMessageId={setMessageId}
             edit = {edit}
             setEdit = {setEdit}
             updatemessage={updatemessage}
             setupdateMessage={setupdateMessage}                         
             />            
          </Grid>

        </Grid>

        {/* Main Grid */}
        <Grid item xs={9} 
              height= {dHeight}                                      
              minWidth='750px' >

          <Grid container spacing={'5px'} height={dHeight}>
            
            <Grid container item xs={12} spacing={'5px'} marginLeft={'10px'} marginRight={'15px'} > 
            {/* Grid contries */}
            <Grid item xs={2} paddingTop={'10px'} >
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label-country" color="warning">
                  To
                </InputLabel>                
                <Select 
                  sx={{
                    borderRadius: "4px",
                  }}
                  labelId="demo-multiple-checkbox-label-country"
                  id="demo-multiple-checkbox-country"
                  name="to"
                  multiple
                  size="small"
                  value={to}                  
                  onChange={handleChange}                                 
                  input={<OutlinedInput label="To" />}
                  renderValue={(selected) => selected.join(", ")}                                
                >
                  {countries.map((name) => (
                    <MenuItem key={name.long} value={name.long}>
                      <Checkbox checked={to.indexOf(name.long) > -1}
                      style = {{color: '#E4610f'}} />
                      <ListItemText primary={name.long} />
                    </MenuItem>
                  ))}
                </Select>                
              </FormControl>
            </Grid>

            {/*Grid GBA*/}
            <Grid item xs={2} paddingTop={'10px'} >
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label-country" color="warning">
                  GBA
                </InputLabel>                
                <Select 
                  sx={{
                    borderRadius: "4px",
                  }}
                  labelId="demo-multiple-checkbox-label-gba"
                  id="demo-multiple-checkbox-gba"
                  name="gba"
                  multiple
                  size="small"
                  value={gba}                  
                  onChange={handleChange}                                 
                  input={<OutlinedInput label="GBA" />}
                  renderValue={(selected) => selected.join(", ")}                                
                >
                  {gba_option.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox value = {name} checked={gba.indexOf(name) > -1}
                      style = {{color: '#E4610f'}} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>                
              </FormControl>
            </Grid>

            {/* Grid Calendar */} 
            {/* Update Start Date */}
            {edit && <Grid item xs={2} style={{cursor:'pointer' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>                
              <DatePicker slotProps={{ textField: { size: 'small', color: 'warning' }} }
                          label='Start Date'                           
                          value={dayjs(new Date(edit_startdate))}  
                          disablePast={!edit}                          
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {setEditStartDate(newValue) }}                           
              />
               </LocalizationProvider></Grid>}

              {/* Send Start Date */}
              {!edit && <Grid item xs={2} style={{cursor:'pointer' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker slotProps={{ textField: { size: 'small', color: 'warning' }} }
                          label='Start Date' 
                          value={startDate}     
                          disablePast={!edit}
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {setStartDate(newValue);} }                          
              />
              </LocalizationProvider>
            </Grid> }

            {/* Update End Date */}
            {edit && <Grid item xs={2} style={{cursor:'pointer' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker slotProps={{ textField: { size: 'small', color: 'warning' }} }
                          label='End Date'                          
                          value={dayjs(new Date(edit_enddate))} 
                          disablePast={!edit}
                          format="DD/MM/YYYY"
                          minDate={editstartdate}                          
                          onChange={(newValue) => {setEditEndDate(newValue)}}
              />   
              </LocalizationProvider>
            </Grid>}

            {/* Send End Date */}
            {!edit && <Grid item xs={2} style={{cursor:'pointer' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker slotProps={{ textField: { size: 'small', color: 'warning' }} }
                          label='End Date'                          
                          value={endDate}
                          disablePast={true}
                          format="DD/MM/YYYY"
                          disabled={!startDate} 
                          minDate={startDate} 
                          onChange={(newValue) => {setEndDate(newValue);}}
              />   
              </LocalizationProvider>
            </Grid>} 

            {/* Grid Software */}
            <Grid item xs={2}  >
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label-sw" color="warning">
                  Software 
                </InputLabel>
                <Select 
                  sx={{
                    borderRadius: "4px"
                  }}
                  labelId="demo-multiple-checkbox-label-sw"
                  id="demo-multiple-checkbox-sw"
                  name="software"                  
                  size="small"
                  value={selectedSoftware}                  
                  onChange={handleChange}
                  input={<OutlinedInput label="Software" />}
                  renderValue={(selected) => selected}                 
                >
                  {software.map((softname) => (
                    <MenuItem key={softname} value={softname}>
                      <ListItemText primary={softname} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Grid Version */}
            <Grid item xs={2}  >
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label-vers" color="warning">
                  Version
                </InputLabel>
                <Select
                  sx={{
                    borderRadius: "4px",
                  }}
                  labelId="demo-multiple-checkbox-label-vers"
                  id="demo-multiple-checkbox-vers"
                  name="version"
                  multiple
                  value={checkboxes}
                  size="small" 
                  input={<OutlinedInput label="Version" />}
                  renderValue={(selected) => selected.join(", ")}                                 
                > 
                    <MenuItem key={"all"}>
                      <Checkbox onClick={handleSelectAll} 
                      style = {{color: '#E4610f'}}  />
                      Select All
                    </MenuItem>        
                  {versions.map((name) => (                     
                    <MenuItem key={name} value={name}>
                       <Checkbox  value={name} onChange={handleCheckboxChange}  
                       checked={(chk_selectedVersion.indexOf(name) > -1) || (checkboxes.includes(name))}
                       style = {{color: '#E4610f'}}/>                                         
                      <ListItemText primary={name} />
                    </MenuItem>                    
                  ))}                                 
                </Select>
              </FormControl>
            </Grid> 
            </Grid>         

            {/* Grid Title */}
            <Grid item xs={12} marginLeft={'15px'} marginRight={'15px'} >
              <TextField
                placeholder="Type title here..."
                size="small" 
                value={ title }                
                onChange={handleChange}                
                name="title"
                sx={{ width: "100%", borderRadius:"4px"}}                
              />
              
            </Grid>
               
            {/* Grid Rich Text Editor */}
            <Grid item xs={12} height={qHeight} >

                {/*Quill Start*/}
                <ReactQuill ref={quillRef}
                            className="message-editor"
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={content}
                            onChange={val=>{setContent(val)}}
                            placeholder="Type message here..." 
                            />
                {/*Quill End*/}
              </Grid>

              {/*Grid Button Send*/}
              <Grid xs={12} 
              display='flex'                       
              justifyContent='flex-end'
              flexDirection='row'
              height='28px' marginRight={'15px'}
              >               
                 <Button variant="contained"
                 className="primary-button"                 
                 onClick={() => {
                  sendMessage();
                  if (edit) {
                    setEdit(!edit); 
                    setEditStartDate(null);
                    setEditEndDate(null);
                  }
                    setGba([]);
                    setTo([]);
                    setSelectedSoftware("");
                    setCheckboxes([]);
                    setTitle("");
                    setContent("");
                    setStartDate(null);
                    setEndDate(null);
                  
                }}
                 >{edit? "UPDATE" : "SEND"}</Button>            
               </Grid>
            </Grid >           
        </Grid>
      </Grid>
    </div>    
  );
}
 
export default Communications;