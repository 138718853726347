const modules={
  
    toolbar: [
      [{header: [1,2,3,4,5,6, false]}],
      [{font: []}],
      [{size: []}],
      ["bold", "italic", "underline", "strike", "blockquote" ],
      [{ color: ["#000000", "#e4610f","red","yellow","green","blue","violet"] }],
      [{ background: ["#000000", "#e4610f","red","yellow","green","blue","violet"] }], 
      [{list:"ordered"}, {list:"bullet"},{indent:"-1"},{indent:"+1"}],
      ["link", "image"],           
      ["clean"]
    ], 
      
    
  }

  const formats = [
    'font','size',
    'bold','italic','underline','strike',
    'color','background',
    'script',
    'header','blockquote','code-block',
    'indent','list',
    'direction','align',
    'link','image','video','formula',
  ]

  export {modules,formats}