
function TokenReusable() {  
  
  if (sessionStorage.length>0){
  for (let i=0; i<sessionStorage.length;i++) {  
    if ( sessionStorage.key(i)!=null && sessionStorage.key(i)!.includes("deas_access_to_api")) 
    {    
    const apitokenkey =  sessionStorage.key(i) as string;
    const mytokenstring=sessionStorage.getItem(apitokenkey) as string;
    return JSON.parse(mytokenstring).secret;}}
  }
    else return ("empty session" as string);
}

export default TokenReusable;
