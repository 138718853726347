import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: 'ccc0414d-73c7-44d1-a3f6-947e591fed42', //This is DEAS dev Azure Microsoft AD
    authority:
      'https://login.microsoftonline.com/7f90057d-3ea0-46fe-b07c-e0568627081b',
    redirectUri: window.location.origin,
  },

  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true, // set to true for IE 11
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};

export const scopes: string[] = [
  'https://analysis.windows.net/powerbi/api/Report.Read.All',
  'User.Read',
];

// Client Id (Application Id) of the AAD app.
export const clientId: string = 'ccc0414d-73c7-44d1-a3f6-947e591fed42';

// Id of the workspace where the report is hosted
export const workspaceId: string = 'a1f267f3-a164-4997-b811-1081ea7c96d2';

// Id of the report to be embedded
export const reportId: string = '22da4b66-abcf-43ec-a14d-11deb46b8608';
