import deascore from './GuidesImages/deascore.svg';
import revitconnector from './GuidesImages/revitconnector.png';
import civil3dconnector from './GuidesImages/civil3dconnector.png';
import tooldeveloper from './GuidesImages/tooldeveloper.svg';
import React from 'react';
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {withRouter} from 'react-router-dom';
import '../App.css';

const Guides=(props) => {

    const history = {props}.props.history;    
    
    return (        
         
    <Grid sx={{flexWrap: "wrap",
                display: "flex",
                justifyContent: "left",
                gap: "50px",                
                width: "80vw"}} paddingTop={'50px'} paddingLeft={'0px'}>
       {/*  Grid DEASCore */}
        <Grid item xs={4} onClick={() => history.push('/Guides/DEASCore') }>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                     
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'}>
            <img src={deascore} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{ fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>DEAS Core</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

        {/*  Grid RevitConnector */}
        <Grid item xs={4} onClick={() => history.push('/Guides/RevitConnector')}>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none",    
                        
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingTop={'40px'} height={'150px'} paddingLeft={'25px'}>
            <img src={revitconnector} width={'150px'} alt=""/>
            </Grid>
            <Grid bgcolor={"#f0f0f0"} height={'50px'}
                    className="standard-text" style={{ fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Revit Connector</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

        {/*  Grid Civil3DConnector */}
        <Grid item xs={4} onClick={() => history.push('/Guides/Civil3DConnector')}>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingTop={'40px'} height={'150px'} paddingLeft={'25px'}>
            <img src={civil3dconnector} width={'150px'} alt=""/>
            </Grid>
            <Grid bgcolor={"#f0f0f0"} height={'50px'}
                  className="standard-text"
                  style={{ fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Civil 3D Connector</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

        {/*  Grid Tool Developer */}
        <Grid item xs={4} onClick={() => history.push('/Guides/ToolDeveloper')}>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingTop={'40px'} height={'150px'} paddingLeft={'60px'}>
            <img src={tooldeveloper} width={'60%'} alt="" className='svg_orange'/>
            </Grid>
            <Grid bgcolor={"#f0f0f0"} height={'50px'}
                  className="standard-text"
                  style={{ fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Tool Developer</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

    </Grid> 
    
    )
}

export default withRouter(Guides);