const data = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Costa Rica",
        sov_a3: "CRI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Costa Rica",
        adm0_a3: "CRI",
        geou_dif: 0,
        geounit: "Costa Rica",
        gu_a3: "CRI",
        su_dif: 0,
        subunit: "Costa Rica",
        su_a3: "CRI",
        brk_diff: 0,
        name: "Costa Rica",
        name_long: "Costa Rica",
        brk_a3: "CRI",
        brk_name: "Costa Rica",
        brk_group: null,
        abbrev: "C.R.",
        postal: "CR",
        formal_en: "Republic of Costa Rica",
        formal_fr: null,
        name_ciawf: "Costa Rica",
        note_adm0: null,
        note_brk: null,
        name_sort: "Costa Rica",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 5047561,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 61801,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "CS",
        iso_a2: "CR",
        iso_a2_eh: "CR",
        iso_a3: "CRI",
        iso_a3_eh: "CRI",
        iso_n3: "188",
        iso_n3_eh: "188",
        un_a3: "188",
        wb_a2: "CR",
        wb_a3: "CRI",
        woe_id: 23424791,
        woe_id_eh: 23424791,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CRI",
        adm0_diff: null,
        adm0_tlc: "CRI",
        adm0_a3_us: "CRI",
        adm0_a3_fr: "CRI",
        adm0_a3_ru: "CRI",
        adm0_a3_es: "CRI",
        adm0_a3_cn: "CRI",
        adm0_a3_tw: "CRI",
        adm0_a3_in: "CRI",
        adm0_a3_np: "CRI",
        adm0_a3_pk: "CRI",
        adm0_a3_de: "CRI",
        adm0_a3_gb: "CRI",
        adm0_a3_br: "CRI",
        adm0_a3_il: "CRI",
        adm0_a3_ps: "CRI",
        adm0_a3_sa: "CRI",
        adm0_a3_eg: "CRI",
        adm0_a3_ma: "CRI",
        adm0_a3_pt: "CRI",
        adm0_a3_ar: "CRI",
        adm0_a3_jp: "CRI",
        adm0_a3_ko: "CRI",
        adm0_a3_vn: "CRI",
        adm0_a3_tr: "CRI",
        adm0_a3_id: "CRI",
        adm0_a3_pl: "CRI",
        adm0_a3_gr: "CRI",
        adm0_a3_it: "CRI",
        adm0_a3_nl: "CRI",
        adm0_a3_se: "CRI",
        adm0_a3_bd: "CRI",
        adm0_a3_ua: "CRI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: -84.077922,
        label_y: 10.0651,
        ne_id: 1159320525,
        wikidataid: "Q800",
        name_ar: "كوستاريكا",
        name_bn: "কোস্টা রিকা",
        name_de: "Costa Rica",
        name_en: "Costa Rica",
        name_es: "Costa Rica",
        name_fa: "کاستاریکا",
        name_fr: "Costa Rica",
        name_el: "Κόστα Ρίκα",
        name_he: "קוסטה ריקה",
        name_hi: "कोस्टा रीका",
        name_hu: "Costa Rica",
        name_id: "Kosta Rika",
        name_it: "Costa Rica",
        name_ja: "コスタリカ",
        name_ko: "코스타리카",
        name_nl: "Costa Rica",
        name_pl: "Kostaryka",
        name_pt: "Costa Rica",
        name_ru: "Коста-Рика",
        name_sv: "Costa Rica",
        name_tr: "Kosta Rika",
        name_uk: "Коста-Рика",
        name_ur: "کوسٹاریکا",
        name_vi: "Costa Rica",
        name_zh: "哥斯达黎加",
        name_zht: "哥斯大黎加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CRI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.54619625520348, 9.566134751824677],
            [-82.93289099804358, 9.476812038608173],
            [-82.92715491405916, 9.074330145702916],
            [-82.71918311230053, 8.925708726431495],
            [-82.86865719270477, 8.807266343618522],
            [-82.82977067740516, 8.62629547773237],
            [-82.91317643912421, 8.42351715741907],
            [-82.96578304719736, 8.225027980985985],
            [-83.50843726269431, 8.446926581247283],
            [-83.71147396516908, 8.656836249216866],
            [-83.59631303580665, 8.830443223501419],
            [-83.63264156770784, 9.051385809765321],
            [-83.90988562695374, 9.29080272057358],
            [-84.30340165885636, 9.487354030795714],
            [-84.64764421256866, 9.61553742109571],
            [-84.71335079622777, 9.908051866083852],
            [-84.97566036654133, 10.086723130733006],
            [-84.91137488477024, 9.795991522658923],
            [-85.11092342806532, 9.55703969974131],
            [-85.33948828809227, 9.83454214114866],
            [-85.66078650586698, 9.933347479690724],
            [-85.79744483106285, 10.134885565629034],
            [-85.79170874707843, 10.439337266476613],
            [-85.65931372754667, 10.75433095951172],
            [-85.94172543002176, 10.895278428587801],
            [-85.7125404528073, 11.088444932494824],
            [-85.5618519762442, 11.217119248901597],
            [-84.90300330273895, 10.952303371621896],
            [-84.67306901725627, 11.082657172078143],
            [-84.35593075228104, 10.999225572142905],
            [-84.19017859570485, 10.793450018756674],
            [-83.89505449088595, 10.726839097532446],
            [-83.65561174186158, 10.938764146361422],
            [-83.40231970898296, 10.395438137244653],
            [-83.01567664257517, 9.992982082555557],
            [-82.54619625520348, 9.566134751824677],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Nicaragua",
        sov_a3: "NIC",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nicaragua",
        adm0_a3: "NIC",
        geou_dif: 0,
        geounit: "Nicaragua",
        gu_a3: "NIC",
        su_dif: 0,
        subunit: "Nicaragua",
        su_a3: "NIC",
        brk_diff: 0,
        name: "Nicaragua",
        name_long: "Nicaragua",
        brk_a3: "NIC",
        brk_name: "Nicaragua",
        brk_group: null,
        abbrev: "Nic.",
        postal: "NI",
        formal_en: "Republic of Nicaragua",
        formal_fr: null,
        name_ciawf: "Nicaragua",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nicaragua",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 6545502,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 12520,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "NU",
        iso_a2: "NI",
        iso_a2_eh: "NI",
        iso_a3: "NIC",
        iso_a3_eh: "NIC",
        iso_n3: "558",
        iso_n3_eh: "558",
        un_a3: "558",
        wb_a2: "NI",
        wb_a3: "NIC",
        woe_id: 23424915,
        woe_id_eh: 23424915,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NIC",
        adm0_diff: null,
        adm0_tlc: "NIC",
        adm0_a3_us: "NIC",
        adm0_a3_fr: "NIC",
        adm0_a3_ru: "NIC",
        adm0_a3_es: "NIC",
        adm0_a3_cn: "NIC",
        adm0_a3_tw: "NIC",
        adm0_a3_in: "NIC",
        adm0_a3_np: "NIC",
        adm0_a3_pk: "NIC",
        adm0_a3_de: "NIC",
        adm0_a3_gb: "NIC",
        adm0_a3_br: "NIC",
        adm0_a3_il: "NIC",
        adm0_a3_ps: "NIC",
        adm0_a3_sa: "NIC",
        adm0_a3_eg: "NIC",
        adm0_a3_ma: "NIC",
        adm0_a3_pt: "NIC",
        adm0_a3_ar: "NIC",
        adm0_a3_jp: "NIC",
        adm0_a3_ko: "NIC",
        adm0_a3_vn: "NIC",
        adm0_a3_tr: "NIC",
        adm0_a3_id: "NIC",
        adm0_a3_pl: "NIC",
        adm0_a3_gr: "NIC",
        adm0_a3_it: "NIC",
        adm0_a3_nl: "NIC",
        adm0_a3_se: "NIC",
        adm0_a3_bd: "NIC",
        adm0_a3_ua: "NIC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -85.069347,
        label_y: 12.670697,
        ne_id: 1159321091,
        wikidataid: "Q811",
        name_ar: "نيكاراغوا",
        name_bn: "নিকারাগুয়া",
        name_de: "Nicaragua",
        name_en: "Nicaragua",
        name_es: "Nicaragua",
        name_fa: "نیکاراگوئه",
        name_fr: "Nicaragua",
        name_el: "Νικαράγουα",
        name_he: "ניקרגואה",
        name_hi: "निकारागुआ",
        name_hu: "Nicaragua",
        name_id: "Nikaragua",
        name_it: "Nicaragua",
        name_ja: "ニカラグア",
        name_ko: "니카라과",
        name_nl: "Nicaragua",
        name_pl: "Nikaragua",
        name_pt: "Nicarágua",
        name_ru: "Никарагуа",
        name_sv: "Nicaragua",
        name_tr: "Nikaragua",
        name_uk: "Нікарагуа",
        name_ur: "نکاراگوا",
        name_vi: "Nicaragua",
        name_zh: "尼加拉瓜",
        name_zht: "尼加拉瓜",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NIC.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.65561174186158, 10.938764146361422],
            [-83.89505449088595, 10.726839097532446],
            [-84.19017859570485, 10.793450018756674],
            [-84.35593075228104, 10.999225572142905],
            [-84.67306901725627, 11.082657172078143],
            [-84.90300330273895, 10.952303371621896],
            [-85.5618519762442, 11.217119248901597],
            [-85.7125404528073, 11.088444932494824],
            [-86.05848832878526, 11.403438625529944],
            [-86.52584998243296, 11.806876532432597],
            [-86.74599158399633, 12.143961900272487],
            [-87.16751624220116, 12.458257961471658],
            [-87.66849341505471, 12.909909979702633],
            [-87.55746660027562, 13.064551703336065],
            [-87.39238623731923, 12.914018256069838],
            [-87.31665442579549, 12.984685777228975],
            [-87.00576900912758, 13.025794379117158],
            [-86.88055701368438, 13.254204209847217],
            [-86.7338217841916, 13.263092556201443],
            [-86.75508663607971, 13.754845485890913],
            [-86.52070817741992, 13.778487453664468],
            [-86.31214209668993, 13.77135610600817],
            [-86.09626380079061, 14.038187364147234],
            [-85.80129472526859, 13.83605499923759],
            [-85.69866533073696, 13.960078436738002],
            [-85.51441301140028, 14.079011745657908],
            [-85.16536454948482, 14.354369615125051],
            [-85.14875057650296, 14.560196844943617],
            [-85.05278744173694, 14.551541042534723],
            [-84.9245006985724, 14.79049286545235],
            [-84.82003679069436, 14.81958669683267],
            [-84.64958207877964, 14.666805324761867],
            [-84.4493359036486, 14.621614284722511],
            [-84.22834164095241, 14.74876414637663],
            [-83.97572140169359, 14.749435939996488],
            [-83.62858496777292, 14.880073960830302],
            [-83.48998877636612, 15.016267198135537],
            [-83.14721900097413, 14.99582916916411],
            [-83.23323442252394, 14.899866034398102],
            [-83.2841615465476, 14.6766238468972],
            [-83.18212643098728, 14.31070302983845],
            [-83.41249996614445, 13.970077826386557],
            [-83.51983191601468, 13.567699286345883],
            [-83.55220720084554, 13.127054348193086],
            [-83.49851538769427, 12.869292303921227],
            [-83.47332312695198, 12.419087225794428],
            [-83.62610449902292, 12.320850328007566],
            [-83.71961300325506, 11.893124497927728],
            [-83.65085751009072, 11.62903209070012],
            [-83.8554703437504, 11.373311265503787],
            [-83.80893571647155, 11.103043524617275],
            [-83.65561174186158, 10.938764146361422],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Haiti",
        sov_a3: "HTI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Haiti",
        adm0_a3: "HTI",
        geou_dif: 0,
        geounit: "Haiti",
        gu_a3: "HTI",
        su_dif: 0,
        subunit: "Haiti",
        su_a3: "HTI",
        brk_diff: 0,
        name: "Haiti",
        name_long: "Haiti",
        brk_a3: "HTI",
        brk_name: "Haiti",
        brk_group: null,
        abbrev: "Haiti",
        postal: "HT",
        formal_en: "Republic of Haiti",
        formal_fr: null,
        name_ciawf: "Haiti",
        note_adm0: null,
        note_brk: null,
        name_sort: "Haiti",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 11263077,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 14332,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "HA",
        iso_a2: "HT",
        iso_a2_eh: "HT",
        iso_a3: "HTI",
        iso_a3_eh: "HTI",
        iso_n3: "332",
        iso_n3_eh: "332",
        un_a3: "332",
        wb_a2: "HT",
        wb_a3: "HTI",
        woe_id: 23424839,
        woe_id_eh: 23424839,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HTI",
        adm0_diff: null,
        adm0_tlc: "HTI",
        adm0_a3_us: "HTI",
        adm0_a3_fr: "HTI",
        adm0_a3_ru: "HTI",
        adm0_a3_es: "HTI",
        adm0_a3_cn: "HTI",
        adm0_a3_tw: "HTI",
        adm0_a3_in: "HTI",
        adm0_a3_np: "HTI",
        adm0_a3_pk: "HTI",
        adm0_a3_de: "HTI",
        adm0_a3_gb: "HTI",
        adm0_a3_br: "HTI",
        adm0_a3_il: "HTI",
        adm0_a3_ps: "HTI",
        adm0_a3_sa: "HTI",
        adm0_a3_eg: "HTI",
        adm0_a3_ma: "HTI",
        adm0_a3_pt: "HTI",
        adm0_a3_ar: "HTI",
        adm0_a3_jp: "HTI",
        adm0_a3_ko: "HTI",
        adm0_a3_vn: "HTI",
        adm0_a3_tr: "HTI",
        adm0_a3_id: "HTI",
        adm0_a3_pl: "HTI",
        adm0_a3_gr: "HTI",
        adm0_a3_it: "HTI",
        adm0_a3_nl: "HTI",
        adm0_a3_se: "HTI",
        adm0_a3_bd: "HTI",
        adm0_a3_ua: "HTI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -72.224051,
        label_y: 19.263784,
        ne_id: 1159320839,
        wikidataid: "Q790",
        name_ar: "هايتي",
        name_bn: "হাইতি",
        name_de: "Haiti",
        name_en: "Haiti",
        name_es: "Haití",
        name_fa: "هائیتی",
        name_fr: "Haïti",
        name_el: "Αϊτή",
        name_he: "האיטי",
        name_hi: "हैती",
        name_hu: "Haiti",
        name_id: "Haiti",
        name_it: "Haiti",
        name_ja: "ハイチ",
        name_ko: "아이티",
        name_nl: "Haïti",
        name_pl: "Haiti",
        name_pt: "Haiti",
        name_ru: "Республика Гаити",
        name_sv: "Haiti",
        name_tr: "Haiti",
        name_uk: "Гаїті",
        name_ur: "ہیٹی",
        name_vi: "Haiti",
        name_zh: "海地",
        name_zht: "海地",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HTI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.71236141629296, 19.714455878167357],
            [-71.62487321642283, 19.169837958243306],
            [-71.7013026597825, 18.785416978424053],
            [-71.94511206733556, 18.61690013272026],
            [-71.68773759630588, 18.31666006110447],
            [-71.70830481635805, 18.044997056546094],
            [-72.37247616238935, 18.21496084235406],
            [-72.84441118029488, 18.145611070218365],
            [-73.45455481636503, 18.2179063989947],
            [-73.92243323433566, 18.030992743395004],
            [-74.45803361682478, 18.342549953682706],
            [-74.36992529976713, 18.66490753831941],
            [-73.44954220243272, 18.526052964751145],
            [-72.69493709989064, 18.445799465401862],
            [-72.334881557897, 18.668421535715254],
            [-72.79164954292489, 19.10162506761803],
            [-72.78410478381028, 19.48359141690341],
            [-73.41502234566175, 19.639550889560283],
            [-73.18979061551762, 19.915683905511912],
            [-72.57967281766362, 19.871500555902358],
            [-71.71236141629296, 19.714455878167357],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Dominican Republic",
        sov_a3: "DOM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Dominican Republic",
        adm0_a3: "DOM",
        geou_dif: 0,
        geounit: "Dominican Republic",
        gu_a3: "DOM",
        su_dif: 0,
        subunit: "Dominican Republic",
        su_a3: "DOM",
        brk_diff: 0,
        name: "Dominican Rep.",
        name_long: "Dominican Republic",
        brk_a3: "DOM",
        brk_name: "Dominican Rep.",
        brk_group: null,
        abbrev: "Dom. Rep.",
        postal: "DO",
        formal_en: "Dominican Republic",
        formal_fr: null,
        name_ciawf: "Dominican Republic",
        note_adm0: null,
        note_brk: null,
        name_sort: "Dominican Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 10738958,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 88941,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "DR",
        iso_a2: "DO",
        iso_a2_eh: "DO",
        iso_a3: "DOM",
        iso_a3_eh: "DOM",
        iso_n3: "214",
        iso_n3_eh: "214",
        un_a3: "214",
        wb_a2: "DO",
        wb_a3: "DOM",
        woe_id: 23424800,
        woe_id_eh: 23424800,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DOM",
        adm0_diff: null,
        adm0_tlc: "DOM",
        adm0_a3_us: "DOM",
        adm0_a3_fr: "DOM",
        adm0_a3_ru: "DOM",
        adm0_a3_es: "DOM",
        adm0_a3_cn: "DOM",
        adm0_a3_tw: "DOM",
        adm0_a3_in: "DOM",
        adm0_a3_np: "DOM",
        adm0_a3_pk: "DOM",
        adm0_a3_de: "DOM",
        adm0_a3_gb: "DOM",
        adm0_a3_br: "DOM",
        adm0_a3_il: "DOM",
        adm0_a3_ps: "DOM",
        adm0_a3_sa: "DOM",
        adm0_a3_eg: "DOM",
        adm0_a3_ma: "DOM",
        adm0_a3_pt: "DOM",
        adm0_a3_ar: "DOM",
        adm0_a3_jp: "DOM",
        adm0_a3_ko: "DOM",
        adm0_a3_vn: "DOM",
        adm0_a3_tr: "DOM",
        adm0_a3_id: "DOM",
        adm0_a3_pl: "DOM",
        adm0_a3_gr: "DOM",
        adm0_a3_it: "DOM",
        adm0_a3_nl: "DOM",
        adm0_a3_se: "DOM",
        adm0_a3_bd: "DOM",
        adm0_a3_ua: "DOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 14,
        long_len: 18,
        abbrev_len: 9,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -70.653998,
        label_y: 19.104137,
        ne_id: 1159320563,
        wikidataid: "Q786",
        name_ar: "جمهورية الدومينيكان",
        name_bn: "ডোমিনিকান প্রজাতন্ত্র",
        name_de: "Dominikanische Republik",
        name_en: "Dominican Republic",
        name_es: "República Dominicana",
        name_fa: "جمهوری دومینیکن",
        name_fr: "République dominicaine",
        name_el: "Δομινικανή Δημοκρατία",
        name_he: "הרפובליקה הדומיניקנית",
        name_hi: "डोमिनिकन गणराज्य",
        name_hu: "Dominikai Köztársaság",
        name_id: "Republik Dominika",
        name_it: "Repubblica Dominicana",
        name_ja: "ドミニカ共和国",
        name_ko: "도미니카 공화국",
        name_nl: "Dominicaanse Republiek",
        name_pl: "Dominikana",
        name_pt: "República Dominicana",
        name_ru: "Доминиканская Республика",
        name_sv: "Dominikanska republiken",
        name_tr: "Dominik Cumhuriyeti",
        name_uk: "Домініканська Республіка",
        name_ur: "جمہوریہ ڈومینیکن",
        name_vi: "Cộng hòa Dominica",
        name_zh: "多米尼加",
        name_zht: "多明尼加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DOM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.70830481635805, 18.044997056546094],
            [-71.68773759630588, 18.31666006110447],
            [-71.94511206733556, 18.61690013272026],
            [-71.7013026597825, 18.785416978424053],
            [-71.62487321642283, 19.169837958243306],
            [-71.71236141629296, 19.714455878167357],
            [-71.58730445014663, 19.8849105900821],
            [-70.80670610216174, 19.880285549391985],
            [-70.21436499701613, 19.62288524014616],
            [-69.95081519232758, 19.64799998624001],
            [-69.76925004747008, 19.29326711677244],
            [-69.22212582057988, 19.313214219637103],
            [-69.25434607611385, 19.015196234609874],
            [-68.80941199408083, 18.979074408437853],
            [-68.31794328476897, 18.612197577381693],
            [-68.68931596543452, 18.205142320218613],
            [-69.16494584824892, 18.42264842373511],
            [-69.62398759629764, 18.38071299893025],
            [-69.95293392605154, 18.42830699307106],
            [-70.1332329983179, 18.245915025296895],
            [-70.51713721381422, 18.184290879788833],
            [-70.66929846869763, 18.426885891183034],
            [-70.99995012071719, 18.283328762276213],
            [-71.4002099270339, 17.5985643579766],
            [-71.65766191271202, 17.7575727401387],
            [-71.70830481635805, 18.044997056546094],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Cuba",
        sov_a3: "CU1",
        adm0_dif: 1,
        level: 1,
        type: "Sovereignty",
        tlc: "1",
        admin: "Cuba",
        adm0_a3: "CUB",
        geou_dif: 0,
        geounit: "Cuba",
        gu_a3: "CUB",
        su_dif: 0,
        subunit: "Cuba",
        su_a3: "CUB",
        brk_diff: 0,
        name: "Cuba",
        name_long: "Cuba",
        brk_a3: "CUB",
        brk_name: "Cuba",
        brk_group: null,
        abbrev: "Cuba",
        postal: "CU",
        formal_en: "Republic of Cuba",
        formal_fr: null,
        name_ciawf: "Cuba",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cuba",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 11333483,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 100023,
        gdp_year: 2018,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "CU",
        iso_a2: "CU",
        iso_a2_eh: "CU",
        iso_a3: "CUB",
        iso_a3_eh: "CUB",
        iso_n3: "192",
        iso_n3_eh: "192",
        un_a3: "192",
        wb_a2: "CU",
        wb_a3: "CUB",
        woe_id: 23424793,
        woe_id_eh: 23424793,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CUB",
        adm0_diff: null,
        adm0_tlc: "CUB",
        adm0_a3_us: "CUB",
        adm0_a3_fr: "CUB",
        adm0_a3_ru: "CUB",
        adm0_a3_es: "CUB",
        adm0_a3_cn: "CUB",
        adm0_a3_tw: "CUB",
        adm0_a3_in: "CUB",
        adm0_a3_np: "CUB",
        adm0_a3_pk: "CUB",
        adm0_a3_de: "CUB",
        adm0_a3_gb: "CUB",
        adm0_a3_br: "CUB",
        adm0_a3_il: "CUB",
        adm0_a3_ps: "CUB",
        adm0_a3_sa: "CUB",
        adm0_a3_eg: "CUB",
        adm0_a3_ma: "CUB",
        adm0_a3_pt: "CUB",
        adm0_a3_ar: "CUB",
        adm0_a3_jp: "CUB",
        adm0_a3_ko: "CUB",
        adm0_a3_vn: "CUB",
        adm0_a3_tr: "CUB",
        adm0_a3_id: "CUB",
        adm0_a3_pl: "CUB",
        adm0_a3_gr: "CUB",
        adm0_a3_it: "CUB",
        adm0_a3_nl: "CUB",
        adm0_a3_se: "CUB",
        adm0_a3_bd: "CUB",
        adm0_a3_ua: "CUB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -77.975855,
        label_y: 21.334024,
        ne_id: 1159320527,
        wikidataid: "Q241",
        name_ar: "كوبا",
        name_bn: "কিউবা",
        name_de: "Kuba",
        name_en: "Cuba",
        name_es: "Cuba",
        name_fa: "کوبا",
        name_fr: "Cuba",
        name_el: "Κούβα",
        name_he: "קובה",
        name_hi: "क्यूबा",
        name_hu: "Kuba",
        name_id: "Kuba",
        name_it: "Cuba",
        name_ja: "キューバ",
        name_ko: "쿠바",
        name_nl: "Cuba",
        name_pl: "Kuba",
        name_pt: "Cuba",
        name_ru: "Куба",
        name_sv: "Kuba",
        name_tr: "Küba",
        name_uk: "Куба",
        name_ur: "کیوبا",
        name_vi: "Cuba",
        name_zh: "古巴",
        name_zht: "古巴",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CUB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.26815121125706, 23.188610744717707],
            [-81.40445716014683, 23.117271429938782],
            [-80.6187686835812, 23.105980129483],
            [-79.67952368846025, 22.76530324959883],
            [-79.28148596873208, 22.399201565027056],
            [-78.34743445505649, 22.512166246017088],
            [-77.99329586456028, 22.277193508385935],
            [-77.14642249216105, 21.657851467367834],
            [-76.52382483590856, 21.206819566324373],
            [-76.19462012399319, 21.220565497314013],
            [-75.59822241891267, 21.016624457274133],
            [-75.67106035022806, 20.735091254148003],
            [-74.9338960435845, 20.693905137611385],
            [-74.17802486845126, 20.28462779385974],
            [-74.29664811877726, 20.05037852628068],
            [-74.96159461129294, 19.92343537035569],
            [-75.63468014189459, 19.873774318923196],
            [-76.323656175426, 19.95289093676206],
            [-77.75548092315307, 19.855480861891877],
            [-77.08510840524674, 20.413353786698792],
            [-77.49265458851661, 20.673105373613893],
            [-78.13729224314159, 20.739948838783434],
            [-78.48282670766119, 21.02861338956585],
            [-78.71986650258401, 21.598113511638434],
            [-79.28499996612794, 21.5591753199065],
            [-80.21747534861865, 21.827324327069036],
            [-80.51753455272141, 22.03707896574176],
            [-81.82094336620318, 22.19205658618507],
            [-82.16999182811864, 22.387109279870753],
            [-81.79500179719267, 22.636964830001958],
            [-82.77589799674085, 22.688150336187064],
            [-83.49445878775936, 22.16851797127613],
            [-83.90880042187563, 22.154565334557333],
            [-84.05215084505326, 21.910575059491254],
            [-84.54703019889638, 21.801227728761646],
            [-84.97491105827311, 21.89602814380109],
            [-84.44706214062776, 22.204949856041907],
            [-84.23035702181178, 22.565754706303764],
            [-83.7782399156902, 22.788118394455694],
            [-83.26754757356575, 22.983041897060644],
            [-82.51043616405751, 23.078746649665188],
            [-82.26815121125706, 23.188610744717707],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "El Salvador",
        sov_a3: "SLV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "El Salvador",
        adm0_a3: "SLV",
        geou_dif: 0,
        geounit: "El Salvador",
        gu_a3: "SLV",
        su_dif: 0,
        subunit: "El Salvador",
        su_a3: "SLV",
        brk_diff: 0,
        name: "El Salvador",
        name_long: "El Salvador",
        brk_a3: "SLV",
        brk_name: "El Salvador",
        brk_group: null,
        abbrev: "El. S.",
        postal: "SV",
        formal_en: "Republic of El Salvador",
        formal_fr: null,
        name_ciawf: "El Salvador",
        note_adm0: null,
        note_brk: null,
        name_sort: "El Salvador",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 6,
        mapcolor13: 8,
        pop_est: 6453553,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 27022,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "ES",
        iso_a2: "SV",
        iso_a2_eh: "SV",
        iso_a3: "SLV",
        iso_a3_eh: "SLV",
        iso_n3: "222",
        iso_n3_eh: "222",
        un_a3: "222",
        wb_a2: "SV",
        wb_a3: "SLV",
        woe_id: 23424807,
        woe_id_eh: 23424807,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SLV",
        adm0_diff: null,
        adm0_tlc: "SLV",
        adm0_a3_us: "SLV",
        adm0_a3_fr: "SLV",
        adm0_a3_ru: "SLV",
        adm0_a3_es: "SLV",
        adm0_a3_cn: "SLV",
        adm0_a3_tw: "SLV",
        adm0_a3_in: "SLV",
        adm0_a3_np: "SLV",
        adm0_a3_pk: "SLV",
        adm0_a3_de: "SLV",
        adm0_a3_gb: "SLV",
        adm0_a3_br: "SLV",
        adm0_a3_il: "SLV",
        adm0_a3_ps: "SLV",
        adm0_a3_sa: "SLV",
        adm0_a3_eg: "SLV",
        adm0_a3_ma: "SLV",
        adm0_a3_pt: "SLV",
        adm0_a3_ar: "SLV",
        adm0_a3_jp: "SLV",
        adm0_a3_ko: "SLV",
        adm0_a3_vn: "SLV",
        adm0_a3_tr: "SLV",
        adm0_a3_id: "SLV",
        adm0_a3_pl: "SLV",
        adm0_a3_gr: "SLV",
        adm0_a3_it: "SLV",
        adm0_a3_nl: "SLV",
        adm0_a3_se: "SLV",
        adm0_a3_bd: "SLV",
        adm0_a3_ua: "SLV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -88.890124,
        label_y: 13.685371,
        ne_id: 1159321253,
        wikidataid: "Q792",
        name_ar: "السلفادور",
        name_bn: "এল সালভাদোর",
        name_de: "El Salvador",
        name_en: "El Salvador",
        name_es: "El Salvador",
        name_fa: "السالوادور",
        name_fr: "Salvador",
        name_el: "Ελ Σαλβαδόρ",
        name_he: "אל סלוודור",
        name_hi: "अल साल्वाडोर",
        name_hu: "Salvador",
        name_id: "El Salvador",
        name_it: "El Salvador",
        name_ja: "エルサルバドル",
        name_ko: "엘살바도르",
        name_nl: "El Salvador",
        name_pl: "Salwador",
        name_pt: "El Salvador",
        name_ru: "Сальвадор",
        name_sv: "El Salvador",
        name_tr: "El Salvador",
        name_uk: "Сальвадор",
        name_ur: "ایل سیلواڈور",
        name_vi: "El Salvador",
        name_zh: "萨尔瓦多",
        name_zht: "薩爾瓦多",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SLV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.35332597528281, 14.424132798719086],
            [-89.05851192905766, 14.340029405164087],
            [-88.84307288283284, 14.14050670008517],
            [-88.541230841816, 13.980154730683479],
            [-88.50399797234971, 13.845485948130857],
            [-88.06534257684014, 13.964625962779778],
            [-87.85951534702161, 13.893312486217098],
            [-87.7235029772294, 13.785050360565506],
            [-87.79311113152657, 13.384480495655055],
            [-87.90411210808952, 13.149016831917137],
            [-88.48330156121682, 13.163951320849492],
            [-88.84322791212972, 13.259733588102478],
            [-89.2567427233293, 13.458532823129303],
            [-89.81239356154767, 13.520622056527998],
            [-90.09555457229098, 13.735337632700734],
            [-90.0646779039966, 13.881969509328925],
            [-89.72193396682073, 14.134228013561696],
            [-89.53421932652051, 14.244815578666305],
            [-89.58734269891656, 14.36258616785949],
            [-89.35332597528281, 14.424132798719086],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Guatemala",
        sov_a3: "GTM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guatemala",
        adm0_a3: "GTM",
        geou_dif: 0,
        geounit: "Guatemala",
        gu_a3: "GTM",
        su_dif: 0,
        subunit: "Guatemala",
        su_a3: "GTM",
        brk_diff: 0,
        name: "Guatemala",
        name_long: "Guatemala",
        brk_a3: "GTM",
        brk_name: "Guatemala",
        brk_group: null,
        abbrev: "Guat.",
        postal: "GT",
        formal_en: "Republic of Guatemala",
        formal_fr: null,
        name_ciawf: "Guatemala",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guatemala",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 16604026,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 76710,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GT",
        iso_a2: "GT",
        iso_a2_eh: "GT",
        iso_a3: "GTM",
        iso_a3_eh: "GTM",
        iso_n3: "320",
        iso_n3_eh: "320",
        un_a3: "320",
        wb_a2: "GT",
        wb_a3: "GTM",
        woe_id: 23424834,
        woe_id_eh: 23424834,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GTM",
        adm0_diff: null,
        adm0_tlc: "GTM",
        adm0_a3_us: "GTM",
        adm0_a3_fr: "GTM",
        adm0_a3_ru: "GTM",
        adm0_a3_es: "GTM",
        adm0_a3_cn: "GTM",
        adm0_a3_tw: "GTM",
        adm0_a3_in: "GTM",
        adm0_a3_np: "GTM",
        adm0_a3_pk: "GTM",
        adm0_a3_de: "GTM",
        adm0_a3_gb: "GTM",
        adm0_a3_br: "GTM",
        adm0_a3_il: "GTM",
        adm0_a3_ps: "GTM",
        adm0_a3_sa: "GTM",
        adm0_a3_eg: "GTM",
        adm0_a3_ma: "GTM",
        adm0_a3_pt: "GTM",
        adm0_a3_ar: "GTM",
        adm0_a3_jp: "GTM",
        adm0_a3_ko: "GTM",
        adm0_a3_vn: "GTM",
        adm0_a3_tr: "GTM",
        adm0_a3_id: "GTM",
        adm0_a3_pl: "GTM",
        adm0_a3_gr: "GTM",
        adm0_a3_it: "GTM",
        adm0_a3_nl: "GTM",
        adm0_a3_se: "GTM",
        adm0_a3_bd: "GTM",
        adm0_a3_ua: "GTM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -90.497134,
        label_y: 14.982133,
        ne_id: 1159320815,
        wikidataid: "Q774",
        name_ar: "غواتيمالا",
        name_bn: "গুয়াতেমালা",
        name_de: "Guatemala",
        name_en: "Guatemala",
        name_es: "Guatemala",
        name_fa: "گواتمالا",
        name_fr: "Guatemala",
        name_el: "Γουατεμάλα",
        name_he: "גואטמלה",
        name_hi: "ग्वाटेमाला",
        name_hu: "Guatemala",
        name_id: "Guatemala",
        name_it: "Guatemala",
        name_ja: "グアテマラ",
        name_ko: "과테말라",
        name_nl: "Guatemala",
        name_pl: "Gwatemala",
        name_pt: "Guatemala",
        name_ru: "Гватемала",
        name_sv: "Guatemala",
        name_tr: "Guatemala",
        name_uk: "Гватемала",
        name_ur: "گواتیمالا",
        name_vi: "Guatemala",
        name_zh: "危地马拉",
        name_zht: "瓜地馬拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GTM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.22775000686983, 14.538828640190928],
            [-92.20322953974733, 14.830102850804069],
            [-92.08721594925207, 15.064584662328441],
            [-92.2292486234063, 15.251446641495875],
            [-91.74796017125595, 16.066564846251765],
            [-90.46447262242266, 16.069562079324655],
            [-90.43886695022204, 16.410109768128095],
            [-90.60084672724093, 16.47077789963879],
            [-90.71182186558772, 16.687483018454728],
            [-91.08167009150065, 16.918476670799407],
            [-91.45392127151516, 17.252177232324172],
            [-91.00226925328421, 17.25465770107418],
            [-91.00151994501596, 17.81759491624571],
            [-90.06793351923098, 17.819326076727478],
            [-89.14308041050333, 17.808318996649405],
            [-89.15080603713095, 17.015576687075836],
            [-89.22912167026928, 15.886937567605171],
            [-88.93061275913527, 15.887273464415076],
            [-88.60458614780585, 15.70638011317736],
            [-88.51836402052686, 15.855389105690975],
            [-88.22502275262202, 15.727722479713904],
            [-88.68067969435563, 15.346247056535304],
            [-89.15481096063357, 15.06641917567481],
            [-89.22522009963127, 14.874286200413621],
            [-89.14553504103719, 14.678019110569153],
            [-89.35332597528281, 14.424132798719086],
            [-89.58734269891656, 14.36258616785949],
            [-89.53421932652051, 14.244815578666305],
            [-89.72193396682073, 14.134228013561696],
            [-90.0646779039966, 13.881969509328925],
            [-90.09555457229098, 13.735337632700734],
            [-90.60862403030085, 13.909771429901951],
            [-91.23241024449605, 13.927832342987957],
            [-91.68974667027913, 14.126218166556455],
            [-92.22775000686983, 14.538828640190928],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Honduras",
        sov_a3: "HND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Honduras",
        adm0_a3: "HND",
        geou_dif: 0,
        geounit: "Honduras",
        gu_a3: "HND",
        su_dif: 0,
        subunit: "Honduras",
        su_a3: "HND",
        brk_diff: 0,
        name: "Honduras",
        name_long: "Honduras",
        brk_a3: "HND",
        brk_name: "Honduras",
        brk_group: null,
        abbrev: "Hond.",
        postal: "HN",
        formal_en: "Republic of Honduras",
        formal_fr: null,
        name_ciawf: "Honduras",
        note_adm0: null,
        note_brk: null,
        name_sort: "Honduras",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 9746117,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 25095,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "HO",
        iso_a2: "HN",
        iso_a2_eh: "HN",
        iso_a3: "HND",
        iso_a3_eh: "HND",
        iso_n3: "340",
        iso_n3_eh: "340",
        un_a3: "340",
        wb_a2: "HN",
        wb_a3: "HND",
        woe_id: 23424841,
        woe_id_eh: 23424841,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HND",
        adm0_diff: null,
        adm0_tlc: "HND",
        adm0_a3_us: "HND",
        adm0_a3_fr: "HND",
        adm0_a3_ru: "HND",
        adm0_a3_es: "HND",
        adm0_a3_cn: "HND",
        adm0_a3_tw: "HND",
        adm0_a3_in: "HND",
        adm0_a3_np: "HND",
        adm0_a3_pk: "HND",
        adm0_a3_de: "HND",
        adm0_a3_gb: "HND",
        adm0_a3_br: "HND",
        adm0_a3_il: "HND",
        adm0_a3_ps: "HND",
        adm0_a3_sa: "HND",
        adm0_a3_eg: "HND",
        adm0_a3_ma: "HND",
        adm0_a3_pt: "HND",
        adm0_a3_ar: "HND",
        adm0_a3_jp: "HND",
        adm0_a3_ko: "HND",
        adm0_a3_vn: "HND",
        adm0_a3_tr: "HND",
        adm0_a3_id: "HND",
        adm0_a3_pl: "HND",
        adm0_a3_gr: "HND",
        adm0_a3_it: "HND",
        adm0_a3_nl: "HND",
        adm0_a3_se: "HND",
        adm0_a3_bd: "HND",
        adm0_a3_ua: "HND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -86.887604,
        label_y: 14.794801,
        ne_id: 1159320827,
        wikidataid: "Q783",
        name_ar: "هندوراس",
        name_bn: "হন্ডুরাস",
        name_de: "Honduras",
        name_en: "Honduras",
        name_es: "Honduras",
        name_fa: "هندوراس",
        name_fr: "Honduras",
        name_el: "Ονδούρα",
        name_he: "הונדורס",
        name_hi: "हौण्डुरस",
        name_hu: "Honduras",
        name_id: "Honduras",
        name_it: "Honduras",
        name_ja: "ホンジュラス",
        name_ko: "온두라스",
        name_nl: "Honduras",
        name_pl: "Honduras",
        name_pt: "Honduras",
        name_ru: "Гондурас",
        name_sv: "Honduras",
        name_tr: "Honduras",
        name_uk: "Гондурас",
        name_ur: "ہونڈوراس",
        name_vi: "Honduras",
        name_zh: "洪都拉斯",
        name_zht: "宏都拉斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HND.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.14721900097413, 14.99582916916411],
            [-83.48998877636612, 15.016267198135537],
            [-83.62858496777292, 14.880073960830302],
            [-83.97572140169359, 14.749435939996488],
            [-84.22834164095241, 14.74876414637663],
            [-84.4493359036486, 14.621614284722511],
            [-84.64958207877964, 14.666805324761867],
            [-84.82003679069436, 14.81958669683267],
            [-84.9245006985724, 14.79049286545235],
            [-85.05278744173694, 14.551541042534723],
            [-85.14875057650296, 14.560196844943617],
            [-85.16536454948482, 14.354369615125051],
            [-85.51441301140028, 14.079011745657908],
            [-85.69866533073696, 13.960078436738002],
            [-85.80129472526859, 13.83605499923759],
            [-86.09626380079061, 14.038187364147234],
            [-86.31214209668993, 13.77135610600817],
            [-86.52070817741992, 13.778487453664468],
            [-86.75508663607971, 13.754845485890913],
            [-86.7338217841916, 13.263092556201443],
            [-86.88055701368438, 13.254204209847217],
            [-87.00576900912758, 13.025794379117158],
            [-87.31665442579549, 12.984685777228975],
            [-87.48940873894713, 13.297534898323931],
            [-87.79311113152657, 13.384480495655055],
            [-87.7235029772294, 13.785050360565506],
            [-87.85951534702161, 13.893312486217098],
            [-88.06534257684014, 13.964625962779778],
            [-88.50399797234971, 13.845485948130857],
            [-88.541230841816, 13.980154730683479],
            [-88.84307288283284, 14.14050670008517],
            [-89.05851192905766, 14.340029405164087],
            [-89.35332597528281, 14.424132798719086],
            [-89.14553504103719, 14.678019110569153],
            [-89.22522009963127, 14.874286200413621],
            [-89.15481096063357, 15.06641917567481],
            [-88.68067969435563, 15.346247056535304],
            [-88.22502275262202, 15.727722479713904],
            [-88.12115312371537, 15.688655096901357],
            [-87.90181250685242, 15.864458319558196],
            [-87.61568010125234, 15.878798529519202],
            [-87.52292090528846, 15.797278957578783],
            [-87.36776241733213, 15.846940009011291],
            [-86.90319129102818, 15.75671295822957],
            [-86.44094560417739, 15.782835394753192],
            [-86.11923397494434, 15.893448798073962],
            [-86.00195431185784, 16.00540578863439],
            [-85.68331743034628, 15.953651841693954],
            [-85.44400387240256, 15.885749009662447],
            [-85.18244361035721, 15.909158433490632],
            [-84.98372188997882, 15.995923163308703],
            [-84.52697974316715, 15.857223619037427],
            [-84.36825558138258, 15.835157782448732],
            [-84.06305457226682, 15.648244126849136],
            [-83.77397661002612, 15.424071763566872],
            [-83.41038123242038, 15.270902818253774],
            [-83.14721900097413, 14.99582916916411],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Belize",
        sov_a3: "BLZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Belize",
        adm0_a3: "BLZ",
        geou_dif: 0,
        geounit: "Belize",
        gu_a3: "BLZ",
        su_dif: 0,
        subunit: "Belize",
        su_a3: "BLZ",
        brk_diff: 0,
        name: "Belize",
        name_long: "Belize",
        brk_a3: "BLZ",
        brk_name: "Belize",
        brk_group: null,
        abbrev: "Belize",
        postal: "BZ",
        formal_en: "Belize",
        formal_fr: null,
        name_ciawf: "Belize",
        note_adm0: null,
        note_brk: null,
        name_sort: "Belize",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 390353,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 1879,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "BH",
        iso_a2: "BZ",
        iso_a2_eh: "BZ",
        iso_a3: "BLZ",
        iso_a3_eh: "BLZ",
        iso_n3: "084",
        iso_n3_eh: "084",
        un_a3: "084",
        wb_a2: "BZ",
        wb_a3: "BLZ",
        woe_id: 23424760,
        woe_id_eh: 23424760,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BLZ",
        adm0_diff: null,
        adm0_tlc: "BLZ",
        adm0_a3_us: "BLZ",
        adm0_a3_fr: "BLZ",
        adm0_a3_ru: "BLZ",
        adm0_a3_es: "BLZ",
        adm0_a3_cn: "BLZ",
        adm0_a3_tw: "BLZ",
        adm0_a3_in: "BLZ",
        adm0_a3_np: "BLZ",
        adm0_a3_pk: "BLZ",
        adm0_a3_de: "BLZ",
        adm0_a3_gb: "BLZ",
        adm0_a3_br: "BLZ",
        adm0_a3_il: "BLZ",
        adm0_a3_ps: "BLZ",
        adm0_a3_sa: "BLZ",
        adm0_a3_eg: "BLZ",
        adm0_a3_ma: "BLZ",
        adm0_a3_pt: "BLZ",
        adm0_a3_ar: "BLZ",
        adm0_a3_jp: "BLZ",
        adm0_a3_ko: "BLZ",
        adm0_a3_vn: "BLZ",
        adm0_a3_tr: "BLZ",
        adm0_a3_id: "BLZ",
        adm0_a3_pl: "BLZ",
        adm0_a3_gr: "BLZ",
        adm0_a3_it: "BLZ",
        adm0_a3_nl: "BLZ",
        adm0_a3_se: "BLZ",
        adm0_a3_bd: "BLZ",
        adm0_a3_ua: "BLZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -88.712962,
        label_y: 17.202068,
        ne_id: 1159320431,
        wikidataid: "Q242",
        name_ar: "بليز",
        name_bn: "বেলিজ",
        name_de: "Belize",
        name_en: "Belize",
        name_es: "Belice",
        name_fa: "بلیز",
        name_fr: "Belize",
        name_el: "Μπελίζ",
        name_he: "בליז",
        name_hi: "बेलीज़",
        name_hu: "Belize",
        name_id: "Belize",
        name_it: "Belize",
        name_ja: "ベリーズ",
        name_ko: "벨리즈",
        name_nl: "Belize",
        name_pl: "Belize",
        name_pt: "Belize",
        name_ru: "Белиз",
        name_sv: "Belize",
        name_tr: "Belize",
        name_uk: "Беліз",
        name_ur: "بیلیز",
        name_vi: "Belize",
        name_zh: "伯利兹",
        name_zht: "貝里斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BLZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.14308041050333, 17.808318996649405],
            [-89.15090938999553, 17.95546763760042],
            [-89.02985734735182, 18.001511338772488],
            [-88.84834387892661, 17.883198147040233],
            [-88.49012285027935, 18.486830552641607],
            [-88.3000310940937, 18.4999822046599],
            [-88.29633622918482, 18.35327281338327],
            [-88.10681291375438, 18.348673610909287],
            [-88.1234785631685, 18.07667470954101],
            [-88.2853549873228, 17.644142971258034],
            [-88.19786678745265, 17.489475409408456],
            [-88.30264075392444, 17.131693630435663],
            [-88.23951799187991, 17.036066392479555],
            [-88.35542822951057, 16.530774237529627],
            [-88.55182451043585, 16.265467434143147],
            [-88.73243364129594, 16.233634751851355],
            [-88.93061275913527, 15.887273464415076],
            [-89.22912167026928, 15.886937567605171],
            [-89.15080603713095, 17.015576687075836],
            [-89.14308041050333, 17.808318996649405],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Panama",
        sov_a3: "PAN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Panama",
        adm0_a3: "PAN",
        geou_dif: 0,
        geounit: "Panama",
        gu_a3: "PAN",
        su_dif: 0,
        subunit: "Panama",
        su_a3: "PAN",
        brk_diff: 0,
        name: "Panama",
        name_long: "Panama",
        brk_a3: "PAN",
        brk_name: "Panama",
        brk_group: null,
        abbrev: "Pan.",
        postal: "PA",
        formal_en: "Republic of Panama",
        formal_fr: null,
        name_ciawf: "Panama",
        note_adm0: null,
        note_brk: null,
        name_sort: "Panama",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 4246439,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 66800,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "PM",
        iso_a2: "PA",
        iso_a2_eh: "PA",
        iso_a3: "PAN",
        iso_a3_eh: "PAN",
        iso_n3: "591",
        iso_n3_eh: "591",
        un_a3: "591",
        wb_a2: "PA",
        wb_a3: "PAN",
        woe_id: 23424924,
        woe_id_eh: 23424924,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PAN",
        adm0_diff: null,
        adm0_tlc: "PAN",
        adm0_a3_us: "PAN",
        adm0_a3_fr: "PAN",
        adm0_a3_ru: "PAN",
        adm0_a3_es: "PAN",
        adm0_a3_cn: "PAN",
        adm0_a3_tw: "PAN",
        adm0_a3_in: "PAN",
        adm0_a3_np: "PAN",
        adm0_a3_pk: "PAN",
        adm0_a3_de: "PAN",
        adm0_a3_gb: "PAN",
        adm0_a3_br: "PAN",
        adm0_a3_il: "PAN",
        adm0_a3_ps: "PAN",
        adm0_a3_sa: "PAN",
        adm0_a3_eg: "PAN",
        adm0_a3_ma: "PAN",
        adm0_a3_pt: "PAN",
        adm0_a3_ar: "PAN",
        adm0_a3_jp: "PAN",
        adm0_a3_ko: "PAN",
        adm0_a3_vn: "PAN",
        adm0_a3_tr: "PAN",
        adm0_a3_id: "PAN",
        adm0_a3_pl: "PAN",
        adm0_a3_gr: "PAN",
        adm0_a3_it: "PAN",
        adm0_a3_nl: "PAN",
        adm0_a3_se: "PAN",
        adm0_a3_bd: "PAN",
        adm0_a3_ua: "PAN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -80.352106,
        label_y: 8.72198,
        ne_id: 1159321161,
        wikidataid: "Q804",
        name_ar: "بنما",
        name_bn: "পানামা",
        name_de: "Panama",
        name_en: "Panama",
        name_es: "Panamá",
        name_fa: "پاناما",
        name_fr: "Panama",
        name_el: "Παναμάς",
        name_he: "פנמה",
        name_hi: "पनामा",
        name_hu: "Panama",
        name_id: "Panama",
        name_it: "Panama",
        name_ja: "パナマ",
        name_ko: "파나마",
        name_nl: "Panama",
        name_pl: "Panama",
        name_pt: "Panamá",
        name_ru: "Панама",
        name_sv: "Panama",
        name_tr: "Panama",
        name_uk: "Панама",
        name_ur: "پاناما",
        name_vi: "Panama",
        name_zh: "巴拿马",
        name_zht: "巴拿馬",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PAN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.35336076527386, 8.67050466555807],
            [-77.47472286651133, 8.524286200388218],
            [-77.24256649444008, 7.935278225125444],
            [-77.43110795765699, 7.638061224798734],
            [-77.7534138658614, 7.709839789252143],
            [-77.88157141794525, 7.223771267114785],
            [-78.21493608266012, 7.512254950384161],
            [-78.42916073272607, 8.052041123888927],
            [-78.18209570993864, 8.319182440621773],
            [-78.4354652574657, 8.38770538984079],
            [-78.62212053090394, 8.718124497915028],
            [-79.12030717641375, 8.996092027213024],
            [-79.55787736684519, 8.932374986197146],
            [-79.76057817251005, 8.5845150822244],
            [-80.16448116730334, 8.333315944853595],
            [-80.38265906443962, 8.298408514840432],
            [-80.4806892564973, 8.09030752200107],
            [-80.00368994822716, 7.547524115423372],
            [-80.276670701809, 7.419754136581716],
            [-80.42115800649708, 7.271571966984765],
            [-80.8864009264208, 7.220541490096537],
            [-81.05954281281473, 7.817921047390597],
            [-81.18971574575795, 7.64790558515034],
            [-81.51951473664468, 7.706610012233909],
            [-81.72131120474447, 8.108962714058435],
            [-82.13144120962892, 8.175392767769637],
            [-82.39093441438257, 8.29236237226229],
            [-82.82008134635042, 8.290863755725823],
            [-82.85095801464482, 8.073822740099956],
            [-82.96578304719736, 8.225027980985985],
            [-82.91317643912421, 8.42351715741907],
            [-82.82977067740516, 8.62629547773237],
            [-82.86865719270477, 8.807266343618522],
            [-82.71918311230053, 8.925708726431495],
            [-82.92715491405916, 9.074330145702916],
            [-82.93289099804358, 9.476812038608173],
            [-82.54619625520348, 9.566134751824677],
            [-82.18712256542341, 9.20744863528678],
            [-82.20758643261097, 8.9955752628901],
            [-81.80856686066929, 8.950616766796173],
            [-81.71415401887204, 9.031955471223583],
            [-81.43928707551154, 8.786234035675719],
            [-80.94730160187676, 8.858503526235907],
            [-80.52190121125008, 9.111072089062432],
            [-79.91459977895599, 9.31276520429762],
            [-79.57330278188431, 9.611610012241528],
            [-79.02119177927793, 9.552931423374105],
            [-79.05845048696037, 9.454565334506526],
            [-78.50088762074719, 9.420458889193881],
            [-78.05592770049802, 9.2477304142583],
            [-77.72951351592641, 8.946844387238869],
            [-77.35336076527386, 8.67050466555807],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Mexico",
        sov_a3: "MEX",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mexico",
        adm0_a3: "MEX",
        geou_dif: 0,
        geounit: "Mexico",
        gu_a3: "MEX",
        su_dif: 0,
        subunit: "Mexico",
        su_a3: "MEX",
        brk_diff: 0,
        name: "Mexico",
        name_long: "Mexico",
        brk_a3: "MEX",
        brk_name: "Mexico",
        brk_group: null,
        abbrev: "Mex.",
        postal: "MX",
        formal_en: "United Mexican States",
        formal_fr: null,
        name_ciawf: "Mexico",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mexico",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 127575529,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1268870,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "3. Upper middle income",
        fips_10: "MX",
        iso_a2: "MX",
        iso_a2_eh: "MX",
        iso_a3: "MEX",
        iso_a3_eh: "MEX",
        iso_n3: "484",
        iso_n3_eh: "484",
        un_a3: "484",
        wb_a2: "MX",
        wb_a3: "MEX",
        woe_id: 23424900,
        woe_id_eh: 23424900,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MEX",
        adm0_diff: null,
        adm0_tlc: "MEX",
        adm0_a3_us: "MEX",
        adm0_a3_fr: "MEX",
        adm0_a3_ru: "MEX",
        adm0_a3_es: "MEX",
        adm0_a3_cn: "MEX",
        adm0_a3_tw: "MEX",
        adm0_a3_in: "MEX",
        adm0_a3_np: "MEX",
        adm0_a3_pk: "MEX",
        adm0_a3_de: "MEX",
        adm0_a3_gb: "MEX",
        adm0_a3_br: "MEX",
        adm0_a3_il: "MEX",
        adm0_a3_ps: "MEX",
        adm0_a3_sa: "MEX",
        adm0_a3_eg: "MEX",
        adm0_a3_ma: "MEX",
        adm0_a3_pt: "MEX",
        adm0_a3_ar: "MEX",
        adm0_a3_jp: "MEX",
        adm0_a3_ko: "MEX",
        adm0_a3_vn: "MEX",
        adm0_a3_tr: "MEX",
        adm0_a3_id: "MEX",
        adm0_a3_pl: "MEX",
        adm0_a3_gr: "MEX",
        adm0_a3_it: "MEX",
        adm0_a3_nl: "MEX",
        adm0_a3_se: "MEX",
        adm0_a3_bd: "MEX",
        adm0_a3_ua: "MEX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 6.7,
        label_x: -102.289448,
        label_y: 23.919988,
        ne_id: 1159321055,
        wikidataid: "Q96",
        name_ar: "المكسيك",
        name_bn: "মেক্সিকো",
        name_de: "Mexiko",
        name_en: "Mexico",
        name_es: "México",
        name_fa: "مکزیک",
        name_fr: "Mexique",
        name_el: "Μεξικό",
        name_he: "מקסיקו",
        name_hi: "मेक्सिको",
        name_hu: "Mexikó",
        name_id: "Meksiko",
        name_it: "Messico",
        name_ja: "メキシコ",
        name_ko: "멕시코",
        name_nl: "Mexico",
        name_pl: "Meksyk",
        name_pt: "México",
        name_ru: "Мексика",
        name_sv: "Mexiko",
        name_tr: "Meksika",
        name_uk: "Мексика",
        name_ur: "میکسیکو",
        name_vi: "México",
        name_zh: "墨西哥",
        name_zht: "墨西哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MEX.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.12775999999985, 32.53534],
            [-115.99135, 32.61239000000012],
            [-114.72139, 32.72083],
            [-114.815, 32.52528],
            [-113.30498, 32.03914],
            [-111.02361, 31.33472],
            [-109.035, 31.341940000000136],
            [-108.24194, 31.34222],
            [-108.24, 31.754853718166373],
            [-106.50758999999988, 31.75452],
            [-106.1429, 31.39995],
            [-105.63159, 31.08383],
            [-105.03737, 30.64402],
            [-104.70575, 30.12173],
            [-104.4569699999999, 29.57196],
            [-103.94, 29.27],
            [-103.11, 28.97],
            [-102.48, 29.76],
            [-101.6624, 29.7793],
            [-100.9576, 29.380710000000136],
            [-100.45584, 28.69612000000012],
            [-100.11, 28.110000000000127],
            [-99.52, 27.54],
            [-99.3, 26.84],
            [-99.02, 26.37],
            [-98.24, 26.06],
            [-97.53, 25.84],
            [-97.14000830767071, 25.8699974634784],
            [-97.52807247596655, 24.9921440699203],
            [-97.70294552284224, 24.272343044526735],
            [-97.77604183631905, 22.93257986092766],
            [-97.87236670611111, 22.44421173755336],
            [-97.69904395220419, 21.898689480064263],
            [-97.38895952023677, 21.411018988525825],
            [-97.18933346229329, 20.635433254473128],
            [-96.52557552772032, 19.890930894444068],
            [-96.29212724484177, 19.320371405509547],
            [-95.90088497595997, 18.82802419684873],
            [-94.83906348344271, 18.562717393462208],
            [-94.42572953975622, 18.144370835843347],
            [-93.5486512926824, 18.423836981677937],
            [-92.7861138577835, 18.52483856859226],
            [-92.0373481920904, 18.704569200103435],
            [-91.40790340855926, 18.87608327888023],
            [-90.77186987991087, 19.28412038825678],
            [-90.53358985061305, 19.8674181177513],
            [-90.45147599970124, 20.707521877520435],
            [-90.2786183336849, 20.999855454995554],
            [-89.60132117385149, 21.26172577563449],
            [-88.54386633986286, 21.49367544197662],
            [-87.65841651075772, 21.45884552661198],
            [-87.05189022494807, 21.5435431991383],
            [-86.81198238803296, 21.331514797444754],
            [-86.84590796583262, 20.849864610268355],
            [-87.38329118523586, 20.25540477139873],
            [-87.62105445021075, 19.64655304613592],
            [-87.43675045444178, 19.47240346931227],
            [-87.58656043165593, 19.04013011319074],
            [-87.83719112827151, 18.25981598558343],
            [-88.09066402866318, 18.51664785407405],
            [-88.3000310940937, 18.4999822046599],
            [-88.49012285027935, 18.486830552641607],
            [-88.84834387892661, 17.883198147040233],
            [-89.02985734735182, 18.001511338772488],
            [-89.15090938999553, 17.95546763760042],
            [-89.14308041050333, 17.808318996649405],
            [-90.06793351923098, 17.819326076727478],
            [-91.00151994501596, 17.81759491624571],
            [-91.00226925328421, 17.25465770107418],
            [-91.45392127151516, 17.252177232324172],
            [-91.08167009150065, 16.918476670799407],
            [-90.71182186558772, 16.687483018454728],
            [-90.60084672724093, 16.47077789963879],
            [-90.43886695022204, 16.410109768128095],
            [-90.46447262242266, 16.069562079324655],
            [-91.74796017125595, 16.066564846251765],
            [-92.2292486234063, 15.251446641495875],
            [-92.08721594925207, 15.064584662328441],
            [-92.20322953974733, 14.830102850804069],
            [-92.22775000686983, 14.538828640190928],
            [-93.35946387406176, 15.615429592343673],
            [-93.87516883011853, 15.940164292865916],
            [-94.69165646033014, 16.200975246642884],
            [-95.25022701697304, 16.128318182840644],
            [-96.05338212765332, 15.752087917539598],
            [-96.55743404822829, 15.653515122942792],
            [-97.26359249549665, 15.917064927631316],
            [-98.01302995480961, 16.107311713113916],
            [-98.94767574745651, 16.566043402568766],
            [-99.69739742714705, 16.70616404872817],
            [-100.82949886758132, 17.17107107184205],
            [-101.66608862995446, 17.649026394109626],
            [-101.91852800170022, 17.916090196193977],
            [-102.47813208698892, 17.975750637275098],
            [-103.50098954955808, 18.29229462327885],
            [-103.91752743204682, 18.74857168220001],
            [-104.9920096504755, 19.316133938061682],
            [-105.49303849976144, 19.946767279535436],
            [-105.73139604370766, 20.434101874264115],
            [-105.39777299683135, 20.531718654863425],
            [-105.50066077352443, 20.81689504646613],
            [-105.27075232625793, 21.07628489835514],
            [-105.26581722697404, 21.42210358325235],
            [-105.6031609769754, 21.87114594165257],
            [-105.69341386597313, 22.269080308516152],
            [-106.02871639689897, 22.773752346278627],
            [-106.90998043498837, 23.767774359628902],
            [-107.91544877809139, 24.54891531015295],
            [-108.40190487347098, 25.172313951105934],
            [-109.26019873740665, 25.58060944264406],
            [-109.44408932171734, 25.82488393808768],
            [-109.29164384645628, 26.442934068298428],
            [-109.80145768923182, 26.676175645447927],
            [-110.3917317370857, 27.16211497650454],
            [-110.64101884646163, 27.859876003525528],
            [-111.17891883018785, 27.94124054616907],
            [-111.75960689985163, 28.46795258230395],
            [-112.2282346260904, 28.95440867768349],
            [-112.27182369672869, 29.266844387320077],
            [-112.80959448937398, 30.02111359305235],
            [-113.16381059451868, 30.786880804969428],
            [-113.14866939985717, 31.170965887978923],
            [-113.87188106978186, 31.567608344035193],
            [-114.20573666060352, 31.524045111613134],
            [-114.77645117883503, 31.79953217216115],
            [-114.93669979537214, 31.393484605427602],
            [-114.7712318591735, 30.913617255165263],
            [-114.67389929895177, 30.162681179315996],
            [-114.33097449426293, 29.750432440707414],
            [-113.58887508833544, 29.061611436473015],
            [-113.42405310754054, 28.82617361095123],
            [-113.27196936730553, 28.7547826197399],
            [-113.14003943566439, 28.41128937429596],
            [-112.9622983467965, 28.42519033458251],
            [-112.76158708377488, 27.780216783147523],
            [-112.45791052941166, 27.52581370697476],
            [-112.2449519519368, 27.17172679291076],
            [-111.61648902061921, 26.662817287700477],
            [-111.28467464887302, 25.732589830014433],
            [-110.9878193835724, 25.294606228124564],
            [-110.71000688357134, 24.82600434010186],
            [-110.65504899782889, 24.298594672131117],
            [-110.17285620811344, 24.265547593680424],
            [-109.77184709352855, 23.811182562754198],
            [-109.40910437705571, 23.36467234953625],
            [-109.43339230023292, 23.1855876734287],
            [-109.85421932660171, 22.818271592698068],
            [-110.03139197471444, 22.823077500901206],
            [-110.29507097048366, 23.43097321216669],
            [-110.94950130902805, 24.000964260345995],
            [-111.6705684070127, 24.484423122652515],
            [-112.18203589562148, 24.738412787367167],
            [-112.14898881717085, 25.47012523040405],
            [-112.3007108223797, 26.012004299416617],
            [-112.77729671919155, 26.32195954030317],
            [-113.46467078332194, 26.768185533143424],
            [-113.59672990604383, 26.639459540304472],
            [-113.84893673384425, 26.90006378835244],
            [-114.46574662968004, 27.142090358991368],
            [-115.05514217818501, 27.72272675222291],
            [-114.98225257043742, 27.798200181585116],
            [-114.57036556685495, 27.74148529714489],
            [-114.19932878299926, 28.115002549750557],
            [-114.16201839888464, 28.566111965442303],
            [-114.93184221073665, 29.27947927501549],
            [-115.518653937627, 29.5563615992354],
            [-115.88736528202958, 30.180793768834178],
            [-116.25835038945291, 30.836464341753583],
            [-116.72152625208497, 31.635743720012044],
            [-117.12775999999985, 32.53534],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "United States of America",
        adm0_a3: "USA",
        geou_dif: 0,
        geounit: "United States of America",
        gu_a3: "USA",
        su_dif: 0,
        subunit: "United States",
        su_a3: "USA",
        brk_diff: 0,
        name: "United States of America",
        name_long: "United States",
        brk_a3: "USA",
        brk_name: "United States",
        brk_group: null,
        abbrev: "U.S.A.",
        postal: "US",
        formal_en: "United States of America",
        formal_fr: null,
        name_ciawf: "United States",
        note_adm0: null,
        note_brk: null,
        name_sort: "United States of America",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 328239523,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 21433226,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "US",
        iso_a2: "US",
        iso_a2_eh: "US",
        iso_a3: "USA",
        iso_a3_eh: "USA",
        iso_n3: "840",
        iso_n3_eh: "840",
        un_a3: "840",
        wb_a2: "US",
        wb_a3: "USA",
        woe_id: 23424977,
        woe_id_eh: 23424977,
        woe_note: "Exact WOE match as country",
        adm0_iso: "USA",
        adm0_diff: null,
        adm0_tlc: "USA",
        adm0_a3_us: "USA",
        adm0_a3_fr: "USA",
        adm0_a3_ru: "USA",
        adm0_a3_es: "USA",
        adm0_a3_cn: "USA",
        adm0_a3_tw: "USA",
        adm0_a3_in: "USA",
        adm0_a3_np: "USA",
        adm0_a3_pk: "USA",
        adm0_a3_de: "USA",
        adm0_a3_gb: "USA",
        adm0_a3_br: "USA",
        adm0_a3_il: "USA",
        adm0_a3_ps: "USA",
        adm0_a3_sa: "USA",
        adm0_a3_eg: "USA",
        adm0_a3_ma: "USA",
        adm0_a3_pt: "USA",
        adm0_a3_ar: "USA",
        adm0_a3_jp: "USA",
        adm0_a3_ko: "USA",
        adm0_a3_vn: "USA",
        adm0_a3_tr: "USA",
        adm0_a3_id: "USA",
        adm0_a3_pl: "USA",
        adm0_a3_gr: "USA",
        adm0_a3_it: "USA",
        adm0_a3_nl: "USA",
        adm0_a3_se: "USA",
        adm0_a3_bd: "USA",
        adm0_a3_ua: "USA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 24,
        long_len: 13,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: -97.482602,
        label_y: 39.538479,
        ne_id: 1159321369,
        wikidataid: "Q30",
        name_ar: "الولايات المتحدة",
        name_bn: "মার্কিন যুক্তরাষ্ট্র",
        name_de: "Vereinigte Staaten",
        name_en: "United States of America",
        name_es: "Estados Unidos",
        name_fa: "ایالات متحده آمریکا",
        name_fr: "États-Unis",
        name_el: "Ηνωμένες Πολιτείες Αμερικής",
        name_he: "ארצות הברית",
        name_hi: "संयुक्त राज्य अमेरिका",
        name_hu: "Amerikai Egyesült Államok",
        name_id: "Amerika Serikat",
        name_it: "Stati Uniti d'America",
        name_ja: "アメリカ合衆国",
        name_ko: "미국",
        name_nl: "Verenigde Staten van Amerika",
        name_pl: "Stany Zjednoczone",
        name_pt: "Estados Unidos",
        name_ru: "США",
        name_sv: "USA",
        name_tr: "Amerika Birleşik Devletleri",
        name_uk: "Сполучені Штати Америки",
        name_ur: "ریاستہائے متحدہ امریکا",
        name_vi: "Hoa Kỳ",
        name_zh: "美国",
        name_zht: "美國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "USA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-122.84, 49.000000000000114],
              [-120, 49.000000000000114],
              [-117.03121, 49],
              [-116.04818, 49],
              [-113, 49],
              [-110.05, 49],
              [-107.05, 49],
              [-104.04826, 48.99986],
              [-100.65, 49.000000000000114],
              [-97.2287200000048, 49.0007],
              [-95.15906950917206, 49],
              [-95.15609, 49.38425],
              [-94.81758, 49.38905],
              [-94.64, 48.84],
              [-94.32914, 48.67074],
              [-93.63087, 48.60926],
              [-92.61, 48.45],
              [-91.64, 48.14],
              [-90.83, 48.27],
              [-89.6, 48.010000000000105],
              [-89.27291744663665, 48.01980825458281],
              [-88.37811418328671, 48.302917588893706],
              [-87.43979262330028, 47.94],
              [-86.46199083122826, 47.553338019392],
              [-85.65236324740341, 47.22021881773051],
              [-84.8760798815149, 46.90008331968238],
              [-84.77923824739992, 46.63710195574902],
              [-84.54374874544584, 46.538684190449146],
              [-84.6049, 46.4396],
              [-84.3367, 46.40877],
              [-84.1421195136734, 46.51222585711571],
              [-84.0918512641615, 46.27541860613826],
              [-83.89076534700574, 46.116926988299014],
              [-83.61613094759059, 46.116926988299014],
              [-83.46955074739469, 45.994686387712534],
              [-83.59285071484311, 45.81689362241252],
              [-82.55092464875821, 45.34751658790543],
              [-82.33776312543114, 44.44],
              [-82.13764238150395, 43.57108755143997],
              [-82.43, 42.98],
              [-82.9, 42.43],
              [-83.12, 42.08],
              [-83.14199968131264, 41.975681057292874],
              [-83.029810146807, 41.83279572200598],
              [-82.69008928092023, 41.675105088867326],
              [-82.4392777167916, 41.675105088867326],
              [-81.27774654816716, 42.209025987306816],
              [-80.24744767934794, 42.36619985612255],
              [-78.93936214874375, 42.86361135514798],
              [-78.92, 42.965],
              [-79.01, 43.27],
              [-79.17167355011186, 43.46633942318426],
              [-78.72027991404235, 43.62508942318493],
              [-77.7378850979577, 43.62905558936328],
              [-76.82003414580558, 43.628784288093755],
              [-76.5, 44.01845889375865],
              [-76.375, 44.09631],
              [-75.31821, 44.81645],
              [-74.867, 45.00048],
              [-73.34783, 45.00738],
              [-71.50506, 45.0082],
              [-71.405, 45.255],
              [-71.08482, 45.30524000000014],
              [-70.66, 45.46],
              [-70.305, 45.915],
              [-69.99997, 46.69307],
              [-69.237216, 47.447781],
              [-68.905, 47.185],
              [-68.23444, 47.35486],
              [-67.79046, 47.06636],
              [-67.79134, 45.70281],
              [-67.13741, 45.13753],
              [-66.96466, 44.809700000000134],
              [-68.03252, 44.3252],
              [-69.06, 43.98],
              [-70.11617, 43.68405000000013],
              [-70.64547563341102, 43.09023834896402],
              [-70.81489, 42.8653],
              [-70.825, 42.335],
              [-70.495, 41.805],
              [-70.08, 41.78],
              [-70.185, 42.145],
              [-69.88497, 41.92283],
              [-69.96503, 41.63717000000014],
              [-70.64, 41.475],
              [-71.12039, 41.49445000000014],
              [-71.86, 41.32],
              [-72.295, 41.27],
              [-72.87643, 41.22065],
              [-73.71, 40.93110235165449],
              [-72.24126, 41.119480000000124],
              [-71.94499999999988, 40.93],
              [-73.345, 40.63],
              [-73.982, 40.628],
              [-73.952325, 40.75075],
              [-74.25671, 40.47351],
              [-73.96244, 40.42763],
              [-74.17838, 39.70926],
              [-74.90604, 38.93954],
              [-74.98041, 39.1964],
              [-75.20002, 39.248450000000105],
              [-75.52805, 39.4985],
              [-75.32, 38.96],
              [-75.07183476478986, 38.782032230179254],
              [-75.05673, 38.40412],
              [-75.37747, 38.01551],
              [-75.94023, 37.21689],
              [-76.03127, 37.2566],
              [-75.72204999999985, 37.93705000000011],
              [-76.23287, 38.319215],
              [-76.35, 39.15],
              [-76.542725, 38.717615],
              [-76.32933, 38.08326],
              [-76.98999793161352, 38.23999176691336],
              [-76.30162, 37.917945],
              [-76.25874, 36.9664],
              [-75.9718, 36.89726],
              [-75.8680399999999, 36.55125],
              [-75.72749, 35.55074000000013],
              [-76.36318, 34.80854000000011],
              [-77.397635, 34.51201],
              [-78.05496, 33.92547],
              [-78.55434999999989, 33.86133000000012],
              [-79.06067, 33.49395],
              [-79.20357, 33.15839],
              [-80.301325, 32.509355],
              [-80.86498, 32.0333],
              [-81.33629, 31.44049],
              [-81.49042, 30.7299900000001],
              [-81.31371, 30.03552],
              [-80.98, 29.18000000000012],
              [-80.535585, 28.47213],
              [-80.52999999999986, 28.04],
              [-80.05653928497759, 26.88000000000011],
              [-80.088015, 26.205765],
              [-80.13156, 25.816775],
              [-80.38103, 25.20616],
              [-80.68, 25.08],
              [-81.17213, 25.201260000000104],
              [-81.33, 25.64],
              [-81.70999999999987, 25.87],
              [-82.24, 26.730000000000132],
              [-82.70515, 27.49504],
              [-82.85526, 27.88624],
              [-82.65, 28.550000000000125],
              [-82.93, 29.1],
              [-83.70959, 29.93656],
              [-84.1, 30.09],
              [-85.10882, 29.63615],
              [-85.28784, 29.68612000000013],
              [-85.7731, 30.15261],
              [-86.4, 30.4],
              [-87.53036, 30.27433],
              [-88.41782, 30.3849],
              [-89.18049, 30.31598],
              [-89.5938311784198, 30.159994004836847],
              [-89.413735, 29.89419],
              [-89.43, 29.48864],
              [-89.21767, 29.29108],
              [-89.40823, 29.15961],
              [-89.77928, 29.307140000000118],
              [-90.15463, 29.11743],
              [-90.880225, 29.148535],
              [-91.626785, 29.677000000000135],
              [-92.49906, 29.5523],
              [-93.22637, 29.78375],
              [-93.84842, 29.71363],
              [-94.69, 29.480000000000132],
              [-95.60026, 28.73863],
              [-96.59404, 28.30748],
              [-97.13999999999987, 27.83],
              [-97.37, 27.38],
              [-97.38, 26.69],
              [-97.33, 26.21],
              [-97.13999999999987, 25.87],
              [-97.53, 25.84],
              [-98.24, 26.06],
              [-99.02, 26.37],
              [-99.3, 26.84],
              [-99.52, 27.54],
              [-100.11, 28.110000000000127],
              [-100.45584, 28.69612000000012],
              [-100.9576, 29.380710000000136],
              [-101.6624, 29.7793],
              [-102.48, 29.76],
              [-103.11, 28.97],
              [-103.94, 29.27],
              [-104.4569699999999, 29.57196],
              [-104.70575, 30.12173],
              [-105.03737, 30.64402],
              [-105.63159, 31.08383],
              [-106.1429, 31.39995],
              [-106.50758999999988, 31.75452],
              [-108.24, 31.754853718166373],
              [-108.24194, 31.34222],
              [-109.035, 31.341940000000136],
              [-111.02361, 31.33472],
              [-113.30498, 32.03914],
              [-114.815, 32.52528],
              [-114.72139, 32.72083],
              [-115.99135, 32.61239000000012],
              [-117.12775999999985, 32.53534],
              [-117.29593769127393, 33.04622461520387],
              [-117.944, 33.621236431201396],
              [-118.41060227589753, 33.74090922312445],
              [-118.51989482279976, 34.02778157757575],
              [-119.081, 34.078],
              [-119.43884064201671, 34.34847717828427],
              [-120.36778, 34.44711],
              [-120.62286, 34.60855],
              [-120.74433, 35.15686000000011],
              [-121.71457, 36.16153],
              [-122.54747, 37.551760000000115],
              [-122.51201, 37.78339000000011],
              [-122.95319, 38.11371],
              [-123.7272, 38.95166000000012],
              [-123.86517, 39.76699],
              [-124.39807, 40.3132],
              [-124.17886, 41.142020000000116],
              [-124.2137, 41.99964000000011],
              [-124.53284, 42.7659900000001],
              [-124.14214, 43.70838],
              [-124.020535, 44.615895],
              [-123.89893, 45.52341],
              [-124.079635, 46.86475],
              [-124.39567, 47.72017000000011],
              [-124.68721008300781, 48.18443298339855],
              [-124.56610107421875, 48.37971496582037],
              [-123.12, 48.04],
              [-122.58736, 47.096],
              [-122.34, 47.36],
              [-122.5, 48.18],
              [-122.84, 49.000000000000114],
            ],
          ],
          [
            [
              [-155.40214, 20.07975],
              [-155.22452, 19.99302],
              [-155.06226, 19.8591],
              [-154.80741, 19.50871],
              [-154.83147, 19.45328],
              [-155.22217, 19.23972],
              [-155.54211, 19.08348],
              [-155.68817, 18.91619],
              [-155.93665, 19.05939],
              [-155.90806, 19.33888],
              [-156.07347, 19.70294],
              [-156.02368, 19.81422],
              [-155.85008, 19.97729],
              [-155.91907, 20.17395],
              [-155.86108, 20.26721],
              [-155.78505, 20.2487],
              [-155.40214, 20.07975],
            ],
          ],
          [
            [
              [-155.99566, 20.76404],
              [-156.07926, 20.64397],
              [-156.41445, 20.57241],
              [-156.58673, 20.783],
              [-156.70167, 20.8643],
              [-156.71055, 20.92676],
              [-156.61258, 21.01249],
              [-156.25711, 20.91745],
              [-155.99566, 20.76404],
            ],
          ],
          [
            [
              [-156.75824, 21.17684],
              [-156.78933, 21.06873],
              [-157.32521, 21.09777],
              [-157.25027, 21.21958],
              [-156.75824, 21.17684],
            ],
          ],
          [
            [
              [-158.0252, 21.71696],
              [-157.94161, 21.65272],
              [-157.65283, 21.32217],
              [-157.70703, 21.26442],
              [-157.7786, 21.27729],
              [-158.12667, 21.31244],
              [-158.2538, 21.53919],
              [-158.29265, 21.57912],
              [-158.0252, 21.71696],
            ],
          ],
          [
            [
              [-159.36569, 22.21494],
              [-159.34512, 21.982],
              [-159.46372, 21.88299],
              [-159.80051, 22.06533],
              [-159.74877, 22.1382],
              [-159.5962, 22.23618],
              [-159.36569, 22.21494],
            ],
          ],
          [
            [
              [-166.46779212142462, 60.384169826897754],
              [-165.67442969466364, 60.29360687930625],
              [-165.57916419173358, 59.90998688418753],
              [-166.19277014876727, 59.75444082298899],
              [-166.84833736882197, 59.941406155020985],
              [-167.45527706609008, 60.21306915957936],
              [-166.46779212142462, 60.384169826897754],
            ],
          ],
          [
            [
              [-153.22872941792113, 57.96896841087248],
              [-152.56479061583514, 57.901427313866996],
              [-152.1411472239064, 57.591058661522],
              [-153.00631405333692, 57.11584219016593],
              [-154.0050902984581, 56.734676825581076],
              [-154.51640275777004, 56.99274892844669],
              [-154.67099280497118, 57.46119578717253],
              [-153.7627795074415, 57.81657461204373],
              [-153.22872941792113, 57.96896841087248],
            ],
          ],
          [
            [
              [-140.98598761037601, 69.71199839952635],
              [-140.986, 69.712],
              [-140.9925, 66.00003],
              [-140.99778, 60.30639],
              [-140.013, 60.27682],
              [-139.039, 60],
              [-138.34089, 59.56211],
              [-137.4525, 58.905],
              [-136.47972, 59.46389],
              [-135.47583, 59.78778],
              [-134.945, 59.2705600000001],
              [-134.27111, 58.86111],
              [-133.35556, 58.41028],
              [-132.73042, 57.69289],
              [-131.70781, 56.55212],
              [-130.00778, 55.91583],
              [-129.98, 55.285],
              [-130.53611, 54.80278],
              [-130.53610895273684, 54.80275447679924],
              [-130.5361101894673, 54.8027534043494],
              [-131.08581823797215, 55.17890615500204],
              [-131.9672114671423, 55.497775580459006],
              [-132.2500107428595, 56.3699962428974],
              [-133.53918108435641, 57.17888743756214],
              [-134.07806292029608, 58.12306753196691],
              [-135.0382110322791, 58.18771474876394],
              [-136.62806230995471, 58.21220937767043],
              [-137.800006279686, 58.49999542910376],
              [-139.867787041413, 59.53776154238915],
              [-140.825273817133, 59.727517401765056],
              [-142.57444353556446, 60.08444651960497],
              [-143.9588809948799, 59.999180406323376],
              [-145.92555681682788, 60.45860972761426],
              [-147.11437394914665, 60.884656073644635],
              [-148.22430620012761, 60.67298940697714],
              [-148.01806555885082, 59.97832896589364],
              [-148.57082251686086, 59.914172675203304],
              [-149.72785783587585, 59.70565827090553],
              [-150.60824337461642, 59.368211168039466],
              [-151.7163927886833, 59.15582103131993],
              [-151.85943315326722, 59.744984035879554],
              [-151.40971900124717, 60.72580272077937],
              [-150.3469414947325, 61.03358755150987],
              [-150.62111080625704, 61.2844249538544],
              [-151.89583919981683, 60.727197984451266],
              [-152.57832984109558, 60.061657212964235],
              [-154.01917212625764, 59.35027944603428],
              [-153.28751135965317, 58.86472768821977],
              [-154.23249243875847, 58.14637360293051],
              [-155.3074914215102, 57.727794501366304],
              [-156.30833472392305, 57.422774359763594],
              [-156.55609737854638, 56.97998484967064],
              [-158.11721655986779, 56.46360809999419],
              [-158.43332129619714, 55.99415355083852],
              [-159.60332739971741, 55.56668610292013],
              [-160.28971961163427, 55.643580634170576],
              [-161.22304765525777, 55.364734605523495],
              [-162.23776607974105, 55.02418691672011],
              [-163.06944658104638, 54.68973704692712],
              [-164.78556922102717, 54.40417308208214],
              [-164.94222632552007, 54.57222483989534],
              [-163.84833960676565, 55.03943146424609],
              [-162.87000139061595, 55.34804311789321],
              [-161.80417497459607, 55.89498647727038],
              [-160.5636047027812, 56.00805451112501],
              [-160.07055986228448, 56.41805532492873],
              [-158.6844429189195, 57.01667511659787],
              [-158.46109737855403, 57.21692129172885],
              [-157.72277035218391, 57.57000051536306],
              [-157.55027442119362, 58.328326321030204],
              [-157.04167497457698, 58.91888458926172],
              [-158.19473120830554, 58.61580231386978],
              [-158.51721798402303, 58.78778148053732],
              [-159.0586061269288, 58.42418610293163],
              [-159.71166704001737, 58.93139028587632],
              [-159.98128882550017, 58.572549140041644],
              [-160.3552711659965, 59.07112335879361],
              [-161.3550034251151, 58.670837714260756],
              [-161.96889360252632, 58.67166453717738],
              [-162.05498653872465, 59.26692536074745],
              [-161.8741707021354, 59.63362132429057],
              [-162.51805904849212, 59.98972361921386],
              [-163.8183414378202, 59.79805573184336],
              [-164.66221757714652, 60.26748444278263],
              [-165.3463877024748, 60.50749563256238],
              [-165.3508318756519, 61.073895168697504],
              [-166.12137915755602, 61.50001902937623],
              [-165.73445187077058, 62.074996853271784],
              [-164.9191786367179, 62.63307648380794],
              [-164.56250790103934, 63.14637848576302],
              [-163.75333248599708, 63.21944896102377],
              [-163.06722449445786, 63.05945872664802],
              [-162.26055538638175, 63.54193573674115],
              [-161.53444983624863, 63.455816962326764],
              [-160.7725066803211, 63.766108100023246],
              [-160.9583351308426, 64.22279857040274],
              [-161.51806840721218, 64.40278758407527],
              [-160.77777767641481, 64.78860382756642],
              [-161.39192623598765, 64.77723501246231],
              [-162.4530500966689, 64.55944468856819],
              [-162.75778601789415, 64.33860545516876],
              [-163.54639421288428, 64.5591604681905],
              [-164.96082984114514, 64.44694509546883],
              [-166.42528825586447, 64.68667206487066],
              [-166.8450042389391, 65.08889557561452],
              [-168.11056006576715, 65.66999705673675],
              [-166.70527116602193, 66.08831777613938],
              [-164.47470964257548, 66.5766600612975],
              [-163.65251176659564, 66.5766600612975],
              [-163.78860165103623, 66.07720734319668],
              [-161.67777442121013, 66.11611969671242],
              [-162.48971452538004, 66.73556509059512],
              [-163.71971696679117, 67.11639455837008],
              [-164.4309913808565, 67.61633820257777],
              [-165.39028683170673, 68.04277212185025],
              [-166.76444068099605, 68.35887685817966],
              [-166.20470740462667, 68.88303091091615],
              [-164.43081051334346, 68.91553538682774],
              [-163.1686136546145, 69.37111481391287],
              [-162.930566169262, 69.85806183539927],
              [-161.90889726463556, 70.33332998318764],
              [-160.93479651593367, 70.44768992784958],
              [-159.03917578838713, 70.89164215766891],
              [-158.11972286683394, 70.82472117785102],
              [-156.58082455139808, 71.35776357694175],
              [-155.06779029032427, 71.14777639432367],
              [-154.3441652089412, 70.69640859647018],
              [-153.9000062733926, 70.88998851183567],
              [-152.21000606993528, 70.82999217394485],
              [-152.27000240782613, 70.60000621202983],
              [-150.73999243874448, 70.43001658800569],
              [-149.7200030181675, 70.53001048449045],
              [-147.61336157935705, 70.2140349392418],
              [-145.68998980022533, 70.12000967068673],
              [-144.9200109590764, 69.98999176704046],
              [-143.58944618042523, 70.15251414659832],
              [-142.07251034871348, 69.85193817817265],
              [-140.98598752156073, 69.71199839952635],
              [-140.98598761037601, 69.71199839952635],
            ],
          ],
          [
            [
              [-171.73165686753944, 63.782515367275934],
              [-171.1144335602453, 63.59219106714495],
              [-170.4911124339407, 63.694975490973505],
              [-169.6825054596536, 63.43111562769119],
              [-168.6894394603007, 63.297506212000556],
              [-168.77194088445466, 63.18859813094544],
              [-169.5294398672051, 62.97693146427792],
              [-170.29055620021595, 63.194437567794424],
              [-170.67138566799093, 63.3758218451389],
              [-171.55306311753873, 63.317789211675105],
              [-171.79111060289122, 63.40584585230046],
              [-171.73165686753944, 63.782515367275934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Canada",
        sov_a3: "CAN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Canada",
        adm0_a3: "CAN",
        geou_dif: 0,
        geounit: "Canada",
        gu_a3: "CAN",
        su_dif: 0,
        subunit: "Canada",
        su_a3: "CAN",
        brk_diff: 0,
        name: "Canada",
        name_long: "Canada",
        brk_a3: "CAN",
        brk_name: "Canada",
        brk_group: null,
        abbrev: "Can.",
        postal: "CA",
        formal_en: "Canada",
        formal_fr: null,
        name_ciawf: "Canada",
        note_adm0: null,
        note_brk: null,
        name_sort: "Canada",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 37589262,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 1736425,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "CA",
        iso_a2: "CA",
        iso_a2_eh: "CA",
        iso_a3: "CAN",
        iso_a3_eh: "CAN",
        iso_n3: "124",
        iso_n3_eh: "124",
        un_a3: "124",
        wb_a2: "CA",
        wb_a3: "CAN",
        woe_id: 23424775,
        woe_id_eh: 23424775,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CAN",
        adm0_diff: null,
        adm0_tlc: "CAN",
        adm0_a3_us: "CAN",
        adm0_a3_fr: "CAN",
        adm0_a3_ru: "CAN",
        adm0_a3_es: "CAN",
        adm0_a3_cn: "CAN",
        adm0_a3_tw: "CAN",
        adm0_a3_in: "CAN",
        adm0_a3_np: "CAN",
        adm0_a3_pk: "CAN",
        adm0_a3_de: "CAN",
        adm0_a3_gb: "CAN",
        adm0_a3_br: "CAN",
        adm0_a3_il: "CAN",
        adm0_a3_ps: "CAN",
        adm0_a3_sa: "CAN",
        adm0_a3_eg: "CAN",
        adm0_a3_ma: "CAN",
        adm0_a3_pt: "CAN",
        adm0_a3_ar: "CAN",
        adm0_a3_jp: "CAN",
        adm0_a3_ko: "CAN",
        adm0_a3_vn: "CAN",
        adm0_a3_tr: "CAN",
        adm0_a3_id: "CAN",
        adm0_a3_pl: "CAN",
        adm0_a3_gr: "CAN",
        adm0_a3_it: "CAN",
        adm0_a3_nl: "CAN",
        adm0_a3_se: "CAN",
        adm0_a3_bd: "CAN",
        adm0_a3_ua: "CAN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: -101.9107,
        label_y: 60.324287,
        ne_id: 1159320467,
        wikidataid: "Q16",
        name_ar: "كندا",
        name_bn: "কানাডা",
        name_de: "Kanada",
        name_en: "Canada",
        name_es: "Canadá",
        name_fa: "کانادا",
        name_fr: "Canada",
        name_el: "Καναδάς",
        name_he: "קנדה",
        name_hi: "कनाडा",
        name_hu: "Kanada",
        name_id: "Kanada",
        name_it: "Canada",
        name_ja: "カナダ",
        name_ko: "캐나다",
        name_nl: "Canada",
        name_pl: "Kanada",
        name_pt: "Canadá",
        name_ru: "Канада",
        name_sv: "Kanada",
        name_tr: "Kanada",
        name_uk: "Канада",
        name_ur: "کینیڈا",
        name_vi: "Canada",
        name_zh: "加拿大",
        name_zht: "加拿大",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CAN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-122.84, 49.000000000000114],
              [-122.97421, 49.00253777777778],
              [-124.91024, 49.98456],
              [-125.62461, 50.41656],
              [-127.43561, 50.83061],
              [-127.99276, 51.71583],
              [-127.85032, 52.32961],
              [-129.12979, 52.75538],
              [-129.30523, 53.56159],
              [-130.51497, 54.28757],
              [-130.53610895273684, 54.80275447679924],
              [-130.53611, 54.80278],
              [-129.98, 55.285],
              [-130.00778, 55.91583],
              [-131.70781, 56.55212],
              [-132.73042, 57.69289],
              [-133.35556, 58.41028],
              [-134.27111, 58.86111],
              [-134.945, 59.2705600000001],
              [-135.47583, 59.78778],
              [-136.47972, 59.46389],
              [-137.4525, 58.905],
              [-138.34089, 59.56211],
              [-139.039, 60],
              [-140.013, 60.27682],
              [-140.99778, 60.30639],
              [-140.9925, 66.00003],
              [-140.986, 69.712],
              [-140.98598761037601, 69.71199839952635],
              [-139.12052, 69.47102],
              [-137.54636, 68.99002],
              [-136.50358, 68.89804],
              [-135.62576, 69.31512],
              [-134.41464, 69.62743],
              [-132.92925, 69.50534],
              [-131.43136, 69.94451],
              [-129.79471, 70.19369],
              [-129.10773, 69.77927],
              [-128.36156, 70.01286],
              [-128.13817, 70.48384],
              [-127.44712, 70.37721],
              [-125.75632, 69.48058],
              [-124.42483, 70.1584],
              [-124.28968, 69.39969],
              [-123.06108, 69.56372],
              [-122.6835, 69.85553],
              [-121.47226, 69.79778],
              [-119.94288, 69.37786],
              [-117.60268, 69.01128],
              [-116.22643, 68.84151],
              [-115.2469, 68.90591],
              [-113.89794, 68.3989],
              [-115.30489, 67.90261],
              [-113.49727, 67.68815],
              [-110.798, 67.80612],
              [-109.94619, 67.98104],
              [-108.8802, 67.38144],
              [-107.79239, 67.88736],
              [-108.81299, 68.31164],
              [-108.16721, 68.65392],
              [-106.95, 68.7],
              [-106.15, 68.8],
              [-105.34282, 68.56122],
              [-104.33791, 68.018],
              [-103.22115, 68.09775],
              [-101.45433, 67.64689],
              [-99.90195, 67.80566],
              [-98.4432, 67.78165],
              [-98.5586, 68.40394],
              [-97.66948, 68.57864],
              [-96.11991, 68.23939],
              [-96.12588, 67.29338],
              [-95.48943, 68.0907],
              [-94.685, 68.06383],
              [-94.23282, 69.06903],
              [-95.30408, 69.68571],
              [-96.47131, 70.08976],
              [-96.39115, 71.19482],
              [-95.2088, 71.92053],
              [-93.88997, 71.76015],
              [-92.87818, 71.31869],
              [-91.51964, 70.19129],
              [-92.40692, 69.69997],
              [-90.5471, 69.49766],
              [-90.55151, 68.47499],
              [-89.21515, 69.25873],
              [-88.01966, 68.61508],
              [-88.31749, 67.87338],
              [-87.35017, 67.19872],
              [-86.30607, 67.92146],
              [-85.57664, 68.78456],
              [-85.52197, 69.88211],
              [-84.10081, 69.80539],
              [-82.62258, 69.65826],
              [-81.28043, 69.16202],
              [-81.2202, 68.66567],
              [-81.96436, 68.13253],
              [-81.25928, 67.59716],
              [-81.38653, 67.11078],
              [-83.34456, 66.41154],
              [-84.73542, 66.2573],
              [-85.76943, 66.55833],
              [-86.0676, 66.05625],
              [-87.03143, 65.21297],
              [-87.32324, 64.77563],
              [-88.48296, 64.09897],
              [-89.91444, 64.03273],
              [-90.70398, 63.61017],
              [-90.77004, 62.96021],
              [-91.93342, 62.83508],
              [-93.15698, 62.02469],
              [-94.24153, 60.89865],
              [-94.62931, 60.11021],
              [-94.6846, 58.94882],
              [-93.21502, 58.78212],
              [-92.76462, 57.84571],
              [-92.29703, 57.08709],
              [-90.89769, 57.28468],
              [-89.03953, 56.85172],
              [-88.03978, 56.47162],
              [-87.32421, 55.99914],
              [-86.07121, 55.72383],
              [-85.01181, 55.3026],
              [-83.36055, 55.24489],
              [-82.27285, 55.14832],
              [-82.4362, 54.28227],
              [-82.12502, 53.27703],
              [-81.40075, 52.15788],
              [-79.91289, 51.20842],
              [-79.14301, 51.53393],
              [-78.60191, 52.56208],
              [-79.12421, 54.14145],
              [-79.82958, 54.66772],
              [-78.22874, 55.13645],
              [-77.0956, 55.83741],
              [-76.54137, 56.53423],
              [-76.62319, 57.20263],
              [-77.30226, 58.05209],
              [-78.51688, 58.80458],
              [-77.33676, 59.85261],
              [-77.77272, 60.75788],
              [-78.10687, 62.31964],
              [-77.41067, 62.55053],
              [-75.69621, 62.2784],
              [-74.6682, 62.18111],
              [-73.83988, 62.4438],
              [-72.90853, 62.10507],
              [-71.67708, 61.52535],
              [-71.37369, 61.13717],
              [-69.59042, 61.06141],
              [-69.62033, 60.22125],
              [-69.2879, 58.95736],
              [-68.37455, 58.80106],
              [-67.64976, 58.21206],
              [-66.20178, 58.76731],
              [-65.24517, 59.87071],
              [-64.58352, 60.33558],
              [-63.80475, 59.4426],
              [-62.50236, 58.16708],
              [-61.39655, 56.96745],
              [-61.79866, 56.33945],
              [-60.46853, 55.77548],
              [-59.56962, 55.20407],
              [-57.97508, 54.94549],
              [-57.3332, 54.6265],
              [-56.93689, 53.78032],
              [-56.15811, 53.64749],
              [-55.75632, 53.27036],
              [-55.68338, 52.14664],
              [-56.40916, 51.7707],
              [-57.12691, 51.41972],
              [-58.77482, 51.0643],
              [-60.03309, 50.24277],
              [-61.72366, 50.08046],
              [-63.86251, 50.29099],
              [-65.36331, 50.2982],
              [-66.39905, 50.22897],
              [-67.23631, 49.51156],
              [-68.51114, 49.06836],
              [-69.95362, 47.74488],
              [-71.10458, 46.82171],
              [-70.25522, 46.98606],
              [-68.65, 48.3],
              [-66.55243, 49.1331],
              [-65.05626, 49.23278],
              [-64.17099, 48.74248],
              [-65.11545, 48.07085],
              [-64.79854, 46.99297],
              [-64.47219, 46.23849],
              [-63.17329, 45.73902],
              [-61.52072, 45.88377],
              [-60.51815, 47.00793],
              [-60.4486, 46.28264],
              [-59.80287, 45.9204],
              [-61.03988, 45.26525],
              [-63.25471, 44.67014],
              [-64.24656, 44.26553],
              [-65.36406, 43.54523],
              [-66.1234, 43.61867],
              [-66.16173, 44.46512],
              [-64.42549, 45.29204],
              [-66.02605, 45.25931],
              [-67.13741, 45.13753],
              [-67.79134, 45.70281],
              [-67.79046, 47.06636],
              [-68.23444, 47.35486],
              [-68.905, 47.185],
              [-69.237216, 47.447781],
              [-69.99997, 46.69307],
              [-70.305, 45.915],
              [-70.66, 45.46],
              [-71.08482, 45.30524000000014],
              [-71.405, 45.255],
              [-71.50506, 45.0082],
              [-73.34783, 45.00738],
              [-74.867, 45.00048],
              [-75.31821, 44.81645],
              [-76.375, 44.09631],
              [-76.5, 44.01845889375865],
              [-76.82003414580558, 43.628784288093755],
              [-77.7378850979577, 43.62905558936328],
              [-78.72027991404235, 43.62508942318493],
              [-79.17167355011186, 43.46633942318426],
              [-79.01, 43.27],
              [-78.92, 42.965],
              [-78.93936214874375, 42.86361135514798],
              [-80.24744767934794, 42.36619985612255],
              [-81.27774654816716, 42.209025987306816],
              [-82.4392777167916, 41.675105088867326],
              [-82.69008928092023, 41.675105088867326],
              [-83.029810146807, 41.83279572200598],
              [-83.14199968131264, 41.975681057292874],
              [-83.12, 42.08],
              [-82.9, 42.43],
              [-82.43, 42.98],
              [-82.13764238150395, 43.57108755143997],
              [-82.33776312543114, 44.44],
              [-82.55092464875821, 45.34751658790543],
              [-83.59285071484311, 45.81689362241252],
              [-83.46955074739469, 45.994686387712534],
              [-83.61613094759059, 46.116926988299014],
              [-83.89076534700574, 46.116926988299014],
              [-84.0918512641615, 46.27541860613826],
              [-84.1421195136734, 46.51222585711571],
              [-84.3367, 46.40877],
              [-84.6049, 46.4396],
              [-84.54374874544584, 46.538684190449146],
              [-84.77923824739992, 46.63710195574902],
              [-84.8760798815149, 46.90008331968238],
              [-85.65236324740341, 47.22021881773051],
              [-86.46199083122826, 47.553338019392],
              [-87.43979262330028, 47.94],
              [-88.37811418328671, 48.302917588893706],
              [-89.27291744663665, 48.01980825458281],
              [-89.6, 48.010000000000105],
              [-90.83, 48.27],
              [-91.64, 48.14],
              [-92.61, 48.45],
              [-93.63087, 48.60926],
              [-94.32914, 48.67074],
              [-94.64, 48.84],
              [-94.81758, 49.38905],
              [-95.15609, 49.38425],
              [-95.15906950917206, 49],
              [-97.2287200000048, 49.0007],
              [-100.65, 49.000000000000114],
              [-104.04826, 48.99986],
              [-107.05, 49],
              [-110.05, 49],
              [-113, 49],
              [-116.04818, 49],
              [-117.03121, 49],
              [-120, 49.000000000000114],
              [-122.84, 49.000000000000114],
            ],
          ],
          [
            [
              [-83.99367, 62.4528],
              [-83.25048, 62.91409],
              [-81.87699, 62.90458],
              [-81.89825, 62.7108],
              [-83.06857, 62.15922],
              [-83.77462, 62.18231],
              [-83.99367, 62.4528],
            ],
          ],
          [
            [
              [-79.77583312988281, 72.8029022216797],
              [-80.87609863281251, 73.33318328857422],
              [-80.83388519287111, 73.69318389892578],
              [-80.35305, 73.75971984863281],
              [-78.06443786621094, 73.65193176269531],
              [-76.34, 73.10268498995305],
              [-76.25140380859375, 72.82638549804688],
              [-77.31443786621094, 72.85554504394531],
              [-78.39167022705078, 72.87665557861328],
              [-79.4862518310547, 72.74220275878906],
              [-79.77583312988281, 72.8029022216797],
            ],
          ],
          [
            [
              [-80.315395, 62.085565],
              [-79.92939, 62.3856],
              [-79.52002, 62.36371],
              [-79.26582, 62.158675],
              [-79.65752, 61.63308],
              [-80.09956, 61.7181],
              [-80.36215, 62.01649],
              [-80.315395, 62.085565],
            ],
          ],
          [
            [
              [-93.61275590694046, 74.97999726022438],
              [-94.15690873897391, 74.59234650338688],
              [-95.60868058956564, 74.66686391875176],
              [-96.82093217648455, 74.92762319609658],
              [-96.28858740922982, 75.37782827422338],
              [-94.85081987178917, 75.64721751576089],
              [-93.97774654821797, 75.29648956979595],
              [-93.61275590694046, 74.97999726022438],
            ],
          ],
          [
            [
              [-93.84000301794399, 77.51999726023455],
              [-94.29560828324529, 77.49134267852868],
              [-96.16965410031007, 77.55511139597685],
              [-96.43630449093614, 77.83462921824362],
              [-94.42257727738641, 77.820004787905],
              [-93.7206562975659, 77.63433136668031],
              [-93.84000301794399, 77.51999726023455],
            ],
          ],
          [
            [
              [-96.75439876990876, 78.76581268992702],
              [-95.5592779202946, 78.41831452098033],
              [-95.83029496944934, 78.05694122996324],
              [-97.30984290239799, 77.85059723582181],
              [-98.12428931353404, 78.08285696075761],
              [-98.55286780474668, 78.45810537384507],
              [-98.63198442258553, 78.87193024363837],
              [-97.33723141151266, 78.83198436147676],
              [-96.75439876990876, 78.76581268992702],
            ],
          ],
          [
            [
              [-88.15035030796028, 74.39230703398503],
              [-89.7647220527584, 74.51555532500116],
              [-92.42244096552946, 74.83775788034099],
              [-92.76828548864282, 75.38681997344214],
              [-92.88990597204175, 75.88265534128267],
              [-93.89382402217599, 76.31924367950056],
              [-95.9624574450358, 76.4413809272224],
              [-97.1213789538295, 76.7510777859476],
              [-96.74512285031237, 77.16138865834507],
              [-94.68408586299944, 77.09787832305837],
              [-93.57392106807313, 76.77629588490605],
              [-91.6050231595366, 76.7785179714946],
              [-90.7418458727493, 76.44959747995681],
              [-90.96966142450802, 76.07401317005947],
              [-89.82223792189926, 75.84777374948565],
              [-89.18708289259985, 75.61016551380762],
              [-87.83827633334965, 75.56618886992725],
              [-86.37919226758864, 75.4824213731821],
              [-84.78962521029058, 75.69920400664653],
              [-82.75344458691006, 75.78431509063124],
              [-81.12853084992436, 75.71398346628199],
              [-80.05751095245915, 75.33684886341591],
              [-79.83393286814837, 74.92312734648716],
              [-80.45777075877587, 74.65730377877777],
              [-81.94884253612557, 74.44245901152432],
              [-83.22889360221143, 74.56402781849094],
              [-86.09745235873332, 74.41003205026117],
              [-88.15035030796028, 74.39230703398503],
            ],
          ],
          [
            [
              [-111.26444332563088, 78.15295604116154],
              [-109.85445187054711, 77.99632477488488],
              [-110.18693803591302, 77.69701487905034],
              [-112.0511911690585, 77.4092288276169],
              [-113.53427893761912, 77.73220652944111],
              [-112.7245867582539, 78.05105011668196],
              [-111.26444332563088, 78.15295604116154],
            ],
          ],
          [
            [
              [-110.96366065147602, 78.8044408230652],
              [-109.6631457182026, 78.60197256134565],
              [-110.88131425661892, 78.40691986765997],
              [-112.54209143761516, 78.4079017198735],
              [-112.52589087609164, 78.55055451121522],
              [-111.5000103422334, 78.8499935981305],
              [-110.96366065147602, 78.8044408230652],
            ],
          ],
          [
            [
              [-55.600218268442056, 51.31707469339794],
              [-56.13403581401709, 50.68700979267928],
              [-56.795881720595276, 49.81230866149089],
              [-56.14310502788433, 50.15011749938286],
              [-55.471492275603, 49.93581533466846],
              [-55.82240108908096, 49.58712860777905],
              [-54.935142584845636, 49.3130109726868],
              [-54.473775397343786, 49.556691189159125],
              [-53.47654944519137, 49.24913890237404],
              [-53.786013759971254, 48.516780503933624],
              [-53.08613399922626, 48.68780365660358],
              [-52.958648240762216, 48.15716421161447],
              [-52.64809872090421, 47.53554840757552],
              [-53.069158291218386, 46.65549876564492],
              [-53.521456264853, 46.61829173439477],
              [-54.17893551290251, 46.80706574155698],
              [-53.9618686590605, 47.62520701760193],
              [-54.24048214376214, 47.752279364607645],
              [-55.40077307801157, 46.884993801453135],
              [-55.99748084168583, 46.919720363953275],
              [-55.29121904155279, 47.38956248635099],
              [-56.250798712780586, 47.632545070987376],
              [-57.32522925477708, 47.57280711525797],
              [-59.26601518414682, 47.60334788674247],
              [-59.419494188053676, 47.899453843774886],
              [-58.79658647320744, 48.25152537697942],
              [-59.23162451845657, 48.52318838153781],
              [-58.3918049790652, 49.12558055276418],
              [-57.35868974468606, 50.71827403421587],
              [-56.738650071832026, 51.28743825947855],
              [-55.87097693543532, 51.63209422464921],
              [-55.40697424988659, 51.5882726100657],
              [-55.600218268442056, 51.31707469339794],
            ],
          ],
          [
            [
              [-83.88262630891977, 65.10961782496354],
              [-82.78757687043883, 64.76669302027467],
              [-81.6420137193926, 64.45513580998697],
              [-81.55344031444432, 63.97960928003714],
              [-80.81736121287886, 64.057485663501],
              [-80.10345130076664, 63.72598135034862],
              [-80.99101986359572, 63.41124603947496],
              [-82.54717810741704, 63.65172231714521],
              [-83.10879757356511, 64.10187571883971],
              [-84.10041663281388, 63.569711819098],
              [-85.52340471061905, 63.052379055424055],
              [-85.8667687649824, 63.63725291610349],
              [-87.22198320183678, 63.54123810490519],
              [-86.35275977247133, 64.0358332383707],
              [-86.2248864407651, 64.82291697860823],
              [-85.88384782585486, 65.7387783881171],
              [-85.1613079495499, 65.6572846543928],
              [-84.97576371940592, 65.21751821558898],
              [-84.4640120104195, 65.37177236598022],
              [-83.88262630891977, 65.10961782496354],
            ],
          ],
          [
            [
              [-78.77063859731078, 72.35217316353418],
              [-77.8246239895596, 72.74961660429098],
              [-75.60584469267573, 72.2436784939374],
              [-74.228616095665, 71.76714427355789],
              [-74.09914079455771, 71.33084015571758],
              [-72.24222571479768, 71.55692454699452],
              [-71.20001542833518, 70.92001251899718],
              [-68.7860542466849, 70.52502370877427],
              [-67.91497046575694, 70.12194753689765],
              [-66.9690333726542, 69.18608734809182],
              [-68.8051228502006, 68.72019847276444],
              [-66.4498660956339, 68.06716339789203],
              [-64.86231441919524, 67.84753856065159],
              [-63.424934454996794, 66.92847321234059],
              [-61.851981370680605, 66.86212067327783],
              [-62.16317684594226, 66.16025136988962],
              [-63.918444383384184, 64.9986685248329],
              [-65.14886023625368, 65.42603261988667],
              [-66.72121904159852, 66.38804108343219],
              [-68.015016038674, 66.26272573512439],
              [-68.1412874009792, 65.68978913030439],
              [-67.08964616562342, 65.10845510523696],
              [-65.73208045109976, 64.64840566675856],
              [-65.32016760930125, 64.38273712834605],
              [-64.66940629744968, 63.392926744227495],
              [-65.01380388045888, 62.67418508569598],
              [-66.27504472519048, 62.94509878198612],
              [-68.7831862046927, 63.74567007105183],
              [-67.36968075221309, 62.88396556258484],
              [-66.32829728866726, 62.28007477482201],
              [-66.16556820338015, 61.93089712182582],
              [-68.87736650254465, 62.330149237712824],
              [-71.02343705919385, 62.91070811629588],
              [-72.23537858751902, 63.39783600529522],
              [-71.88627844917127, 63.67998932560887],
              [-73.37830624051838, 64.19396312118384],
              [-74.83441891142263, 64.6790756293238],
              [-74.81850257027673, 64.38909332951793],
              [-77.70997982452008, 64.22954234481678],
              [-78.5559488593542, 64.57290639918013],
              [-77.89728105336198, 65.30919220647475],
              [-76.01827429879717, 65.32696889918314],
              [-73.95979529488268, 65.45476471624094],
              [-74.29388342964964, 65.81177134872938],
              [-73.94491248238262, 66.31057811142666],
              [-72.65116716173942, 67.28457550726391],
              [-72.92605994331605, 67.72692576768235],
              [-73.31161780464572, 68.06943716091287],
              [-74.84330725777684, 68.55462718370127],
              [-76.86910091826672, 68.89473562283025],
              [-76.22864905465738, 69.14776927354741],
              [-77.28736996123715, 69.76954010688321],
              [-78.1686339993266, 69.82648753526887],
              [-78.95724219431673, 70.16688019477543],
              [-79.49245500356366, 69.87180776638884],
              [-81.30547095409176, 69.74318512641436],
              [-84.94470618359851, 69.96663401964442],
              [-87.06000342481789, 70.26000112576538],
              [-88.68171322300148, 70.4107412787608],
              [-89.51341956252303, 70.76203766548095],
              [-88.46772111688082, 71.21818553332132],
              [-89.88815121128755, 71.22255219184997],
              [-90.20516028518205, 72.23507436796079],
              [-89.436576707705, 73.12946421985238],
              [-88.40824154331287, 73.53788890247121],
              [-85.82615108920098, 73.80381582304518],
              [-86.56217851433412, 73.15744700793844],
              [-85.77437130404454, 72.53412588163387],
              [-84.85011247428822, 73.34027822538708],
              [-82.31559017610101, 73.7509508328106],
              [-80.60008765330768, 72.71654368762417],
              [-80.74894161652443, 72.06190664335072],
              [-78.77063859731078, 72.35217316353418],
            ],
          ],
          [
            [
              [-94.50365759965237, 74.13490672473922],
              [-92.42001217321173, 74.1000251329422],
              [-90.50979285354263, 73.85673248971206],
              [-92.00396521682987, 72.96624420845852],
              [-93.19629553910026, 72.77199249947334],
              [-94.26904659704726, 72.02459625923599],
              [-95.40985551632266, 72.06188080513458],
              [-96.03374508338244, 72.94027680123183],
              [-96.01826799191102, 73.43742991809582],
              [-95.49579342322404, 73.86241689726417],
              [-94.50365759965237, 74.13490672473922],
            ],
          ],
          [
            [
              [-122.85492448615902, 76.11654287383568],
              [-122.85492529360326, 76.11654287383568],
              [-121.15753536032824, 76.86450755482828],
              [-119.1039389718211, 77.51221995717462],
              [-117.570130784966, 77.4983189968881],
              [-116.19858659550738, 77.6452867703262],
              [-116.33581336145845, 76.87696157501061],
              [-117.10605058476882, 76.53003184681911],
              [-118.04041215703819, 76.48117178008714],
              [-119.89931758688572, 76.053213406062],
              [-121.49999507712648, 75.90001862253276],
              [-122.85492448615902, 76.11654287383568],
            ],
          ],
          [
            [
              [-132.71000788443126, 54.04000931542356],
              [-131.74998958400334, 54.12000438090922],
              [-132.049480347351, 52.98462148702447],
              [-131.1790425218266, 52.180432847698285],
              [-131.57782954982298, 52.18237071390928],
              [-132.18042842677852, 52.639707139692405],
              [-132.54999243231384, 53.100014960332146],
              [-133.05461117875552, 53.411468817755406],
              [-133.2396644827927, 53.851080227262344],
              [-133.1800040417117, 54.169975490935315],
              [-132.71000788443126, 54.04000931542356],
            ],
          ],
          [
            [
              [-105.4922891914932, 79.30159393992916],
              [-103.52928239623795, 79.16534902619163],
              [-100.8251580472688, 78.80046173777872],
              [-100.0601918200522, 78.32475434031589],
              [-99.67093909381364, 77.90754466420744],
              [-101.30394019245301, 78.01898489044486],
              [-102.94980872273302, 78.34322866486023],
              [-105.17613277873151, 78.3803323432458],
              [-104.21042945027713, 78.67742015249176],
              [-105.41958045125853, 78.91833567983649],
              [-105.4922891914932, 79.30159393992916],
            ],
          ],
          [
            [
              [-123.51000158755119, 48.51001089130341],
              [-124.01289078839955, 48.37084625914139],
              [-125.65501277733838, 48.8250045843385],
              [-125.95499446679275, 49.17999583596759],
              [-126.85000443587185, 49.53000031188043],
              [-127.02999344954443, 49.81499583597008],
              [-128.0593363043662, 49.9949590114266],
              [-128.44458410710214, 50.539137681676095],
              [-128.35841365625546, 50.77064809834371],
              [-127.30858109602994, 50.552573554071955],
              [-126.69500097721235, 50.400903225295394],
              [-125.7550066738232, 50.29501821552935],
              [-125.4150015875588, 49.95000051533259],
              [-124.92076818911934, 49.475274970083376],
              [-123.92250870832106, 49.06248362893581],
              [-123.51000158755119, 48.51001089130341],
            ],
          ],
          [
            [
              [-121.53788, 74.44893],
              [-120.10978, 74.24135],
              [-117.55564, 74.18577],
              [-116.58442, 73.89607],
              [-115.51081, 73.47519],
              [-116.76794, 73.22292],
              [-119.22, 72.52],
              [-120.46, 71.82],
              [-120.46, 71.38360179308756],
              [-123.09219, 70.90164],
              [-123.62, 71.34],
              [-125.92894873747338, 71.86868846301138],
              [-125.5, 72.29226081179502],
              [-124.80729, 73.02256],
              [-123.94, 73.68000000000012],
              [-124.91775, 74.29275000000013],
              [-121.53788, 74.44893],
            ],
          ],
          [
            [
              [-107.81943, 75.84552],
              [-106.92893, 76.01282],
              [-105.881, 75.9694],
              [-105.70498, 75.47951],
              [-106.31347, 75.00527],
              [-109.7, 74.85],
              [-112.22307, 74.41696],
              [-113.74381, 74.39427],
              [-113.87135, 74.72029],
              [-111.79421, 75.1625],
              [-116.31221, 75.04343],
              [-117.7104, 75.2222],
              [-116.34602, 76.19903],
              [-115.40487, 76.47887],
              [-112.59056, 76.14134],
              [-110.81422, 75.54919],
              [-109.0671, 75.47321],
              [-110.49726, 76.42982],
              [-109.5811, 76.79417],
              [-108.54859, 76.67832],
              [-108.21141, 76.20168],
              [-107.81943, 75.84552],
            ],
          ],
          [
            [
              [-106.52259, 73.07601],
              [-105.40246, 72.67259],
              [-104.77484, 71.6984000000001],
              [-104.4647599999999, 70.99297],
              [-102.78537, 70.49776],
              [-100.98078, 70.02432],
              [-101.08929, 69.58447000000012],
              [-102.73116, 69.50402],
              [-102.09329, 69.11962000000011],
              [-102.43024, 68.75282],
              [-104.24, 68.91],
              [-105.96, 69.18000000000012],
              [-107.12254, 69.11922],
              [-109, 68.78],
              [-111.53414887520017, 68.63005915681794],
              [-113.3132, 68.53554],
              [-113.85495999999989, 69.00744],
              [-115.22, 69.28],
              [-116.10794, 69.16821],
              [-117.34, 69.96],
              [-116.67473, 70.06655],
              [-115.13112, 70.2373],
              [-113.72141, 70.19237],
              [-112.4161, 70.36638],
              [-114.35, 70.6],
              [-116.48684, 70.52045],
              [-117.9048, 70.54056000000014],
              [-118.43238, 70.9092],
              [-116.11311, 71.30918],
              [-117.65568, 71.2952],
              [-119.40199, 71.55859],
              [-118.56267, 72.30785],
              [-117.86642, 72.70594],
              [-115.18909, 73.31459000000012],
              [-114.16717, 73.12145],
              [-114.66634, 72.65277],
              [-112.44102, 72.95540000000011],
              [-111.05039, 72.4504],
              [-109.92035, 72.96113],
              [-109.00654, 72.63335],
              [-108.18835, 71.65089],
              [-107.68599, 72.06548],
              [-108.39639, 73.08953],
              [-107.51645, 73.23598],
              [-106.52259, 73.07601],
            ],
          ],
          [
            [
              [-100.43836, 72.70588],
              [-101.54, 73.36],
              [-100.35642, 73.84389],
              [-99.16387, 73.63339],
              [-97.38, 73.76],
              [-97.12, 73.47],
              [-98.05359, 72.99052],
              [-96.54, 72.56],
              [-96.72, 71.66],
              [-98.35966, 71.27285],
              [-99.32286, 71.35639],
              [-100.01482, 71.73827],
              [-102.5, 72.51],
              [-102.48, 72.83],
              [-100.43836, 72.70588],
            ],
          ],
          [
            [
              [-106.6, 73.6],
              [-105.26, 73.64],
              [-104.5, 73.42],
              [-105.38, 72.76],
              [-106.94, 73.46],
              [-106.6, 73.6],
            ],
          ],
          [
            [
              [-98.5, 76.72],
              [-97.735585, 76.25656],
              [-97.704415, 75.74344],
              [-98.16, 75],
              [-99.80874, 74.89744],
              [-100.88366, 75.05736],
              [-100.86292, 75.64075],
              [-102.50209, 75.5638],
              [-102.56552, 76.3366],
              [-101.48973, 76.30537],
              [-99.98349, 76.64634],
              [-98.57699, 76.58859],
              [-98.5, 76.72],
            ],
          ],
          [
            [
              [-96.01644, 80.60233],
              [-95.32345, 80.90729],
              [-94.29843, 80.97727],
              [-94.73542, 81.20646],
              [-92.40984, 81.25739],
              [-91.13289, 80.72345],
              [-89.45, 80.50932203389831],
              [-87.81, 80.32],
              [-87.02, 79.66],
              [-85.81435, 79.3369],
              [-87.18756, 79.0393],
              [-89.03535, 78.28723],
              [-90.80436, 78.21533],
              [-92.87669, 78.34333],
              [-93.95116, 78.75099],
              [-93.93574, 79.11373],
              [-93.14524, 79.3801],
              [-94.974, 79.37248],
              [-96.07614, 79.70502],
              [-96.70972, 80.15777],
              [-96.01644, 80.60233],
            ],
          ],
          [
            [
              [-91.58702, 81.89429],
              [-90.1, 82.085],
              [-88.93227, 82.11751],
              [-86.97024, 82.27961],
              [-85.5, 82.65227345805702],
              [-84.260005, 82.6],
              [-83.18, 82.32],
              [-82.42, 82.86],
              [-81.1, 83.02],
              [-79.30664, 83.13056],
              [-76.25, 83.17205882352941],
              [-75.71878, 83.06404],
              [-72.83153, 83.23324],
              [-70.665765, 83.16978075838284],
              [-68.5, 83.10632151676572],
              [-65.82735, 83.02801],
              [-63.68, 82.9],
              [-61.85, 82.6286],
              [-61.89388, 82.36165],
              [-64.334, 81.92775],
              [-66.75342, 81.72527],
              [-67.65755, 81.50141],
              [-65.48031, 81.50657],
              [-67.84, 80.9],
              [-69.4697, 80.61683],
              [-71.18, 79.8],
              [-73.2428, 79.63415],
              [-73.88, 79.43016220480206],
              [-76.90773, 79.32309],
              [-75.52924, 79.19766],
              [-76.22046, 79.01907],
              [-75.39345, 78.52581],
              [-76.34354, 78.18296],
              [-77.88851, 77.89991],
              [-78.36269, 77.50859],
              [-79.75951, 77.20968],
              [-79.61965, 76.98336],
              [-77.91089, 77.022045],
              [-77.88911, 76.777955],
              [-80.56125, 76.17812],
              [-83.17439, 76.45403],
              [-86.11184, 76.29901],
              [-87.6, 76.42],
              [-89.49068, 76.47239],
              [-89.6161, 76.95213],
              [-87.76739, 77.17833],
              [-88.26, 77.9],
              [-87.65, 77.97022222222223],
              [-84.97634, 77.53873],
              [-86.34, 78.18],
              [-87.96192, 78.37181],
              [-87.15198, 78.75867],
              [-85.37868, 78.9969],
              [-85.09495, 79.34543],
              [-86.50734, 79.73624],
              [-86.93179, 80.25145],
              [-84.19844, 80.20836],
              [-83.40869565217389, 80.1],
              [-81.84823, 80.46442],
              [-84.1, 80.58],
              [-87.59895, 80.51627],
              [-89.36663, 80.85569],
              [-90.2, 81.26],
              [-91.36786, 81.5531],
              [-91.58702, 81.89429],
            ],
          ],
          [
            [
              [-75.21597, 67.44425],
              [-75.86588, 67.14886],
              [-76.98687, 67.09873],
              [-77.2364, 67.58809],
              [-76.81166, 68.14856],
              [-75.89521, 68.28721],
              [-75.1145, 68.01036],
              [-75.10333, 67.58202],
              [-75.21597, 67.44425],
            ],
          ],
          [
            [
              [-96.25740120380055, 69.49003035832177],
              [-95.64768120380054, 69.10769035832178],
              [-96.26952120380055, 68.75704035832177],
              [-97.61740120380055, 69.06003035832177],
              [-98.43180120380055, 68.95070035832177],
              [-99.79740120380055, 69.40003035832177],
              [-98.91740120380055, 69.71003035832177],
              [-98.21826120380055, 70.14354035832177],
              [-97.15740120380055, 69.86003035832177],
              [-96.55740120380055, 69.68003035832177],
              [-96.25740120380055, 69.49003035832177],
            ],
          ],
          [
            [
              [-64.51912, 49.87304],
              [-64.17322, 49.95718],
              [-62.85829, 49.70641],
              [-61.835585, 49.28855],
              [-61.806305, 49.10506],
              [-62.29318, 49.08717],
              [-63.58926, 49.40069],
              [-64.51912, 49.87304],
            ],
          ],
          [
            [
              [-64.01486, 47.03601],
              [-63.6645, 46.55001],
              [-62.9393, 46.41587],
              [-62.01208, 46.44314],
              [-62.50391, 46.03339],
              [-62.87433, 45.96818],
              [-64.1428, 46.39265],
              [-64.39261, 46.72747],
              [-64.01486, 47.03601],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Greenland",
        adm0_a3: "GRL",
        geou_dif: 0,
        geounit: "Greenland",
        gu_a3: "GRL",
        su_dif: 0,
        subunit: "Greenland",
        su_a3: "GRL",
        brk_diff: 0,
        name: "Greenland",
        name_long: "Greenland",
        brk_a3: "GRL",
        brk_name: "Greenland",
        brk_group: null,
        abbrev: "Grlnd.",
        postal: "GL",
        formal_en: "Greenland",
        formal_fr: null,
        name_ciawf: "Greenland",
        note_adm0: "Den.",
        note_brk: null,
        name_sort: "Greenland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 56225,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 3051,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "GL",
        iso_a2: "GL",
        iso_a2_eh: "GL",
        iso_a3: "GRL",
        iso_a3_eh: "GRL",
        iso_n3: "304",
        iso_n3_eh: "304",
        un_a3: "304",
        wb_a2: "GL",
        wb_a3: "GRL",
        woe_id: 23424828,
        woe_id_eh: 23424828,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GRL",
        adm0_diff: null,
        adm0_tlc: "GRL",
        adm0_a3_us: "GRL",
        adm0_a3_fr: "GRL",
        adm0_a3_ru: "GRL",
        adm0_a3_es: "GRL",
        adm0_a3_cn: "GRL",
        adm0_a3_tw: "GRL",
        adm0_a3_in: "GRL",
        adm0_a3_np: "GRL",
        adm0_a3_pk: "GRL",
        adm0_a3_de: "GRL",
        adm0_a3_gb: "GRL",
        adm0_a3_br: "GRL",
        adm0_a3_il: "GRL",
        adm0_a3_ps: "GRL",
        adm0_a3_sa: "GRL",
        adm0_a3_eg: "GRL",
        adm0_a3_ma: "GRL",
        adm0_a3_pt: "GRL",
        adm0_a3_ar: "GRL",
        adm0_a3_jp: "GRL",
        adm0_a3_ko: "GRL",
        adm0_a3_vn: "GRL",
        adm0_a3_tr: "GRL",
        adm0_a3_id: "GRL",
        adm0_a3_pl: "GRL",
        adm0_a3_gr: "GRL",
        adm0_a3_it: "GRL",
        adm0_a3_nl: "GRL",
        adm0_a3_se: "GRL",
        adm0_a3_bd: "GRL",
        adm0_a3_ua: "GRL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: -39.335251,
        label_y: 74.319387,
        ne_id: 1159320551,
        wikidataid: "Q223",
        name_ar: "جرينلاند",
        name_bn: "গ্রিনল্যান্ড",
        name_de: "Grönland",
        name_en: "Greenland",
        name_es: "Groenlandia",
        name_fa: "گرینلند",
        name_fr: "Groenland",
        name_el: "Γροιλανδία",
        name_he: "גרינלנד",
        name_hi: "ग्रीनलैण्ड",
        name_hu: "Grönland",
        name_id: "Greenland",
        name_it: "Groenlandia",
        name_ja: "グリーンランド",
        name_ko: "그린란드",
        name_nl: "Groenland",
        name_pl: "Grenlandia",
        name_pt: "Groenlândia",
        name_ru: "Гренландия",
        name_sv: "Grönland",
        name_tr: "Grönland",
        name_uk: "Гренландія",
        name_ur: "گرین لینڈ",
        name_vi: "Greenland",
        name_zh: "格陵兰",
        name_zht: "格陵蘭",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GRL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.76379, 82.62796],
            [-43.40644, 83.22516],
            [-39.89753, 83.18018],
            [-38.62214, 83.54905],
            [-35.08787, 83.64513],
            [-27.10046, 83.51966],
            [-20.84539, 82.72669],
            [-22.69182, 82.34165],
            [-26.51753, 82.29765],
            [-31.9, 82.2],
            [-31.39646, 82.02154],
            [-27.85666, 82.13178],
            [-24.84448, 81.78697],
            [-22.90328, 82.09317],
            [-22.07175, 81.73449],
            [-23.16961, 81.15271],
            [-20.62363, 81.52462],
            [-15.76818, 81.91245],
            [-12.77018, 81.71885],
            [-12.20855, 81.29154],
            [-16.28533, 80.58004],
            [-16.85, 80.35],
            [-20.04624, 80.17708],
            [-17.73035, 80.12912],
            [-18.9, 79.4],
            [-19.70499, 78.75128],
            [-19.67353, 77.63859],
            [-18.47285, 76.98565],
            [-20.03503, 76.94434],
            [-21.67944, 76.62795],
            [-19.83407, 76.09808],
            [-19.59896, 75.24838],
            [-20.66818, 75.15585],
            [-19.37281, 74.29561],
            [-21.59422, 74.22382],
            [-20.43454, 73.81713],
            [-20.76234, 73.46436],
            [-22.17221, 73.30955],
            [-23.56593, 73.30663],
            [-22.31311, 72.62928],
            [-22.29954, 72.18409],
            [-24.27834, 72.59788],
            [-24.79296, 72.3302],
            [-23.44296, 72.08016],
            [-22.13281, 71.46898],
            [-21.75356, 70.66369],
            [-23.53603, 70.471],
            [-24.30702, 70.85649],
            [-25.54341, 71.43094],
            [-25.20135, 70.75226],
            [-26.36276, 70.22646],
            [-23.72742, 70.18401],
            [-22.34902, 70.12946],
            [-25.02927, 69.2588],
            [-27.74737, 68.47046],
            [-30.67371, 68.12503],
            [-31.77665, 68.12078],
            [-32.81105, 67.73547],
            [-34.20196, 66.67974],
            [-36.35284, 65.9789],
            [-37.04378, 65.93768],
            [-38.37505, 65.69213],
            [-39.81222, 65.45848],
            [-40.66899, 64.83997],
            [-40.68281, 64.13902],
            [-41.1887, 63.48246],
            [-42.81938, 62.68233],
            [-42.41666, 61.90093],
            [-42.86619, 61.07404],
            [-43.3784, 60.09772],
            [-44.7875, 60.03676],
            [-46.26364, 60.85328],
            [-48.26294, 60.85843],
            [-49.23308, 61.40681],
            [-49.90039, 62.38336],
            [-51.63325, 63.62691],
            [-52.14014, 64.27842],
            [-52.27659, 65.1767],
            [-53.66166, 66.09957],
            [-53.30161, 66.8365],
            [-53.96911, 67.18899],
            [-52.9804, 68.35759],
            [-51.47536, 68.72958],
            [-51.08041, 69.14781],
            [-50.87122, 69.9291],
            [-52.013585, 69.574925],
            [-52.55792, 69.42616],
            [-53.45629, 69.283625],
            [-54.68336, 69.61003],
            [-54.75001, 70.28932],
            [-54.35884, 70.821315],
            [-53.431315, 70.835755],
            [-51.39014, 70.56978],
            [-53.10937, 71.20485],
            [-54.00422, 71.54719],
            [-55, 71.40653696727257],
            [-55.83468, 71.65444],
            [-54.71819, 72.58625],
            [-55.32634, 72.95861],
            [-56.12003, 73.64977],
            [-57.32363, 74.71026],
            [-58.59679, 75.09861],
            [-58.58516, 75.51727],
            [-61.26861, 76.10238],
            [-63.39165, 76.1752],
            [-66.06427, 76.13486],
            [-68.50438, 76.06141],
            [-69.66485, 76.37975],
            [-71.40257, 77.00857],
            [-68.77671, 77.32312],
            [-66.76397, 77.37595],
            [-71.04293, 77.63595],
            [-73.297, 78.04419],
            [-73.15938, 78.43271],
            [-69.37345, 78.91388],
            [-65.7107, 79.39436],
            [-65.3239, 79.75814],
            [-68.02298, 80.11721],
            [-67.15129, 80.51582],
            [-63.68925, 81.21396],
            [-62.23444, 81.3211],
            [-62.65116, 81.77042],
            [-60.28249, 82.03363],
            [-57.20744, 82.19074],
            [-54.13442, 82.19962],
            [-53.04328, 81.88833],
            [-50.39061, 82.43883],
            [-48.00386, 82.06481],
            [-46.59984, 81.985945],
            [-44.523, 81.6607],
            [-46.9007, 82.19979],
            [-46.76379, 82.62796],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "The Bahamas",
        sov_a3: "BHS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "The Bahamas",
        adm0_a3: "BHS",
        geou_dif: 0,
        geounit: "The Bahamas",
        gu_a3: "BHS",
        su_dif: 0,
        subunit: "The Bahamas",
        su_a3: "BHS",
        brk_diff: 0,
        name: "Bahamas",
        name_long: "Bahamas",
        brk_a3: "BHS",
        brk_name: "Bahamas",
        brk_group: null,
        abbrev: "Bhs.",
        postal: "BS",
        formal_en: "Commonwealth of the Bahamas",
        formal_fr: null,
        name_ciawf: "Bahamas, The",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bahamas, The",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 389482,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 13578,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "BF",
        iso_a2: "BS",
        iso_a2_eh: "BS",
        iso_a3: "BHS",
        iso_a3_eh: "BHS",
        iso_n3: "044",
        iso_n3_eh: "044",
        un_a3: "044",
        wb_a2: "BS",
        wb_a3: "BHS",
        woe_id: 23424758,
        woe_id_eh: 23424758,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BHS",
        adm0_diff: null,
        adm0_tlc: "BHS",
        adm0_a3_us: "BHS",
        adm0_a3_fr: "BHS",
        adm0_a3_ru: "BHS",
        adm0_a3_es: "BHS",
        adm0_a3_cn: "BHS",
        adm0_a3_tw: "BHS",
        adm0_a3_in: "BHS",
        adm0_a3_np: "BHS",
        adm0_a3_pk: "BHS",
        adm0_a3_de: "BHS",
        adm0_a3_gb: "BHS",
        adm0_a3_br: "BHS",
        adm0_a3_il: "BHS",
        adm0_a3_ps: "BHS",
        adm0_a3_sa: "BHS",
        adm0_a3_eg: "BHS",
        adm0_a3_ma: "BHS",
        adm0_a3_pt: "BHS",
        adm0_a3_ar: "BHS",
        adm0_a3_jp: "BHS",
        adm0_a3_ko: "BHS",
        adm0_a3_vn: "BHS",
        adm0_a3_tr: "BHS",
        adm0_a3_id: "BHS",
        adm0_a3_pl: "BHS",
        adm0_a3_gr: "BHS",
        adm0_a3_it: "BHS",
        adm0_a3_nl: "BHS",
        adm0_a3_se: "BHS",
        adm0_a3_bd: "BHS",
        adm0_a3_ua: "BHS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -77.146688,
        label_y: 26.401789,
        ne_id: 1159320415,
        wikidataid: "Q778",
        name_ar: "باهاماس",
        name_bn: "বাহামা দ্বীপপুঞ্জ",
        name_de: "Bahamas",
        name_en: "The Bahamas",
        name_es: "Bahamas",
        name_fa: "باهاما",
        name_fr: "Bahamas",
        name_el: "Μπαχάμες",
        name_he: "איי בהאמה",
        name_hi: "बहामास",
        name_hu: "Bahama-szigetek",
        name_id: "Bahama",
        name_it: "Bahamas",
        name_ja: "バハマ",
        name_ko: "바하마",
        name_nl: "Bahama's",
        name_pl: "Bahamy",
        name_pt: "Bahamas",
        name_ru: "Багамские Острова",
        name_sv: "Bahamas",
        name_tr: "Bahamalar",
        name_uk: "Багамські Острови",
        name_ur: "بہاماس",
        name_vi: "Bahamas",
        name_zh: "巴哈马",
        name_zht: "巴哈馬",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BHS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-78.98, 26.79],
              [-78.51, 26.87],
              [-77.85, 26.84],
              [-77.82, 26.58],
              [-78.91, 26.42],
              [-78.98, 26.79],
            ],
          ],
          [
            [
              [-77.79, 27.04],
              [-77, 26.59],
              [-77.17255, 25.87918],
              [-77.35641, 26.00735],
              [-77.34, 26.53],
              [-77.78802, 26.92516],
              [-77.79, 27.04],
            ],
          ],
          [
            [
              [-78.19087, 25.2103],
              [-77.89, 25.17],
              [-77.54, 24.34],
              [-77.53466, 23.75975],
              [-77.78, 23.71],
              [-78.03405, 24.28615],
              [-78.40848, 24.57564],
              [-78.19087, 25.2103],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Trinidad and Tobago",
        sov_a3: "TTO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Trinidad and Tobago",
        adm0_a3: "TTO",
        geou_dif: 0,
        geounit: "Trinidad and Tobago",
        gu_a3: "TTO",
        su_dif: 0,
        subunit: "Trinidad and Tobago",
        su_a3: "TTO",
        brk_diff: 0,
        name: "Trinidad and Tobago",
        name_long: "Trinidad and Tobago",
        brk_a3: "TTO",
        brk_name: "Trinidad and Tobago",
        brk_group: null,
        abbrev: "Tr.T.",
        postal: "TT",
        formal_en: "Republic of Trinidad and Tobago",
        formal_fr: null,
        name_ciawf: "Trinidad and Tobago",
        note_adm0: null,
        note_brk: null,
        name_sort: "Trinidad and Tobago",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 1394973,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 24269,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "TD",
        iso_a2: "TT",
        iso_a2_eh: "TT",
        iso_a3: "TTO",
        iso_a3_eh: "TTO",
        iso_n3: "780",
        iso_n3_eh: "780",
        un_a3: "780",
        wb_a2: "TT",
        wb_a3: "TTO",
        woe_id: 23424958,
        woe_id_eh: 23424958,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TTO",
        adm0_diff: null,
        adm0_tlc: "TTO",
        adm0_a3_us: "TTO",
        adm0_a3_fr: "TTO",
        adm0_a3_ru: "TTO",
        adm0_a3_es: "TTO",
        adm0_a3_cn: "TTO",
        adm0_a3_tw: "TTO",
        adm0_a3_in: "TTO",
        adm0_a3_np: "TTO",
        adm0_a3_pk: "TTO",
        adm0_a3_de: "TTO",
        adm0_a3_gb: "TTO",
        adm0_a3_br: "TTO",
        adm0_a3_il: "TTO",
        adm0_a3_ps: "TTO",
        adm0_a3_sa: "TTO",
        adm0_a3_eg: "TTO",
        adm0_a3_ma: "TTO",
        adm0_a3_pt: "TTO",
        adm0_a3_ar: "TTO",
        adm0_a3_jp: "TTO",
        adm0_a3_ko: "TTO",
        adm0_a3_vn: "TTO",
        adm0_a3_tr: "TTO",
        adm0_a3_id: "TTO",
        adm0_a3_pl: "TTO",
        adm0_a3_gr: "TTO",
        adm0_a3_it: "TTO",
        adm0_a3_nl: "TTO",
        adm0_a3_se: "TTO",
        adm0_a3_bd: "TTO",
        adm0_a3_ua: "TTO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 19,
        long_len: 19,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -60.9184,
        label_y: 10.9989,
        ne_id: 1159321321,
        wikidataid: "Q754",
        name_ar: "ترينيداد وتوباغو",
        name_bn: "ত্রিনিদাদ ও টোবাগো",
        name_de: "Trinidad und Tobago",
        name_en: "Trinidad and Tobago",
        name_es: "Trinidad y Tobago",
        name_fa: "ترینیداد و توباگو",
        name_fr: "Trinité-et-Tobago",
        name_el: "Τρινιντάντ και Τομπάγκο",
        name_he: "טרינידד וטובגו",
        name_hi: "त्रिनिदाद और टोबैगो",
        name_hu: "Trinidad és Tobago",
        name_id: "Trinidad dan Tobago",
        name_it: "Trinidad e Tobago",
        name_ja: "トリニダード・トバゴ",
        name_ko: "트리니다드 토바고",
        name_nl: "Trinidad en Tobago",
        name_pl: "Trynidad i Tobago",
        name_pt: "Trinidad e Tobago",
        name_ru: "Тринидад и Тобаго",
        name_sv: "Trinidad och Tobago",
        name_tr: "Trinidad ve Tobago",
        name_uk: "Тринідад і Тобаго",
        name_ur: "ٹرینیڈاڈ و ٹوباگو",
        name_vi: "Trinidad và Tobago",
        name_zh: "特立尼达和多巴哥",
        name_zht: "千里達及托巴哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TTO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.68, 10.76],
            [-61.105, 10.89],
            [-60.895, 10.855],
            [-60.935, 10.11],
            [-61.77, 10],
            [-61.95, 10.09],
            [-61.66, 10.365],
            [-61.68, 10.76],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Puerto Rico",
        adm0_a3: "PRI",
        geou_dif: 0,
        geounit: "Puerto Rico",
        gu_a3: "PRI",
        su_dif: 0,
        subunit: "Puerto Rico",
        su_a3: "PRI",
        brk_diff: 0,
        name: "Puerto Rico",
        name_long: "Puerto Rico",
        brk_a3: "PRI",
        brk_name: "Puerto Rico",
        brk_group: null,
        abbrev: "P.R.",
        postal: "PR",
        formal_en: "Commonwealth of Puerto Rico",
        formal_fr: null,
        name_ciawf: "Puerto Rico",
        note_adm0: "U.S.A.",
        note_brk: null,
        name_sort: "Puerto Rico",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 3193694,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 104988,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "RQ",
        iso_a2: "PR",
        iso_a2_eh: "PR",
        iso_a3: "PRI",
        iso_a3_eh: "PRI",
        iso_n3: "630",
        iso_n3_eh: "630",
        un_a3: "630",
        wb_a2: "PR",
        wb_a3: "PRI",
        woe_id: 23424935,
        woe_id_eh: 23424935,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PRI",
        adm0_diff: null,
        adm0_tlc: "PRI",
        adm0_a3_us: "PRI",
        adm0_a3_fr: "PRI",
        adm0_a3_ru: "PRI",
        adm0_a3_es: "PRI",
        adm0_a3_cn: "PRI",
        adm0_a3_tw: "PRI",
        adm0_a3_in: "PRI",
        adm0_a3_np: "PRI",
        adm0_a3_pk: "PRI",
        adm0_a3_de: "PRI",
        adm0_a3_gb: "PRI",
        adm0_a3_br: "PRI",
        adm0_a3_il: "PRI",
        adm0_a3_ps: "PRI",
        adm0_a3_sa: "PRI",
        adm0_a3_eg: "PRI",
        adm0_a3_ma: "PRI",
        adm0_a3_pt: "PRI",
        adm0_a3_ar: "PRI",
        adm0_a3_jp: "PRI",
        adm0_a3_ko: "PRI",
        adm0_a3_vn: "PRI",
        adm0_a3_tr: "PRI",
        adm0_a3_id: "PRI",
        adm0_a3_pl: "PRI",
        adm0_a3_gr: "PRI",
        adm0_a3_it: "PRI",
        adm0_a3_nl: "PRI",
        adm0_a3_se: "PRI",
        adm0_a3_bd: "PRI",
        adm0_a3_ua: "PRI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -66.481065,
        label_y: 18.234668,
        ne_id: 1159321363,
        wikidataid: "Q1183",
        name_ar: "بورتوريكو",
        name_bn: "পুয়ের্তো রিকো",
        name_de: "Puerto Rico",
        name_en: "Puerto Rico",
        name_es: "Puerto Rico",
        name_fa: "پورتوریکو",
        name_fr: "Porto Rico",
        name_el: "Πουέρτο Ρίκο",
        name_he: "פוארטו ריקו",
        name_hi: "पोर्टो रीको",
        name_hu: "Puerto Rico",
        name_id: "Puerto Riko",
        name_it: "Porto Rico",
        name_ja: "プエルトリコ",
        name_ko: "푸에르토리코",
        name_nl: "Puerto Rico",
        name_pl: "Portoryko",
        name_pt: "Porto Rico",
        name_ru: "Пуэрто-Рико",
        name_sv: "Puerto Rico",
        name_tr: "Porto Riko",
        name_uk: "Пуерто-Рико",
        name_ur: "پورٹو ریکو",
        name_vi: "Puerto Rico",
        name_zh: "波多黎各",
        name_zht: "波多黎各",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.28243445500821, 18.514761664295364],
            [-65.7713028632093, 18.426679185453878],
            [-65.59100379094295, 18.228034979723915],
            [-65.84716386581377, 17.97590566657186],
            [-66.59993445500949, 17.981822618069273],
            [-67.18416236028527, 17.946553453030077],
            [-67.24242753769435, 18.374460150622937],
            [-67.10067908391774, 18.52060110114435],
            [-66.28243445500821, 18.514761664295364],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Jamaica",
        sov_a3: "JAM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Jamaica",
        adm0_a3: "JAM",
        geou_dif: 0,
        geounit: "Jamaica",
        gu_a3: "JAM",
        su_dif: 0,
        subunit: "Jamaica",
        su_a3: "JAM",
        brk_diff: 0,
        name: "Jamaica",
        name_long: "Jamaica",
        brk_a3: "JAM",
        brk_name: "Jamaica",
        brk_group: null,
        abbrev: "Jam.",
        postal: "J",
        formal_en: "Jamaica",
        formal_fr: null,
        name_ciawf: "Jamaica",
        note_adm0: null,
        note_brk: null,
        name_sort: "Jamaica",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 10,
        pop_est: 2948279,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 16458,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "JM",
        iso_a2: "JM",
        iso_a2_eh: "JM",
        iso_a3: "JAM",
        iso_a3_eh: "JAM",
        iso_n3: "388",
        iso_n3_eh: "388",
        un_a3: "388",
        wb_a2: "JM",
        wb_a3: "JAM",
        woe_id: 23424858,
        woe_id_eh: 23424858,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JAM",
        adm0_diff: null,
        adm0_tlc: "JAM",
        adm0_a3_us: "JAM",
        adm0_a3_fr: "JAM",
        adm0_a3_ru: "JAM",
        adm0_a3_es: "JAM",
        adm0_a3_cn: "JAM",
        adm0_a3_tw: "JAM",
        adm0_a3_in: "JAM",
        adm0_a3_np: "JAM",
        adm0_a3_pk: "JAM",
        adm0_a3_de: "JAM",
        adm0_a3_gb: "JAM",
        adm0_a3_br: "JAM",
        adm0_a3_il: "JAM",
        adm0_a3_ps: "JAM",
        adm0_a3_sa: "JAM",
        adm0_a3_eg: "JAM",
        adm0_a3_ma: "JAM",
        adm0_a3_pt: "JAM",
        adm0_a3_ar: "JAM",
        adm0_a3_jp: "JAM",
        adm0_a3_ko: "JAM",
        adm0_a3_vn: "JAM",
        adm0_a3_tr: "JAM",
        adm0_a3_id: "JAM",
        adm0_a3_pl: "JAM",
        adm0_a3_gr: "JAM",
        adm0_a3_it: "JAM",
        adm0_a3_nl: "JAM",
        adm0_a3_se: "JAM",
        adm0_a3_bd: "JAM",
        adm0_a3_ua: "JAM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -77.318767,
        label_y: 18.137124,
        ne_id: 1159320931,
        wikidataid: "Q766",
        name_ar: "جامايكا",
        name_bn: "জ্যামাইকা",
        name_de: "Jamaika",
        name_en: "Jamaica",
        name_es: "Jamaica",
        name_fa: "جامائیکا",
        name_fr: "Jamaïque",
        name_el: "Τζαμάικα",
        name_he: "ג'מייקה",
        name_hi: "जमैका",
        name_hu: "Jamaica",
        name_id: "Jamaika",
        name_it: "Giamaica",
        name_ja: "ジャマイカ",
        name_ko: "자메이카",
        name_nl: "Jamaica",
        name_pl: "Jamajka",
        name_pt: "Jamaica",
        name_ru: "Ямайка",
        name_sv: "Jamaica",
        name_tr: "Jamaika",
        name_uk: "Ямайка",
        name_ur: "جمیکا",
        name_vi: "Jamaica",
        name_zh: "牙买加",
        name_zht: "牙買加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JAM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.56960079619921, 18.490525417550487],
            [-76.89661861846213, 18.400866807524082],
            [-76.36535905628554, 18.160700588447597],
            [-76.19965857614164, 17.886867173732966],
            [-76.9025614081757, 17.868237819891746],
            [-77.20634131540348, 17.70111623785982],
            [-77.76602291534061, 17.86159739834224],
            [-78.33771928578561, 18.225967922432233],
            [-78.21772661000388, 18.454532782459196],
            [-77.79736467152563, 18.524218451404778],
            [-77.56960079619921, 18.490525417550487],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Chile",
        sov_a3: "CHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Chile",
        adm0_a3: "CHL",
        geou_dif: 0,
        geounit: "Chile",
        gu_a3: "CHL",
        su_dif: 0,
        subunit: "Chile",
        su_a3: "CHL",
        brk_diff: 0,
        name: "Chile",
        name_long: "Chile",
        brk_a3: "CHL",
        brk_name: "Chile",
        brk_group: null,
        abbrev: "Chile",
        postal: "CL",
        formal_en: "Republic of Chile",
        formal_fr: null,
        name_ciawf: "Chile",
        note_adm0: null,
        note_brk: null,
        name_sort: "Chile",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 9,
        pop_est: 18952038,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 282318,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "CI",
        iso_a2: "CL",
        iso_a2_eh: "CL",
        iso_a3: "CHL",
        iso_a3_eh: "CHL",
        iso_n3: "152",
        iso_n3_eh: "152",
        un_a3: "152",
        wb_a2: "CL",
        wb_a3: "CHL",
        woe_id: 23424782,
        woe_id_eh: 23424782,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CHL",
        adm0_diff: null,
        adm0_tlc: "CHL",
        adm0_a3_us: "CHL",
        adm0_a3_fr: "CHL",
        adm0_a3_ru: "CHL",
        adm0_a3_es: "CHL",
        adm0_a3_cn: "CHL",
        adm0_a3_tw: "CHL",
        adm0_a3_in: "CHL",
        adm0_a3_np: "CHL",
        adm0_a3_pk: "CHL",
        adm0_a3_de: "CHL",
        adm0_a3_gb: "CHL",
        adm0_a3_br: "CHL",
        adm0_a3_il: "CHL",
        adm0_a3_ps: "CHL",
        adm0_a3_sa: "CHL",
        adm0_a3_eg: "CHL",
        adm0_a3_ma: "CHL",
        adm0_a3_pt: "CHL",
        adm0_a3_ar: "CHL",
        adm0_a3_jp: "CHL",
        adm0_a3_ko: "CHL",
        adm0_a3_vn: "CHL",
        adm0_a3_tr: "CHL",
        adm0_a3_id: "CHL",
        adm0_a3_pl: "CHL",
        adm0_a3_gr: "CHL",
        adm0_a3_it: "CHL",
        adm0_a3_nl: "CHL",
        adm0_a3_se: "CHL",
        adm0_a3_bd: "CHL",
        adm0_a3_ua: "CHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: -72.318871,
        label_y: -38.151771,
        ne_id: 1159320493,
        wikidataid: "Q298",
        name_ar: "تشيلي",
        name_bn: "চিলি",
        name_de: "Chile",
        name_en: "Chile",
        name_es: "Chile",
        name_fa: "شیلی",
        name_fr: "Chili",
        name_el: "Χιλή",
        name_he: "צ'ילה",
        name_hi: "चिली",
        name_hu: "Chile",
        name_id: "Chili",
        name_it: "Cile",
        name_ja: "チリ",
        name_ko: "칠레",
        name_nl: "Chili",
        name_pl: "Chile",
        name_pt: "Chile",
        name_ru: "Чили",
        name_sv: "Chile",
        name_tr: "Şili",
        name_uk: "Чилі",
        name_ur: "چلی",
        name_vi: "Chile",
        name_zh: "智利",
        name_zht: "智利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-68.63401022758323, -52.63637045887449],
              [-68.63335, -54.8695],
              [-67.56244, -54.87001],
              [-66.95992, -54.89681],
              [-67.29103, -55.30124],
              [-68.14863, -55.61183],
              [-68.63999081081187, -55.58001799908692],
              [-69.2321, -55.49906],
              [-69.95809, -55.19843],
              [-71.00568, -55.05383],
              [-72.2639, -54.49514],
              [-73.2852, -53.95752],
              [-74.66253, -52.83749],
              [-73.8381, -53.04743],
              [-72.43418, -53.7154],
              [-71.10773, -54.07433],
              [-70.59177999999986, -53.61583],
              [-70.26748, -52.93123],
              [-69.34565, -52.5183],
              [-68.63401022758323, -52.63637045887449],
            ],
          ],
          [
            [
              [-69.59042375352405, -17.580011895419332],
              [-69.10024695501949, -18.260125420812678],
              [-68.96681840684187, -18.981683444904107],
              [-68.44222510443092, -19.40506845467143],
              [-68.75716712103375, -20.372657972904463],
              [-68.21991309271128, -21.494346612231865],
              [-67.82817989772273, -22.872918796482175],
              [-67.1066735500636, -22.735924574476417],
              [-66.9852339341777, -22.98634856536284],
              [-67.32844295924417, -24.02530323659095],
              [-68.41765296087614, -24.51855478281688],
              [-68.38600114609736, -26.185016371365215],
              [-68.59479977077268, -26.506908868111296],
              [-68.29554155137043, -26.89933969493578],
              [-69.00123491074825, -27.52121388113618],
              [-69.65613033718317, -28.459141127233686],
              [-70.01355038112992, -29.367922865518572],
              [-69.91900834825194, -30.33633920666828],
              [-70.53506893581951, -31.36501026787031],
              [-70.0743993801536, -33.09120981214805],
              [-69.81477698431922, -33.273886000299825],
              [-69.81730912950152, -34.1935714657983],
              [-70.38804948594913, -35.16968759535949],
              [-70.36476925320164, -36.00508879978992],
              [-71.12188066270987, -36.65812387466232],
              [-71.11862504747549, -37.57682748794724],
              [-70.81466427273469, -38.55299529394074],
              [-71.41351660834906, -38.91602223079114],
              [-71.68076127794649, -39.808164157878046],
              [-71.91573401557763, -40.83233936947069],
              [-71.7468037584155, -42.05138640723598],
              [-72.14889807807856, -42.254888197601375],
              [-71.91542395698389, -43.40856454851745],
              [-71.46405615913051, -43.787611179378345],
              [-71.79362260607193, -44.207172133156064],
              [-71.32980078803622, -44.407521661151655],
              [-71.22277889675976, -44.784242852559416],
              [-71.65931555854536, -44.973688653341426],
              [-71.55200944689128, -45.5607329241771],
              [-71.91725847033024, -46.88483814879177],
              [-72.44735531278027, -47.73853281025352],
              [-72.33116085477201, -48.2442383766618],
              [-72.64824744331494, -48.87861825947683],
              [-73.4154357571201, -49.31843637471297],
              [-73.32805091011453, -50.378785088909915],
              [-72.97574683296469, -50.741450290734285],
              [-72.30997351753234, -50.67700977966632],
              [-72.32940385607407, -51.42595631287243],
              [-71.91480383979638, -52.0090223058659],
              [-69.49836218939609, -52.14276091263727],
              [-68.57154537624133, -52.299443855346226],
              [-69.46128434922667, -52.29195077266391],
              [-69.9427795071062, -52.53793059037322],
              [-70.8451016913546, -52.89920052852571],
              [-71.00633216010525, -53.83325204220132],
              [-71.429794684521, -53.85645476030037],
              [-72.55794287788488, -53.53141000118449],
              [-73.7027567206629, -52.835069268607235],
              [-73.7027567206629, -52.835070076051494],
              [-74.94676347522517, -52.262753588419],
              [-75.2600260077785, -51.62935475037325],
              [-74.97663245308988, -51.0433956846157],
              [-75.47975419788355, -50.37837167745158],
              [-75.60801510283198, -48.67377288187184],
              [-75.18276974150216, -47.7119194476232],
              [-74.1265809801047, -46.93925343199511],
              [-75.64439531116545, -46.64764332457207],
              [-74.69215369332312, -45.76397633238103],
              [-74.35170935738425, -44.10304412208794],
              [-73.24035600451522, -44.454960625995604],
              [-72.7178039211798, -42.38335580827898],
              [-73.38889990913822, -42.117532240569574],
              [-73.70133561877488, -43.365776462579774],
              [-74.33194312203261, -43.22495818458442],
              [-74.0179571194272, -41.79481292090683],
              [-73.67709937202999, -39.94221282324317],
              [-73.21759253609065, -39.25868865331856],
              [-73.50555945503712, -38.282882582351114],
              [-73.58806087919109, -37.15628468195598],
              [-73.1667170884993, -37.12378020604439],
              [-72.55313696968174, -35.50884002049106],
              [-71.86173214383263, -33.90909270603153],
              [-71.4384504869299, -32.41889942803078],
              [-71.66872066922247, -30.920644626592495],
              [-71.37008256700773, -30.09568206148503],
              [-71.48989437527645, -28.861442152625923],
              [-70.90512386746161, -27.640379734001247],
              [-70.72495398627599, -25.705924167587256],
              [-70.40396582709502, -23.628996677344574],
              [-70.09124589708074, -21.39331918710126],
              [-70.16441972520605, -19.756468194256165],
              [-70.37257239447771, -18.34797535570887],
              [-69.85844356960587, -18.092693780187012],
              [-69.59042375352405, -17.580011895419332],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Bolivia",
        sov_a3: "BOL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bolivia",
        adm0_a3: "BOL",
        geou_dif: 0,
        geounit: "Bolivia",
        gu_a3: "BOL",
        su_dif: 0,
        subunit: "Bolivia",
        su_a3: "BOL",
        brk_diff: 0,
        name: "Bolivia",
        name_long: "Bolivia",
        brk_a3: "BOL",
        brk_name: "Bolivia",
        brk_group: null,
        abbrev: "Bolivia",
        postal: "BO",
        formal_en: "Plurinational State of Bolivia",
        formal_fr: null,
        name_ciawf: "Bolivia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bolivia",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 3,
        pop_est: 11513100,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 40895,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "BL",
        iso_a2: "BO",
        iso_a2_eh: "BO",
        iso_a3: "BOL",
        iso_a3_eh: "BOL",
        iso_n3: "068",
        iso_n3_eh: "068",
        un_a3: "068",
        wb_a2: "BO",
        wb_a3: "BOL",
        woe_id: 23424762,
        woe_id_eh: 23424762,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BOL",
        adm0_diff: null,
        adm0_tlc: "BOL",
        adm0_a3_us: "BOL",
        adm0_a3_fr: "BOL",
        adm0_a3_ru: "BOL",
        adm0_a3_es: "BOL",
        adm0_a3_cn: "BOL",
        adm0_a3_tw: "BOL",
        adm0_a3_in: "BOL",
        adm0_a3_np: "BOL",
        adm0_a3_pk: "BOL",
        adm0_a3_de: "BOL",
        adm0_a3_gb: "BOL",
        adm0_a3_br: "BOL",
        adm0_a3_il: "BOL",
        adm0_a3_ps: "BOL",
        adm0_a3_sa: "BOL",
        adm0_a3_eg: "BOL",
        adm0_a3_ma: "BOL",
        adm0_a3_pt: "BOL",
        adm0_a3_ar: "BOL",
        adm0_a3_jp: "BOL",
        adm0_a3_ko: "BOL",
        adm0_a3_vn: "BOL",
        adm0_a3_tr: "BOL",
        adm0_a3_id: "BOL",
        adm0_a3_pl: "BOL",
        adm0_a3_gr: "BOL",
        adm0_a3_it: "BOL",
        adm0_a3_nl: "BOL",
        adm0_a3_se: "BOL",
        adm0_a3_bd: "BOL",
        adm0_a3_ua: "BOL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7.5,
        label_x: -64.593433,
        label_y: -16.666015,
        ne_id: 1159320439,
        wikidataid: "Q750",
        name_ar: "بوليفيا",
        name_bn: "বলিভিয়া",
        name_de: "Bolivien",
        name_en: "Bolivia",
        name_es: "Bolivia",
        name_fa: "بولیوی",
        name_fr: "Bolivie",
        name_el: "Βολιβία",
        name_he: "בוליביה",
        name_hi: "बोलिविया",
        name_hu: "Bolívia",
        name_id: "Bolivia",
        name_it: "Bolivia",
        name_ja: "ボリビア",
        name_ko: "볼리비아",
        name_nl: "Bolivia",
        name_pl: "Boliwia",
        name_pt: "Bolívia",
        name_ru: "Боливия",
        name_sv: "Bolivia",
        name_tr: "Bolivya",
        name_uk: "Болівія",
        name_ur: "بولیویا",
        name_vi: "Bolivia",
        name_zh: "玻利维亚",
        name_zht: "玻利維亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BOL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.52967810736496, -10.951734307502194],
            [-68.78615759954948, -11.03638030359628],
            [-68.27125362819326, -11.01452117273682],
            [-68.04819230820539, -10.712059014532485],
            [-67.17380123561074, -10.306812432499612],
            [-66.6469083319628, -9.931331475466862],
            [-65.33843522811642, -9.761987806846392],
            [-65.44483700220539, -10.511451104375432],
            [-65.32189876978302, -10.895872084194679],
            [-65.40228146021303, -11.566270440317155],
            [-64.3163529120316, -12.461978041232193],
            [-63.19649878605057, -12.627032565972435],
            [-62.803060268796386, -13.000653171442686],
            [-62.127080857986385, -13.198780612849724],
            [-61.71320431176078, -13.489202162330052],
            [-61.08412126325565, -13.479383640194598],
            [-60.503304002511136, -13.775954685117659],
            [-60.45919816755003, -14.354007256734555],
            [-60.26432634137737, -14.645979099183641],
            [-60.251148851142936, -15.07721892665932],
            [-60.54296566429515, -15.093910414289596],
            [-60.158389655179036, -16.258283786690086],
            [-58.24121985536668, -16.299573256091293],
            [-58.38805843772404, -16.877109063385276],
            [-58.28080400250225, -17.271710300366017],
            [-57.734558274961, -17.55246835700777],
            [-57.49837114117099, -18.174187513911292],
            [-57.67600887717431, -18.96183969490403],
            [-57.949997321185826, -19.40000416430682],
            [-57.85380164247451, -19.96999521248619],
            [-58.166392381408045, -20.176700941653678],
            [-58.183471442280506, -19.868399346600363],
            [-59.11504248720611, -19.3569060197754],
            [-60.04356462262649, -19.342746677327426],
            [-61.78632646345377, -19.633736667562964],
            [-62.2659612697708, -20.513734633061276],
            [-62.291179368729225, -21.051634616787393],
            [-62.685057135657885, -22.249029229422387],
            [-62.84646847192156, -22.03498544686945],
            [-63.986838141522476, -21.99364430103595],
            [-64.37702104354226, -22.79809132252354],
            [-64.96489213729461, -22.075861504812327],
            [-66.27333940292485, -21.83231047942072],
            [-67.1066735500636, -22.735924574476417],
            [-67.82817989772273, -22.872918796482175],
            [-68.21991309271128, -21.494346612231865],
            [-68.75716712103375, -20.372657972904463],
            [-68.44222510443092, -19.40506845467143],
            [-68.96681840684187, -18.981683444904107],
            [-69.10024695501949, -18.260125420812678],
            [-69.59042375352405, -17.580011895419332],
            [-68.9596353827533, -16.50069793057127],
            [-69.38976416693471, -15.660129082911652],
            [-69.16034664577495, -15.323973890853019],
            [-69.33953467474701, -14.953195489158832],
            [-68.9488866848366, -14.453639418193283],
            [-68.92922380234954, -13.602683607643009],
            [-68.88007951523997, -12.899729099176653],
            [-68.66507971868963, -12.561300144097173],
            [-69.52967810736496, -10.951734307502194],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Peru",
        sov_a3: "PER",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Peru",
        adm0_a3: "PER",
        geou_dif: 0,
        geounit: "Peru",
        gu_a3: "PER",
        su_dif: 0,
        subunit: "Peru",
        su_a3: "PER",
        brk_diff: 0,
        name: "Peru",
        name_long: "Peru",
        brk_a3: "PER",
        brk_name: "Peru",
        brk_group: null,
        abbrev: "Peru",
        postal: "PE",
        formal_en: "Republic of Peru",
        formal_fr: null,
        name_ciawf: "Peru",
        note_adm0: null,
        note_brk: null,
        name_sort: "Peru",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 11,
        pop_est: 32510453,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 226848,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "PE",
        iso_a2: "PE",
        iso_a2_eh: "PE",
        iso_a3: "PER",
        iso_a3_eh: "PER",
        iso_n3: "604",
        iso_n3_eh: "604",
        un_a3: "604",
        wb_a2: "PE",
        wb_a3: "PER",
        woe_id: 23424919,
        woe_id_eh: 23424919,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PER",
        adm0_diff: null,
        adm0_tlc: "PER",
        adm0_a3_us: "PER",
        adm0_a3_fr: "PER",
        adm0_a3_ru: "PER",
        adm0_a3_es: "PER",
        adm0_a3_cn: "PER",
        adm0_a3_tw: "PER",
        adm0_a3_in: "PER",
        adm0_a3_np: "PER",
        adm0_a3_pk: "PER",
        adm0_a3_de: "PER",
        adm0_a3_gb: "PER",
        adm0_a3_br: "PER",
        adm0_a3_il: "PER",
        adm0_a3_ps: "PER",
        adm0_a3_sa: "PER",
        adm0_a3_eg: "PER",
        adm0_a3_ma: "PER",
        adm0_a3_pt: "PER",
        adm0_a3_ar: "PER",
        adm0_a3_jp: "PER",
        adm0_a3_ko: "PER",
        adm0_a3_vn: "PER",
        adm0_a3_tr: "PER",
        adm0_a3_id: "PER",
        adm0_a3_pl: "PER",
        adm0_a3_gr: "PER",
        adm0_a3_it: "PER",
        adm0_a3_nl: "PER",
        adm0_a3_se: "PER",
        adm0_a3_bd: "PER",
        adm0_a3_ua: "PER",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -72.90016,
        label_y: -12.976679,
        ne_id: 1159321163,
        wikidataid: "Q419",
        name_ar: "بيرو",
        name_bn: "পেরু",
        name_de: "Peru",
        name_en: "Peru",
        name_es: "Perú",
        name_fa: "پرو",
        name_fr: "Pérou",
        name_el: "Περού",
        name_he: "פרו",
        name_hi: "पेरू",
        name_hu: "Peru",
        name_id: "Peru",
        name_it: "Perù",
        name_ja: "ペルー",
        name_ko: "페루",
        name_nl: "Peru",
        name_pl: "Peru",
        name_pt: "Peru",
        name_ru: "Перу",
        name_sv: "Peru",
        name_tr: "Peru",
        name_uk: "Перу",
        name_ur: "پیرو",
        name_vi: "Peru",
        name_zh: "秘鲁",
        name_zht: "秘魯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PER.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.89363521999663, -4.298186944194327],
            [-70.7947688463023, -4.251264743673303],
            [-70.92884334988358, -4.401591485210368],
            [-71.74840572781655, -4.593982842633011],
            [-72.89192765978726, -5.274561455916981],
            [-72.9645072089412, -5.741251315944893],
            [-73.21971126981461, -6.089188734566078],
            [-73.1200274319236, -6.629930922068239],
            [-73.72448666044164, -6.91859547285064],
            [-73.7234014553635, -7.340998630404414],
            [-73.98723548042966, -7.523829847853065],
            [-73.57105933296707, -8.424446709835834],
            [-73.01538265653255, -9.032833347208062],
            [-73.22671342639016, -9.462212823121234],
            [-72.56303300646564, -9.520193780152717],
            [-72.18489071316985, -10.053597914269432],
            [-71.30241227892154, -10.079436130415374],
            [-70.48189388699117, -9.490118096558845],
            [-70.54868567572841, -11.009146823778465],
            [-70.0937522040469, -11.123971856331012],
            [-69.52967810736496, -10.951734307502194],
            [-68.66507971868963, -12.561300144097173],
            [-68.88007951523997, -12.899729099176653],
            [-68.92922380234954, -13.602683607643009],
            [-68.9488866848366, -14.453639418193283],
            [-69.33953467474701, -14.953195489158832],
            [-69.16034664577495, -15.323973890853019],
            [-69.38976416693471, -15.660129082911652],
            [-68.9596353827533, -16.50069793057127],
            [-69.59042375352405, -17.580011895419332],
            [-69.85844356960587, -18.092693780187012],
            [-70.37257239447771, -18.34797535570887],
            [-71.37525021023693, -17.773798516513857],
            [-71.46204077827113, -17.363487644116383],
            [-73.44452958850042, -16.359362888252996],
            [-75.23788265654144, -15.265682875227782],
            [-76.00920508492995, -14.649286390850321],
            [-76.42346920439775, -13.823186944232432],
            [-76.25924150257417, -13.535039157772943],
            [-77.10619238962184, -12.22271615972082],
            [-78.09215287953464, -10.377712497604065],
            [-79.03695309112695, -8.386567884965892],
            [-79.44592037628485, -7.93083342858386],
            [-79.76057817251005, -7.194340915560084],
            [-80.53748165558608, -6.541667575713717],
            [-81.24999630402642, -6.136834405139183],
            [-80.92634680858244, -5.690556735866565],
            [-81.41094255239946, -4.736764825055459],
            [-81.09966956248937, -4.036394138203697],
            [-80.30256059438722, -3.404856459164713],
            [-80.18401485870967, -3.821161797708044],
            [-80.46929460317695, -4.059286797708999],
            [-80.44224199087216, -4.425724379090674],
            [-80.02890804718561, -4.346090996928893],
            [-79.62497921417618, -4.454198093283495],
            [-79.20528906931773, -4.959128513207389],
            [-78.63989722361234, -4.547784112164074],
            [-78.45068396677564, -3.873096612161376],
            [-77.83790483265861, -3.003020521663103],
            [-76.63539425322672, -2.608677666843818],
            [-75.54499569365204, -1.56160979574588],
            [-75.23372270374195, -0.911416924649529],
            [-75.37322323271385, -0.15203175212045],
            [-75.10662451852008, -0.05720549886486],
            [-74.44160051135597, -0.530820000819887],
            [-74.12239518908906, -1.002832533373848],
            [-73.6595035468346, -1.260491224781134],
            [-73.07039221870724, -2.308954359550953],
            [-72.32578650581365, -2.434218031426454],
            [-71.7747607082854, -2.169789727388938],
            [-71.41364579942979, -2.342802422702128],
            [-70.81347571479196, -2.256864515800743],
            [-70.04770850287485, -2.725156345229699],
            [-70.69268205430971, -3.742872002785859],
            [-70.39404395209499, -3.766591485207825],
            [-69.89363521999663, -4.298186944194327],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Argentina",
        sov_a3: "ARG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Argentina",
        adm0_a3: "ARG",
        geou_dif: 0,
        geounit: "Argentina",
        gu_a3: "ARG",
        su_dif: 0,
        subunit: "Argentina",
        su_a3: "ARG",
        brk_diff: 0,
        name: "Argentina",
        name_long: "Argentina",
        brk_a3: "ARG",
        brk_name: "Argentina",
        brk_group: null,
        abbrev: "Arg.",
        postal: "AR",
        formal_en: "Argentine Republic",
        formal_fr: null,
        name_ciawf: "Argentina",
        note_adm0: null,
        note_brk: null,
        name_sort: "Argentina",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 44938712,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 445445,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "AR",
        iso_a2: "AR",
        iso_a2_eh: "AR",
        iso_a3: "ARG",
        iso_a3_eh: "ARG",
        iso_n3: "032",
        iso_n3_eh: "032",
        un_a3: "032",
        wb_a2: "AR",
        wb_a3: "ARG",
        woe_id: 23424747,
        woe_id_eh: 23424747,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ARG",
        adm0_diff: null,
        adm0_tlc: "ARG",
        adm0_a3_us: "ARG",
        adm0_a3_fr: "ARG",
        adm0_a3_ru: "ARG",
        adm0_a3_es: "ARG",
        adm0_a3_cn: "ARG",
        adm0_a3_tw: "ARG",
        adm0_a3_in: "ARG",
        adm0_a3_np: "ARG",
        adm0_a3_pk: "ARG",
        adm0_a3_de: "ARG",
        adm0_a3_gb: "ARG",
        adm0_a3_br: "ARG",
        adm0_a3_il: "ARG",
        adm0_a3_ps: "ARG",
        adm0_a3_sa: "ARG",
        adm0_a3_eg: "ARG",
        adm0_a3_ma: "ARG",
        adm0_a3_pt: "ARG",
        adm0_a3_ar: "ARG",
        adm0_a3_jp: "ARG",
        adm0_a3_ko: "ARG",
        adm0_a3_vn: "ARG",
        adm0_a3_tr: "ARG",
        adm0_a3_id: "ARG",
        adm0_a3_pl: "ARG",
        adm0_a3_gr: "ARG",
        adm0_a3_it: "ARG",
        adm0_a3_nl: "ARG",
        adm0_a3_se: "ARG",
        adm0_a3_bd: "ARG",
        adm0_a3_ua: "ARG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -64.173331,
        label_y: -33.501159,
        ne_id: 1159320331,
        wikidataid: "Q414",
        name_ar: "الأرجنتين",
        name_bn: "আর্জেন্টিনা",
        name_de: "Argentinien",
        name_en: "Argentina",
        name_es: "Argentina",
        name_fa: "آرژانتین",
        name_fr: "Argentine",
        name_el: "Αργεντινή",
        name_he: "ארגנטינה",
        name_hi: "अर्जेण्टीना",
        name_hu: "Argentína",
        name_id: "Argentina",
        name_it: "Argentina",
        name_ja: "アルゼンチン",
        name_ko: "아르헨티나",
        name_nl: "Argentinië",
        name_pl: "Argentyna",
        name_pt: "Argentina",
        name_ru: "Аргентина",
        name_sv: "Argentina",
        name_tr: "Arjantin",
        name_uk: "Аргентина",
        name_ur: "ارجنٹائن",
        name_vi: "Argentina",
        name_zh: "阿根廷",
        name_zht: "阿根廷",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ARG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-68.63401022758323, -52.63637045887449],
              [-68.25, -53.1],
              [-67.75, -53.85],
              [-66.45, -54.45],
              [-65.05, -54.7],
              [-65.5, -55.2],
              [-66.45, -55.25],
              [-66.95992, -54.89681],
              [-67.56244, -54.87001],
              [-68.63335, -54.8695],
              [-68.63401022758323, -52.63637045887449],
            ],
          ],
          [
            [
              [-57.62513342958296, -30.21629485445426],
              [-57.87493730328188, -31.016556084926208],
              [-58.14244035504076, -32.044503676076154],
              [-58.13264767112145, -33.040566908502015],
              [-58.349611172098875, -33.26318897881541],
              [-58.42707414410439, -33.909454441057576],
              [-58.49544206402655, -34.43148976007008],
              [-57.22582963726366, -35.28802662530788],
              [-57.36235877137878, -35.977390232081476],
              [-56.73748735210545, -36.41312590916655],
              [-56.78828528504836, -36.901571547189334],
              [-57.74915686708346, -38.18387053807989],
              [-59.23185706240189, -38.720220228837235],
              [-61.23744523786564, -38.9284245745412],
              [-62.33595699731013, -38.827707208004334],
              [-62.125763108962936, -39.42410491308485],
              [-62.330530971919494, -40.17258635840034],
              [-62.145994432205214, -40.67689666113672],
              [-62.745802781816984, -41.0287614886121],
              [-63.77049475773255, -41.16678923926369],
              [-64.73208980981973, -40.80267709733515],
              [-65.11803524439158, -41.06431487402891],
              [-64.97856055363582, -42.05800099056934],
              [-64.3034079657425, -42.35901620866951],
              [-63.75594784204239, -42.043686618824495],
              [-63.458059048095876, -42.563138116222405],
              [-64.37880388045633, -42.87355844499969],
              [-65.18180396183975, -43.495380954767796],
              [-65.32882341171013, -44.501366062193696],
              [-65.5652689276616, -45.036785577169795],
              [-66.50996578638934, -45.03962778094586],
              [-67.29379391139247, -45.55189625425519],
              [-67.58054643418008, -46.30177296324257],
              [-66.59706641301729, -47.033924655953825],
              [-65.64102657740149, -47.23613453551193],
              [-65.98508826360079, -48.133289076531135],
              [-67.16617896184769, -48.697337334996945],
              [-67.81608761256643, -49.86966887797038],
              [-68.72874508327321, -50.26421843851883],
              [-69.13853919134777, -50.732510267947795],
              [-68.81556148952356, -51.771104011594126],
              [-68.14999487982038, -52.34998340612768],
              [-68.57154537624133, -52.299443855346226],
              [-69.49836218939609, -52.14276091263727],
              [-71.91480383979638, -52.0090223058659],
              [-72.32940385607407, -51.42595631287243],
              [-72.30997351753234, -50.67700977966632],
              [-72.97574683296469, -50.741450290734285],
              [-73.32805091011453, -50.378785088909915],
              [-73.4154357571201, -49.31843637471297],
              [-72.64824744331494, -48.87861825947683],
              [-72.33116085477201, -48.2442383766618],
              [-72.44735531278027, -47.73853281025352],
              [-71.91725847033024, -46.88483814879177],
              [-71.55200944689128, -45.5607329241771],
              [-71.65931555854536, -44.973688653341426],
              [-71.22277889675976, -44.784242852559416],
              [-71.32980078803622, -44.407521661151655],
              [-71.79362260607193, -44.207172133156064],
              [-71.46405615913051, -43.787611179378345],
              [-71.91542395698389, -43.40856454851745],
              [-72.14889807807856, -42.254888197601375],
              [-71.7468037584155, -42.05138640723598],
              [-71.91573401557763, -40.83233936947069],
              [-71.68076127794649, -39.808164157878046],
              [-71.41351660834906, -38.91602223079114],
              [-70.81466427273469, -38.55299529394074],
              [-71.11862504747549, -37.57682748794724],
              [-71.12188066270987, -36.65812387466232],
              [-70.36476925320164, -36.00508879978992],
              [-70.38804948594913, -35.16968759535949],
              [-69.81730912950152, -34.1935714657983],
              [-69.81477698431922, -33.273886000299825],
              [-70.0743993801536, -33.09120981214805],
              [-70.53506893581951, -31.36501026787031],
              [-69.91900834825194, -30.33633920666828],
              [-70.01355038112992, -29.367922865518572],
              [-69.65613033718317, -28.459141127233686],
              [-69.00123491074825, -27.52121388113618],
              [-68.29554155137043, -26.89933969493578],
              [-68.59479977077268, -26.506908868111296],
              [-68.38600114609736, -26.185016371365215],
              [-68.41765296087614, -24.51855478281688],
              [-67.32844295924417, -24.02530323659095],
              [-66.9852339341777, -22.98634856536284],
              [-67.1066735500636, -22.735924574476417],
              [-66.27333940292485, -21.83231047942072],
              [-64.96489213729461, -22.075861504812327],
              [-64.37702104354226, -22.79809132252354],
              [-63.986838141522476, -21.99364430103595],
              [-62.84646847192156, -22.03498544686945],
              [-62.685057135657885, -22.249029229422387],
              [-60.846564704009914, -23.880712579038292],
              [-60.02896603050403, -24.032796319273274],
              [-58.80712846539498, -24.77145924245331],
              [-57.77721716981794, -25.16233977630904],
              [-57.63366004091113, -25.60365650808164],
              [-58.61817359071975, -27.123718763947096],
              [-57.60975969097614, -27.395898532828387],
              [-56.486701626192996, -27.548499037386293],
              [-55.69584550639816, -27.387837009390864],
              [-54.78879492859505, -26.621785577096134],
              [-54.625290696823576, -25.739255466415514],
              [-54.13004960795439, -25.547639255477254],
              [-53.628348965048744, -26.124865004177472],
              [-53.64873531758789, -26.92347258881609],
              [-54.490725267135524, -27.47475676850579],
              [-55.16228634298457, -27.881915378533463],
              [-56.29089962423908, -28.852760512000895],
              [-57.62513342958296, -30.21629485445426],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Brazil",
        sov_a3: "BRA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Brazil",
        adm0_a3: "BRA",
        geou_dif: 0,
        geounit: "Brazil",
        gu_a3: "BRA",
        su_dif: 0,
        subunit: "Brazil",
        su_a3: "BRA",
        brk_diff: 0,
        name: "Brazil",
        name_long: "Brazil",
        brk_a3: "BRA",
        brk_name: "Brazil",
        brk_group: null,
        abbrev: "Brazil",
        postal: "BR",
        formal_en: "Federative Republic of Brazil",
        formal_fr: null,
        name_ciawf: "Brazil",
        note_adm0: null,
        note_brk: null,
        name_sort: "Brazil",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 211049527,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1839758,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        fips_10: "BR",
        iso_a2: "BR",
        iso_a2_eh: "BR",
        iso_a3: "BRA",
        iso_a3_eh: "BRA",
        iso_n3: "076",
        iso_n3_eh: "076",
        un_a3: "076",
        wb_a2: "BR",
        wb_a3: "BRA",
        woe_id: 23424768,
        woe_id_eh: 23424768,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BRA",
        adm0_diff: null,
        adm0_tlc: "BRA",
        adm0_a3_us: "BRA",
        adm0_a3_fr: "BRA",
        adm0_a3_ru: "BRA",
        adm0_a3_es: "BRA",
        adm0_a3_cn: "BRA",
        adm0_a3_tw: "BRA",
        adm0_a3_in: "BRA",
        adm0_a3_np: "BRA",
        adm0_a3_pk: "BRA",
        adm0_a3_de: "BRA",
        adm0_a3_gb: "BRA",
        adm0_a3_br: "BRA",
        adm0_a3_il: "BRA",
        adm0_a3_ps: "BRA",
        adm0_a3_sa: "BRA",
        adm0_a3_eg: "BRA",
        adm0_a3_ma: "BRA",
        adm0_a3_pt: "BRA",
        adm0_a3_ar: "BRA",
        adm0_a3_jp: "BRA",
        adm0_a3_ko: "BRA",
        adm0_a3_vn: "BRA",
        adm0_a3_tr: "BRA",
        adm0_a3_id: "BRA",
        adm0_a3_pl: "BRA",
        adm0_a3_gr: "BRA",
        adm0_a3_it: "BRA",
        adm0_a3_nl: "BRA",
        adm0_a3_se: "BRA",
        adm0_a3_bd: "BRA",
        adm0_a3_ua: "BRA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: -49.55945,
        label_y: -12.098687,
        ne_id: 1159320441,
        wikidataid: "Q155",
        name_ar: "البرازيل",
        name_bn: "ব্রাজিল",
        name_de: "Brasilien",
        name_en: "Brazil",
        name_es: "Brasil",
        name_fa: "برزیل",
        name_fr: "Brésil",
        name_el: "Βραζιλία",
        name_he: "ברזיל",
        name_hi: "ब्राज़ील",
        name_hu: "Brazília",
        name_id: "Brasil",
        name_it: "Brasile",
        name_ja: "ブラジル",
        name_ko: "브라질",
        name_nl: "Brazilië",
        name_pl: "Brazylia",
        name_pt: "Brasil",
        name_ru: "Бразилия",
        name_sv: "Brasilien",
        name_tr: "Brezilya",
        name_uk: "Бразилія",
        name_ur: "برازیل",
        name_vi: "Brasil",
        name_zh: "巴西",
        name_zht: "巴西",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BRA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.373661668498244, -33.768377780900764],
            [-53.6505439927181, -33.20200408298183],
            [-53.209588995971544, -32.727666110974724],
            [-53.78795162618219, -32.047242526987624],
            [-54.57245154480512, -31.494511407193748],
            [-55.601510179249345, -30.853878676071393],
            [-55.97324459494094, -30.883075860316303],
            [-56.976025763564735, -30.109686374636127],
            [-57.62513342958296, -30.21629485445426],
            [-56.29089962423908, -28.852760512000895],
            [-55.16228634298457, -27.881915378533463],
            [-54.490725267135524, -27.47475676850579],
            [-53.64873531758789, -26.92347258881609],
            [-53.628348965048744, -26.124865004177472],
            [-54.13004960795439, -25.547639255477254],
            [-54.625290696823576, -25.739255466415514],
            [-54.42894609233059, -25.162184747012166],
            [-54.29347632507745, -24.570799655863965],
            [-54.29295956075452, -24.02101409271073],
            [-54.65283423523513, -23.83957813893396],
            [-55.02790178080955, -24.00127369557523],
            [-55.40074723979542, -23.956935316668805],
            [-55.517639329639636, -23.571997572526637],
            [-55.610682745981144, -22.655619398694846],
            [-55.79795813660691, -22.356929620047822],
            [-56.47331743022939, -22.086300144135283],
            [-56.8815095689029, -22.28215382252148],
            [-57.937155727761294, -22.090175876557172],
            [-57.8706739976178, -20.73268767668195],
            [-58.166392381408045, -20.176700941653678],
            [-57.85380164247451, -19.96999521248619],
            [-57.949997321185826, -19.40000416430682],
            [-57.67600887717431, -18.96183969490403],
            [-57.49837114117099, -18.174187513911292],
            [-57.734558274961, -17.55246835700777],
            [-58.28080400250225, -17.271710300366017],
            [-58.38805843772404, -16.877109063385276],
            [-58.24121985536668, -16.299573256091293],
            [-60.158389655179036, -16.258283786690086],
            [-60.54296566429515, -15.093910414289596],
            [-60.251148851142936, -15.07721892665932],
            [-60.26432634137737, -14.645979099183641],
            [-60.45919816755003, -14.354007256734555],
            [-60.503304002511136, -13.775954685117659],
            [-61.08412126325565, -13.479383640194598],
            [-61.71320431176078, -13.489202162330052],
            [-62.127080857986385, -13.198780612849724],
            [-62.803060268796386, -13.000653171442686],
            [-63.19649878605057, -12.627032565972435],
            [-64.3163529120316, -12.461978041232193],
            [-65.40228146021303, -11.566270440317155],
            [-65.32189876978302, -10.895872084194679],
            [-65.44483700220539, -10.511451104375432],
            [-65.33843522811642, -9.761987806846392],
            [-66.6469083319628, -9.931331475466862],
            [-67.17380123561074, -10.306812432499612],
            [-68.04819230820539, -10.712059014532485],
            [-68.27125362819326, -11.01452117273682],
            [-68.78615759954948, -11.03638030359628],
            [-69.52967810736496, -10.951734307502194],
            [-70.0937522040469, -11.123971856331012],
            [-70.54868567572841, -11.009146823778465],
            [-70.48189388699117, -9.490118096558845],
            [-71.30241227892154, -10.079436130415374],
            [-72.18489071316985, -10.053597914269432],
            [-72.56303300646564, -9.520193780152717],
            [-73.22671342639016, -9.462212823121234],
            [-73.01538265653255, -9.032833347208062],
            [-73.57105933296707, -8.424446709835834],
            [-73.98723548042966, -7.523829847853065],
            [-73.7234014553635, -7.340998630404414],
            [-73.72448666044164, -6.91859547285064],
            [-73.1200274319236, -6.629930922068239],
            [-73.21971126981461, -6.089188734566078],
            [-72.9645072089412, -5.741251315944893],
            [-72.89192765978726, -5.274561455916981],
            [-71.74840572781655, -4.593982842633011],
            [-70.92884334988358, -4.401591485210368],
            [-70.7947688463023, -4.251264743673303],
            [-69.89363521999663, -4.298186944194327],
            [-69.44410193548961, -1.556287123219818],
            [-69.42048580593223, -1.122618503426409],
            [-69.5770653957766, -0.549991957200163],
            [-70.02065589057005, -0.185156345219539],
            [-70.01556576198931, 0.541414292804205],
            [-69.45239600287246, 0.706158758950693],
            [-69.25243404811906, 0.602650865070075],
            [-69.21863766140018, 0.985676581217433],
            [-69.80459672715773, 1.089081122233466],
            [-69.81697323269162, 1.714805202639624],
            [-67.86856502955884, 1.692455145673392],
            [-67.5378100246747, 2.03716278727633],
            [-67.2599975246736, 1.719998684084956],
            [-67.0650481838525, 1.130112209473225],
            [-66.87632585312258, 1.253360500489336],
            [-66.32576514348496, 0.724452215982012],
            [-65.54826738143757, 0.78925446207603],
            [-65.35471330428837, 1.0952822941085],
            [-64.61101192895987, 1.328730576987042],
            [-64.19930579289051, 1.49285492594602],
            [-64.08308549666609, 1.91636912679408],
            [-63.368788011311665, 2.200899562993129],
            [-63.42286739770512, 2.411067613124175],
            [-64.2699991522658, 2.497005520025567],
            [-64.40882788761792, 3.126786200366624],
            [-64.3684944322141, 3.797210394705246],
            [-64.81606401229402, 4.056445217297423],
            [-64.62865943058755, 4.14848094320925],
            [-63.88834286157416, 4.020530096854571],
            [-63.093197597899106, 3.770571193858785],
            [-62.804533047116706, 4.006965033377952],
            [-62.08542965355913, 4.162123521334308],
            [-60.96689327660154, 4.536467596856639],
            [-60.601179165271944, 4.91809804933213],
            [-60.73357418480372, 5.200277207861901],
            [-60.21368343773133, 5.244486395687602],
            [-59.980958624904886, 5.014061184098139],
            [-60.11100236676738, 4.574966538914083],
            [-59.767405768458715, 4.423502915866607],
            [-59.53803992373123, 3.958802598481938],
            [-59.815413174057866, 3.606498521332085],
            [-59.97452490908456, 2.755232652188056],
            [-59.71854570172675, 2.24963043864436],
            [-59.64604366722126, 1.786893825686789],
            [-59.03086157900265, 1.317697658692722],
            [-58.540012986878295, 1.268088283692521],
            [-58.429477098205965, 1.463941962078721],
            [-58.11344987652502, 1.507195135907025],
            [-57.66097103537737, 1.682584947105639],
            [-57.335822923396904, 1.948537705895759],
            [-56.78270423036083, 1.863710842288654],
            [-56.539385748914555, 1.899522609866921],
            [-55.995698004771754, 1.817667141116601],
            [-55.905600145070885, 2.02199575439866],
            [-56.0733418442903, 2.220794989425499],
            [-55.973322109589375, 2.510363877773017],
            [-55.569755011606, 2.421506252447131],
            [-55.09758744975514, 2.523748073736613],
            [-54.524754197799716, 2.311848863123785],
            [-54.08806250671725, 2.105556545414629],
            [-53.77852067728892, 2.376702785650082],
            [-53.554839240113544, 2.334896551925951],
            [-53.41846513529531, 2.053389187015981],
            [-52.939657151894956, 2.124857692875636],
            [-52.55642473001842, 2.504705308437053],
            [-52.249337531123956, 3.241094468596245],
            [-51.65779741067889, 4.156232408053029],
            [-51.31714636901086, 4.203490505383954],
            [-51.069771287629656, 3.650397650564031],
            [-50.508875291533656, 1.901563828942457],
            [-49.97407589374506, 1.736483465986069],
            [-49.94710079608871, 1.046189683431223],
            [-50.699251268096916, 0.222984117021682],
            [-50.38821082213214, -0.078444512536819],
            [-48.62056677915632, -0.235489190271821],
            [-48.58449662941659, -1.237805271005001],
            [-47.824956427590635, -0.5816179337628],
            [-46.566583624851226, -0.941027520352776],
            [-44.905703090990414, -1.551739597178134],
            [-44.417619187993665, -2.137750339367976],
            [-44.58158850765578, -2.691308282078524],
            [-43.418791266440195, -2.383110039889793],
            [-41.47265682632825, -2.912018324397116],
            [-39.97866533055404, -2.873054294449041],
            [-38.50038347019657, -3.700652357603396],
            [-37.2232521225352, -4.820945733258917],
            [-36.45293738457639, -5.109403578312154],
            [-35.59779578301047, -5.149504489770649],
            [-35.23538896334756, -5.464937432480247],
            [-34.89602983248683, -6.738193047719711],
            [-34.729993455533034, -7.343220716992967],
            [-35.12821204277422, -8.996401462442286],
            [-35.636966518687714, -9.649281508017815],
            [-37.046518724097, -11.040721123908803],
            [-37.68361161960736, -12.171194756725823],
            [-38.42387651218844, -13.038118584854288],
            [-38.67388709161652, -13.057652276260619],
            [-38.953275722802545, -13.793369642800023],
            [-38.88229814304965, -15.667053724838768],
            [-39.16109249526431, -17.208406670808472],
            [-39.2673392400564, -17.867746270420483],
            [-39.58352149103423, -18.262295830968938],
            [-39.76082333022764, -19.59911345792741],
            [-40.77474077001034, -20.904511814052423],
            [-40.94475623225061, -21.93731698983781],
            [-41.754164191238225, -22.370675551037458],
            [-41.98828426773656, -22.970070489190896],
            [-43.07470374202475, -22.96769337330547],
            [-44.64781185563781, -23.351959323827842],
            [-45.35213578955992, -23.796841729428582],
            [-46.47209326840554, -24.088968601174543],
            [-47.64897233742066, -24.885199069927722],
            [-48.4954581365777, -25.877024834905654],
            [-48.64100480812774, -26.623697605090932],
            [-48.474735887228654, -27.17591196056189],
            [-48.661520351747626, -28.18613453543572],
            [-48.8884574041574, -28.674115085567884],
            [-49.587329474472675, -29.224469089476337],
            [-50.696874152211485, -30.98446502047296],
            [-51.576226162306156, -31.77769825615321],
            [-52.256081305538046, -32.24536996839467],
            [-52.712099982297694, -33.19657805759118],
            [-53.373661668498244, -33.768377780900764],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Suriname",
        sov_a3: "SUR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Suriname",
        adm0_a3: "SUR",
        geou_dif: 0,
        geounit: "Suriname",
        gu_a3: "SUR",
        su_dif: 0,
        subunit: "Suriname",
        su_a3: "SUR",
        brk_diff: 0,
        name: "Suriname",
        name_long: "Suriname",
        brk_a3: "SUR",
        brk_name: "Suriname",
        brk_group: null,
        abbrev: "Sur.",
        postal: "SR",
        formal_en: "Republic of Suriname",
        formal_fr: null,
        name_ciawf: "Suriname",
        note_adm0: null,
        note_brk: null,
        name_sort: "Suriname",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 7,
        mapcolor13: 6,
        pop_est: 581363,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 3697,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "NS",
        iso_a2: "SR",
        iso_a2_eh: "SR",
        iso_a3: "SUR",
        iso_a3_eh: "SUR",
        iso_n3: "740",
        iso_n3_eh: "740",
        un_a3: "740",
        wb_a2: "SR",
        wb_a3: "SUR",
        woe_id: 23424913,
        woe_id_eh: 23424913,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SUR",
        adm0_diff: null,
        adm0_tlc: "SUR",
        adm0_a3_us: "SUR",
        adm0_a3_fr: "SUR",
        adm0_a3_ru: "SUR",
        adm0_a3_es: "SUR",
        adm0_a3_cn: "SUR",
        adm0_a3_tw: "SUR",
        adm0_a3_in: "SUR",
        adm0_a3_np: "SUR",
        adm0_a3_pk: "SUR",
        adm0_a3_de: "SUR",
        adm0_a3_gb: "SUR",
        adm0_a3_br: "SUR",
        adm0_a3_il: "SUR",
        adm0_a3_ps: "SUR",
        adm0_a3_sa: "SUR",
        adm0_a3_eg: "SUR",
        adm0_a3_ma: "SUR",
        adm0_a3_pt: "SUR",
        adm0_a3_ar: "SUR",
        adm0_a3_jp: "SUR",
        adm0_a3_ko: "SUR",
        adm0_a3_vn: "SUR",
        adm0_a3_tr: "SUR",
        adm0_a3_id: "SUR",
        adm0_a3_pl: "SUR",
        adm0_a3_gr: "SUR",
        adm0_a3_it: "SUR",
        adm0_a3_nl: "SUR",
        adm0_a3_se: "SUR",
        adm0_a3_bd: "SUR",
        adm0_a3_ua: "SUR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -55.91094,
        label_y: 4.143987,
        ne_id: 1159321281,
        wikidataid: "Q730",
        name_ar: "سورينام",
        name_bn: "সুরিনাম",
        name_de: "Suriname",
        name_en: "Suriname",
        name_es: "Surinam",
        name_fa: "سورینام",
        name_fr: "Suriname",
        name_el: "Σουρινάμ",
        name_he: "סורינאם",
        name_hi: "सूरीनाम",
        name_hu: "Suriname",
        name_id: "Suriname",
        name_it: "Suriname",
        name_ja: "スリナム",
        name_ko: "수리남",
        name_nl: "Suriname",
        name_pl: "Surinam",
        name_pt: "Suriname",
        name_ru: "Суринам",
        name_sv: "Surinam",
        name_tr: "Surinam",
        name_uk: "Суринам",
        name_ur: "سرینام",
        name_vi: "Suriname",
        name_zh: "苏里南",
        name_zht: "蘇利南",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SUR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.524754197799716, 2.311848863123785],
            [-55.09758744975514, 2.523748073736613],
            [-55.569755011606, 2.421506252447131],
            [-55.973322109589375, 2.510363877773017],
            [-56.0733418442903, 2.220794989425499],
            [-55.905600145070885, 2.02199575439866],
            [-55.995698004771754, 1.817667141116601],
            [-56.539385748914555, 1.899522609866921],
            [-57.15009782573991, 2.768926906745406],
            [-57.28143347840971, 3.333491929534119],
            [-57.60156897645787, 3.334654649260685],
            [-58.04469438336068, 4.060863552258382],
            [-57.8602095200787, 4.57680105226045],
            [-57.91428890647214, 4.812626451024414],
            [-57.307245856339506, 5.073566595882227],
            [-57.14743648947689, 5.973149929219161],
            [-55.9493184067898, 5.772877915872002],
            [-55.841779751190415, 5.95312531170606],
            [-55.033250291551774, 6.025291449401664],
            [-53.9580446030709, 5.756548163267765],
            [-54.47863298197923, 4.896755682795586],
            [-54.399542202356514, 4.212611395683467],
            [-54.00693050801901, 3.620037746592558],
            [-54.181726040246275, 3.189779771330421],
            [-54.2697051662232, 2.732391669115046],
            [-54.524754197799716, 2.311848863123785],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Guyana",
        sov_a3: "GUY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guyana",
        adm0_a3: "GUY",
        geou_dif: 0,
        geounit: "Guyana",
        gu_a3: "GUY",
        su_dif: 0,
        subunit: "Guyana",
        su_a3: "GUY",
        brk_diff: 0,
        name: "Guyana",
        name_long: "Guyana",
        brk_a3: "GUY",
        brk_name: "Guyana",
        brk_group: null,
        abbrev: "Guy.",
        postal: "GY",
        formal_en: "Co-operative Republic of Guyana",
        formal_fr: null,
        name_ciawf: "Guyana",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guyana",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 782766,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 5173,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GY",
        iso_a2: "GY",
        iso_a2_eh: "GY",
        iso_a3: "GUY",
        iso_a3_eh: "GUY",
        iso_n3: "328",
        iso_n3_eh: "328",
        un_a3: "328",
        wb_a2: "GY",
        wb_a3: "GUY",
        woe_id: 23424836,
        woe_id_eh: 23424836,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GUY",
        adm0_diff: null,
        adm0_tlc: "GUY",
        adm0_a3_us: "GUY",
        adm0_a3_fr: "GUY",
        adm0_a3_ru: "GUY",
        adm0_a3_es: "GUY",
        adm0_a3_cn: "GUY",
        adm0_a3_tw: "GUY",
        adm0_a3_in: "GUY",
        adm0_a3_np: "GUY",
        adm0_a3_pk: "GUY",
        adm0_a3_de: "GUY",
        adm0_a3_gb: "GUY",
        adm0_a3_br: "GUY",
        adm0_a3_il: "GUY",
        adm0_a3_ps: "GUY",
        adm0_a3_sa: "GUY",
        adm0_a3_eg: "GUY",
        adm0_a3_ma: "GUY",
        adm0_a3_pt: "GUY",
        adm0_a3_ar: "GUY",
        adm0_a3_jp: "GUY",
        adm0_a3_ko: "GUY",
        adm0_a3_vn: "GUY",
        adm0_a3_tr: "GUY",
        adm0_a3_id: "GUY",
        adm0_a3_pl: "GUY",
        adm0_a3_gr: "GUY",
        adm0_a3_it: "GUY",
        adm0_a3_nl: "GUY",
        adm0_a3_se: "GUY",
        adm0_a3_bd: "GUY",
        adm0_a3_ua: "GUY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -58.942643,
        label_y: 5.124317,
        ne_id: 1159320817,
        wikidataid: "Q734",
        name_ar: "غيانا",
        name_bn: "গায়ানা",
        name_de: "Guyana",
        name_en: "Guyana",
        name_es: "Guyana",
        name_fa: "گویان",
        name_fr: "Guyana",
        name_el: "Γουιάνα",
        name_he: "גיאנה",
        name_hi: "गयाना",
        name_hu: "Guyana",
        name_id: "Guyana",
        name_it: "Guyana",
        name_ja: "ガイアナ",
        name_ko: "가이아나",
        name_nl: "Guyana",
        name_pl: "Gujana",
        name_pt: "Guiana",
        name_ru: "Гайана",
        name_sv: "Guyana",
        name_tr: "Guyana",
        name_uk: "Гаяна",
        name_ur: "گیانا",
        name_vi: "Guyana",
        name_zh: "圭亚那",
        name_zht: "圭亞那",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GUY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-56.539385748914555, 1.899522609866921],
            [-56.78270423036083, 1.863710842288654],
            [-57.335822923396904, 1.948537705895759],
            [-57.66097103537737, 1.682584947105639],
            [-58.11344987652502, 1.507195135907025],
            [-58.429477098205965, 1.463941962078721],
            [-58.540012986878295, 1.268088283692521],
            [-59.03086157900265, 1.317697658692722],
            [-59.64604366722126, 1.786893825686789],
            [-59.71854570172675, 2.24963043864436],
            [-59.97452490908456, 2.755232652188056],
            [-59.815413174057866, 3.606498521332085],
            [-59.53803992373123, 3.958802598481938],
            [-59.767405768458715, 4.423502915866607],
            [-60.11100236676738, 4.574966538914083],
            [-59.980958624904886, 5.014061184098139],
            [-60.21368343773133, 5.244486395687602],
            [-60.73357418480372, 5.200277207861901],
            [-61.410302903881956, 5.959068101419618],
            [-61.13941504580795, 6.234296779806144],
            [-61.15933631045648, 6.696077378766319],
            [-60.54399919294099, 6.856584377464883],
            [-60.2956680975624, 7.043911444522919],
            [-60.637972785063766, 7.414999904810855],
            [-60.5505879380582, 7.779602972846178],
            [-59.758284878159195, 8.367034816924047],
            [-59.10168412945866, 7.999201971870492],
            [-58.48296220562806, 7.347691351750697],
            [-58.45487606467742, 6.832787380394464],
            [-58.078103196837375, 6.809093736188643],
            [-57.542218593970645, 6.321268215353356],
            [-57.14743648947689, 5.973149929219161],
            [-57.307245856339506, 5.073566595882227],
            [-57.91428890647214, 4.812626451024414],
            [-57.8602095200787, 4.57680105226045],
            [-58.04469438336068, 4.060863552258382],
            [-57.60156897645787, 3.334654649260685],
            [-57.28143347840971, 3.333491929534119],
            [-57.15009782573991, 2.768926906745406],
            [-56.539385748914555, 1.899522609866921],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Uruguay",
        sov_a3: "URY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Uruguay",
        adm0_a3: "URY",
        geou_dif: 0,
        geounit: "Uruguay",
        gu_a3: "URY",
        su_dif: 0,
        subunit: "Uruguay",
        su_a3: "URY",
        brk_diff: 0,
        name: "Uruguay",
        name_long: "Uruguay",
        brk_a3: "URY",
        brk_name: "Uruguay",
        brk_group: null,
        abbrev: "Ury.",
        postal: "UY",
        formal_en: "Oriental Republic of Uruguay",
        formal_fr: null,
        name_ciawf: "Uruguay",
        note_adm0: null,
        note_brk: null,
        name_sort: "Uruguay",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 3461734,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 56045,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "UY",
        iso_a2: "UY",
        iso_a2_eh: "UY",
        iso_a3: "URY",
        iso_a3_eh: "URY",
        iso_n3: "858",
        iso_n3_eh: "858",
        un_a3: "858",
        wb_a2: "UY",
        wb_a3: "URY",
        woe_id: 23424979,
        woe_id_eh: 23424979,
        woe_note: "Exact WOE match as country",
        adm0_iso: "URY",
        adm0_diff: null,
        adm0_tlc: "URY",
        adm0_a3_us: "URY",
        adm0_a3_fr: "URY",
        adm0_a3_ru: "URY",
        adm0_a3_es: "URY",
        adm0_a3_cn: "URY",
        adm0_a3_tw: "URY",
        adm0_a3_in: "URY",
        adm0_a3_np: "URY",
        adm0_a3_pk: "URY",
        adm0_a3_de: "URY",
        adm0_a3_gb: "URY",
        adm0_a3_br: "URY",
        adm0_a3_il: "URY",
        adm0_a3_ps: "URY",
        adm0_a3_sa: "URY",
        adm0_a3_eg: "URY",
        adm0_a3_ma: "URY",
        adm0_a3_pt: "URY",
        adm0_a3_ar: "URY",
        adm0_a3_jp: "URY",
        adm0_a3_ko: "URY",
        adm0_a3_vn: "URY",
        adm0_a3_tr: "URY",
        adm0_a3_id: "URY",
        adm0_a3_pl: "URY",
        adm0_a3_gr: "URY",
        adm0_a3_it: "URY",
        adm0_a3_nl: "URY",
        adm0_a3_se: "URY",
        adm0_a3_bd: "URY",
        adm0_a3_ua: "URY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -55.966942,
        label_y: -32.961127,
        ne_id: 1159321353,
        wikidataid: "Q77",
        name_ar: "الأوروغواي",
        name_bn: "উরুগুয়ে",
        name_de: "Uruguay",
        name_en: "Uruguay",
        name_es: "Uruguay",
        name_fa: "اروگوئه",
        name_fr: "Uruguay",
        name_el: "Ουρουγουάη",
        name_he: "אורוגוואי",
        name_hi: "उरुग्वे",
        name_hu: "Uruguay",
        name_id: "Uruguay",
        name_it: "Uruguay",
        name_ja: "ウルグアイ",
        name_ko: "우루과이",
        name_nl: "Uruguay",
        name_pl: "Urugwaj",
        name_pt: "Uruguai",
        name_ru: "Уругвай",
        name_sv: "Uruguay",
        name_tr: "Uruguay",
        name_uk: "Уругвай",
        name_ur: "یوراگوئے",
        name_vi: "Uruguay",
        name_zh: "乌拉圭",
        name_zht: "烏拉圭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "URY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.62513342958296, -30.21629485445426],
            [-56.976025763564735, -30.109686374636127],
            [-55.97324459494094, -30.883075860316303],
            [-55.601510179249345, -30.853878676071393],
            [-54.57245154480512, -31.494511407193748],
            [-53.78795162618219, -32.047242526987624],
            [-53.209588995971544, -32.727666110974724],
            [-53.6505439927181, -33.20200408298183],
            [-53.373661668498244, -33.768377780900764],
            [-53.806425950726535, -34.39681487400223],
            [-54.93586605489773, -34.952646579733624],
            [-55.67408972840329, -34.75265878676407],
            [-56.21529700379607, -34.85983570733742],
            [-57.1396850246331, -34.430456231424245],
            [-57.81786068381551, -34.4625472958775],
            [-58.42707414410439, -33.909454441057576],
            [-58.349611172098875, -33.26318897881541],
            [-58.13264767112145, -33.040566908502015],
            [-58.14244035504076, -32.044503676076154],
            [-57.87493730328188, -31.016556084926208],
            [-57.62513342958296, -30.21629485445426],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ecuador",
        sov_a3: "ECU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ecuador",
        adm0_a3: "ECU",
        geou_dif: 0,
        geounit: "Ecuador",
        gu_a3: "ECU",
        su_dif: 0,
        subunit: "Ecuador",
        su_a3: "ECU",
        brk_diff: 0,
        name: "Ecuador",
        name_long: "Ecuador",
        brk_a3: "ECU",
        brk_name: "Ecuador",
        brk_group: null,
        abbrev: "Ecu.",
        postal: "EC",
        formal_en: "Republic of Ecuador",
        formal_fr: null,
        name_ciawf: "Ecuador",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ecuador",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 17373662,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 107435,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "EC",
        iso_a2: "EC",
        iso_a2_eh: "EC",
        iso_a3: "ECU",
        iso_a3_eh: "ECU",
        iso_n3: "218",
        iso_n3_eh: "218",
        un_a3: "218",
        wb_a2: "EC",
        wb_a3: "ECU",
        woe_id: 23424801,
        woe_id_eh: 23424801,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ECU",
        adm0_diff: null,
        adm0_tlc: "ECU",
        adm0_a3_us: "ECU",
        adm0_a3_fr: "ECU",
        adm0_a3_ru: "ECU",
        adm0_a3_es: "ECU",
        adm0_a3_cn: "ECU",
        adm0_a3_tw: "ECU",
        adm0_a3_in: "ECU",
        adm0_a3_np: "ECU",
        adm0_a3_pk: "ECU",
        adm0_a3_de: "ECU",
        adm0_a3_gb: "ECU",
        adm0_a3_br: "ECU",
        adm0_a3_il: "ECU",
        adm0_a3_ps: "ECU",
        adm0_a3_sa: "ECU",
        adm0_a3_eg: "ECU",
        adm0_a3_ma: "ECU",
        adm0_a3_pt: "ECU",
        adm0_a3_ar: "ECU",
        adm0_a3_jp: "ECU",
        adm0_a3_ko: "ECU",
        adm0_a3_vn: "ECU",
        adm0_a3_tr: "ECU",
        adm0_a3_id: "ECU",
        adm0_a3_pl: "ECU",
        adm0_a3_gr: "ECU",
        adm0_a3_it: "ECU",
        adm0_a3_nl: "ECU",
        adm0_a3_se: "ECU",
        adm0_a3_bd: "ECU",
        adm0_a3_ua: "ECU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -78.188375,
        label_y: -1.259076,
        ne_id: 1159320567,
        wikidataid: "Q736",
        name_ar: "الإكوادور",
        name_bn: "ইকুয়েডর",
        name_de: "Ecuador",
        name_en: "Ecuador",
        name_es: "Ecuador",
        name_fa: "اکوادور",
        name_fr: "Équateur",
        name_el: "Εκουαδόρ",
        name_he: "אקוודור",
        name_hi: "ईक्वाडोर",
        name_hu: "Ecuador",
        name_id: "Ekuador",
        name_it: "Ecuador",
        name_ja: "エクアドル",
        name_ko: "에콰도르",
        name_nl: "Ecuador",
        name_pl: "Ekwador",
        name_pt: "Equador",
        name_ru: "Эквадор",
        name_sv: "Ecuador",
        name_tr: "Ekvador",
        name_uk: "Еквадор",
        name_ur: "ایکواڈور",
        name_vi: "Ecuador",
        name_zh: "厄瓜多尔",
        name_zht: "厄瓜多爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ECU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.37322323271385, -0.15203175212045],
            [-75.23372270374195, -0.911416924649529],
            [-75.54499569365204, -1.56160979574588],
            [-76.63539425322672, -2.608677666843818],
            [-77.83790483265861, -3.003020521663103],
            [-78.45068396677564, -3.873096612161376],
            [-78.63989722361234, -4.547784112164074],
            [-79.20528906931773, -4.959128513207389],
            [-79.62497921417618, -4.454198093283495],
            [-80.02890804718561, -4.346090996928893],
            [-80.44224199087216, -4.425724379090674],
            [-80.46929460317695, -4.059286797708999],
            [-80.18401485870967, -3.821161797708044],
            [-80.30256059438722, -3.404856459164713],
            [-79.77029334178093, -2.65751189535964],
            [-79.98655921092242, -2.220794366061014],
            [-80.36878394236925, -2.685158786635788],
            [-80.96776546906436, -2.246942640800704],
            [-80.76480628123804, -1.965047702648533],
            [-80.93365902375172, -1.057454522306358],
            [-80.58337032746127, -0.906662692878683],
            [-80.39932471385376, -0.283703301600141],
            [-80.02089820018037, 0.360340074053468],
            [-80.09060970734211, 0.768428859862397],
            [-79.5427620103998, 0.982937730305963],
            [-78.85525875518871, 1.380923773601822],
            [-77.85506140817952, 0.809925034992773],
            [-77.66861284047044, 0.825893052570962],
            [-77.4249843004304, 0.395686753741117],
            [-76.5763797675494, 0.256935533037435],
            [-76.29231441924097, 0.416047268064119],
            [-75.8014658271166, 0.084801337073202],
            [-75.37322323271385, -0.15203175212045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Colombia",
        sov_a3: "COL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Colombia",
        adm0_a3: "COL",
        geou_dif: 0,
        geounit: "Colombia",
        gu_a3: "COL",
        su_dif: 0,
        subunit: "Colombia",
        su_a3: "COL",
        brk_diff: 0,
        name: "Colombia",
        name_long: "Colombia",
        brk_a3: "COL",
        brk_name: "Colombia",
        brk_group: null,
        abbrev: "Col.",
        postal: "CO",
        formal_en: "Republic of Colombia",
        formal_fr: null,
        name_ciawf: "Colombia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Colombia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 50339443,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 323615,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "CO",
        iso_a2: "CO",
        iso_a2_eh: "CO",
        iso_a3: "COL",
        iso_a3_eh: "COL",
        iso_n3: "170",
        iso_n3_eh: "170",
        un_a3: "170",
        wb_a2: "CO",
        wb_a3: "COL",
        woe_id: 23424787,
        woe_id_eh: 23424787,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COL",
        adm0_diff: null,
        adm0_tlc: "COL",
        adm0_a3_us: "COL",
        adm0_a3_fr: "COL",
        adm0_a3_ru: "COL",
        adm0_a3_es: "COL",
        adm0_a3_cn: "COL",
        adm0_a3_tw: "COL",
        adm0_a3_in: "COL",
        adm0_a3_np: "COL",
        adm0_a3_pk: "COL",
        adm0_a3_de: "COL",
        adm0_a3_gb: "COL",
        adm0_a3_br: "COL",
        adm0_a3_il: "COL",
        adm0_a3_ps: "COL",
        adm0_a3_sa: "COL",
        adm0_a3_eg: "COL",
        adm0_a3_ma: "COL",
        adm0_a3_pt: "COL",
        adm0_a3_ar: "COL",
        adm0_a3_jp: "COL",
        adm0_a3_ko: "COL",
        adm0_a3_vn: "COL",
        adm0_a3_tr: "COL",
        adm0_a3_id: "COL",
        adm0_a3_pl: "COL",
        adm0_a3_gr: "COL",
        adm0_a3_it: "COL",
        adm0_a3_nl: "COL",
        adm0_a3_se: "COL",
        adm0_a3_bd: "COL",
        adm0_a3_ua: "COL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: -73.174347,
        label_y: 3.373111,
        ne_id: 1159320517,
        wikidataid: "Q739",
        name_ar: "كولومبيا",
        name_bn: "কলম্বিয়া",
        name_de: "Kolumbien",
        name_en: "Colombia",
        name_es: "Colombia",
        name_fa: "کلمبیا",
        name_fr: "Colombie",
        name_el: "Κολομβία",
        name_he: "קולומביה",
        name_hi: "कोलम्बिया",
        name_hu: "Kolumbia",
        name_id: "Kolombia",
        name_it: "Colombia",
        name_ja: "コロンビア",
        name_ko: "콜롬비아",
        name_nl: "Colombia",
        name_pl: "Kolumbia",
        name_pt: "Colômbia",
        name_ru: "Колумбия",
        name_sv: "Colombia",
        name_tr: "Kolombiya",
        name_uk: "Колумбія",
        name_ur: "کولمبیا",
        name_vi: "Colombia",
        name_zh: "哥伦比亚",
        name_zht: "哥倫比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.87632585312258, 1.253360500489336],
            [-67.0650481838525, 1.130112209473225],
            [-67.2599975246736, 1.719998684084956],
            [-67.5378100246747, 2.03716278727633],
            [-67.86856502955884, 1.692455145673392],
            [-69.81697323269162, 1.714805202639624],
            [-69.80459672715773, 1.089081122233466],
            [-69.21863766140018, 0.985676581217433],
            [-69.25243404811906, 0.602650865070075],
            [-69.45239600287246, 0.706158758950693],
            [-70.01556576198931, 0.541414292804205],
            [-70.02065589057005, -0.185156345219539],
            [-69.5770653957766, -0.549991957200163],
            [-69.42048580593223, -1.122618503426409],
            [-69.44410193548961, -1.556287123219818],
            [-69.89363521999663, -4.298186944194327],
            [-70.39404395209499, -3.766591485207825],
            [-70.69268205430971, -3.742872002785859],
            [-70.04770850287485, -2.725156345229699],
            [-70.81347571479196, -2.256864515800743],
            [-71.41364579942979, -2.342802422702128],
            [-71.7747607082854, -2.169789727388938],
            [-72.32578650581365, -2.434218031426454],
            [-73.07039221870724, -2.308954359550953],
            [-73.6595035468346, -1.260491224781134],
            [-74.12239518908906, -1.002832533373848],
            [-74.44160051135597, -0.530820000819887],
            [-75.10662451852008, -0.05720549886486],
            [-75.37322323271385, -0.15203175212045],
            [-75.8014658271166, 0.084801337073202],
            [-76.29231441924097, 0.416047268064119],
            [-76.5763797675494, 0.256935533037435],
            [-77.4249843004304, 0.395686753741117],
            [-77.66861284047044, 0.825893052570962],
            [-77.85506140817952, 0.809925034992773],
            [-78.85525875518871, 1.380923773601822],
            [-78.99093522817104, 1.691369940595251],
            [-78.61783138702371, 1.766404120283056],
            [-78.66211808949785, 2.267355454920477],
            [-78.42761043975733, 2.629555568854215],
            [-77.93154252797149, 2.696605739752926],
            [-77.51043128122501, 3.325016994638247],
            [-77.12768978545526, 3.849636135265357],
            [-77.49627193877703, 4.087606105969428],
            [-77.3076012844794, 4.667984117039452],
            [-77.53322058786573, 5.582811997902497],
            [-77.31881507028675, 5.84535411216136],
            [-77.47666073272228, 6.691116441266303],
            [-77.88157141794525, 7.223771267114785],
            [-77.7534138658614, 7.709839789252143],
            [-77.43110795765699, 7.638061224798734],
            [-77.24256649444008, 7.935278225125444],
            [-77.47472286651133, 8.524286200388218],
            [-77.35336076527386, 8.67050466555807],
            [-76.83667395700357, 8.638749497914716],
            [-76.08638383655786, 9.336820583529487],
            [-75.67460018584006, 9.443248195834599],
            [-75.66470414905618, 9.774003200718738],
            [-75.48042599150335, 10.618990383339309],
            [-74.90689510771199, 11.083044745320322],
            [-74.27675269234489, 11.102035834187587],
            [-74.1972226630477, 11.310472723836867],
            [-73.41476396350029, 11.22701528568548],
            [-72.62783525255963, 11.731971543825523],
            [-72.23819495307892, 11.955549628136326],
            [-71.75409013536864, 12.437303168177309],
            [-71.3998223537917, 12.376040757695293],
            [-71.13746110704588, 12.112981879113505],
            [-71.3315836249503, 11.776284084515808],
            [-71.97392167833829, 11.60867157637712],
            [-72.22757544624294, 11.10870209395324],
            [-72.61465776232521, 10.821975409381778],
            [-72.9052860175347, 10.450344346554772],
            [-73.02760413276957, 9.736770331252444],
            [-73.30495154488005, 9.151999823437606],
            [-72.7887298245004, 9.085027167187334],
            [-72.6604947577681, 8.625287787302682],
            [-72.43986223009796, 8.405275376820029],
            [-72.36090064155597, 8.002638454617895],
            [-72.47967892117885, 7.632506008327354],
            [-72.44448727078807, 7.423784898300482],
            [-72.19835242378188, 7.340430813013683],
            [-71.96017574734864, 6.991614895043539],
            [-70.67423356798152, 7.087784735538719],
            [-70.09331295437242, 6.96037649172311],
            [-69.38947994655712, 6.099860541198836],
            [-68.98531856960236, 6.206804917826858],
            [-68.26505245631823, 6.153268133972475],
            [-67.69508724635502, 6.267318020040647],
            [-67.34143958196557, 6.095468044454023],
            [-67.52153194850275, 5.556870428891969],
            [-67.74469662135522, 5.221128648291668],
            [-67.82301225449355, 4.503937282728899],
            [-67.62183590358129, 3.839481716319995],
            [-67.33756384954368, 3.542342230641722],
            [-67.30317318385345, 3.31845408773718],
            [-67.8099381171237, 2.820655015469569],
            [-67.44709204778631, 2.600280869960869],
            [-67.18129431829307, 2.250638129074062],
            [-66.87632585312258, 1.253360500489336],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Venezuela",
        sov_a3: "VEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Venezuela",
        adm0_a3: "VEN",
        geou_dif: 0,
        geounit: "Venezuela",
        gu_a3: "VEN",
        su_dif: 0,
        subunit: "Venezuela",
        su_a3: "VEN",
        brk_diff: 0,
        name: "Venezuela",
        name_long: "Venezuela",
        brk_a3: "VEN",
        brk_name: "Venezuela",
        brk_group: null,
        abbrev: "Ven.",
        postal: "VE",
        formal_en: "Bolivarian Republic of Venezuela",
        formal_fr: "República Bolivariana de Venezuela",
        name_ciawf: "Venezuela",
        note_adm0: null,
        note_brk: null,
        name_sort: "Venezuela, RB",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 28515829,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 482359,
        gdp_year: 2014,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "VE",
        iso_a2: "VE",
        iso_a2_eh: "VE",
        iso_a3: "VEN",
        iso_a3_eh: "VEN",
        iso_n3: "862",
        iso_n3_eh: "862",
        un_a3: "862",
        wb_a2: "VE",
        wb_a3: "VEN",
        woe_id: 23424982,
        woe_id_eh: 23424982,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VEN",
        adm0_diff: null,
        adm0_tlc: "VEN",
        adm0_a3_us: "VEN",
        adm0_a3_fr: "VEN",
        adm0_a3_ru: "VEN",
        adm0_a3_es: "VEN",
        adm0_a3_cn: "VEN",
        adm0_a3_tw: "VEN",
        adm0_a3_in: "VEN",
        adm0_a3_np: "VEN",
        adm0_a3_pk: "VEN",
        adm0_a3_de: "VEN",
        adm0_a3_gb: "VEN",
        adm0_a3_br: "VEN",
        adm0_a3_il: "VEN",
        adm0_a3_ps: "VEN",
        adm0_a3_sa: "VEN",
        adm0_a3_eg: "VEN",
        adm0_a3_ma: "VEN",
        adm0_a3_pt: "VEN",
        adm0_a3_ar: "VEN",
        adm0_a3_jp: "VEN",
        adm0_a3_ko: "VEN",
        adm0_a3_vn: "VEN",
        adm0_a3_tr: "VEN",
        adm0_a3_id: "VEN",
        adm0_a3_pl: "VEN",
        adm0_a3_gr: "VEN",
        adm0_a3_it: "VEN",
        adm0_a3_nl: "VEN",
        adm0_a3_se: "VEN",
        adm0_a3_bd: "VEN",
        adm0_a3_ua: "VEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7.5,
        label_x: -64.599381,
        label_y: 7.182476,
        ne_id: 1159321411,
        wikidataid: "Q717",
        name_ar: "فنزويلا",
        name_bn: "ভেনেজুয়েলা",
        name_de: "Venezuela",
        name_en: "Venezuela",
        name_es: "Venezuela",
        name_fa: "ونزوئلا",
        name_fr: "Venezuela",
        name_el: "Βενεζουέλα",
        name_he: "ונצואלה",
        name_hi: "वेनेज़ुएला",
        name_hu: "Venezuela",
        name_id: "Venezuela",
        name_it: "Venezuela",
        name_ja: "ベネズエラ",
        name_ko: "베네수엘라",
        name_nl: "Venezuela",
        name_pl: "Wenezuela",
        name_pt: "Venezuela",
        name_ru: "Венесуэла",
        name_sv: "Venezuela",
        name_tr: "Venezuela",
        name_uk: "Венесуела",
        name_ur: "وینیزویلا",
        name_vi: "Venezuela",
        name_zh: "委内瑞拉",
        name_zht: "委內瑞拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.73357418480372, 5.200277207861901],
            [-60.601179165271944, 4.91809804933213],
            [-60.96689327660154, 4.536467596856639],
            [-62.08542965355913, 4.162123521334308],
            [-62.804533047116706, 4.006965033377952],
            [-63.093197597899106, 3.770571193858785],
            [-63.88834286157416, 4.020530096854571],
            [-64.62865943058755, 4.14848094320925],
            [-64.81606401229402, 4.056445217297423],
            [-64.3684944322141, 3.797210394705246],
            [-64.40882788761792, 3.126786200366624],
            [-64.2699991522658, 2.497005520025567],
            [-63.42286739770512, 2.411067613124175],
            [-63.368788011311665, 2.200899562993129],
            [-64.08308549666609, 1.91636912679408],
            [-64.19930579289051, 1.49285492594602],
            [-64.61101192895987, 1.328730576987042],
            [-65.35471330428837, 1.0952822941085],
            [-65.54826738143757, 0.78925446207603],
            [-66.32576514348496, 0.724452215982012],
            [-66.87632585312258, 1.253360500489336],
            [-67.18129431829307, 2.250638129074062],
            [-67.44709204778631, 2.600280869960869],
            [-67.8099381171237, 2.820655015469569],
            [-67.30317318385345, 3.31845408773718],
            [-67.33756384954368, 3.542342230641722],
            [-67.62183590358129, 3.839481716319995],
            [-67.82301225449355, 4.503937282728899],
            [-67.74469662135522, 5.221128648291668],
            [-67.52153194850275, 5.556870428891969],
            [-67.34143958196557, 6.095468044454023],
            [-67.69508724635502, 6.267318020040647],
            [-68.26505245631823, 6.153268133972475],
            [-68.98531856960236, 6.206804917826858],
            [-69.38947994655712, 6.099860541198836],
            [-70.09331295437242, 6.96037649172311],
            [-70.67423356798152, 7.087784735538719],
            [-71.96017574734864, 6.991614895043539],
            [-72.19835242378188, 7.340430813013683],
            [-72.44448727078807, 7.423784898300482],
            [-72.47967892117885, 7.632506008327354],
            [-72.36090064155597, 8.002638454617895],
            [-72.43986223009796, 8.405275376820029],
            [-72.6604947577681, 8.625287787302682],
            [-72.7887298245004, 9.085027167187334],
            [-73.30495154488005, 9.151999823437606],
            [-73.02760413276957, 9.736770331252444],
            [-72.9052860175347, 10.450344346554772],
            [-72.61465776232521, 10.821975409381778],
            [-72.22757544624294, 11.10870209395324],
            [-71.97392167833829, 11.60867157637712],
            [-71.3315836249503, 11.776284084515808],
            [-71.36000566271082, 11.539993597861212],
            [-71.94704993354651, 11.423282375530022],
            [-71.62086829292019, 10.969459947142795],
            [-71.63306393094109, 10.446494452349029],
            [-72.07417395698451, 9.865651353388373],
            [-71.69564409044654, 9.072263088411248],
            [-71.26455929226773, 9.137194525585983],
            [-71.03999935574339, 9.859992784052409],
            [-71.35008378771079, 10.211935126176215],
            [-71.40062333849224, 10.968969021036015],
            [-70.15529883490652, 11.37548167566004],
            [-70.29384334988103, 11.846822414594214],
            [-69.94324459499683, 12.162307033736099],
            [-69.58430009629747, 11.459610907431212],
            [-68.88299923366445, 11.443384507691563],
            [-68.23327145045873, 10.885744126829946],
            [-68.19412655299763, 10.554653225135922],
            [-67.29624854192633, 10.54586823164631],
            [-66.227864142508, 10.648626817258688],
            [-65.65523759628175, 10.200798855017323],
            [-64.89045223657817, 10.0772146671913],
            [-64.32947872583374, 10.38959870039568],
            [-64.31800655786495, 10.64141795495398],
            [-63.07932247582873, 10.7017243514386],
            [-61.880946010980196, 10.715625311725104],
            [-62.73011898461641, 10.420268662960906],
            [-62.388511928950976, 9.94820445397464],
            [-61.58876746280194, 9.873066921422264],
            [-60.83059668643172, 9.381339829948942],
            [-60.67125240745973, 8.580174261911878],
            [-60.15009558779618, 8.602756862823426],
            [-59.758284878159195, 8.367034816924047],
            [-60.5505879380582, 7.779602972846178],
            [-60.637972785063766, 7.414999904810855],
            [-60.2956680975624, 7.043911444522919],
            [-60.54399919294099, 6.856584377464883],
            [-61.15933631045648, 6.696077378766319],
            [-61.13941504580795, 6.234296779806144],
            [-61.410302903881956, 5.959068101419618],
            [-60.73357418480372, 5.200277207861901],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Paraguay",
        sov_a3: "PRY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Paraguay",
        adm0_a3: "PRY",
        geou_dif: 0,
        geounit: "Paraguay",
        gu_a3: "PRY",
        su_dif: 0,
        subunit: "Paraguay",
        su_a3: "PRY",
        brk_diff: 0,
        name: "Paraguay",
        name_long: "Paraguay",
        brk_a3: "PRY",
        brk_name: "Paraguay",
        brk_group: null,
        abbrev: "Para.",
        postal: "PY",
        formal_en: "Republic of Paraguay",
        formal_fr: null,
        name_ciawf: "Paraguay",
        note_adm0: null,
        note_brk: null,
        name_sort: "Paraguay",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 2,
        pop_est: 7044636,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 38145,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "PA",
        iso_a2: "PY",
        iso_a2_eh: "PY",
        iso_a3: "PRY",
        iso_a3_eh: "PRY",
        iso_n3: "600",
        iso_n3_eh: "600",
        un_a3: "600",
        wb_a2: "PY",
        wb_a3: "PRY",
        woe_id: 23424917,
        woe_id_eh: 23424917,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PRY",
        adm0_diff: null,
        adm0_tlc: "PRY",
        adm0_a3_us: "PRY",
        adm0_a3_fr: "PRY",
        adm0_a3_ru: "PRY",
        adm0_a3_es: "PRY",
        adm0_a3_cn: "PRY",
        adm0_a3_tw: "PRY",
        adm0_a3_in: "PRY",
        adm0_a3_np: "PRY",
        adm0_a3_pk: "PRY",
        adm0_a3_de: "PRY",
        adm0_a3_gb: "PRY",
        adm0_a3_br: "PRY",
        adm0_a3_il: "PRY",
        adm0_a3_ps: "PRY",
        adm0_a3_sa: "PRY",
        adm0_a3_eg: "PRY",
        adm0_a3_ma: "PRY",
        adm0_a3_pt: "PRY",
        adm0_a3_ar: "PRY",
        adm0_a3_jp: "PRY",
        adm0_a3_ko: "PRY",
        adm0_a3_vn: "PRY",
        adm0_a3_tr: "PRY",
        adm0_a3_id: "PRY",
        adm0_a3_pl: "PRY",
        adm0_a3_gr: "PRY",
        adm0_a3_it: "PRY",
        adm0_a3_nl: "PRY",
        adm0_a3_se: "PRY",
        adm0_a3_bd: "PRY",
        adm0_a3_ua: "PRY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -60.146394,
        label_y: -21.674509,
        ne_id: 1159321195,
        wikidataid: "Q733",
        name_ar: "باراغواي",
        name_bn: "প্যারাগুয়ে",
        name_de: "Paraguay",
        name_en: "Paraguay",
        name_es: "Paraguay",
        name_fa: "پاراگوئه",
        name_fr: "Paraguay",
        name_el: "Παραγουάη",
        name_he: "פרגוואי",
        name_hi: "पैराग्वे",
        name_hu: "Paraguay",
        name_id: "Paraguay",
        name_it: "Paraguay",
        name_ja: "パラグアイ",
        name_ko: "파라과이",
        name_nl: "Paraguay",
        name_pl: "Paragwaj",
        name_pt: "Paraguai",
        name_ru: "Парагвай",
        name_sv: "Paraguay",
        name_tr: "Paraguay",
        name_uk: "Парагвай",
        name_ur: "پیراگوئے",
        name_vi: "Paraguay",
        name_zh: "巴拉圭",
        name_zht: "巴拉圭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.166392381408045, -20.176700941653678],
            [-57.8706739976178, -20.73268767668195],
            [-57.937155727761294, -22.090175876557172],
            [-56.8815095689029, -22.28215382252148],
            [-56.47331743022939, -22.086300144135283],
            [-55.79795813660691, -22.356929620047822],
            [-55.610682745981144, -22.655619398694846],
            [-55.517639329639636, -23.571997572526637],
            [-55.40074723979542, -23.956935316668805],
            [-55.02790178080955, -24.00127369557523],
            [-54.65283423523513, -23.83957813893396],
            [-54.29295956075452, -24.02101409271073],
            [-54.29347632507745, -24.570799655863965],
            [-54.42894609233059, -25.162184747012166],
            [-54.625290696823576, -25.739255466415514],
            [-54.78879492859505, -26.621785577096134],
            [-55.69584550639816, -27.387837009390864],
            [-56.486701626192996, -27.548499037386293],
            [-57.60975969097614, -27.395898532828387],
            [-58.61817359071975, -27.123718763947096],
            [-57.63366004091113, -25.60365650808164],
            [-57.77721716981794, -25.16233977630904],
            [-58.80712846539498, -24.77145924245331],
            [-60.02896603050403, -24.032796319273274],
            [-60.846564704009914, -23.880712579038292],
            [-62.685057135657885, -22.249029229422387],
            [-62.291179368729225, -21.051634616787393],
            [-62.2659612697708, -20.513734633061276],
            [-61.78632646345377, -19.633736667562964],
            [-60.04356462262649, -19.342746677327426],
            [-59.11504248720611, -19.3569060197754],
            [-58.183471442280506, -19.868399346600363],
            [-58.166392381408045, -20.176700941653678],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Disputed",
        tlc: "1",
        admin: "Falkland Islands",
        adm0_a3: "FLK",
        geou_dif: 0,
        geounit: "Falkland Islands",
        gu_a3: "FLK",
        su_dif: 0,
        subunit: "Falkland Islands",
        su_a3: "FLK",
        brk_diff: 1,
        name: "Falkland Is.",
        name_long: "Falkland Islands / Malvinas",
        brk_a3: "B12",
        brk_name: "Falkland Is.",
        brk_group: null,
        abbrev: "Flk. Is.",
        postal: "FK",
        formal_en: "Falkland Islands",
        formal_fr: null,
        name_ciawf: "Falkland Islands (Islas Malvinas)",
        note_adm0: "U.K.",
        note_brk: "Admin. by U.K.; Claimed by Argentina",
        name_sort: "Falkland Islands",
        name_alt: "Islas Malvinas",
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 3398,
        pop_rank: 4,
        pop_year: 2016,
        gdp_md: 282,
        gdp_year: 2012,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "FK",
        iso_a2: "FK",
        iso_a2_eh: "FK",
        iso_a3: "FLK",
        iso_a3_eh: "FLK",
        iso_n3: "238",
        iso_n3_eh: "238",
        un_a3: "238",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424814,
        woe_id_eh: 23424814,
        woe_note: "Exact WOE match as country",
        adm0_iso: "B12",
        adm0_diff: null,
        adm0_tlc: "B12",
        adm0_a3_us: "FLK",
        adm0_a3_fr: "FLK",
        adm0_a3_ru: "FLK",
        adm0_a3_es: "FLK",
        adm0_a3_cn: "FLK",
        adm0_a3_tw: "FLK",
        adm0_a3_in: "FLK",
        adm0_a3_np: "FLK",
        adm0_a3_pk: "FLK",
        adm0_a3_de: "FLK",
        adm0_a3_gb: "FLK",
        adm0_a3_br: "FLK",
        adm0_a3_il: "FLK",
        adm0_a3_ps: "FLK",
        adm0_a3_sa: "FLK",
        adm0_a3_eg: "FLK",
        adm0_a3_ma: "FLK",
        adm0_a3_pt: "FLK",
        adm0_a3_ar: "ARG",
        adm0_a3_jp: "FLK",
        adm0_a3_ko: "FLK",
        adm0_a3_vn: "FLK",
        adm0_a3_tr: "FLK",
        adm0_a3_id: "FLK",
        adm0_a3_pl: "FLK",
        adm0_a3_gr: "FLK",
        adm0_a3_it: "FLK",
        adm0_a3_nl: "FLK",
        adm0_a3_se: "FLK",
        adm0_a3_bd: "FLK",
        adm0_a3_ua: "FLK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 12,
        long_len: 27,
        abbrev_len: 8,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9,
        label_x: -58.738602,
        label_y: -51.608913,
        ne_id: 1159320711,
        wikidataid: "Q9648",
        name_ar: "جزر فوكلاند",
        name_bn: "ফকল্যান্ড দ্বীপপুঞ্জ",
        name_de: "Falklandinseln",
        name_en: "Falkland Islands",
        name_es: "Islas Malvinas",
        name_fa: "جزایر فالکلند",
        name_fr: "îles Malouines",
        name_el: "Νήσοι Φώκλαντ",
        name_he: "איי פוקלנד",
        name_hi: "फ़ॉकलैंड द्वीपसमूह",
        name_hu: "Falkland-szigetek",
        name_id: "Kepulauan Falkland",
        name_it: "Isole Falkland",
        name_ja: "フォークランド諸島",
        name_ko: "포클랜드 제도",
        name_nl: "Falklandeilanden",
        name_pl: "Falklandy",
        name_pt: "Ilhas Malvinas",
        name_ru: "Фолклендские острова",
        name_sv: "Falklandsöarna",
        name_tr: "Falkland Adaları",
        name_uk: "Фолклендські острови",
        name_ur: "جزائر فاکلینڈ",
        name_vi: "Quần đảo Falkland",
        name_zh: "福克兰群岛",
        name_zht: "福克蘭群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: "Unrecognized",
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FLK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.2, -51.85],
            [-60, -51.25],
            [-59.15, -51.5],
            [-58.55, -51.1],
            [-57.75, -51.55],
            [-58.05, -51.9],
            [-59.4, -52.2],
            [-59.85, -51.85],
            [-60.7, -52.3],
            [-61.2, -51.85],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Papua New Guinea",
        sov_a3: "PNG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Papua New Guinea",
        adm0_a3: "PNG",
        geou_dif: 0,
        geounit: "Papua New Guinea",
        gu_a3: "PNG",
        su_dif: 1,
        subunit: "Papua New Guinea",
        su_a3: "PN1",
        brk_diff: 0,
        name: "Papua New Guinea",
        name_long: "Papua New Guinea",
        brk_a3: "PN1",
        brk_name: "Papua New Guinea",
        brk_group: null,
        abbrev: "P.N.G.",
        postal: "PG",
        formal_en: "Independent State of Papua New Guinea",
        formal_fr: null,
        name_ciawf: "Papua New Guinea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Papua New Guinea",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 8776109,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 24829,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "PP",
        iso_a2: "PG",
        iso_a2_eh: "PG",
        iso_a3: "PNG",
        iso_a3_eh: "PNG",
        iso_n3: "598",
        iso_n3_eh: "598",
        un_a3: "598",
        wb_a2: "PG",
        wb_a3: "PNG",
        woe_id: 23424926,
        woe_id_eh: 23424926,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PN1",
        adm0_diff: null,
        adm0_tlc: "PN1",
        adm0_a3_us: "PNG",
        adm0_a3_fr: "PNG",
        adm0_a3_ru: "PNG",
        adm0_a3_es: "PNG",
        adm0_a3_cn: "PNG",
        adm0_a3_tw: "PNG",
        adm0_a3_in: "PNG",
        adm0_a3_np: "PNG",
        adm0_a3_pk: "PNG",
        adm0_a3_de: "PNG",
        adm0_a3_gb: "PNG",
        adm0_a3_br: "PNG",
        adm0_a3_il: "PNG",
        adm0_a3_ps: "PNG",
        adm0_a3_sa: "PNG",
        adm0_a3_eg: "PNG",
        adm0_a3_ma: "PNG",
        adm0_a3_pt: "PNG",
        adm0_a3_ar: "PNG",
        adm0_a3_jp: "PNG",
        adm0_a3_ko: "PNG",
        adm0_a3_vn: "PNG",
        adm0_a3_tr: "PNG",
        adm0_a3_id: "PNG",
        adm0_a3_pl: "PNG",
        adm0_a3_gr: "PNG",
        adm0_a3_it: "PNG",
        adm0_a3_nl: "PNG",
        adm0_a3_se: "PNG",
        adm0_a3_bd: "PNG",
        adm0_a3_ua: "PNG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 16,
        long_len: 16,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7.5,
        label_x: 143.910216,
        label_y: -5.695285,
        ne_id: 1159321173,
        wikidataid: "Q691",
        name_ar: "بابوا غينيا الجديدة",
        name_bn: "পাপুয়া নিউগিনি",
        name_de: "Papua-Neuguinea",
        name_en: "Papua New Guinea",
        name_es: "Papúa Nueva Guinea",
        name_fa: "پاپوآ گینه نو",
        name_fr: "Papouasie-Nouvelle-Guinée",
        name_el: "Παπούα Νέα Γουινέα",
        name_he: "פפואה גינאה החדשה",
        name_hi: "पापुआ न्यू गिनी",
        name_hu: "Pápua Új-Guinea",
        name_id: "Papua Nugini",
        name_it: "Papua Nuova Guinea",
        name_ja: "パプアニューギニア",
        name_ko: "파푸아뉴기니",
        name_nl: "Papoea-Nieuw-Guinea",
        name_pl: "Papua-Nowa Gwinea",
        name_pt: "Papua-Nova Guiné",
        name_ru: "Папуа — Новая Гвинея",
        name_sv: "Papua Nya Guinea",
        name_tr: "Papua Yeni Gine",
        name_uk: "Папуа Нова Гвінея",
        name_ur: "پاپوا نیو گنی",
        name_vi: "Papua New Guinea",
        name_zh: "巴布亚新几内亚",
        name_zht: "巴布亞紐幾內亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PNG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [141.00021040259185, -2.60015105551566],
              [142.73524661679147, -3.28915292726321],
              [144.58397098203324, -3.861417738463416],
              [145.27317955950997, -4.373737888205049],
              [145.8297864117257, -4.876497897972683],
              [145.981921828393, -5.465609226100043],
              [147.64807335834757, -6.083659356310847],
              [147.89110761941623, -6.614014580922343],
              [146.97090538959486, -6.721656589386313],
              [147.19187381407494, -7.388024183790023],
              [148.08463585834932, -8.044108168167647],
              [148.73410525939357, -9.104663588093764],
              [149.30683515848443, -9.07143564213009],
              [149.26663089416132, -9.514406019736029],
              [150.03872846903425, -9.684318129111709],
              [149.7387984560122, -9.872937106977048],
              [150.80162763895913, -10.293686618697478],
              [150.6905749859639, -10.582712904505925],
              [150.02839318257583, -10.652476088099952],
              [149.78231001200197, -10.393267103723923],
              [148.92313764871727, -10.280922539921384],
              [147.913018426708, -10.13044076908745],
              [147.13544315001218, -9.492443536011983],
              [146.56788089415056, -8.942554619994155],
              [146.04848107318492, -8.067414239131281],
              [144.74416792213805, -7.630128269077446],
              [143.89708784400966, -7.915330498896296],
              [143.28637576718432, -8.24549122480908],
              [143.41391320208066, -8.983068942910982],
              [142.62843143124417, -9.326820570516524],
              [142.06825890520025, -9.159595635620022],
              [141.03385176001382, -9.117892754760483],
              [141.01705691951895, -5.859021905138071],
              [141.00021040259185, -2.60015105551566],
            ],
          ],
          [
            [
              [152.64001671774253, -3.659983005389691],
              [153.0199935243847, -3.980015150573265],
              [153.14003787659874, -4.499983412294092],
              [152.82729210836828, -4.766427097190991],
              [152.63867313050298, -4.176127211120921],
              [152.40602583232493, -3.789742526874583],
              [151.95323693258354, -3.462062269711816],
              [151.38427941305002, -3.035421644710112],
              [150.66204959533883, -2.741486097833935],
              [150.93996544820448, -2.500002129734007],
              [151.47998416565457, -2.779985039891379],
              [151.8200150901351, -2.999971612157886],
              [152.23998945537113, -3.24000864015364],
              [152.64001671774253, -3.659983005389691],
            ],
          ],
          [
            [
              [151.30139041565388, -5.840728448106752],
              [150.75444705627666, -6.083762709175431],
              [150.2411967307538, -6.317753594593028],
              [149.70996300679332, -6.316513360218025],
              [148.89006473205046, -6.026040134305404],
              [148.31893680236067, -5.747142429226166],
              [148.40182579975686, -5.437755629094717],
              [149.29841190002082, -5.58374155031926],
              [149.84556196512722, -5.505503431829368],
              [149.99625044169028, -5.026101169457654],
              [150.13975589416486, -5.001348158389852],
              [150.23690758687354, -5.532220147324267],
              [150.80746707580812, -5.455842380396874],
              [151.08967207255404, -5.113692722192383],
              [151.6478808941709, -4.757073662946162],
              [151.53786176982146, -4.167807305521933],
              [152.1367916200843, -4.14879037843852],
              [152.33874311748093, -4.312966403829805],
              [152.3186926617517, -4.867661228050771],
              [151.98279585185452, -5.478063246282382],
              [151.45910688700866, -5.560280450058754],
              [151.30139041565388, -5.840728448106752],
            ],
          ],
          [
            [
              [154.75999067608439, -5.339983819198495],
              [155.06291792217934, -5.56679168052753],
              [155.5477462099417, -6.200654799019645],
              [156.0199654482248, -6.540013929880381],
              [155.8800256695784, -6.819996840037753],
              [155.59999108298877, -6.919990736522522],
              [155.16699425681514, -6.535931491729322],
              [154.72919152243838, -5.900828138862195],
              [154.51411421123964, -5.139117526879986],
              [154.65250369691728, -5.04243092206189],
              [154.75999067608439, -5.339983819198495],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "New Caledonia",
        adm0_a3: "NCL",
        geou_dif: 0,
        geounit: "New Caledonia",
        gu_a3: "NCL",
        su_dif: 0,
        subunit: "New Caledonia",
        su_a3: "NCL",
        brk_diff: 0,
        name: "New Caledonia",
        name_long: "New Caledonia",
        brk_a3: "NCL",
        brk_name: "New Caledonia",
        brk_group: null,
        abbrev: "New C.",
        postal: "NC",
        formal_en: "New Caledonia",
        formal_fr: "Nouvelle-Calédonie",
        name_ciawf: "New Caledonia",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "New Caledonia",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 287800,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 10770,
        gdp_year: 2016,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "NC",
        iso_a2: "NC",
        iso_a2_eh: "NC",
        iso_a3: "NCL",
        iso_a3_eh: "NCL",
        iso_n3: "540",
        iso_n3_eh: "540",
        un_a3: "540",
        wb_a2: "NC",
        wb_a3: "NCL",
        woe_id: 23424903,
        woe_id_eh: 23424903,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NCL",
        adm0_diff: null,
        adm0_tlc: "NCL",
        adm0_a3_us: "NCL",
        adm0_a3_fr: "NCL",
        adm0_a3_ru: "NCL",
        adm0_a3_es: "NCL",
        adm0_a3_cn: "NCL",
        adm0_a3_tw: "NCL",
        adm0_a3_in: "NCL",
        adm0_a3_np: "NCL",
        adm0_a3_pk: "NCL",
        adm0_a3_de: "NCL",
        adm0_a3_gb: "NCL",
        adm0_a3_br: "NCL",
        adm0_a3_il: "NCL",
        adm0_a3_ps: "NCL",
        adm0_a3_sa: "NCL",
        adm0_a3_eg: "NCL",
        adm0_a3_ma: "NCL",
        adm0_a3_pt: "NCL",
        adm0_a3_ar: "NCL",
        adm0_a3_jp: "NCL",
        adm0_a3_ko: "NCL",
        adm0_a3_vn: "NCL",
        adm0_a3_tr: "NCL",
        adm0_a3_id: "NCL",
        adm0_a3_pl: "NCL",
        adm0_a3_gr: "NCL",
        adm0_a3_it: "NCL",
        adm0_a3_nl: "NCL",
        adm0_a3_se: "NCL",
        adm0_a3_bd: "NCL",
        adm0_a3_ua: "NCL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 13,
        long_len: 13,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.6,
        max_label: 8,
        label_x: 165.084004,
        label_y: -21.064697,
        ne_id: 1159320641,
        wikidataid: "Q33788",
        name_ar: "كاليدونيا الجديدة",
        name_bn: "নতুন ক্যালিডোনিয়া",
        name_de: "Neukaledonien",
        name_en: "New Caledonia",
        name_es: "Nueva Caledonia",
        name_fa: "کالدونیای جدید",
        name_fr: "Nouvelle-Calédonie",
        name_el: "Νέα Καληδονία",
        name_he: "קלדוניה החדשה",
        name_hi: "नया कैलेडोनिया",
        name_hu: "Új-Kaledónia",
        name_id: "Kaledonia Baru",
        name_it: "Nuova Caledonia",
        name_ja: "ニューカレドニア",
        name_ko: "누벨칼레도니",
        name_nl: "Nieuw-Caledonië",
        name_pl: "Nowa Kaledonia",
        name_pt: "Nova Caledónia",
        name_ru: "Новая Каледония",
        name_sv: "Nya Kaledonien",
        name_tr: "Yeni Kaledonya",
        name_uk: "Нова Каледонія",
        name_ur: "نیو کیلیڈونیا",
        name_vi: "Nouvelle-Calédonie",
        name_zh: "新喀里多尼亚",
        name_zht: "新喀里多尼亞",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NCL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [165.77998986232637, -21.08000497811563],
            [166.59999148993384, -21.700018812753527],
            [167.1200114280869, -22.15999073658349],
            [166.7400346214448, -22.39997608814695],
            [166.18973229396866, -22.129708347260454],
            [165.47437544175222, -21.679606621998232],
            [164.82981530177568, -21.14981983814195],
            [164.16799523341365, -20.444746595951628],
            [164.029605747736, -20.105645847252354],
            [164.45996707586272, -20.1200118954295],
            [165.02003624904205, -20.45999114347773],
            [165.46000939357512, -20.80002206795826],
            [165.77998986232637, -21.08000497811563],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Fiji",
        sov_a3: "FJI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Fiji",
        adm0_a3: "FJI",
        geou_dif: 0,
        geounit: "Fiji",
        gu_a3: "FJI",
        su_dif: 0,
        subunit: "Fiji",
        su_a3: "FJI",
        brk_diff: 0,
        name: "Fiji",
        name_long: "Fiji",
        brk_a3: "FJI",
        brk_name: "Fiji",
        brk_group: null,
        abbrev: "Fiji",
        postal: "FJ",
        formal_en: "Republic of Fiji",
        formal_fr: null,
        name_ciawf: "Fiji",
        note_adm0: null,
        note_brk: null,
        name_sort: "Fiji",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 889953,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 5496,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "FJ",
        iso_a2: "FJ",
        iso_a2_eh: "FJ",
        iso_a3: "FJI",
        iso_a3_eh: "FJI",
        iso_n3: "242",
        iso_n3_eh: "242",
        un_a3: "242",
        wb_a2: "FJ",
        wb_a3: "FJI",
        woe_id: 23424813,
        woe_id_eh: 23424813,
        woe_note: "Exact WOE match as country",
        adm0_iso: "FJI",
        adm0_diff: null,
        adm0_tlc: "FJI",
        adm0_a3_us: "FJI",
        adm0_a3_fr: "FJI",
        adm0_a3_ru: "FJI",
        adm0_a3_es: "FJI",
        adm0_a3_cn: "FJI",
        adm0_a3_tw: "FJI",
        adm0_a3_in: "FJI",
        adm0_a3_np: "FJI",
        adm0_a3_pk: "FJI",
        adm0_a3_de: "FJI",
        adm0_a3_gb: "FJI",
        adm0_a3_br: "FJI",
        adm0_a3_il: "FJI",
        adm0_a3_ps: "FJI",
        adm0_a3_sa: "FJI",
        adm0_a3_eg: "FJI",
        adm0_a3_ma: "FJI",
        adm0_a3_pt: "FJI",
        adm0_a3_ar: "FJI",
        adm0_a3_jp: "FJI",
        adm0_a3_ko: "FJI",
        adm0_a3_vn: "FJI",
        adm0_a3_tr: "FJI",
        adm0_a3_id: "FJI",
        adm0_a3_pl: "FJI",
        adm0_a3_gr: "FJI",
        adm0_a3_it: "FJI",
        adm0_a3_nl: "FJI",
        adm0_a3_se: "FJI",
        adm0_a3_bd: "FJI",
        adm0_a3_ua: "FJI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 177.975427,
        label_y: -17.826099,
        ne_id: 1159320625,
        wikidataid: "Q712",
        name_ar: "فيجي",
        name_bn: "ফিজি",
        name_de: "Fidschi",
        name_en: "Fiji",
        name_es: "Fiyi",
        name_fa: "فیجی",
        name_fr: "Fidji",
        name_el: "Φίτζι",
        name_he: "פיג'י",
        name_hi: "फ़िजी",
        name_hu: "Fidzsi-szigetek",
        name_id: "Fiji",
        name_it: "Figi",
        name_ja: "フィジー",
        name_ko: "피지",
        name_nl: "Fiji",
        name_pl: "Fidżi",
        name_pt: "Fiji",
        name_ru: "Фиджи",
        name_sv: "Fiji",
        name_tr: "Fiji",
        name_uk: "Фіджі",
        name_ur: "فجی",
        name_vi: "Fiji",
        name_zh: "斐济",
        name_zht: "斐濟",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FJI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [180, -16.067132663642447],
              [180, -16.555216566639196],
              [179.36414266196414, -16.801354076946883],
              [178.72505936299711, -17.01204167436804],
              [178.59683859511713, -16.63915],
              [179.0966093629971, -16.433984277547403],
              [179.4135093629971, -16.379054277547404],
              [180, -16.067132663642447],
            ],
          ],
          [
            [
              [178.12557, -17.50481],
              [178.3736, -17.33992],
              [178.71806, -17.62846],
              [178.55271, -18.15059],
              [177.93266, -18.28799],
              [177.38146, -18.16432],
              [177.28504, -17.72465],
              [177.67087, -17.38114],
              [178.12557, -17.50481],
            ],
          ],
          [
            [
              [-179.79332010904864, -16.020882256741224],
              [-179.9173693847653, -16.501783135649397],
              [-180, -16.555216566639196],
              [-180, -16.067132663642447],
              [-179.79332010904864, -16.020882256741224],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "New Zealand",
        sov_a3: "NZ1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "New Zealand",
        adm0_a3: "NZL",
        geou_dif: 0,
        geounit: "New Zealand",
        gu_a3: "NZL",
        su_dif: 0,
        subunit: "New Zealand",
        su_a3: "NZL",
        brk_diff: 0,
        name: "New Zealand",
        name_long: "New Zealand",
        brk_a3: "NZL",
        brk_name: "New Zealand",
        brk_group: null,
        abbrev: "N.Z.",
        postal: "NZ",
        formal_en: "New Zealand",
        formal_fr: null,
        name_ciawf: "New Zealand",
        note_adm0: null,
        note_brk: null,
        name_sort: "New Zealand",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 4917000,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 206928,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "NZ",
        iso_a2: "NZ",
        iso_a2_eh: "NZ",
        iso_a3: "NZL",
        iso_a3_eh: "NZL",
        iso_n3: "554",
        iso_n3_eh: "554",
        un_a3: "554",
        wb_a2: "NZ",
        wb_a3: "NZL",
        woe_id: 23424916,
        woe_id_eh: 23424916,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NZL",
        adm0_diff: null,
        adm0_tlc: "NZL",
        adm0_a3_us: "NZL",
        adm0_a3_fr: "NZL",
        adm0_a3_ru: "NZL",
        adm0_a3_es: "NZL",
        adm0_a3_cn: "NZL",
        adm0_a3_tw: "NZL",
        adm0_a3_in: "NZL",
        adm0_a3_np: "NZL",
        adm0_a3_pk: "NZL",
        adm0_a3_de: "NZL",
        adm0_a3_gb: "NZL",
        adm0_a3_br: "NZL",
        adm0_a3_il: "NZL",
        adm0_a3_ps: "NZL",
        adm0_a3_sa: "NZL",
        adm0_a3_eg: "NZL",
        adm0_a3_ma: "NZL",
        adm0_a3_pt: "NZL",
        adm0_a3_ar: "NZL",
        adm0_a3_jp: "NZL",
        adm0_a3_ko: "NZL",
        adm0_a3_vn: "NZL",
        adm0_a3_tr: "NZL",
        adm0_a3_id: "NZL",
        adm0_a3_pl: "NZL",
        adm0_a3_gr: "NZL",
        adm0_a3_it: "NZL",
        adm0_a3_nl: "NZL",
        adm0_a3_se: "NZL",
        adm0_a3_bd: "NZL",
        adm0_a3_ua: "NZL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 6.7,
        label_x: 172.787,
        label_y: -39.759,
        ne_id: 1159321135,
        wikidataid: "Q664",
        name_ar: "نيوزيلندا",
        name_bn: "নিউজিল্যান্ড",
        name_de: "Neuseeland",
        name_en: "New Zealand",
        name_es: "Nueva Zelanda",
        name_fa: "نیوزیلند",
        name_fr: "Nouvelle-Zélande",
        name_el: "Νέα Ζηλανδία",
        name_he: "ניו זילנד",
        name_hi: "न्यूज़ीलैण्ड",
        name_hu: "Új-Zéland",
        name_id: "Selandia Baru",
        name_it: "Nuova Zelanda",
        name_ja: "ニュージーランド",
        name_ko: "뉴질랜드",
        name_nl: "Nieuw-Zeeland",
        name_pl: "Nowa Zelandia",
        name_pt: "Nova Zelândia",
        name_ru: "Новая Зеландия",
        name_sv: "Nya Zeeland",
        name_tr: "Yeni Zelanda",
        name_uk: "Нова Зеландія",
        name_ur: "نیوزی لینڈ",
        name_vi: "New Zealand",
        name_zh: "新西兰",
        name_zht: "新西蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NZL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [176.8858236026052, -40.0659778785822],
              [176.50801720611926, -40.60480803808957],
              [176.01244022044023, -41.28962411882147],
              [175.23956749908297, -41.68830779395328],
              [175.06789839100935, -41.42589487077513],
              [174.65097293527847, -41.2818209775454],
              [175.22763024322356, -40.45923552832336],
              [174.9001566917899, -39.9089332008472],
              [173.82404666574394, -39.50885426204351],
              [173.85226199777532, -39.14660247167748],
              [174.57480187408035, -38.797683200842755],
              [174.74347374908098, -38.02780771255843],
              [174.6970166364506, -37.38112883885792],
              [174.2920284365792, -36.71109221776149],
              [174.31900353423552, -36.53482390721391],
              [173.8409965355357, -36.12198088963413],
              [173.0541711774596, -35.23712533950039],
              [172.63600548735377, -34.52910654066943],
              [173.00704227120946, -34.45066171645037],
              [173.55129845610747, -35.00618336358801],
              [174.3293904971262, -35.26549570082862],
              [174.61200890533044, -36.15639739354052],
              [175.33661583892712, -37.20909799575827],
              [175.3575964704376, -36.52619394302117],
              [175.80888675364253, -36.79894215265767],
              [175.9584900251275, -37.55538176854612],
              [176.76319542877656, -37.881253350578675],
              [177.4388131045605, -37.961248467766495],
              [178.01035444570866, -37.579824721020174],
              [178.51709354076274, -37.6953732236248],
              [178.27473107331383, -38.58281259537314],
              [177.97046023997927, -39.166342868812976],
              [177.20699262929918, -39.14577564876082],
              [176.93998050364706, -39.44973642350161],
              [177.0329464053401, -39.879942722331464],
              [176.8858236026052, -40.0659778785822],
            ],
          ],
          [
            [
              [169.66781456937315, -43.555325616226376],
              [170.52491987536615, -43.031688327812816],
              [171.125089960004, -42.51275359473782],
              [171.56971398344325, -41.767424411792135],
              [171.94870893787186, -41.514416599291124],
              [172.0972270042787, -40.956104424809716],
              [172.79857954334403, -40.4939620908235],
              [173.02037479074076, -40.919052422856446],
              [173.2472343285021, -41.33199879330081],
              [173.9584053897028, -40.926700534835646],
              [174.24758670480816, -41.34915536882171],
              [174.24851688058942, -41.77000823340673],
              [173.87644656808794, -42.23318409603879],
              [173.2227396995957, -42.970038344088614],
              [172.71124637277074, -43.372287693048555],
              [173.08011274647015, -43.853343601253606],
              [172.3085836123525, -43.86569426857136],
              [171.45292524646362, -44.2425188128437],
              [171.18513797432718, -44.89710418068486],
              [170.61669721911653, -45.908928724959736],
              [169.8314221540093, -46.35577483498757],
              [169.33233117093428, -46.641235446967876],
              [168.41135379462855, -46.61994475686363],
              [167.76374474514682, -46.29019744240919],
              [166.67688602118417, -46.219917494492236],
              [166.50914432196467, -45.85270476662619],
              [167.0464241885033, -45.110941257508635],
              [168.30376346259686, -44.12397307716614],
              [168.94940880765157, -43.935819187191434],
              [169.66781456937315, -43.555325616226376],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Australia",
        sov_a3: "AU1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Australia",
        adm0_a3: "AUS",
        geou_dif: 0,
        geounit: "Australia",
        gu_a3: "AUS",
        su_dif: 0,
        subunit: "Australia",
        su_a3: "AUS",
        brk_diff: 0,
        name: "Australia",
        name_long: "Australia",
        brk_a3: "AUS",
        brk_name: "Australia",
        brk_group: null,
        abbrev: "Auz.",
        postal: "AU",
        formal_en: "Commonwealth of Australia",
        formal_fr: null,
        name_ciawf: "Australia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Australia",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 7,
        pop_est: 25364307,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 1396567,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "AS",
        iso_a2: "AU",
        iso_a2_eh: "AU",
        iso_a3: "AUS",
        iso_a3_eh: "AUS",
        iso_n3: "036",
        iso_n3_eh: "036",
        un_a3: "036",
        wb_a2: "AU",
        wb_a3: "AUS",
        woe_id: -90,
        woe_id_eh: 23424748,
        woe_note:
          "Includes Ashmore and Cartier Islands (23424749) and Coral Sea Islands (23424790).",
        adm0_iso: "AUS",
        adm0_diff: null,
        adm0_tlc: "AUS",
        adm0_a3_us: "AUS",
        adm0_a3_fr: "AUS",
        adm0_a3_ru: "AUS",
        adm0_a3_es: "AUS",
        adm0_a3_cn: "AUS",
        adm0_a3_tw: "AUS",
        adm0_a3_in: "AUS",
        adm0_a3_np: "AUS",
        adm0_a3_pk: "AUS",
        adm0_a3_de: "AUS",
        adm0_a3_gb: "AUS",
        adm0_a3_br: "AUS",
        adm0_a3_il: "AUS",
        adm0_a3_ps: "AUS",
        adm0_a3_sa: "AUS",
        adm0_a3_eg: "AUS",
        adm0_a3_ma: "AUS",
        adm0_a3_pt: "AUS",
        adm0_a3_ar: "AUS",
        adm0_a3_jp: "AUS",
        adm0_a3_ko: "AUS",
        adm0_a3_vn: "AUS",
        adm0_a3_tr: "AUS",
        adm0_a3_id: "AUS",
        adm0_a3_pl: "AUS",
        adm0_a3_gr: "AUS",
        adm0_a3_it: "AUS",
        adm0_a3_nl: "AUS",
        adm0_a3_se: "AUS",
        adm0_a3_bd: "AUS",
        adm0_a3_ua: "AUS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: 134.04972,
        label_y: -24.129522,
        ne_id: 1159320355,
        wikidataid: "Q408",
        name_ar: "أستراليا",
        name_bn: "অস্ট্রেলিয়া",
        name_de: "Australien",
        name_en: "Australia",
        name_es: "Australia",
        name_fa: "استرالیا",
        name_fr: "Australie",
        name_el: "Αυστραλία",
        name_he: "אוסטרליה",
        name_hi: "ऑस्ट्रेलिया",
        name_hu: "Ausztrália",
        name_id: "Australia",
        name_it: "Australia",
        name_ja: "オーストラリア",
        name_ko: "오스트레일리아",
        name_nl: "Australië",
        name_pl: "Australia",
        name_pt: "Austrália",
        name_ru: "Австралия",
        name_sv: "Australien",
        name_tr: "Avustralya",
        name_uk: "Австралія",
        name_ur: "آسٹریلیا",
        name_vi: "Úc",
        name_zh: "澳大利亚",
        name_zht: "澳大利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AUS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [147.68925947488418, -40.808258152022674],
              [148.289067824496, -40.87543751400211],
              [148.35986453673587, -42.06244516374644],
              [148.01730146707303, -42.40702361426865],
              [147.91405195535384, -43.211522312188535],
              [147.56456424376393, -42.937688897473905],
              [146.87034305235488, -43.6345972633621],
              [146.66332726459365, -43.58085377377856],
              [146.04837772032033, -43.549744561538844],
              [145.4319295595106, -42.693776137056254],
              [145.29509036680173, -42.033609714527564],
              [144.71807132383066, -41.16255177181576],
              [144.7437545106797, -40.70397511165767],
              [145.3979781434948, -40.79254851660594],
              [146.3641207216237, -41.13769540788336],
              [146.90858361225088, -41.00054615658073],
              [147.68925947488418, -40.808258152022674],
            ],
          ],
          [
            [
              [126.14871382050114, -32.21596607842059],
              [125.08862348846566, -32.72875131605285],
              [124.22164798390492, -32.95948658623607],
              [124.02894656788851, -33.4838473447017],
              [123.65966678273077, -33.89017913181271],
              [122.81103641163364, -33.914467054989885],
              [122.1830644064228, -34.0034021949642],
              [121.29919070850259, -33.821036065406176],
              [120.58026818245806, -33.93017669040661],
              [119.89369510302822, -33.9760653622818],
              [119.29889936734875, -34.50936614353394],
              [119.00734093635802, -34.46414926527854],
              [118.5057178081008, -34.74681934991509],
              [118.02497195848949, -35.0647327613747],
              [117.29550744025741, -35.02545867283287],
              [116.62510908413495, -35.02509693780683],
              [115.56434695847966, -34.38642791111157],
              [115.02680870977957, -34.19651702243893],
              [115.04861616420676, -33.623425388322055],
              [115.54512332566708, -33.48725798923297],
              [115.7146737000167, -33.25957162855497],
              [115.67937869676135, -32.900368747694166],
              [115.80164513556394, -32.205062351207005],
              [115.68961063035516, -31.612437025683807],
              [115.160909051577, -30.601594333622465],
              [114.99704308477948, -30.03072478609414],
              [115.04003787644629, -29.46109547294082],
              [114.64197431850201, -28.81023080822467],
              [114.6164978373821, -28.51639861421308],
              [114.17357913620847, -28.11807667410732],
              [114.04888390508816, -27.334765313427106],
              [113.47749759323692, -26.543134047147902],
              [113.33895307826242, -26.116545098578484],
              [113.77835778204022, -26.549025160429174],
              [113.44096235560656, -25.621278171493167],
              [113.93690107631167, -25.91123463308287],
              [114.23285200404723, -26.29844614024588],
              [114.21616051641698, -25.786281019801123],
              [113.7212553243577, -24.99893889740214],
              [113.62534386602397, -24.683971042583167],
              [113.39352339076264, -24.384764499613226],
              [113.5020438985756, -23.806350192970285],
              [113.70699262904515, -23.56021534596409],
              [113.84341841029567, -23.059987481378755],
              [113.73655154831609, -22.47547535572538],
              [114.1497563009219, -21.75588103606104],
              [114.22530724493262, -22.517488295178673],
              [114.6477620789187, -21.829519952076954],
              [115.46016727097924, -21.495173435148537],
              [115.94737267462702, -21.068687839443704],
              [116.71161543179153, -20.701681817306824],
              [117.16631635952771, -20.623598728113805],
              [117.44154503791424, -20.74689869556221],
              [118.229558953933, -20.37420826587322],
              [118.83608523974274, -20.263310642174858],
              [118.98780724495168, -20.044202569257315],
              [119.25249393115067, -19.952941989829867],
              [119.80522505094451, -19.976506442954964],
              [120.85622033089668, -19.683707777589206],
              [121.39985639860717, -19.239755547769725],
              [121.65513797412902, -18.70531788500717],
              [122.24166548064179, -18.197648614171804],
              [122.28662397673571, -17.798603204013958],
              [122.3127722514754, -17.25496713630345],
              [123.01257449757193, -16.405199883695886],
              [123.43378909718304, -17.268558037996215],
              [123.85934451710659, -17.069035332917288],
              [123.50324222218329, -16.596506036040402],
              [123.81707319549184, -16.111316013252],
              [124.25828657439985, -16.327943617419535],
              [124.37972619028575, -15.56705982835399],
              [124.92615278534004, -15.07510019293536],
              [125.16727501841387, -14.680395603090028],
              [125.67008670461381, -14.510070082256014],
              [125.68579634003055, -14.23065561285385],
              [126.12514936737608, -14.347340996968903],
              [126.14282270721986, -14.095986830301227],
              [126.58258914602374, -13.952791436420448],
              [127.06586714081732, -13.817967624570954],
              [127.80463341686196, -14.27690601975508],
              [128.35968997610894, -14.869169610252243],
              [128.98554324759584, -14.875990899314765],
              [129.62147342337965, -14.969783623924522],
              [129.40960005098293, -14.42066985439107],
              [129.8886405783286, -13.618703301653492],
              [130.33946577364293, -13.357375583553484],
              [130.18350630098604, -13.107520033422276],
              [130.61779503796697, -12.536392103732489],
              [131.22349450086, -12.183648776908166],
              [131.73509118054955, -12.302452894747184],
              [132.5752982931831, -12.114040622611007],
              [132.55721154188097, -11.603012383676678],
              [131.82469811414364, -11.27378183354515],
              [132.3572237489114, -11.128519382372696],
              [133.01956058159635, -11.376411228076812],
              [133.55084598198908, -11.786515394745116],
              [134.39306847548204, -12.042365411022182],
              [134.67863244032696, -11.941182956594693],
              [135.29849124566795, -12.248606052299046],
              [135.8826933127276, -11.962266940969776],
              [136.2583809754895, -12.049341729381588],
              [136.49247521377168, -11.857208754120398],
              [136.951620314685, -12.351958916882793],
              [136.6851249533558, -12.887223402562022],
              [136.3054065288751, -13.291229750219884],
              [135.96175825413417, -13.324509372615852],
              [136.07761681533253, -13.724278252825783],
              [135.78383629775323, -14.2239893530882],
              [135.4286641786112, -14.715432224183912],
              [135.50018436090318, -14.997740573794424],
              [136.2951745952813, -15.55026498785913],
              [137.06536014215942, -15.87076222093333],
              [137.5804708192448, -16.21508228929408],
              [138.30321740127897, -16.807604261952704],
              [138.58516401586343, -16.806622409739155],
              [139.10854292211548, -17.06267913174539],
              [139.2605749859182, -17.371600843986208],
              [140.21524539607827, -17.710804945550066],
              [140.87546349503924, -17.369068698803908],
              [141.07111046769626, -16.83204721442676],
              [141.27409549373874, -16.38887013109165],
              [141.39822228410384, -15.840531508042588],
              [141.70218305884464, -15.044921156476901],
              [141.56338016170866, -14.561333103089552],
              [141.6355204611881, -14.270394789286307],
              [141.5198686057189, -13.698078301653808],
              [141.65092003801107, -12.944687595270585],
              [141.8426912782462, -12.741547539931231],
              [141.68699018775084, -12.407614434461145],
              [141.9286291851476, -11.877465915578817],
              [142.11848839738798, -11.328042087451612],
              [142.1437064963464, -11.042736504768186],
              [142.51526004452495, -10.668185723516686],
              [142.797310011974, -11.157354831591562],
              [142.86676313697427, -11.784706719614903],
              [143.11594689348573, -11.905629571177885],
              [143.15863162655876, -12.325655612846232],
              [143.5221236512998, -12.834358412327433],
              [143.5971578309876, -13.400422051652612],
              [143.5618111513, -13.763655694232192],
              [143.9220992372389, -14.548310642151996],
              [144.56371382057483, -14.171176039285903],
              [144.89490807513346, -14.594457696188641],
              [145.3747237489635, -14.98497649501833],
              [145.27199100156724, -15.428205254785732],
              [145.4852596376358, -16.28567229580478],
              [145.637033319277, -16.78491830817657],
              [145.88890425026761, -16.906926364817686],
              [146.16030887266453, -17.761654554925272],
              [146.06367394427872, -18.28007252367734],
              [146.38747846901964, -18.958274021075887],
              [147.4710815777479, -19.48072275154673],
              [148.17760176004242, -19.9559392229028],
              [148.84841352762322, -20.391209812097244],
              [148.71746544819558, -20.63346892668155],
              [149.28942020080206, -21.260510756111135],
              [149.6783370302307, -22.342511895438385],
              [150.07738244038853, -22.122783705333337],
              [150.48293908101516, -22.556142266532994],
              [150.72726525289113, -22.402404880464665],
              [150.89955447815225, -23.462236830338696],
              [151.60917524638427, -24.07625619883074],
              [152.07353966695905, -24.457886651306225],
              [152.8551973818059, -25.267501316023],
              [153.1361621441768, -26.071173191026215],
              [153.16194868389044, -26.641319268502457],
              [153.0929089703485, -27.260299574494514],
              [153.56946902894418, -28.11006682710208],
              [153.51210818910022, -28.99507740653271],
              [153.339095493787, -29.45820159273248],
              [153.06924116435886, -30.350240166954794],
              [153.08960167868184, -30.923641859665423],
              [152.89157759013938, -31.640445651986],
              [152.45000247620533, -32.550002536755265],
              [151.70911746643674, -33.041342054986394],
              [151.3439717958624, -33.81602345147387],
              [151.0105554547152, -34.31036020277793],
              [150.71413943908902, -35.173459974916796],
              [150.3282198427333, -35.671879164371916],
              [150.0752120302323, -36.42020558039054],
              [149.9461243023672, -37.10905242284121],
              [149.99728397033613, -37.42526051203518],
              [149.42388227762552, -37.77268116633344],
              [148.30462243061584, -37.809061374666925],
              [147.38173302631526, -38.21921721776752],
              [146.92212283751132, -38.606532077795116],
              [146.31792199115478, -39.03575652441141],
              [145.4896521343806, -38.59376799901902],
              [144.87697635312816, -38.41744801203915],
              [145.03221235573295, -37.89618783951102],
              [144.48568240781407, -38.085323581699285],
              [143.60997358619602, -38.8094654274053],
              [142.74542687395297, -38.538267510737555],
              [142.17832970598192, -38.380034275059835],
              [141.60658165910468, -38.30851409276788],
              [140.63857872941327, -38.019332777662555],
              [139.99215823787426, -37.402936293285094],
              [139.8065881695141, -36.64360279718831],
              [139.57414757706528, -36.13836231867066],
              [139.08280805883413, -35.732754001611745],
              [138.12074791885635, -35.61229623793939],
              [138.44946170466494, -35.127261244447865],
              [138.20756432510672, -34.38472258884593],
              [137.71917036351618, -35.076825046531],
              [136.8294055523147, -35.26053476332861],
              [137.35237104710848, -34.7073385556441],
              [137.50388634658827, -34.13026783624075],
              [137.8901160015377, -33.64047861097838],
              [137.81032759007905, -32.90000701266812],
              [136.9968371929404, -33.752771498348615],
              [136.37206912653164, -34.094766127256236],
              [135.98904341038428, -34.89011809666046],
              [135.20821251845405, -34.478670342752565],
              [135.23921837782916, -33.94795338311502],
              [134.6134167827746, -33.222778008763164],
              [134.08590376193916, -32.84807219821479],
              [134.27390262261702, -32.61723357516699],
              [132.99077680880976, -32.01122405368019],
              [132.28808068250487, -31.982646986622782],
              [131.32633060112084, -31.49580331800104],
              [129.53579389863972, -31.590422865527465],
              [128.24093753470225, -31.948488864877852],
              [127.1028674663383, -32.28226694105106],
              [126.14871382050114, -32.21596607842059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Solomon Islands",
        sov_a3: "SLB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Solomon Islands",
        adm0_a3: "SLB",
        geou_dif: 0,
        geounit: "Solomon Islands",
        gu_a3: "SLB",
        su_dif: 0,
        subunit: "Solomon Islands",
        su_a3: "SLB",
        brk_diff: 0,
        name: "Solomon Is.",
        name_long: "Solomon Islands",
        brk_a3: "SLB",
        brk_name: "Solomon Is.",
        brk_group: null,
        abbrev: "S. Is.",
        postal: "SB",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Solomon Islands",
        note_adm0: null,
        note_brk: null,
        name_sort: "Solomon Islands",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 669823,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 1589,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "BP",
        iso_a2: "SB",
        iso_a2_eh: "SB",
        iso_a3: "SLB",
        iso_a3_eh: "SLB",
        iso_n3: "090",
        iso_n3_eh: "090",
        un_a3: "090",
        wb_a2: "SB",
        wb_a3: "SLB",
        woe_id: 23424766,
        woe_id_eh: 23424766,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SLB",
        adm0_diff: null,
        adm0_tlc: "SLB",
        adm0_a3_us: "SLB",
        adm0_a3_fr: "SLB",
        adm0_a3_ru: "SLB",
        adm0_a3_es: "SLB",
        adm0_a3_cn: "SLB",
        adm0_a3_tw: "SLB",
        adm0_a3_in: "SLB",
        adm0_a3_np: "SLB",
        adm0_a3_pk: "SLB",
        adm0_a3_de: "SLB",
        adm0_a3_gb: "SLB",
        adm0_a3_br: "SLB",
        adm0_a3_il: "SLB",
        adm0_a3_ps: "SLB",
        adm0_a3_sa: "SLB",
        adm0_a3_eg: "SLB",
        adm0_a3_ma: "SLB",
        adm0_a3_pt: "SLB",
        adm0_a3_ar: "SLB",
        adm0_a3_jp: "SLB",
        adm0_a3_ko: "SLB",
        adm0_a3_vn: "SLB",
        adm0_a3_tr: "SLB",
        adm0_a3_id: "SLB",
        adm0_a3_pl: "SLB",
        adm0_a3_gr: "SLB",
        adm0_a3_it: "SLB",
        adm0_a3_nl: "SLB",
        adm0_a3_se: "SLB",
        adm0_a3_bd: "SLB",
        adm0_a3_ua: "SLB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 15,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 159.170468,
        label_y: -8.029548,
        ne_id: 1159321249,
        wikidataid: "Q685",
        name_ar: "جزر سليمان",
        name_bn: "সলোমন দ্বীপপুঞ্জ",
        name_de: "Salomonen",
        name_en: "Solomon Islands",
        name_es: "Islas Salomón",
        name_fa: "جزایر سلیمان",
        name_fr: "Îles Salomon",
        name_el: "Νησιά Σολομώντα",
        name_he: "איי שלמה",
        name_hi: "सोलोमन द्वीपसमूह",
        name_hu: "Salamon-szigetek",
        name_id: "Kepulauan Solomon",
        name_it: "Isole Salomone",
        name_ja: "ソロモン諸島",
        name_ko: "솔로몬 제도",
        name_nl: "Salomonseilanden",
        name_pl: "Wyspy Salomona",
        name_pt: "Ilhas Salomão",
        name_ru: "Соломоновы Острова",
        name_sv: "Salomonöarna",
        name_tr: "Solomon Adaları",
        name_uk: "Соломонові Острови",
        name_ur: "جزائر سلیمان",
        name_vi: "Quần đảo Solomon",
        name_zh: "所罗门群岛",
        name_zht: "索羅門群島",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SLB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [162.1190246930409, -10.48271900802115],
              [162.3986458681722, -10.826367282762106],
              [161.70003218001835, -10.820011081590211],
              [161.31979699121476, -10.204751478723168],
              [161.91738325423802, -10.446700534713713],
              [162.1190246930409, -10.48271900802115],
            ],
          ],
          [
            [
              [161.67998172428912, -9.599982191611367],
              [161.5293966005906, -9.784312025596485],
              [160.78825320866054, -8.917543226764892],
              [160.57999718652434, -8.32000864017396],
              [160.92002811100485, -8.32000864017396],
              [161.28000613835, -9.120011488484451],
              [161.67998172428912, -9.599982191611367],
            ],
          ],
          [
            [
              [160.85222863183787, -9.872937106977048],
              [160.4625883323572, -9.895209649294841],
              [159.84944746321412, -9.794027194867354],
              [159.64000288313514, -9.639979750205278],
              [159.70294477766663, -9.242949720906815],
              [160.36295617089843, -9.40030445723557],
              [160.68851769433724, -9.610162448772869],
              [160.85222863183787, -9.872937106977048],
            ],
          ],
          [
            [
              [159.64000288313514, -8.020026950719632],
              [159.87502729719859, -8.337320244991737],
              [159.91740197167792, -8.53828989017483],
              [159.13367719953936, -8.114181410355428],
              [158.5861137229747, -7.754823500197737],
              [158.21114953026483, -7.421872246941199],
              [158.35997765526542, -7.320017998893917],
              [158.8200012555277, -7.560003350457379],
              [159.64000288313514, -8.020026950719632],
            ],
          ],
          [
            [
              [157.14000044171888, -7.021638278840641],
              [157.5384257346892, -7.347819919466943],
              [157.33941979393325, -7.404767347852592],
              [156.90203047101483, -7.176874281445428],
              [156.4913578635913, -6.765943291860452],
              [156.542827590154, -6.599338474151452],
              [157.14000044171888, -7.021638278840641],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Vanuatu",
        sov_a3: "VUT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Vanuatu",
        adm0_a3: "VUT",
        geou_dif: 0,
        geounit: "Vanuatu",
        gu_a3: "VUT",
        su_dif: 0,
        subunit: "Vanuatu",
        su_a3: "VUT",
        brk_diff: 0,
        name: "Vanuatu",
        name_long: "Vanuatu",
        brk_a3: "VUT",
        brk_name: "Vanuatu",
        brk_group: null,
        abbrev: "Van.",
        postal: "VU",
        formal_en: "Republic of Vanuatu",
        formal_fr: null,
        name_ciawf: "Vanuatu",
        note_adm0: null,
        note_brk: null,
        name_sort: "Vanuatu",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 299882,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 934,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "NH",
        iso_a2: "VU",
        iso_a2_eh: "VU",
        iso_a3: "VUT",
        iso_a3_eh: "VUT",
        iso_n3: "548",
        iso_n3_eh: "548",
        un_a3: "548",
        wb_a2: "VU",
        wb_a3: "VUT",
        woe_id: 23424907,
        woe_id_eh: 23424907,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VUT",
        adm0_diff: null,
        adm0_tlc: "VUT",
        adm0_a3_us: "VUT",
        adm0_a3_fr: "VUT",
        adm0_a3_ru: "VUT",
        adm0_a3_es: "VUT",
        adm0_a3_cn: "VUT",
        adm0_a3_tw: "VUT",
        adm0_a3_in: "VUT",
        adm0_a3_np: "VUT",
        adm0_a3_pk: "VUT",
        adm0_a3_de: "VUT",
        adm0_a3_gb: "VUT",
        adm0_a3_br: "VUT",
        adm0_a3_il: "VUT",
        adm0_a3_ps: "VUT",
        adm0_a3_sa: "VUT",
        adm0_a3_eg: "VUT",
        adm0_a3_ma: "VUT",
        adm0_a3_pt: "VUT",
        adm0_a3_ar: "VUT",
        adm0_a3_jp: "VUT",
        adm0_a3_ko: "VUT",
        adm0_a3_vn: "VUT",
        adm0_a3_tr: "VUT",
        adm0_a3_id: "VUT",
        adm0_a3_pl: "VUT",
        adm0_a3_gr: "VUT",
        adm0_a3_it: "VUT",
        adm0_a3_nl: "VUT",
        adm0_a3_se: "VUT",
        adm0_a3_bd: "VUT",
        adm0_a3_ua: "VUT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 166.908762,
        label_y: -15.37153,
        ne_id: 1159321421,
        wikidataid: "Q686",
        name_ar: "فانواتو",
        name_bn: "ভানুয়াতু",
        name_de: "Vanuatu",
        name_en: "Vanuatu",
        name_es: "Vanuatu",
        name_fa: "وانواتو",
        name_fr: "Vanuatu",
        name_el: "Βανουάτου",
        name_he: "ונואטו",
        name_hi: "वानूआटू",
        name_hu: "Vanuatu",
        name_id: "Vanuatu",
        name_it: "Vanuatu",
        name_ja: "バヌアツ",
        name_ko: "바누아투",
        name_nl: "Vanuatu",
        name_pl: "Vanuatu",
        name_pt: "Vanuatu",
        name_ru: "Вануату",
        name_sv: "Vanuatu",
        name_tr: "Vanuatu",
        name_uk: "Вануату",
        name_ur: "وانواتو",
        name_vi: "Vanuatu",
        name_zh: "瓦努阿图",
        name_zht: "萬那杜",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VUT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [167.2168013857696, -15.89184620530842],
              [167.84487674384502, -16.46633310309717],
              [167.51518110582288, -16.59784962327999],
              [167.1800077659778, -16.159995212470946],
              [167.2168013857696, -15.89184620530842],
            ],
          ],
          [
            [
              [166.79315799384085, -15.668810723536687],
              [166.6498592470955, -15.392703545801211],
              [166.6291369977464, -14.626497084209605],
              [167.10771243720149, -14.933920179913954],
              [167.27002811103023, -15.740020847234888],
              [167.00120731024793, -15.614602146062516],
              [166.79315799384085, -15.668810723536687],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "France",
        adm0_a3: "FRA",
        geou_dif: 0,
        geounit: "France",
        gu_a3: "FRA",
        su_dif: 0,
        subunit: "France",
        su_a3: "FRA",
        brk_diff: 0,
        name: "France",
        name_long: "France",
        brk_a3: "FRA",
        brk_name: "France",
        brk_group: null,
        abbrev: "Fr.",
        postal: "F",
        formal_en: "French Republic",
        formal_fr: null,
        name_ciawf: "France",
        note_adm0: null,
        note_brk: null,
        name_sort: "France",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 67059887,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 2715518,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "FR",
        iso_a2: "-99",
        iso_a2_eh: "FR",
        iso_a3: "-99",
        iso_a3_eh: "FRA",
        iso_n3: "-99",
        iso_n3_eh: "250",
        un_a3: "250",
        wb_a2: "FR",
        wb_a3: "FRA",
        woe_id: -90,
        woe_id_eh: 23424819,
        woe_note: "Includes only Metropolitan France (including Corsica)",
        adm0_iso: "FRA",
        adm0_diff: null,
        adm0_tlc: "FRA",
        adm0_a3_us: "FRA",
        adm0_a3_fr: "FRA",
        adm0_a3_ru: "FRA",
        adm0_a3_es: "FRA",
        adm0_a3_cn: "FRA",
        adm0_a3_tw: "FRA",
        adm0_a3_in: "FRA",
        adm0_a3_np: "FRA",
        adm0_a3_pk: "FRA",
        adm0_a3_de: "FRA",
        adm0_a3_gb: "FRA",
        adm0_a3_br: "FRA",
        adm0_a3_il: "FRA",
        adm0_a3_ps: "FRA",
        adm0_a3_sa: "FRA",
        adm0_a3_eg: "FRA",
        adm0_a3_ma: "FRA",
        adm0_a3_pt: "FRA",
        adm0_a3_ar: "FRA",
        adm0_a3_jp: "FRA",
        adm0_a3_ko: "FRA",
        adm0_a3_vn: "FRA",
        adm0_a3_tr: "FRA",
        adm0_a3_id: "FRA",
        adm0_a3_pl: "FRA",
        adm0_a3_gr: "FRA",
        adm0_a3_it: "FRA",
        adm0_a3_nl: "FRA",
        adm0_a3_se: "FRA",
        adm0_a3_bd: "FRA",
        adm0_a3_ua: "FRA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 2.552275,
        label_y: 46.696113,
        ne_id: 1159320637,
        wikidataid: "Q142",
        name_ar: "فرنسا",
        name_bn: "ফ্রান্স",
        name_de: "Frankreich",
        name_en: "France",
        name_es: "Francia",
        name_fa: "فرانسه",
        name_fr: "France",
        name_el: "Γαλλία",
        name_he: "צרפת",
        name_hi: "फ़्रान्स",
        name_hu: "Franciaország",
        name_id: "Prancis",
        name_it: "Francia",
        name_ja: "フランス",
        name_ko: "프랑스",
        name_nl: "Frankrijk",
        name_pl: "Francja",
        name_pt: "França",
        name_ru: "Франция",
        name_sv: "Frankrike",
        name_tr: "Fransa",
        name_uk: "Франція",
        name_ur: "فرانس",
        name_vi: "Pháp",
        name_zh: "法国",
        name_zht: "法國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FRA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-51.65779741067889, 4.156232408053029],
              [-52.249337531123956, 3.241094468596245],
              [-52.55642473001842, 2.504705308437053],
              [-52.939657151894956, 2.124857692875636],
              [-53.41846513529531, 2.053389187015981],
              [-53.554839240113544, 2.334896551925951],
              [-53.77852067728892, 2.376702785650082],
              [-54.08806250671725, 2.105556545414629],
              [-54.524754197799716, 2.311848863123785],
              [-54.2697051662232, 2.732391669115046],
              [-54.181726040246275, 3.189779771330421],
              [-54.00693050801901, 3.620037746592558],
              [-54.399542202356514, 4.212611395683467],
              [-54.47863298197923, 4.896755682795586],
              [-53.9580446030709, 5.756548163267765],
              [-53.618452928264844, 5.646529038918374],
              [-52.88214128275409, 5.409850979021584],
              [-51.8233428615259, 4.565768133966131],
              [-51.65779741067889, 4.156232408053029],
            ],
          ],
          [
            [
              [6.186320428094177, 49.463802802114515],
              [6.658229607783568, 49.20195831969157],
              [8.099278598674744, 49.01778351500333],
              [7.593676385131062, 48.33301911070372],
              [7.466759067422231, 47.62058197691181],
              [7.192202182655507, 47.44976552997102],
              [6.736571079138059, 47.541801255882845],
              [6.768713820023606, 47.2877082383037],
              [6.037388950229001, 46.725778713561866],
              [6.022609490593538, 46.27298981382047],
              [6.500099724970426, 46.42967275652944],
              [6.843592970414505, 45.99114655210061],
              [6.802355177445605, 45.70857982032864],
              [7.096652459347837, 45.33309886329589],
              [6.749955275101655, 45.02851797136758],
              [7.007562290076635, 44.25476675066136],
              [7.549596388386107, 44.12790110938481],
              [7.435184767291872, 43.69384491634922],
              [6.52924523278304, 43.128892320318315],
              [4.556962517931424, 43.399650987311595],
              [3.100410597352663, 43.075200507167054],
              [2.985998976258458, 42.47301504166986],
              [1.826793247087153, 42.34338471126569],
              [0.701590610363894, 42.795734361332606],
              [0.338046909190581, 42.57954600683955],
              [-1.502770961910528, 43.03401439063043],
              [-1.901351284177764, 43.42280202897834],
              [-1.384225226232985, 44.022610378590116],
              [-1.193797573237418, 46.01491771095486],
              [-2.225724249673846, 47.06436269793822],
              [-2.963276129559603, 47.57032664650795],
              [-4.491554938159481, 47.954954332056374],
              [-4.592349819344776, 48.68416046812699],
              [-3.295813971357802, 48.90169240985963],
              [-1.616510789384961, 48.64442129169454],
              [-1.933494025063311, 49.776341864615745],
              [-0.98946895995536, 49.34737580016091],
              [1.338761020522696, 50.12717316344526],
              [1.6390010921385, 50.9466063502975],
              [2.513573032246143, 51.14850617126183],
              [2.658422071960274, 50.796848049515745],
              [3.123251580425688, 50.78036326761455],
              [3.588184441755658, 50.37899241800356],
              [4.286022983425084, 49.907496649772554],
              [4.799221632515724, 49.98537303323637],
              [5.674051954784829, 49.529483547557504],
              [5.897759230176348, 49.44266714130711],
              [6.186320428094177, 49.463802802114515],
            ],
          ],
          [
            [
              [8.746009148807559, 42.62812185319392],
              [9.390000848028876, 43.00998484961471],
              [9.560016310269134, 42.15249197037952],
              [9.229752231491773, 41.380006822264455],
              [8.775723097375362, 41.58361196549443],
              [8.544212680707773, 42.25651662858306],
              [8.746009148807559, 42.62812185319392],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ukraine",
        sov_a3: "UKR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ukraine",
        adm0_a3: "UKR",
        geou_dif: 0,
        geounit: "Ukraine",
        gu_a3: "UKR",
        su_dif: 0,
        subunit: "Ukraine",
        su_a3: "UKR",
        brk_diff: 0,
        name: "Ukraine",
        name_long: "Ukraine",
        brk_a3: "UKR",
        brk_name: "Ukraine",
        brk_group: null,
        abbrev: "Ukr.",
        postal: "UA",
        formal_en: "Ukraine",
        formal_fr: null,
        name_ciawf: "Ukraine",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ukraine",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 44385155,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 153781,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "UP",
        iso_a2: "UA",
        iso_a2_eh: "UA",
        iso_a3: "UKR",
        iso_a3_eh: "UKR",
        iso_n3: "804",
        iso_n3_eh: "804",
        un_a3: "804",
        wb_a2: "UA",
        wb_a3: "UKR",
        woe_id: 23424976,
        woe_id_eh: 23424976,
        woe_note: "Exact WOE match as country",
        adm0_iso: "UKR",
        adm0_diff: null,
        adm0_tlc: "UKR",
        adm0_a3_us: "UKR",
        adm0_a3_fr: "UKR",
        adm0_a3_ru: "UKR",
        adm0_a3_es: "UKR",
        adm0_a3_cn: "UKR",
        adm0_a3_tw: "UKR",
        adm0_a3_in: "UKR",
        adm0_a3_np: "UKR",
        adm0_a3_pk: "UKR",
        adm0_a3_de: "UKR",
        adm0_a3_gb: "UKR",
        adm0_a3_br: "UKR",
        adm0_a3_il: "UKR",
        adm0_a3_ps: "UKR",
        adm0_a3_sa: "UKR",
        adm0_a3_eg: "UKR",
        adm0_a3_ma: "UKR",
        adm0_a3_pt: "UKR",
        adm0_a3_ar: "UKR",
        adm0_a3_jp: "UKR",
        adm0_a3_ko: "UKR",
        adm0_a3_vn: "UKR",
        adm0_a3_tr: "UKR",
        adm0_a3_id: "UKR",
        adm0_a3_pl: "UKR",
        adm0_a3_gr: "UKR",
        adm0_a3_it: "UKR",
        adm0_a3_nl: "UKR",
        adm0_a3_se: "UKR",
        adm0_a3_bd: "UKR",
        adm0_a3_ua: "UKR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 32.140865,
        label_y: 49.724739,
        ne_id: 1159321345,
        wikidataid: "Q212",
        name_ar: "أوكرانيا",
        name_bn: "ইউক্রেন",
        name_de: "Ukraine",
        name_en: "Ukraine",
        name_es: "Ucrania",
        name_fa: "اوکراین",
        name_fr: "Ukraine",
        name_el: "Ουκρανία",
        name_he: "אוקראינה",
        name_hi: "युक्रेन",
        name_hu: "Ukrajna",
        name_id: "Ukraina",
        name_it: "Ucraina",
        name_ja: "ウクライナ",
        name_ko: "우크라이나",
        name_nl: "Oekraïne",
        name_pl: "Ukraina",
        name_pt: "Ucrânia",
        name_ru: "Украина",
        name_sv: "Ukraina",
        name_tr: "Ukrayna",
        name_uk: "Україна",
        name_ur: "یوکرین",
        name_vi: "Ukraina",
        name_zh: "乌克兰",
        name_zht: "烏克蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "UKR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.78599244755525, 52.1016775699397],
            [32.15944000000013, 52.06125000000014],
            [32.41205813978769, 52.28869497334978],
            [32.715760532367085, 52.23846548116211],
            [33.75269982273579, 52.3350745713318],
            [34.39173058445709, 51.768881740925906],
            [34.141978387190534, 51.566413479206346],
            [34.22481570815427, 51.25599315042888],
            [35.02218305841791, 51.2075723333715],
            [35.37791, 50.77394],
            [35.356116163887975, 50.57719737405904],
            [36.62616784032542, 50.225590928745135],
            [37.39345950699516, 50.38395335550365],
            [38.01063113785693, 49.9156615260747],
            [38.59498823421342, 49.9264619004237],
            [40.06904000000014, 49.60105],
            [40.080789015469406, 49.30742991799934],
            [39.67465, 48.78382000000016],
            [39.89562000000018, 48.23241],
            [39.738277622238854, 47.89893707945197],
            [38.77057, 47.825620000000185],
            [38.25511233902978, 47.54640045835691],
            [38.223538038899335, 47.10218984637595],
            [37.4251371599899, 47.02222056740419],
            [36.75985477066445, 46.69870026304102],
            [35.82368452326489, 46.64596446388714],
            [34.96234174982385, 46.27319651954974],
            [35.01265897004737, 45.73772519982549],
            [34.861792128174045, 45.76818243191957],
            [34.73201738827845, 45.96566573176062],
            [34.41040172853718, 46.005162391728845],
            [33.69946184910907, 46.219572831556434],
            [33.435988094713366, 45.971917370797485],
            [33.29856733575474, 46.08059845639781],
            [31.744140252415207, 46.33334788673733],
            [31.67530724460255, 46.70624502215554],
            [30.74874881360921, 46.583100084004116],
            [30.377608676888883, 46.03241018328572],
            [29.603289015427436, 45.293308010431126],
            [29.149724969201653, 45.464925442072456],
            [28.67977949393938, 45.304030870131704],
            [28.23355350109904, 45.48828318946829],
            [28.485269402792767, 45.5969070501459],
            [28.65998742037158, 45.93998688413164],
            [28.933717482221596, 46.25883047137256],
            [28.862972446414062, 46.43788930926383],
            [29.07210696789929, 46.517677720722496],
            [29.170653924279804, 46.37926239682872],
            [29.759971958136394, 46.34998769793536],
            [30.024658644335375, 46.42393667254504],
            [29.838210076626297, 46.52532583270169],
            [29.908851759569302, 46.67436066343146],
            [29.559674106573112, 46.928582872091326],
            [29.415135125452736, 47.34664520933258],
            [29.05086795422727, 47.5102269557525],
            [29.12269819511303, 47.849095160506465],
            [28.670891147585166, 48.1181485052341],
            [28.259546746541844, 48.15556224221342],
            [27.522537469195157, 48.467119452501116],
            [26.857823520624805, 48.368210761094495],
            [26.619336785597795, 48.22072622333347],
            [26.19745039236693, 48.22088125263035],
            [25.9459411964024, 47.987148749374214],
            [25.20774336111299, 47.89105642352747],
            [24.866317172960578, 47.737525743188314],
            [24.40205610525038, 47.98187775328043],
            [23.76095828623741, 47.985598456405455],
            [23.142236362406805, 48.09634105080695],
            [22.710531447040495, 47.88219391538941],
            [22.640819939878725, 48.150239569687415],
            [22.085608351334855, 48.42226430927179],
            [22.28084191253356, 48.82539215758067],
            [22.558137648211755, 49.085738023467144],
            [22.776418898212626, 49.02739533140962],
            [22.518450148211603, 49.47677358661974],
            [23.426508416444392, 50.30850576435745],
            [23.92275719574326, 50.42488108987874],
            [24.029985792748903, 50.70540660257518],
            [23.52707075368437, 51.57845408793031],
            [24.00507775238418, 51.617443956094405],
            [24.553106316839518, 51.888461005249184],
            [25.32778771332701, 51.91065603291855],
            [26.337958611768556, 51.83228872334797],
            [27.454066196408405, 51.592303371784396],
            [28.24161502453657, 51.57222707783907],
            [28.61761274589225, 51.42771393493484],
            [28.992835320763533, 51.602044379271476],
            [29.25493818534784, 51.36823436136689],
            [30.157363722460897, 51.41613841410147],
            [30.555117221811457, 51.31950348571566],
            [30.619454380014815, 51.822806098022454],
            [30.927549269338982, 52.04235342061439],
            [31.78599244755525, 52.1016775699397],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Belarus",
        sov_a3: "BLR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Belarus",
        adm0_a3: "BLR",
        geou_dif: 0,
        geounit: "Belarus",
        gu_a3: "BLR",
        su_dif: 0,
        subunit: "Belarus",
        su_a3: "BLR",
        brk_diff: 0,
        name: "Belarus",
        name_long: "Belarus",
        brk_a3: "BLR",
        brk_name: "Belarus",
        brk_group: null,
        abbrev: "Bela.",
        postal: "BY",
        formal_en: "Republic of Belarus",
        formal_fr: null,
        name_ciawf: "Belarus",
        note_adm0: null,
        note_brk: null,
        name_sort: "Belarus",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 11,
        pop_est: 9466856,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 63080,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "BO",
        iso_a2: "BY",
        iso_a2_eh: "BY",
        iso_a3: "BLR",
        iso_a3_eh: "BLR",
        iso_n3: "112",
        iso_n3_eh: "112",
        un_a3: "112",
        wb_a2: "BY",
        wb_a3: "BLR",
        woe_id: 23424765,
        woe_id_eh: 23424765,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BLR",
        adm0_diff: null,
        adm0_tlc: "BLR",
        adm0_a3_us: "BLR",
        adm0_a3_fr: "BLR",
        adm0_a3_ru: "BLR",
        adm0_a3_es: "BLR",
        adm0_a3_cn: "BLR",
        adm0_a3_tw: "BLR",
        adm0_a3_in: "BLR",
        adm0_a3_np: "BLR",
        adm0_a3_pk: "BLR",
        adm0_a3_de: "BLR",
        adm0_a3_gb: "BLR",
        adm0_a3_br: "BLR",
        adm0_a3_il: "BLR",
        adm0_a3_ps: "BLR",
        adm0_a3_sa: "BLR",
        adm0_a3_eg: "BLR",
        adm0_a3_ma: "BLR",
        adm0_a3_pt: "BLR",
        adm0_a3_ar: "BLR",
        adm0_a3_jp: "BLR",
        adm0_a3_ko: "BLR",
        adm0_a3_vn: "BLR",
        adm0_a3_tr: "BLR",
        adm0_a3_id: "BLR",
        adm0_a3_pl: "BLR",
        adm0_a3_gr: "BLR",
        adm0_a3_it: "BLR",
        adm0_a3_nl: "BLR",
        adm0_a3_se: "BLR",
        adm0_a3_bd: "BLR",
        adm0_a3_ua: "BLR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 28.417701,
        label_y: 53.821888,
        ne_id: 1159320427,
        wikidataid: "Q184",
        name_ar: "بيلاروسيا",
        name_bn: "বেলারুশ",
        name_de: "Belarus",
        name_en: "Belarus",
        name_es: "Bielorrusia",
        name_fa: "بلاروس",
        name_fr: "Biélorussie",
        name_el: "Λευκορωσία",
        name_he: "בלארוס",
        name_hi: "बेलारूस",
        name_hu: "Fehéroroszország",
        name_id: "Belarus",
        name_it: "Bielorussia",
        name_ja: "ベラルーシ",
        name_ko: "벨라루스",
        name_nl: "Wit-Rusland",
        name_pl: "Białoruś",
        name_pt: "Bielorrússia",
        name_ru: "Белоруссия",
        name_sv: "Belarus",
        name_tr: "Beyaz Rusya",
        name_uk: "Білорусь",
        name_ur: "بیلاروس",
        name_vi: "Belarus",
        name_zh: "白俄罗斯",
        name_zht: "白俄羅斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BLR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.17670942557794, 56.16912995057879],
            [29.229513380660308, 55.91834422466636],
            [29.371571893030673, 55.670090643936184],
            [29.896294386522356, 55.78946320253041],
            [30.87390913262001, 55.55097646750341],
            [30.971835971813135, 55.08154775656404],
            [30.75753380709872, 54.81177094178432],
            [31.38447228366374, 54.15705638286238],
            [31.79142418796224, 53.974638576872124],
            [31.731272820774507, 53.79402944601202],
            [32.405598585751164, 53.61804535584204],
            [32.69364301934604, 53.35142080343218],
            [32.30451948418823, 53.13272614197291],
            [31.49764, 53.16743000000014],
            [31.305200636528014, 53.07399587667321],
            [31.54001834486226, 52.74205231384636],
            [31.78597, 52.1016800000001],
            [31.78599244755525, 52.1016775699397],
            [30.927549269338982, 52.04235342061439],
            [30.619454380014815, 51.822806098022454],
            [30.555117221811457, 51.31950348571566],
            [30.157363722460897, 51.41613841410147],
            [29.25493818534784, 51.36823436136689],
            [28.992835320763533, 51.602044379271476],
            [28.61761274589225, 51.42771393493484],
            [28.24161502453657, 51.57222707783907],
            [27.454066196408405, 51.592303371784396],
            [26.337958611768556, 51.83228872334797],
            [25.32778771332701, 51.91065603291855],
            [24.553106316839518, 51.888461005249184],
            [24.00507775238418, 51.617443956094405],
            [23.52707075368437, 51.57845408793031],
            [23.508002150168693, 52.02364655212473],
            [23.199493849386187, 52.48697744405367],
            [23.79919884613338, 52.69109935160657],
            [23.80493493011778, 53.089731350306074],
            [23.527535841575002, 53.470121568406555],
            [23.48412763844985, 53.91249766704114],
            [24.450683628037037, 53.905702216194754],
            [25.536353794056993, 54.28242340760253],
            [25.7684326514798, 54.84696259217509],
            [26.58827924979039, 55.16717560487167],
            [26.494331495883756, 55.615106919977634],
            [27.10245975109453, 55.783313707087686],
            [28.17670942557794, 56.16912995057879],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Lithuania",
        sov_a3: "LTU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Lithuania",
        adm0_a3: "LTU",
        geou_dif: 0,
        geounit: "Lithuania",
        gu_a3: "LTU",
        su_dif: 0,
        subunit: "Lithuania",
        su_a3: "LTU",
        brk_diff: 0,
        name: "Lithuania",
        name_long: "Lithuania",
        brk_a3: "LTU",
        brk_name: "Lithuania",
        brk_group: null,
        abbrev: "Lith.",
        postal: "LT",
        formal_en: "Republic of Lithuania",
        formal_fr: null,
        name_ciawf: "Lithuania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lithuania",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 2786844,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 54627,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "LH",
        iso_a2: "LT",
        iso_a2_eh: "LT",
        iso_a3: "LTU",
        iso_a3_eh: "LTU",
        iso_n3: "440",
        iso_n3_eh: "440",
        un_a3: "440",
        wb_a2: "LT",
        wb_a3: "LTU",
        woe_id: 23424875,
        woe_id_eh: 23424875,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LTU",
        adm0_diff: null,
        adm0_tlc: "LTU",
        adm0_a3_us: "LTU",
        adm0_a3_fr: "LTU",
        adm0_a3_ru: "LTU",
        adm0_a3_es: "LTU",
        adm0_a3_cn: "LTU",
        adm0_a3_tw: "LTU",
        adm0_a3_in: "LTU",
        adm0_a3_np: "LTU",
        adm0_a3_pk: "LTU",
        adm0_a3_de: "LTU",
        adm0_a3_gb: "LTU",
        adm0_a3_br: "LTU",
        adm0_a3_il: "LTU",
        adm0_a3_ps: "LTU",
        adm0_a3_sa: "LTU",
        adm0_a3_eg: "LTU",
        adm0_a3_ma: "LTU",
        adm0_a3_pt: "LTU",
        adm0_a3_ar: "LTU",
        adm0_a3_jp: "LTU",
        adm0_a3_ko: "LTU",
        adm0_a3_vn: "LTU",
        adm0_a3_tr: "LTU",
        adm0_a3_id: "LTU",
        adm0_a3_pl: "LTU",
        adm0_a3_gr: "LTU",
        adm0_a3_it: "LTU",
        adm0_a3_nl: "LTU",
        adm0_a3_se: "LTU",
        adm0_a3_bd: "LTU",
        adm0_a3_ua: "LTU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 24.089932,
        label_y: 55.103703,
        ne_id: 1159321029,
        wikidataid: "Q37",
        name_ar: "ليتوانيا",
        name_bn: "লিথুয়ানিয়া",
        name_de: "Litauen",
        name_en: "Lithuania",
        name_es: "Lituania",
        name_fa: "لیتوانی",
        name_fr: "Lituanie",
        name_el: "Λιθουανία",
        name_he: "ליטא",
        name_hi: "लिथुआनिया",
        name_hu: "Litvánia",
        name_id: "Lituania",
        name_it: "Lituania",
        name_ja: "リトアニア",
        name_ko: "리투아니아",
        name_nl: "Litouwen",
        name_pl: "Litwa",
        name_pt: "Lituânia",
        name_ru: "Литва",
        name_sv: "Litauen",
        name_tr: "Litvanya",
        name_uk: "Литва",
        name_ur: "لتھووینیا",
        name_vi: "Litva",
        name_zh: "立陶宛",
        name_zht: "立陶宛",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LTU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.494331495883756, 55.615106919977634],
            [26.58827924979039, 55.16717560487167],
            [25.7684326514798, 54.84696259217509],
            [25.536353794056993, 54.28242340760253],
            [24.450683628037037, 53.905702216194754],
            [23.48412763844985, 53.91249766704114],
            [23.24398725758951, 54.22056671814914],
            [22.731098667092652, 54.327536932993326],
            [22.65105187347254, 54.582740993866736],
            [22.75776370615526, 54.85657440858138],
            [22.315723504330577, 55.015298570365864],
            [21.268448927503467, 55.190481675835315],
            [21.055800408622417, 56.031076361711065],
            [22.201156853939494, 56.33780182557949],
            [23.878263787539964, 56.27367137310527],
            [24.86068444184076, 56.37252838807963],
            [25.000934279080894, 56.16453074810484],
            [25.533046502390334, 56.100296942766036],
            [26.494331495883756, 55.615106919977634],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Czechia",
        sov_a3: "CZE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Czechia",
        adm0_a3: "CZE",
        geou_dif: 0,
        geounit: "Czechia",
        gu_a3: "CZE",
        su_dif: 0,
        subunit: "Czechia",
        su_a3: "CZE",
        brk_diff: 0,
        name: "Czechia",
        name_long: "Czech Republic",
        brk_a3: "CZE",
        brk_name: "Czechia",
        brk_group: null,
        abbrev: "Cz.",
        postal: "CZ",
        formal_en: "Czech Republic",
        formal_fr: "la République tchèque",
        name_ciawf: "Czechia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Czechia",
        name_alt: "Česko",
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 6,
        pop_est: 10669709,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 250680,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "EZ",
        iso_a2: "CZ",
        iso_a2_eh: "CZ",
        iso_a3: "CZE",
        iso_a3_eh: "CZE",
        iso_n3: "203",
        iso_n3_eh: "203",
        un_a3: "203",
        wb_a2: "CZ",
        wb_a3: "CZE",
        woe_id: 23424810,
        woe_id_eh: 23424810,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CZE",
        adm0_diff: null,
        adm0_tlc: "CZE",
        adm0_a3_us: "CZE",
        adm0_a3_fr: "CZE",
        adm0_a3_ru: "CZE",
        adm0_a3_es: "CZE",
        adm0_a3_cn: "CZE",
        adm0_a3_tw: "CZE",
        adm0_a3_in: "CZE",
        adm0_a3_np: "CZE",
        adm0_a3_pk: "CZE",
        adm0_a3_de: "CZE",
        adm0_a3_gb: "CZE",
        adm0_a3_br: "CZE",
        adm0_a3_il: "CZE",
        adm0_a3_ps: "CZE",
        adm0_a3_sa: "CZE",
        adm0_a3_eg: "CZE",
        adm0_a3_ma: "CZE",
        adm0_a3_pt: "CZE",
        adm0_a3_ar: "CZE",
        adm0_a3_jp: "CZE",
        adm0_a3_ko: "CZE",
        adm0_a3_vn: "CZE",
        adm0_a3_tr: "CZE",
        adm0_a3_id: "CZE",
        adm0_a3_pl: "CZE",
        adm0_a3_gr: "CZE",
        adm0_a3_it: "CZE",
        adm0_a3_nl: "CZE",
        adm0_a3_se: "CZE",
        adm0_a3_bd: "CZE",
        adm0_a3_ua: "CZE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 14,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 15.377555,
        label_y: 49.882364,
        ne_id: 1159320535,
        wikidataid: "Q213",
        name_ar: "التشيك",
        name_bn: "চেক প্রজাতন্ত্র",
        name_de: "Tschechien",
        name_en: "Czech Republic",
        name_es: "República Checa",
        name_fa: "جمهوری چک",
        name_fr: "Tchéquie",
        name_el: "Τσεχία",
        name_he: "צ'כיה",
        name_hi: "चेक गणराज्य",
        name_hu: "Csehország",
        name_id: "Republik Ceko",
        name_it: "Repubblica Ceca",
        name_ja: "チェコ",
        name_ko: "체코",
        name_nl: "Tsjechië",
        name_pl: "Czechy",
        name_pt: "Chéquia",
        name_ru: "Чехия",
        name_sv: "Tjeckien",
        name_tr: "Çek Cumhuriyeti",
        name_uk: "Чехія",
        name_ur: "چیک جمہوریہ",
        name_vi: "Cộng hòa Séc",
        name_zh: "捷克",
        name_zht: "捷克共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CZE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.01699588385867, 51.10667409932158],
            [15.490972120839729, 50.78472992614321],
            [16.23862674323857, 50.69773265237984],
            [16.176253289462267, 50.42260732685791],
            [16.719475945714436, 50.21574656839354],
            [16.86876915860566, 50.47397370055603],
            [17.55456709155112, 50.36214590107642],
            [17.64944502123899, 50.049038397819956],
            [18.392913852622172, 49.98862864847075],
            [18.853144158613617, 49.49622976337764],
            [18.554971144289482, 49.495015367218784],
            [18.399993523846177, 49.31500051533004],
            [18.170498488037964, 49.271514797556435],
            [18.104972771891852, 49.04398346617531],
            [17.913511590250465, 48.996492824899086],
            [17.88648481616181, 48.90347524677371],
            [17.545006951577108, 48.80001902932537],
            [17.101984897538898, 48.816968899117114],
            [16.960288120194576, 48.5969823268506],
            [16.499282667718774, 48.78580801044511],
            [16.02964725105022, 48.73389903420793],
            [15.253415561593982, 49.03907420510758],
            [14.901447381254057, 48.964401760445824],
            [14.338897739324722, 48.55530528420721],
            [13.595945672264437, 48.87717194273715],
            [13.031328973043431, 49.30706818297324],
            [12.521024204161193, 49.547415269562734],
            [12.415190870827445, 49.96912079528057],
            [12.240111118222558, 50.266337795607285],
            [12.966836785543194, 50.484076443069085],
            [13.338131951560285, 50.73323436136435],
            [14.056227654688172, 50.9269176295943],
            [14.307013380600637, 51.117267767941414],
            [14.570718214586066, 51.002339382524276],
            [15.01699588385867, 51.10667409932158],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Latvia",
        sov_a3: "LVA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Latvia",
        adm0_a3: "LVA",
        geou_dif: 0,
        geounit: "Latvia",
        gu_a3: "LVA",
        su_dif: 0,
        subunit: "Latvia",
        su_a3: "LVA",
        brk_diff: 0,
        name: "Latvia",
        name_long: "Latvia",
        brk_a3: "LVA",
        brk_name: "Latvia",
        brk_group: null,
        abbrev: "Lat.",
        postal: "LV",
        formal_en: "Republic of Latvia",
        formal_fr: null,
        name_ciawf: "Latvia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Latvia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 7,
        mapcolor9: 6,
        mapcolor13: 13,
        pop_est: 1912789,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 34102,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "LG",
        iso_a2: "LV",
        iso_a2_eh: "LV",
        iso_a3: "LVA",
        iso_a3_eh: "LVA",
        iso_n3: "428",
        iso_n3_eh: "428",
        un_a3: "428",
        wb_a2: "LV",
        wb_a3: "LVA",
        woe_id: 23424874,
        woe_id_eh: 23424874,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LVA",
        adm0_diff: null,
        adm0_tlc: "LVA",
        adm0_a3_us: "LVA",
        adm0_a3_fr: "LVA",
        adm0_a3_ru: "LVA",
        adm0_a3_es: "LVA",
        adm0_a3_cn: "LVA",
        adm0_a3_tw: "LVA",
        adm0_a3_in: "LVA",
        adm0_a3_np: "LVA",
        adm0_a3_pk: "LVA",
        adm0_a3_de: "LVA",
        adm0_a3_gb: "LVA",
        adm0_a3_br: "LVA",
        adm0_a3_il: "LVA",
        adm0_a3_ps: "LVA",
        adm0_a3_sa: "LVA",
        adm0_a3_eg: "LVA",
        adm0_a3_ma: "LVA",
        adm0_a3_pt: "LVA",
        adm0_a3_ar: "LVA",
        adm0_a3_jp: "LVA",
        adm0_a3_ko: "LVA",
        adm0_a3_vn: "LVA",
        adm0_a3_tr: "LVA",
        adm0_a3_id: "LVA",
        adm0_a3_pl: "LVA",
        adm0_a3_gr: "LVA",
        adm0_a3_it: "LVA",
        adm0_a3_nl: "LVA",
        adm0_a3_se: "LVA",
        adm0_a3_bd: "LVA",
        adm0_a3_ua: "LVA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 25.458723,
        label_y: 57.066872,
        ne_id: 1159321033,
        wikidataid: "Q211",
        name_ar: "لاتفيا",
        name_bn: "লাতভিয়া",
        name_de: "Lettland",
        name_en: "Latvia",
        name_es: "Letonia",
        name_fa: "لتونی",
        name_fr: "Lettonie",
        name_el: "Λετονία",
        name_he: "לטביה",
        name_hi: "लातविया",
        name_hu: "Lettország",
        name_id: "Latvia",
        name_it: "Lettonia",
        name_ja: "ラトビア",
        name_ko: "라트비아",
        name_nl: "Letland",
        name_pl: "Łotwa",
        name_pt: "Letónia",
        name_ru: "Латвия",
        name_sv: "Lettland",
        name_tr: "Letonya",
        name_uk: "Латвія",
        name_ur: "لٹویا",
        name_vi: "Latvia",
        name_zh: "拉脱维亚",
        name_zht: "拉脫維亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LVA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.288184848751513, 57.47452830670383],
            [27.77001590344093, 57.24425812441123],
            [27.855282016722526, 56.75932648378429],
            [28.17670942557794, 56.16912995057879],
            [27.10245975109453, 55.783313707087686],
            [26.494331495883756, 55.615106919977634],
            [25.533046502390334, 56.100296942766036],
            [25.000934279080894, 56.16453074810484],
            [24.86068444184076, 56.37252838807963],
            [23.878263787539964, 56.27367137310527],
            [22.201156853939494, 56.33780182557949],
            [21.055800408622417, 56.031076361711065],
            [21.090423618257972, 56.78387278912294],
            [21.581866489353672, 57.411870632549935],
            [22.52434126149288, 57.75337433535076],
            [23.318452996522097, 57.00623647727487],
            [24.12072960785343, 57.02569265403277],
            [24.312862583114622, 57.79342357037697],
            [25.16459354014927, 57.97015696881519],
            [25.60280968598437, 57.84752879498657],
            [26.463532342237787, 57.47638865826633],
            [27.288184848751513, 57.47452830670383],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Germany",
        sov_a3: "DEU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Germany",
        adm0_a3: "DEU",
        geou_dif: 0,
        geounit: "Germany",
        gu_a3: "DEU",
        su_dif: 0,
        subunit: "Germany",
        su_a3: "DEU",
        brk_diff: 0,
        name: "Germany",
        name_long: "Germany",
        brk_a3: "DEU",
        brk_name: "Germany",
        brk_group: null,
        abbrev: "Ger.",
        postal: "D",
        formal_en: "Federal Republic of Germany",
        formal_fr: null,
        name_ciawf: "Germany",
        note_adm0: null,
        note_brk: null,
        name_sort: "Germany",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 1,
        pop_est: 83132799,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 3861123,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "GM",
        iso_a2: "DE",
        iso_a2_eh: "DE",
        iso_a3: "DEU",
        iso_a3_eh: "DEU",
        iso_n3: "276",
        iso_n3_eh: "276",
        un_a3: "276",
        wb_a2: "DE",
        wb_a3: "DEU",
        woe_id: 23424829,
        woe_id_eh: 23424829,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DEU",
        adm0_diff: null,
        adm0_tlc: "DEU",
        adm0_a3_us: "DEU",
        adm0_a3_fr: "DEU",
        adm0_a3_ru: "DEU",
        adm0_a3_es: "DEU",
        adm0_a3_cn: "DEU",
        adm0_a3_tw: "DEU",
        adm0_a3_in: "DEU",
        adm0_a3_np: "DEU",
        adm0_a3_pk: "DEU",
        adm0_a3_de: "DEU",
        adm0_a3_gb: "DEU",
        adm0_a3_br: "DEU",
        adm0_a3_il: "DEU",
        adm0_a3_ps: "DEU",
        adm0_a3_sa: "DEU",
        adm0_a3_eg: "DEU",
        adm0_a3_ma: "DEU",
        adm0_a3_pt: "DEU",
        adm0_a3_ar: "DEU",
        adm0_a3_jp: "DEU",
        adm0_a3_ko: "DEU",
        adm0_a3_vn: "DEU",
        adm0_a3_tr: "DEU",
        adm0_a3_id: "DEU",
        adm0_a3_pl: "DEU",
        adm0_a3_gr: "DEU",
        adm0_a3_it: "DEU",
        adm0_a3_nl: "DEU",
        adm0_a3_se: "DEU",
        adm0_a3_bd: "DEU",
        adm0_a3_ua: "DEU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 9.678348,
        label_y: 50.961733,
        ne_id: 1159320539,
        wikidataid: "Q183",
        name_ar: "ألمانيا",
        name_bn: "জার্মানি",
        name_de: "Deutschland",
        name_en: "Germany",
        name_es: "Alemania",
        name_fa: "آلمان",
        name_fr: "Allemagne",
        name_el: "Γερμανία",
        name_he: "גרמניה",
        name_hi: "जर्मनी",
        name_hu: "Németország",
        name_id: "Jerman",
        name_it: "Germania",
        name_ja: "ドイツ",
        name_ko: "독일",
        name_nl: "Duitsland",
        name_pl: "Niemcy",
        name_pt: "Alemanha",
        name_ru: "Германия",
        name_sv: "Tyskland",
        name_tr: "Almanya",
        name_uk: "Німеччина",
        name_ur: "جرمنی",
        name_vi: "Đức",
        name_zh: "德国",
        name_zht: "德國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DEU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.119686313542559, 53.75702912049104],
            [14.353315463934138, 53.24817129171297],
            [14.074521111719434, 52.98126251892535],
            [14.437599725002201, 52.62485016540839],
            [14.685026482815687, 52.0899474147552],
            [14.607098422919535, 51.74518809671997],
            [15.01699588385867, 51.10667409932158],
            [14.570718214586066, 51.002339382524276],
            [14.307013380600637, 51.117267767941414],
            [14.056227654688172, 50.9269176295943],
            [13.338131951560285, 50.73323436136435],
            [12.966836785543194, 50.484076443069085],
            [12.240111118222558, 50.266337795607285],
            [12.415190870827445, 49.96912079528057],
            [12.521024204161193, 49.547415269562734],
            [13.031328973043431, 49.30706818297324],
            [13.595945672264437, 48.87717194273715],
            [13.243357374737, 48.416114813829054],
            [12.884102817443875, 48.28914581968786],
            [13.02585127122049, 47.63758352313583],
            [12.932626987365948, 47.467645575544],
            [12.620759718484493, 47.67238760028441],
            [12.141357456112788, 47.703083401065776],
            [11.426414015354737, 47.523766181012974],
            [10.544504021861599, 47.5663992376538],
            [10.402083774465211, 47.30248769793916],
            [9.89606814946319, 47.580196845075704],
            [9.59422610844635, 47.52505809182027],
            [8.522611932009767, 47.83082754169129],
            [8.317301466514095, 47.61357982033627],
            [7.466759067422231, 47.62058197691181],
            [7.593676385131062, 48.33301911070372],
            [8.099278598674744, 49.01778351500333],
            [6.658229607783568, 49.20195831969157],
            [6.186320428094177, 49.463802802114515],
            [6.242751092156993, 49.90222565367873],
            [6.043073357781111, 50.128051662794235],
            [6.15665815595878, 50.80372101501058],
            [5.988658074577813, 51.851615709025054],
            [6.589396599970826, 51.852029120483394],
            [6.842869500362383, 52.22844025329755],
            [7.092053256873896, 53.144043280644894],
            [6.905139601274129, 53.48216217713065],
            [7.100424838905269, 53.69393219666267],
            [7.936239454793963, 53.74829580343379],
            [8.121706170289485, 53.52779246684429],
            [8.800734490604668, 54.020785630908904],
            [8.57211795414537, 54.39564647075406],
            [8.526229282270208, 54.96274363872516],
            [9.282048780971138, 54.830865383516176],
            [9.921906365609118, 54.98310415304803],
            [9.9395797054529, 54.596641954153256],
            [10.950112338920519, 54.363607082733154],
            [10.93946699386845, 54.00869334575259],
            [11.956252475643282, 54.19648550070116],
            [12.518440382546714, 54.470370591847995],
            [13.647467075259499, 54.0755109727059],
            [14.119686313542559, 53.75702912049104],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Norway",
        sov_a3: "NOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: null,
        admin: "Norway",
        adm0_a3: "NOR",
        geou_dif: 0,
        geounit: "Norway",
        gu_a3: "NOR",
        su_dif: 0,
        subunit: "Norway",
        su_a3: "NOR",
        brk_diff: 0,
        name: "Norway",
        name_long: "Norway",
        brk_a3: "NOR",
        brk_name: "Norway",
        brk_group: null,
        abbrev: "Nor.",
        postal: "N",
        formal_en: "Kingdom of Norway",
        formal_fr: null,
        name_ciawf: "Norway",
        note_adm0: null,
        note_brk: null,
        name_sort: "Norway",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 12,
        pop_est: 5347896,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 403336,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "NO",
        iso_a3: "-99",
        iso_a3_eh: "NOR",
        iso_n3: "-99",
        iso_n3_eh: "578",
        un_a3: "-99",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -90,
        woe_id_eh: 23424910,
        woe_note:
          "Does not include Svalbard, Jan Mayen, or Bouvet Islands (28289410).",
        adm0_iso: "NOR",
        adm0_diff: null,
        adm0_tlc: "NOR",
        adm0_a3_us: "NOR",
        adm0_a3_fr: "NOR",
        adm0_a3_ru: "NOR",
        adm0_a3_es: "NOR",
        adm0_a3_cn: "NOR",
        adm0_a3_tw: "NOR",
        adm0_a3_in: "NOR",
        adm0_a3_np: "NOR",
        adm0_a3_pk: "NOR",
        adm0_a3_de: "NOR",
        adm0_a3_gb: "NOR",
        adm0_a3_br: "NOR",
        adm0_a3_il: "NOR",
        adm0_a3_ps: "NOR",
        adm0_a3_sa: "NOR",
        adm0_a3_eg: "NOR",
        adm0_a3_ma: "NOR",
        adm0_a3_pt: "NOR",
        adm0_a3_ar: "NOR",
        adm0_a3_jp: "NOR",
        adm0_a3_ko: "NOR",
        adm0_a3_vn: "NOR",
        adm0_a3_tr: "NOR",
        adm0_a3_id: "NOR",
        adm0_a3_pl: "NOR",
        adm0_a3_gr: "NOR",
        adm0_a3_it: "NOR",
        adm0_a3_nl: "NOR",
        adm0_a3_se: "NOR",
        adm0_a3_bd: "NOR",
        adm0_a3_ua: "NOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 9.679975,
        label_y: 61.357092,
        ne_id: 1159321109,
        wikidataid: "Q20",
        name_ar: "النرويج",
        name_bn: "নরওয়ে",
        name_de: "Norwegen",
        name_en: "Norway",
        name_es: "Noruega",
        name_fa: "نروژ",
        name_fr: "Norvège",
        name_el: "Νορβηγία",
        name_he: "נורווגיה",
        name_hi: "नॉर्वे",
        name_hu: "Norvégia",
        name_id: "Norwegia",
        name_it: "Norvegia",
        name_ja: "ノルウェー",
        name_ko: "노르웨이",
        name_nl: "Noorwegen",
        name_pl: "Norwegia",
        name_pt: "Noruega",
        name_ru: "Норвегия",
        name_sv: "Norge",
        name_tr: "Norveç",
        name_uk: "Норвегія",
        name_ur: "ناروے",
        name_vi: "Na Uy",
        name_zh: "挪威",
        name_zht: "挪威",
        fclass_iso: "Unrecognized",
        tlc_diff: null,
        fclass_tlc: "Unrecognized",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NOR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [15.14282, 79.67431],
              [15.52255, 80.01608],
              [16.99085, 80.05086],
              [18.25183, 79.70175],
              [21.54383, 78.95611],
              [19.02737, 78.5626],
              [18.47172, 77.82669],
              [17.59441, 77.63796],
              [17.1182, 76.80941],
              [15.91315, 76.77045],
              [13.76259, 77.38035],
              [14.66956, 77.73565],
              [13.1706, 78.02493],
              [11.22231, 78.8693],
              [10.44453, 79.65239],
              [13.17077, 80.01046],
              [13.71852, 79.66039],
              [15.14282, 79.67431],
            ],
          ],
          [
            [
              [31.101042202597625, 69.55810108805622],
              [29.39955, 69.15692000000013],
              [28.591929559043194, 69.06477692328666],
              [29.015572950971972, 69.76649119737799],
              [27.732292107867863, 70.16419302029625],
              [26.179622023226244, 69.82529897732614],
              [25.689212680776365, 69.09211375596904],
              [24.735679152126725, 68.64955678982146],
              [23.66204959483076, 68.89124746365054],
              [22.356237827247412, 68.84174144151491],
              [21.244936150810673, 69.37044302029308],
              [20.645592889089528, 69.10624726020087],
              [20.025268995857886, 69.0651386583127],
              [19.878559604581255, 68.40719432237258],
              [17.993868442464333, 68.56739126247736],
              [17.729181756265348, 68.01055186631628],
              [16.768878614985482, 68.0139366726314],
              [16.108712192456778, 67.30245555283689],
              [15.108411492583002, 66.19386688909547],
              [13.55568973150909, 64.78702769638151],
              [13.919905226302204, 64.44542064071608],
              [13.571916131248713, 64.04911408146971],
              [12.579935336973934, 64.06621898055833],
              [11.930569288794231, 63.12831757267698],
              [11.992064243221563, 61.80036245385655],
              [12.631146681375185, 61.293571682370136],
              [12.3003658382749, 60.11793284773003],
              [11.468271925511146, 59.43239329694604],
              [11.027368605196868, 58.85614940045936],
              [10.356556837616067, 59.469807033925356],
              [8.382000359743586, 58.313288479233215],
              [7.048748406613271, 58.078884182357285],
              [5.665835402050419, 58.5881554225937],
              [5.308234490590678, 59.663231919993834],
              [4.992078077828978, 61.97099803328432],
              [5.912900424837886, 62.614472968182724],
              [8.553411085655739, 63.45400828719648],
              [10.527709181366758, 64.4860383164975],
              [12.358346795306373, 65.87972585719318],
              [14.761145867581604, 67.81064158799516],
              [16.435927361728943, 68.56320547146173],
              [19.18402835457846, 69.81744415961778],
              [21.378416375420585, 70.2551693793461],
              [23.023742303161526, 70.2020718451662],
              [24.546543409938465, 71.03049673123722],
              [26.37004967622181, 70.98626170519539],
              [28.165547316202943, 71.18547435168055],
              [31.293418409965454, 70.4537877468599],
              [30.005435011522792, 70.18625885688489],
              [31.101042202597625, 69.55810108805622],
            ],
          ],
          [
            [
              [27.407505730913446, 80.05640574820042],
              [25.92465050629815, 79.51783397085451],
              [23.024465773213617, 79.40001170522903],
              [20.075188429451828, 79.56682322866722],
              [19.897266473070914, 79.84236196564747],
              [18.462263624757867, 79.85988027619443],
              [17.368015170977458, 80.31889618602698],
              [20.45599205901064, 80.59815562613225],
              [21.907944777115404, 80.35767934846204],
              [22.91925255706738, 80.65714427359343],
              [25.447625359811866, 80.40734039989452],
              [27.407505730913446, 80.05640574820042],
            ],
          ],
          [
            [
              [24.72412, 77.85385],
              [22.49032, 77.44493],
              [20.72601, 77.67704],
              [21.41611, 77.93504],
              [20.8119, 78.25463],
              [22.88426, 78.45494],
              [23.28134, 78.07954],
              [24.72412, 77.85385],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Russia",
        sov_a3: "RUS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Russia",
        adm0_a3: "RUS",
        geou_dif: 0,
        geounit: "Russia",
        gu_a3: "RUS",
        su_dif: 0,
        subunit: "Russia",
        su_a3: "RUS",
        brk_diff: 0,
        name: "Russia",
        name_long: "Russian Federation",
        brk_a3: "RUS",
        brk_name: "Russia",
        brk_group: null,
        abbrev: "Rus.",
        postal: "RUS",
        formal_en: "Russian Federation",
        formal_fr: null,
        name_ciawf: "Russia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Russian Federation",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 7,
        pop_est: 144373535,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1699876,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        fips_10: "RS",
        iso_a2: "RU",
        iso_a2_eh: "RU",
        iso_a3: "RUS",
        iso_a3_eh: "RUS",
        iso_n3: "643",
        iso_n3_eh: "643",
        un_a3: "643",
        wb_a2: "RU",
        wb_a3: "RUS",
        woe_id: 23424936,
        woe_id_eh: 23424936,
        woe_note: "Exact WOE match as country",
        adm0_iso: "RUS",
        adm0_diff: null,
        adm0_tlc: "RUS",
        adm0_a3_us: "RUS",
        adm0_a3_fr: "RUS",
        adm0_a3_ru: "RUS",
        adm0_a3_es: "RUS",
        adm0_a3_cn: "RUS",
        adm0_a3_tw: "RUS",
        adm0_a3_in: "RUS",
        adm0_a3_np: "RUS",
        adm0_a3_pk: "RUS",
        adm0_a3_de: "RUS",
        adm0_a3_gb: "RUS",
        adm0_a3_br: "RUS",
        adm0_a3_il: "RUS",
        adm0_a3_ps: "RUS",
        adm0_a3_sa: "RUS",
        adm0_a3_eg: "RUS",
        adm0_a3_ma: "RUS",
        adm0_a3_pt: "RUS",
        adm0_a3_ar: "RUS",
        adm0_a3_jp: "RUS",
        adm0_a3_ko: "RUS",
        adm0_a3_vn: "RUS",
        adm0_a3_tr: "RUS",
        adm0_a3_id: "RUS",
        adm0_a3_pl: "RUS",
        adm0_a3_gr: "RUS",
        adm0_a3_it: "RUS",
        adm0_a3_nl: "RUS",
        adm0_a3_se: "RUS",
        adm0_a3_bd: "RUS",
        adm0_a3_ua: "RUS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 18,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.2,
        label_x: 44.686469,
        label_y: 58.249357,
        ne_id: 1159321201,
        wikidataid: "Q159",
        name_ar: "روسيا",
        name_bn: "রাশিয়া",
        name_de: "Russland",
        name_en: "Russia",
        name_es: "Rusia",
        name_fa: "روسیه",
        name_fr: "Russie",
        name_el: "Ρωσία",
        name_he: "רוסיה",
        name_hi: "रूस",
        name_hu: "Oroszország",
        name_id: "Rusia",
        name_it: "Russia",
        name_ja: "ロシア",
        name_ko: "러시아",
        name_nl: "Rusland",
        name_pl: "Rosja",
        name_pt: "Rússia",
        name_ru: "Россия",
        name_sv: "Ryssland",
        name_tr: "Rusya",
        name_uk: "Росія",
        name_ur: "روس",
        name_vi: "Nga",
        name_zh: "俄罗斯",
        name_zht: "俄羅斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "RUS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [178.7253, 71.0988],
              [180, 71.51571433642829],
              [180, 70.83219920854673],
              [178.903425, 70.78114],
              [178.7253, 71.0988],
            ],
          ],
          [
            [
              [49.10116000000011, 46.399330000000134],
              [48.64541, 45.806290000000104],
              [47.67591, 45.64149000000015],
              [46.68201, 44.6092000000001],
              [47.59094, 43.66016],
              [47.49252, 42.98658000000012],
              [48.58437000000015, 41.80888],
              [48.58435339611342, 41.80886879162067],
              [47.98728315612598, 41.40581920019423],
              [47.81566572448463, 41.15141612402135],
              [47.37331546406623, 41.2197323675112],
              [46.68607059101661, 41.82713715266988],
              [46.404950799348825, 41.860675157227305],
              [45.7764, 42.09244000000018],
              [45.47027916848572, 42.50278066666998],
              [44.537622918481986, 42.71199270280363],
              [43.931210000000135, 42.55496000000011],
              [43.75599000000011, 42.74083],
              [42.39440000000013, 43.2203],
              [40.922190000000114, 43.38215000000014],
              [40.07696495947977, 43.553104153002316],
              [39.955008579270924, 43.43499766699922],
              [38.68, 44.28],
              [37.53912, 44.65721],
              [36.6754600000001, 45.244690000000105],
              [37.4031700000001, 45.40451000000013],
              [38.23295, 46.24087],
              [37.67372, 46.63657],
              [39.14767000000012, 47.044750000000136],
              [39.1212, 47.26336],
              [38.223538038899335, 47.10218984637595],
              [38.25511233902978, 47.54640045835691],
              [38.77057, 47.825620000000185],
              [39.738277622238854, 47.89893707945197],
              [39.89562000000018, 48.23241],
              [39.67465, 48.78382000000016],
              [40.080789015469406, 49.30742991799934],
              [40.06904000000014, 49.60105],
              [38.59498823421342, 49.9264619004237],
              [38.01063113785693, 49.9156615260747],
              [37.39345950699516, 50.38395335550365],
              [36.62616784032542, 50.225590928745135],
              [35.356116163887975, 50.57719737405904],
              [35.37791, 50.77394],
              [35.02218305841791, 51.2075723333715],
              [34.22481570815427, 51.25599315042888],
              [34.141978387190534, 51.566413479206346],
              [34.39173058445709, 51.768881740925906],
              [33.75269982273579, 52.3350745713318],
              [32.715760532367085, 52.23846548116211],
              [32.41205813978769, 52.28869497334978],
              [32.15944000000013, 52.06125000000014],
              [31.78599244755525, 52.1016775699397],
              [31.78597, 52.1016800000001],
              [31.54001834486226, 52.74205231384636],
              [31.305200636528014, 53.07399587667321],
              [31.49764, 53.16743000000014],
              [32.30451948418823, 53.13272614197291],
              [32.69364301934604, 53.35142080343218],
              [32.405598585751164, 53.61804535584204],
              [31.731272820774507, 53.79402944601202],
              [31.79142418796224, 53.974638576872124],
              [31.38447228366374, 54.15705638286238],
              [30.75753380709872, 54.81177094178432],
              [30.971835971813135, 55.08154775656404],
              [30.87390913262001, 55.55097646750341],
              [29.896294386522356, 55.78946320253041],
              [29.371571893030673, 55.670090643936184],
              [29.229513380660308, 55.91834422466636],
              [28.17670942557794, 56.16912995057879],
              [27.855282016722526, 56.75932648378429],
              [27.77001590344093, 57.24425812441123],
              [27.288184848751513, 57.47452830670383],
              [27.71668582531572, 57.79189911562436],
              [27.42015000000015, 58.72457000000014],
              [28.13169925305175, 59.300825100330925],
              [27.98112, 59.47537000000011],
              [27.981126857000987, 59.47537333432527],
              [29.1177, 60.02805000000012],
              [28.070001921525666, 60.50351912796823],
              [28.07, 60.50352],
              [30.21110721204445, 61.780027777749694],
              [31.139991082490894, 62.35769277612441],
              [31.516092156711125, 62.86768748641289],
              [30.035872430142717, 63.55281362573855],
              [30.44468468600371, 64.20445343693909],
              [29.54442955904699, 64.94867157659048],
              [30.21765, 65.80598],
              [29.054588657352326, 66.94428620062206],
              [29.97742638522061, 67.69829702419275],
              [28.445943637818658, 68.36461294216404],
              [28.591929559043194, 69.06477692328666],
              [29.39955, 69.15692000000013],
              [31.101042202597625, 69.55810108805622],
              [31.10108, 69.55811000000011],
              [32.13272000000018, 69.90595000000019],
              [33.77547, 69.30142000000012],
              [36.51396, 69.06342],
              [40.29234000000014, 67.9324],
              [41.05987, 67.45713000000012],
              [41.1259500000001, 66.79158],
              [40.01583, 66.26618000000013],
              [38.38295, 65.9995300000001],
              [33.91871000000015, 66.75961],
              [33.18444, 66.63253],
              [34.814770000000124, 65.90015000000017],
              [34.87857425307874, 65.4362128770482],
              [34.94391, 64.41437000000019],
              [36.23129, 64.10945],
              [37.01273, 63.84983000000011],
              [37.141970000000185, 64.33471],
              [36.53957903508979, 64.76446],
              [37.17604, 65.14322000000016],
              [39.59345, 64.5207900000002],
              [40.4356, 64.76446],
              [39.76260000000019, 65.49682],
              [42.09309000000013, 66.47623],
              [43.01604, 66.41858000000013],
              [43.94975000000011, 66.06908],
              [44.53226, 66.75634000000014],
              [43.69839, 67.35245],
              [44.18795000000017, 67.95051000000012],
              [43.45282, 68.57079],
              [46.250000000000114, 68.25],
              [46.82134, 67.68997],
              [45.55517, 67.56652],
              [45.56202000000013, 67.01005000000015],
              [46.34915000000012, 66.6676700000001],
              [47.89416000000017, 66.8845500000001],
              [48.13876, 67.52238],
              [50.22766000000013, 67.99867000000017],
              [53.71743000000015, 68.85738000000015],
              [54.47171, 68.80815],
              [53.48582000000016, 68.20131],
              [54.72628, 68.09702],
              [55.44268000000011, 68.43866],
              [57.31702, 68.46628],
              [58.802000000000135, 68.88082],
              [59.94142000000011, 68.27844],
              [61.07784000000021, 68.94069],
              [60.03, 69.52],
              [60.55, 69.85],
              [63.50400000000013, 69.54739],
              [64.888115, 69.234835],
              [68.51216000000016, 68.09233000000012],
              [69.18068000000011, 68.61563000000012],
              [68.16444, 69.14436],
              [68.13522, 69.35649000000012],
              [66.93008000000015, 69.45461000000012],
              [67.25976, 69.92873],
              [66.72492000000017, 70.70889000000017],
              [66.69466, 71.02897000000019],
              [68.54006, 71.93450000000018],
              [69.19636, 72.84336000000019],
              [69.94, 73.04],
              [72.58754, 72.77629000000013],
              [72.79603, 72.22006],
              [71.84811, 71.40898],
              [72.47011, 71.09019],
              [72.79188, 70.39114],
              [72.56470000000013, 69.02085],
              [73.66787, 68.4079],
              [73.2387, 67.7404],
              [71.28, 66.3200000000001],
              [72.42301, 66.17267000000021],
              [72.82077, 66.53267],
              [73.92099000000013, 66.78946000000013],
              [74.18651000000011, 67.28429],
              [75.052, 67.76047000000011],
              [74.46926000000013, 68.32899],
              [74.93584, 68.98918],
              [73.84236, 69.07146],
              [73.60187000000019, 69.62763],
              [74.3998, 70.63175],
              [73.1011, 71.4471700000002],
              [74.89082000000019, 72.12119000000013],
              [74.65926, 72.83227],
              [75.1580100000001, 72.85497000000015],
              [75.68351000000013, 72.30056000000013],
              [75.28898, 71.3355600000001],
              [76.35911, 71.15287000000018],
              [75.90313000000015, 71.87401],
              [77.57665, 72.26717],
              [79.6520200000001, 72.32011],
              [81.5, 71.75],
              [80.61071, 72.58285000000012],
              [80.51109, 73.6482],
              [82.25, 73.85000000000014],
              [84.65526, 73.80591000000021],
              [86.82230000000015, 73.93688],
              [86.00956, 74.45967000000019],
              [87.16682000000014, 75.11643],
              [88.31571, 75.14393],
              [90.26, 75.64],
              [92.90058, 75.7733300000001],
              [93.23421, 76.0472],
              [95.86000000000018, 76.1400000000001],
              [96.67821, 75.91548],
              [98.92254000000014, 76.44689],
              [100.75967000000014, 76.43028],
              [101.03532, 76.86189],
              [101.9908400000001, 77.28754000000015],
              [104.35160000000013, 77.69792],
              [106.06664000000018, 77.37389],
              [104.70500000000015, 77.1274],
              [106.97013, 76.97419],
              [107.24000000000012, 76.48],
              [108.1538000000001, 76.72335],
              [111.0772600000002, 76.71],
              [113.33151, 76.22224],
              [114.13417, 75.84764],
              [113.88539, 75.32779000000016],
              [112.77918, 75.03186000000011],
              [110.15125000000012, 74.47673],
              [109.4, 74.18],
              [110.64, 74.04],
              [112.11919, 73.78774000000016],
              [113.01954000000023, 73.97693000000015],
              [113.52958000000024, 73.33505000000014],
              [113.96881, 73.59488],
              [115.56782, 73.75285],
              [118.77633000000014, 73.58772],
              [119.02, 73.12],
              [123.20066, 72.97122],
              [123.25777000000016, 73.73503],
              [125.38000000000011, 73.56],
              [126.97644, 73.56549000000012],
              [128.59126, 73.03871],
              [129.05157, 72.39872],
              [128.46, 71.98],
              [129.71599000000015, 71.19304000000011],
              [131.28858000000025, 70.78699000000012],
              [132.25350000000014, 71.83630000000011],
              [133.85766000000024, 71.3864200000001],
              [135.56193, 71.65525000000014],
              [137.49755, 71.34763],
              [138.23409000000015, 71.62803],
              [139.86983, 71.48783000000014],
              [139.14791, 72.41619000000014],
              [140.46817, 72.84941000000015],
              [149.5, 72.2],
              [150.3511800000001, 71.60643],
              [152.9689000000002, 70.84222],
              [157.00688, 71.03141],
              [158.99779, 70.86672],
              [159.83031000000017, 70.45324],
              [159.70866, 69.72198],
              [160.9405300000003, 69.4372800000001],
              [162.27907, 69.64204],
              [164.05248, 69.66823],
              [165.94037000000014, 69.47199],
              [167.83567, 69.58269000000013],
              [169.5776300000001, 68.6938],
              [170.8168800000002, 69.01363],
              [170.0082000000001, 69.65276],
              [170.4534500000002, 70.09703],
              [173.64391000000012, 69.81743],
              [175.7240300000002, 69.87725000000017],
              [178.6, 69.4],
              [180, 68.96363636363651],
              [180, 64.97970870219848],
              [179.99281, 64.97433],
              [178.70720000000028, 64.53493],
              [177.41128, 64.60821],
              [178.3130000000001, 64.07593],
              [178.90825000000018, 63.25197],
              [179.37034, 62.98262000000011],
              [179.48636, 62.56894],
              [179.22825, 62.304100000000176],
              [177.3643, 62.5219],
              [174.56929000000025, 61.76915],
              [173.68013, 61.65261],
              [172.15, 60.95],
              [170.6985, 60.33618000000013],
              [170.33085000000028, 59.88177],
              [168.90046, 60.57355],
              [166.29498000000024, 59.78855000000016],
              [165.84000000000015, 60.16],
              [164.87674, 59.7316],
              [163.53929, 59.86871],
              [163.21711000000016, 59.21101],
              [162.01733, 58.24328],
              [162.05297, 57.83912],
              [163.19191, 57.615030000000104],
              [163.05794000000014, 56.159240000000125],
              [162.12958000000015, 56.12219],
              [161.70146, 55.285680000000184],
              [162.11749, 54.85514],
              [160.36877000000027, 54.34433],
              [160.02173000000016, 53.20257],
              [158.5309400000001, 52.958680000000186],
              [158.23118, 51.94269],
              [156.78979000000027, 51.01105],
              [156.42, 51.7],
              [155.99182, 53.15895],
              [155.43366, 55.38103000000012],
              [155.9144200000003, 56.767920000000174],
              [156.75815, 57.3647],
              [156.81035, 57.83204],
              [158.3643300000001, 58.05575],
              [160.15064, 59.31477],
              [161.87204, 60.343],
              [163.66969, 61.1409000000001],
              [164.47355, 62.55061],
              [163.25842000000011, 62.46627000000012],
              [162.65791, 61.6425],
              [160.12148, 60.54423],
              [159.30232, 61.7739600000001],
              [156.72068, 61.43442],
              [154.21806000000026, 59.75818000000015],
              [155.04375, 59.14495],
              [152.81185, 58.88385],
              [151.2657300000002, 58.78089],
              [151.33815, 59.50396],
              [149.78371, 59.655730000000176],
              [148.54481, 59.16448],
              [145.48722, 59.33637],
              [142.19782, 59.03998000000013],
              [138.95848000000024, 57.08805],
              [135.12619, 54.72959],
              [136.70171, 54.603550000000155],
              [137.19342, 53.97732],
              [138.1647, 53.7550100000002],
              [138.80463, 54.25455],
              [139.90151, 54.18968000000012],
              [141.34531, 53.08957000000015],
              [141.37923, 52.23877],
              [140.59742000000017, 51.2396700000001],
              [140.51308, 50.045530000000156],
              [140.0619300000002, 48.44671000000011],
              [138.5547200000002, 46.99965],
              [138.21971, 46.30795],
              [136.86232, 45.14350000000013],
              [135.51535000000013, 43.989],
              [134.86939000000018, 43.39821],
              [133.53687000000025, 42.81147],
              [132.90627, 42.79849000000013],
              [132.27807000000018, 43.28456000000011],
              [130.93587000000014, 42.55274],
              [130.78000485358513, 42.22001036108258],
              [130.78000366004676, 42.220007813203225],
              [130.78, 42.22000000000014],
              [130.77999231657833, 42.22000960427719],
              [130.6400000000001, 42.395],
              [130.63999970690955, 42.39502427522179],
              [130.63386640840974, 42.90301463477053],
              [131.1446879416149, 42.92998973242689],
              [131.28855512911548, 44.11151968034828],
              [131.02519000000018, 44.96796],
              [131.8834542176595, 45.32116160743652],
              [133.09712000000013, 45.14409],
              [133.76964399631288, 46.116926988299014],
              [134.1123500000001, 47.21248],
              [134.50081, 47.57845],
              [135.02631147678665, 48.478229885443874],
              [133.37359581922794, 48.18344167743487],
              [132.50669, 47.78896],
              [130.9872600000001, 47.79013],
              [130.58229332898242, 48.72968740497614],
              [129.39781782442043, 49.44060008401544],
              [127.65740000000028, 49.760270000000105],
              [127.28745568248485, 50.73979726826548],
              [126.93915652883766, 51.35389415140592],
              [126.56439904185697, 51.7842554795327],
              [125.94634891164617, 52.79279857035698],
              [125.06821129771038, 53.16104482686886],
              [123.57147, 53.4588],
              [122.24574791879283, 53.431725979213695],
              [121.00308475147017, 53.25140106873119],
              [120.17708865771681, 52.75388621684124],
              [120.72578901579197, 52.516226304730935],
              [120.7382, 51.96411],
              [120.18208000000016, 51.64355000000012],
              [119.27939, 50.58292],
              [119.2884607280258, 50.142882798862004],
              [117.8792444194264, 49.510983384796965],
              [116.67880089728612, 49.888531399121405],
              [115.48569542853136, 49.80517731383475],
              [114.96210981655018, 50.140247300815126],
              [114.36245649623527, 50.24830272073741],
              [112.89773969935436, 49.54356537535699],
              [111.58123091028662, 49.37796824807769],
              [110.66201053267878, 49.13012807880585],
              [109.40244917199664, 49.292960516957635],
              [108.47516727095129, 49.28254771585074],
              [107.86817589725095, 49.793705145865815],
              [106.8888041524553, 50.27429596618032],
              [105.88659142458675, 50.406019192092224],
              [104.62158, 50.27532000000019],
              [103.67654544476022, 50.089966132195116],
              [102.25589, 50.51056000000011],
              [102.06521, 51.25991],
              [100.88948042196262, 51.51685578063832],
              [99.98173221232354, 51.63400625264399],
              [98.86149051310034, 52.04736603454669],
              [97.82573978067431, 51.01099518493318],
              [98.23176150919156, 50.422400621128745],
              [97.25976000000014, 49.72605],
              [95.8140200000002, 49.97746000000012],
              [94.81594933469873, 50.01343333597085],
              [94.14756635943559, 50.48053660745717],
              [93.10421, 50.49529],
              [92.23471154171968, 50.80217072204172],
              [90.71366743364068, 50.33181183532109],
              [88.80556684769552, 49.47052073831242],
              [87.75126427607671, 49.297197984405486],
              [87.35997033076265, 49.21498078062912],
              [86.82935672398963, 49.82667470966817],
              [85.54126997268247, 49.69285858824816],
              [85.11555952346203, 50.11730296487764],
              [84.41637739455308, 50.311399644565824],
              [83.93511478061885, 50.88924551045358],
              [83.38300377801238, 51.069182847693924],
              [81.94598554883993, 50.81219594990637],
              [80.56844689323549, 51.38833649352847],
              [80.03555952344169, 50.86475088154725],
              [77.80091556184425, 53.404414984747575],
              [76.52517947785475, 54.17700348572714],
              [76.89110029491343, 54.49052440044193],
              [74.38482000000016, 53.54685000000012],
              [73.42567874542044, 53.489810289109755],
              [73.5085160663844, 54.0356167669766],
              [72.22415001820218, 54.376655381886735],
              [71.18013105660941, 54.13328522400826],
              [70.86526655465514, 55.169733588270105],
              [69.06816694527288, 55.38525014914353],
              [68.16910037625883, 54.97039175070432],
              [65.66687, 54.60125000000011],
              [65.17853356309593, 54.35422781027211],
              [61.43660000000017, 54.00625],
              [60.978066440683165, 53.66499339457914],
              [61.699986199800605, 52.97999644633427],
              [60.73999311711458, 52.71998647725775],
              [60.92726850774028, 52.44754832621504],
              [59.967533807215545, 51.9604204372157],
              [61.58800337102417, 51.272658799843214],
              [61.337424350840934, 50.79907013610426],
              [59.93280724471549, 50.842194118851864],
              [59.642282342370606, 50.545442206415714],
              [58.36332, 51.06364],
              [56.77798000000013, 51.04355],
              [55.71694, 50.62171000000018],
              [54.532878452376224, 51.02623973245932],
              [52.32872358583097, 51.718652248738124],
              [50.76664839051216, 51.6927623561599],
              [48.70238162618102, 50.60512848571284],
              [48.57784142435753, 49.87475962991567],
              [47.54948042174931, 50.454698391311126],
              [46.75159630716274, 49.35600576435377],
              [47.04367150247651, 49.152038886097614],
              [46.46644575377627, 48.39415233010493],
              [47.31524000000013, 47.7158500000001],
              [48.05725, 47.74377],
              [48.694733514201744, 47.07562816017793],
              [48.59325000000018, 46.56104],
              [49.10116000000011, 46.399330000000134],
            ],
          ],
          [
            [
              [93.77766, 81.0246],
              [95.940895, 81.2504],
              [97.88385, 80.746975],
              [100.186655, 79.780135],
              [99.93976, 78.88094],
              [97.75794, 78.7562],
              [94.97259, 79.044745],
              [93.31288, 79.4265],
              [92.5454, 80.14379],
              [91.18107, 80.34146],
              [93.77766, 81.0246],
            ],
          ],
          [
            [
              [102.837815, 79.28129],
              [105.37243, 78.71334],
              [105.07547, 78.30689],
              [99.43814, 77.921],
              [101.2649, 79.23399],
              [102.08635, 79.34641],
              [102.837815, 79.28129],
            ],
          ],
          [
            [
              [138.831075, 76.13676],
              [141.471615, 76.09289],
              [145.086285, 75.562625],
              [144.3, 74.82],
              [140.61381, 74.84768],
              [138.95544, 74.61148],
              [136.97439, 75.26167],
              [137.51176, 75.94917],
              [138.831075, 76.13676],
            ],
          ],
          [
            [
              [148.22223, 75.345845],
              [150.73167, 75.08406],
              [149.575925, 74.68892],
              [147.977465, 74.778355],
              [146.11919, 75.17298],
              [146.358485, 75.49682],
              [148.22223, 75.345845],
            ],
          ],
          [
            [
              [139.86312, 73.36983],
              [140.81171, 73.76506],
              [142.06207, 73.85758],
              [143.48283, 73.47525],
              [143.60385, 73.21244],
              [142.08763, 73.20544],
              [140.038155, 73.31692],
              [139.86312, 73.36983],
            ],
          ],
          [
            [
              [44.84695804218114, 80.58980988231714],
              [46.79913862487123, 80.77191762971368],
              [48.31847741068461, 80.78400991486998],
              [48.52280602396667, 80.51456899690017],
              [49.097189568890855, 80.7539859077084],
              [50.03976769389462, 80.91888540315178],
              [51.522932977103665, 80.69972565380193],
              [51.13618655783128, 80.54728017854093],
              [49.79368452332071, 80.4154277615482],
              [48.89441124857755, 80.33956675894375],
              [48.75493655782177, 80.17546824820089],
              [47.58611901224418, 80.01018117951533],
              [46.50282596210963, 80.2472468126543],
              [47.07245527526294, 80.55942414012951],
              [44.84695804218114, 80.58980988231714],
            ],
          ],
          [
            [
              [22.731098667092652, 54.327536932993326],
              [20.892244500418627, 54.31252492941253],
              [19.660640089606403, 54.42608388937393],
              [19.888481479581287, 54.86616038677151],
              [21.268448927503467, 55.190481675835315],
              [22.315723504330577, 55.015298570365864],
              [22.75776370615526, 54.85657440858138],
              [22.65105187347254, 54.582740993866736],
              [22.731098667092652, 54.327536932993326],
            ],
          ],
          [
            [
              [53.50828982932515, 73.7498139513002],
              [55.90245893740766, 74.62748647734536],
              [55.631932814359686, 75.08141225859718],
              [57.86864383324885, 75.60939036732326],
              [61.170044386647476, 76.25188345000812],
              [64.49836836127017, 76.43905548776927],
              [66.21097700385513, 76.80978221303117],
              [68.1570597675348, 76.93969676381293],
              [68.85221113472508, 76.5448113064546],
              [68.1805725442276, 76.23364166940907],
              [64.63732628770302, 75.73775462513625],
              [61.58350752141476, 75.26088450794684],
              [58.47708214705335, 74.30905630156285],
              [56.98678551618803, 73.33304352486623],
              [55.419335971910925, 72.37126760526603],
              [55.62283776227633, 71.54059479439032],
              [57.535692579992315, 70.72046397570212],
              [56.94497928246388, 70.63274323188666],
              [53.67737511578417, 70.76265778266846],
              [53.412016635965394, 71.20666168892022],
              [51.601894565645665, 71.47475901965045],
              [51.455753615124216, 72.01488108996513],
              [52.47827518088354, 72.22944163684097],
              [52.44416873557088, 72.77473135038481],
              [54.42761355979758, 73.6275475124976],
              [53.50828982932515, 73.7498139513002],
            ],
          ],
          [
            [
              [142.91461551327654, 53.704577541714784],
              [143.2608476096321, 52.74076040303906],
              [143.23526777564763, 51.75666026468876],
              [143.64800744036285, 50.747600409541505],
              [144.6541475770856, 48.97639069273754],
              [143.1739278505172, 49.306551418650315],
              [142.55866824765013, 47.86157501890495],
              [143.53349246640403, 46.83672801369252],
              [143.50527713437265, 46.137907619809525],
              [142.74770063697383, 46.74076487892651],
              [142.09203006405454, 45.966755276058834],
              [141.906925083585, 46.80592886004656],
              [142.01844282447087, 47.78013296161296],
              [141.90444461483503, 48.85918854429957],
              [142.1358000022057, 49.61516307229739],
              [142.17998335181528, 50.9523424342819],
              [141.59407596249002, 51.9354348822025],
              [141.6825460145737, 53.301966457728795],
              [142.60693403541075, 53.762145087287934],
              [142.20974897681543, 54.225475979216874],
              [142.65478641171302, 54.36588084575389],
              [142.91461551327654, 53.704577541714784],
            ],
          ],
          [
            [
              [-174.92825, 67.20589],
              [-175.01425, 66.58435],
              [-174.33983, 66.33556],
              [-174.57182, 67.06219],
              [-171.85731, 66.91308],
              [-169.89958, 65.97724],
              [-170.89107, 65.54139],
              [-172.53025, 65.43791],
              [-172.555, 64.46079],
              [-172.95533, 64.25269],
              [-173.89184, 64.2826],
              [-174.65392, 64.63125],
              [-175.98353, 64.92288],
              [-176.20716, 65.35667],
              [-177.22266, 65.52024],
              [-178.35993, 65.39052],
              [-178.90332, 65.74044],
              [-178.68611, 66.11211],
              [-179.88377, 65.87456],
              [-179.43268, 65.40411],
              [-180, 64.9797087021984],
              [-180, 68.96363636363635],
              [-177.55, 68.2],
              [-174.92825, 67.20589],
            ],
          ],
          [
            [
              [-178.69378, 70.89302],
              [-180, 70.83219920854673],
              [-180, 71.51571433642827],
              [-179.871875, 71.55762],
              [-179.02433, 71.55553],
              [-177.577945, 71.26948],
              [-177.663575, 71.13277],
              [-178.69378, 70.89302],
            ],
          ],
          [
            [
              [33.435988094713366, 45.971917370797485],
              [33.69946184910907, 46.219572831556434],
              [34.41040172853718, 46.005162391728845],
              [34.73201738827845, 45.96566573176062],
              [34.861792128174045, 45.76818243191957],
              [35.01265897004737, 45.73772519982549],
              [35.02078779474607, 45.65121898048466],
              [35.51000857925311, 45.40999339454612],
              [36.52999799983019, 45.46998973243717],
              [36.334712762199274, 45.11321564389402],
              [35.239999220528205, 44.93999624285175],
              [33.882511020652885, 44.361478583344194],
              [33.32642093276013, 44.564877020844904],
              [33.546924269349404, 45.03477081967486],
              [32.4541744321055, 45.327466132176085],
              [32.63080447767919, 45.51918569597899],
              [33.58816206231842, 45.85156850848023],
              [33.435988094713366, 45.971917370797485],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Estonia",
        sov_a3: "EST",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Estonia",
        adm0_a3: "EST",
        geou_dif: 0,
        geounit: "Estonia",
        gu_a3: "EST",
        su_dif: 0,
        subunit: "Estonia",
        su_a3: "EST",
        brk_diff: 0,
        name: "Estonia",
        name_long: "Estonia",
        brk_a3: "EST",
        brk_name: "Estonia",
        brk_group: null,
        abbrev: "Est.",
        postal: "EST",
        formal_en: "Republic of Estonia",
        formal_fr: null,
        name_ciawf: "Estonia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Estonia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 10,
        pop_est: 1326590,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 31471,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "EN",
        iso_a2: "EE",
        iso_a2_eh: "EE",
        iso_a3: "EST",
        iso_a3_eh: "EST",
        iso_n3: "233",
        iso_n3_eh: "233",
        un_a3: "233",
        wb_a2: "EE",
        wb_a3: "EST",
        woe_id: 23424805,
        woe_id_eh: 23424805,
        woe_note: "Exact WOE match as country",
        adm0_iso: "EST",
        adm0_diff: null,
        adm0_tlc: "EST",
        adm0_a3_us: "EST",
        adm0_a3_fr: "EST",
        adm0_a3_ru: "EST",
        adm0_a3_es: "EST",
        adm0_a3_cn: "EST",
        adm0_a3_tw: "EST",
        adm0_a3_in: "EST",
        adm0_a3_np: "EST",
        adm0_a3_pk: "EST",
        adm0_a3_de: "EST",
        adm0_a3_gb: "EST",
        adm0_a3_br: "EST",
        adm0_a3_il: "EST",
        adm0_a3_ps: "EST",
        adm0_a3_sa: "EST",
        adm0_a3_eg: "EST",
        adm0_a3_ma: "EST",
        adm0_a3_pt: "EST",
        adm0_a3_ar: "EST",
        adm0_a3_jp: "EST",
        adm0_a3_ko: "EST",
        adm0_a3_vn: "EST",
        adm0_a3_tr: "EST",
        adm0_a3_id: "EST",
        adm0_a3_pl: "EST",
        adm0_a3_gr: "EST",
        adm0_a3_it: "EST",
        adm0_a3_nl: "EST",
        adm0_a3_se: "EST",
        adm0_a3_bd: "EST",
        adm0_a3_ua: "EST",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 25.867126,
        label_y: 58.724865,
        ne_id: 1159320615,
        wikidataid: "Q191",
        name_ar: "إستونيا",
        name_bn: "এস্তোনিয়া",
        name_de: "Estland",
        name_en: "Estonia",
        name_es: "Estonia",
        name_fa: "استونی",
        name_fr: "Estonie",
        name_el: "Εσθονία",
        name_he: "אסטוניה",
        name_hi: "एस्टोनिया",
        name_hu: "Észtország",
        name_id: "Estonia",
        name_it: "Estonia",
        name_ja: "エストニア",
        name_ko: "에스토니아",
        name_nl: "Estland",
        name_pl: "Estonia",
        name_pt: "Estónia",
        name_ru: "Эстония",
        name_sv: "Estland",
        name_tr: "Estonya",
        name_uk: "Естонія",
        name_ur: "استونیا",
        name_vi: "Estonia",
        name_zh: "爱沙尼亚",
        name_zht: "愛沙尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "EST.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.981126857000987, 59.47537333432527],
            [27.98112, 59.47537000000011],
            [28.13169925305175, 59.300825100330925],
            [27.42015000000015, 58.72457000000014],
            [27.71668582531572, 57.79189911562436],
            [27.288184848751513, 57.47452830670383],
            [26.463532342237787, 57.47638865826633],
            [25.60280968598437, 57.84752879498657],
            [25.16459354014927, 57.97015696881519],
            [24.312862583114622, 57.79342357037697],
            [24.42892785004216, 58.38341339785329],
            [24.061198357853186, 58.25737457949341],
            [23.426560092876684, 58.612753404364625],
            [23.339795363058645, 59.187240302153384],
            [24.604214308376186, 59.46585378685502],
            [25.86418908051664, 59.61109039981133],
            [26.949135776484525, 59.445803331125774],
            [27.981114129353244, 59.475388088612874],
            [27.981126857000987, 59.47537333432527],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Sweden",
        sov_a3: "SWE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sweden",
        adm0_a3: "SWE",
        geou_dif: 0,
        geounit: "Sweden",
        gu_a3: "SWE",
        su_dif: 0,
        subunit: "Sweden",
        su_a3: "SWE",
        brk_diff: 0,
        name: "Sweden",
        name_long: "Sweden",
        brk_a3: "SWE",
        brk_name: "Sweden",
        brk_group: null,
        abbrev: "Swe.",
        postal: "S",
        formal_en: "Kingdom of Sweden",
        formal_fr: null,
        name_ciawf: "Sweden",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sweden",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 2,
        mapcolor13: 4,
        pop_est: 10285453,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 530883,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SW",
        iso_a2: "SE",
        iso_a2_eh: "SE",
        iso_a3: "SWE",
        iso_a3_eh: "SWE",
        iso_n3: "752",
        iso_n3_eh: "752",
        un_a3: "752",
        wb_a2: "SE",
        wb_a3: "SWE",
        woe_id: 23424954,
        woe_id_eh: 23424954,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SWE",
        adm0_diff: null,
        adm0_tlc: "SWE",
        adm0_a3_us: "SWE",
        adm0_a3_fr: "SWE",
        adm0_a3_ru: "SWE",
        adm0_a3_es: "SWE",
        adm0_a3_cn: "SWE",
        adm0_a3_tw: "SWE",
        adm0_a3_in: "SWE",
        adm0_a3_np: "SWE",
        adm0_a3_pk: "SWE",
        adm0_a3_de: "SWE",
        adm0_a3_gb: "SWE",
        adm0_a3_br: "SWE",
        adm0_a3_il: "SWE",
        adm0_a3_ps: "SWE",
        adm0_a3_sa: "SWE",
        adm0_a3_eg: "SWE",
        adm0_a3_ma: "SWE",
        adm0_a3_pt: "SWE",
        adm0_a3_ar: "SWE",
        adm0_a3_jp: "SWE",
        adm0_a3_ko: "SWE",
        adm0_a3_vn: "SWE",
        adm0_a3_tr: "SWE",
        adm0_a3_id: "SWE",
        adm0_a3_pl: "SWE",
        adm0_a3_gr: "SWE",
        adm0_a3_it: "SWE",
        adm0_a3_nl: "SWE",
        adm0_a3_se: "SWE",
        adm0_a3_bd: "SWE",
        adm0_a3_ua: "SWE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 19.01705,
        label_y: 65.85918,
        ne_id: 1159321287,
        wikidataid: "Q34",
        name_ar: "السويد",
        name_bn: "সুইডেন",
        name_de: "Schweden",
        name_en: "Sweden",
        name_es: "Suecia",
        name_fa: "سوئد",
        name_fr: "Suède",
        name_el: "Σουηδία",
        name_he: "שוודיה",
        name_hi: "स्वीडन",
        name_hu: "Svédország",
        name_id: "Swedia",
        name_it: "Svezia",
        name_ja: "スウェーデン",
        name_ko: "스웨덴",
        name_nl: "Zweden",
        name_pl: "Szwecja",
        name_pt: "Suécia",
        name_ru: "Швеция",
        name_sv: "Sverige",
        name_tr: "İsveç",
        name_uk: "Швеція",
        name_ur: "سویڈن",
        name_vi: "Thụy Điển",
        name_zh: "瑞典",
        name_zht: "瑞典",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SWE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.027368605196868, 58.85614940045936],
            [11.468271925511146, 59.43239329694604],
            [12.3003658382749, 60.11793284773003],
            [12.631146681375185, 61.293571682370136],
            [11.992064243221563, 61.80036245385655],
            [11.930569288794231, 63.12831757267698],
            [12.579935336973934, 64.06621898055833],
            [13.571916131248713, 64.04911408146971],
            [13.919905226302204, 64.44542064071608],
            [13.55568973150909, 64.78702769638151],
            [15.108411492583002, 66.19386688909547],
            [16.108712192456778, 67.30245555283689],
            [16.768878614985482, 68.0139366726314],
            [17.729181756265348, 68.01055186631628],
            [17.993868442464333, 68.56739126247736],
            [19.878559604581255, 68.40719432237258],
            [20.025268995857886, 69.0651386583127],
            [20.645592889089528, 69.10624726020087],
            [21.978534783626117, 68.6168456081807],
            [23.53947309743444, 67.93600861273525],
            [23.565879754335583, 66.39605093043743],
            [23.903378533633802, 66.00692739527962],
            [22.18317345550193, 65.72374054632017],
            [21.21351687997722, 65.02600535751527],
            [21.369631381930958, 64.41358795842429],
            [19.77887576669022, 63.60955434839504],
            [17.84777916837521, 62.74940013289681],
            [17.119554884518124, 61.34116567651097],
            [17.83134606290639, 60.63658336042741],
            [18.78772179533209, 60.081914374422595],
            [17.86922488777634, 58.9537661810587],
            [16.829185011470088, 58.71982697207339],
            [16.447709588291474, 57.041118069071885],
            [15.879785597403783, 56.10430186626866],
            [14.666681349352075, 56.200885118222175],
            [14.100721062891465, 55.40778107362265],
            [12.942910597392057, 55.36173737245058],
            [12.625100538797028, 56.30708018658197],
            [11.787942335668674, 57.44181712506307],
            [11.027368605196868, 58.85614940045936],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Luxembourg",
        sov_a3: "LUX",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Luxembourg",
        adm0_a3: "LUX",
        geou_dif: 0,
        geounit: "Luxembourg",
        gu_a3: "LUX",
        su_dif: 0,
        subunit: "Luxembourg",
        su_a3: "LUX",
        brk_diff: 0,
        name: "Luxembourg",
        name_long: "Luxembourg",
        brk_a3: "LUX",
        brk_name: "Luxembourg",
        brk_group: null,
        abbrev: "Lux.",
        postal: "L",
        formal_en: "Grand Duchy of Luxembourg",
        formal_fr: null,
        name_ciawf: "Luxembourg",
        note_adm0: null,
        note_brk: null,
        name_sort: "Luxembourg",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 7,
        mapcolor9: 3,
        mapcolor13: 7,
        pop_est: 619896,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 71104,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "LU",
        iso_a2: "LU",
        iso_a2_eh: "LU",
        iso_a3: "LUX",
        iso_a3_eh: "LUX",
        iso_n3: "442",
        iso_n3_eh: "442",
        un_a3: "442",
        wb_a2: "LU",
        wb_a3: "LUX",
        woe_id: 23424881,
        woe_id_eh: 23424881,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LUX",
        adm0_diff: null,
        adm0_tlc: "LUX",
        adm0_a3_us: "LUX",
        adm0_a3_fr: "LUX",
        adm0_a3_ru: "LUX",
        adm0_a3_es: "LUX",
        adm0_a3_cn: "LUX",
        adm0_a3_tw: "LUX",
        adm0_a3_in: "LUX",
        adm0_a3_np: "LUX",
        adm0_a3_pk: "LUX",
        adm0_a3_de: "LUX",
        adm0_a3_gb: "LUX",
        adm0_a3_br: "LUX",
        adm0_a3_il: "LUX",
        adm0_a3_ps: "LUX",
        adm0_a3_sa: "LUX",
        adm0_a3_eg: "LUX",
        adm0_a3_ma: "LUX",
        adm0_a3_pt: "LUX",
        adm0_a3_ar: "LUX",
        adm0_a3_jp: "LUX",
        adm0_a3_ko: "LUX",
        adm0_a3_vn: "LUX",
        adm0_a3_tr: "LUX",
        adm0_a3_id: "LUX",
        adm0_a3_pl: "LUX",
        adm0_a3_gr: "LUX",
        adm0_a3_it: "LUX",
        adm0_a3_nl: "LUX",
        adm0_a3_se: "LUX",
        adm0_a3_bd: "LUX",
        adm0_a3_ua: "LUX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 5.7,
        max_label: 10,
        label_x: 6.07762,
        label_y: 49.733732,
        ne_id: 1159321031,
        wikidataid: "Q32",
        name_ar: "لوكسمبورغ",
        name_bn: "লুক্সেমবুর্গ",
        name_de: "Luxemburg",
        name_en: "Luxembourg",
        name_es: "Luxemburgo",
        name_fa: "لوکزامبورگ",
        name_fr: "Luxembourg",
        name_el: "Λουξεμβούργο",
        name_he: "לוקסמבורג",
        name_hi: "लक्ज़मबर्ग",
        name_hu: "Luxemburg",
        name_id: "Luksemburg",
        name_it: "Lussemburgo",
        name_ja: "ルクセンブルク",
        name_ko: "룩셈부르크",
        name_nl: "Luxemburg",
        name_pl: "Luksemburg",
        name_pt: "Luxemburgo",
        name_ru: "Люксембург",
        name_sv: "Luxemburg",
        name_tr: "Lüksemburg",
        name_uk: "Люксембург",
        name_ur: "لکسمبرگ",
        name_vi: "Luxembourg",
        name_zh: "卢森堡",
        name_zht: "盧森堡",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LUX.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.043073357781111, 50.128051662794235],
            [6.242751092156993, 49.90222565367873],
            [6.186320428094177, 49.463802802114515],
            [5.897759230176348, 49.44266714130711],
            [5.674051954784829, 49.529483547557504],
            [5.782417433300907, 50.09032786722122],
            [6.043073357781111, 50.128051662794235],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Finland",
        sov_a3: "FI1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Finland",
        adm0_a3: "FIN",
        geou_dif: 0,
        geounit: "Finland",
        gu_a3: "FIN",
        su_dif: 0,
        subunit: "Finland",
        su_a3: "FIN",
        brk_diff: 0,
        name: "Finland",
        name_long: "Finland",
        brk_a3: "FIN",
        brk_name: "Finland",
        brk_group: null,
        abbrev: "Fin.",
        postal: "FIN",
        formal_en: "Republic of Finland",
        formal_fr: null,
        name_ciawf: "Finland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Finland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 6,
        pop_est: 5520314,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 269296,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "FI",
        iso_a2: "FI",
        iso_a2_eh: "FI",
        iso_a3: "FIN",
        iso_a3_eh: "FIN",
        iso_n3: "246",
        iso_n3_eh: "246",
        un_a3: "246",
        wb_a2: "FI",
        wb_a3: "FIN",
        woe_id: 23424812,
        woe_id_eh: 23424812,
        woe_note: "Exact WOE match as country",
        adm0_iso: "FIN",
        adm0_diff: null,
        adm0_tlc: "FIN",
        adm0_a3_us: "FIN",
        adm0_a3_fr: "FIN",
        adm0_a3_ru: "FIN",
        adm0_a3_es: "FIN",
        adm0_a3_cn: "FIN",
        adm0_a3_tw: "FIN",
        adm0_a3_in: "FIN",
        adm0_a3_np: "FIN",
        adm0_a3_pk: "FIN",
        adm0_a3_de: "FIN",
        adm0_a3_gb: "FIN",
        adm0_a3_br: "FIN",
        adm0_a3_il: "FIN",
        adm0_a3_ps: "FIN",
        adm0_a3_sa: "FIN",
        adm0_a3_eg: "FIN",
        adm0_a3_ma: "FIN",
        adm0_a3_pt: "FIN",
        adm0_a3_ar: "FIN",
        adm0_a3_jp: "FIN",
        adm0_a3_ko: "FIN",
        adm0_a3_vn: "FIN",
        adm0_a3_tr: "FIN",
        adm0_a3_id: "FIN",
        adm0_a3_pl: "FIN",
        adm0_a3_gr: "FIN",
        adm0_a3_it: "FIN",
        adm0_a3_nl: "FIN",
        adm0_a3_se: "FIN",
        adm0_a3_bd: "FIN",
        adm0_a3_ua: "FIN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 27.276449,
        label_y: 63.252361,
        ne_id: 1159320623,
        wikidataid: "Q33",
        name_ar: "فنلندا",
        name_bn: "ফিনল্যান্ড",
        name_de: "Finnland",
        name_en: "Finland",
        name_es: "Finlandia",
        name_fa: "فنلاند",
        name_fr: "Finlande",
        name_el: "Φινλανδία",
        name_he: "פינלנד",
        name_hi: "फ़िनलैण्ड",
        name_hu: "Finnország",
        name_id: "Finlandia",
        name_it: "Finlandia",
        name_ja: "フィンランド",
        name_ko: "핀란드",
        name_nl: "Finland",
        name_pl: "Finlandia",
        name_pt: "Finlândia",
        name_ru: "Финляндия",
        name_sv: "Finland",
        name_tr: "Finlandiya",
        name_uk: "Фінляндія",
        name_ur: "فن لینڈ",
        name_vi: "Phần Lan",
        name_zh: "芬兰",
        name_zht: "芬蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FIN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.591929559043194, 69.06477692328666],
            [28.445943637818658, 68.36461294216404],
            [29.97742638522061, 67.69829702419275],
            [29.054588657352326, 66.94428620062206],
            [30.21765, 65.80598],
            [29.54442955904699, 64.94867157659048],
            [30.44468468600371, 64.20445343693909],
            [30.035872430142717, 63.55281362573855],
            [31.516092156711125, 62.86768748641289],
            [31.139991082490894, 62.35769277612441],
            [30.21110721204445, 61.780027777749694],
            [28.07, 60.50352],
            [28.070001921525666, 60.50351912796823],
            [28.069997592895277, 60.50351654727584],
            [26.255172967236973, 60.4239606797625],
            [24.496623976344523, 60.05731639265166],
            [22.869694858499457, 59.846373196036225],
            [22.290763787533592, 60.39192129174154],
            [21.322244093519316, 60.720169989659524],
            [21.544866163832694, 61.70532949487179],
            [21.05921105315369, 62.60739329695874],
            [21.536029493910803, 63.18973501245587],
            [22.442744174903993, 63.81781037053129],
            [24.730511508897536, 64.90234365504084],
            [25.398067661243942, 65.11142650009374],
            [25.294043003040404, 65.53434642197045],
            [23.903378533633802, 66.00692739527962],
            [23.565879754335583, 66.39605093043743],
            [23.53947309743444, 67.93600861273525],
            [21.978534783626117, 68.6168456081807],
            [20.645592889089528, 69.10624726020087],
            [21.244936150810673, 69.37044302029308],
            [22.356237827247412, 68.84174144151491],
            [23.66204959483076, 68.89124746365054],
            [24.735679152126725, 68.64955678982146],
            [25.689212680776365, 69.09211375596904],
            [26.179622023226244, 69.82529897732614],
            [27.732292107867863, 70.16419302029625],
            [29.015572950971972, 69.76649119737799],
            [28.591929559043194, 69.06477692328666],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Belgium",
        sov_a3: "BEL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Belgium",
        adm0_a3: "BEL",
        geou_dif: 0,
        geounit: "Belgium",
        gu_a3: "BEL",
        su_dif: 0,
        subunit: "Belgium",
        su_a3: "BEL",
        brk_diff: 0,
        name: "Belgium",
        name_long: "Belgium",
        brk_a3: "BEL",
        brk_name: "Belgium",
        brk_group: null,
        abbrev: "Belg.",
        postal: "B",
        formal_en: "Kingdom of Belgium",
        formal_fr: null,
        name_ciawf: "Belgium",
        note_adm0: null,
        note_brk: null,
        name_sort: "Belgium",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 11484055,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 533097,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "BE",
        iso_a2: "BE",
        iso_a2_eh: "BE",
        iso_a3: "BEL",
        iso_a3_eh: "BEL",
        iso_n3: "056",
        iso_n3_eh: "056",
        un_a3: "056",
        wb_a2: "BE",
        wb_a3: "BEL",
        woe_id: 23424757,
        woe_id_eh: 23424757,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BEL",
        adm0_diff: null,
        adm0_tlc: "BEL",
        adm0_a3_us: "BEL",
        adm0_a3_fr: "BEL",
        adm0_a3_ru: "BEL",
        adm0_a3_es: "BEL",
        adm0_a3_cn: "BEL",
        adm0_a3_tw: "BEL",
        adm0_a3_in: "BEL",
        adm0_a3_np: "BEL",
        adm0_a3_pk: "BEL",
        adm0_a3_de: "BEL",
        adm0_a3_gb: "BEL",
        adm0_a3_br: "BEL",
        adm0_a3_il: "BEL",
        adm0_a3_ps: "BEL",
        adm0_a3_sa: "BEL",
        adm0_a3_eg: "BEL",
        adm0_a3_ma: "BEL",
        adm0_a3_pt: "BEL",
        adm0_a3_ar: "BEL",
        adm0_a3_jp: "BEL",
        adm0_a3_ko: "BEL",
        adm0_a3_vn: "BEL",
        adm0_a3_tr: "BEL",
        adm0_a3_id: "BEL",
        adm0_a3_pl: "BEL",
        adm0_a3_gr: "BEL",
        adm0_a3_it: "BEL",
        adm0_a3_nl: "BEL",
        adm0_a3_se: "BEL",
        adm0_a3_bd: "BEL",
        adm0_a3_ua: "BEL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 4.800448,
        label_y: 50.785392,
        ne_id: 1159320389,
        wikidataid: "Q31",
        name_ar: "بلجيكا",
        name_bn: "বেলজিয়াম",
        name_de: "Belgien",
        name_en: "Belgium",
        name_es: "Bélgica",
        name_fa: "بلژیک",
        name_fr: "Belgique",
        name_el: "Βέλγιο",
        name_he: "בלגיה",
        name_hi: "बेल्जियम",
        name_hu: "Belgium",
        name_id: "Belgia",
        name_it: "Belgio",
        name_ja: "ベルギー",
        name_ko: "벨기에",
        name_nl: "België",
        name_pl: "Belgia",
        name_pt: "Bélgica",
        name_ru: "Бельгия",
        name_sv: "Belgien",
        name_tr: "Belçika",
        name_uk: "Бельгія",
        name_ur: "بلجئیم",
        name_vi: "Bỉ",
        name_zh: "比利时",
        name_zht: "比利時",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BEL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.15665815595878, 50.80372101501058],
            [6.043073357781111, 50.128051662794235],
            [5.782417433300907, 50.09032786722122],
            [5.674051954784829, 49.529483547557504],
            [4.799221632515724, 49.98537303323637],
            [4.286022983425084, 49.907496649772554],
            [3.588184441755658, 50.37899241800356],
            [3.123251580425688, 50.78036326761455],
            [2.658422071960274, 50.796848049515745],
            [2.513573032246143, 51.14850617126183],
            [3.314971144228537, 51.345780951536085],
            [3.31501148496416, 51.34577662473805],
            [3.314971144228537, 51.34575511331991],
            [4.047071160507528, 51.26725861266857],
            [4.973991326526914, 51.47502370869813],
            [5.606975945670001, 51.03729848896978],
            [6.15665815595878, 50.80372101501058],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "North Macedonia",
        sov_a3: "MKD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "North Macedonia",
        adm0_a3: "MKD",
        geou_dif: 0,
        geounit: "North Macedonia",
        gu_a3: "MKD",
        su_dif: 0,
        subunit: "North Macedonia",
        su_a3: "MKD",
        brk_diff: 0,
        name: "North Macedonia",
        name_long: "North Macedonia",
        brk_a3: "MKD",
        brk_name: "North Macedonia",
        brk_group: null,
        abbrev: "N. Mac.",
        postal: "NM",
        formal_en: "Republic of North Macedonia",
        formal_fr: null,
        name_ciawf: "North Macedonia",
        note_adm0: null,
        note_brk: null,
        name_sort: "North Macedonia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 2083459,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 12547,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "MK",
        iso_a2: "MK",
        iso_a2_eh: "MK",
        iso_a3: "MKD",
        iso_a3_eh: "MKD",
        iso_n3: "807",
        iso_n3_eh: "807",
        un_a3: "807",
        wb_a2: "MK",
        wb_a3: "MKD",
        woe_id: 23424890,
        woe_id_eh: 23424890,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MKD",
        adm0_diff: null,
        adm0_tlc: "MKD",
        adm0_a3_us: "MKD",
        adm0_a3_fr: "MKD",
        adm0_a3_ru: "MKD",
        adm0_a3_es: "MKD",
        adm0_a3_cn: "MKD",
        adm0_a3_tw: "MKD",
        adm0_a3_in: "MKD",
        adm0_a3_np: "MKD",
        adm0_a3_pk: "MKD",
        adm0_a3_de: "MKD",
        adm0_a3_gb: "MKD",
        adm0_a3_br: "MKD",
        adm0_a3_il: "MKD",
        adm0_a3_ps: "MKD",
        adm0_a3_sa: "MKD",
        adm0_a3_eg: "MKD",
        adm0_a3_ma: "MKD",
        adm0_a3_pt: "MKD",
        adm0_a3_ar: "MKD",
        adm0_a3_jp: "MKD",
        adm0_a3_ko: "MKD",
        adm0_a3_vn: "MKD",
        adm0_a3_tr: "MKD",
        adm0_a3_id: "MKD",
        adm0_a3_pl: "MKD",
        adm0_a3_gr: "MKD",
        adm0_a3_it: "MKD",
        adm0_a3_nl: "MKD",
        adm0_a3_se: "MKD",
        adm0_a3_bd: "MKD",
        adm0_a3_ua: "MKD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 15,
        long_len: 15,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 21.555839,
        label_y: 41.558223,
        ne_id: 1159321061,
        wikidataid: "Q221",
        name_ar: "مقدونيا الشمالية",
        name_bn: "উত্তর মেসিডোনিয়া",
        name_de: "Nordmazedonien",
        name_en: "North Macedonia",
        name_es: "Macedonia del Norte",
        name_fa: "مقدونیه شمالی",
        name_fr: "Macédoine du Nord",
        name_el: "Βόρεια Μακεδονία",
        name_he: "מקדוניה הצפונית",
        name_hi: "उत्तर मैसिडोनिया",
        name_hu: "Észak-Macedónia",
        name_id: "Republik Makedonia Utara",
        name_it: "Macedonia del Nord",
        name_ja: "北マケドニア",
        name_ko: "북마케도니아",
        name_nl: "Noord-Macedonië",
        name_pl: "Macedonia Północna",
        name_pt: "Macedónia do Norte",
        name_ru: "Северная Македония",
        name_sv: "Nordmakedonien",
        name_tr: "Kuzey Makedonya",
        name_uk: "Північна Македонія",
        name_ur: "شمالی مقدونیہ",
        name_vi: "Bắc Macedonia",
        name_zh: "北马其顿",
        name_zht: "北馬其頓",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MKD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.380525750424592, 42.32025950781509],
            [22.881373732197346, 41.999297186850356],
            [22.952377150166452, 41.33799388281115],
            [22.76177, 41.3048],
            [22.597308383889015, 41.130487168943205],
            [22.05537763844427, 41.14986583105269],
            [21.674160597426976, 40.931274522457954],
            [21.0200403174764, 40.84272695572588],
            [20.605181919037364, 41.086226304685226],
            [20.463175083099202, 41.51508901627534],
            [20.59024743010491, 41.855404161133606],
            [20.590246546680227, 41.855408919283626],
            [20.71731000000011, 41.84711],
            [20.76216, 42.05186],
            [21.35270000000014, 42.2068],
            [21.57663598940212, 42.24522439706186],
            [21.917080000000112, 42.30364],
            [22.380525750424592, 42.32025950781509],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Albania",
        sov_a3: "ALB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Albania",
        adm0_a3: "ALB",
        geou_dif: 0,
        geounit: "Albania",
        gu_a3: "ALB",
        su_dif: 0,
        subunit: "Albania",
        su_a3: "ALB",
        brk_diff: 0,
        name: "Albania",
        name_long: "Albania",
        brk_a3: "ALB",
        brk_name: "Albania",
        brk_group: null,
        abbrev: "Alb.",
        postal: "AL",
        formal_en: "Republic of Albania",
        formal_fr: null,
        name_ciawf: "Albania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Albania",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 2854191,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 15279,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "AL",
        iso_a2: "AL",
        iso_a2_eh: "AL",
        iso_a3: "ALB",
        iso_a3_eh: "ALB",
        iso_n3: "008",
        iso_n3_eh: "008",
        un_a3: "008",
        wb_a2: "AL",
        wb_a3: "ALB",
        woe_id: 23424742,
        woe_id_eh: 23424742,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ALB",
        adm0_diff: null,
        adm0_tlc: "ALB",
        adm0_a3_us: "ALB",
        adm0_a3_fr: "ALB",
        adm0_a3_ru: "ALB",
        adm0_a3_es: "ALB",
        adm0_a3_cn: "ALB",
        adm0_a3_tw: "ALB",
        adm0_a3_in: "ALB",
        adm0_a3_np: "ALB",
        adm0_a3_pk: "ALB",
        adm0_a3_de: "ALB",
        adm0_a3_gb: "ALB",
        adm0_a3_br: "ALB",
        adm0_a3_il: "ALB",
        adm0_a3_ps: "ALB",
        adm0_a3_sa: "ALB",
        adm0_a3_eg: "ALB",
        adm0_a3_ma: "ALB",
        adm0_a3_pt: "ALB",
        adm0_a3_ar: "ALB",
        adm0_a3_jp: "ALB",
        adm0_a3_ko: "ALB",
        adm0_a3_vn: "ALB",
        adm0_a3_tr: "ALB",
        adm0_a3_id: "ALB",
        adm0_a3_pl: "ALB",
        adm0_a3_gr: "ALB",
        adm0_a3_it: "ALB",
        adm0_a3_nl: "ALB",
        adm0_a3_se: "ALB",
        adm0_a3_bd: "ALB",
        adm0_a3_ua: "ALB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 20.11384,
        label_y: 40.654855,
        ne_id: 1159320325,
        wikidataid: "Q222",
        name_ar: "ألبانيا",
        name_bn: "আলবেনিয়া",
        name_de: "Albanien",
        name_en: "Albania",
        name_es: "Albania",
        name_fa: "آلبانی",
        name_fr: "Albanie",
        name_el: "Αλβανία",
        name_he: "אלבניה",
        name_hi: "अल्बानिया",
        name_hu: "Albánia",
        name_id: "Albania",
        name_it: "Albania",
        name_ja: "アルバニア",
        name_ko: "알바니아",
        name_nl: "Albanië",
        name_pl: "Albania",
        name_pt: "Albânia",
        name_ru: "Албания",
        name_sv: "Albanien",
        name_tr: "Arnavutluk",
        name_uk: "Албанія",
        name_ur: "البانیا",
        name_vi: "Albania",
        name_zh: "阿尔巴尼亚",
        name_zht: "阿爾巴尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ALB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.0200403174764, 40.84272695572588],
            [20.999989861747224, 40.58000397395398],
            [20.674996779063633, 40.43499990494303],
            [20.615000441172754, 40.11000682225938],
            [20.15001590341052, 39.62499766698397],
            [19.980000441170148, 39.69499339452341],
            [19.960001661873207, 39.91500580500605],
            [19.406081984136733, 40.250773423822466],
            [19.319058872157143, 40.72723012955356],
            [19.40354983895429, 41.40956574153546],
            [19.540027296637106, 41.71998607031276],
            [19.37176883309496, 41.877547512370654],
            [19.37176816334725, 41.877550679783496],
            [19.304486118250793, 42.19574514420782],
            [19.73805138517963, 42.68824738216557],
            [19.801613396898688, 42.50009349219084],
            [20.0707, 42.58863],
            [20.283754510181893, 42.32025950781508],
            [20.52295, 42.21787],
            [20.590246546680227, 41.855408919283626],
            [20.59024743010491, 41.855404161133606],
            [20.463175083099202, 41.51508901627534],
            [20.605181919037364, 41.086226304685226],
            [21.0200403174764, 40.84272695572588],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Kosovo",
        sov_a3: "KOS",
        adm0_dif: 0,
        level: 2,
        type: "Disputed",
        tlc: "1",
        admin: "Kosovo",
        adm0_a3: "KOS",
        geou_dif: 0,
        geounit: "Kosovo",
        gu_a3: "KOS",
        su_dif: 0,
        subunit: "Kosovo",
        su_a3: "KOS",
        brk_diff: 0,
        name: "Kosovo",
        name_long: "Kosovo",
        brk_a3: "KOS",
        brk_name: "Kosovo",
        brk_group: null,
        abbrev: "Kos.",
        postal: "KO",
        formal_en: "Republic of Kosovo",
        formal_fr: null,
        name_ciawf: "Kosovo",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kosovo",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 1794248,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 7926,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "KV",
        iso_a2: "-99",
        iso_a2_eh: "XK",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "KV",
        wb_a3: "KSV",
        woe_id: -90,
        woe_id_eh: 29389201,
        woe_note:
          "Subunit of Serbia in WOE still; should include 29389201, 29389207, 29389218, 29389209 and 29389214.",
        adm0_iso: "SRB",
        adm0_diff: "1",
        adm0_tlc: "KOS",
        adm0_a3_us: "KOS",
        adm0_a3_fr: "KOS",
        adm0_a3_ru: "SRB",
        adm0_a3_es: "SRB",
        adm0_a3_cn: "SRB",
        adm0_a3_tw: "KOS",
        adm0_a3_in: "SRB",
        adm0_a3_np: "SRB",
        adm0_a3_pk: "KOS",
        adm0_a3_de: "KOS",
        adm0_a3_gb: "SRB",
        adm0_a3_br: "KOS",
        adm0_a3_il: "KOS",
        adm0_a3_ps: "SRB",
        adm0_a3_sa: "KOS",
        adm0_a3_eg: "KOS",
        adm0_a3_ma: "SRB",
        adm0_a3_pt: "KOS",
        adm0_a3_ar: "SRB",
        adm0_a3_jp: "KOS",
        adm0_a3_ko: "KOS",
        adm0_a3_vn: "SRB",
        adm0_a3_tr: "KOS",
        adm0_a3_id: "SRB",
        adm0_a3_pl: "KOS",
        adm0_a3_gr: "SRB",
        adm0_a3_it: "KOS",
        adm0_a3_nl: "KOS",
        adm0_a3_se: "KOS",
        adm0_a3_bd: "KOS",
        adm0_a3_ua: "SRB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 20.860719,
        label_y: 42.593587,
        ne_id: 1159321007,
        wikidataid: "Q1246",
        name_ar: "كوسوفو",
        name_bn: "কসোভো",
        name_de: "Kosovo",
        name_en: "Kosovo",
        name_es: "Kosovo",
        name_fa: "کوزووو",
        name_fr: "Kosovo",
        name_el: "Κοσσυφοπέδιο",
        name_he: "קוסובו",
        name_hi: "कोसोवो गणराज्य",
        name_hu: "Koszovó",
        name_id: "Kosovo",
        name_it: "Kosovo",
        name_ja: "コソボ共和国",
        name_ko: "코소보",
        name_nl: "Kosovo",
        name_pl: "Kosowo",
        name_pt: "Kosovo",
        name_ru: "Республика Косово",
        name_sv: "Kosovo",
        name_tr: "Kosova",
        name_uk: "Косово",
        name_ur: "کوسووہ",
        name_vi: "Kosovo",
        name_zh: "科索沃",
        name_zht: "科索沃",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: "Admin-0 country",
        fclass_fr: "Admin-0 country",
        fclass_ru: "Admin-1 region",
        fclass_es: "Unrecognized",
        fclass_cn: "Unrecognized",
        fclass_tw: "Admin-0 country",
        fclass_in: "Admin-1 region",
        fclass_np: "Unrecognized",
        fclass_pk: "Admin-0 country",
        fclass_de: "Admin-0 country",
        fclass_gb: "Admin-0 country",
        fclass_br: "Unrecognized",
        fclass_il: "Admin-0 country",
        fclass_ps: "Unrecognized",
        fclass_sa: "Admin-0 country",
        fclass_eg: "Admin-0 country",
        fclass_ma: "Unrecognized",
        fclass_pt: "Admin-0 country",
        fclass_ar: "Unrecognized",
        fclass_jp: "Admin-0 country",
        fclass_ko: "Admin-0 country",
        fclass_vn: "Unrecognized",
        fclass_tr: "Admin-0 country",
        fclass_id: "Unrecognized",
        fclass_pl: "Admin-0 country",
        fclass_gr: "Unrecognized",
        fclass_it: "Admin-0 country",
        fclass_nl: "Admin-0 country",
        fclass_se: "Admin-0 country",
        fclass_bd: "Admin-0 country",
        fclass_ua: "Unrecognized",
        filename: "kosovo.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.590246546680227, 41.855408919283626],
            [20.52295, 42.21787],
            [20.283754510181893, 42.32025950781508],
            [20.0707, 42.58863],
            [20.25758, 42.81275000000011],
            [20.49679, 42.88469],
            [20.63508, 43.21671],
            [20.81448, 43.27205],
            [20.95651, 43.13094],
            [21.143395, 43.06868500000013],
            [21.27421, 42.90959],
            [21.43866, 42.86255],
            [21.63302, 42.67717],
            [21.77505, 42.6827],
            [21.66292, 42.43922],
            [21.54332, 42.3202500000001],
            [21.57663598940212, 42.24522439706186],
            [21.35270000000014, 42.2068],
            [20.76216, 42.05186],
            [20.71731000000011, 41.84711],
            [20.590246546680227, 41.855408919283626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Spain",
        sov_a3: "ESP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Spain",
        adm0_a3: "ESP",
        geou_dif: 0,
        geounit: "Spain",
        gu_a3: "ESP",
        su_dif: 0,
        subunit: "Spain",
        su_a3: "ESP",
        brk_diff: 0,
        name: "Spain",
        name_long: "Spain",
        brk_a3: "ESP",
        brk_name: "Spain",
        brk_group: null,
        abbrev: "Sp.",
        postal: "E",
        formal_en: "Kingdom of Spain",
        formal_fr: null,
        name_ciawf: "Spain",
        note_adm0: null,
        note_brk: null,
        name_sort: "Spain",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 47076781,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 1393490,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SP",
        iso_a2: "ES",
        iso_a2_eh: "ES",
        iso_a3: "ESP",
        iso_a3_eh: "ESP",
        iso_n3: "724",
        iso_n3_eh: "724",
        un_a3: "724",
        wb_a2: "ES",
        wb_a3: "ESP",
        woe_id: 23424950,
        woe_id_eh: 23424950,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ESP",
        adm0_diff: null,
        adm0_tlc: "ESP",
        adm0_a3_us: "ESP",
        adm0_a3_fr: "ESP",
        adm0_a3_ru: "ESP",
        adm0_a3_es: "ESP",
        adm0_a3_cn: "ESP",
        adm0_a3_tw: "ESP",
        adm0_a3_in: "ESP",
        adm0_a3_np: "ESP",
        adm0_a3_pk: "ESP",
        adm0_a3_de: "ESP",
        adm0_a3_gb: "ESP",
        adm0_a3_br: "ESP",
        adm0_a3_il: "ESP",
        adm0_a3_ps: "ESP",
        adm0_a3_sa: "ESP",
        adm0_a3_eg: "ESP",
        adm0_a3_ma: "ESP",
        adm0_a3_pt: "ESP",
        adm0_a3_ar: "ESP",
        adm0_a3_jp: "ESP",
        adm0_a3_ko: "ESP",
        adm0_a3_vn: "ESP",
        adm0_a3_tr: "ESP",
        adm0_a3_id: "ESP",
        adm0_a3_pl: "ESP",
        adm0_a3_gr: "ESP",
        adm0_a3_it: "ESP",
        adm0_a3_nl: "ESP",
        adm0_a3_se: "ESP",
        adm0_a3_bd: "ESP",
        adm0_a3_ua: "ESP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -3.464718,
        label_y: 40.090953,
        ne_id: 1159320587,
        wikidataid: "Q29",
        name_ar: "إسبانيا",
        name_bn: "স্পেন",
        name_de: "Spanien",
        name_en: "Spain",
        name_es: "España",
        name_fa: "اسپانیا",
        name_fr: "Espagne",
        name_el: "Ισπανία",
        name_he: "ספרד",
        name_hi: "स्पेन",
        name_hu: "Spanyolország",
        name_id: "Spanyol",
        name_it: "Spagna",
        name_ja: "スペイン",
        name_ko: "스페인",
        name_nl: "Spanje",
        name_pl: "Hiszpania",
        name_pt: "Espanha",
        name_ru: "Испания",
        name_sv: "Spanien",
        name_tr: "İspanya",
        name_uk: "Іспанія",
        name_ur: "ہسپانیہ",
        name_vi: "Tây Ban Nha",
        name_zh: "西班牙",
        name_zht: "西班牙",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ESP.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.453725551778092, 37.09778758396607],
            [-7.537105475281024, 37.42890432387624],
            [-7.166507941099865, 37.803894354802225],
            [-7.029281175148796, 38.07576406508977],
            [-7.374092169616318, 38.37305858006492],
            [-7.098036668313128, 39.03007274022379],
            [-7.498632371439726, 39.62957103124181],
            [-7.066591559263529, 39.711891587882775],
            [-7.026413133156595, 40.184524237624245],
            [-6.864019944679385, 40.33087189387483],
            [-6.851126674822552, 41.11108266861753],
            [-6.389087693700915, 41.381815497394655],
            [-6.668605515967656, 41.883386949219584],
            [-7.251308966490824, 41.91834605566505],
            [-7.422512986673795, 41.79207469335984],
            [-8.013174607769912, 41.790886135417125],
            [-8.263856980817792, 42.28046865495034],
            [-8.67194576662672, 42.13468943945496],
            [-9.034817674180246, 41.880570583659676],
            [-8.984433152695672, 42.59277517350627],
            [-9.392883673530648, 43.0266246608127],
            [-7.97818966310831, 43.74833771420099],
            [-6.754491746436756, 43.567909450853925],
            [-5.411886359061597, 43.57423981380968],
            [-4.347842779955783, 43.40344920508504],
            [-3.517531704106091, 43.4559007838613],
            [-1.901351284177764, 43.42280202897834],
            [-1.502770961910528, 43.03401439063043],
            [0.338046909190581, 42.57954600683955],
            [0.701590610363894, 42.795734361332606],
            [1.826793247087153, 42.34338471126569],
            [2.985998976258458, 42.47301504166986],
            [3.039484083680549, 41.892120266276905],
            [2.091841668312185, 41.226088568683096],
            [0.810524529635188, 41.01473196060934],
            [0.721331007499401, 40.678318386389236],
            [0.106691521819869, 40.12393362076202],
            [-0.278711310212941, 39.30997813573272],
            [0.111290724293838, 38.73851430923304],
            [-0.467123582349103, 38.29236583104115],
            [-0.683389451490598, 37.642353827457825],
            [-1.438382127274849, 37.44306366632422],
            [-2.146452602538119, 36.67414419203729],
            [-3.415780808923387, 36.65889964451118],
            [-4.368900926114719, 36.677839056946155],
            [-4.995219285492212, 36.32470815687964],
            [-5.377159796561457, 35.946850083961465],
            [-5.866432257500904, 36.02981659600606],
            [-6.236693894872175, 36.367677110330334],
            [-6.520190802425404, 36.94291331638732],
            [-7.453725551778092, 37.09778758396607],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Denmark",
        adm0_a3: "DNK",
        geou_dif: 0,
        geounit: "Denmark",
        gu_a3: "DNK",
        su_dif: 0,
        subunit: "Denmark",
        su_a3: "DNK",
        brk_diff: 0,
        name: "Denmark",
        name_long: "Denmark",
        brk_a3: "DNK",
        brk_name: "Denmark",
        brk_group: null,
        abbrev: "Den.",
        postal: "DK",
        formal_en: "Kingdom of Denmark",
        formal_fr: null,
        name_ciawf: "Denmark",
        note_adm0: null,
        note_brk: null,
        name_sort: "Denmark",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 5818553,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 350104,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "DA",
        iso_a2: "DK",
        iso_a2_eh: "DK",
        iso_a3: "DNK",
        iso_a3_eh: "DNK",
        iso_n3: "208",
        iso_n3_eh: "208",
        un_a3: "208",
        wb_a2: "DK",
        wb_a3: "DNK",
        woe_id: 23424796,
        woe_id_eh: 23424796,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DNK",
        adm0_diff: null,
        adm0_tlc: "DNK",
        adm0_a3_us: "DNK",
        adm0_a3_fr: "DNK",
        adm0_a3_ru: "DNK",
        adm0_a3_es: "DNK",
        adm0_a3_cn: "DNK",
        adm0_a3_tw: "DNK",
        adm0_a3_in: "DNK",
        adm0_a3_np: "DNK",
        adm0_a3_pk: "DNK",
        adm0_a3_de: "DNK",
        adm0_a3_gb: "DNK",
        adm0_a3_br: "DNK",
        adm0_a3_il: "DNK",
        adm0_a3_ps: "DNK",
        adm0_a3_sa: "DNK",
        adm0_a3_eg: "DNK",
        adm0_a3_ma: "DNK",
        adm0_a3_pt: "DNK",
        adm0_a3_ar: "DNK",
        adm0_a3_jp: "DNK",
        adm0_a3_ko: "DNK",
        adm0_a3_vn: "DNK",
        adm0_a3_tr: "DNK",
        adm0_a3_id: "DNK",
        adm0_a3_pl: "DNK",
        adm0_a3_gr: "DNK",
        adm0_a3_it: "DNK",
        adm0_a3_nl: "DNK",
        adm0_a3_se: "DNK",
        adm0_a3_bd: "DNK",
        adm0_a3_ua: "DNK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 9.018163,
        label_y: 55.966965,
        ne_id: 1159320547,
        wikidataid: "Q35",
        name_ar: "الدنمارك",
        name_bn: "ডেনমার্ক",
        name_de: "Dänemark",
        name_en: "Denmark",
        name_es: "Dinamarca",
        name_fa: "دانمارک",
        name_fr: "Danemark",
        name_el: "Δανία",
        name_he: "דנמרק",
        name_hi: "डेनमार्क",
        name_hu: "Dánia",
        name_id: "Denmark",
        name_it: "Danimarca",
        name_ja: "デンマーク",
        name_ko: "덴마크",
        name_nl: "Denemarken",
        name_pl: "Dania",
        name_pt: "Dinamarca",
        name_ru: "Дания",
        name_sv: "Danmark",
        name_tr: "Danimarka",
        name_uk: "Данія",
        name_ur: "ڈنمارک",
        name_vi: "Đan Mạch",
        name_zh: "丹麦",
        name_zht: "丹麥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DNK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.921906365609118, 54.98310415304803],
              [9.282048780971138, 54.830865383516176],
              [8.526229282270208, 54.96274363872516],
              [8.120310906617533, 55.5177226833236],
              [8.08997684086222, 56.54001170513759],
              [8.256581658571207, 56.80996938743033],
              [8.543437534223415, 57.11000275331695],
              [9.424469028367554, 57.1720661484995],
              [9.775558709358535, 57.44794078228966],
              [10.580005730846125, 57.73001658795485],
              [10.546105991262664, 57.21573273378612],
              [10.250000034230226, 56.89001618105044],
              [10.369992710011957, 56.60998159446079],
              [10.912181837618306, 56.458621324277885],
              [10.667803989310016, 56.08138336854721],
              [10.369992710011957, 56.19000722922473],
              [9.649984978889279, 55.469999498102055],
              [9.921906365609118, 54.98310415304803],
            ],
          ],
          [
            [
              [12.370904168353292, 56.111407375708794],
              [12.690006137755603, 55.60999095318074],
              [12.089991082414684, 54.80001455343792],
              [11.043543328504226, 55.36486379660424],
              [10.903913608451603, 55.77995473898872],
              [12.370904168353292, 56.111407375708794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Romania",
        sov_a3: "ROU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Romania",
        adm0_a3: "ROU",
        geou_dif: 0,
        geounit: "Romania",
        gu_a3: "ROU",
        su_dif: 0,
        subunit: "Romania",
        su_a3: "ROU",
        brk_diff: 0,
        name: "Romania",
        name_long: "Romania",
        brk_a3: "ROU",
        brk_name: "Romania",
        brk_group: null,
        abbrev: "Rom.",
        postal: "RO",
        formal_en: "Romania",
        formal_fr: null,
        name_ciawf: "Romania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Romania",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 19356544,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 250077,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "RO",
        iso_a2: "RO",
        iso_a2_eh: "RO",
        iso_a3: "ROU",
        iso_a3_eh: "ROU",
        iso_n3: "642",
        iso_n3_eh: "642",
        un_a3: "642",
        wb_a2: "RO",
        wb_a3: "ROM",
        woe_id: 23424933,
        woe_id_eh: 23424933,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ROU",
        adm0_diff: null,
        adm0_tlc: "ROU",
        adm0_a3_us: "ROU",
        adm0_a3_fr: "ROU",
        adm0_a3_ru: "ROU",
        adm0_a3_es: "ROU",
        adm0_a3_cn: "ROU",
        adm0_a3_tw: "ROU",
        adm0_a3_in: "ROU",
        adm0_a3_np: "ROU",
        adm0_a3_pk: "ROU",
        adm0_a3_de: "ROU",
        adm0_a3_gb: "ROU",
        adm0_a3_br: "ROU",
        adm0_a3_il: "ROU",
        adm0_a3_ps: "ROU",
        adm0_a3_sa: "ROU",
        adm0_a3_eg: "ROU",
        adm0_a3_ma: "ROU",
        adm0_a3_pt: "ROU",
        adm0_a3_ar: "ROU",
        adm0_a3_jp: "ROU",
        adm0_a3_ko: "ROU",
        adm0_a3_vn: "ROU",
        adm0_a3_tr: "ROU",
        adm0_a3_id: "ROU",
        adm0_a3_pl: "ROU",
        adm0_a3_gr: "ROU",
        adm0_a3_it: "ROU",
        adm0_a3_nl: "ROU",
        adm0_a3_se: "ROU",
        adm0_a3_bd: "ROU",
        adm0_a3_ua: "ROU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 24.972624,
        label_y: 45.733237,
        ne_id: 1159321199,
        wikidataid: "Q218",
        name_ar: "رومانيا",
        name_bn: "রোমানিয়া",
        name_de: "Rumänien",
        name_en: "Romania",
        name_es: "Rumania",
        name_fa: "رومانی",
        name_fr: "Roumanie",
        name_el: "Ρουμανία",
        name_he: "רומניה",
        name_hi: "रोमानिया",
        name_hu: "Románia",
        name_id: "Rumania",
        name_it: "Romania",
        name_ja: "ルーマニア",
        name_ko: "루마니아",
        name_nl: "Roemenië",
        name_pl: "Rumunia",
        name_pt: "Roménia",
        name_ru: "Румыния",
        name_sv: "Rumänien",
        name_tr: "Romanya",
        name_uk: "Румунія",
        name_ur: "رومانیہ",
        name_vi: "Romania",
        name_zh: "罗马尼亚",
        name_zht: "羅馬尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ROU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.23355350109904, 45.48828318946829],
            [28.67977949393938, 45.304030870131704],
            [29.149724969201653, 45.464925442072456],
            [29.603289015427436, 45.293308010431126],
            [29.62654340995877, 45.0353909368624],
            [29.141611769331835, 44.820210272799045],
            [28.8378577003202, 44.913873806328056],
            [28.558081495891997, 43.70746165625813],
            [27.970107049275075, 43.81246816667522],
            [27.242399529740908, 44.175986029632405],
            [26.065158725699746, 43.94349376075127],
            [25.569271681426926, 43.68844472917472],
            [24.100679152124172, 43.74105133724785],
            [23.332302280376325, 43.897010809904714],
            [22.944832391051847, 43.82378530534713],
            [22.65714969248299, 44.23492300066128],
            [22.4740084164406, 44.40922760678177],
            [22.705725538837356, 44.57800283464702],
            [22.459022251075936, 44.7025171982543],
            [22.14508792490281, 44.47842234962059],
            [21.56202273935361, 44.7689472519655],
            [21.48352623870221, 45.18117015235788],
            [20.874312778413355, 45.416375433934235],
            [20.762174920339987, 45.734573065771485],
            [20.220192498462836, 46.127468980486555],
            [21.02195234547125, 46.3160879583519],
            [21.626514926853872, 46.99423777931816],
            [22.099767693782837, 47.6724392767167],
            [22.710531447040495, 47.88219391538941],
            [23.142236362406805, 48.09634105080695],
            [23.76095828623741, 47.985598456405455],
            [24.40205610525038, 47.98187775328043],
            [24.866317172960578, 47.737525743188314],
            [25.20774336111299, 47.89105642352747],
            [25.9459411964024, 47.987148749374214],
            [26.19745039236693, 48.22088125263035],
            [26.619336785597795, 48.22072622333347],
            [26.924176059687568, 48.123264472030996],
            [27.233872918412743, 47.82677094175638],
            [27.551166212684848, 47.40511709247083],
            [28.128030226359044, 46.810476386088254],
            [28.160017937947714, 46.37156260841722],
            [28.0544429867754, 45.944586086605625],
            [28.23355350109904, 45.48828318946829],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Hungary",
        sov_a3: "HUN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Hungary",
        adm0_a3: "HUN",
        geou_dif: 0,
        geounit: "Hungary",
        gu_a3: "HUN",
        su_dif: 0,
        subunit: "Hungary",
        su_a3: "HUN",
        brk_diff: 0,
        name: "Hungary",
        name_long: "Hungary",
        brk_a3: "HUN",
        brk_name: "Hungary",
        brk_group: null,
        abbrev: "Hun.",
        postal: "HU",
        formal_en: "Republic of Hungary",
        formal_fr: null,
        name_ciawf: "Hungary",
        note_adm0: null,
        note_brk: null,
        name_sort: "Hungary",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 5,
        pop_est: 9769949,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 163469,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "HU",
        iso_a2: "HU",
        iso_a2_eh: "HU",
        iso_a3: "HUN",
        iso_a3_eh: "HUN",
        iso_n3: "348",
        iso_n3_eh: "348",
        un_a3: "348",
        wb_a2: "HU",
        wb_a3: "HUN",
        woe_id: 23424844,
        woe_id_eh: 23424844,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HUN",
        adm0_diff: null,
        adm0_tlc: "HUN",
        adm0_a3_us: "HUN",
        adm0_a3_fr: "HUN",
        adm0_a3_ru: "HUN",
        adm0_a3_es: "HUN",
        adm0_a3_cn: "HUN",
        adm0_a3_tw: "HUN",
        adm0_a3_in: "HUN",
        adm0_a3_np: "HUN",
        adm0_a3_pk: "HUN",
        adm0_a3_de: "HUN",
        adm0_a3_gb: "HUN",
        adm0_a3_br: "HUN",
        adm0_a3_il: "HUN",
        adm0_a3_ps: "HUN",
        adm0_a3_sa: "HUN",
        adm0_a3_eg: "HUN",
        adm0_a3_ma: "HUN",
        adm0_a3_pt: "HUN",
        adm0_a3_ar: "HUN",
        adm0_a3_jp: "HUN",
        adm0_a3_ko: "HUN",
        adm0_a3_vn: "HUN",
        adm0_a3_tr: "HUN",
        adm0_a3_id: "HUN",
        adm0_a3_pl: "HUN",
        adm0_a3_gr: "HUN",
        adm0_a3_it: "HUN",
        adm0_a3_nl: "HUN",
        adm0_a3_se: "HUN",
        adm0_a3_bd: "HUN",
        adm0_a3_ua: "HUN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 19.447867,
        label_y: 47.086841,
        ne_id: 1159320841,
        wikidataid: "Q28",
        name_ar: "المجر",
        name_bn: "হাঙ্গেরি",
        name_de: "Ungarn",
        name_en: "Hungary",
        name_es: "Hungría",
        name_fa: "مجارستان",
        name_fr: "Hongrie",
        name_el: "Ουγγαρία",
        name_he: "הונגריה",
        name_hi: "हंगरी",
        name_hu: "Magyarország",
        name_id: "Hongaria",
        name_it: "Ungheria",
        name_ja: "ハンガリー",
        name_ko: "헝가리",
        name_nl: "Hongarije",
        name_pl: "Węgry",
        name_pt: "Hungria",
        name_ru: "Венгрия",
        name_sv: "Ungern",
        name_tr: "Macaristan",
        name_uk: "Угорщина",
        name_ur: "ہنگری",
        name_vi: "Hungary",
        name_zh: "匈牙利",
        name_zht: "匈牙利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HUN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.085608351334855, 48.42226430927179],
            [22.640819939878725, 48.150239569687415],
            [22.710531447040495, 47.88219391538941],
            [22.099767693782837, 47.6724392767167],
            [21.626514926853872, 46.99423777931816],
            [21.02195234547125, 46.3160879583519],
            [20.220192498462836, 46.127468980486555],
            [19.596044549241583, 46.17172984474454],
            [18.82983808764996, 45.90887767189193],
            [18.829824792873946, 45.908872358025285],
            [18.45606245288286, 45.75948110613615],
            [17.630066359129557, 45.95176911069419],
            [16.8825150895953, 46.38063182228444],
            [16.564808383864857, 46.50375092221983],
            [16.370504998447416, 46.841327216166505],
            [16.202298211337364, 46.85238597267696],
            [16.534267612380376, 47.49617096616912],
            [16.340584344150415, 47.71290192320123],
            [16.90375410326726, 47.71486562762833],
            [16.979666782304037, 48.123497015976305],
            [17.48847293464982, 47.867466132186216],
            [17.857132602620027, 47.75842886005037],
            [18.696512892336926, 47.880953681014404],
            [18.77702477384767, 48.081768296900634],
            [19.17436486173989, 48.11137889260387],
            [19.661363559658497, 48.26661489520866],
            [19.769470656013112, 48.202691148463614],
            [20.239054396249347, 48.32756724709692],
            [20.473562045989866, 48.562850043321816],
            [20.801293979584926, 48.623854071642384],
            [21.872236362401736, 48.31997081155002],
            [22.085608351334855, 48.42226430927179],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Slovakia",
        sov_a3: "SVK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Slovakia",
        adm0_a3: "SVK",
        geou_dif: 0,
        geounit: "Slovakia",
        gu_a3: "SVK",
        su_dif: 0,
        subunit: "Slovakia",
        su_a3: "SVK",
        brk_diff: 0,
        name: "Slovakia",
        name_long: "Slovakia",
        brk_a3: "SVK",
        brk_name: "Slovakia",
        brk_group: null,
        abbrev: "Svk.",
        postal: "SK",
        formal_en: "Slovak Republic",
        formal_fr: null,
        name_ciawf: "Slovakia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Slovak Republic",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 5454073,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 105079,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "LO",
        iso_a2: "SK",
        iso_a2_eh: "SK",
        iso_a3: "SVK",
        iso_a3_eh: "SVK",
        iso_n3: "703",
        iso_n3_eh: "703",
        un_a3: "703",
        wb_a2: "SK",
        wb_a3: "SVK",
        woe_id: 23424877,
        woe_id_eh: 23424877,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SVK",
        adm0_diff: null,
        adm0_tlc: "SVK",
        adm0_a3_us: "SVK",
        adm0_a3_fr: "SVK",
        adm0_a3_ru: "SVK",
        adm0_a3_es: "SVK",
        adm0_a3_cn: "SVK",
        adm0_a3_tw: "SVK",
        adm0_a3_in: "SVK",
        adm0_a3_np: "SVK",
        adm0_a3_pk: "SVK",
        adm0_a3_de: "SVK",
        adm0_a3_gb: "SVK",
        adm0_a3_br: "SVK",
        adm0_a3_il: "SVK",
        adm0_a3_ps: "SVK",
        adm0_a3_sa: "SVK",
        adm0_a3_eg: "SVK",
        adm0_a3_ma: "SVK",
        adm0_a3_pt: "SVK",
        adm0_a3_ar: "SVK",
        adm0_a3_jp: "SVK",
        adm0_a3_ko: "SVK",
        adm0_a3_vn: "SVK",
        adm0_a3_tr: "SVK",
        adm0_a3_id: "SVK",
        adm0_a3_pl: "SVK",
        adm0_a3_gr: "SVK",
        adm0_a3_it: "SVK",
        adm0_a3_nl: "SVK",
        adm0_a3_se: "SVK",
        adm0_a3_bd: "SVK",
        adm0_a3_ua: "SVK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 19.049868,
        label_y: 48.734044,
        ne_id: 1159321283,
        wikidataid: "Q214",
        name_ar: "سلوفاكيا",
        name_bn: "স্লোভাকিয়া",
        name_de: "Slowakei",
        name_en: "Slovakia",
        name_es: "Eslovaquia",
        name_fa: "اسلواکی",
        name_fr: "Slovaquie",
        name_el: "Σλοβακία",
        name_he: "סלובקיה",
        name_hi: "स्लोवाकिया",
        name_hu: "Szlovákia",
        name_id: "Slowakia",
        name_it: "Slovacchia",
        name_ja: "スロバキア",
        name_ko: "슬로바키아",
        name_nl: "Slowakije",
        name_pl: "Słowacja",
        name_pt: "Eslováquia",
        name_ru: "Словакия",
        name_sv: "Slovakien",
        name_tr: "Slovakya",
        name_uk: "Словаччина",
        name_ur: "سلوواکیہ",
        name_vi: "Slovakia",
        name_zh: "斯洛伐克",
        name_zht: "斯洛伐克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SVK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.558137648211755, 49.085738023467144],
            [22.28084191253356, 48.82539215758067],
            [22.085608351334855, 48.42226430927179],
            [21.872236362401736, 48.31997081155002],
            [20.801293979584926, 48.623854071642384],
            [20.473562045989866, 48.562850043321816],
            [20.239054396249347, 48.32756724709692],
            [19.769470656013112, 48.202691148463614],
            [19.661363559658497, 48.26661489520866],
            [19.17436486173989, 48.11137889260387],
            [18.77702477384767, 48.081768296900634],
            [18.696512892336926, 47.880953681014404],
            [17.857132602620027, 47.75842886005037],
            [17.48847293464982, 47.867466132186216],
            [16.979666782304037, 48.123497015976305],
            [16.879982944413, 48.47001333270947],
            [16.960288120194576, 48.5969823268506],
            [17.101984897538898, 48.816968899117114],
            [17.545006951577108, 48.80001902932537],
            [17.88648481616181, 48.90347524677371],
            [17.913511590250465, 48.996492824899086],
            [18.104972771891852, 49.04398346617531],
            [18.170498488037964, 49.271514797556435],
            [18.399993523846177, 49.31500051533004],
            [18.554971144289482, 49.495015367218784],
            [18.853144158613617, 49.49622976337764],
            [18.90957482267632, 49.435845852244576],
            [19.320712517990472, 49.571574001659194],
            [19.825022820726872, 49.21712535256923],
            [20.415839471119853, 49.43145335549977],
            [20.887955356538413, 49.32877228453583],
            [21.607808058364213, 49.47010732685409],
            [22.558137648211755, 49.085738023467144],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Poland",
        sov_a3: "POL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Poland",
        adm0_a3: "POL",
        geou_dif: 0,
        geounit: "Poland",
        gu_a3: "POL",
        su_dif: 0,
        subunit: "Poland",
        su_a3: "POL",
        brk_diff: 0,
        name: "Poland",
        name_long: "Poland",
        brk_a3: "POL",
        brk_name: "Poland",
        brk_group: null,
        abbrev: "Pol.",
        postal: "PL",
        formal_en: "Republic of Poland",
        formal_fr: null,
        name_ciawf: "Poland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Poland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 7,
        mapcolor9: 1,
        mapcolor13: 2,
        pop_est: 37970874,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 595858,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "PL",
        iso_a2: "PL",
        iso_a2_eh: "PL",
        iso_a3: "POL",
        iso_a3_eh: "POL",
        iso_n3: "616",
        iso_n3_eh: "616",
        un_a3: "616",
        wb_a2: "PL",
        wb_a3: "POL",
        woe_id: 23424923,
        woe_id_eh: 23424923,
        woe_note: "Exact WOE match as country",
        adm0_iso: "POL",
        adm0_diff: null,
        adm0_tlc: "POL",
        adm0_a3_us: "POL",
        adm0_a3_fr: "POL",
        adm0_a3_ru: "POL",
        adm0_a3_es: "POL",
        adm0_a3_cn: "POL",
        adm0_a3_tw: "POL",
        adm0_a3_in: "POL",
        adm0_a3_np: "POL",
        adm0_a3_pk: "POL",
        adm0_a3_de: "POL",
        adm0_a3_gb: "POL",
        adm0_a3_br: "POL",
        adm0_a3_il: "POL",
        adm0_a3_ps: "POL",
        adm0_a3_sa: "POL",
        adm0_a3_eg: "POL",
        adm0_a3_ma: "POL",
        adm0_a3_pt: "POL",
        adm0_a3_ar: "POL",
        adm0_a3_jp: "POL",
        adm0_a3_ko: "POL",
        adm0_a3_vn: "POL",
        adm0_a3_tr: "POL",
        adm0_a3_id: "POL",
        adm0_a3_pl: "POL",
        adm0_a3_gr: "POL",
        adm0_a3_it: "POL",
        adm0_a3_nl: "POL",
        adm0_a3_se: "POL",
        adm0_a3_bd: "POL",
        adm0_a3_ua: "POL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 19.490468,
        label_y: 51.990316,
        ne_id: 1159321179,
        wikidataid: "Q36",
        name_ar: "بولندا",
        name_bn: "পোল্যান্ড",
        name_de: "Polen",
        name_en: "Poland",
        name_es: "Polonia",
        name_fa: "لهستان",
        name_fr: "Pologne",
        name_el: "Πολωνία",
        name_he: "פולין",
        name_hi: "पोलैंड",
        name_hu: "Lengyelország",
        name_id: "Polandia",
        name_it: "Polonia",
        name_ja: "ポーランド",
        name_ko: "폴란드",
        name_nl: "Polen",
        name_pl: "Polska",
        name_pt: "Polónia",
        name_ru: "Польша",
        name_sv: "Polen",
        name_tr: "Polonya",
        name_uk: "Польща",
        name_ur: "پولینڈ",
        name_vi: "Ba Lan",
        name_zh: "波兰",
        name_zht: "波蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "POL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.48412763844985, 53.91249766704114],
            [23.527535841575002, 53.470121568406555],
            [23.80493493011778, 53.089731350306074],
            [23.79919884613338, 52.69109935160657],
            [23.199493849386187, 52.48697744405367],
            [23.508002150168693, 52.02364655212473],
            [23.52707075368437, 51.57845408793031],
            [24.029985792748903, 50.70540660257518],
            [23.92275719574326, 50.42488108987874],
            [23.426508416444392, 50.30850576435745],
            [22.518450148211603, 49.47677358661974],
            [22.776418898212626, 49.02739533140962],
            [22.558137648211755, 49.085738023467144],
            [21.607808058364213, 49.47010732685409],
            [20.887955356538413, 49.32877228453583],
            [20.415839471119853, 49.43145335549977],
            [19.825022820726872, 49.21712535256923],
            [19.320712517990472, 49.571574001659194],
            [18.90957482267632, 49.435845852244576],
            [18.853144158613617, 49.49622976337764],
            [18.392913852622172, 49.98862864847075],
            [17.64944502123899, 50.049038397819956],
            [17.55456709155112, 50.36214590107642],
            [16.86876915860566, 50.47397370055603],
            [16.719475945714436, 50.21574656839354],
            [16.176253289462267, 50.42260732685791],
            [16.23862674323857, 50.69773265237984],
            [15.490972120839729, 50.78472992614321],
            [15.01699588385867, 51.10667409932158],
            [14.607098422919535, 51.74518809671997],
            [14.685026482815687, 52.0899474147552],
            [14.437599725002201, 52.62485016540839],
            [14.074521111719434, 52.98126251892535],
            [14.353315463934138, 53.24817129171297],
            [14.119686313542559, 53.75702912049104],
            [14.802900424873458, 54.05070628520575],
            [16.36347700365573, 54.513158677785725],
            [17.622831658608675, 54.85153595643291],
            [18.62085859546164, 54.68260569927078],
            [18.696254510175464, 54.43871877706929],
            [19.660640089606403, 54.42608388937393],
            [20.892244500418627, 54.31252492941253],
            [22.731098667092652, 54.327536932993326],
            [23.24398725758951, 54.22056671814914],
            [23.48412763844985, 53.91249766704114],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ireland",
        sov_a3: "IRL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ireland",
        adm0_a3: "IRL",
        geou_dif: 0,
        geounit: "Ireland",
        gu_a3: "IRL",
        su_dif: 0,
        subunit: "Ireland",
        su_a3: "IRL",
        brk_diff: 0,
        name: "Ireland",
        name_long: "Ireland",
        brk_a3: "IRL",
        brk_name: "Ireland",
        brk_group: null,
        abbrev: "Ire.",
        postal: "IRL",
        formal_en: "Ireland",
        formal_fr: null,
        name_ciawf: "Ireland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ireland",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 4941444,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 388698,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "EI",
        iso_a2: "IE",
        iso_a2_eh: "IE",
        iso_a3: "IRL",
        iso_a3_eh: "IRL",
        iso_n3: "372",
        iso_n3_eh: "372",
        un_a3: "372",
        wb_a2: "IE",
        wb_a3: "IRL",
        woe_id: 23424803,
        woe_id_eh: 23424803,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IRL",
        adm0_diff: null,
        adm0_tlc: "IRL",
        adm0_a3_us: "IRL",
        adm0_a3_fr: "IRL",
        adm0_a3_ru: "IRL",
        adm0_a3_es: "IRL",
        adm0_a3_cn: "IRL",
        adm0_a3_tw: "IRL",
        adm0_a3_in: "IRL",
        adm0_a3_np: "IRL",
        adm0_a3_pk: "IRL",
        adm0_a3_de: "IRL",
        adm0_a3_gb: "IRL",
        adm0_a3_br: "IRL",
        adm0_a3_il: "IRL",
        adm0_a3_ps: "IRL",
        adm0_a3_sa: "IRL",
        adm0_a3_eg: "IRL",
        adm0_a3_ma: "IRL",
        adm0_a3_pt: "IRL",
        adm0_a3_ar: "IRL",
        adm0_a3_jp: "IRL",
        adm0_a3_ko: "IRL",
        adm0_a3_vn: "IRL",
        adm0_a3_tr: "IRL",
        adm0_a3_id: "IRL",
        adm0_a3_pl: "IRL",
        adm0_a3_gr: "IRL",
        adm0_a3_it: "IRL",
        adm0_a3_nl: "IRL",
        adm0_a3_se: "IRL",
        adm0_a3_bd: "IRL",
        adm0_a3_ua: "IRL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -7.798588,
        label_y: 53.078726,
        ne_id: 1159320877,
        wikidataid: "Q27",
        name_ar: "جمهورية أيرلندا",
        name_bn: "প্রজাতন্ত্রী আয়ারল্যান্ড",
        name_de: "Irland",
        name_en: "Ireland",
        name_es: "Irlanda",
        name_fa: "ایرلند",
        name_fr: "Irlande",
        name_el: "Δημοκρατία της Ιρλανδίας",
        name_he: "אירלנד",
        name_hi: "आयरलैण्ड",
        name_hu: "Írország",
        name_id: "Republik Irlandia",
        name_it: "Irlanda",
        name_ja: "アイルランド",
        name_ko: "아일랜드",
        name_nl: "Ierland",
        name_pl: "Irlandia",
        name_pt: "República da Irlanda",
        name_ru: "Ирландия",
        name_sv: "Irland",
        name_tr: "İrlanda",
        name_uk: "Ірландія",
        name_ur: "جمہوریہ آئرلینڈ",
        name_vi: "Cộng hòa Ireland",
        name_zh: "爱尔兰",
        name_zht: "愛爾蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IRL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.197884894220991, 53.867565009163364],
            [-6.032985398777611, 53.15316417094435],
            [-6.788856573910849, 52.260117906292336],
            [-8.56161658368356, 51.669301255899356],
            [-9.977085740590269, 51.82045482035308],
            [-9.166282517930782, 52.86462881124268],
            [-9.688524542672454, 53.8813626165853],
            [-8.327987433292009, 54.66451894796863],
            [-7.572167934591064, 55.13162221945487],
            [-7.366030646178785, 54.59584096945272],
            [-7.572167934591064, 54.059956366586],
            [-6.953730231138067, 54.073702297575636],
            [-6.197884894220991, 53.867565009163364],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "United Kingdom",
        adm0_a3: "GBR",
        geou_dif: 0,
        geounit: "United Kingdom",
        gu_a3: "GBR",
        su_dif: 0,
        subunit: "United Kingdom",
        su_a3: "GBR",
        brk_diff: 0,
        name: "United Kingdom",
        name_long: "United Kingdom",
        brk_a3: "GBR",
        brk_name: "United Kingdom",
        brk_group: null,
        abbrev: "U.K.",
        postal: "GB",
        formal_en: "United Kingdom of Great Britain and Northern Ireland",
        formal_fr: null,
        name_ciawf: "United Kingdom",
        note_adm0: null,
        note_brk: null,
        name_sort: "United Kingdom",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 66834405,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 2829108,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "UK",
        iso_a2: "GB",
        iso_a2_eh: "GB",
        iso_a3: "GBR",
        iso_a3_eh: "GBR",
        iso_n3: "826",
        iso_n3_eh: "826",
        un_a3: "826",
        wb_a2: "GB",
        wb_a3: "GBR",
        woe_id: -90,
        woe_id_eh: 23424975,
        woe_note:
          "Eh ID includes Channel Islands and Isle of Man. UK constituent countries of England (24554868), Wales (12578049), Scotland (12578048), and Northern Ireland (20070563).",
        adm0_iso: "GBR",
        adm0_diff: null,
        adm0_tlc: "GBR",
        adm0_a3_us: "GBR",
        adm0_a3_fr: "GBR",
        adm0_a3_ru: "GBR",
        adm0_a3_es: "GBR",
        adm0_a3_cn: "GBR",
        adm0_a3_tw: "GBR",
        adm0_a3_in: "GBR",
        adm0_a3_np: "GBR",
        adm0_a3_pk: "GBR",
        adm0_a3_de: "GBR",
        adm0_a3_gb: "GBR",
        adm0_a3_br: "GBR",
        adm0_a3_il: "GBR",
        adm0_a3_ps: "GBR",
        adm0_a3_sa: "GBR",
        adm0_a3_eg: "GBR",
        adm0_a3_ma: "GBR",
        adm0_a3_pt: "GBR",
        adm0_a3_ar: "GBR",
        adm0_a3_jp: "GBR",
        adm0_a3_ko: "GBR",
        adm0_a3_vn: "GBR",
        adm0_a3_tr: "GBR",
        adm0_a3_id: "GBR",
        adm0_a3_pl: "GBR",
        adm0_a3_gr: "GBR",
        adm0_a3_it: "GBR",
        adm0_a3_nl: "GBR",
        adm0_a3_se: "GBR",
        adm0_a3_bd: "GBR",
        adm0_a3_ua: "GBR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 14,
        long_len: 14,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: -2.116346,
        label_y: 54.402739,
        ne_id: 1159320713,
        wikidataid: "Q145",
        name_ar: "المملكة المتحدة",
        name_bn: "যুক্তরাজ্য",
        name_de: "Vereinigtes Königreich",
        name_en: "United Kingdom",
        name_es: "Reino Unido",
        name_fa: "بریتانیا",
        name_fr: "Royaume-Uni",
        name_el: "Ηνωμένο Βασίλειο",
        name_he: "הממלכה המאוחדת",
        name_hi: "यूनाइटेड किंगडम",
        name_hu: "Egyesült Királyság",
        name_id: "Britania Raya",
        name_it: "Regno Unito",
        name_ja: "イギリス",
        name_ko: "영국",
        name_nl: "Verenigd Koninkrijk",
        name_pl: "Wielka Brytania",
        name_pt: "Reino Unido",
        name_ru: "Великобритания",
        name_sv: "Storbritannien",
        name_tr: "Birleşik Krallık",
        name_uk: "Велика Британія",
        name_ur: "مملکت متحدہ",
        name_vi: "Vương quốc Liên hiệp Anh và Bắc Ireland",
        name_zh: "英国",
        name_zht: "英國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GBR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.197884894220991, 53.867565009163364],
              [-6.953730231138067, 54.073702297575636],
              [-7.572167934591064, 54.059956366586],
              [-7.366030646178785, 54.59584096945272],
              [-7.572167934591064, 55.13162221945487],
              [-6.733847011736145, 55.17286001242378],
              [-5.661948614921968, 54.55460317648381],
              [-6.197884894220991, 53.867565009163364],
            ],
          ],
          [
            [
              [-3.093830673788716, 53.404547400669685],
              [-3.092079637047107, 53.40444082296358],
              [-2.945008510744344, 53.98499970154671],
              [-3.614700825433033, 54.600936773292574],
              [-3.630005458989331, 54.615012925833014],
              [-4.844169073903061, 54.790971177786844],
              [-5.082526617849283, 55.06160065369937],
              [-4.7191121077567, 55.50847260194348],
              [-5.047980922862109, 55.78398550070756],
              [-5.586397670911197, 55.31114614523682],
              [-5.644998745130238, 56.27501496034483],
              [-6.149980841486411, 56.785009670633485],
              [-5.786824713555291, 57.81884837506465],
              [-5.009998745127632, 58.63001333275008],
              [-4.211494513353557, 58.55084503847911],
              [-3.005004848635281, 58.63500010846633],
              [-4.073828497728073, 57.55302480735526],
              [-3.055001796877718, 57.69001902936094],
              [-1.959280564776918, 57.68479970969952],
              [-2.219988165689358, 56.87001740175356],
              [-3.119003058271176, 55.973793036515474],
              [-2.085009324543023, 55.9099984808513],
              [-2.005675679673857, 55.804902850350175],
              [-1.11499101399221, 54.624986477265395],
              [-0.4304849918542, 54.46437612570219],
              [0.184981316742039, 53.32501414653103],
              [0.469976840831805, 52.929999498092],
              [1.681530795914682, 52.739520168664],
              [1.559987827164321, 52.09999848083601],
              [1.050561557630942, 51.806760565795685],
              [1.449865349950244, 51.28942780212191],
              [0.550333693045502, 50.765738837275876],
              [-0.787517462558696, 50.77498891865622],
              [-2.489997524414434, 50.50001862243124],
              [-2.956273972984093, 50.696879991247044],
              [-3.617448085942385, 50.22835561787275],
              [-4.542507900399244, 50.341837063185665],
              [-5.245023159191135, 49.95999990498109],
              [-5.776566941745273, 50.15967763935686],
              [-4.309989793301895, 51.21000112568919],
              [-3.414850633142123, 51.42600861266925],
              [-3.422719467108379, 51.42684816740609],
              [-4.98436723471093, 51.593466091511004],
              [-5.267295701508942, 51.991400458374585],
              [-4.222346564134909, 52.301355699261364],
              [-4.77001339356417, 52.840004991255626],
              [-4.579999152026971, 53.49500377055517],
              [-3.093830673788716, 53.404547400669685],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Greece",
        sov_a3: "GRC",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Greece",
        adm0_a3: "GRC",
        geou_dif: 0,
        geounit: "Greece",
        gu_a3: "GRC",
        su_dif: 0,
        subunit: "Greece",
        su_a3: "GRC",
        brk_diff: 0,
        name: "Greece",
        name_long: "Greece",
        brk_a3: "GRC",
        brk_name: "Greece",
        brk_group: null,
        abbrev: "Greece",
        postal: "GR",
        formal_en: "Hellenic Republic",
        formal_fr: null,
        name_ciawf: "Greece",
        note_adm0: null,
        note_brk: null,
        name_sort: "Greece",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 10716322,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 209852,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "GR",
        iso_a2: "GR",
        iso_a2_eh: "GR",
        iso_a3: "GRC",
        iso_a3_eh: "GRC",
        iso_n3: "300",
        iso_n3_eh: "300",
        un_a3: "300",
        wb_a2: "GR",
        wb_a3: "GRC",
        woe_id: 23424833,
        woe_id_eh: 23424833,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GRC",
        adm0_diff: null,
        adm0_tlc: "GRC",
        adm0_a3_us: "GRC",
        adm0_a3_fr: "GRC",
        adm0_a3_ru: "GRC",
        adm0_a3_es: "GRC",
        adm0_a3_cn: "GRC",
        adm0_a3_tw: "GRC",
        adm0_a3_in: "GRC",
        adm0_a3_np: "GRC",
        adm0_a3_pk: "GRC",
        adm0_a3_de: "GRC",
        adm0_a3_gb: "GRC",
        adm0_a3_br: "GRC",
        adm0_a3_il: "GRC",
        adm0_a3_ps: "GRC",
        adm0_a3_sa: "GRC",
        adm0_a3_eg: "GRC",
        adm0_a3_ma: "GRC",
        adm0_a3_pt: "GRC",
        adm0_a3_ar: "GRC",
        adm0_a3_jp: "GRC",
        adm0_a3_ko: "GRC",
        adm0_a3_vn: "GRC",
        adm0_a3_tr: "GRC",
        adm0_a3_id: "GRC",
        adm0_a3_pl: "GRC",
        adm0_a3_gr: "GRC",
        adm0_a3_it: "GRC",
        adm0_a3_nl: "GRC",
        adm0_a3_se: "GRC",
        adm0_a3_bd: "GRC",
        adm0_a3_ua: "GRC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: 21.72568,
        label_y: 39.492763,
        ne_id: 1159320811,
        wikidataid: "Q41",
        name_ar: "اليونان",
        name_bn: "গ্রিস",
        name_de: "Griechenland",
        name_en: "Greece",
        name_es: "Grecia",
        name_fa: "یونان",
        name_fr: "Grèce",
        name_el: "Ελλάδα",
        name_he: "יוון",
        name_hi: "यूनान",
        name_hu: "Görögország",
        name_id: "Yunani",
        name_it: "Grecia",
        name_ja: "ギリシャ",
        name_ko: "그리스",
        name_nl: "Griekenland",
        name_pl: "Grecja",
        name_pt: "Grécia",
        name_ru: "Греция",
        name_sv: "Grekland",
        name_tr: "Yunanistan",
        name_uk: "Греція",
        name_ur: "یونان",
        name_vi: "Hy Lạp",
        name_zh: "希腊",
        name_zht: "希臘",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GRC.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [26.290002882601698, 35.29999034274793],
              [26.16499759288766, 35.00499542900977],
              [24.724982130642303, 34.91998769788964],
              [24.735007358506916, 35.08499054619759],
              [23.51497846852808, 35.27999156345098],
              [23.699980096133004, 35.70500438083549],
              [24.246665073348705, 35.368022365860185],
              [25.02501549652891, 35.42499563246197],
              [25.769207797964185, 35.35401805270908],
              [25.745023227651586, 35.1799976669662],
              [26.290002882601698, 35.29999034274793],
            ],
          ],
          [
            [
              [22.952377150166452, 41.33799388281115],
              [23.692073601992348, 41.309080918943856],
              [24.492644891057978, 41.58389618587205],
              [25.197201368925448, 41.23448598893053],
              [26.106138136507127, 41.32889883072784],
              [26.1170418637208, 41.82690460872456],
              [26.60419559093623, 41.562114569661105],
              [26.294602085075667, 40.93626129817413],
              [26.05694217296534, 40.82412344010076],
              [25.44767703624416, 40.85254547786144],
              [24.92584842296094, 40.947061672523205],
              [23.71481123220076, 40.687129218095095],
              [24.40799889496401, 40.12499298762407],
              [23.899967889102584, 39.96200552017552],
              [23.342999301860743, 39.96099782974579],
              [22.813987664488934, 40.476005153966526],
              [22.626298862404724, 40.25656118423916],
              [22.84974775563478, 39.659310818025745],
              [23.35002729665257, 39.190011298167235],
              [22.973099399515547, 38.97090322524963],
              [23.530016310324925, 38.51000112563844],
              [24.025024855248887, 38.21999298761642],
              [24.040011020613576, 37.655014553369426],
              [23.115002882589124, 37.920011298162194],
              [23.409971958111043, 37.40999074965737],
              [22.77497195810861, 37.30501007745653],
              [23.15422529469862, 36.422505804992056],
              [22.490028110451078, 36.4100001083774],
              [21.67002648284364, 36.8449864771942],
              [21.29501061370155, 37.64498932550464],
              [21.120034213961333, 38.31032339126273],
              [20.730032179454554, 38.769985256498785],
              [20.217712029712857, 39.34023468683961],
              [20.15001590341052, 39.62499766698397],
              [20.615000441172754, 40.11000682225938],
              [20.674996779063633, 40.43499990494303],
              [20.999989861747224, 40.58000397395398],
              [21.0200403174764, 40.84272695572588],
              [21.674160597426976, 40.931274522457954],
              [22.05537763844427, 41.14986583105269],
              [22.597308383889015, 41.130487168943205],
              [22.76177, 41.3048],
              [22.952377150166452, 41.33799388281115],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Austria",
        sov_a3: "AUT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Austria",
        adm0_a3: "AUT",
        geou_dif: 0,
        geounit: "Austria",
        gu_a3: "AUT",
        su_dif: 0,
        subunit: "Austria",
        su_a3: "AUT",
        brk_diff: 0,
        name: "Austria",
        name_long: "Austria",
        brk_a3: "AUT",
        brk_name: "Austria",
        brk_group: null,
        abbrev: "Aust.",
        postal: "A",
        formal_en: "Republic of Austria",
        formal_fr: null,
        name_ciawf: "Austria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Austria",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 8877067,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 445075,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "AU",
        iso_a2: "AT",
        iso_a2_eh: "AT",
        iso_a3: "AUT",
        iso_a3_eh: "AUT",
        iso_n3: "040",
        iso_n3_eh: "040",
        un_a3: "040",
        wb_a2: "AT",
        wb_a3: "AUT",
        woe_id: 23424750,
        woe_id_eh: 23424750,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AUT",
        adm0_diff: null,
        adm0_tlc: "AUT",
        adm0_a3_us: "AUT",
        adm0_a3_fr: "AUT",
        adm0_a3_ru: "AUT",
        adm0_a3_es: "AUT",
        adm0_a3_cn: "AUT",
        adm0_a3_tw: "AUT",
        adm0_a3_in: "AUT",
        adm0_a3_np: "AUT",
        adm0_a3_pk: "AUT",
        adm0_a3_de: "AUT",
        adm0_a3_gb: "AUT",
        adm0_a3_br: "AUT",
        adm0_a3_il: "AUT",
        adm0_a3_ps: "AUT",
        adm0_a3_sa: "AUT",
        adm0_a3_eg: "AUT",
        adm0_a3_ma: "AUT",
        adm0_a3_pt: "AUT",
        adm0_a3_ar: "AUT",
        adm0_a3_jp: "AUT",
        adm0_a3_ko: "AUT",
        adm0_a3_vn: "AUT",
        adm0_a3_tr: "AUT",
        adm0_a3_id: "AUT",
        adm0_a3_pl: "AUT",
        adm0_a3_gr: "AUT",
        adm0_a3_it: "AUT",
        adm0_a3_nl: "AUT",
        adm0_a3_se: "AUT",
        adm0_a3_bd: "AUT",
        adm0_a3_ua: "AUT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 14.130515,
        label_y: 47.518859,
        ne_id: 1159320379,
        wikidataid: "Q40",
        name_ar: "النمسا",
        name_bn: "অস্ট্রিয়া",
        name_de: "Österreich",
        name_en: "Austria",
        name_es: "Austria",
        name_fa: "اتریش",
        name_fr: "Autriche",
        name_el: "Αυστρία",
        name_he: "אוסטריה",
        name_hi: "ऑस्ट्रिया",
        name_hu: "Ausztria",
        name_id: "Austria",
        name_it: "Austria",
        name_ja: "オーストリア",
        name_ko: "오스트리아",
        name_nl: "Oostenrijk",
        name_pl: "Austria",
        name_pt: "Áustria",
        name_ru: "Австрия",
        name_sv: "Österrike",
        name_tr: "Avusturya",
        name_uk: "Австрія",
        name_ur: "آسٹریا",
        name_vi: "Áo",
        name_zh: "奥地利",
        name_zht: "奧地利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AUT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.979666782304037, 48.123497015976305],
            [16.90375410326726, 47.71486562762833],
            [16.340584344150415, 47.71290192320123],
            [16.534267612380376, 47.49617096616912],
            [16.202298211337364, 46.85238597267696],
            [16.011663852612656, 46.6836107448117],
            [15.137091912504985, 46.65870270444703],
            [14.63247155117483, 46.43181732846955],
            [13.806475457421527, 46.509306138691215],
            [12.376485223040817, 46.76755910906985],
            [12.153088006243054, 47.11539317482645],
            [11.16482791509327, 46.94157949481273],
            [11.048555942436536, 46.75135854754634],
            [10.44270145024663, 46.89354625099743],
            [9.932448357796659, 46.92072805438296],
            [9.479969516649021, 47.102809963563374],
            [9.632931756232978, 47.34760122332999],
            [9.59422610844635, 47.52505809182027],
            [9.89606814946319, 47.580196845075704],
            [10.402083774465211, 47.30248769793916],
            [10.544504021861599, 47.5663992376538],
            [11.426414015354737, 47.523766181012974],
            [12.141357456112788, 47.703083401065776],
            [12.620759718484493, 47.67238760028441],
            [12.932626987365948, 47.467645575544],
            [13.02585127122049, 47.63758352313583],
            [12.884102817443875, 48.28914581968786],
            [13.243357374737, 48.416114813829054],
            [13.595945672264437, 48.87717194273715],
            [14.338897739324722, 48.55530528420721],
            [14.901447381254057, 48.964401760445824],
            [15.253415561593982, 49.03907420510758],
            [16.02964725105022, 48.73389903420793],
            [16.499282667718774, 48.78580801044511],
            [16.960288120194576, 48.5969823268506],
            [16.879982944413, 48.47001333270947],
            [16.979666782304037, 48.123497015976305],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Italy",
        sov_a3: "ITA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Italy",
        adm0_a3: "ITA",
        geou_dif: 0,
        geounit: "Italy",
        gu_a3: "ITA",
        su_dif: 0,
        subunit: "Italy",
        su_a3: "ITA",
        brk_diff: 0,
        name: "Italy",
        name_long: "Italy",
        brk_a3: "ITA",
        brk_name: "Italy",
        brk_group: null,
        abbrev: "Italy",
        postal: "I",
        formal_en: "Italian Republic",
        formal_fr: null,
        name_ciawf: "Italy",
        note_adm0: null,
        note_brk: null,
        name_sort: "Italy",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 7,
        mapcolor9: 8,
        mapcolor13: 7,
        pop_est: 60297396,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 2003576,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "IT",
        iso_a2: "IT",
        iso_a2_eh: "IT",
        iso_a3: "ITA",
        iso_a3_eh: "ITA",
        iso_n3: "380",
        iso_n3_eh: "380",
        un_a3: "380",
        wb_a2: "IT",
        wb_a3: "ITA",
        woe_id: 23424853,
        woe_id_eh: 23424853,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ITA",
        adm0_diff: null,
        adm0_tlc: "ITA",
        adm0_a3_us: "ITA",
        adm0_a3_fr: "ITA",
        adm0_a3_ru: "ITA",
        adm0_a3_es: "ITA",
        adm0_a3_cn: "ITA",
        adm0_a3_tw: "ITA",
        adm0_a3_in: "ITA",
        adm0_a3_np: "ITA",
        adm0_a3_pk: "ITA",
        adm0_a3_de: "ITA",
        adm0_a3_gb: "ITA",
        adm0_a3_br: "ITA",
        adm0_a3_il: "ITA",
        adm0_a3_ps: "ITA",
        adm0_a3_sa: "ITA",
        adm0_a3_eg: "ITA",
        adm0_a3_ma: "ITA",
        adm0_a3_pt: "ITA",
        adm0_a3_ar: "ITA",
        adm0_a3_jp: "ITA",
        adm0_a3_ko: "ITA",
        adm0_a3_vn: "ITA",
        adm0_a3_tr: "ITA",
        adm0_a3_id: "ITA",
        adm0_a3_pl: "ITA",
        adm0_a3_gr: "ITA",
        adm0_a3_it: "ITA",
        adm0_a3_nl: "ITA",
        adm0_a3_se: "ITA",
        adm0_a3_bd: "ITA",
        adm0_a3_ua: "ITA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 11.076907,
        label_y: 44.732482,
        ne_id: 1159320919,
        wikidataid: "Q38",
        name_ar: "إيطاليا",
        name_bn: "ইতালি",
        name_de: "Italien",
        name_en: "Italy",
        name_es: "Italia",
        name_fa: "ایتالیا",
        name_fr: "Italie",
        name_el: "Ιταλία",
        name_he: "איטליה",
        name_hi: "इटली",
        name_hu: "Olaszország",
        name_id: "Italia",
        name_it: "Italia",
        name_ja: "イタリア",
        name_ko: "이탈리아",
        name_nl: "Italië",
        name_pl: "Włochy",
        name_pt: "Itália",
        name_ru: "Италия",
        name_sv: "Italien",
        name_tr: "İtalya",
        name_uk: "Італія",
        name_ur: "اطالیہ",
        name_vi: "Ý",
        name_zh: "意大利",
        name_zht: "義大利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ITA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [10.44270145024663, 46.89354625099743],
              [11.048555942436536, 46.75135854754634],
              [11.16482791509327, 46.94157949481273],
              [12.153088006243054, 47.11539317482645],
              [12.376485223040817, 46.76755910906985],
              [13.806475457421527, 46.509306138691215],
              [13.698109978905478, 46.01677806251735],
              [13.937630242578308, 45.59101593686462],
              [13.141606479554296, 45.73669179949542],
              [12.32858117030625, 45.381778062514826],
              [12.383874952858548, 44.885374253919096],
              [12.261453484759102, 44.600482082693986],
              [12.589237094786455, 44.09136587175446],
              [13.526905958722494, 43.587727362637864],
              [14.029820997787029, 42.761007798832466],
              [15.142569614327925, 41.95513967545685],
              [15.926191033601896, 41.961315009115715],
              [16.169897088290384, 41.74029490820339],
              [15.889345737377797, 41.541082261718216],
              [16.785001661860548, 41.17960561783656],
              [17.51916873543118, 40.87714345963222],
              [18.37668745288252, 40.35562490494263],
              [18.48024702319543, 40.16886627863981],
              [18.293385044028042, 39.810774441073264],
              [17.738380161213314, 40.277671006830346],
              [16.869595981522338, 40.44223460546381],
              [16.448743116937322, 39.79540070246644],
              [17.17148969897147, 39.42469981542068],
              [17.052840610429314, 38.90287120213735],
              [16.635088331781816, 38.843572496082416],
              [16.100960727613, 37.9858987493342],
              [15.684086948314473, 37.908849188787016],
              [15.687962680736348, 38.21459280044188],
              [15.89198123542468, 38.75094249119924],
              [16.109332309644287, 38.9645470240777],
              [15.718813510814613, 39.54407237401492],
              [15.413612501698793, 40.048356838535156],
              [14.99849572109818, 40.17294871679087],
              [14.703268263414714, 40.604550279292596],
              [14.060671827865264, 40.7863479680954],
              [13.627985060285397, 41.1882872584616],
              [12.888081902730365, 41.253089504555604],
              [12.106682570044939, 41.70453481705741],
              [11.191906365614216, 42.3554253199897],
              [10.51194786951774, 42.93146251074721],
              [10.200028924203991, 43.9200068222746],
              [9.702488234097842, 44.03627879493132],
              [8.88894616052687, 44.36633616797951],
              [8.428560825238577, 44.23122813575242],
              [7.850766635783144, 43.76714793555524],
              [7.435184767291872, 43.69384491634922],
              [7.549596388386107, 44.12790110938481],
              [7.007562290076635, 44.25476675066136],
              [6.749955275101655, 45.02851797136758],
              [7.096652459347837, 45.33309886329589],
              [6.802355177445605, 45.70857982032864],
              [6.843592970414505, 45.99114655210061],
              [7.273850945676656, 45.776947740250776],
              [7.755992058959833, 45.82449005795931],
              [8.31662967289438, 46.16364248309086],
              [8.489952426801324, 46.005150865251686],
              [8.966305779667806, 46.03693187111119],
              [9.182881707403055, 46.44021474871698],
              [9.922836541390382, 46.31489940040919],
              [10.363378126678612, 46.48357127540986],
              [10.44270145024663, 46.89354625099743],
            ],
          ],
          [
            [
              [14.761249220446189, 38.14387360285046],
              [15.520376010813806, 38.23115509699143],
              [15.160242954171736, 37.44404551853776],
              [15.309897902089006, 37.13421946873183],
              [15.099988234119422, 36.6199872909954],
              [14.335228712631988, 36.996630967754726],
              [13.826732618879959, 37.10453135838016],
              [12.431003859108756, 37.61294993748375],
              [12.570943637755136, 38.12638113051966],
              [13.741156447004613, 38.03496552179533],
              [14.761249220446189, 38.14387360285046],
            ],
          ],
          [
            [
              [8.709990675500109, 40.899984442705225],
              [9.210011834356266, 41.209991360024176],
              [9.80997521326492, 40.50000885676613],
              [9.669518670295616, 39.17737641047178],
              [9.214817742559433, 39.24047333430015],
              [8.806935662479674, 38.906617743478506],
              [8.428302443077115, 39.17184703221655],
              [8.388253208050912, 40.37831085871876],
              [8.159998406617689, 40.95000722916376],
              [8.709990675500109, 40.899984442705225],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Switzerland",
        sov_a3: "CHE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Switzerland",
        adm0_a3: "CHE",
        geou_dif: 0,
        geounit: "Switzerland",
        gu_a3: "CHE",
        su_dif: 0,
        subunit: "Switzerland",
        su_a3: "CHE",
        brk_diff: 0,
        name: "Switzerland",
        name_long: "Switzerland",
        brk_a3: "CHE",
        brk_name: "Switzerland",
        brk_group: null,
        abbrev: "Switz.",
        postal: "CH",
        formal_en: "Swiss Confederation",
        formal_fr: null,
        name_ciawf: "Switzerland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Switzerland",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 8574832,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 703082,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SZ",
        iso_a2: "CH",
        iso_a2_eh: "CH",
        iso_a3: "CHE",
        iso_a3_eh: "CHE",
        iso_n3: "756",
        iso_n3_eh: "756",
        un_a3: "756",
        wb_a2: "CH",
        wb_a3: "CHE",
        woe_id: 23424957,
        woe_id_eh: 23424957,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CHE",
        adm0_diff: null,
        adm0_tlc: "CHE",
        adm0_a3_us: "CHE",
        adm0_a3_fr: "CHE",
        adm0_a3_ru: "CHE",
        adm0_a3_es: "CHE",
        adm0_a3_cn: "CHE",
        adm0_a3_tw: "CHE",
        adm0_a3_in: "CHE",
        adm0_a3_np: "CHE",
        adm0_a3_pk: "CHE",
        adm0_a3_de: "CHE",
        adm0_a3_gb: "CHE",
        adm0_a3_br: "CHE",
        adm0_a3_il: "CHE",
        adm0_a3_ps: "CHE",
        adm0_a3_sa: "CHE",
        adm0_a3_eg: "CHE",
        adm0_a3_ma: "CHE",
        adm0_a3_pt: "CHE",
        adm0_a3_ar: "CHE",
        adm0_a3_jp: "CHE",
        adm0_a3_ko: "CHE",
        adm0_a3_vn: "CHE",
        adm0_a3_tr: "CHE",
        adm0_a3_id: "CHE",
        adm0_a3_pl: "CHE",
        adm0_a3_gr: "CHE",
        adm0_a3_it: "CHE",
        adm0_a3_nl: "CHE",
        adm0_a3_se: "CHE",
        adm0_a3_bd: "CHE",
        adm0_a3_ua: "CHE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 7.463965,
        label_y: 46.719114,
        ne_id: 1159320491,
        wikidataid: "Q39",
        name_ar: "سويسرا",
        name_bn: "সুইজারল্যান্ড",
        name_de: "Schweiz",
        name_en: "Switzerland",
        name_es: "Suiza",
        name_fa: "سوئیس",
        name_fr: "Suisse",
        name_el: "Ελβετία",
        name_he: "שווייץ",
        name_hi: "स्विट्ज़रलैण्ड",
        name_hu: "Svájc",
        name_id: "Swiss",
        name_it: "Svizzera",
        name_ja: "スイス",
        name_ko: "스위스",
        name_nl: "Zwitserland",
        name_pl: "Szwajcaria",
        name_pt: "Suíça",
        name_ru: "Швейцария",
        name_sv: "Schweiz",
        name_tr: "İsviçre",
        name_uk: "Швейцарія",
        name_ur: "سویٹزرلینڈ",
        name_vi: "Thụy Sĩ",
        name_zh: "瑞士",
        name_zht: "瑞士",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CHE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.59422610844635, 47.52505809182027],
            [9.632931756232978, 47.34760122332999],
            [9.479969516649021, 47.102809963563374],
            [9.932448357796659, 46.92072805438296],
            [10.44270145024663, 46.89354625099743],
            [10.363378126678612, 46.48357127540986],
            [9.922836541390382, 46.31489940040919],
            [9.182881707403055, 46.44021474871698],
            [8.966305779667806, 46.03693187111119],
            [8.489952426801324, 46.005150865251686],
            [8.31662967289438, 46.16364248309086],
            [7.755992058959833, 45.82449005795931],
            [7.273850945676656, 45.776947740250776],
            [6.843592970414505, 45.99114655210061],
            [6.500099724970426, 46.42967275652944],
            [6.022609490593538, 46.27298981382047],
            [6.037388950229001, 46.725778713561866],
            [6.768713820023606, 47.2877082383037],
            [6.736571079138059, 47.541801255882845],
            [7.192202182655507, 47.44976552997102],
            [7.466759067422231, 47.62058197691181],
            [8.317301466514095, 47.61357982033627],
            [8.522611932009767, 47.83082754169129],
            [9.59422610844635, 47.52505809182027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Netherlands",
        sov_a3: "NL1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Netherlands",
        adm0_a3: "NLD",
        geou_dif: 0,
        geounit: "Netherlands",
        gu_a3: "NLD",
        su_dif: 0,
        subunit: "Netherlands",
        su_a3: "NLD",
        brk_diff: 0,
        name: "Netherlands",
        name_long: "Netherlands",
        brk_a3: "NLD",
        brk_name: "Netherlands",
        brk_group: null,
        abbrev: "Neth.",
        postal: "NL",
        formal_en: "Kingdom of the Netherlands",
        formal_fr: null,
        name_ciawf: "Netherlands",
        note_adm0: null,
        note_brk: null,
        name_sort: "Netherlands",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 17332850,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 907050,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "NL",
        iso_a2: "NL",
        iso_a2_eh: "NL",
        iso_a3: "NLD",
        iso_a3_eh: "NLD",
        iso_n3: "528",
        iso_n3_eh: "528",
        un_a3: "528",
        wb_a2: "NL",
        wb_a3: "NLD",
        woe_id: -90,
        woe_id_eh: 23424909,
        woe_note:
          "Doesn't include new former units of Netherlands Antilles (24549811, 24549808, and 24549809)",
        adm0_iso: "NLD",
        adm0_diff: null,
        adm0_tlc: "NLD",
        adm0_a3_us: "NLD",
        adm0_a3_fr: "NLD",
        adm0_a3_ru: "NLD",
        adm0_a3_es: "NLD",
        adm0_a3_cn: "NLD",
        adm0_a3_tw: "NLD",
        adm0_a3_in: "NLD",
        adm0_a3_np: "NLD",
        adm0_a3_pk: "NLD",
        adm0_a3_de: "NLD",
        adm0_a3_gb: "NLD",
        adm0_a3_br: "NLD",
        adm0_a3_il: "NLD",
        adm0_a3_ps: "NLD",
        adm0_a3_sa: "NLD",
        adm0_a3_eg: "NLD",
        adm0_a3_ma: "NLD",
        adm0_a3_pt: "NLD",
        adm0_a3_ar: "NLD",
        adm0_a3_jp: "NLD",
        adm0_a3_ko: "NLD",
        adm0_a3_vn: "NLD",
        adm0_a3_tr: "NLD",
        adm0_a3_id: "NLD",
        adm0_a3_pl: "NLD",
        adm0_a3_gr: "NLD",
        adm0_a3_it: "NLD",
        adm0_a3_nl: "NLD",
        adm0_a3_se: "NLD",
        adm0_a3_bd: "NLD",
        adm0_a3_ua: "NLD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 10,
        label_x: 5.61144,
        label_y: 52.422211,
        ne_id: 1159321101,
        wikidataid: "Q55",
        name_ar: "هولندا",
        name_bn: "নেদারল্যান্ডস",
        name_de: "Niederlande",
        name_en: "Netherlands",
        name_es: "Países Bajos",
        name_fa: "هلند",
        name_fr: "Pays-Bas",
        name_el: "Ολλανδία",
        name_he: "הולנד",
        name_hi: "नीदरलैण्ड",
        name_hu: "Hollandia",
        name_id: "Belanda",
        name_it: "Paesi Bassi",
        name_ja: "オランダ",
        name_ko: "네덜란드",
        name_nl: "Nederland",
        name_pl: "Holandia",
        name_pt: "Países Baixos",
        name_ru: "Нидерланды",
        name_sv: "Nederländerna",
        name_tr: "Hollanda",
        name_uk: "Нідерланди",
        name_ur: "نیدرلینڈز",
        name_vi: "Hà Lan",
        name_zh: "荷兰",
        name_zht: "荷蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NLD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.905139601274129, 53.48216217713065],
            [7.092053256873896, 53.144043280644894],
            [6.842869500362383, 52.22844025329755],
            [6.589396599970826, 51.852029120483394],
            [5.988658074577813, 51.851615709025054],
            [6.15665815595878, 50.80372101501058],
            [5.606975945670001, 51.03729848896978],
            [4.973991326526914, 51.47502370869813],
            [4.047071160507528, 51.26725861266857],
            [3.314971144228537, 51.34575511331991],
            [3.31501148496416, 51.34577662473805],
            [3.830288527043137, 51.62054454203195],
            [4.705997348661185, 53.091798407597764],
            [6.074182570020923, 53.510403347378144],
            [6.905139601274129, 53.48216217713065],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Republic of Serbia",
        sov_a3: "SRB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Republic of Serbia",
        adm0_a3: "SRB",
        geou_dif: 0,
        geounit: "Republic of Serbia",
        gu_a3: "SRB",
        su_dif: 0,
        subunit: "Republic of Serbia",
        su_a3: "SRB",
        brk_diff: 0,
        name: "Serbia",
        name_long: "Serbia",
        brk_a3: "SRB",
        brk_name: "Serbia",
        brk_group: null,
        abbrev: "Serb.",
        postal: "RS",
        formal_en: "Republic of Serbia",
        formal_fr: null,
        name_ciawf: "Serbia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Serbia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 6944975,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 51475,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "RI",
        iso_a2: "RS",
        iso_a2_eh: "RS",
        iso_a3: "SRB",
        iso_a3_eh: "SRB",
        iso_n3: "688",
        iso_n3_eh: "688",
        un_a3: "688",
        wb_a2: "YF",
        wb_a3: "SRB",
        woe_id: -90,
        woe_id_eh: 20069818,
        woe_note: "Expired WOE also contains Kosovo.",
        adm0_iso: "SRB",
        adm0_diff: null,
        adm0_tlc: "SRB",
        adm0_a3_us: "SRB",
        adm0_a3_fr: "SRB",
        adm0_a3_ru: "SRB",
        adm0_a3_es: "SRB",
        adm0_a3_cn: "SRB",
        adm0_a3_tw: "SRB",
        adm0_a3_in: "SRB",
        adm0_a3_np: "SRB",
        adm0_a3_pk: "SRB",
        adm0_a3_de: "SRB",
        adm0_a3_gb: "SRB",
        adm0_a3_br: "SRB",
        adm0_a3_il: "SRB",
        adm0_a3_ps: "SRB",
        adm0_a3_sa: "SRB",
        adm0_a3_eg: "SRB",
        adm0_a3_ma: "SRB",
        adm0_a3_pt: "SRB",
        adm0_a3_ar: "SRB",
        adm0_a3_jp: "SRB",
        adm0_a3_ko: "SRB",
        adm0_a3_vn: "SRB",
        adm0_a3_tr: "SRB",
        adm0_a3_id: "SRB",
        adm0_a3_pl: "SRB",
        adm0_a3_gr: "SRB",
        adm0_a3_it: "SRB",
        adm0_a3_nl: "SRB",
        adm0_a3_se: "SRB",
        adm0_a3_bd: "SRB",
        adm0_a3_ua: "SRB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 7,
        label_x: 20.787989,
        label_y: 44.189919,
        ne_id: 1159321267,
        wikidataid: "Q403",
        name_ar: "صربيا",
        name_bn: "সার্বিয়া",
        name_de: "Serbien",
        name_en: "Serbia",
        name_es: "Serbia",
        name_fa: "صربستان",
        name_fr: "Serbie",
        name_el: "Σερβία",
        name_he: "סרביה",
        name_hi: "सर्बिया",
        name_hu: "Szerbia",
        name_id: "Serbia",
        name_it: "Serbia",
        name_ja: "セルビア",
        name_ko: "세르비아",
        name_nl: "Servië",
        name_pl: "Serbia",
        name_pt: "Sérvia",
        name_ru: "Сербия",
        name_sv: "Serbien",
        name_tr: "Sırbistan",
        name_uk: "Сербія",
        name_ur: "سربیا",
        name_vi: "Serbia",
        name_zh: "塞尔维亚",
        name_zht: "塞爾維亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SRB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.829824792873946, 45.908872358025285],
            [18.82983808764996, 45.90887767189193],
            [19.596044549241583, 46.17172984474454],
            [20.220192498462836, 46.127468980486555],
            [20.762174920339987, 45.734573065771485],
            [20.874312778413355, 45.416375433934235],
            [21.48352623870221, 45.18117015235788],
            [21.56202273935361, 44.7689472519655],
            [22.14508792490281, 44.47842234962059],
            [22.459022251075936, 44.7025171982543],
            [22.705725538837356, 44.57800283464702],
            [22.4740084164406, 44.40922760678177],
            [22.65714969248299, 44.23492300066128],
            [22.410446404721597, 44.008063462899955],
            [22.500156691180223, 43.642814439461006],
            [22.986018507588483, 43.2111612005271],
            [22.60480146657133, 42.898518785161144],
            [22.43659467946128, 42.580321153323936],
            [22.54501183440962, 42.46136200618804],
            [22.380525750424592, 42.32025950781509],
            [21.917080000000112, 42.30364],
            [21.57663598940212, 42.24522439706186],
            [21.54332, 42.3202500000001],
            [21.66292, 42.43922],
            [21.77505, 42.6827],
            [21.63302, 42.67717],
            [21.43866, 42.86255],
            [21.27421, 42.90959],
            [21.143395, 43.06868500000013],
            [20.95651, 43.13094],
            [20.81448, 43.27205],
            [20.63508, 43.21671],
            [20.49679, 42.88469],
            [20.25758, 42.81275000000011],
            [20.3398, 42.89852],
            [19.95857, 43.10604],
            [19.63, 43.21377997027054],
            [19.48389, 43.35229],
            [19.21852, 43.52384],
            [19.454, 43.56810000000013],
            [19.59976, 44.03847],
            [19.11761, 44.42307000000011],
            [19.36803, 44.863],
            [19.00548, 44.86023],
            [19.005484597557594, 44.86023449354299],
            [19.39047570158459, 45.236515611342384],
            [19.072768995854176, 45.52151113543209],
            [18.829824792873946, 45.908872358025285],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Croatia",
        sov_a3: "HRV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Croatia",
        adm0_a3: "HRV",
        geou_dif: 0,
        geounit: "Croatia",
        gu_a3: "HRV",
        su_dif: 0,
        subunit: "Croatia",
        su_a3: "HRV",
        brk_diff: 0,
        name: "Croatia",
        name_long: "Croatia",
        brk_a3: "HRV",
        brk_name: "Croatia",
        brk_group: null,
        abbrev: "Cro.",
        postal: "HR",
        formal_en: "Republic of Croatia",
        formal_fr: null,
        name_ciawf: "Croatia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Croatia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 4,
        mapcolor9: 5,
        mapcolor13: 1,
        pop_est: 4067500,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 60752,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "HR",
        iso_a2: "HR",
        iso_a2_eh: "HR",
        iso_a3: "HRV",
        iso_a3_eh: "HRV",
        iso_n3: "191",
        iso_n3_eh: "191",
        un_a3: "191",
        wb_a2: "HR",
        wb_a3: "HRV",
        woe_id: 23424843,
        woe_id_eh: 23424843,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HRV",
        adm0_diff: null,
        adm0_tlc: "HRV",
        adm0_a3_us: "HRV",
        adm0_a3_fr: "HRV",
        adm0_a3_ru: "HRV",
        adm0_a3_es: "HRV",
        adm0_a3_cn: "HRV",
        adm0_a3_tw: "HRV",
        adm0_a3_in: "HRV",
        adm0_a3_np: "HRV",
        adm0_a3_pk: "HRV",
        adm0_a3_de: "HRV",
        adm0_a3_gb: "HRV",
        adm0_a3_br: "HRV",
        adm0_a3_il: "HRV",
        adm0_a3_ps: "HRV",
        adm0_a3_sa: "HRV",
        adm0_a3_eg: "HRV",
        adm0_a3_ma: "HRV",
        adm0_a3_pt: "HRV",
        adm0_a3_ar: "HRV",
        adm0_a3_jp: "HRV",
        adm0_a3_ko: "HRV",
        adm0_a3_vn: "HRV",
        adm0_a3_tr: "HRV",
        adm0_a3_id: "HRV",
        adm0_a3_pl: "HRV",
        adm0_a3_gr: "HRV",
        adm0_a3_it: "HRV",
        adm0_a3_nl: "HRV",
        adm0_a3_se: "HRV",
        adm0_a3_bd: "HRV",
        adm0_a3_ua: "HRV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 16.37241,
        label_y: 45.805799,
        ne_id: 1159320833,
        wikidataid: "Q224",
        name_ar: "كرواتيا",
        name_bn: "ক্রোয়েশিয়া",
        name_de: "Kroatien",
        name_en: "Croatia",
        name_es: "Croacia",
        name_fa: "کرواسی",
        name_fr: "Croatie",
        name_el: "Κροατία",
        name_he: "קרואטיה",
        name_hi: "क्रोएशिया",
        name_hu: "Horvátország",
        name_id: "Kroasia",
        name_it: "Croazia",
        name_ja: "クロアチア",
        name_ko: "크로아티아",
        name_nl: "Kroatië",
        name_pl: "Chorwacja",
        name_pt: "Croácia",
        name_ru: "Хорватия",
        name_sv: "Kroatien",
        name_tr: "Hırvatistan",
        name_uk: "Хорватія",
        name_ur: "کروشیا",
        name_vi: "Croatia",
        name_zh: "克罗地亚",
        name_zht: "克羅地亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HRV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.564808383864857, 46.50375092221983],
            [16.8825150895953, 46.38063182228444],
            [17.630066359129557, 45.95176911069419],
            [18.45606245288286, 45.75948110613615],
            [18.829824792873946, 45.908872358025285],
            [19.072768995854176, 45.52151113543209],
            [19.39047570158459, 45.236515611342384],
            [19.005484597557594, 44.86023449354299],
            [18.553214145591653, 45.08158966733146],
            [17.861783481526402, 45.067740383477144],
            [17.002146030351014, 45.23377676043094],
            [16.534939406000206, 45.21160757097772],
            [16.318156772535872, 45.00412669532591],
            [15.959367303133376, 45.23377676043094],
            [15.750026075918981, 44.818711656262565],
            [16.23966027188453, 44.35114329688571],
            [16.456442905348865, 44.04123973243128],
            [16.91615644701733, 43.66772247982567],
            [17.297373488034452, 43.44634064388737],
            [17.674921502358984, 43.02856252702361],
            [18.56, 42.65],
            [18.45001688302086, 42.47999224531218],
            [18.450016310304818, 42.47999136002932],
            [17.509970330483327, 42.849994615239154],
            [16.930005730871642, 43.20999848080038],
            [16.015384555737683, 43.50721548112722],
            [15.174453973052096, 44.243191229827914],
            [15.376250441151797, 44.31791535092208],
            [14.92030927904051, 44.73848399512946],
            [14.901602410550879, 45.07606028907611],
            [14.258747592839995, 45.23377676043094],
            [13.952254672917036, 44.80212352149687],
            [13.656975538801191, 45.13693512631596],
            [13.67940311041582, 45.48414907488501],
            [13.715059848697223, 45.500323798192376],
            [14.411968214585414, 45.46616567644746],
            [14.595109490627806, 45.634940904312714],
            [14.935243767972935, 45.471695054702685],
            [15.327674594797429, 45.45231639259333],
            [15.323953891672405, 45.73178253842768],
            [15.671529575267556, 45.83415355079788],
            [15.768732944408553, 46.23810822202345],
            [16.564808383864857, 46.50375092221983],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Slovenia",
        sov_a3: "SVN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Slovenia",
        adm0_a3: "SVN",
        geou_dif: 0,
        geounit: "Slovenia",
        gu_a3: "SVN",
        su_dif: 0,
        subunit: "Slovenia",
        su_a3: "SVN",
        brk_diff: 0,
        name: "Slovenia",
        name_long: "Slovenia",
        brk_a3: "SVN",
        brk_name: "Slovenia",
        brk_group: null,
        abbrev: "Slo.",
        postal: "SLO",
        formal_en: "Republic of Slovenia",
        formal_fr: null,
        name_ciawf: "Slovenia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Slovenia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 2087946,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 54174,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SI",
        iso_a2: "SI",
        iso_a2_eh: "SI",
        iso_a3: "SVN",
        iso_a3_eh: "SVN",
        iso_n3: "705",
        iso_n3_eh: "705",
        un_a3: "705",
        wb_a2: "SI",
        wb_a3: "SVN",
        woe_id: 23424945,
        woe_id_eh: 23424945,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SVN",
        adm0_diff: null,
        adm0_tlc: "SVN",
        adm0_a3_us: "SVN",
        adm0_a3_fr: "SVN",
        adm0_a3_ru: "SVN",
        adm0_a3_es: "SVN",
        adm0_a3_cn: "SVN",
        adm0_a3_tw: "SVN",
        adm0_a3_in: "SVN",
        adm0_a3_np: "SVN",
        adm0_a3_pk: "SVN",
        adm0_a3_de: "SVN",
        adm0_a3_gb: "SVN",
        adm0_a3_br: "SVN",
        adm0_a3_il: "SVN",
        adm0_a3_ps: "SVN",
        adm0_a3_sa: "SVN",
        adm0_a3_eg: "SVN",
        adm0_a3_ma: "SVN",
        adm0_a3_pt: "SVN",
        adm0_a3_ar: "SVN",
        adm0_a3_jp: "SVN",
        adm0_a3_ko: "SVN",
        adm0_a3_vn: "SVN",
        adm0_a3_tr: "SVN",
        adm0_a3_id: "SVN",
        adm0_a3_pl: "SVN",
        adm0_a3_gr: "SVN",
        adm0_a3_it: "SVN",
        adm0_a3_nl: "SVN",
        adm0_a3_se: "SVN",
        adm0_a3_bd: "SVN",
        adm0_a3_ua: "SVN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 14.915312,
        label_y: 46.06076,
        ne_id: 1159321285,
        wikidataid: "Q215",
        name_ar: "سلوفينيا",
        name_bn: "স্লোভেনিয়া",
        name_de: "Slowenien",
        name_en: "Slovenia",
        name_es: "Eslovenia",
        name_fa: "اسلوونی",
        name_fr: "Slovénie",
        name_el: "Σλοβενία",
        name_he: "סלובניה",
        name_hi: "स्लोवेनिया",
        name_hu: "Szlovénia",
        name_id: "Slovenia",
        name_it: "Slovenia",
        name_ja: "スロベニア",
        name_ko: "슬로베니아",
        name_nl: "Slovenië",
        name_pl: "Słowenia",
        name_pt: "Eslovénia",
        name_ru: "Словения",
        name_sv: "Slovenien",
        name_tr: "Slovenya",
        name_uk: "Словенія",
        name_ur: "سلووینیا",
        name_vi: "Slovenia",
        name_zh: "斯洛文尼亚",
        name_zht: "斯洛維尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SVN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.806475457421527, 46.509306138691215],
            [14.63247155117483, 46.43181732846955],
            [15.137091912504985, 46.65870270444703],
            [16.011663852612656, 46.6836107448117],
            [16.202298211337364, 46.85238597267696],
            [16.370504998447416, 46.841327216166505],
            [16.564808383864857, 46.50375092221983],
            [15.768732944408553, 46.23810822202345],
            [15.671529575267556, 45.83415355079788],
            [15.323953891672405, 45.73178253842768],
            [15.327674594797429, 45.45231639259333],
            [14.935243767972935, 45.471695054702685],
            [14.595109490627806, 45.634940904312714],
            [14.411968214585414, 45.46616567644746],
            [13.715059848697223, 45.500323798192376],
            [13.937630242578308, 45.59101593686462],
            [13.698109978905478, 46.01677806251735],
            [13.806475457421527, 46.509306138691215],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Bulgaria",
        sov_a3: "BGR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bulgaria",
        adm0_a3: "BGR",
        geou_dif: 0,
        geounit: "Bulgaria",
        gu_a3: "BGR",
        su_dif: 0,
        subunit: "Bulgaria",
        su_a3: "BGR",
        brk_diff: 0,
        name: "Bulgaria",
        name_long: "Bulgaria",
        brk_a3: "BGR",
        brk_name: "Bulgaria",
        brk_group: null,
        abbrev: "Bulg.",
        postal: "BG",
        formal_en: "Republic of Bulgaria",
        formal_fr: null,
        name_ciawf: "Bulgaria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bulgaria",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 6975761,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 68558,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "BU",
        iso_a2: "BG",
        iso_a2_eh: "BG",
        iso_a3: "BGR",
        iso_a3_eh: "BGR",
        iso_n3: "100",
        iso_n3_eh: "100",
        un_a3: "100",
        wb_a2: "BG",
        wb_a3: "BGR",
        woe_id: 23424771,
        woe_id_eh: 23424771,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BGR",
        adm0_diff: null,
        adm0_tlc: "BGR",
        adm0_a3_us: "BGR",
        adm0_a3_fr: "BGR",
        adm0_a3_ru: "BGR",
        adm0_a3_es: "BGR",
        adm0_a3_cn: "BGR",
        adm0_a3_tw: "BGR",
        adm0_a3_in: "BGR",
        adm0_a3_np: "BGR",
        adm0_a3_pk: "BGR",
        adm0_a3_de: "BGR",
        adm0_a3_gb: "BGR",
        adm0_a3_br: "BGR",
        adm0_a3_il: "BGR",
        adm0_a3_ps: "BGR",
        adm0_a3_sa: "BGR",
        adm0_a3_eg: "BGR",
        adm0_a3_ma: "BGR",
        adm0_a3_pt: "BGR",
        adm0_a3_ar: "BGR",
        adm0_a3_jp: "BGR",
        adm0_a3_ko: "BGR",
        adm0_a3_vn: "BGR",
        adm0_a3_tr: "BGR",
        adm0_a3_id: "BGR",
        adm0_a3_pl: "BGR",
        adm0_a3_gr: "BGR",
        adm0_a3_it: "BGR",
        adm0_a3_nl: "BGR",
        adm0_a3_se: "BGR",
        adm0_a3_bd: "BGR",
        adm0_a3_ua: "BGR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 25.15709,
        label_y: 42.508785,
        ne_id: 1159320409,
        wikidataid: "Q219",
        name_ar: "بلغاريا",
        name_bn: "বুলগেরিয়া",
        name_de: "Bulgarien",
        name_en: "Bulgaria",
        name_es: "Bulgaria",
        name_fa: "بلغارستان",
        name_fr: "Bulgarie",
        name_el: "Βουλγαρία",
        name_he: "בולגריה",
        name_hi: "बुल्गारिया",
        name_hu: "Bulgária",
        name_id: "Bulgaria",
        name_it: "Bulgaria",
        name_ja: "ブルガリア",
        name_ko: "불가리아",
        name_nl: "Bulgarije",
        name_pl: "Bułgaria",
        name_pt: "Bulgária",
        name_ru: "Болгария",
        name_sv: "Bulgarien",
        name_tr: "Bulgaristan",
        name_uk: "Болгарія",
        name_ur: "بلغاریہ",
        name_vi: "Bulgaria",
        name_zh: "保加利亚",
        name_zht: "保加利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BGR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.65714969248299, 44.23492300066128],
            [22.944832391051847, 43.82378530534713],
            [23.332302280376325, 43.897010809904714],
            [24.100679152124172, 43.74105133724785],
            [25.569271681426926, 43.68844472917472],
            [26.065158725699746, 43.94349376075127],
            [27.242399529740908, 44.175986029632405],
            [27.970107049275075, 43.81246816667522],
            [28.558081495891997, 43.70746165625813],
            [28.03909508638472, 43.293171698574184],
            [27.67389773937805, 42.57789236100622],
            [27.99672041190539, 42.00735871028779],
            [27.13573937349048, 42.14148489030134],
            [26.1170418637208, 41.82690460872456],
            [26.106138136507127, 41.32889883072784],
            [25.197201368925448, 41.23448598893053],
            [24.492644891057978, 41.58389618587205],
            [23.692073601992348, 41.309080918943856],
            [22.952377150166452, 41.33799388281115],
            [22.881373732197346, 41.999297186850356],
            [22.380525750424592, 42.32025950781509],
            [22.54501183440962, 42.46136200618804],
            [22.43659467946128, 42.580321153323936],
            [22.60480146657133, 42.898518785161144],
            [22.986018507588483, 43.2111612005271],
            [22.500156691180223, 43.642814439461006],
            [22.410446404721597, 44.008063462899955],
            [22.65714969248299, 44.23492300066128],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Montenegro",
        sov_a3: "MNE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Montenegro",
        adm0_a3: "MNE",
        geou_dif: 0,
        geounit: "Montenegro",
        gu_a3: "MNE",
        su_dif: 0,
        subunit: "Montenegro",
        su_a3: "MNE",
        brk_diff: 0,
        name: "Montenegro",
        name_long: "Montenegro",
        brk_a3: "MNE",
        brk_name: "Montenegro",
        brk_group: null,
        abbrev: "Mont.",
        postal: "ME",
        formal_en: "Montenegro",
        formal_fr: null,
        name_ciawf: "Montenegro",
        note_adm0: null,
        note_brk: null,
        name_sort: "Montenegro",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 5,
        pop_est: 622137,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 5542,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "MJ",
        iso_a2: "ME",
        iso_a2_eh: "ME",
        iso_a3: "MNE",
        iso_a3_eh: "MNE",
        iso_n3: "499",
        iso_n3_eh: "499",
        un_a3: "499",
        wb_a2: "ME",
        wb_a3: "MNE",
        woe_id: 20069817,
        woe_id_eh: 20069817,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MNE",
        adm0_diff: null,
        adm0_tlc: "MNE",
        adm0_a3_us: "MNE",
        adm0_a3_fr: "MNE",
        adm0_a3_ru: "MNE",
        adm0_a3_es: "MNE",
        adm0_a3_cn: "MNE",
        adm0_a3_tw: "MNE",
        adm0_a3_in: "MNE",
        adm0_a3_np: "MNE",
        adm0_a3_pk: "MNE",
        adm0_a3_de: "MNE",
        adm0_a3_gb: "MNE",
        adm0_a3_br: "MNE",
        adm0_a3_il: "MNE",
        adm0_a3_ps: "MNE",
        adm0_a3_sa: "MNE",
        adm0_a3_eg: "MNE",
        adm0_a3_ma: "MNE",
        adm0_a3_pt: "MNE",
        adm0_a3_ar: "MNE",
        adm0_a3_jp: "MNE",
        adm0_a3_ko: "MNE",
        adm0_a3_vn: "MNE",
        adm0_a3_tr: "MNE",
        adm0_a3_id: "MNE",
        adm0_a3_pl: "MNE",
        adm0_a3_gr: "MNE",
        adm0_a3_it: "MNE",
        adm0_a3_nl: "MNE",
        adm0_a3_se: "MNE",
        adm0_a3_bd: "MNE",
        adm0_a3_ua: "MNE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 19.143727,
        label_y: 42.803101,
        ne_id: 1159321069,
        wikidataid: "Q236",
        name_ar: "الجبل الأسود",
        name_bn: "মন্টিনিগ্রো",
        name_de: "Montenegro",
        name_en: "Montenegro",
        name_es: "Montenegro",
        name_fa: "مونتهنگرو",
        name_fr: "Monténégro",
        name_el: "Μαυροβούνιο",
        name_he: "מונטנגרו",
        name_hi: "मॉन्टेनीग्रो",
        name_hu: "Montenegró",
        name_id: "Montenegro",
        name_it: "Montenegro",
        name_ja: "モンテネグロ",
        name_ko: "몬테네그로",
        name_nl: "Montenegro",
        name_pl: "Czarnogóra",
        name_pt: "Montenegro",
        name_ru: "Черногория",
        name_sv: "Montenegro",
        name_tr: "Karadağ",
        name_uk: "Чорногорія",
        name_ur: "مونٹینیگرو",
        name_vi: "Montenegro",
        name_zh: "黑山",
        name_zht: "蒙特內哥羅",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MNE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.0707, 42.58863],
            [19.801613396898688, 42.50009349219084],
            [19.73805138517963, 42.68824738216557],
            [19.304486118250793, 42.19574514420782],
            [19.37176816334725, 41.877550679783496],
            [19.16246, 41.95502],
            [18.88214, 42.28151],
            [18.45001688302086, 42.47999224531218],
            [18.56, 42.65],
            [18.70648, 43.20011],
            [19.03165, 43.43253],
            [19.21852, 43.52384],
            [19.48389, 43.35229],
            [19.63, 43.21377997027054],
            [19.95857, 43.10604],
            [20.3398, 42.89852],
            [20.25758, 42.81275000000011],
            [20.0707, 42.58863],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Bosnia and Herzegovina",
        sov_a3: "BIH",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bosnia and Herzegovina",
        adm0_a3: "BIH",
        geou_dif: 0,
        geounit: "Bosnia and Herzegovina",
        gu_a3: "BIH",
        su_dif: 0,
        subunit: "Bosnia and Herzegovina",
        su_a3: "BIH",
        brk_diff: 0,
        name: "Bosnia and Herz.",
        name_long: "Bosnia and Herzegovina",
        brk_a3: "BIH",
        brk_name: "Bosnia and Herz.",
        brk_group: null,
        abbrev: "B.H.",
        postal: "BiH",
        formal_en: "Bosnia and Herzegovina",
        formal_fr: null,
        name_ciawf: "Bosnia and Herzegovina",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bosnia and Herzegovina",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 2,
        pop_est: 3301000,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 20164,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "BK",
        iso_a2: "BA",
        iso_a2_eh: "BA",
        iso_a3: "BIH",
        iso_a3_eh: "BIH",
        iso_n3: "070",
        iso_n3_eh: "070",
        un_a3: "070",
        wb_a2: "BA",
        wb_a3: "BIH",
        woe_id: 23424761,
        woe_id_eh: 23424761,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BIH",
        adm0_diff: null,
        adm0_tlc: "BIH",
        adm0_a3_us: "BIH",
        adm0_a3_fr: "BIH",
        adm0_a3_ru: "BIH",
        adm0_a3_es: "BIH",
        adm0_a3_cn: "BIH",
        adm0_a3_tw: "BIH",
        adm0_a3_in: "BIH",
        adm0_a3_np: "BIH",
        adm0_a3_pk: "BIH",
        adm0_a3_de: "BIH",
        adm0_a3_gb: "BIH",
        adm0_a3_br: "BIH",
        adm0_a3_il: "BIH",
        adm0_a3_ps: "BIH",
        adm0_a3_sa: "BIH",
        adm0_a3_eg: "BIH",
        adm0_a3_ma: "BIH",
        adm0_a3_pt: "BIH",
        adm0_a3_ar: "BIH",
        adm0_a3_jp: "BIH",
        adm0_a3_ko: "BIH",
        adm0_a3_vn: "BIH",
        adm0_a3_tr: "BIH",
        adm0_a3_id: "BIH",
        adm0_a3_pl: "BIH",
        adm0_a3_gr: "BIH",
        adm0_a3_it: "BIH",
        adm0_a3_nl: "BIH",
        adm0_a3_se: "BIH",
        adm0_a3_bd: "BIH",
        adm0_a3_ua: "BIH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 16,
        long_len: 22,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 6.8,
        label_x: 18.06841,
        label_y: 44.091051,
        ne_id: 1159320417,
        wikidataid: "Q225",
        name_ar: "البوسنة والهرسك",
        name_bn: "বসনিয়া ও হার্জেগোভিনা",
        name_de: "Bosnien und Herzegowina",
        name_en: "Bosnia and Herzegovina",
        name_es: "Bosnia y Herzegovina",
        name_fa: "بوسنی و هرزگوین",
        name_fr: "Bosnie-Herzégovine",
        name_el: "Βοσνία και Ερζεγοβίνη",
        name_he: "בוסניה והרצגובינה",
        name_hi: "बॉस्निया और हर्ज़ेगोविना",
        name_hu: "Bosznia-Hercegovina",
        name_id: "Bosnia dan Herzegovina",
        name_it: "Bosnia ed Erzegovina",
        name_ja: "ボスニア・ヘルツェゴビナ",
        name_ko: "보스니아 헤르체고비나",
        name_nl: "Bosnië en Herzegovina",
        name_pl: "Bośnia i Hercegowina",
        name_pt: "Bósnia e Herzegovina",
        name_ru: "Босния и Герцеговина",
        name_sv: "Bosnien och Hercegovina",
        name_tr: "Bosna-Hersek",
        name_uk: "Боснія і Герцеговина",
        name_ur: "بوسنیا و ہرزیگووینا",
        name_vi: "Bosna và Hercegovina",
        name_zh: "波斯尼亚和黑塞哥维那",
        name_zht: "波士尼亞與赫塞哥維納",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BIH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.56, 42.65],
            [17.674921502358984, 43.02856252702361],
            [17.297373488034452, 43.44634064388737],
            [16.91615644701733, 43.66772247982567],
            [16.456442905348865, 44.04123973243128],
            [16.23966027188453, 44.35114329688571],
            [15.750026075918981, 44.818711656262565],
            [15.959367303133376, 45.23377676043094],
            [16.318156772535872, 45.00412669532591],
            [16.534939406000206, 45.21160757097772],
            [17.002146030351014, 45.23377676043094],
            [17.861783481526402, 45.067740383477144],
            [18.553214145591653, 45.08158966733146],
            [19.005484597557594, 44.86023449354299],
            [19.00548, 44.86023],
            [19.36803, 44.863],
            [19.11761, 44.42307000000011],
            [19.59976, 44.03847],
            [19.454, 43.56810000000013],
            [19.21852, 43.52384],
            [19.03165, 43.43253],
            [18.70648, 43.20011],
            [18.56, 42.65],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Portugal",
        sov_a3: "PRT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Portugal",
        adm0_a3: "PRT",
        geou_dif: 0,
        geounit: "Portugal",
        gu_a3: "PRT",
        su_dif: 1,
        subunit: "Portugal",
        su_a3: "PR1",
        brk_diff: 0,
        name: "Portugal",
        name_long: "Portugal",
        brk_a3: "PR1",
        brk_name: "Portugal",
        brk_group: null,
        abbrev: "Port.",
        postal: "P",
        formal_en: "Portuguese Republic",
        formal_fr: null,
        name_ciawf: "Portugal",
        note_adm0: null,
        note_brk: null,
        name_sort: "Portugal",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 7,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 10269417,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 238785,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "PO",
        iso_a2: "PT",
        iso_a2_eh: "PT",
        iso_a3: "PRT",
        iso_a3_eh: "PRT",
        iso_n3: "620",
        iso_n3_eh: "620",
        un_a3: "620",
        wb_a2: "PT",
        wb_a3: "PRT",
        woe_id: 23424925,
        woe_id_eh: 23424925,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PR1",
        adm0_diff: null,
        adm0_tlc: "PR1",
        adm0_a3_us: "PRT",
        adm0_a3_fr: "PRT",
        adm0_a3_ru: "PRT",
        adm0_a3_es: "PRT",
        adm0_a3_cn: "PRT",
        adm0_a3_tw: "PRT",
        adm0_a3_in: "PRT",
        adm0_a3_np: "PRT",
        adm0_a3_pk: "PRT",
        adm0_a3_de: "PRT",
        adm0_a3_gb: "PRT",
        adm0_a3_br: "PRT",
        adm0_a3_il: "PRT",
        adm0_a3_ps: "PRT",
        adm0_a3_sa: "PRT",
        adm0_a3_eg: "PRT",
        adm0_a3_ma: "PRT",
        adm0_a3_pt: "PRT",
        adm0_a3_ar: "PRT",
        adm0_a3_jp: "PRT",
        adm0_a3_ko: "PRT",
        adm0_a3_vn: "PRT",
        adm0_a3_tr: "PRT",
        adm0_a3_id: "PRT",
        adm0_a3_pl: "PRT",
        adm0_a3_gr: "PRT",
        adm0_a3_it: "PRT",
        adm0_a3_nl: "PRT",
        adm0_a3_se: "PRT",
        adm0_a3_bd: "PRT",
        adm0_a3_ua: "PRT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -8.271754,
        label_y: 39.606675,
        ne_id: 1159321187,
        wikidataid: "Q45",
        name_ar: "البرتغال",
        name_bn: "পর্তুগাল",
        name_de: "Portugal",
        name_en: "Portugal",
        name_es: "Portugal",
        name_fa: "پرتغال",
        name_fr: "Portugal",
        name_el: "Πορτογαλία",
        name_he: "פורטוגל",
        name_hi: "पुर्तगाल",
        name_hu: "Portugália",
        name_id: "Portugal",
        name_it: "Portogallo",
        name_ja: "ポルトガル",
        name_ko: "포르투갈",
        name_nl: "Portugal",
        name_pl: "Portugalia",
        name_pt: "Portugal",
        name_ru: "Португалия",
        name_sv: "Portugal",
        name_tr: "Portekiz",
        name_uk: "Португалія",
        name_ur: "پرتگال",
        name_vi: "Bồ Đào Nha",
        name_zh: "葡萄牙",
        name_zht: "葡萄牙",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.034817674180246, 41.880570583659676],
            [-8.67194576662672, 42.13468943945496],
            [-8.263856980817792, 42.28046865495034],
            [-8.013174607769912, 41.790886135417125],
            [-7.422512986673795, 41.79207469335984],
            [-7.251308966490824, 41.91834605566505],
            [-6.668605515967656, 41.883386949219584],
            [-6.389087693700915, 41.381815497394655],
            [-6.851126674822552, 41.11108266861753],
            [-6.864019944679385, 40.33087189387483],
            [-7.026413133156595, 40.184524237624245],
            [-7.066591559263529, 39.711891587882775],
            [-7.498632371439726, 39.62957103124181],
            [-7.098036668313128, 39.03007274022379],
            [-7.374092169616318, 38.37305858006492],
            [-7.029281175148796, 38.07576406508977],
            [-7.166507941099865, 37.803894354802225],
            [-7.537105475281024, 37.42890432387624],
            [-7.453725551778092, 37.09778758396607],
            [-7.855613165711986, 36.83826854099627],
            [-8.382816127953689, 36.97888011326246],
            [-8.898856980820327, 36.86880931248078],
            [-8.746101446965554, 37.65134552667661],
            [-8.83999752443988, 38.266243394517616],
            [-9.287463751655224, 38.3584858261586],
            [-9.526570603869715, 38.73742910415491],
            [-9.446988898140233, 39.39206614842837],
            [-9.048305223008427, 39.75509308527877],
            [-8.977353481471681, 40.15930613866581],
            [-8.768684047877102, 40.76063894303019],
            [-8.79085323733031, 41.18433401139126],
            [-8.99078935386757, 41.54345937760364],
            [-9.034817674180246, 41.880570583659676],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Moldova",
        sov_a3: "MDA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Moldova",
        adm0_a3: "MDA",
        geou_dif: 0,
        geounit: "Moldova",
        gu_a3: "MDA",
        su_dif: 0,
        subunit: "Moldova",
        su_a3: "MDA",
        brk_diff: 0,
        name: "Moldova",
        name_long: "Moldova",
        brk_a3: "MDA",
        brk_name: "Moldova",
        brk_group: null,
        abbrev: "Mda.",
        postal: "MD",
        formal_en: "Republic of Moldova",
        formal_fr: null,
        name_ciawf: "Moldova",
        note_adm0: null,
        note_brk: null,
        name_sort: "Moldova",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 4,
        mapcolor13: 12,
        pop_est: 2657637,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 11968,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MD",
        iso_a2: "MD",
        iso_a2_eh: "MD",
        iso_a3: "MDA",
        iso_a3_eh: "MDA",
        iso_n3: "498",
        iso_n3_eh: "498",
        un_a3: "498",
        wb_a2: "MD",
        wb_a3: "MDA",
        woe_id: 23424885,
        woe_id_eh: 23424885,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MDA",
        adm0_diff: null,
        adm0_tlc: "MDA",
        adm0_a3_us: "MDA",
        adm0_a3_fr: "MDA",
        adm0_a3_ru: "MDA",
        adm0_a3_es: "MDA",
        adm0_a3_cn: "MDA",
        adm0_a3_tw: "MDA",
        adm0_a3_in: "MDA",
        adm0_a3_np: "MDA",
        adm0_a3_pk: "MDA",
        adm0_a3_de: "MDA",
        adm0_a3_gb: "MDA",
        adm0_a3_br: "MDA",
        adm0_a3_il: "MDA",
        adm0_a3_ps: "MDA",
        adm0_a3_sa: "MDA",
        adm0_a3_eg: "MDA",
        adm0_a3_ma: "MDA",
        adm0_a3_pt: "MDA",
        adm0_a3_ar: "MDA",
        adm0_a3_jp: "MDA",
        adm0_a3_ko: "MDA",
        adm0_a3_vn: "MDA",
        adm0_a3_tr: "MDA",
        adm0_a3_id: "MDA",
        adm0_a3_pl: "MDA",
        adm0_a3_gr: "MDA",
        adm0_a3_it: "MDA",
        adm0_a3_nl: "MDA",
        adm0_a3_se: "MDA",
        adm0_a3_bd: "MDA",
        adm0_a3_ua: "MDA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 28.487904,
        label_y: 47.434999,
        ne_id: 1159321045,
        wikidataid: "Q217",
        name_ar: "مولدوفا",
        name_bn: "মলদোভা",
        name_de: "Republik Moldau",
        name_en: "Moldova",
        name_es: "Moldavia",
        name_fa: "مولداوی",
        name_fr: "Moldavie",
        name_el: "Μολδαβία",
        name_he: "מולדובה",
        name_hi: "मॉल्डोवा",
        name_hu: "Moldova",
        name_id: "Moldova",
        name_it: "Moldavia",
        name_ja: "モルドバ",
        name_ko: "몰도바",
        name_nl: "Moldavië",
        name_pl: "Mołdawia",
        name_pt: "Moldávia",
        name_ru: "Молдавия",
        name_sv: "Moldavien",
        name_tr: "Moldova",
        name_uk: "Молдова",
        name_ur: "مالدووا",
        name_vi: "Moldova",
        name_zh: "摩尔多瓦",
        name_zht: "摩爾多瓦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MDA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.619336785597795, 48.22072622333347],
            [26.857823520624805, 48.368210761094495],
            [27.522537469195157, 48.467119452501116],
            [28.259546746541844, 48.15556224221342],
            [28.670891147585166, 48.1181485052341],
            [29.12269819511303, 47.849095160506465],
            [29.05086795422727, 47.5102269557525],
            [29.415135125452736, 47.34664520933258],
            [29.559674106573112, 46.928582872091326],
            [29.908851759569302, 46.67436066343146],
            [29.838210076626297, 46.52532583270169],
            [30.024658644335375, 46.42393667254504],
            [29.759971958136394, 46.34998769793536],
            [29.170653924279804, 46.37926239682872],
            [29.07210696789929, 46.517677720722496],
            [28.862972446414062, 46.43788930926383],
            [28.933717482221596, 46.25883047137256],
            [28.65998742037158, 45.93998688413164],
            [28.485269402792767, 45.5969070501459],
            [28.23355350109904, 45.48828318946829],
            [28.0544429867754, 45.944586086605625],
            [28.160017937947714, 46.37156260841722],
            [28.128030226359044, 46.810476386088254],
            [27.551166212684848, 47.40511709247083],
            [27.233872918412743, 47.82677094175638],
            [26.924176059687568, 48.123264472030996],
            [26.619336785597795, 48.22072622333347],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Iceland",
        sov_a3: "ISL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Iceland",
        adm0_a3: "ISL",
        geou_dif: 0,
        geounit: "Iceland",
        gu_a3: "ISL",
        su_dif: 0,
        subunit: "Iceland",
        su_a3: "ISL",
        brk_diff: 0,
        name: "Iceland",
        name_long: "Iceland",
        brk_a3: "ISL",
        brk_name: "Iceland",
        brk_group: null,
        abbrev: "Iceland",
        postal: "IS",
        formal_en: "Republic of Iceland",
        formal_fr: null,
        name_ciawf: "Iceland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Iceland",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 361313,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 24188,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "IC",
        iso_a2: "IS",
        iso_a2_eh: "IS",
        iso_a3: "ISL",
        iso_a3_eh: "ISL",
        iso_n3: "352",
        iso_n3_eh: "352",
        un_a3: "352",
        wb_a2: "IS",
        wb_a3: "ISL",
        woe_id: 23424845,
        woe_id_eh: 23424845,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ISL",
        adm0_diff: null,
        adm0_tlc: "ISL",
        adm0_a3_us: "ISL",
        adm0_a3_fr: "ISL",
        adm0_a3_ru: "ISL",
        adm0_a3_es: "ISL",
        adm0_a3_cn: "ISL",
        adm0_a3_tw: "ISL",
        adm0_a3_in: "ISL",
        adm0_a3_np: "ISL",
        adm0_a3_pk: "ISL",
        adm0_a3_de: "ISL",
        adm0_a3_gb: "ISL",
        adm0_a3_br: "ISL",
        adm0_a3_il: "ISL",
        adm0_a3_ps: "ISL",
        adm0_a3_sa: "ISL",
        adm0_a3_eg: "ISL",
        adm0_a3_ma: "ISL",
        adm0_a3_pt: "ISL",
        adm0_a3_ar: "ISL",
        adm0_a3_jp: "ISL",
        adm0_a3_ko: "ISL",
        adm0_a3_vn: "ISL",
        adm0_a3_tr: "ISL",
        adm0_a3_id: "ISL",
        adm0_a3_pl: "ISL",
        adm0_a3_gr: "ISL",
        adm0_a3_it: "ISL",
        adm0_a3_nl: "ISL",
        adm0_a3_se: "ISL",
        adm0_a3_bd: "ISL",
        adm0_a3_ua: "ISL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -18.673711,
        label_y: 64.779286,
        ne_id: 1159320917,
        wikidataid: "Q189",
        name_ar: "آيسلندا",
        name_bn: "আইসল্যান্ড",
        name_de: "Island",
        name_en: "Iceland",
        name_es: "Islandia",
        name_fa: "ایسلند",
        name_fr: "Islande",
        name_el: "Ισλανδία",
        name_he: "איסלנד",
        name_hi: "आइसलैण्ड",
        name_hu: "Izland",
        name_id: "Islandia",
        name_it: "Islanda",
        name_ja: "アイスランド",
        name_ko: "아이슬란드",
        name_nl: "IJsland",
        name_pl: "Islandia",
        name_pt: "Islândia",
        name_ru: "Исландия",
        name_sv: "Island",
        name_tr: "İzlanda",
        name_uk: "Ісландія",
        name_ur: "آئس لینڈ",
        name_vi: "Iceland",
        name_zh: "冰岛",
        name_zht: "冰島",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ISL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.508695441129234, 66.45589223903143],
            [-14.739637417041607, 65.8087482774403],
            [-13.60973222497981, 65.12667104761987],
            [-14.909833746794902, 64.36408193628868],
            [-17.794438035543422, 63.678749091233854],
            [-18.656245896874992, 63.49638296167582],
            [-19.97275468594276, 63.64363495549153],
            [-22.762971971110158, 63.960178941495386],
            [-21.778484259517683, 64.40211579045551],
            [-23.95504391121911, 64.8911298692335],
            [-22.184402635170358, 65.0849681667603],
            [-22.227423265053332, 65.37859365504274],
            [-24.326184047939336, 65.61118927678847],
            [-23.65051469572309, 66.26251902939522],
            [-22.134922451250887, 66.41046865504687],
            [-20.57628373867955, 65.73211212835143],
            [-19.05684160000159, 66.27660085719477],
            [-17.79862382655905, 65.99385325790978],
            [-16.167818976292125, 66.52679230413587],
            [-14.508695441129234, 66.45589223903143],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Ethiopia",
        sov_a3: "ETH",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ethiopia",
        adm0_a3: "ETH",
        geou_dif: 0,
        geounit: "Ethiopia",
        gu_a3: "ETH",
        su_dif: 0,
        subunit: "Ethiopia",
        su_a3: "ETH",
        brk_diff: 0,
        name: "Ethiopia",
        name_long: "Ethiopia",
        brk_a3: "ETH",
        brk_name: "Ethiopia",
        brk_group: null,
        abbrev: "Eth.",
        postal: "ET",
        formal_en: "Federal Democratic Republic of Ethiopia",
        formal_fr: null,
        name_ciawf: "Ethiopia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ethiopia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 13,
        pop_est: 112078730,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 95912,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "ET",
        iso_a2: "ET",
        iso_a2_eh: "ET",
        iso_a3: "ETH",
        iso_a3_eh: "ETH",
        iso_n3: "231",
        iso_n3_eh: "231",
        un_a3: "231",
        wb_a2: "ET",
        wb_a3: "ETH",
        woe_id: 23424808,
        woe_id_eh: 23424808,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ETH",
        adm0_diff: null,
        adm0_tlc: "ETH",
        adm0_a3_us: "ETH",
        adm0_a3_fr: "ETH",
        adm0_a3_ru: "ETH",
        adm0_a3_es: "ETH",
        adm0_a3_cn: "ETH",
        adm0_a3_tw: "ETH",
        adm0_a3_in: "ETH",
        adm0_a3_np: "ETH",
        adm0_a3_pk: "ETH",
        adm0_a3_de: "ETH",
        adm0_a3_gb: "ETH",
        adm0_a3_br: "ETH",
        adm0_a3_il: "ETH",
        adm0_a3_ps: "ETH",
        adm0_a3_sa: "ETH",
        adm0_a3_eg: "ETH",
        adm0_a3_ma: "ETH",
        adm0_a3_pt: "ETH",
        adm0_a3_ar: "ETH",
        adm0_a3_jp: "ETH",
        adm0_a3_ko: "ETH",
        adm0_a3_vn: "ETH",
        adm0_a3_tr: "ETH",
        adm0_a3_id: "ETH",
        adm0_a3_pl: "ETH",
        adm0_a3_gr: "ETH",
        adm0_a3_it: "ETH",
        adm0_a3_nl: "ETH",
        adm0_a3_se: "ETH",
        adm0_a3_bd: "ETH",
        adm0_a3_ua: "ETH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 39.0886,
        label_y: 8.032795,
        ne_id: 1159320617,
        wikidataid: "Q115",
        name_ar: "إثيوبيا",
        name_bn: "ইথিওপিয়া",
        name_de: "Äthiopien",
        name_en: "Ethiopia",
        name_es: "Etiopía",
        name_fa: "اتیوپی",
        name_fr: "Éthiopie",
        name_el: "Αιθιοπία",
        name_he: "אתיופיה",
        name_hi: "इथियोपिया",
        name_hu: "Etiópia",
        name_id: "Ethiopia",
        name_it: "Etiopia",
        name_ja: "エチオピア",
        name_ko: "에티오피아",
        name_nl: "Ethiopië",
        name_pl: "Etiopia",
        name_pt: "Etiópia",
        name_ru: "Эфиопия",
        name_sv: "Etiopien",
        name_tr: "Etiyopya",
        name_uk: "Ефіопія",
        name_ur: "ایتھوپیا",
        name_vi: "Ethiopia",
        name_zh: "埃塞俄比亚",
        name_zht: "衣索比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ETH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [47.78942, 8.003],
            [44.9636, 5.00162],
            [43.66087, 4.95755],
            [42.76967, 4.25259],
            [42.12861, 4.23413],
            [41.85508309264397, 3.918911920483727],
            [41.1718, 3.91909],
            [40.76848, 4.25702],
            [39.85494, 3.83879],
            [39.55938425876585, 3.42206],
            [38.89251, 3.50074],
            [38.67114, 3.61607],
            [38.43697, 3.58851],
            [38.120915, 3.598605],
            [36.85509323800812, 4.447864127672769],
            [36.159078632855646, 4.447864127672769],
            [35.817447662353516, 4.77696566346189],
            [35.817447662353516, 5.338232082790797],
            [35.29800711823298, 5.506],
            [34.70702, 6.594220000000121],
            [34.25032, 6.82607],
            [34.0751, 7.22595],
            [33.568290000000104, 7.71334],
            [32.95418, 7.784970000000101],
            [33.29480000000012, 8.35458],
            [33.82550000000015, 8.37916],
            [33.97498, 8.68456],
            [33.96162, 9.58358],
            [34.25745, 10.63009],
            [34.73115000000013, 10.910170000000107],
            [34.83163000000013, 11.318960000000118],
            [35.26049, 12.08286],
            [35.86363, 12.57828],
            [36.27022, 13.563330000000121],
            [36.42951, 14.42211],
            [37.59377, 14.2131],
            [37.90607000000011, 14.959430000000168],
            [38.51295, 14.50547],
            [39.0994, 14.74064],
            [39.34061, 14.53155],
            [40.02625000000012, 14.51959],
            [40.8966, 14.118640000000141],
            [41.1552, 13.77333],
            [41.59856, 13.452090000000112],
            [42.00975, 12.86582],
            [42.35156000000012, 12.542230000000131],
            [42.000000000000114, 12.100000000000136],
            [41.66176000000013, 11.6312],
            [41.73959000000019, 11.355110000000138],
            [41.755570000000205, 11.050910000000101],
            [42.31414000000012, 11.0342],
            [42.55493000000013, 11.105110000000195],
            [42.77685184100096, 10.92687856693442],
            [42.55876, 10.57258000000013],
            [42.92812, 10.021940000000143],
            [43.29699000000011, 9.540480000000173],
            [43.67875, 9.18358000000012],
            [46.94834, 7.99688],
            [47.78942, 8.003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "South Sudan",
        sov_a3: "SDS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "South Sudan",
        adm0_a3: "SDS",
        geou_dif: 0,
        geounit: "South Sudan",
        gu_a3: "SDS",
        su_dif: 0,
        subunit: "South Sudan",
        su_a3: "SDS",
        brk_diff: 0,
        name: "S. Sudan",
        name_long: "South Sudan",
        brk_a3: "SDS",
        brk_name: "S. Sudan",
        brk_group: null,
        abbrev: "S. Sud.",
        postal: "SS",
        formal_en: "Republic of South Sudan",
        formal_fr: null,
        name_ciawf: "South Sudan",
        note_adm0: null,
        note_brk: null,
        name_sort: "South Sudan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 5,
        pop_est: 11062113,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 11998,
        gdp_year: 2015,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "-99",
        iso_a2: "SS",
        iso_a2_eh: "SS",
        iso_a3: "SSD",
        iso_a3_eh: "SSD",
        iso_n3: "728",
        iso_n3_eh: "728",
        un_a3: "728",
        wb_a2: "SS",
        wb_a3: "SSD",
        woe_id: -99,
        woe_id_eh: -99,
        woe_note:
          "Includes states of 20069899, 20069897, 20069898, 20069901, 20069909, and 20069908 but maybe more?",
        adm0_iso: "SSD",
        adm0_diff: "1",
        adm0_tlc: "SDS",
        adm0_a3_us: "SDS",
        adm0_a3_fr: "SDS",
        adm0_a3_ru: "SDS",
        adm0_a3_es: "SDS",
        adm0_a3_cn: "SDS",
        adm0_a3_tw: "SDS",
        adm0_a3_in: "SDS",
        adm0_a3_np: "SDS",
        adm0_a3_pk: "SDS",
        adm0_a3_de: "SDS",
        adm0_a3_gb: "SDS",
        adm0_a3_br: "SDS",
        adm0_a3_il: "SDS",
        adm0_a3_ps: "SDS",
        adm0_a3_sa: "SDS",
        adm0_a3_eg: "SDS",
        adm0_a3_ma: "SDS",
        adm0_a3_pt: "SDS",
        adm0_a3_ar: "SDS",
        adm0_a3_jp: "SDS",
        adm0_a3_ko: "SDS",
        adm0_a3_vn: "SDS",
        adm0_a3_tr: "SDS",
        adm0_a3_id: "SDS",
        adm0_a3_pl: "SDS",
        adm0_a3_gr: "SDS",
        adm0_a3_it: "SDS",
        adm0_a3_nl: "SDS",
        adm0_a3_se: "SDS",
        adm0_a3_bd: "SDS",
        adm0_a3_ua: "SDS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 11,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 30.390151,
        label_y: 7.230477,
        ne_id: 1159321235,
        wikidataid: "Q958",
        name_ar: "جنوب السودان",
        name_bn: "দক্ষিণ সুদান",
        name_de: "Südsudan",
        name_en: "South Sudan",
        name_es: "Sudán del Sur",
        name_fa: "سودان جنوبی",
        name_fr: "Soudan du Sud",
        name_el: "Νότιο Σουδάν",
        name_he: "דרום סודאן",
        name_hi: "दक्षिण सूडान",
        name_hu: "Dél-Szudán",
        name_id: "Sudan Selatan",
        name_it: "Sudan del Sud",
        name_ja: "南スーダン",
        name_ko: "남수단",
        name_nl: "Zuid-Soedan",
        name_pl: "Sudan Południowy",
        name_pt: "Sudão do Sul",
        name_ru: "Южный Судан",
        name_sv: "Sydsudan",
        name_tr: "Güney Sudan",
        name_uk: "Південний Судан",
        name_ur: "جنوبی سوڈان",
        name_vi: "Nam Sudan",
        name_zh: "南苏丹",
        name_zht: "南蘇丹",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SSD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.833852421715427, 3.509171604222463],
            [29.953500197069474, 4.173699042167684],
            [29.71599531425602, 4.600804755060153],
            [29.1590784034465, 4.389267279473231],
            [28.696677687298802, 4.455077215996937],
            [28.428993768026913, 4.287154649264494],
            [27.97997724784281, 4.408413397637375],
            [27.37422610851749, 5.233944403500061],
            [27.21340905122517, 5.550953477394557],
            [26.465909458123235, 5.94671743410187],
            [26.213418409945117, 6.546603298362072],
            [25.79664798351118, 6.979315904158071],
            [25.124130893664727, 7.500085150579437],
            [25.11493248871679, 7.825104071479174],
            [24.567369012152085, 8.229187933785468],
            [23.886979580860668, 8.619729712933065],
            [24.19406772118765, 8.728696472403897],
            [24.53741516360202, 8.91753756573172],
            [24.794925745412684, 9.810240916008695],
            [25.069603699343986, 10.273759963267992],
            [25.790633328413946, 10.411098940233728],
            [25.962307049621018, 10.136420986302426],
            [26.477328213242515, 9.552730334198088],
            [26.752006167173818, 9.466893473594496],
            [27.112520981708883, 9.638567194801624],
            [27.833550610778786, 9.60423245056029],
            [27.970889587744352, 9.398223985111656],
            [28.966597170745786, 9.398223985111656],
            [29.000931914987177, 9.60423245056029],
            [29.515953078608618, 9.793073543888056],
            [29.61895731133285, 10.084918869940225],
            [29.996639497988554, 10.290927335388687],
            [30.837840731903384, 9.70723668328452],
            [31.35286189552488, 9.810240916008695],
            [31.850715687025513, 10.531270545078826],
            [32.400071594888345, 11.080626452941488],
            [32.31423473428475, 11.68148447716652],
            [32.073891524594785, 11.973329803218519],
            [32.67474954881965, 12.02483191958072],
            [32.743419037302544, 12.248007757149992],
            [33.206938084561784, 12.179338268667095],
            [33.086766479716744, 11.441141267476496],
            [33.206938084561784, 10.720111638406593],
            [33.72195924818311, 10.325262079630193],
            [33.84213085302815, 9.981914637215993],
            [33.82496348090751, 9.484060845715362],
            [33.963392794971185, 9.464285229420625],
            [33.97498, 8.68456],
            [33.82550000000015, 8.37916],
            [33.29480000000012, 8.35458],
            [32.95418, 7.784970000000101],
            [33.568290000000104, 7.71334],
            [34.0751, 7.22595],
            [34.25032, 6.82607],
            [34.70702, 6.594220000000121],
            [35.29800711823298, 5.506],
            [34.62019626785388, 4.847122742081988],
            [34.005, 4.249884947362048],
            [33.3900000000001, 3.79],
            [32.68642, 3.79232],
            [31.88145, 3.55827],
            [31.24556, 3.7819],
            [30.833852421715427, 3.509171604222463],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Somalia",
        sov_a3: "SOM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Somalia",
        adm0_a3: "SOM",
        geou_dif: 0,
        geounit: "Somalia",
        gu_a3: "SOM",
        su_dif: 0,
        subunit: "Somalia",
        su_a3: "SOM",
        brk_diff: 0,
        name: "Somalia",
        name_long: "Somalia",
        brk_a3: "SOM",
        brk_name: "Somalia",
        brk_group: null,
        abbrev: "Som.",
        postal: "SO",
        formal_en: "Federal Republic of Somalia",
        formal_fr: null,
        name_ciawf: "Somalia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Somalia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 8,
        mapcolor9: 6,
        mapcolor13: 7,
        pop_est: 10192317.3,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 4719,
        gdp_year: 2016,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "SO",
        iso_a2: "SO",
        iso_a2_eh: "SO",
        iso_a3: "SOM",
        iso_a3_eh: "SOM",
        iso_n3: "706",
        iso_n3_eh: "706",
        un_a3: "706",
        wb_a2: "SO",
        wb_a3: "SOM",
        woe_id: -90,
        woe_id_eh: 23424949,
        woe_note:
          "Includes Somaliland (2347021, 2347020, 2347017 and portion of 2347016)",
        adm0_iso: "SOM",
        adm0_diff: null,
        adm0_tlc: "SOM",
        adm0_a3_us: "SOM",
        adm0_a3_fr: "SOM",
        adm0_a3_ru: "SOM",
        adm0_a3_es: "SOM",
        adm0_a3_cn: "SOM",
        adm0_a3_tw: "SOM",
        adm0_a3_in: "SOM",
        adm0_a3_np: "SOM",
        adm0_a3_pk: "SOM",
        adm0_a3_de: "SOM",
        adm0_a3_gb: "SOM",
        adm0_a3_br: "SOM",
        adm0_a3_il: "SOM",
        adm0_a3_ps: "SOM",
        adm0_a3_sa: "SOM",
        adm0_a3_eg: "SOM",
        adm0_a3_ma: "SOM",
        adm0_a3_pt: "SOM",
        adm0_a3_ar: "SOM",
        adm0_a3_jp: "SOM",
        adm0_a3_ko: "SOM",
        adm0_a3_vn: "SOM",
        adm0_a3_tr: "SOM",
        adm0_a3_id: "SOM",
        adm0_a3_pl: "SOM",
        adm0_a3_gr: "SOM",
        adm0_a3_it: "SOM",
        adm0_a3_nl: "SOM",
        adm0_a3_se: "SOM",
        adm0_a3_bd: "SOM",
        adm0_a3_ua: "SOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 45.19238,
        label_y: 3.568925,
        ne_id: 1159321261,
        wikidataid: "Q1045",
        name_ar: "الصومال",
        name_bn: "সোমালিয়া",
        name_de: "Somalia",
        name_en: "Somalia",
        name_es: "Somalia",
        name_fa: "سومالی",
        name_fr: "Somalie",
        name_el: "Σομαλία",
        name_he: "סומליה",
        name_hi: "सोमालिया",
        name_hu: "Szomália",
        name_id: "Somalia",
        name_it: "Somalia",
        name_ja: "ソマリア",
        name_ko: "소말리아",
        name_nl: "Somalië",
        name_pl: "Somalia",
        name_pt: "Somália",
        name_ru: "Сомали",
        name_sv: "Somalia",
        name_tr: "Somali",
        name_uk: "Сомалі",
        name_ur: "صومالیہ",
        name_vi: "Somalia",
        name_zh: "索马里",
        name_zht: "索馬利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SOM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.58513, -1.68325],
            [40.993, -0.85829],
            [40.98105, 2.78452],
            [41.85508309264397, 3.918911920483727],
            [42.12861, 4.23413],
            [42.76967, 4.25259],
            [43.66087, 4.95755],
            [44.9636, 5.00162],
            [47.78942, 8.003],
            [48.48673587422695, 8.837626247589995],
            [48.93812951029645, 9.451748968946617],
            [48.93823286316103, 9.973500067581512],
            [48.938491245322496, 10.982327378783467],
            [48.94200524271835, 11.394266058798138],
            [48.94820475850974, 11.410617281697963],
            [48.94820475850985, 11.41061728169797],
            [49.26776, 11.43033],
            [49.72862, 11.5789],
            [50.25878, 11.67957],
            [50.73202, 12.0219],
            [51.1112, 12.02464],
            [51.13387, 11.74815],
            [51.04153, 11.16651],
            [51.04531, 10.6409],
            [50.83418, 10.27972],
            [50.55239, 9.19874],
            [50.07092, 8.08173],
            [49.4527, 6.80466],
            [48.59455, 5.33911],
            [47.74079, 4.2194],
            [46.56476, 2.85529],
            [45.56399, 2.04576],
            [44.06815, 1.05283],
            [43.13597, 0.2922],
            [42.04157, -0.91916],
            [41.81095, -1.44647],
            [41.58513, -1.68325],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "United Republic of Tanzania",
        sov_a3: "TZA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "United Republic of Tanzania",
        adm0_a3: "TZA",
        geou_dif: 0,
        geounit: "Tanzania",
        gu_a3: "TZA",
        su_dif: 0,
        subunit: "Tanzania",
        su_a3: "TZA",
        brk_diff: 0,
        name: "Tanzania",
        name_long: "Tanzania",
        brk_a3: "TZA",
        brk_name: "Tanzania",
        brk_group: null,
        abbrev: "Tanz.",
        postal: "TZ",
        formal_en: "United Republic of Tanzania",
        formal_fr: null,
        name_ciawf: "Tanzania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tanzania",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 58005463,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 63177,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "TZ",
        iso_a2: "TZ",
        iso_a2_eh: "TZ",
        iso_a3: "TZA",
        iso_a3_eh: "TZA",
        iso_n3: "834",
        iso_n3_eh: "834",
        un_a3: "834",
        wb_a2: "TZ",
        wb_a3: "TZA",
        woe_id: 23424973,
        woe_id_eh: 23424973,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TZA",
        adm0_diff: null,
        adm0_tlc: "TZA",
        adm0_a3_us: "TZA",
        adm0_a3_fr: "TZA",
        adm0_a3_ru: "TZA",
        adm0_a3_es: "TZA",
        adm0_a3_cn: "TZA",
        adm0_a3_tw: "TZA",
        adm0_a3_in: "TZA",
        adm0_a3_np: "TZA",
        adm0_a3_pk: "TZA",
        adm0_a3_de: "TZA",
        adm0_a3_gb: "TZA",
        adm0_a3_br: "TZA",
        adm0_a3_il: "TZA",
        adm0_a3_ps: "TZA",
        adm0_a3_sa: "TZA",
        adm0_a3_eg: "TZA",
        adm0_a3_ma: "TZA",
        adm0_a3_pt: "TZA",
        adm0_a3_ar: "TZA",
        adm0_a3_jp: "TZA",
        adm0_a3_ko: "TZA",
        adm0_a3_vn: "TZA",
        adm0_a3_tr: "TZA",
        adm0_a3_id: "TZA",
        adm0_a3_pl: "TZA",
        adm0_a3_gr: "TZA",
        adm0_a3_it: "TZA",
        adm0_a3_nl: "TZA",
        adm0_a3_se: "TZA",
        adm0_a3_bd: "TZA",
        adm0_a3_ua: "TZA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 34.959183,
        label_y: -6.051866,
        ne_id: 1159321337,
        wikidataid: "Q924",
        name_ar: "تنزانيا",
        name_bn: "তানজানিয়া",
        name_de: "Tansania",
        name_en: "Tanzania",
        name_es: "Tanzania",
        name_fa: "تانزانیا",
        name_fr: "Tanzanie",
        name_el: "Τανζανία",
        name_he: "טנזניה",
        name_hi: "तंज़ानिया",
        name_hu: "Tanzánia",
        name_id: "Tanzania",
        name_it: "Tanzania",
        name_ja: "タンザニア",
        name_ko: "탄자니아",
        name_nl: "Tanzania",
        name_pl: "Tanzania",
        name_pt: "Tanzânia",
        name_ru: "Танзания",
        name_sv: "Tanzania",
        name_tr: "Tanzanya",
        name_uk: "Танзанія",
        name_ur: "تنزانیہ",
        name_vi: "Tanzania",
        name_zh: "坦桑尼亚",
        name_zht: "坦尚尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TZA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.90371119710453, -0.95],
            [34.07262, -1.05982],
            [37.69869, -3.09699],
            [37.7669, -3.67712],
            [39.20222, -4.67677],
            [38.74054, -5.90895],
            [38.79977, -6.47566],
            [39.44, -6.839999999999861],
            [39.47000000000014, -7.1],
            [39.19469, -7.7039],
            [39.25203, -8.00781],
            [39.18652, -8.48551],
            [39.53574, -9.112369999999885],
            [39.9496, -10.0984],
            [40.316586229110854, -10.317097752817492],
            [40.31659, -10.317099999999868],
            [39.521, -10.89688],
            [38.42755659358775, -11.285202325081656],
            [37.82764, -11.26879],
            [37.47129, -11.56876],
            [36.775150994622805, -11.594537448780805],
            [36.51408165868426, -11.720938002166735],
            [35.31239790216904, -11.439146416879147],
            [34.55998904799935, -11.520020033415925],
            [34.28, -10.16],
            [33.940837724096525, -9.693673841980285],
            [33.73972, -9.41715],
            [32.75937544122132, -9.23059905358906],
            [32.19186486179194, -8.930358981973257],
            [31.556348097466497, -8.762048841998642],
            [31.15775133695005, -8.594578747317366],
            [30.740009731422095, -8.34000593035372],
            [30.74001549655179, -8.340007419470915],
            [30.199996779101696, -7.079980970898163],
            [29.620032179490014, -6.520015150583426],
            [29.419992710088167, -5.939998874539434],
            [29.519986606572928, -5.419978936386315],
            [29.339997592900346, -4.499983412294092],
            [29.753512404099865, -4.452389418153302],
            [30.11632, -4.09012],
            [30.50554, -3.56858],
            [30.75224, -3.35931],
            [30.74301, -3.03431],
            [30.52766, -2.80762],
            [30.469673645761223, -2.41385475710134],
            [30.46967, -2.41383],
            [30.75830895358311, -2.287250257988369],
            [30.816134881317712, -1.698914076345389],
            [30.419104852019245, -1.134659112150416],
            [30.769860000000108, -1.01455],
            [31.86617, -1.02736],
            [33.90371119710453, -0.95],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Malawi",
        sov_a3: "MWI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Malawi",
        adm0_a3: "MWI",
        geou_dif: 0,
        geounit: "Malawi",
        gu_a3: "MWI",
        su_dif: 0,
        subunit: "Malawi",
        su_a3: "MWI",
        brk_diff: 0,
        name: "Malawi",
        name_long: "Malawi",
        brk_a3: "MWI",
        brk_name: "Malawi",
        brk_group: null,
        abbrev: "Mal.",
        postal: "MW",
        formal_en: "Republic of Malawi",
        formal_fr: null,
        name_ciawf: "Malawi",
        note_adm0: null,
        note_brk: null,
        name_sort: "Malawi",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 5,
        pop_est: 18628747,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 7666,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MI",
        iso_a2: "MW",
        iso_a2_eh: "MW",
        iso_a3: "MWI",
        iso_a3_eh: "MWI",
        iso_n3: "454",
        iso_n3_eh: "454",
        un_a3: "454",
        wb_a2: "MW",
        wb_a3: "MWI",
        woe_id: 23424889,
        woe_id_eh: 23424889,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MWI",
        adm0_diff: null,
        adm0_tlc: "MWI",
        adm0_a3_us: "MWI",
        adm0_a3_fr: "MWI",
        adm0_a3_ru: "MWI",
        adm0_a3_es: "MWI",
        adm0_a3_cn: "MWI",
        adm0_a3_tw: "MWI",
        adm0_a3_in: "MWI",
        adm0_a3_np: "MWI",
        adm0_a3_pk: "MWI",
        adm0_a3_de: "MWI",
        adm0_a3_gb: "MWI",
        adm0_a3_br: "MWI",
        adm0_a3_il: "MWI",
        adm0_a3_ps: "MWI",
        adm0_a3_sa: "MWI",
        adm0_a3_eg: "MWI",
        adm0_a3_ma: "MWI",
        adm0_a3_pt: "MWI",
        adm0_a3_ar: "MWI",
        adm0_a3_jp: "MWI",
        adm0_a3_ko: "MWI",
        adm0_a3_vn: "MWI",
        adm0_a3_tr: "MWI",
        adm0_a3_id: "MWI",
        adm0_a3_pl: "MWI",
        adm0_a3_gr: "MWI",
        adm0_a3_it: "MWI",
        adm0_a3_nl: "MWI",
        adm0_a3_se: "MWI",
        adm0_a3_bd: "MWI",
        adm0_a3_ua: "MWI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 33.608082,
        label_y: -13.386737,
        ne_id: 1159321081,
        wikidataid: "Q1020",
        name_ar: "مالاوي",
        name_bn: "মালাউই",
        name_de: "Malawi",
        name_en: "Malawi",
        name_es: "Malaui",
        name_fa: "مالاوی",
        name_fr: "Malawi",
        name_el: "Μαλάουι",
        name_he: "מלאווי",
        name_hi: "मलावी",
        name_hu: "Malawi",
        name_id: "Malawi",
        name_it: "Malawi",
        name_ja: "マラウイ",
        name_ko: "말라위",
        name_nl: "Malawi",
        name_pl: "Malawi",
        name_pt: "Malawi",
        name_ru: "Малави",
        name_sv: "Malawi",
        name_tr: "Malavi",
        name_uk: "Малаві",
        name_ur: "ملاوی",
        name_vi: "Malawi",
        name_zh: "马拉维",
        name_zht: "馬拉威",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MWI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.75937544122132, -9.23059905358906],
            [33.73972, -9.41715],
            [33.940837724096525, -9.693673841980285],
            [34.28, -10.16],
            [34.55998904799935, -11.520020033415925],
            [34.28000613784198, -12.280025323132506],
            [34.55998904799935, -13.579997653866876],
            [34.907151320136165, -13.565424899960568],
            [35.26795617039801, -13.887834161029566],
            [35.68684533055594, -14.611045830954332],
            [35.77190473810836, -15.896858819240727],
            [35.339062941231646, -16.10744028083011],
            [35.033810255683534, -16.801299737213093],
            [34.38129194513405, -16.183559665596043],
            [34.307291294092096, -15.478641452702597],
            [34.51766604995231, -15.013708591372612],
            [34.45963341648854, -14.613009535381423],
            [34.064825473778626, -14.35995004644812],
            [33.789700148256685, -14.45183074306307],
            [33.214024692525214, -13.971860039936153],
            [32.68816531752313, -13.712857761289277],
            [32.991764357237884, -12.783870537978274],
            [33.306422153463075, -12.435778090060218],
            [33.114289178201915, -11.607198174692314],
            [33.315310499817286, -10.796549981329697],
            [33.48568769708359, -10.525558770391115],
            [33.2313879737753, -9.6767216935648],
            [32.75937544122132, -9.23059905358906],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Kenya",
        sov_a3: "KEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kenya",
        adm0_a3: "KEN",
        geou_dif: 0,
        geounit: "Kenya",
        gu_a3: "KEN",
        su_dif: 0,
        subunit: "Kenya",
        su_a3: "KEN",
        brk_diff: 0,
        name: "Kenya",
        name_long: "Kenya",
        brk_a3: "KEN",
        brk_name: "Kenya",
        brk_group: null,
        abbrev: "Ken.",
        postal: "KE",
        formal_en: "Republic of Kenya",
        formal_fr: null,
        name_ciawf: "Kenya",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kenya",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 52573973,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 95503,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "5. Low income",
        fips_10: "KE",
        iso_a2: "KE",
        iso_a2_eh: "KE",
        iso_a3: "KEN",
        iso_a3_eh: "KEN",
        iso_n3: "404",
        iso_n3_eh: "404",
        un_a3: "404",
        wb_a2: "KE",
        wb_a3: "KEN",
        woe_id: 23424863,
        woe_id_eh: 23424863,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KEN",
        adm0_diff: null,
        adm0_tlc: "KEN",
        adm0_a3_us: "KEN",
        adm0_a3_fr: "KEN",
        adm0_a3_ru: "KEN",
        adm0_a3_es: "KEN",
        adm0_a3_cn: "KEN",
        adm0_a3_tw: "KEN",
        adm0_a3_in: "KEN",
        adm0_a3_np: "KEN",
        adm0_a3_pk: "KEN",
        adm0_a3_de: "KEN",
        adm0_a3_gb: "KEN",
        adm0_a3_br: "KEN",
        adm0_a3_il: "KEN",
        adm0_a3_ps: "KEN",
        adm0_a3_sa: "KEN",
        adm0_a3_eg: "KEN",
        adm0_a3_ma: "KEN",
        adm0_a3_pt: "KEN",
        adm0_a3_ar: "KEN",
        adm0_a3_jp: "KEN",
        adm0_a3_ko: "KEN",
        adm0_a3_vn: "KEN",
        adm0_a3_tr: "KEN",
        adm0_a3_id: "KEN",
        adm0_a3_pl: "KEN",
        adm0_a3_gr: "KEN",
        adm0_a3_it: "KEN",
        adm0_a3_nl: "KEN",
        adm0_a3_se: "KEN",
        adm0_a3_bd: "KEN",
        adm0_a3_ua: "KEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 37.907632,
        label_y: 0.549043,
        ne_id: 1159320971,
        wikidataid: "Q114",
        name_ar: "كينيا",
        name_bn: "কেনিয়া",
        name_de: "Kenia",
        name_en: "Kenya",
        name_es: "Kenia",
        name_fa: "کنیا",
        name_fr: "Kenya",
        name_el: "Κένυα",
        name_he: "קניה",
        name_hi: "कीनिया",
        name_hu: "Kenya",
        name_id: "Kenya",
        name_it: "Kenya",
        name_ja: "ケニア",
        name_ko: "케냐",
        name_nl: "Kenia",
        name_pl: "Kenia",
        name_pt: "Quénia",
        name_ru: "Кения",
        name_sv: "Kenya",
        name_tr: "Kenya",
        name_uk: "Кенія",
        name_ur: "کینیا",
        name_vi: "Kenya",
        name_zh: "肯尼亚",
        name_zht: "肯亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.20222, -4.67677],
            [37.7669, -3.67712],
            [37.69869, -3.09699],
            [34.07262, -1.05982],
            [33.90371119710453, -0.95],
            [33.893568969666944, 0.109813537861896],
            [34.18, 0.515],
            [34.6721, 1.17694],
            [35.03599, 1.90584],
            [34.59607, 3.053740000000118],
            [34.47913, 3.5556],
            [34.005, 4.249884947362048],
            [34.62019626785388, 4.847122742081988],
            [35.29800711823298, 5.506],
            [35.817447662353516, 5.338232082790797],
            [35.817447662353516, 4.77696566346189],
            [36.159078632855646, 4.447864127672769],
            [36.85509323800812, 4.447864127672769],
            [38.120915, 3.598605],
            [38.43697, 3.58851],
            [38.67114, 3.61607],
            [38.89251, 3.50074],
            [39.55938425876585, 3.42206],
            [39.85494, 3.83879],
            [40.76848, 4.25702],
            [41.1718, 3.91909],
            [41.85508309264397, 3.918911920483727],
            [40.98105, 2.78452],
            [40.993, -0.85829],
            [41.58513, -1.68325],
            [40.88477, -2.08255],
            [40.63785, -2.49979],
            [40.26304, -2.57309],
            [40.12119, -3.27768],
            [39.80006, -3.68116],
            [39.60489, -4.34653],
            [39.20222, -4.67677],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Somaliland",
        sov_a3: "SOL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Somaliland",
        adm0_a3: "SOL",
        geou_dif: 0,
        geounit: "Somaliland",
        gu_a3: "SOL",
        su_dif: 0,
        subunit: "Somaliland",
        su_a3: "SOL",
        brk_diff: 0,
        name: "Somaliland",
        name_long: "Somaliland",
        brk_a3: "SOL",
        brk_name: "Somaliland",
        brk_group: null,
        abbrev: "Solnd.",
        postal: "SL",
        formal_en: "Republic of Somaliland",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Disputed",
        note_brk: "Self admin.; Claimed by Somalia",
        name_sort: "Somaliland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 2,
        pop_est: 5096159,
        pop_rank: 13,
        pop_year: 2014,
        gdp_md: 17836,
        gdp_year: 2013,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "-99",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        woe_id_eh: -99,
        woe_note:
          "Includes old states of 2347021, 2347020, 2347017 and portion of 2347016.",
        adm0_iso: "SOM",
        adm0_diff: "1",
        adm0_tlc: "SOL",
        adm0_a3_us: "SOM",
        adm0_a3_fr: "SOM",
        adm0_a3_ru: "SOM",
        adm0_a3_es: "SOM",
        adm0_a3_cn: "SOM",
        adm0_a3_tw: "SOL",
        adm0_a3_in: "SOM",
        adm0_a3_np: "SOM",
        adm0_a3_pk: "SOM",
        adm0_a3_de: "SOM",
        adm0_a3_gb: "SOM",
        adm0_a3_br: "SOM",
        adm0_a3_il: "SOM",
        adm0_a3_ps: "SOM",
        adm0_a3_sa: "SOM",
        adm0_a3_eg: "SOM",
        adm0_a3_ma: "SOM",
        adm0_a3_pt: "SOM",
        adm0_a3_ar: "SOM",
        adm0_a3_jp: "SOM",
        adm0_a3_ko: "SOM",
        adm0_a3_vn: "SOM",
        adm0_a3_tr: "SOM",
        adm0_a3_id: "SOM",
        adm0_a3_pl: "SOM",
        adm0_a3_gr: "SOM",
        adm0_a3_it: "SOM",
        adm0_a3_nl: "SOM",
        adm0_a3_se: "SOM",
        adm0_a3_bd: "SOM",
        adm0_a3_ua: "SOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 4,
        min_label: 4.5,
        max_label: 9,
        label_x: 46.731595,
        label_y: 9.443889,
        ne_id: 1159321259,
        wikidataid: "Q34754",
        name_ar: "صوماليلاند",
        name_bn: "সোমালিল্যান্ড",
        name_de: "Somaliland",
        name_en: "Somaliland",
        name_es: "Somalilandia",
        name_fa: "سومالیلند",
        name_fr: "Somaliland",
        name_el: "Σομαλιλάνδη",
        name_he: "סומלילנד",
        name_hi: "सोमालीदेश",
        name_hu: "Szomáliföld",
        name_id: "Somaliland",
        name_it: "Somaliland",
        name_ja: "ソマリランド",
        name_ko: "소말릴란드",
        name_nl: "Somaliland",
        name_pl: "Somaliland",
        name_pt: "Somalilândia",
        name_ru: "Сомалиленд",
        name_sv: "Somaliland",
        name_tr: "Somaliland",
        name_uk: "Сомаліленд",
        name_ur: "صومالی لینڈ",
        name_vi: "Somaliland",
        name_zh: "索马里兰",
        name_zht: "索馬利蘭",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: "Unrecognized",
        fclass_fr: "Unrecognized",
        fclass_ru: "Unrecognized",
        fclass_es: "Unrecognized",
        fclass_cn: "Unrecognized",
        fclass_tw: "Admin-0 country",
        fclass_in: "Unrecognized",
        fclass_np: "Unrecognized",
        fclass_pk: "Unrecognized",
        fclass_de: "Unrecognized",
        fclass_gb: "Unrecognized",
        fclass_br: "Unrecognized",
        fclass_il: "Unrecognized",
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: "Unrecognized",
        fclass_ma: "Unrecognized",
        fclass_pt: "Unrecognized",
        fclass_ar: "Unrecognized",
        fclass_jp: "Unrecognized",
        fclass_ko: "Unrecognized",
        fclass_vn: "Unrecognized",
        fclass_tr: "Unrecognized",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: "Unrecognized",
        fclass_it: "Unrecognized",
        fclass_nl: "Unrecognized",
        fclass_se: "Unrecognized",
        fclass_bd: "Unrecognized",
        fclass_ua: "Unrecognized",
        filename: "SOL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.94820475850985, 11.41061728169797],
            [48.94820475850974, 11.410617281697963],
            [48.94200524271835, 11.394266058798138],
            [48.938491245322496, 10.982327378783467],
            [48.93823286316103, 9.973500067581512],
            [48.93812951029645, 9.451748968946617],
            [48.48673587422695, 8.837626247589995],
            [47.78942, 8.003],
            [46.94834, 7.99688],
            [43.67875, 9.18358000000012],
            [43.29699000000011, 9.540480000000173],
            [42.92812, 10.021940000000143],
            [42.55876, 10.57258000000013],
            [42.77685184100096, 10.92687856693442],
            [43.14530480324214, 11.462039699748857],
            [43.470659620951665, 11.277709865763882],
            [43.66666832863484, 10.86416921634816],
            [44.11780358254282, 10.445538438351605],
            [44.614259067570856, 10.442205308468942],
            [45.55694054543915, 10.698029486529776],
            [46.645401238803004, 10.816549383991173],
            [47.525657586462785, 11.12722809492999],
            [48.02159630716778, 11.193063869669743],
            [48.37878380716927, 11.375481675660126],
            [48.94820641459347, 11.41062164961852],
            [48.94820475850985, 11.41061728169797],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Morocco",
        sov_a3: "MAR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Morocco",
        adm0_a3: "MAR",
        geou_dif: 0,
        geounit: "Morocco",
        gu_a3: "MAR",
        su_dif: 0,
        subunit: "Morocco",
        su_a3: "MAR",
        brk_diff: 0,
        name: "Morocco",
        name_long: "Morocco",
        brk_a3: "MAR",
        brk_name: "Morocco",
        brk_group: null,
        abbrev: "Mor.",
        postal: "MA",
        formal_en: "Kingdom of Morocco",
        formal_fr: null,
        name_ciawf: "Morocco",
        note_adm0: null,
        note_brk: null,
        name_sort: "Morocco",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 36471769,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 119700,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MO",
        iso_a2: "MA",
        iso_a2_eh: "MA",
        iso_a3: "MAR",
        iso_a3_eh: "MAR",
        iso_n3: "504",
        iso_n3_eh: "504",
        un_a3: "504",
        wb_a2: "MA",
        wb_a3: "MAR",
        woe_id: 23424893,
        woe_id_eh: 23424893,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MAR",
        adm0_diff: null,
        adm0_tlc: "MAR",
        adm0_a3_us: "MAR",
        adm0_a3_fr: "MAR",
        adm0_a3_ru: "MAR",
        adm0_a3_es: "MAR",
        adm0_a3_cn: "MAR",
        adm0_a3_tw: "MAR",
        adm0_a3_in: "MAR",
        adm0_a3_np: "MAR",
        adm0_a3_pk: "MAR",
        adm0_a3_de: "MAR",
        adm0_a3_gb: "MAR",
        adm0_a3_br: "MAR",
        adm0_a3_il: "MAR",
        adm0_a3_ps: "MAR",
        adm0_a3_sa: "MAR",
        adm0_a3_eg: "MAR",
        adm0_a3_ma: "MAR",
        adm0_a3_pt: "MAR",
        adm0_a3_ar: "MAR",
        adm0_a3_jp: "MAR",
        adm0_a3_ko: "MAR",
        adm0_a3_vn: "MAR",
        adm0_a3_tr: "MAR",
        adm0_a3_id: "MAR",
        adm0_a3_pl: "MAR",
        adm0_a3_gr: "MAR",
        adm0_a3_it: "MAR",
        adm0_a3_nl: "MAR",
        adm0_a3_se: "MAR",
        adm0_a3_bd: "MAR",
        adm0_a3_ua: "MAR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -7.187296,
        label_y: 31.650723,
        ne_id: 1159321035,
        wikidataid: "Q1028",
        name_ar: "المغرب",
        name_bn: "মরক্কো",
        name_de: "Marokko",
        name_en: "Morocco",
        name_es: "Marruecos",
        name_fa: "مراکش",
        name_fr: "Maroc",
        name_el: "Μαρόκο",
        name_he: "מרוקו",
        name_hi: "मोरक्को",
        name_hu: "Marokkó",
        name_id: "Maroko",
        name_it: "Marocco",
        name_ja: "モロッコ",
        name_ko: "모로코",
        name_nl: "Marokko",
        name_pl: "Maroko",
        name_pt: "Marrocos",
        name_ru: "Марокко",
        name_sv: "Marocko",
        name_tr: "Fas",
        name_uk: "Марокко",
        name_ur: "مراکش",
        name_vi: "Maroc",
        name_zh: "摩洛哥",
        name_zht: "摩洛哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MAR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.169913702798624, 35.16839630791668],
            [-1.792985805661715, 34.527918606091305],
            [-1.733454555661467, 33.91971283623212],
            [-1.388049282222596, 32.86401500094138],
            [-1.124551153966308, 32.65152151135713],
            [-1.30789913573787, 32.2628889023061],
            [-2.616604783529567, 32.09434621838619],
            [-3.068980271812648, 31.724497992473218],
            [-3.647497931320146, 31.637294012980675],
            [-3.690441046554724, 30.896951605751156],
            [-4.859646165374471, 30.501187649043846],
            [-5.242129278982787, 30.00044302013559],
            [-6.060632290053774, 29.731699734001694],
            [-7.059227667661958, 29.5792284205246],
            [-8.674116176782974, 28.84128896739658],
            [-8.665589565454809, 27.656425889592356],
            [-8.817828334986672, 27.656425889592356],
            [-8.794883999049077, 27.120696316022507],
            [-9.41303748212448, 27.088476060488574],
            [-9.735343390328879, 26.860944729107405],
            [-10.189424200877582, 26.860944729107405],
            [-10.551262579785273, 26.990807603456886],
            [-11.392554897497007, 26.883423977154393],
            [-11.718219773800357, 26.104091701760623],
            [-12.03075883630163, 26.030866197203068],
            [-12.50096269372537, 24.7701162785782],
            [-13.891110398809047, 23.691009019459305],
            [-14.221167771857253, 22.31016307218816],
            [-14.630832688851072, 21.860939846274903],
            [-14.750954555713534, 21.500600083903663],
            [-17.00296179856109, 21.420734157796577],
            [-17.02042843267577, 21.422310288981578],
            [-16.973247849993243, 21.885744533774982],
            [-16.589136928767687, 22.158234361250095],
            [-16.261921759495635, 22.679339504481277],
            [-16.3264139469959, 23.017768459560898],
            [-15.982610642958036, 23.723358466074046],
            [-15.426003790742186, 24.35913361256104],
            [-15.089331834360735, 24.520260728447],
            [-14.824645148161665, 25.103532619725343],
            [-14.800925665739726, 25.63626496022232],
            [-14.439939947964831, 26.254418443297652],
            [-13.773804897506466, 26.618892320252314],
            [-13.13994177901435, 27.640147813420526],
            [-13.121613369914769, 27.65414767171984],
            [-12.618836635783111, 28.03818553314869],
            [-11.688919236690765, 28.148643907172527],
            [-10.900956997104402, 28.83214223888092],
            [-10.399592251008642, 29.09858592377782],
            [-9.564811163765683, 29.93357371674989],
            [-9.814718390329176, 31.17773550060906],
            [-9.434793260119363, 32.038096421836485],
            [-9.300692918321886, 32.564679266890664],
            [-8.657476365585012, 33.240245266242425],
            [-7.654178432638219, 33.69706492770251],
            [-6.912544114601417, 34.110476386037476],
            [-6.244342006851411, 35.145865383437524],
            [-5.92999426921989, 35.75998810479399],
            [-5.193863491222032, 35.75518219659085],
            [-4.591006232105144, 35.330711981745594],
            [-3.640056525070065, 35.399855048152006],
            [-2.604305792644084, 35.179093329401155],
            [-2.169913702798624, 35.16839630791668],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 7,
        sovereignt: "Western Sahara",
        sov_a3: "SAH",
        adm0_dif: 0,
        level: 2,
        type: "Indeterminate",
        tlc: "1",
        admin: "Western Sahara",
        adm0_a3: "SAH",
        geou_dif: 0,
        geounit: "Western Sahara",
        gu_a3: "SAH",
        su_dif: 0,
        subunit: "Western Sahara",
        su_a3: "SAH",
        brk_diff: 1,
        name: "W. Sahara",
        name_long: "Western Sahara",
        brk_a3: "B28",
        brk_name: "W. Sahara",
        brk_group: null,
        abbrev: "W. Sah.",
        postal: "WS",
        formal_en: "Sahrawi Arab Democratic Republic",
        formal_fr: null,
        name_ciawf: "Western Sahara",
        note_adm0: null,
        note_brk: "Self admin.; Claimed by Morocco",
        name_sort: "Western Sahara",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 7,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 603253,
        pop_rank: 11,
        pop_year: 2017,
        gdp_md: 907,
        gdp_year: 2007,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "WI",
        iso_a2: "EH",
        iso_a2_eh: "EH",
        iso_a3: "ESH",
        iso_a3_eh: "ESH",
        iso_n3: "732",
        iso_n3_eh: "732",
        un_a3: "732",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424990,
        woe_id_eh: 23424990,
        woe_note: "Exact WOE match as country",
        adm0_iso: "B28",
        adm0_diff: null,
        adm0_tlc: "B28",
        adm0_a3_us: "SAH",
        adm0_a3_fr: "MAR",
        adm0_a3_ru: "SAH",
        adm0_a3_es: "SAH",
        adm0_a3_cn: "SAH",
        adm0_a3_tw: "SAH",
        adm0_a3_in: "MAR",
        adm0_a3_np: "SAH",
        adm0_a3_pk: "SAH",
        adm0_a3_de: "SAH",
        adm0_a3_gb: "SAH",
        adm0_a3_br: "SAH",
        adm0_a3_il: "SAH",
        adm0_a3_ps: "MAR",
        adm0_a3_sa: "MAR",
        adm0_a3_eg: "SAH",
        adm0_a3_ma: "MAR",
        adm0_a3_pt: "SAH",
        adm0_a3_ar: "SAH",
        adm0_a3_jp: "SAH",
        adm0_a3_ko: "SAH",
        adm0_a3_vn: "SAH",
        adm0_a3_tr: "MAR",
        adm0_a3_id: "MAR",
        adm0_a3_pl: "MAR",
        adm0_a3_gr: "SAH",
        adm0_a3_it: "SAH",
        adm0_a3_nl: "MAR",
        adm0_a3_se: "SAH",
        adm0_a3_bd: "SAH",
        adm0_a3_ua: "SAH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 9,
        long_len: 14,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 4.7,
        min_label: 6,
        max_label: 11,
        label_x: -12.630304,
        label_y: 23.967592,
        ne_id: 1159321223,
        wikidataid: "Q6250",
        name_ar: "الصحراء الغربية",
        name_bn: "পশ্চিম সাহারা",
        name_de: "Westsahara",
        name_en: "Western Sahara",
        name_es: "Sahara Occidental",
        name_fa: "صحرای غربی",
        name_fr: "Sahara occidental",
        name_el: "Δυτική Σαχάρα",
        name_he: "סהרה המערבית",
        name_hi: "पश्चिमी सहारा",
        name_hu: "Nyugat-Szahara",
        name_id: "Sahara Barat",
        name_it: "Sahara Occidentale",
        name_ja: "西サハラ",
        name_ko: "서사하라",
        name_nl: "Westelijke Sahara",
        name_pl: "Sahara Zachodnia",
        name_pt: "Sara Ocidental",
        name_ru: "Западная Сахара",
        name_sv: "Västsahara",
        name_tr: "Batı Sahra",
        name_uk: "Західна Сахара",
        name_ur: "مغربی صحارا",
        name_vi: "Tây Sahara",
        name_zh: "西撒哈拉",
        name_zht: "西撒哈拉",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: "Unrecognized",
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: "Unrecognized",
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: null,
        fclass_ma: "Unrecognized",
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: "Unrecognized",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: "Unrecognized",
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ESH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.665589565454809, 27.656425889592356],
            [-8.665124477564191, 27.589479071558227],
            [-8.684399786809053, 27.395744126896005],
            [-8.6872936670174, 25.881056219988906],
            [-11.96941891117116, 25.933352769468268],
            [-11.937224493853321, 23.374594224536168],
            [-12.874221564169575, 23.284832261645178],
            [-13.118754441774712, 22.771220201096256],
            [-12.929101935263532, 21.327070624267563],
            [-16.845193650773993, 21.33332347257488],
            [-17.06342322434257, 20.999752102130827],
            [-17.02042843267577, 21.422310288981578],
            [-17.00296179856109, 21.420734157796577],
            [-14.750954555713534, 21.500600083903663],
            [-14.630832688851072, 21.860939846274903],
            [-14.221167771857253, 22.31016307218816],
            [-13.891110398809047, 23.691009019459305],
            [-12.50096269372537, 24.7701162785782],
            [-12.03075883630163, 26.030866197203068],
            [-11.718219773800357, 26.104091701760623],
            [-11.392554897497007, 26.883423977154393],
            [-10.551262579785273, 26.990807603456886],
            [-10.189424200877582, 26.860944729107405],
            [-9.735343390328879, 26.860944729107405],
            [-9.41303748212448, 27.088476060488574],
            [-8.794883999049077, 27.120696316022507],
            [-8.817828334986672, 27.656425889592356],
            [-8.665589565454809, 27.656425889592356],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Namibia",
        sov_a3: "NAM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Namibia",
        adm0_a3: "NAM",
        geou_dif: 0,
        geounit: "Namibia",
        gu_a3: "NAM",
        su_dif: 0,
        subunit: "Namibia",
        su_a3: "NAM",
        brk_diff: 0,
        name: "Namibia",
        name_long: "Namibia",
        brk_a3: "NAM",
        brk_name: "Namibia",
        brk_group: null,
        abbrev: "Nam.",
        postal: "NA",
        formal_en: "Republic of Namibia",
        formal_fr: null,
        name_ciawf: "Namibia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Namibia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 2494530,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 12366,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "WA",
        iso_a2: "NA",
        iso_a2_eh: "NA",
        iso_a3: "NAM",
        iso_a3_eh: "NAM",
        iso_n3: "516",
        iso_n3_eh: "516",
        un_a3: "516",
        wb_a2: "NA",
        wb_a3: "NAM",
        woe_id: 23424987,
        woe_id_eh: 23424987,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NAM",
        adm0_diff: null,
        adm0_tlc: "NAM",
        adm0_a3_us: "NAM",
        adm0_a3_fr: "NAM",
        adm0_a3_ru: "NAM",
        adm0_a3_es: "NAM",
        adm0_a3_cn: "NAM",
        adm0_a3_tw: "NAM",
        adm0_a3_in: "NAM",
        adm0_a3_np: "NAM",
        adm0_a3_pk: "NAM",
        adm0_a3_de: "NAM",
        adm0_a3_gb: "NAM",
        adm0_a3_br: "NAM",
        adm0_a3_il: "NAM",
        adm0_a3_ps: "NAM",
        adm0_a3_sa: "NAM",
        adm0_a3_eg: "NAM",
        adm0_a3_ma: "NAM",
        adm0_a3_pt: "NAM",
        adm0_a3_ar: "NAM",
        adm0_a3_jp: "NAM",
        adm0_a3_ko: "NAM",
        adm0_a3_vn: "NAM",
        adm0_a3_tr: "NAM",
        adm0_a3_id: "NAM",
        adm0_a3_pl: "NAM",
        adm0_a3_gr: "NAM",
        adm0_a3_it: "NAM",
        adm0_a3_nl: "NAM",
        adm0_a3_se: "NAM",
        adm0_a3_bd: "NAM",
        adm0_a3_ua: "NAM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7.5,
        label_x: 17.108166,
        label_y: -20.575298,
        ne_id: 1159321085,
        wikidataid: "Q1030",
        name_ar: "ناميبيا",
        name_bn: "নামিবিয়া",
        name_de: "Namibia",
        name_en: "Namibia",
        name_es: "Namibia",
        name_fa: "نامیبیا",
        name_fr: "Namibie",
        name_el: "Ναμίμπια",
        name_he: "נמיביה",
        name_hi: "नामीबिया",
        name_hu: "Namíbia",
        name_id: "Namibia",
        name_it: "Namibia",
        name_ja: "ナミビア",
        name_ko: "나미비아",
        name_nl: "Namibië",
        name_pl: "Namibia",
        name_pt: "Namíbia",
        name_ru: "Намибия",
        name_sv: "Namibia",
        name_tr: "Namibya",
        name_uk: "Намібія",
        name_ur: "نمیبیا",
        name_vi: "Namibia",
        name_zh: "纳米比亚",
        name_zht: "納米比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NAM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.895767856534434, -24.76779021576059],
            [19.894734327888614, -28.461104831660776],
            [19.002127312911085, -28.972443129188868],
            [18.464899122804752, -29.04546192801728],
            [17.83615197110953, -28.85637786226132],
            [17.387497185951503, -28.78351409272978],
            [17.218928663815404, -28.35594329194681],
            [16.824017368240902, -28.08216155366447],
            [16.344976840895242, -28.5767050106977],
            [15.601818068105816, -27.821247247022804],
            [15.21047244635946, -27.090955905874047],
            [14.989710727608553, -26.117371921495156],
            [14.743214145576331, -25.39292001719538],
            [14.408144158595833, -23.853014011329847],
            [14.385716586981149, -22.65665292734069],
            [14.257714064194175, -22.111208184499958],
            [13.86864220546866, -21.699036960539978],
            [13.35249799973744, -20.872834161057504],
            [12.826845330464494, -19.673165785401665],
            [12.608564080463621, -19.0453488094877],
            [11.794918654028066, -18.069129327061916],
            [11.734198846085121, -17.301889336824473],
            [12.215461460019355, -17.111668389558083],
            [12.814081251688407, -16.94134286872407],
            [13.462362094789967, -16.971211846588773],
            [14.05850141770901, -17.423380629142663],
            [14.209706658595024, -17.35310068122572],
            [18.263309360434164, -17.309950860262006],
            [18.956186964603603, -17.789094740472258],
            [21.377176141045567, -17.930636488519696],
            [23.215048455506064, -17.523116143465984],
            [24.033861525170778, -17.295843194246324],
            [24.682349074001507, -17.353410739819473],
            [25.07695031098226, -17.57882333747662],
            [25.08444339366457, -17.661815687737374],
            [24.520705193792537, -17.887124932529936],
            [24.217364536239213, -17.88934701911849],
            [23.579005568137717, -18.28126108162006],
            [23.1968583513393, -17.869038181227786],
            [21.655040317478978, -18.219146010005225],
            [20.910641310314535, -18.252218926672022],
            [20.88113406747587, -21.814327080983148],
            [19.89545779794068, -21.84915699634787],
            [19.895767856534434, -24.76779021576059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Republic of the Congo",
        sov_a3: "COG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Republic of the Congo",
        adm0_a3: "COG",
        geou_dif: 0,
        geounit: "Republic of the Congo",
        gu_a3: "COG",
        su_dif: 0,
        subunit: "Republic of the Congo",
        su_a3: "COG",
        brk_diff: 0,
        name: "Congo",
        name_long: "Republic of the Congo",
        brk_a3: "COG",
        brk_name: "Republic of the Congo",
        brk_group: null,
        abbrev: "Rep. Congo",
        postal: "CG",
        formal_en: "Republic of the Congo",
        formal_fr: null,
        name_ciawf: "Congo, Republic of the",
        note_adm0: null,
        note_brk: null,
        name_sort: "Congo, Rep.",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 10,
        pop_est: 5380508,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 12267,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CF",
        iso_a2: "CG",
        iso_a2_eh: "CG",
        iso_a3: "COG",
        iso_a3_eh: "COG",
        iso_n3: "178",
        iso_n3_eh: "178",
        un_a3: "178",
        wb_a2: "CG",
        wb_a3: "COG",
        woe_id: 23424779,
        woe_id_eh: 23424779,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COG",
        adm0_diff: null,
        adm0_tlc: "COG",
        adm0_a3_us: "COG",
        adm0_a3_fr: "COG",
        adm0_a3_ru: "COG",
        adm0_a3_es: "COG",
        adm0_a3_cn: "COG",
        adm0_a3_tw: "COG",
        adm0_a3_in: "COG",
        adm0_a3_np: "COG",
        adm0_a3_pk: "COG",
        adm0_a3_de: "COG",
        adm0_a3_gb: "COG",
        adm0_a3_br: "COG",
        adm0_a3_il: "COG",
        adm0_a3_ps: "COG",
        adm0_a3_sa: "COG",
        adm0_a3_eg: "COG",
        adm0_a3_ma: "COG",
        adm0_a3_pt: "COG",
        adm0_a3_ar: "COG",
        adm0_a3_jp: "COG",
        adm0_a3_ko: "COG",
        adm0_a3_vn: "COG",
        adm0_a3_tr: "COG",
        adm0_a3_id: "COG",
        adm0_a3_pl: "COG",
        adm0_a3_gr: "COG",
        adm0_a3_it: "COG",
        adm0_a3_nl: "COG",
        adm0_a3_se: "COG",
        adm0_a3_bd: "COG",
        adm0_a3_ua: "COG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 21,
        abbrev_len: 10,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 15.9005,
        label_y: 0.142331,
        ne_id: 1159320515,
        wikidataid: "Q971",
        name_ar: "جمهورية الكونغو",
        name_bn: "কঙ্গো প্রজাতন্ত্র",
        name_de: "Republik Kongo",
        name_en: "Republic of the Congo",
        name_es: "República del Congo",
        name_fa: "جمهوری کنگو",
        name_fr: "République du Congo",
        name_el: "Δημοκρατία του Κονγκό",
        name_he: "הרפובליקה של קונגו",
        name_hi: "कांगो गणराज्य",
        name_hu: "Kongói Köztársaság",
        name_id: "Republik Kongo",
        name_it: "Repubblica del Congo",
        name_ja: "コンゴ共和国",
        name_ko: "콩고 공화국",
        name_nl: "Congo-Brazzaville",
        name_pl: "Kongo",
        name_pt: "República do Congo",
        name_ru: "Республика Конго",
        name_sv: "Kongo-Brazzaville",
        name_tr: "Kongo Cumhuriyeti",
        name_uk: "Республіка Конго",
        name_ur: "جمہوریہ کانگو",
        name_vi: "Cộng hòa Congo",
        name_zh: "刚果共和国",
        name_zht: "剛果共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.45306521980993, 3.504385891123349],
            [18.393792351971143, 2.90044342692822],
            [18.094275750407434, 2.365721543788055],
            [17.898835483479587, 1.741831976728278],
            [17.774191928791566, 0.855658677571085],
            [17.826540154703252, 0.288923244626105],
            [17.66355268725468, -0.058083998213817],
            [17.638644646889986, -0.424831638189247],
            [17.523716261472856, -0.743830254726987],
            [16.865306837642123, -1.225816338713287],
            [16.407091912510054, -1.740927015798682],
            [15.972803175529151, -2.712392266453612],
            [16.0062895036543, -3.535132744972529],
            [15.753540073314753, -3.855164890156097],
            [15.170991652088444, -4.343507175314301],
            [14.582603794013181, -4.97023894615014],
            [14.209034864975223, -4.793092136253598],
            [14.144956088933299, -4.510008640158716],
            [13.600234816144678, -4.50013844159097],
            [13.258240187237048, -4.882957452009165],
            [12.995517205465177, -4.781103203961884],
            [12.620759718484493, -4.438023369976136],
            [12.318607618873926, -4.606230157086188],
            [11.91496300624209, -5.037986748884791],
            [11.093772820691925, -3.978826592630547],
            [11.855121697648116, -3.426870619321051],
            [11.478038771214303, -2.765618991714241],
            [11.820963575903193, -2.514161472181982],
            [12.495702752338161, -2.391688327650243],
            [12.575284458067642, -1.948511244315135],
            [13.109618767965628, -2.428740329603514],
            [13.99240726080771, -2.4708049454891],
            [14.299210239324566, -1.998275648612214],
            [14.425455763413595, -1.333406670744971],
            [14.316418491277744, -0.552627455247048],
            [13.843320753645656, 0.038757635901149],
            [14.276265903386957, 1.196929836426619],
            [14.026668735417218, 1.395677395021153],
            [13.282631463278818, 1.31418366129688],
            [13.003113641012078, 1.83089630778332],
            [13.075822381246752, 2.267097072759015],
            [14.33781253424658, 2.227874660649491],
            [15.146341993885244, 1.964014797367184],
            [15.940918816805066, 1.727672634280296],
            [16.012852410555354, 2.267639675298085],
            [16.537058139724138, 3.198254706226279],
            [17.133042433346304, 3.728196519379452],
            [17.809900343505262, 3.56019643799857],
            [18.45306521980993, 3.504385891123349],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Democratic Republic of the Congo",
        sov_a3: "COD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Democratic Republic of the Congo",
        adm0_a3: "COD",
        geou_dif: 0,
        geounit: "Democratic Republic of the Congo",
        gu_a3: "COD",
        su_dif: 0,
        subunit: "Democratic Republic of the Congo",
        su_a3: "COD",
        brk_diff: 0,
        name: "Dem. Rep. Congo",
        name_long: "Democratic Republic of the Congo",
        brk_a3: "COD",
        brk_name: "Democratic Republic of the Congo",
        brk_group: null,
        abbrev: "D.R.C.",
        postal: "DRC",
        formal_en: "Democratic Republic of the Congo",
        formal_fr: null,
        name_ciawf: "Congo, Democratic Republic of the",
        note_adm0: null,
        note_brk: null,
        name_sort: "Congo, Dem. Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 7,
        pop_est: 86790567,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 50400,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CG",
        iso_a2: "CD",
        iso_a2_eh: "CD",
        iso_a3: "COD",
        iso_a3_eh: "COD",
        iso_n3: "180",
        iso_n3_eh: "180",
        un_a3: "180",
        wb_a2: "ZR",
        wb_a3: "ZAR",
        woe_id: 23424780,
        woe_id_eh: 23424780,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COD",
        adm0_diff: null,
        adm0_tlc: "COD",
        adm0_a3_us: "COD",
        adm0_a3_fr: "COD",
        adm0_a3_ru: "COD",
        adm0_a3_es: "COD",
        adm0_a3_cn: "COD",
        adm0_a3_tw: "COD",
        adm0_a3_in: "COD",
        adm0_a3_np: "COD",
        adm0_a3_pk: "COD",
        adm0_a3_de: "COD",
        adm0_a3_gb: "COD",
        adm0_a3_br: "COD",
        adm0_a3_il: "COD",
        adm0_a3_ps: "COD",
        adm0_a3_sa: "COD",
        adm0_a3_eg: "COD",
        adm0_a3_ma: "COD",
        adm0_a3_pt: "COD",
        adm0_a3_ar: "COD",
        adm0_a3_jp: "COD",
        adm0_a3_ko: "COD",
        adm0_a3_vn: "COD",
        adm0_a3_tr: "COD",
        adm0_a3_id: "COD",
        adm0_a3_pl: "COD",
        adm0_a3_gr: "COD",
        adm0_a3_it: "COD",
        adm0_a3_nl: "COD",
        adm0_a3_se: "COD",
        adm0_a3_bd: "COD",
        adm0_a3_ua: "COD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 15,
        long_len: 32,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 23.458829,
        label_y: -1.858167,
        ne_id: 1159320513,
        wikidataid: "Q974",
        name_ar: "جمهورية الكونغو الديمقراطية",
        name_bn: "গণতান্ত্রিক কঙ্গো প্রজাতন্ত্র",
        name_de: "Demokratische Republik Kongo",
        name_en: "Democratic Republic of the Congo",
        name_es: "República Democrática del Congo",
        name_fa: "جمهوری دموکراتیک کنگو",
        name_fr: "République démocratique du Congo",
        name_el: "Λαϊκή Δημοκρατία του Κονγκό",
        name_he: "הרפובליקה הדמוקרטית של קונגו",
        name_hi: "कांगो लोकतान्त्रिक गणराज्य",
        name_hu: "Kongói Demokratikus Köztársaság",
        name_id: "Republik Demokratik Kongo",
        name_it: "Repubblica Democratica del Congo",
        name_ja: "コンゴ民主共和国",
        name_ko: "콩고 민주 공화국",
        name_nl: "Congo-Kinshasa",
        name_pl: "Demokratyczna Republika Konga",
        name_pt: "República Democrática do Congo",
        name_ru: "Демократическая Республика Конго",
        name_sv: "Kongo-Kinshasa",
        name_tr: "Demokratik Kongo Cumhuriyeti",
        name_uk: "Демократична Республіка Конго",
        name_ur: "جمہوری جمہوریہ کانگو",
        name_vi: "Cộng hòa Dân chủ Congo",
        name_zh: "刚果民主共和国",
        name_zht: "剛果民主共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.339997592900346, -4.499983412294092],
            [29.519986606572928, -5.419978936386315],
            [29.419992710088167, -5.939998874539434],
            [29.620032179490014, -6.520015150583426],
            [30.199996779101696, -7.079980970898163],
            [30.74001549655179, -8.340007419470915],
            [30.740009731422095, -8.34000593035372],
            [30.346086053190817, -8.238256524288218],
            [29.00291222506047, -8.407031752153472],
            [28.734866570762502, -8.526559340044578],
            [28.449871046672826, -9.164918308146085],
            [28.67368167492893, -9.605924981324932],
            [28.49606977714177, -10.789883721564046],
            [28.372253045370428, -11.793646742401393],
            [28.642417433392353, -11.971568698782315],
            [29.34154788586909, -12.360743910372413],
            [29.61600141777123, -12.178894545137311],
            [29.69961388521949, -13.257226657771831],
            [28.934285922976837, -13.248958428605135],
            [28.523561639121027, -12.698604424696683],
            [28.155108676879987, -12.272480564017897],
            [27.388798862423783, -12.132747491100666],
            [27.164419793412463, -11.608748467661075],
            [26.553087599399618, -11.924439792532127],
            [25.752309604604733, -11.784965101776358],
            [25.418118116973204, -11.330935967659961],
            [24.78316979340295, -11.238693536018964],
            [24.31451622894795, -11.26282642989927],
            [24.25715538910399, -10.951992689663657],
            [23.912215203555718, -10.926826267137514],
            [23.45679080576744, -10.867863457892483],
            [22.83734541188474, -11.01762175867433],
            [22.402798292742375, -10.993075453335692],
            [22.155268182064308, -11.084801120653772],
            [22.208753289486395, -9.894796237836509],
            [21.875181919042348, -9.523707777548566],
            [21.8018013851879, -8.90870655684298],
            [21.949130893652043, -8.305900974158277],
            [21.74645592620331, -7.920084730667149],
            [21.7281107927397, -7.290872491081302],
            [20.5147481625265, -7.299605808138629],
            [20.6018229509383, -6.939317722199682],
            [20.091621534920648, -6.943090101756994],
            [20.037723016040218, -7.116361179231646],
            [19.41750247567316, -7.155428562044299],
            [19.16661339689611, -7.738183688999754],
            [19.01675174324967, -7.988245944860132],
            [18.464175652752687, -7.847014255406443],
            [18.13422163256905, -7.987677504104923],
            [17.472970004962235, -8.0685511206417],
            [17.08999596524717, -7.545688978712526],
            [16.8601908708452, -7.222297865429987],
            [16.573179965896145, -6.622644545115087],
            [16.326528354567046, -5.877470391466268],
            [13.375597364971895, -5.864241224799549],
            [13.024869419006961, -5.984388929878158],
            [12.735171339578699, -5.965682061388499],
            [12.32243167486351, -6.10009246177966],
            [12.182336866920252, -5.789930515163839],
            [12.436688266660868, -5.684303887559246],
            [12.468004184629736, -5.248361504745005],
            [12.63161176926579, -4.991271254092936],
            [12.995517205465177, -4.781103203961884],
            [13.258240187237048, -4.882957452009165],
            [13.600234816144678, -4.50013844159097],
            [14.144956088933299, -4.510008640158716],
            [14.209034864975223, -4.793092136253598],
            [14.582603794013181, -4.97023894615014],
            [15.170991652088444, -4.343507175314301],
            [15.753540073314753, -3.855164890156097],
            [16.0062895036543, -3.535132744972529],
            [15.972803175529151, -2.712392266453612],
            [16.407091912510054, -1.740927015798682],
            [16.865306837642123, -1.225816338713287],
            [17.523716261472856, -0.743830254726987],
            [17.638644646889986, -0.424831638189247],
            [17.66355268725468, -0.058083998213817],
            [17.826540154703252, 0.288923244626105],
            [17.774191928791566, 0.855658677571085],
            [17.898835483479587, 1.741831976728278],
            [18.094275750407434, 2.365721543788055],
            [18.393792351971143, 2.90044342692822],
            [18.45306521980993, 3.504385891123349],
            [18.54298221199778, 4.201785183118318],
            [18.93231245288476, 4.709506130385975],
            [19.46778364429315, 5.03152781821278],
            [20.290679152108936, 4.691677761245288],
            [20.927591180106276, 4.322785549329737],
            [21.659122755630023, 4.22434194581372],
            [22.405123732195538, 4.029160061047321],
            [22.70412356943629, 4.633050848810157],
            [22.841479526468106, 4.710126247573484],
            [23.29721398285014, 4.609693101414223],
            [24.410531040146253, 5.10878408448913],
            [24.805028924262416, 4.89724660890235],
            [25.12883344900328, 4.92724477784779],
            [25.278798455514305, 5.170408229997192],
            [25.650455356557472, 5.256087754737123],
            [26.402760857862543, 5.150874538590871],
            [27.04406538260471, 5.127852688004836],
            [27.37422610851749, 5.233944403500061],
            [27.97997724784281, 4.408413397637375],
            [28.428993768026913, 4.287154649264494],
            [28.696677687298802, 4.455077215996937],
            [29.1590784034465, 4.389267279473231],
            [29.71599531425602, 4.600804755060153],
            [29.953500197069474, 4.173699042167684],
            [30.833852421715427, 3.509171604222463],
            [30.83385989759381, 3.509165961110341],
            [30.77334679538004, 2.339883327642127],
            [31.174149204235817, 2.204465236821264],
            [30.85267011894806, 1.849396470543809],
            [30.46850752129029, 1.583805446779706],
            [30.086153598762706, 1.062312730306289],
            [29.875778842902434, 0.597379868976361],
            [29.819503208136638, -0.205310153813372],
            [29.58783776217217, -0.587405694179381],
            [29.579466180140884, -1.341313164885626],
            [29.291886834436614, -1.620055840667987],
            [29.254834832483343, -2.215109958508911],
            [29.117478875451553, -2.292211195488385],
            [29.024926385216787, -2.839257907730158],
            [29.276383904749053, -3.293907159034063],
            [29.339997592900346, -4.499983412294092],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "South Africa",
        sov_a3: "ZAF",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "South Africa",
        adm0_a3: "ZAF",
        geou_dif: 0,
        geounit: "South Africa",
        gu_a3: "ZAF",
        su_dif: 0,
        subunit: "South Africa",
        su_a3: "ZAF",
        brk_diff: 0,
        name: "South Africa",
        name_long: "South Africa",
        brk_a3: "ZAF",
        brk_name: "South Africa",
        brk_group: null,
        abbrev: "S.Af.",
        postal: "ZA",
        formal_en: "Republic of South Africa",
        formal_fr: null,
        name_ciawf: "South Africa",
        note_adm0: null,
        note_brk: null,
        name_sort: "South Africa",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 58558270,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 351431,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "SF",
        iso_a2: "ZA",
        iso_a2_eh: "ZA",
        iso_a3: "ZAF",
        iso_a3_eh: "ZAF",
        iso_n3: "710",
        iso_n3_eh: "710",
        un_a3: "710",
        wb_a2: "ZA",
        wb_a3: "ZAF",
        woe_id: 23424942,
        woe_id_eh: 23424942,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ZAF",
        adm0_diff: null,
        adm0_tlc: "ZAF",
        adm0_a3_us: "ZAF",
        adm0_a3_fr: "ZAF",
        adm0_a3_ru: "ZAF",
        adm0_a3_es: "ZAF",
        adm0_a3_cn: "ZAF",
        adm0_a3_tw: "ZAF",
        adm0_a3_in: "ZAF",
        adm0_a3_np: "ZAF",
        adm0_a3_pk: "ZAF",
        adm0_a3_de: "ZAF",
        adm0_a3_gb: "ZAF",
        adm0_a3_br: "ZAF",
        adm0_a3_il: "ZAF",
        adm0_a3_ps: "ZAF",
        adm0_a3_sa: "ZAF",
        adm0_a3_eg: "ZAF",
        adm0_a3_ma: "ZAF",
        adm0_a3_pt: "ZAF",
        adm0_a3_ar: "ZAF",
        adm0_a3_jp: "ZAF",
        adm0_a3_ko: "ZAF",
        adm0_a3_vn: "ZAF",
        adm0_a3_tr: "ZAF",
        adm0_a3_id: "ZAF",
        adm0_a3_pl: "ZAF",
        adm0_a3_gr: "ZAF",
        adm0_a3_it: "ZAF",
        adm0_a3_nl: "ZAF",
        adm0_a3_se: "ZAF",
        adm0_a3_bd: "ZAF",
        adm0_a3_ua: "ZAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 23.665734,
        label_y: -29.708776,
        ne_id: 1159321431,
        wikidataid: "Q258",
        name_ar: "جنوب أفريقيا",
        name_bn: "দক্ষিণ আফ্রিকা",
        name_de: "Südafrika",
        name_en: "South Africa",
        name_es: "Sudáfrica",
        name_fa: "آفریقای جنوبی",
        name_fr: "Afrique du Sud",
        name_el: "Νότια Αφρική",
        name_he: "דרום אפריקה",
        name_hi: "दक्षिण अफ़्रीका",
        name_hu: "Dél-afrikai Köztársaság",
        name_id: "Afrika Selatan",
        name_it: "Sudafrica",
        name_ja: "南アフリカ共和国",
        name_ko: "남아프리카 공화국",
        name_nl: "Zuid-Afrika",
        name_pl: "Południowa Afryka",
        name_pt: "África do Sul",
        name_ru: "ЮАР",
        name_sv: "Sydafrika",
        name_tr: "Güney Afrika Cumhuriyeti",
        name_uk: "Південно-Африканська Республіка",
        name_ur: "جنوبی افریقا",
        name_vi: "Cộng hòa Nam Phi",
        name_zh: "南非",
        name_zht: "南非",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ZAF.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.344976840895242, -28.5767050106977],
            [16.824017368240902, -28.08216155366447],
            [17.218928663815404, -28.35594329194681],
            [17.387497185951503, -28.78351409272978],
            [17.83615197110953, -28.85637786226132],
            [18.464899122804752, -29.04546192801728],
            [19.002127312911085, -28.972443129188868],
            [19.894734327888614, -28.461104831660776],
            [19.895767856534434, -24.76779021576059],
            [20.16572553882719, -24.91796192800077],
            [20.75860924651184, -25.86813648855145],
            [20.66647016773544, -26.477453301704923],
            [20.88960900237174, -26.828542982695915],
            [21.605896030369394, -26.726533705351756],
            [22.105968865657868, -26.280256036079138],
            [22.57953169118059, -25.979447523708146],
            [22.8242712745149, -25.50045867279477],
            [23.312096795350186, -25.26868987396572],
            [23.73356977712271, -25.390129489851617],
            [24.211266717228796, -25.670215752873574],
            [25.025170525825786, -25.7196700985769],
            [25.66466637543772, -25.486816094669713],
            [25.76584882986521, -25.17484547292368],
            [25.94165205252216, -24.69637338633322],
            [26.4857532081233, -24.616326592713104],
            [26.786406691197413, -24.240690606383485],
            [27.119409620886245, -23.574323011979775],
            [28.01723595552525, -22.82775359465908],
            [29.43218834810904, -22.091312758067588],
            [29.839036899542972, -22.102216485281176],
            [30.322883335091774, -22.271611830333935],
            [30.65986535006709, -22.151567478119915],
            [31.19140913262129, -22.2515096981724],
            [31.670397983534656, -23.658969008073864],
            [31.930588820124253, -24.36941659922254],
            [31.75240848158188, -25.484283949487413],
            [31.83777794772806, -25.84333180105135],
            [31.333157586397903, -25.66019052500895],
            [31.04407962415715, -25.731452325139443],
            [30.949666782359913, -26.02264902110415],
            [30.67660851412964, -26.398078301704608],
            [30.68596194837448, -26.743845310169533],
            [31.28277306491333, -27.285879408478998],
            [31.86806033705108, -27.177927341421277],
            [32.07166548028107, -26.73382008230491],
            [32.830120477028885, -26.742191664336197],
            [32.580264926897684, -27.470157566031816],
            [32.46213260267845, -28.301011244420557],
            [32.20338870619304, -28.75240488049007],
            [31.521001417778876, -29.257386976846256],
            [31.325561150851, -29.401977634398914],
            [30.901762729625347, -29.909956963828037],
            [30.62281334811382, -30.42377573010613],
            [30.05571618014278, -31.140269463832958],
            [28.92555260591954, -32.1720411109725],
            [28.2197558936771, -32.771952813448856],
            [27.464608188595975, -33.2269637997788],
            [26.419452345492825, -33.61495045342619],
            [25.90966434093349, -33.6670402971764],
            [25.780628289500697, -33.94464609144834],
            [25.172861769315972, -33.796851495093584],
            [24.677853224392123, -33.98717579522455],
            [23.594043409934642, -33.794474379208154],
            [22.988188917744736, -33.91643075941698],
            [22.574157342222236, -33.86408253350531],
            [21.542799106541025, -34.258838799782936],
            [20.689052768647002, -34.417175388325234],
            [20.071261020597632, -34.79513681410799],
            [19.61640506356457, -34.81916635512371],
            [19.193278435958717, -34.46259897230979],
            [18.85531456876987, -34.444305515278465],
            [18.42464318204938, -33.99787281670897],
            [18.377410922934615, -34.13652068454807],
            [18.24449913907992, -33.86775156019803],
            [18.250080193767445, -33.28143075941444],
            [17.92519046394844, -32.61129078545343],
            [18.247909783611192, -32.42913136162457],
            [18.22176150887148, -31.66163298922567],
            [17.56691775886887, -30.725721123987547],
            [17.064416131262703, -29.878641045859162],
            [17.062917514726223, -29.875953871379984],
            [16.344976840895242, -28.5767050106977],
          ],
          [
            [28.978262566857243, -28.95559661226171],
            [28.541700066855498, -28.64750172293757],
            [28.074338413207784, -28.851468601193588],
            [27.532511020627478, -29.24271087007536],
            [26.999261915807637, -29.875953871379984],
            [27.749397006956485, -30.645105889612225],
            [28.107204624145425, -30.54573211031495],
            [28.29106937023991, -30.2262167294543],
            [28.84839969250774, -30.070050551068256],
            [29.018415154748027, -29.74376555757737],
            [29.32516645683259, -29.257386976846256],
            [28.978262566857243, -28.95559661226171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Libya",
        sov_a3: "LBY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Libya",
        adm0_a3: "LBY",
        geou_dif: 0,
        geounit: "Libya",
        gu_a3: "LBY",
        su_dif: 0,
        subunit: "Libya",
        su_a3: "LBY",
        brk_diff: 0,
        name: "Libya",
        name_long: "Libya",
        brk_a3: "LBY",
        brk_name: "Libya",
        brk_group: null,
        abbrev: "Libya",
        postal: "LY",
        formal_en: "Libya",
        formal_fr: null,
        name_ciawf: "Libya",
        note_adm0: null,
        note_brk: null,
        name_sort: "Libya",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 11,
        pop_est: 6777452,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 52091,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "LY",
        iso_a2: "LY",
        iso_a2_eh: "LY",
        iso_a3: "LBY",
        iso_a3_eh: "LBY",
        iso_n3: "434",
        iso_n3_eh: "434",
        un_a3: "434",
        wb_a2: "LY",
        wb_a3: "LBY",
        woe_id: 23424882,
        woe_id_eh: 23424882,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LBY",
        adm0_diff: null,
        adm0_tlc: "LBY",
        adm0_a3_us: "LBY",
        adm0_a3_fr: "LBY",
        adm0_a3_ru: "LBY",
        adm0_a3_es: "LBY",
        adm0_a3_cn: "LBY",
        adm0_a3_tw: "LBY",
        adm0_a3_in: "LBY",
        adm0_a3_np: "LBY",
        adm0_a3_pk: "LBY",
        adm0_a3_de: "LBY",
        adm0_a3_gb: "LBY",
        adm0_a3_br: "LBY",
        adm0_a3_il: "LBY",
        adm0_a3_ps: "LBY",
        adm0_a3_sa: "LBY",
        adm0_a3_eg: "LBY",
        adm0_a3_ma: "LBY",
        adm0_a3_pt: "LBY",
        adm0_a3_ar: "LBY",
        adm0_a3_jp: "LBY",
        adm0_a3_ko: "LBY",
        adm0_a3_vn: "LBY",
        adm0_a3_tr: "LBY",
        adm0_a3_id: "LBY",
        adm0_a3_pl: "LBY",
        adm0_a3_gr: "LBY",
        adm0_a3_it: "LBY",
        adm0_a3_nl: "LBY",
        adm0_a3_se: "LBY",
        adm0_a3_bd: "LBY",
        adm0_a3_ua: "LBY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 18.011015,
        label_y: 26.638944,
        ne_id: 1159321017,
        wikidataid: "Q1016",
        name_ar: "ليبيا",
        name_bn: "লিবিয়া",
        name_de: "Libyen",
        name_en: "Libya",
        name_es: "Libia",
        name_fa: "لیبی",
        name_fr: "Libye",
        name_el: "Λιβύη",
        name_he: "לוב",
        name_hi: "लीबिया",
        name_hu: "Líbia",
        name_id: "Libya",
        name_it: "Libia",
        name_ja: "リビア",
        name_ko: "리비아",
        name_nl: "Libië",
        name_pl: "Libia",
        name_pt: "Líbia",
        name_ru: "Ливия",
        name_sv: "Libyen",
        name_tr: "Libya",
        name_uk: "Лівія",
        name_ur: "لیبیا",
        name_vi: "Libya",
        name_zh: "利比亚",
        name_zht: "利比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LBY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25, 22],
            [25.000000000000114, 20.00304],
            [23.850000000000136, 20],
            [23.83766000000014, 19.580470000000105],
            [19.84926, 21.49509],
            [15.86085, 23.40972],
            [14.8513, 22.862950000000126],
            [14.143870883855243, 22.491288967371133],
            [13.581424594790462, 23.04050608976928],
            [11.999505649471613, 23.47166840259645],
            [11.560669386449005, 24.097909247325518],
            [10.771363559622927, 24.56253205006175],
            [10.303846876678362, 24.379313259370917],
            [9.94826134607797, 24.936953640232517],
            [9.910692579801776, 25.365454616796796],
            [9.319410841518163, 26.094324856057455],
            [9.716285841519664, 26.512206325785655],
            [9.629056023811074, 27.14095347748092],
            [9.756128370816782, 27.688258571884205],
            [9.683884718472768, 28.1441738957792],
            [9.859997999723447, 28.959989732371014],
            [9.805634392952356, 29.424638373323376],
            [9.482139926805274, 30.307556057246188],
            [9.970017124072854, 30.53932485607524],
            [10.056575148161699, 30.961831366493527],
            [9.950225050505082, 31.376069647745258],
            [10.636901482799487, 31.761420803345757],
            [10.944789666394456, 32.081814683555365],
            [11.432253452203696, 32.368903103152874],
            [11.488787469131012, 33.13699575452324],
            [12.66331, 32.79278],
            [13.08326, 32.87882],
            [13.91868, 32.71196],
            [15.24563, 32.26508],
            [15.71394, 31.37626],
            [16.61162, 31.18218],
            [18.02109, 30.76357],
            [19.08641, 30.26639],
            [19.57404, 30.52582],
            [20.05335, 30.98576],
            [19.82033, 31.751790000000142],
            [20.13397, 32.2382],
            [20.85452, 32.7068],
            [21.54298, 32.8432],
            [22.89576, 32.63858],
            [23.2368, 32.19149],
            [23.609130000000107, 32.18726],
            [23.9275, 32.01667],
            [24.92114, 31.89936],
            [25.16482, 31.56915],
            [24.80287, 31.08929],
            [24.95762, 30.6616],
            [24.70007, 30.04419],
            [25, 29.23865452953346],
            [25, 25.682499996361],
            [25, 22],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Tunisia",
        sov_a3: "TUN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Tunisia",
        adm0_a3: "TUN",
        geou_dif: 0,
        geounit: "Tunisia",
        gu_a3: "TUN",
        su_dif: 0,
        subunit: "Tunisia",
        su_a3: "TUN",
        brk_diff: 0,
        name: "Tunisia",
        name_long: "Tunisia",
        brk_a3: "TUN",
        brk_name: "Tunisia",
        brk_group: null,
        abbrev: "Tun.",
        postal: "TN",
        formal_en: "Republic of Tunisia",
        formal_fr: null,
        name_ciawf: "Tunisia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tunisia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 2,
        pop_est: 11694719,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 38796,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "TS",
        iso_a2: "TN",
        iso_a2_eh: "TN",
        iso_a3: "TUN",
        iso_a3_eh: "TUN",
        iso_n3: "788",
        iso_n3_eh: "788",
        un_a3: "788",
        wb_a2: "TN",
        wb_a3: "TUN",
        woe_id: 23424967,
        woe_id_eh: 23424967,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TUN",
        adm0_diff: null,
        adm0_tlc: "TUN",
        adm0_a3_us: "TUN",
        adm0_a3_fr: "TUN",
        adm0_a3_ru: "TUN",
        adm0_a3_es: "TUN",
        adm0_a3_cn: "TUN",
        adm0_a3_tw: "TUN",
        adm0_a3_in: "TUN",
        adm0_a3_np: "TUN",
        adm0_a3_pk: "TUN",
        adm0_a3_de: "TUN",
        adm0_a3_gb: "TUN",
        adm0_a3_br: "TUN",
        adm0_a3_il: "TUN",
        adm0_a3_ps: "TUN",
        adm0_a3_sa: "TUN",
        adm0_a3_eg: "TUN",
        adm0_a3_ma: "TUN",
        adm0_a3_pt: "TUN",
        adm0_a3_ar: "TUN",
        adm0_a3_jp: "TUN",
        adm0_a3_ko: "TUN",
        adm0_a3_vn: "TUN",
        adm0_a3_tr: "TUN",
        adm0_a3_id: "TUN",
        adm0_a3_pl: "TUN",
        adm0_a3_gr: "TUN",
        adm0_a3_it: "TUN",
        adm0_a3_nl: "TUN",
        adm0_a3_se: "TUN",
        adm0_a3_bd: "TUN",
        adm0_a3_ua: "TUN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 9.007881,
        label_y: 33.687263,
        ne_id: 1159321327,
        wikidataid: "Q948",
        name_ar: "تونس",
        name_bn: "তিউনিসিয়া",
        name_de: "Tunesien",
        name_en: "Tunisia",
        name_es: "Túnez",
        name_fa: "تونس",
        name_fr: "Tunisie",
        name_el: "Τυνησία",
        name_he: "תוניסיה",
        name_hi: "ट्यूनिशिया",
        name_hu: "Tunézia",
        name_id: "Tunisia",
        name_it: "Tunisia",
        name_ja: "チュニジア",
        name_ko: "튀니지",
        name_nl: "Tunesië",
        name_pl: "Tunezja",
        name_pt: "Tunísia",
        name_ru: "Тунис",
        name_sv: "Tunisien",
        name_tr: "Tunus",
        name_uk: "Туніс",
        name_ur: "تونس",
        name_vi: "Tuy-ni-di",
        name_zh: "突尼斯",
        name_zht: "突尼西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TUN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.482139926805274, 30.307556057246188],
            [9.05560265466815, 32.10269196220129],
            [8.439102817426118, 32.50628489840082],
            [8.430472853233368, 32.74833730725595],
            [7.612641635782182, 33.34411489514896],
            [7.524481642292244, 34.09737641045146],
            [8.140981479534304, 34.65514598239379],
            [8.376367628623768, 35.479876003555944],
            [8.217824334352315, 36.433176988260286],
            [8.420964389691676, 36.94642731378316],
            [9.509993523810607, 37.349994411766545],
            [10.210002475636317, 37.230001735984814],
            [10.180650262094531, 36.724037787415085],
            [11.02886722173335, 37.09210317641396],
            [11.100025668999251, 36.899996039368915],
            [10.600004510143094, 36.410000108377375],
            [10.593286573945138, 35.94744436293281],
            [10.939518670300687, 35.698984076473494],
            [10.807847120821009, 34.83350718844919],
            [10.149592726287125, 34.33077301689771],
            [10.339658644256616, 33.78574168551532],
            [10.856836378633687, 33.76874013929128],
            [11.108500603895122, 33.293342800422195],
            [11.488787469131012, 33.13699575452324],
            [11.432253452203696, 32.368903103152874],
            [10.944789666394456, 32.081814683555365],
            [10.636901482799487, 31.761420803345757],
            [9.950225050505082, 31.376069647745258],
            [10.056575148161699, 30.961831366493527],
            [9.970017124072854, 30.53932485607524],
            [9.482139926805274, 30.307556057246188],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Zambia",
        sov_a3: "ZMB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Zambia",
        adm0_a3: "ZMB",
        geou_dif: 0,
        geounit: "Zambia",
        gu_a3: "ZMB",
        su_dif: 0,
        subunit: "Zambia",
        su_a3: "ZMB",
        brk_diff: 0,
        name: "Zambia",
        name_long: "Zambia",
        brk_a3: "ZMB",
        brk_name: "Zambia",
        brk_group: null,
        abbrev: "Zambia",
        postal: "ZM",
        formal_en: "Republic of Zambia",
        formal_fr: null,
        name_ciawf: "Zambia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Zambia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 8,
        mapcolor9: 5,
        mapcolor13: 13,
        pop_est: 17861030,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 23309,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "ZA",
        iso_a2: "ZM",
        iso_a2_eh: "ZM",
        iso_a3: "ZMB",
        iso_a3_eh: "ZMB",
        iso_n3: "894",
        iso_n3_eh: "894",
        un_a3: "894",
        wb_a2: "ZM",
        wb_a3: "ZMB",
        woe_id: 23425003,
        woe_id_eh: 23425003,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ZMB",
        adm0_diff: null,
        adm0_tlc: "ZMB",
        adm0_a3_us: "ZMB",
        adm0_a3_fr: "ZMB",
        adm0_a3_ru: "ZMB",
        adm0_a3_es: "ZMB",
        adm0_a3_cn: "ZMB",
        adm0_a3_tw: "ZMB",
        adm0_a3_in: "ZMB",
        adm0_a3_np: "ZMB",
        adm0_a3_pk: "ZMB",
        adm0_a3_de: "ZMB",
        adm0_a3_gb: "ZMB",
        adm0_a3_br: "ZMB",
        adm0_a3_il: "ZMB",
        adm0_a3_ps: "ZMB",
        adm0_a3_sa: "ZMB",
        adm0_a3_eg: "ZMB",
        adm0_a3_ma: "ZMB",
        adm0_a3_pt: "ZMB",
        adm0_a3_ar: "ZMB",
        adm0_a3_jp: "ZMB",
        adm0_a3_ko: "ZMB",
        adm0_a3_vn: "ZMB",
        adm0_a3_tr: "ZMB",
        adm0_a3_id: "ZMB",
        adm0_a3_pl: "ZMB",
        adm0_a3_gr: "ZMB",
        adm0_a3_it: "ZMB",
        adm0_a3_nl: "ZMB",
        adm0_a3_se: "ZMB",
        adm0_a3_bd: "ZMB",
        adm0_a3_ua: "ZMB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 26.395298,
        label_y: -14.660804,
        ne_id: 1159321439,
        wikidataid: "Q953",
        name_ar: "زامبيا",
        name_bn: "জাম্বিয়া",
        name_de: "Sambia",
        name_en: "Zambia",
        name_es: "Zambia",
        name_fa: "زامبیا",
        name_fr: "Zambie",
        name_el: "Ζάμπια",
        name_he: "זמביה",
        name_hi: "ज़ाम्बिया",
        name_hu: "Zambia",
        name_id: "Zambia",
        name_it: "Zambia",
        name_ja: "ザンビア",
        name_ko: "잠비아",
        name_nl: "Zambia",
        name_pl: "Zambia",
        name_pt: "Zâmbia",
        name_ru: "Замбия",
        name_sv: "Zambia",
        name_tr: "Zambiya",
        name_uk: "Замбія",
        name_ur: "زیمبیا",
        name_vi: "Zambia",
        name_zh: "赞比亚",
        name_zht: "尚比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ZMB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.740009731422095, -8.34000593035372],
            [31.15775133695005, -8.594578747317366],
            [31.556348097466497, -8.762048841998642],
            [32.19186486179194, -8.930358981973257],
            [32.75937544122132, -9.23059905358906],
            [33.2313879737753, -9.6767216935648],
            [33.48568769708359, -10.525558770391115],
            [33.315310499817286, -10.796549981329697],
            [33.114289178201915, -11.607198174692314],
            [33.306422153463075, -12.435778090060218],
            [32.991764357237884, -12.783870537978274],
            [32.68816531752313, -13.712857761289277],
            [33.214024692525214, -13.971860039936153],
            [30.17948123548183, -14.796099134991529],
            [30.27425581230511, -15.507786960515213],
            [29.516834344203147, -15.644677829656388],
            [28.947463413211267, -16.04305144619444],
            [28.8258687680285, -16.389748630440614],
            [28.467906121542683, -16.468400160388846],
            [27.59824344250276, -17.290830580314008],
            [27.044427117630732, -17.938026218337434],
            [26.70677330903564, -17.961228936436484],
            [26.381935255648926, -17.8460421688579],
            [25.264225701608012, -17.736539808831417],
            [25.08444339366457, -17.661815687737374],
            [25.07695031098226, -17.57882333747662],
            [24.682349074001507, -17.353410739819473],
            [24.033861525170778, -17.295843194246324],
            [23.215048455506064, -17.523116143465984],
            [22.56247846852426, -16.898451429921813],
            [21.887842644953874, -16.08031015387688],
            [21.933886346125917, -12.898437188369359],
            [24.016136508894675, -12.911046237848574],
            [23.930922072045377, -12.565847670138856],
            [24.079905226342845, -12.191296888887365],
            [23.904153680118185, -11.722281589406322],
            [24.01789350759259, -11.23729827234709],
            [23.912215203555718, -10.926826267137514],
            [24.25715538910399, -10.951992689663657],
            [24.31451622894795, -11.26282642989927],
            [24.78316979340295, -11.238693536018964],
            [25.418118116973204, -11.330935967659961],
            [25.752309604604733, -11.784965101776358],
            [26.553087599399618, -11.924439792532127],
            [27.164419793412463, -11.608748467661075],
            [27.388798862423783, -12.132747491100666],
            [28.155108676879987, -12.272480564017897],
            [28.523561639121027, -12.698604424696683],
            [28.934285922976837, -13.248958428605135],
            [29.69961388521949, -13.257226657771831],
            [29.61600141777123, -12.178894545137311],
            [29.34154788586909, -12.360743910372413],
            [28.642417433392353, -11.971568698782315],
            [28.372253045370428, -11.793646742401393],
            [28.49606977714177, -10.789883721564046],
            [28.67368167492893, -9.605924981324932],
            [28.449871046672826, -9.164918308146085],
            [28.734866570762502, -8.526559340044578],
            [29.00291222506047, -8.407031752153472],
            [30.346086053190817, -8.238256524288218],
            [30.740009731422095, -8.34000593035372],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Sierra Leone",
        sov_a3: "SLE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sierra Leone",
        adm0_a3: "SLE",
        geou_dif: 0,
        geounit: "Sierra Leone",
        gu_a3: "SLE",
        su_dif: 0,
        subunit: "Sierra Leone",
        su_a3: "SLE",
        brk_diff: 0,
        name: "Sierra Leone",
        name_long: "Sierra Leone",
        brk_a3: "SLE",
        brk_name: "Sierra Leone",
        brk_group: null,
        abbrev: "S.L.",
        postal: "SL",
        formal_en: "Republic of Sierra Leone",
        formal_fr: null,
        name_ciawf: "Sierra Leone",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sierra Leone",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 7813215,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 4121,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "SL",
        iso_a2: "SL",
        iso_a2_eh: "SL",
        iso_a3: "SLE",
        iso_a3_eh: "SLE",
        iso_n3: "694",
        iso_n3_eh: "694",
        un_a3: "694",
        wb_a2: "SL",
        wb_a3: "SLE",
        woe_id: 23424946,
        woe_id_eh: 23424946,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SLE",
        adm0_diff: null,
        adm0_tlc: "SLE",
        adm0_a3_us: "SLE",
        adm0_a3_fr: "SLE",
        adm0_a3_ru: "SLE",
        adm0_a3_es: "SLE",
        adm0_a3_cn: "SLE",
        adm0_a3_tw: "SLE",
        adm0_a3_in: "SLE",
        adm0_a3_np: "SLE",
        adm0_a3_pk: "SLE",
        adm0_a3_de: "SLE",
        adm0_a3_gb: "SLE",
        adm0_a3_br: "SLE",
        adm0_a3_il: "SLE",
        adm0_a3_ps: "SLE",
        adm0_a3_sa: "SLE",
        adm0_a3_eg: "SLE",
        adm0_a3_ma: "SLE",
        adm0_a3_pt: "SLE",
        adm0_a3_ar: "SLE",
        adm0_a3_jp: "SLE",
        adm0_a3_ko: "SLE",
        adm0_a3_vn: "SLE",
        adm0_a3_tr: "SLE",
        adm0_a3_id: "SLE",
        adm0_a3_pl: "SLE",
        adm0_a3_gr: "SLE",
        adm0_a3_it: "SLE",
        adm0_a3_nl: "SLE",
        adm0_a3_se: "SLE",
        adm0_a3_bd: "SLE",
        adm0_a3_ua: "SLE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -11.763677,
        label_y: 8.617449,
        ne_id: 1159321251,
        wikidataid: "Q1044",
        name_ar: "سيراليون",
        name_bn: "সিয়েরা লিওন",
        name_de: "Sierra Leone",
        name_en: "Sierra Leone",
        name_es: "Sierra Leona",
        name_fa: "سیرالئون",
        name_fr: "Sierra Leone",
        name_el: "Σιέρα Λεόνε",
        name_he: "סיירה לאון",
        name_hi: "सिएरा लियोन",
        name_hu: "Sierra Leone",
        name_id: "Sierra Leone",
        name_it: "Sierra Leone",
        name_ja: "シエラレオネ",
        name_ko: "시에라리온",
        name_nl: "Sierra Leone",
        name_pl: "Sierra Leone",
        name_pt: "Serra Leoa",
        name_ru: "Сьерра-Леоне",
        name_sv: "Sierra Leone",
        name_tr: "Sierra Leone",
        name_uk: "Сьєрра-Леоне",
        name_ur: "سیرالیون",
        name_vi: "Sierra Leone",
        name_zh: "塞拉利昂",
        name_zht: "獅子山",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SLE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.246550258832515, 8.903048610871508],
            [-12.71195756677308, 9.342711696810767],
            [-12.59671912276221, 9.62018830000197],
            [-12.425928514037565, 9.835834051955956],
            [-12.150338100625005, 9.858571682164381],
            [-11.917277390988659, 10.046983954300558],
            [-11.11748124840733, 10.045872911006285],
            [-10.839151984083301, 9.688246161330369],
            [-10.622395188835041, 9.267910061068278],
            [-10.654770473665891, 8.977178452994195],
            [-10.494315151399633, 8.715540676300435],
            [-10.505477260774668, 8.348896389189605],
            [-10.23009355309128, 8.406205552601293],
            [-10.69559485517648, 7.939464016141088],
            [-11.146704270868383, 7.396706447779536],
            [-11.19980180504828, 7.105845648624737],
            [-11.438779466182055, 6.785916856305747],
            [-11.70819454593574, 6.860098374860726],
            [-12.428098924193819, 7.26294200279203],
            [-12.949049038128194, 7.798645738145738],
            [-13.124025437868482, 8.163946438016978],
            [-13.246550258832515, 8.903048610871508],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Guinea",
        sov_a3: "GIN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guinea",
        adm0_a3: "GIN",
        geou_dif: 0,
        geounit: "Guinea",
        gu_a3: "GIN",
        su_dif: 0,
        subunit: "Guinea",
        su_a3: "GIN",
        brk_diff: 0,
        name: "Guinea",
        name_long: "Guinea",
        brk_a3: "GIN",
        brk_name: "Guinea",
        brk_group: null,
        abbrev: "Gin.",
        postal: "GN",
        formal_en: "Republic of Guinea",
        formal_fr: null,
        name_ciawf: "Guinea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guinea",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 12771246,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 12296,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "GV",
        iso_a2: "GN",
        iso_a2_eh: "GN",
        iso_a3: "GIN",
        iso_a3_eh: "GIN",
        iso_n3: "324",
        iso_n3_eh: "324",
        un_a3: "324",
        wb_a2: "GN",
        wb_a3: "GIN",
        woe_id: 23424835,
        woe_id_eh: 23424835,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GIN",
        adm0_diff: null,
        adm0_tlc: "GIN",
        adm0_a3_us: "GIN",
        adm0_a3_fr: "GIN",
        adm0_a3_ru: "GIN",
        adm0_a3_es: "GIN",
        adm0_a3_cn: "GIN",
        adm0_a3_tw: "GIN",
        adm0_a3_in: "GIN",
        adm0_a3_np: "GIN",
        adm0_a3_pk: "GIN",
        adm0_a3_de: "GIN",
        adm0_a3_gb: "GIN",
        adm0_a3_br: "GIN",
        adm0_a3_il: "GIN",
        adm0_a3_ps: "GIN",
        adm0_a3_sa: "GIN",
        adm0_a3_eg: "GIN",
        adm0_a3_ma: "GIN",
        adm0_a3_pt: "GIN",
        adm0_a3_ar: "GIN",
        adm0_a3_jp: "GIN",
        adm0_a3_ko: "GIN",
        adm0_a3_vn: "GIN",
        adm0_a3_tr: "GIN",
        adm0_a3_id: "GIN",
        adm0_a3_pl: "GIN",
        adm0_a3_gr: "GIN",
        adm0_a3_it: "GIN",
        adm0_a3_nl: "GIN",
        adm0_a3_se: "GIN",
        adm0_a3_bd: "GIN",
        adm0_a3_ua: "GIN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -10.016402,
        label_y: 10.618516,
        ne_id: 1159320795,
        wikidataid: "Q1006",
        name_ar: "غينيا",
        name_bn: "গিনি",
        name_de: "Guinea",
        name_en: "Guinea",
        name_es: "Guinea",
        name_fa: "گینه",
        name_fr: "Guinée",
        name_el: "Γουινέα",
        name_he: "גינאה",
        name_hi: "गिनी",
        name_hu: "Guinea",
        name_id: "Guinea",
        name_it: "Guinea",
        name_ja: "ギニア",
        name_ko: "기니",
        name_nl: "Guinee",
        name_pl: "Gwinea",
        name_pt: "Guiné",
        name_ru: "Гвинея",
        name_sv: "Guinea",
        name_tr: "Gine",
        name_uk: "Гвінея",
        name_ur: "جمہوریہ گنی",
        name_vi: "Guinée",
        name_zh: "几内亚",
        name_zht: "幾內亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GIN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.700476040084325, 12.586182969610194],
            [-13.217818162478238, 12.575873521367967],
            [-12.499050665730564, 12.332089952031057],
            [-12.27859900557344, 12.354440008997287],
            [-12.203564825885634, 12.465647691289405],
            [-11.658300950557932, 12.386582749882836],
            [-11.51394283695059, 12.442987575729418],
            [-11.456168585648271, 12.076834214725338],
            [-11.297573614944511, 12.077971096235771],
            [-11.03655595543826, 12.211244615116515],
            [-10.870829637078215, 12.17788747807211],
            [-10.593223842806282, 11.92397532800598],
            [-10.165213792348837, 11.844083563682744],
            [-9.890992804392013, 12.060478623904972],
            [-9.567911749703214, 12.194243068892476],
            [-9.327616339546012, 12.334286200403454],
            [-9.127473517279583, 12.308060411015333],
            [-8.90526485842453, 12.088358059126437],
            [-8.786099005559464, 11.812560939984706],
            [-8.376304897484914, 11.393645941610629],
            [-8.581305304386774, 11.136245632364805],
            [-8.620321010767128, 10.810890814655183],
            [-8.407310756860028, 10.909256903522762],
            [-8.282357143578281, 10.792597357623846],
            [-8.33537716310974, 10.494811916541934],
            [-8.029943610048619, 10.206534939001713],
            [-8.229337124046822, 10.1290202905639],
            [-8.30961646161225, 9.789531968622441],
            [-8.07911373537435, 9.376223863152035],
            [-7.832100389019188, 8.575704250518626],
            [-8.20349890790088, 8.455453192575447],
            [-8.299048631208564, 8.316443589710303],
            [-8.221792364932199, 8.123328762235573],
            [-8.280703497744938, 7.687179673692157],
            [-8.439298468448698, 7.686042792181738],
            [-8.722123582382125, 7.71167430259851],
            [-8.926064622422004, 7.309037380396376],
            [-9.208786383490846, 7.313920803247953],
            [-9.40334815106975, 7.526905218938907],
            [-9.337279832384581, 7.928534450711354],
            [-9.755342169625834, 8.541055202666925],
            [-10.016566534861255, 8.428503933135232],
            [-10.23009355309128, 8.406205552601293],
            [-10.505477260774668, 8.348896389189605],
            [-10.494315151399633, 8.715540676300435],
            [-10.654770473665891, 8.977178452994195],
            [-10.622395188835041, 9.267910061068278],
            [-10.839151984083301, 9.688246161330369],
            [-11.11748124840733, 10.045872911006285],
            [-11.917277390988659, 10.046983954300558],
            [-12.150338100625005, 9.858571682164381],
            [-12.425928514037565, 9.835834051955956],
            [-12.59671912276221, 9.62018830000197],
            [-12.71195756677308, 9.342711696810767],
            [-13.246550258832515, 8.903048610871508],
            [-13.685153977909792, 9.49474376061346],
            [-14.074044969122282, 9.886166897008252],
            [-14.33007585291237, 10.015719712763968],
            [-14.579698859098258, 10.214467271358515],
            [-14.693231980843505, 10.656300767454042],
            [-14.839553798877944, 10.87657156009814],
            [-15.130311245168173, 11.040411688679526],
            [-14.685687221728898, 11.527823798056488],
            [-14.382191534878729, 11.509271958863692],
            [-14.121406419317779, 11.677117010947697],
            [-13.900799729863776, 11.678718980348748],
            [-13.743160773157413, 11.811269029177412],
            [-13.828271857142125, 12.142644151249044],
            [-13.718743658899513, 12.24718557377551],
            [-13.700476040084325, 12.586182969610194],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Liberia",
        sov_a3: "LBR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Liberia",
        adm0_a3: "LBR",
        geou_dif: 0,
        geounit: "Liberia",
        gu_a3: "LBR",
        su_dif: 0,
        subunit: "Liberia",
        su_a3: "LBR",
        brk_diff: 0,
        name: "Liberia",
        name_long: "Liberia",
        brk_a3: "LBR",
        brk_name: "Liberia",
        brk_group: null,
        abbrev: "Liberia",
        postal: "LR",
        formal_en: "Republic of Liberia",
        formal_fr: null,
        name_ciawf: "Liberia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Liberia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 4937374,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 3070,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "LI",
        iso_a2: "LR",
        iso_a2_eh: "LR",
        iso_a3: "LBR",
        iso_a3_eh: "LBR",
        iso_n3: "430",
        iso_n3_eh: "430",
        un_a3: "430",
        wb_a2: "LR",
        wb_a3: "LBR",
        woe_id: 23424876,
        woe_id_eh: 23424876,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LBR",
        adm0_diff: null,
        adm0_tlc: "LBR",
        adm0_a3_us: "LBR",
        adm0_a3_fr: "LBR",
        adm0_a3_ru: "LBR",
        adm0_a3_es: "LBR",
        adm0_a3_cn: "LBR",
        adm0_a3_tw: "LBR",
        adm0_a3_in: "LBR",
        adm0_a3_np: "LBR",
        adm0_a3_pk: "LBR",
        adm0_a3_de: "LBR",
        adm0_a3_gb: "LBR",
        adm0_a3_br: "LBR",
        adm0_a3_il: "LBR",
        adm0_a3_ps: "LBR",
        adm0_a3_sa: "LBR",
        adm0_a3_eg: "LBR",
        adm0_a3_ma: "LBR",
        adm0_a3_pt: "LBR",
        adm0_a3_ar: "LBR",
        adm0_a3_jp: "LBR",
        adm0_a3_ko: "LBR",
        adm0_a3_vn: "LBR",
        adm0_a3_tr: "LBR",
        adm0_a3_id: "LBR",
        adm0_a3_pl: "LBR",
        adm0_a3_gr: "LBR",
        adm0_a3_it: "LBR",
        adm0_a3_nl: "LBR",
        adm0_a3_se: "LBR",
        adm0_a3_bd: "LBR",
        adm0_a3_ua: "LBR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -9.460379,
        label_y: 6.447177,
        ne_id: 1159321015,
        wikidataid: "Q1014",
        name_ar: "ليبيريا",
        name_bn: "লাইবেরিয়া",
        name_de: "Liberia",
        name_en: "Liberia",
        name_es: "Liberia",
        name_fa: "لیبریا",
        name_fr: "Liberia",
        name_el: "Λιβερία",
        name_he: "ליבריה",
        name_hi: "लाइबेरिया",
        name_hu: "Libéria",
        name_id: "Liberia",
        name_it: "Liberia",
        name_ja: "リベリア",
        name_ko: "라이베리아",
        name_nl: "Liberia",
        name_pl: "Liberia",
        name_pt: "Libéria",
        name_ru: "Либерия",
        name_sv: "Liberia",
        name_tr: "Liberya",
        name_uk: "Ліберія",
        name_ur: "لائبیریا",
        name_vi: "Liberia",
        name_zh: "利比里亚",
        name_zht: "賴比瑞亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LBR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.439298468448698, 7.686042792181738],
            [-8.48544552248535, 7.39520783124307],
            [-8.385451626000574, 6.911800645368743],
            [-8.60288021486862, 6.46756419517166],
            [-8.311347622094019, 6.193033148621083],
            [-7.993692592795881, 6.126189683451543],
            [-7.570152553731688, 5.707352199725904],
            [-7.539715135111763, 5.313345241716519],
            [-7.635368211284031, 5.188159084489456],
            [-7.71215938966975, 4.364565944837722],
            [-7.974107224957251, 4.355755113131963],
            [-9.004793667018674, 4.8324185245922],
            [-9.913420376006684, 5.593560695819207],
            [-10.765383876986645, 6.140710760925558],
            [-11.438779466182055, 6.785916856305747],
            [-11.19980180504828, 7.105845648624737],
            [-11.146704270868383, 7.396706447779536],
            [-10.69559485517648, 7.939464016141088],
            [-10.23009355309128, 8.406205552601293],
            [-10.016566534861255, 8.428503933135232],
            [-9.755342169625834, 8.541055202666925],
            [-9.337279832384581, 7.928534450711354],
            [-9.40334815106975, 7.526905218938907],
            [-9.208786383490846, 7.313920803247953],
            [-8.926064622422004, 7.309037380396376],
            [-8.722123582382125, 7.71167430259851],
            [-8.439298468448698, 7.686042792181738],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Sudan",
        sov_a3: "SDN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sudan",
        adm0_a3: "SDN",
        geou_dif: 0,
        geounit: "Sudan",
        gu_a3: "SDN",
        su_dif: 0,
        subunit: "Sudan",
        su_a3: "SDN",
        brk_diff: 0,
        name: "Sudan",
        name_long: "Sudan",
        brk_a3: "SDN",
        brk_name: "Sudan",
        brk_group: null,
        abbrev: "Sudan",
        postal: "SD",
        formal_en: "Republic of the Sudan",
        formal_fr: null,
        name_ciawf: "Sudan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sudan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 4,
        mapcolor13: 1,
        pop_est: 42813238,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 30513,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "SU",
        iso_a2: "SD",
        iso_a2_eh: "SD",
        iso_a3: "SDN",
        iso_a3_eh: "SDN",
        iso_n3: "729",
        iso_n3_eh: "729",
        un_a3: "729",
        wb_a2: "SD",
        wb_a3: "SDN",
        woe_id: -90,
        woe_id_eh: 23424952,
        woe_note: "Almost all FLickr photos are in the north.",
        adm0_iso: "SDZ",
        adm0_diff: null,
        adm0_tlc: "SDZ",
        adm0_a3_us: "SDN",
        adm0_a3_fr: "SDN",
        adm0_a3_ru: "SDN",
        adm0_a3_es: "SDN",
        adm0_a3_cn: "SDN",
        adm0_a3_tw: "SDN",
        adm0_a3_in: "SDN",
        adm0_a3_np: "SDN",
        adm0_a3_pk: "SDN",
        adm0_a3_de: "SDN",
        adm0_a3_gb: "SDN",
        adm0_a3_br: "SDN",
        adm0_a3_il: "SDN",
        adm0_a3_ps: "SDN",
        adm0_a3_sa: "SDN",
        adm0_a3_eg: "SDN",
        adm0_a3_ma: "SDN",
        adm0_a3_pt: "SDN",
        adm0_a3_ar: "SDN",
        adm0_a3_jp: "SDN",
        adm0_a3_ko: "SDN",
        adm0_a3_vn: "SDN",
        adm0_a3_tr: "SDN",
        adm0_a3_id: "SDN",
        adm0_a3_pl: "SDN",
        adm0_a3_gr: "SDN",
        adm0_a3_it: "SDN",
        adm0_a3_nl: "SDN",
        adm0_a3_se: "SDN",
        adm0_a3_bd: "SDN",
        adm0_a3_ua: "SDN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: 29.260657,
        label_y: 16.330746,
        ne_id: 1159321229,
        wikidataid: "Q1049",
        name_ar: "السودان",
        name_bn: "সুদান",
        name_de: "Sudan",
        name_en: "Sudan",
        name_es: "Sudán",
        name_fa: "سودان",
        name_fr: "Soudan",
        name_el: "Σουδάν",
        name_he: "סודאן",
        name_hi: "सूडान",
        name_hu: "Szudán",
        name_id: "Sudan",
        name_it: "Sudan",
        name_ja: "スーダン",
        name_ko: "수단",
        name_nl: "Soedan",
        name_pl: "Sudan",
        name_pt: "Sudão",
        name_ru: "Судан",
        name_sv: "Sudan",
        name_tr: "Sudan",
        name_uk: "Судан",
        name_ur: "سوڈان",
        name_vi: "Sudan",
        name_zh: "苏丹",
        name_zht: "蘇丹",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SDN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.567369012152085, 8.229187933785468],
            [23.805813429466752, 8.666318874542526],
            [23.459012892355986, 8.954285793488893],
            [23.394779087017184, 9.265067857292223],
            [23.55724979014283, 9.681218166538684],
            [23.554304233502194, 10.089255275915308],
            [22.97754357269261, 10.71446259199854],
            [22.864165480244225, 11.142395127807546],
            [22.87622, 11.384610000000123],
            [22.50869, 11.67936],
            [22.49762, 12.26024],
            [22.28801, 12.64605],
            [21.93681, 12.588180000000136],
            [22.03759, 12.95546],
            [22.29658, 13.37232],
            [22.18329, 13.78648],
            [22.51202, 14.09318],
            [22.30351, 14.32682],
            [22.56795000000011, 14.944290000000137],
            [23.024590000000103, 15.68072],
            [23.886890000000108, 15.61084],
            [23.83766000000014, 19.580470000000105],
            [23.850000000000136, 20],
            [25.000000000000114, 20.00304],
            [25, 22],
            [29.02, 22],
            [32.9, 22],
            [36.86623, 22],
            [37.1887200000001, 21.01885],
            [36.96941, 20.83744000000013],
            [37.11470000000014, 19.80796],
            [37.4817900000001, 18.61409],
            [37.86276, 18.36786],
            [38.410089959473225, 17.998307399970315],
            [37.90400000000011, 17.42754],
            [37.16747, 17.263140000000135],
            [36.852530000000115, 16.95655],
            [36.75389, 16.29186],
            [36.32322, 14.82249],
            [36.42951, 14.42211],
            [36.27022, 13.563330000000121],
            [35.86363, 12.57828],
            [35.26049, 12.08286],
            [34.83163000000013, 11.318960000000118],
            [34.73115000000013, 10.910170000000107],
            [34.25745, 10.63009],
            [33.96162, 9.58358],
            [33.97498, 8.68456],
            [33.963392794971185, 9.464285229420625],
            [33.82496348090751, 9.484060845715362],
            [33.84213085302815, 9.981914637215993],
            [33.72195924818311, 10.325262079630193],
            [33.206938084561784, 10.720111638406593],
            [33.086766479716744, 11.441141267476496],
            [33.206938084561784, 12.179338268667095],
            [32.743419037302544, 12.248007757149992],
            [32.67474954881965, 12.02483191958072],
            [32.073891524594785, 11.973329803218519],
            [32.31423473428475, 11.68148447716652],
            [32.400071594888345, 11.080626452941488],
            [31.850715687025513, 10.531270545078826],
            [31.35286189552488, 9.810240916008695],
            [30.837840731903384, 9.70723668328452],
            [29.996639497988554, 10.290927335388687],
            [29.61895731133285, 10.084918869940225],
            [29.515953078608618, 9.793073543888056],
            [29.000931914987177, 9.60423245056029],
            [28.966597170745786, 9.398223985111656],
            [27.970889587744352, 9.398223985111656],
            [27.833550610778786, 9.60423245056029],
            [27.112520981708883, 9.638567194801624],
            [26.752006167173818, 9.466893473594496],
            [26.477328213242515, 9.552730334198088],
            [25.962307049621018, 10.136420986302426],
            [25.790633328413946, 10.411098940233728],
            [25.069603699343986, 10.273759963267992],
            [24.794925745412684, 9.810240916008695],
            [24.53741516360202, 8.91753756573172],
            [24.19406772118765, 8.728696472403897],
            [23.886979580860668, 8.619729712933065],
            [24.567369012152085, 8.229187933785468],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Central African Republic",
        sov_a3: "CAF",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Central African Republic",
        adm0_a3: "CAF",
        geou_dif: 0,
        geounit: "Central African Republic",
        gu_a3: "CAF",
        su_dif: 0,
        subunit: "Central African Republic",
        su_a3: "CAF",
        brk_diff: 0,
        name: "Central African Rep.",
        name_long: "Central African Republic",
        brk_a3: "CAF",
        brk_name: "Central African Rep.",
        brk_group: null,
        abbrev: "C.A.R.",
        postal: "CF",
        formal_en: "Central African Republic",
        formal_fr: null,
        name_ciawf: "Central African Republic",
        note_adm0: null,
        note_brk: null,
        name_sort: "Central African Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 9,
        pop_est: 4745185,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 2220,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CT",
        iso_a2: "CF",
        iso_a2_eh: "CF",
        iso_a3: "CAF",
        iso_a3_eh: "CAF",
        iso_n3: "140",
        iso_n3_eh: "140",
        un_a3: "140",
        wb_a2: "CF",
        wb_a3: "CAF",
        woe_id: 23424792,
        woe_id_eh: 23424792,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CAF",
        adm0_diff: null,
        adm0_tlc: "CAF",
        adm0_a3_us: "CAF",
        adm0_a3_fr: "CAF",
        adm0_a3_ru: "CAF",
        adm0_a3_es: "CAF",
        adm0_a3_cn: "CAF",
        adm0_a3_tw: "CAF",
        adm0_a3_in: "CAF",
        adm0_a3_np: "CAF",
        adm0_a3_pk: "CAF",
        adm0_a3_de: "CAF",
        adm0_a3_gb: "CAF",
        adm0_a3_br: "CAF",
        adm0_a3_il: "CAF",
        adm0_a3_ps: "CAF",
        adm0_a3_sa: "CAF",
        adm0_a3_eg: "CAF",
        adm0_a3_ma: "CAF",
        adm0_a3_pt: "CAF",
        adm0_a3_ar: "CAF",
        adm0_a3_jp: "CAF",
        adm0_a3_ko: "CAF",
        adm0_a3_vn: "CAF",
        adm0_a3_tr: "CAF",
        adm0_a3_id: "CAF",
        adm0_a3_pl: "CAF",
        adm0_a3_gr: "CAF",
        adm0_a3_it: "CAF",
        adm0_a3_nl: "CAF",
        adm0_a3_se: "CAF",
        adm0_a3_bd: "CAF",
        adm0_a3_ua: "CAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 20,
        long_len: 24,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 20.906897,
        label_y: 6.989681,
        ne_id: 1159320463,
        wikidataid: "Q929",
        name_ar: "جمهورية أفريقيا الوسطى",
        name_bn: "মধ্য আফ্রিকান প্রজাতন্ত্র",
        name_de: "Zentralafrikanische Republik",
        name_en: "Central African Republic",
        name_es: "República Centroafricana",
        name_fa: "جمهوری آفریقای مرکزی",
        name_fr: "République centrafricaine",
        name_el: "Κεντροαφρικανική Δημοκρατία",
        name_he: "הרפובליקה המרכז-אפריקאית",
        name_hi: "मध्य अफ़्रीकी गणराज्य",
        name_hu: "Közép-afrikai Köztársaság",
        name_id: "Republik Afrika Tengah",
        name_it: "Repubblica Centrafricana",
        name_ja: "中央アフリカ共和国",
        name_ko: "중앙아프리카 공화국",
        name_nl: "Centraal-Afrikaanse Republiek",
        name_pl: "Republika Środkowoafrykańska",
        name_pt: "República Centro-Africana",
        name_ru: "Центральноафриканская Республика",
        name_sv: "Centralafrikanska republiken",
        name_tr: "Orta Afrika Cumhuriyeti",
        name_uk: "Центральноафриканська Республіка",
        name_ur: "وسطی افریقی جمہوریہ",
        name_vi: "Cộng hòa Trung Phi",
        name_zh: "中非共和国",
        name_zht: "中非共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CAF.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.37422610851749, 5.233944403500061],
            [27.04406538260471, 5.127852688004836],
            [26.402760857862543, 5.150874538590871],
            [25.650455356557472, 5.256087754737123],
            [25.278798455514305, 5.170408229997192],
            [25.12883344900328, 4.92724477784779],
            [24.805028924262416, 4.89724660890235],
            [24.410531040146253, 5.10878408448913],
            [23.29721398285014, 4.609693101414223],
            [22.841479526468106, 4.710126247573484],
            [22.70412356943629, 4.633050848810157],
            [22.405123732195538, 4.029160061047321],
            [21.659122755630023, 4.22434194581372],
            [20.927591180106276, 4.322785549329737],
            [20.290679152108936, 4.691677761245288],
            [19.46778364429315, 5.03152781821278],
            [18.93231245288476, 4.709506130385975],
            [18.54298221199778, 4.201785183118318],
            [18.45306521980993, 3.504385891123349],
            [17.809900343505262, 3.56019643799857],
            [17.133042433346304, 3.728196519379452],
            [16.537058139724138, 3.198254706226279],
            [16.012852410555354, 2.267639675298085],
            [15.907380812247652, 2.557389431158612],
            [15.862732374747482, 3.013537298998983],
            [15.405395948964383, 3.33530060466434],
            [15.036219516671252, 3.851367295747124],
            [14.950953403389661, 4.210389309094921],
            [14.47837243008047, 4.732605495620447],
            [14.558935988023507, 5.03059764243153],
            [14.459407179429348, 5.4517605656103],
            [14.536560092841114, 6.22695872642069],
            [14.776545444404576, 6.408498033062045],
            [15.279460483469109, 7.421924546737969],
            [16.106231723706742, 7.497087917506462],
            [16.290561557691888, 7.754307359239419],
            [16.456184523187346, 7.734773667832968],
            [16.705988396886255, 7.508327541529979],
            [17.964929640380888, 7.890914008002994],
            [18.38955488452322, 8.281303615751824],
            [18.911021762780507, 8.630894680206353],
            [18.812009718509273, 8.982914536978598],
            [19.09400800952602, 9.07484691002584],
            [20.05968549976427, 9.012706000194854],
            [21.000868361096167, 9.475985215691509],
            [21.723821648859456, 10.567055568885976],
            [22.23112918466876, 10.97188873946061],
            [22.864165480244225, 11.142395127807546],
            [22.97754357269261, 10.71446259199854],
            [23.554304233502194, 10.089255275915308],
            [23.55724979014283, 9.681218166538684],
            [23.394779087017184, 9.265067857292223],
            [23.459012892355986, 8.954285793488893],
            [23.805813429466752, 8.666318874542526],
            [24.567369012152085, 8.229187933785468],
            [25.11493248871679, 7.825104071479174],
            [25.124130893664727, 7.500085150579437],
            [25.79664798351118, 6.979315904158071],
            [26.213418409945117, 6.546603298362072],
            [26.465909458123235, 5.94671743410187],
            [27.21340905122517, 5.550953477394557],
            [27.37422610851749, 5.233944403500061],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Djibouti",
        sov_a3: "DJI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Djibouti",
        adm0_a3: "DJI",
        geou_dif: 0,
        geounit: "Djibouti",
        gu_a3: "DJI",
        su_dif: 0,
        subunit: "Djibouti",
        su_a3: "DJI",
        brk_diff: 0,
        name: "Djibouti",
        name_long: "Djibouti",
        brk_a3: "DJI",
        brk_name: "Djibouti",
        brk_group: null,
        abbrev: "Dji.",
        postal: "DJ",
        formal_en: "Republic of Djibouti",
        formal_fr: null,
        name_ciawf: "Djibouti",
        note_adm0: null,
        note_brk: null,
        name_sort: "Djibouti",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 973560,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 3324,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "DJ",
        iso_a2: "DJ",
        iso_a2_eh: "DJ",
        iso_a3: "DJI",
        iso_a3_eh: "DJI",
        iso_n3: "262",
        iso_n3_eh: "262",
        un_a3: "262",
        wb_a2: "DJ",
        wb_a3: "DJI",
        woe_id: 23424797,
        woe_id_eh: 23424797,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DJI",
        adm0_diff: null,
        adm0_tlc: "DJI",
        adm0_a3_us: "DJI",
        adm0_a3_fr: "DJI",
        adm0_a3_ru: "DJI",
        adm0_a3_es: "DJI",
        adm0_a3_cn: "DJI",
        adm0_a3_tw: "DJI",
        adm0_a3_in: "DJI",
        adm0_a3_np: "DJI",
        adm0_a3_pk: "DJI",
        adm0_a3_de: "DJI",
        adm0_a3_gb: "DJI",
        adm0_a3_br: "DJI",
        adm0_a3_il: "DJI",
        adm0_a3_ps: "DJI",
        adm0_a3_sa: "DJI",
        adm0_a3_eg: "DJI",
        adm0_a3_ma: "DJI",
        adm0_a3_pt: "DJI",
        adm0_a3_ar: "DJI",
        adm0_a3_jp: "DJI",
        adm0_a3_ko: "DJI",
        adm0_a3_vn: "DJI",
        adm0_a3_tr: "DJI",
        adm0_a3_id: "DJI",
        adm0_a3_pl: "DJI",
        adm0_a3_gr: "DJI",
        adm0_a3_it: "DJI",
        adm0_a3_nl: "DJI",
        adm0_a3_se: "DJI",
        adm0_a3_bd: "DJI",
        adm0_a3_ua: "DJI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 42.498825,
        label_y: 11.976343,
        ne_id: 1159320541,
        wikidataid: "Q977",
        name_ar: "جيبوتي",
        name_bn: "জিবুতি",
        name_de: "Dschibuti",
        name_en: "Djibouti",
        name_es: "Yibuti",
        name_fa: "جیبوتی",
        name_fr: "Djibouti",
        name_el: "Τζιμπουτί",
        name_he: "ג'יבוטי",
        name_hi: "जिबूती",
        name_hu: "Dzsibuti",
        name_id: "Djibouti",
        name_it: "Gibuti",
        name_ja: "ジブチ",
        name_ko: "지부티",
        name_nl: "Djibouti",
        name_pl: "Dżibuti",
        name_pt: "Djibouti",
        name_ru: "Джибути",
        name_sv: "Djibouti",
        name_tr: "Cibuti",
        name_uk: "Джибуті",
        name_ur: "جبوتی",
        name_vi: "Djibouti",
        name_zh: "吉布提",
        name_zht: "吉布地",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DJI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.35156000000012, 12.542230000000131],
            [42.77964236834475, 12.455415757695675],
            [43.08122602720016, 12.699638576707116],
            [43.31785241066467, 12.390148423711025],
            [43.286381463398925, 11.974928290245884],
            [42.715873650896526, 11.735640570518342],
            [43.14530480324214, 11.462039699748857],
            [42.77685184100096, 10.92687856693442],
            [42.55493000000013, 11.105110000000195],
            [42.31414000000012, 11.0342],
            [41.755570000000205, 11.050910000000101],
            [41.73959000000019, 11.355110000000138],
            [41.66176000000013, 11.6312],
            [42.000000000000114, 12.100000000000136],
            [42.35156000000012, 12.542230000000131],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ivory Coast",
        sov_a3: "CIV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ivory Coast",
        adm0_a3: "CIV",
        geou_dif: 0,
        geounit: "Ivory Coast",
        gu_a3: "CIV",
        su_dif: 0,
        subunit: "Ivory Coast",
        su_a3: "CIV",
        brk_diff: 0,
        name: "Côte d'Ivoire",
        name_long: "Côte d'Ivoire",
        brk_a3: "CIV",
        brk_name: "Côte d'Ivoire",
        brk_group: null,
        abbrev: "I.C.",
        postal: "CI",
        formal_en: "Republic of Ivory Coast",
        formal_fr: "Republic of Cote D'Ivoire",
        name_ciawf: "Cote D'ivoire",
        note_adm0: null,
        note_brk: null,
        name_sort: "Côte d'Ivoire",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 3,
        mapcolor13: 3,
        pop_est: 25716544,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 58539,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "IV",
        iso_a2: "CI",
        iso_a2_eh: "CI",
        iso_a3: "CIV",
        iso_a3_eh: "CIV",
        iso_n3: "384",
        iso_n3_eh: "384",
        un_a3: "384",
        wb_a2: "CI",
        wb_a3: "CIV",
        woe_id: 23424854,
        woe_id_eh: 23424854,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CIV",
        adm0_diff: null,
        adm0_tlc: "CIV",
        adm0_a3_us: "CIV",
        adm0_a3_fr: "CIV",
        adm0_a3_ru: "CIV",
        adm0_a3_es: "CIV",
        adm0_a3_cn: "CIV",
        adm0_a3_tw: "CIV",
        adm0_a3_in: "CIV",
        adm0_a3_np: "CIV",
        adm0_a3_pk: "CIV",
        adm0_a3_de: "CIV",
        adm0_a3_gb: "CIV",
        adm0_a3_br: "CIV",
        adm0_a3_il: "CIV",
        adm0_a3_ps: "CIV",
        adm0_a3_sa: "CIV",
        adm0_a3_eg: "CIV",
        adm0_a3_ma: "CIV",
        adm0_a3_pt: "CIV",
        adm0_a3_ar: "CIV",
        adm0_a3_jp: "CIV",
        adm0_a3_ko: "CIV",
        adm0_a3_vn: "CIV",
        adm0_a3_tr: "CIV",
        adm0_a3_id: "CIV",
        adm0_a3_pl: "CIV",
        adm0_a3_gr: "CIV",
        adm0_a3_it: "CIV",
        adm0_a3_nl: "CIV",
        adm0_a3_se: "CIV",
        adm0_a3_bd: "CIV",
        adm0_a3_ua: "CIV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 13,
        long_len: 13,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: -5.568618,
        label_y: 7.49139,
        ne_id: 1159320507,
        wikidataid: "Q1008",
        name_ar: "ساحل العاج",
        name_bn: "কোত দিভোয়ার",
        name_de: "Elfenbeinküste",
        name_en: "Ivory Coast",
        name_es: "Costa de Marfil",
        name_fa: "ساحل عاج",
        name_fr: "Côte d'Ivoire",
        name_el: "Ακτή Ελεφαντοστού",
        name_he: "חוף השנהב",
        name_hi: "कोत दिव्वार",
        name_hu: "Elefántcsontpart",
        name_id: "Pantai Gading",
        name_it: "Costa d'Avorio",
        name_ja: "コートジボワール",
        name_ko: "코트디부아르",
        name_nl: "Ivoorkust",
        name_pl: "Wybrzeże Kości Słoniowej",
        name_pt: "Costa do Marfim",
        name_ru: "Кот-д’Ивуар",
        name_sv: "Elfenbenskusten",
        name_tr: "Fildişi Sahili",
        name_uk: "Кот-д'Івуар",
        name_ur: "کوت داوواغ",
        name_vi: "Bờ Biển Ngà",
        name_zh: "科特迪瓦",
        name_zht: "象牙海岸",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CIV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.029943610048619, 10.206534939001713],
            [-7.899589809592372, 10.297382106970828],
            [-7.622759161804809, 10.147236232946796],
            [-6.850506557635057, 10.138993841996239],
            [-6.666460944027548, 10.430810655148449],
            [-6.493965013037268, 10.411302801958271],
            [-6.205222947606431, 10.524060777219134],
            [-6.050452032892267, 10.096360785355444],
            [-5.816926235365287, 10.222554633012194],
            [-5.404341599946974, 10.370736802609146],
            [-4.954653286143099, 10.152713934769736],
            [-4.779883592131966, 9.821984768101743],
            [-4.330246954760383, 9.610834865757141],
            [-3.980449184576685, 9.8623440617217],
            [-3.511898972986273, 9.90032623945622],
            [-2.827496303712707, 9.642460842319778],
            [-2.562189500326241, 8.219627793811483],
            [-2.983584967450327, 7.379704901555513],
            [-3.244370083011262, 6.250471503113502],
            [-2.81070146321784, 5.38905121502411],
            [-2.856125047202397, 4.994475816259509],
            [-3.311084357100071, 4.984295559098015],
            [-4.008819545904942, 5.179813340674315],
            [-4.649917364917911, 5.168263658057086],
            [-5.834496222344526, 4.993700669775137],
            [-6.528769090185847, 4.705087795425015],
            [-7.518941209330436, 4.338288479017308],
            [-7.71215938966975, 4.364565944837722],
            [-7.635368211284031, 5.188159084489456],
            [-7.539715135111763, 5.313345241716519],
            [-7.570152553731688, 5.707352199725904],
            [-7.993692592795881, 6.126189683451543],
            [-8.311347622094019, 6.193033148621083],
            [-8.60288021486862, 6.46756419517166],
            [-8.385451626000574, 6.911800645368743],
            [-8.48544552248535, 7.39520783124307],
            [-8.439298468448698, 7.686042792181738],
            [-8.280703497744938, 7.687179673692157],
            [-8.221792364932199, 8.123328762235573],
            [-8.299048631208564, 8.316443589710303],
            [-8.20349890790088, 8.455453192575447],
            [-7.832100389019188, 8.575704250518626],
            [-8.07911373537435, 9.376223863152035],
            [-8.30961646161225, 9.789531968622441],
            [-8.229337124046822, 10.1290202905639],
            [-8.029943610048619, 10.206534939001713],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Eritrea",
        sov_a3: "ERI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Eritrea",
        adm0_a3: "ERI",
        geou_dif: 0,
        geounit: "Eritrea",
        gu_a3: "ERI",
        su_dif: 0,
        subunit: "Eritrea",
        su_a3: "ERI",
        brk_diff: 0,
        name: "Eritrea",
        name_long: "Eritrea",
        brk_a3: "ERI",
        brk_name: "Eritrea",
        brk_group: null,
        abbrev: "Erit.",
        postal: "ER",
        formal_en: "State of Eritrea",
        formal_fr: null,
        name_ciawf: "Eritrea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Eritrea",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 6081196,
        pop_rank: 13,
        pop_year: 2020,
        gdp_md: 2065,
        gdp_year: 2011,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "ER",
        iso_a2: "ER",
        iso_a2_eh: "ER",
        iso_a3: "ERI",
        iso_a3_eh: "ERI",
        iso_n3: "232",
        iso_n3_eh: "232",
        un_a3: "232",
        wb_a2: "ER",
        wb_a3: "ERI",
        woe_id: 23424806,
        woe_id_eh: 23424806,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ERI",
        adm0_diff: null,
        adm0_tlc: "ERI",
        adm0_a3_us: "ERI",
        adm0_a3_fr: "ERI",
        adm0_a3_ru: "ERI",
        adm0_a3_es: "ERI",
        adm0_a3_cn: "ERI",
        adm0_a3_tw: "ERI",
        adm0_a3_in: "ERI",
        adm0_a3_np: "ERI",
        adm0_a3_pk: "ERI",
        adm0_a3_de: "ERI",
        adm0_a3_gb: "ERI",
        adm0_a3_br: "ERI",
        adm0_a3_il: "ERI",
        adm0_a3_ps: "ERI",
        adm0_a3_sa: "ERI",
        adm0_a3_eg: "ERI",
        adm0_a3_ma: "ERI",
        adm0_a3_pt: "ERI",
        adm0_a3_ar: "ERI",
        adm0_a3_jp: "ERI",
        adm0_a3_ko: "ERI",
        adm0_a3_vn: "ERI",
        adm0_a3_tr: "ERI",
        adm0_a3_id: "ERI",
        adm0_a3_pl: "ERI",
        adm0_a3_gr: "ERI",
        adm0_a3_it: "ERI",
        adm0_a3_nl: "ERI",
        adm0_a3_se: "ERI",
        adm0_a3_bd: "ERI",
        adm0_a3_ua: "ERI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 38.285566,
        label_y: 15.787401,
        ne_id: 1159320581,
        wikidataid: "Q986",
        name_ar: "إريتريا",
        name_bn: "ইরিত্রিয়া",
        name_de: "Eritrea",
        name_en: "Eritrea",
        name_es: "Eritrea",
        name_fa: "اریتره",
        name_fr: "Érythrée",
        name_el: "Ερυθραία",
        name_he: "אריתריאה",
        name_hi: "इरित्रिया",
        name_hu: "Eritrea",
        name_id: "Eritrea",
        name_it: "Eritrea",
        name_ja: "エリトリア",
        name_ko: "에리트레아",
        name_nl: "Eritrea",
        name_pl: "Erytrea",
        name_pt: "Eritreia",
        name_ru: "Эритрея",
        name_sv: "Eritrea",
        name_tr: "Eritre",
        name_uk: "Еритрея",
        name_ur: "اریتریا",
        name_vi: "Eritrea",
        name_zh: "厄立特里亚",
        name_zht: "厄利垂亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ERI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.42951, 14.42211],
            [36.32322, 14.82249],
            [36.75389, 16.29186],
            [36.852530000000115, 16.95655],
            [37.16747, 17.263140000000135],
            [37.90400000000011, 17.42754],
            [38.410089959473225, 17.998307399970315],
            [38.990622999840014, 16.840626125551694],
            [39.26611006038803, 15.92272349696725],
            [39.814293654140215, 15.435647284400318],
            [41.17927493669765, 14.491079616753211],
            [41.73495161313235, 13.921036892141558],
            [42.27683068214486, 13.343992010954423],
            [42.58957645037526, 13.000421250861905],
            [43.08122602720016, 12.699638576707116],
            [42.77964236834475, 12.455415757695675],
            [42.35156000000012, 12.542230000000131],
            [42.00975, 12.86582],
            [41.59856, 13.452090000000112],
            [41.1552, 13.77333],
            [40.8966, 14.118640000000141],
            [40.02625000000012, 14.51959],
            [39.34061, 14.53155],
            [39.0994, 14.74064],
            [38.51295, 14.50547],
            [37.90607000000011, 14.959430000000168],
            [37.59377, 14.2131],
            [36.42951, 14.42211],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mali",
        sov_a3: "MLI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mali",
        adm0_a3: "MLI",
        geou_dif: 0,
        geounit: "Mali",
        gu_a3: "MLI",
        su_dif: 0,
        subunit: "Mali",
        su_a3: "MLI",
        brk_diff: 0,
        name: "Mali",
        name_long: "Mali",
        brk_a3: "MLI",
        brk_name: "Mali",
        brk_group: null,
        abbrev: "Mali",
        postal: "ML",
        formal_en: "Republic of Mali",
        formal_fr: null,
        name_ciawf: "Mali",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mali",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 19658031,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 17279,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "ML",
        iso_a2: "ML",
        iso_a2_eh: "ML",
        iso_a3: "MLI",
        iso_a3_eh: "MLI",
        iso_n3: "466",
        iso_n3_eh: "466",
        un_a3: "466",
        wb_a2: "ML",
        wb_a3: "MLI",
        woe_id: 23424891,
        woe_id_eh: 23424891,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MLI",
        adm0_diff: null,
        adm0_tlc: "MLI",
        adm0_a3_us: "MLI",
        adm0_a3_fr: "MLI",
        adm0_a3_ru: "MLI",
        adm0_a3_es: "MLI",
        adm0_a3_cn: "MLI",
        adm0_a3_tw: "MLI",
        adm0_a3_in: "MLI",
        adm0_a3_np: "MLI",
        adm0_a3_pk: "MLI",
        adm0_a3_de: "MLI",
        adm0_a3_gb: "MLI",
        adm0_a3_br: "MLI",
        adm0_a3_il: "MLI",
        adm0_a3_ps: "MLI",
        adm0_a3_sa: "MLI",
        adm0_a3_eg: "MLI",
        adm0_a3_ma: "MLI",
        adm0_a3_pt: "MLI",
        adm0_a3_ar: "MLI",
        adm0_a3_jp: "MLI",
        adm0_a3_ko: "MLI",
        adm0_a3_vn: "MLI",
        adm0_a3_tr: "MLI",
        adm0_a3_id: "MLI",
        adm0_a3_pl: "MLI",
        adm0_a3_gr: "MLI",
        adm0_a3_it: "MLI",
        adm0_a3_nl: "MLI",
        adm0_a3_se: "MLI",
        adm0_a3_bd: "MLI",
        adm0_a3_ua: "MLI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: -2.038455,
        label_y: 18.692713,
        ne_id: 1159321063,
        wikidataid: "Q912",
        name_ar: "مالي",
        name_bn: "মালি",
        name_de: "Mali",
        name_en: "Mali",
        name_es: "Malí",
        name_fa: "مالی",
        name_fr: "Mali",
        name_el: "Μάλι",
        name_he: "מאלי",
        name_hi: "माली",
        name_hu: "Mali",
        name_id: "Mali",
        name_it: "Mali",
        name_ja: "マリ共和国",
        name_ko: "말리",
        name_nl: "Mali",
        name_pl: "Mali",
        name_pt: "Mali",
        name_ru: "Мали",
        name_sv: "Mali",
        name_tr: "Mali",
        name_uk: "Малі",
        name_ur: "مالی",
        name_vi: "Mali",
        name_zh: "马里",
        name_zht: "馬利共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MLI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.51394283695059, 12.442987575729418],
            [-11.467899135778524, 12.754518947800975],
            [-11.55339779300543, 13.141213690641067],
            [-11.927716030311615, 13.422075100147396],
            [-12.12488745772126, 13.994727484589788],
            [-12.170750291380301, 14.616834214735505],
            [-11.834207526079467, 14.79909699142894],
            [-11.666078253617854, 15.388208319556298],
            [-11.349095017939504, 15.411256008358478],
            [-10.650791388379417, 15.132745876521426],
            [-10.086846482778213, 15.330485744686271],
            [-9.700255092802706, 15.264107367407362],
            [-9.55023840985939, 15.486496893775438],
            [-5.537744309908447, 15.501689764869257],
            [-5.315277268891933, 16.20185374599184],
            [-5.488522508150439, 16.325102037007966],
            [-5.971128709324248, 20.64083344164763],
            [-6.453786586930335, 24.956590684503425],
            [-4.923337368174231, 24.974574082941],
            [-1.550054897457613, 22.792665920497384],
            [1.823227573259032, 20.610809434486043],
            [2.06099083823392, 20.142233384679486],
            [2.683588494486429, 19.856230170160117],
            [3.1466610042539, 19.693578599521445],
            [3.158133172222705, 19.057364203360038],
            [4.267419467800039, 19.155265204337],
            [4.270209995143802, 16.852227484601215],
            [3.723421665063483, 16.184283759012615],
            [3.638258904646477, 15.568119818580456],
            [2.749992709981484, 15.409524847876696],
            [1.385528191746858, 15.323561102759172],
            [1.015783318698482, 14.96818227788799],
            [0.374892205414682, 14.92890818934613],
            [-0.26625729003058, 14.924308986872148],
            [-0.515854458000348, 15.116157741755728],
            [-1.066363491205664, 14.973815009007765],
            [-2.001035122068771, 14.559008287000893],
            [-2.191824510090385, 14.246417548067356],
            [-2.967694464520577, 13.79815033615151],
            [-3.10370683431276, 13.541266791228594],
            [-3.522802700199861, 13.337661647998615],
            [-4.006390753587226, 13.472485459848116],
            [-4.28040503581488, 13.228443508349741],
            [-4.427166103523803, 12.542645575404295],
            [-5.220941941743121, 11.713858954307227],
            [-5.197842576508648, 11.37514577885014],
            [-5.470564947929006, 10.951269842976048],
            [-5.404341599946974, 10.370736802609146],
            [-5.816926235365287, 10.222554633012194],
            [-6.050452032892267, 10.096360785355444],
            [-6.205222947606431, 10.524060777219134],
            [-6.493965013037268, 10.411302801958271],
            [-6.666460944027548, 10.430810655148449],
            [-6.850506557635057, 10.138993841996239],
            [-7.622759161804809, 10.147236232946796],
            [-7.899589809592372, 10.297382106970828],
            [-8.029943610048619, 10.206534939001713],
            [-8.33537716310974, 10.494811916541934],
            [-8.282357143578281, 10.792597357623846],
            [-8.407310756860028, 10.909256903522762],
            [-8.620321010767128, 10.810890814655183],
            [-8.581305304386774, 11.136245632364805],
            [-8.376304897484914, 11.393645941610629],
            [-8.786099005559464, 11.812560939984706],
            [-8.90526485842453, 12.088358059126437],
            [-9.127473517279583, 12.308060411015333],
            [-9.327616339546012, 12.334286200403454],
            [-9.567911749703214, 12.194243068892476],
            [-9.890992804392013, 12.060478623904972],
            [-10.165213792348837, 11.844083563682744],
            [-10.593223842806282, 11.92397532800598],
            [-10.870829637078215, 12.17788747807211],
            [-11.03655595543826, 12.211244615116515],
            [-11.297573614944511, 12.077971096235771],
            [-11.456168585648271, 12.076834214725338],
            [-11.51394283695059, 12.442987575729418],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Nigeria",
        sov_a3: "NGA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nigeria",
        adm0_a3: "NGA",
        geou_dif: 0,
        geounit: "Nigeria",
        gu_a3: "NGA",
        su_dif: 0,
        subunit: "Nigeria",
        su_a3: "NGA",
        brk_diff: 0,
        name: "Nigeria",
        name_long: "Nigeria",
        brk_a3: "NGA",
        brk_name: "Nigeria",
        brk_group: null,
        abbrev: "Nigeria",
        postal: "NG",
        formal_en: "Federal Republic of Nigeria",
        formal_fr: null,
        name_ciawf: "Nigeria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nigeria",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 2,
        pop_est: 200963599,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 448120,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "NI",
        iso_a2: "NG",
        iso_a2_eh: "NG",
        iso_a3: "NGA",
        iso_a3_eh: "NGA",
        iso_n3: "566",
        iso_n3_eh: "566",
        un_a3: "566",
        wb_a2: "NG",
        wb_a3: "NGA",
        woe_id: 23424908,
        woe_id_eh: 23424908,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NGA",
        adm0_diff: null,
        adm0_tlc: "NGA",
        adm0_a3_us: "NGA",
        adm0_a3_fr: "NGA",
        adm0_a3_ru: "NGA",
        adm0_a3_es: "NGA",
        adm0_a3_cn: "NGA",
        adm0_a3_tw: "NGA",
        adm0_a3_in: "NGA",
        adm0_a3_np: "NGA",
        adm0_a3_pk: "NGA",
        adm0_a3_de: "NGA",
        adm0_a3_gb: "NGA",
        adm0_a3_br: "NGA",
        adm0_a3_il: "NGA",
        adm0_a3_ps: "NGA",
        adm0_a3_sa: "NGA",
        adm0_a3_eg: "NGA",
        adm0_a3_ma: "NGA",
        adm0_a3_pt: "NGA",
        adm0_a3_ar: "NGA",
        adm0_a3_jp: "NGA",
        adm0_a3_ko: "NGA",
        adm0_a3_vn: "NGA",
        adm0_a3_tr: "NGA",
        adm0_a3_id: "NGA",
        adm0_a3_pl: "NGA",
        adm0_a3_gr: "NGA",
        adm0_a3_it: "NGA",
        adm0_a3_nl: "NGA",
        adm0_a3_se: "NGA",
        adm0_a3_bd: "NGA",
        adm0_a3_ua: "NGA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 7.50322,
        label_y: 9.439799,
        ne_id: 1159321089,
        wikidataid: "Q1033",
        name_ar: "نيجيريا",
        name_bn: "নাইজেরিয়া",
        name_de: "Nigeria",
        name_en: "Nigeria",
        name_es: "Nigeria",
        name_fa: "نیجریه",
        name_fr: "Nigeria",
        name_el: "Νιγηρία",
        name_he: "ניגריה",
        name_hi: "नाईजीरिया",
        name_hu: "Nigéria",
        name_id: "Nigeria",
        name_it: "Nigeria",
        name_ja: "ナイジェリア",
        name_ko: "나이지리아",
        name_nl: "Nigeria",
        name_pl: "Nigeria",
        name_pt: "Nigéria",
        name_ru: "Нигерия",
        name_sv: "Nigeria",
        name_tr: "Nijerya",
        name_uk: "Нігерія",
        name_ur: "نائجیریا",
        name_vi: "Nigeria",
        name_zh: "尼日利亚",
        name_zht: "奈及利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NGA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.691701694356254, 6.258817246928629],
            [2.74906253420022, 7.870734361192888],
            [2.723792758809509, 8.50684540448971],
            [2.912308383810256, 9.137607937044322],
            [3.220351596702101, 9.444152533399702],
            [3.705438266625919, 10.063210354040208],
            [3.600070021182802, 10.332186184119408],
            [3.797112257511714, 10.734745591673105],
            [3.572216424177469, 11.32793935795152],
            [3.611180454125559, 11.660167141155968],
            [3.680633579125811, 12.552903347214226],
            [3.967282749048849, 12.956108710171575],
            [4.107945997747322, 13.531215725147831],
            [4.368343540066007, 13.747481594289411],
            [5.443058302440136, 13.865923977102227],
            [6.445426059605637, 13.492768459522678],
            [6.820441928747754, 13.115091254117518],
            [7.330746697630019, 13.0980380314612],
            [7.804671258178786, 13.343526923063747],
            [9.014933302454438, 12.826659247280418],
            [9.524928012742947, 12.851102199754479],
            [10.114814487354693, 13.27725189864941],
            [10.701031935273706, 13.246917832894084],
            [10.989593133191534, 13.387322699431195],
            [11.527803175511394, 13.328980007373588],
            [12.302071160540523, 13.037189032437524],
            [13.083987257548813, 13.596147162322495],
            [13.318701613018561, 13.556356309457826],
            [13.995352817448293, 12.461565253138303],
            [14.181336297266794, 12.483656927943116],
            [14.577177768622533, 12.085360826053503],
            [14.468192172918975, 11.904751695193411],
            [14.415378859116684, 11.572368882692075],
            [13.572949659894562, 10.798565985553566],
            [13.308676385153918, 10.160362046748928],
            [13.167599724997103, 9.640626328973411],
            [12.955467970438974, 9.417771714714704],
            [12.753671502339216, 8.717762762888995],
            [12.218872104550599, 8.305824082874324],
            [12.063946160539558, 7.799808457872302],
            [11.839308709366803, 7.397042344589437],
            [11.74577436691851, 6.981382961449754],
            [11.058787876030351, 6.644426784690594],
            [10.497375115611419, 7.055357774275564],
            [10.118276808318257, 7.03876963950988],
            [9.522705926154401, 6.453482367372117],
            [9.233162876023044, 6.444490668153335],
            [8.757532993208628, 5.479665839047911],
            [8.500287713259695, 4.771982937026849],
            [7.462108188515941, 4.412108262546241],
            [7.082596469764439, 4.464689032403228],
            [6.6980721370806, 4.240594183769517],
            [5.898172641634687, 4.262453314628985],
            [5.362804803090881, 4.887970689305959],
            [5.033574252959369, 5.611802476418234],
            [4.325607130560684, 6.270651149923467],
            [3.574180128604553, 6.258300482605719],
            [2.691701694356254, 6.258817246928629],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Senegal",
        sov_a3: "SEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Senegal",
        adm0_a3: "SEN",
        geou_dif: 0,
        geounit: "Senegal",
        gu_a3: "SEN",
        su_dif: 0,
        subunit: "Senegal",
        su_a3: "SEN",
        brk_diff: 0,
        name: "Senegal",
        name_long: "Senegal",
        brk_a3: "SEN",
        brk_name: "Senegal",
        brk_group: null,
        abbrev: "Sen.",
        postal: "SN",
        formal_en: "Republic of Senegal",
        formal_fr: null,
        name_ciawf: "Senegal",
        note_adm0: null,
        note_brk: null,
        name_sort: "Senegal",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 16296364,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 23578,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "SG",
        iso_a2: "SN",
        iso_a2_eh: "SN",
        iso_a3: "SEN",
        iso_a3_eh: "SEN",
        iso_n3: "686",
        iso_n3_eh: "686",
        un_a3: "686",
        wb_a2: "SN",
        wb_a3: "SEN",
        woe_id: 23424943,
        woe_id_eh: 23424943,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SEN",
        adm0_diff: null,
        adm0_tlc: "SEN",
        adm0_a3_us: "SEN",
        adm0_a3_fr: "SEN",
        adm0_a3_ru: "SEN",
        adm0_a3_es: "SEN",
        adm0_a3_cn: "SEN",
        adm0_a3_tw: "SEN",
        adm0_a3_in: "SEN",
        adm0_a3_np: "SEN",
        adm0_a3_pk: "SEN",
        adm0_a3_de: "SEN",
        adm0_a3_gb: "SEN",
        adm0_a3_br: "SEN",
        adm0_a3_il: "SEN",
        adm0_a3_ps: "SEN",
        adm0_a3_sa: "SEN",
        adm0_a3_eg: "SEN",
        adm0_a3_ma: "SEN",
        adm0_a3_pt: "SEN",
        adm0_a3_ar: "SEN",
        adm0_a3_jp: "SEN",
        adm0_a3_ko: "SEN",
        adm0_a3_vn: "SEN",
        adm0_a3_tr: "SEN",
        adm0_a3_id: "SEN",
        adm0_a3_pl: "SEN",
        adm0_a3_gr: "SEN",
        adm0_a3_it: "SEN",
        adm0_a3_nl: "SEN",
        adm0_a3_se: "SEN",
        adm0_a3_bd: "SEN",
        adm0_a3_ua: "SEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -14.778586,
        label_y: 15.138125,
        ne_id: 1159321243,
        wikidataid: "Q1041",
        name_ar: "السنغال",
        name_bn: "সেনেগাল",
        name_de: "Senegal",
        name_en: "Senegal",
        name_es: "Senegal",
        name_fa: "سنگال",
        name_fr: "Sénégal",
        name_el: "Σενεγάλη",
        name_he: "סנגל",
        name_hi: "सेनेगल",
        name_hu: "Szenegál",
        name_id: "Senegal",
        name_it: "Senegal",
        name_ja: "セネガル",
        name_ko: "세네갈",
        name_nl: "Senegal",
        name_pl: "Senegal",
        name_pt: "Senegal",
        name_ru: "Сенегал",
        name_sv: "Senegal",
        name_tr: "Senegal",
        name_uk: "Сенегал",
        name_ur: "سینیگال",
        name_vi: "Sénégal",
        name_zh: "塞内加尔",
        name_zht: "塞內加爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.71372880702347, 13.594958604379855],
            [-17.126106736712615, 14.373515733289224],
            [-17.62504269049066, 14.729540513564073],
            [-17.18517289882223, 14.919477240452862],
            [-16.700706346085923, 15.621527411354108],
            [-16.463098110407884, 16.13503611903846],
            [-16.12069007004193, 16.455662543193384],
            [-15.623666144258692, 16.369337063049812],
            [-15.135737270558819, 16.587282416240782],
            [-14.577347581428981, 16.59826365810281],
            [-14.099521450242179, 16.304302273010492],
            [-13.435737677453062, 16.03938304286619],
            [-12.830658331747516, 15.303691514542948],
            [-12.170750291380301, 14.616834214735505],
            [-12.12488745772126, 13.994727484589788],
            [-11.927716030311615, 13.422075100147396],
            [-11.55339779300543, 13.141213690641067],
            [-11.467899135778524, 12.754518947800975],
            [-11.51394283695059, 12.442987575729418],
            [-11.658300950557932, 12.386582749882836],
            [-12.203564825885634, 12.465647691289405],
            [-12.27859900557344, 12.354440008997287],
            [-12.499050665730564, 12.332089952031057],
            [-13.217818162478238, 12.575873521367967],
            [-13.700476040084325, 12.586182969610194],
            [-15.54847693527401, 12.628170070847347],
            [-15.816574266004254, 12.515567124883347],
            [-16.147716844130585, 12.547761542201187],
            [-16.677451951554573, 12.384851589401052],
            [-16.841524624081273, 13.15139394780256],
            [-15.931295945692211, 13.130284125211332],
            [-15.691000535534995, 13.270353094938457],
            [-15.511812506562933, 13.278569647672867],
            [-15.141163295949468, 13.509511623585238],
            [-14.712197231494628, 13.298206691943777],
            [-14.277701788784555, 13.280585028532244],
            [-13.844963344772408, 13.505041612192002],
            [-14.046992356817482, 13.79406789800045],
            [-14.376713833055788, 13.625680243377374],
            [-14.687030808968487, 13.630356960499784],
            [-15.08173539881382, 13.876491807505985],
            [-15.39877031092446, 13.86036876063092],
            [-15.624596320039942, 13.62358734786956],
            [-16.71372880702347, 13.594958604379855],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Benin",
        sov_a3: "BEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Benin",
        adm0_a3: "BEN",
        geou_dif: 0,
        geounit: "Benin",
        gu_a3: "BEN",
        su_dif: 0,
        subunit: "Benin",
        su_a3: "BEN",
        brk_diff: 0,
        name: "Benin",
        name_long: "Benin",
        brk_a3: "BEN",
        brk_name: "Benin",
        brk_group: null,
        abbrev: "Benin",
        postal: "BJ",
        formal_en: "Republic of Benin",
        formal_fr: null,
        name_ciawf: "Benin",
        note_adm0: null,
        note_brk: null,
        name_sort: "Benin",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 11801151,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 14390,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BN",
        iso_a2: "BJ",
        iso_a2_eh: "BJ",
        iso_a3: "BEN",
        iso_a3_eh: "BEN",
        iso_n3: "204",
        iso_n3_eh: "204",
        un_a3: "204",
        wb_a2: "BJ",
        wb_a3: "BEN",
        woe_id: 23424764,
        woe_id_eh: 23424764,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BEN",
        adm0_diff: null,
        adm0_tlc: "BEN",
        adm0_a3_us: "BEN",
        adm0_a3_fr: "BEN",
        adm0_a3_ru: "BEN",
        adm0_a3_es: "BEN",
        adm0_a3_cn: "BEN",
        adm0_a3_tw: "BEN",
        adm0_a3_in: "BEN",
        adm0_a3_np: "BEN",
        adm0_a3_pk: "BEN",
        adm0_a3_de: "BEN",
        adm0_a3_gb: "BEN",
        adm0_a3_br: "BEN",
        adm0_a3_il: "BEN",
        adm0_a3_ps: "BEN",
        adm0_a3_sa: "BEN",
        adm0_a3_eg: "BEN",
        adm0_a3_ma: "BEN",
        adm0_a3_pt: "BEN",
        adm0_a3_ar: "BEN",
        adm0_a3_jp: "BEN",
        adm0_a3_ko: "BEN",
        adm0_a3_vn: "BEN",
        adm0_a3_tr: "BEN",
        adm0_a3_id: "BEN",
        adm0_a3_pl: "BEN",
        adm0_a3_gr: "BEN",
        adm0_a3_it: "BEN",
        adm0_a3_nl: "BEN",
        adm0_a3_se: "BEN",
        adm0_a3_bd: "BEN",
        adm0_a3_ua: "BEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 2.352018,
        label_y: 10.324775,
        ne_id: 1159320399,
        wikidataid: "Q962",
        name_ar: "بنين",
        name_bn: "বেনিন",
        name_de: "Benin",
        name_en: "Benin",
        name_es: "Benín",
        name_fa: "بنین",
        name_fr: "Bénin",
        name_el: "Μπενίν",
        name_he: "בנין",
        name_hi: "बेनिन",
        name_hu: "Benin",
        name_id: "Benin",
        name_it: "Benin",
        name_ja: "ベナン",
        name_ko: "베냉",
        name_nl: "Benin",
        name_pl: "Benin",
        name_pt: "Benim",
        name_ru: "Бенин",
        name_sv: "Benin",
        name_tr: "Benin",
        name_uk: "Бенін",
        name_ur: "بینن",
        name_vi: "Bénin",
        name_zh: "贝宁",
        name_zht: "貝南",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.691701694356254, 6.258817246928629],
            [1.865240512712319, 6.142157701029731],
            [1.618950636409238, 6.832038072126238],
            [1.664477573258381, 9.12859039960938],
            [1.46304284018467, 9.334624335157088],
            [1.425060662450136, 9.825395412633],
            [1.077795037448738, 10.175606594275024],
            [0.772335646171484, 10.470808213742359],
            [0.899563022474069, 10.99733938236426],
            [1.243469679376489, 11.110510769083461],
            [1.447178175471066, 11.547719224488858],
            [1.935985548519881, 11.641150214072553],
            [2.154473504249921, 11.940150051313339],
            [2.49016360841793, 12.233052069543675],
            [2.848643019226586, 12.23563589115821],
            [3.611180454125559, 11.660167141155968],
            [3.572216424177469, 11.32793935795152],
            [3.797112257511714, 10.734745591673105],
            [3.600070021182802, 10.332186184119408],
            [3.705438266625919, 10.063210354040208],
            [3.220351596702101, 9.444152533399702],
            [2.912308383810256, 9.137607937044322],
            [2.723792758809509, 8.50684540448971],
            [2.74906253420022, 7.870734361192888],
            [2.691701694356254, 6.258817246928629],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Angola",
        sov_a3: "AGO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Angola",
        adm0_a3: "AGO",
        geou_dif: 0,
        geounit: "Angola",
        gu_a3: "AGO",
        su_dif: 0,
        subunit: "Angola",
        su_a3: "AGO",
        brk_diff: 0,
        name: "Angola",
        name_long: "Angola",
        brk_a3: "AGO",
        brk_name: "Angola",
        brk_group: null,
        abbrev: "Ang.",
        postal: "AO",
        formal_en: "People's Republic of Angola",
        formal_fr: null,
        name_ciawf: "Angola",
        note_adm0: null,
        note_brk: null,
        name_sort: "Angola",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 6,
        mapcolor13: 1,
        pop_est: 31825295,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 88815,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "3. Upper middle income",
        fips_10: "AO",
        iso_a2: "AO",
        iso_a2_eh: "AO",
        iso_a3: "AGO",
        iso_a3_eh: "AGO",
        iso_n3: "024",
        iso_n3_eh: "024",
        un_a3: "024",
        wb_a2: "AO",
        wb_a3: "AGO",
        woe_id: 23424745,
        woe_id_eh: 23424745,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AGO",
        adm0_diff: null,
        adm0_tlc: "AGO",
        adm0_a3_us: "AGO",
        adm0_a3_fr: "AGO",
        adm0_a3_ru: "AGO",
        adm0_a3_es: "AGO",
        adm0_a3_cn: "AGO",
        adm0_a3_tw: "AGO",
        adm0_a3_in: "AGO",
        adm0_a3_np: "AGO",
        adm0_a3_pk: "AGO",
        adm0_a3_de: "AGO",
        adm0_a3_gb: "AGO",
        adm0_a3_br: "AGO",
        adm0_a3_il: "AGO",
        adm0_a3_ps: "AGO",
        adm0_a3_sa: "AGO",
        adm0_a3_eg: "AGO",
        adm0_a3_ma: "AGO",
        adm0_a3_pt: "AGO",
        adm0_a3_ar: "AGO",
        adm0_a3_jp: "AGO",
        adm0_a3_ko: "AGO",
        adm0_a3_vn: "AGO",
        adm0_a3_tr: "AGO",
        adm0_a3_id: "AGO",
        adm0_a3_pl: "AGO",
        adm0_a3_gr: "AGO",
        adm0_a3_it: "AGO",
        adm0_a3_nl: "AGO",
        adm0_a3_se: "AGO",
        adm0_a3_bd: "AGO",
        adm0_a3_ua: "AGO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 17.984249,
        label_y: -12.182762,
        ne_id: 1159320323,
        wikidataid: "Q916",
        name_ar: "أنغولا",
        name_bn: "অ্যাঙ্গোলা",
        name_de: "Angola",
        name_en: "Angola",
        name_es: "Angola",
        name_fa: "آنگولا",
        name_fr: "Angola",
        name_el: "Ανγκόλα",
        name_he: "אנגולה",
        name_hi: "अंगोला",
        name_hu: "Angola",
        name_id: "Angola",
        name_it: "Angola",
        name_ja: "アンゴラ",
        name_ko: "앙골라",
        name_nl: "Angola",
        name_pl: "Angola",
        name_pt: "Angola",
        name_ru: "Ангола",
        name_sv: "Angola",
        name_tr: "Angola",
        name_uk: "Ангола",
        name_ur: "انگولا",
        name_vi: "Angola",
        name_zh: "安哥拉",
        name_zht: "安哥拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AGO.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.995517205465177, -4.781103203961884],
              [12.63161176926579, -4.991271254092936],
              [12.468004184629736, -5.248361504745005],
              [12.436688266660868, -5.684303887559246],
              [12.182336866920252, -5.789930515163839],
              [11.91496300624209, -5.037986748884791],
              [12.318607618873926, -4.606230157086188],
              [12.620759718484493, -4.438023369976136],
              [12.995517205465177, -4.781103203961884],
            ],
          ],
          [
            [
              [12.32243167486351, -6.10009246177966],
              [12.735171339578699, -5.965682061388499],
              [13.024869419006961, -5.984388929878158],
              [13.375597364971895, -5.864241224799549],
              [16.326528354567046, -5.877470391466268],
              [16.573179965896145, -6.622644545115087],
              [16.8601908708452, -7.222297865429987],
              [17.08999596524717, -7.545688978712526],
              [17.472970004962235, -8.0685511206417],
              [18.13422163256905, -7.987677504104923],
              [18.464175652752687, -7.847014255406443],
              [19.01675174324967, -7.988245944860132],
              [19.16661339689611, -7.738183688999754],
              [19.41750247567316, -7.155428562044299],
              [20.037723016040218, -7.116361179231646],
              [20.091621534920648, -6.943090101756994],
              [20.6018229509383, -6.939317722199682],
              [20.5147481625265, -7.299605808138629],
              [21.7281107927397, -7.290872491081302],
              [21.74645592620331, -7.920084730667149],
              [21.949130893652043, -8.305900974158277],
              [21.8018013851879, -8.90870655684298],
              [21.875181919042348, -9.523707777548566],
              [22.208753289486395, -9.894796237836509],
              [22.155268182064308, -11.084801120653772],
              [22.402798292742375, -10.993075453335692],
              [22.83734541188474, -11.01762175867433],
              [23.45679080576744, -10.867863457892483],
              [23.912215203555718, -10.926826267137514],
              [24.01789350759259, -11.23729827234709],
              [23.904153680118185, -11.722281589406322],
              [24.079905226342845, -12.191296888887365],
              [23.930922072045377, -12.565847670138856],
              [24.016136508894675, -12.911046237848574],
              [21.933886346125917, -12.898437188369359],
              [21.887842644953874, -16.08031015387688],
              [22.56247846852426, -16.898451429921813],
              [23.215048455506064, -17.523116143465984],
              [21.377176141045567, -17.930636488519696],
              [18.956186964603603, -17.789094740472258],
              [18.263309360434164, -17.309950860262006],
              [14.209706658595024, -17.35310068122572],
              [14.05850141770901, -17.423380629142663],
              [13.462362094789967, -16.971211846588773],
              [12.814081251688407, -16.94134286872407],
              [12.215461460019355, -17.111668389558083],
              [11.734198846085121, -17.301889336824473],
              [11.64009606288161, -16.67314218512925],
              [11.778537224991537, -15.793816013250735],
              [12.12358076340439, -14.878316338767904],
              [12.175618930722294, -14.449143568583892],
              [12.50009524908299, -13.54769988368445],
              [12.738478631245385, -13.137905775609902],
              [13.312913852601866, -12.483630466362492],
              [13.6337211442698, -12.03864470789717],
              [13.738727654686897, -11.297863050993165],
              [13.686379428775238, -10.73107594161589],
              [13.387327915102162, -10.373578383020714],
              [13.120987583069846, -9.766897067914122],
              [12.875369500386569, -9.166933689005468],
              [12.92906131353783, -8.959091078327553],
              [13.236432732809874, -8.562629489784307],
              [12.93304039882429, -7.596538588087733],
              [12.728298374083892, -6.927122084178805],
              [12.227347039446471, -6.294447523629394],
              [12.32243167486351, -6.10009246177966],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Botswana",
        sov_a3: "BWA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Botswana",
        adm0_a3: "BWA",
        geou_dif: 0,
        geounit: "Botswana",
        gu_a3: "BWA",
        su_dif: 0,
        subunit: "Botswana",
        su_a3: "BWA",
        brk_diff: 0,
        name: "Botswana",
        name_long: "Botswana",
        brk_a3: "BWA",
        brk_name: "Botswana",
        brk_group: null,
        abbrev: "Bwa.",
        postal: "BW",
        formal_en: "Republic of Botswana",
        formal_fr: null,
        name_ciawf: "Botswana",
        note_adm0: null,
        note_brk: null,
        name_sort: "Botswana",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 2303697,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 18340,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "BC",
        iso_a2: "BW",
        iso_a2_eh: "BW",
        iso_a3: "BWA",
        iso_a3_eh: "BWA",
        iso_n3: "072",
        iso_n3_eh: "072",
        un_a3: "072",
        wb_a2: "BW",
        wb_a3: "BWA",
        woe_id: 23424755,
        woe_id_eh: 23424755,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BWA",
        adm0_diff: null,
        adm0_tlc: "BWA",
        adm0_a3_us: "BWA",
        adm0_a3_fr: "BWA",
        adm0_a3_ru: "BWA",
        adm0_a3_es: "BWA",
        adm0_a3_cn: "BWA",
        adm0_a3_tw: "BWA",
        adm0_a3_in: "BWA",
        adm0_a3_np: "BWA",
        adm0_a3_pk: "BWA",
        adm0_a3_de: "BWA",
        adm0_a3_gb: "BWA",
        adm0_a3_br: "BWA",
        adm0_a3_il: "BWA",
        adm0_a3_ps: "BWA",
        adm0_a3_sa: "BWA",
        adm0_a3_eg: "BWA",
        adm0_a3_ma: "BWA",
        adm0_a3_pt: "BWA",
        adm0_a3_ar: "BWA",
        adm0_a3_jp: "BWA",
        adm0_a3_ko: "BWA",
        adm0_a3_vn: "BWA",
        adm0_a3_tr: "BWA",
        adm0_a3_id: "BWA",
        adm0_a3_pl: "BWA",
        adm0_a3_gr: "BWA",
        adm0_a3_it: "BWA",
        adm0_a3_nl: "BWA",
        adm0_a3_se: "BWA",
        adm0_a3_bd: "BWA",
        adm0_a3_ua: "BWA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 24.179216,
        label_y: -22.102634,
        ne_id: 1159320461,
        wikidataid: "Q963",
        name_ar: "بوتسوانا",
        name_bn: "বতসোয়ানা",
        name_de: "Botswana",
        name_en: "Botswana",
        name_es: "Botsuana",
        name_fa: "بوتسوانا",
        name_fr: "Botswana",
        name_el: "Μποτσουάνα",
        name_he: "בוטסואנה",
        name_hi: "बोत्सवाना",
        name_hu: "Botswana",
        name_id: "Botswana",
        name_it: "Botswana",
        name_ja: "ボツワナ",
        name_ko: "보츠와나",
        name_nl: "Botswana",
        name_pl: "Botswana",
        name_pt: "Botsuana",
        name_ru: "Ботсвана",
        name_sv: "Botswana",
        name_tr: "Botsvana",
        name_uk: "Ботсвана",
        name_ur: "بوٹسوانا",
        name_vi: "Botswana",
        name_zh: "博茨瓦纳",
        name_zht: "波札那",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BWA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.43218834810904, -22.091312758067588],
            [28.01723595552525, -22.82775359465908],
            [27.119409620886245, -23.574323011979775],
            [26.786406691197413, -24.240690606383485],
            [26.4857532081233, -24.616326592713104],
            [25.94165205252216, -24.69637338633322],
            [25.76584882986521, -25.17484547292368],
            [25.66466637543772, -25.486816094669713],
            [25.025170525825786, -25.7196700985769],
            [24.211266717228796, -25.670215752873574],
            [23.73356977712271, -25.390129489851617],
            [23.312096795350186, -25.26868987396572],
            [22.8242712745149, -25.50045867279477],
            [22.57953169118059, -25.979447523708146],
            [22.105968865657868, -26.280256036079138],
            [21.605896030369394, -26.726533705351756],
            [20.88960900237174, -26.828542982695915],
            [20.66647016773544, -26.477453301704923],
            [20.75860924651184, -25.86813648855145],
            [20.16572553882719, -24.91796192800077],
            [19.895767856534434, -24.76779021576059],
            [19.89545779794068, -21.84915699634787],
            [20.88113406747587, -21.814327080983148],
            [20.910641310314535, -18.252218926672022],
            [21.655040317478978, -18.219146010005225],
            [23.1968583513393, -17.869038181227786],
            [23.579005568137717, -18.28126108162006],
            [24.217364536239213, -17.88934701911849],
            [24.520705193792537, -17.887124932529936],
            [25.08444339366457, -17.661815687737374],
            [25.264225701608012, -17.736539808831417],
            [25.649163445750162, -18.53602589281899],
            [25.85039147309473, -18.714412937090536],
            [26.164790887158485, -19.29308562589494],
            [27.296504754350508, -20.391519870691],
            [27.724747348753255, -20.49905852629039],
            [27.72722781750326, -20.851801853114715],
            [28.021370070108617, -21.485975030200585],
            [28.794656202924216, -21.63945403410745],
            [29.43218834810904, -22.091312758067588],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Zimbabwe",
        sov_a3: "ZWE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Zimbabwe",
        adm0_a3: "ZWE",
        geou_dif: 0,
        geounit: "Zimbabwe",
        gu_a3: "ZWE",
        su_dif: 0,
        subunit: "Zimbabwe",
        su_a3: "ZWE",
        brk_diff: 0,
        name: "Zimbabwe",
        name_long: "Zimbabwe",
        brk_a3: "ZWE",
        brk_name: "Zimbabwe",
        brk_group: null,
        abbrev: "Zimb.",
        postal: "ZW",
        formal_en: "Republic of Zimbabwe",
        formal_fr: null,
        name_ciawf: "Zimbabwe",
        note_adm0: null,
        note_brk: null,
        name_sort: "Zimbabwe",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 14645468,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 21440,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "5. Low income",
        fips_10: "ZI",
        iso_a2: "ZW",
        iso_a2_eh: "ZW",
        iso_a3: "ZWE",
        iso_a3_eh: "ZWE",
        iso_n3: "716",
        iso_n3_eh: "716",
        un_a3: "716",
        wb_a2: "ZW",
        wb_a3: "ZWE",
        woe_id: 23425004,
        woe_id_eh: 23425004,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ZWE",
        adm0_diff: null,
        adm0_tlc: "ZWE",
        adm0_a3_us: "ZWE",
        adm0_a3_fr: "ZWE",
        adm0_a3_ru: "ZWE",
        adm0_a3_es: "ZWE",
        adm0_a3_cn: "ZWE",
        adm0_a3_tw: "ZWE",
        adm0_a3_in: "ZWE",
        adm0_a3_np: "ZWE",
        adm0_a3_pk: "ZWE",
        adm0_a3_de: "ZWE",
        adm0_a3_gb: "ZWE",
        adm0_a3_br: "ZWE",
        adm0_a3_il: "ZWE",
        adm0_a3_ps: "ZWE",
        adm0_a3_sa: "ZWE",
        adm0_a3_eg: "ZWE",
        adm0_a3_ma: "ZWE",
        adm0_a3_pt: "ZWE",
        adm0_a3_ar: "ZWE",
        adm0_a3_jp: "ZWE",
        adm0_a3_ko: "ZWE",
        adm0_a3_vn: "ZWE",
        adm0_a3_tr: "ZWE",
        adm0_a3_id: "ZWE",
        adm0_a3_pl: "ZWE",
        adm0_a3_gr: "ZWE",
        adm0_a3_it: "ZWE",
        adm0_a3_nl: "ZWE",
        adm0_a3_se: "ZWE",
        adm0_a3_bd: "ZWE",
        adm0_a3_ua: "ZWE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: 29.925444,
        label_y: -18.91164,
        ne_id: 1159321441,
        wikidataid: "Q954",
        name_ar: "زيمبابوي",
        name_bn: "জিম্বাবুয়ে",
        name_de: "Simbabwe",
        name_en: "Zimbabwe",
        name_es: "Zimbabue",
        name_fa: "زیمبابوه",
        name_fr: "Zimbabwe",
        name_el: "Ζιμπάμπουε",
        name_he: "זימבבואה",
        name_hi: "ज़िम्बाब्वे",
        name_hu: "Zimbabwe",
        name_id: "Zimbabwe",
        name_it: "Zimbabwe",
        name_ja: "ジンバブエ",
        name_ko: "짐바브웨",
        name_nl: "Zimbabwe",
        name_pl: "Zimbabwe",
        name_pt: "Zimbábue",
        name_ru: "Зимбабве",
        name_sv: "Zimbabwe",
        name_tr: "Zimbabve",
        name_uk: "Зімбабве",
        name_ur: "زمبابوے",
        name_vi: "Zimbabwe",
        name_zh: "津巴布韦",
        name_zht: "辛巴威",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ZWE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.19140913262129, -22.2515096981724],
            [30.65986535006709, -22.151567478119915],
            [30.322883335091774, -22.271611830333935],
            [29.839036899542972, -22.102216485281176],
            [29.43218834810904, -22.091312758067588],
            [28.794656202924216, -21.63945403410745],
            [28.021370070108617, -21.485975030200585],
            [27.72722781750326, -20.851801853114715],
            [27.724747348753255, -20.49905852629039],
            [27.296504754350508, -20.391519870691],
            [26.164790887158485, -19.29308562589494],
            [25.85039147309473, -18.714412937090536],
            [25.649163445750162, -18.53602589281899],
            [25.264225701608012, -17.736539808831417],
            [26.381935255648926, -17.8460421688579],
            [26.70677330903564, -17.961228936436484],
            [27.044427117630732, -17.938026218337434],
            [27.59824344250276, -17.290830580314008],
            [28.467906121542683, -16.468400160388846],
            [28.8258687680285, -16.389748630440614],
            [28.947463413211267, -16.04305144619444],
            [29.516834344203147, -15.644677829656388],
            [30.27425581230511, -15.507786960515213],
            [30.338954705534544, -15.880839125230246],
            [31.17306399915768, -15.860943698797874],
            [31.636498243951195, -16.071990248277885],
            [31.8520406430406, -16.319417006091378],
            [32.32823896661023, -16.392074069893752],
            [32.847638787575846, -16.713398125884616],
            [32.84986087416439, -17.97905730557718],
            [32.65488569512715, -18.672089939043495],
            [32.61199425632489, -19.419382826416275],
            [32.772707960752626, -19.715592136313298],
            [32.65974327976258, -20.304290052982317],
            [32.50869306817344, -20.395292250248307],
            [32.244988234188014, -21.116488539313693],
            [31.19140913262129, -22.2515096981724],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Chad",
        sov_a3: "TCD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Chad",
        adm0_a3: "TCD",
        geou_dif: 0,
        geounit: "Chad",
        gu_a3: "TCD",
        su_dif: 0,
        subunit: "Chad",
        su_a3: "TCD",
        brk_diff: 0,
        name: "Chad",
        name_long: "Chad",
        brk_a3: "TCD",
        brk_name: "Chad",
        brk_group: null,
        abbrev: "Chad",
        postal: "TD",
        formal_en: "Republic of Chad",
        formal_fr: null,
        name_ciawf: "Chad",
        note_adm0: null,
        note_brk: null,
        name_sort: "Chad",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 8,
        mapcolor13: 6,
        pop_est: 15946876,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 11314,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CD",
        iso_a2: "TD",
        iso_a2_eh: "TD",
        iso_a3: "TCD",
        iso_a3_eh: "TCD",
        iso_n3: "148",
        iso_n3_eh: "148",
        un_a3: "148",
        wb_a2: "TD",
        wb_a3: "TCD",
        woe_id: 23424777,
        woe_id_eh: 23424777,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TCD",
        adm0_diff: null,
        adm0_tlc: "TCD",
        adm0_a3_us: "TCD",
        adm0_a3_fr: "TCD",
        adm0_a3_ru: "TCD",
        adm0_a3_es: "TCD",
        adm0_a3_cn: "TCD",
        adm0_a3_tw: "TCD",
        adm0_a3_in: "TCD",
        adm0_a3_np: "TCD",
        adm0_a3_pk: "TCD",
        adm0_a3_de: "TCD",
        adm0_a3_gb: "TCD",
        adm0_a3_br: "TCD",
        adm0_a3_il: "TCD",
        adm0_a3_ps: "TCD",
        adm0_a3_sa: "TCD",
        adm0_a3_eg: "TCD",
        adm0_a3_ma: "TCD",
        adm0_a3_pt: "TCD",
        adm0_a3_ar: "TCD",
        adm0_a3_jp: "TCD",
        adm0_a3_ko: "TCD",
        adm0_a3_vn: "TCD",
        adm0_a3_tr: "TCD",
        adm0_a3_id: "TCD",
        adm0_a3_pl: "TCD",
        adm0_a3_gr: "TCD",
        adm0_a3_it: "TCD",
        adm0_a3_nl: "TCD",
        adm0_a3_se: "TCD",
        adm0_a3_bd: "TCD",
        adm0_a3_ua: "TCD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 18.645041,
        label_y: 15.142959,
        ne_id: 1159321301,
        wikidataid: "Q657",
        name_ar: "تشاد",
        name_bn: "চাদ",
        name_de: "Tschad",
        name_en: "Chad",
        name_es: "Chad",
        name_fa: "چاد",
        name_fr: "Tchad",
        name_el: "Τσαντ",
        name_he: "צ'אד",
        name_hi: "चाड",
        name_hu: "Csád",
        name_id: "Chad",
        name_it: "Ciad",
        name_ja: "チャド",
        name_ko: "차드",
        name_nl: "Tsjaad",
        name_pl: "Czad",
        name_pt: "Chade",
        name_ru: "Чад",
        name_sv: "Tchad",
        name_tr: "Çad",
        name_uk: "Чад",
        name_ur: "چاڈ",
        name_vi: "Tchad",
        name_zh: "乍得",
        name_zht: "查德",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TCD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.83766000000014, 19.580470000000105],
            [23.886890000000108, 15.61084],
            [23.024590000000103, 15.68072],
            [22.56795000000011, 14.944290000000137],
            [22.30351, 14.32682],
            [22.51202, 14.09318],
            [22.18329, 13.78648],
            [22.29658, 13.37232],
            [22.03759, 12.95546],
            [21.93681, 12.588180000000136],
            [22.28801, 12.64605],
            [22.49762, 12.26024],
            [22.50869, 11.67936],
            [22.87622, 11.384610000000123],
            [22.864165480244225, 11.142395127807546],
            [22.23112918466876, 10.97188873946061],
            [21.723821648859456, 10.567055568885976],
            [21.000868361096167, 9.475985215691509],
            [20.05968549976427, 9.012706000194854],
            [19.09400800952602, 9.07484691002584],
            [18.812009718509273, 8.982914536978598],
            [18.911021762780507, 8.630894680206353],
            [18.38955488452322, 8.281303615751824],
            [17.964929640380888, 7.890914008002994],
            [16.705988396886255, 7.508327541529979],
            [16.456184523187346, 7.734773667832968],
            [16.290561557691888, 7.754307359239419],
            [16.106231723706742, 7.497087917506462],
            [15.279460483469109, 7.421924546737969],
            [15.436091749745742, 7.692812404811889],
            [15.120865512765306, 8.382150173369439],
            [14.97999555833769, 8.796104234243472],
            [14.54446658698177, 8.965861314322268],
            [13.954218377344006, 9.549494940626687],
            [14.171466098699028, 10.021378282099931],
            [14.62720055508106, 9.920919297724538],
            [14.909353875394716, 9.992129421422732],
            [15.467872755605242, 9.982336737503545],
            [14.92356489427496, 10.891325181517473],
            [14.9601518083376, 11.555574042197224],
            [14.89336, 12.21905],
            [14.495787387762846, 12.85939626713733],
            [14.595781284247607, 13.33042694747786],
            [13.95447675950561, 13.353448798063766],
            [13.956698846094127, 13.996691189016929],
            [13.540393507550789, 14.367133693901224],
            [13.97217, 15.68437],
            [15.247731154041844, 16.627305813050782],
            [15.30044111497972, 17.927949937405003],
            [15.685740594147774, 19.957180080642388],
            [15.903246697664315, 20.387618923417506],
            [15.487148064850146, 20.730414537025638],
            [15.47106, 21.04845],
            [15.096887648181848, 21.30851878507491],
            [14.8513, 22.862950000000126],
            [15.86085, 23.40972],
            [19.84926, 21.49509],
            [23.83766000000014, 19.580470000000105],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Algeria",
        sov_a3: "DZA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Algeria",
        adm0_a3: "DZA",
        geou_dif: 0,
        geounit: "Algeria",
        gu_a3: "DZA",
        su_dif: 0,
        subunit: "Algeria",
        su_a3: "DZA",
        brk_diff: 0,
        name: "Algeria",
        name_long: "Algeria",
        brk_a3: "DZA",
        brk_name: "Algeria",
        brk_group: null,
        abbrev: "Alg.",
        postal: "DZ",
        formal_en: "People's Democratic Republic of Algeria",
        formal_fr: null,
        name_ciawf: "Algeria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Algeria",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 43053054,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 171091,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AG",
        iso_a2: "DZ",
        iso_a2_eh: "DZ",
        iso_a3: "DZA",
        iso_a3_eh: "DZA",
        iso_n3: "012",
        iso_n3_eh: "012",
        un_a3: "012",
        wb_a2: "DZ",
        wb_a3: "DZA",
        woe_id: 23424740,
        woe_id_eh: 23424740,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DZA",
        adm0_diff: null,
        adm0_tlc: "DZA",
        adm0_a3_us: "DZA",
        adm0_a3_fr: "DZA",
        adm0_a3_ru: "DZA",
        adm0_a3_es: "DZA",
        adm0_a3_cn: "DZA",
        adm0_a3_tw: "DZA",
        adm0_a3_in: "DZA",
        adm0_a3_np: "DZA",
        adm0_a3_pk: "DZA",
        adm0_a3_de: "DZA",
        adm0_a3_gb: "DZA",
        adm0_a3_br: "DZA",
        adm0_a3_il: "DZA",
        adm0_a3_ps: "DZA",
        adm0_a3_sa: "DZA",
        adm0_a3_eg: "DZA",
        adm0_a3_ma: "DZA",
        adm0_a3_pt: "DZA",
        adm0_a3_ar: "DZA",
        adm0_a3_jp: "DZA",
        adm0_a3_ko: "DZA",
        adm0_a3_vn: "DZA",
        adm0_a3_tr: "DZA",
        adm0_a3_id: "DZA",
        adm0_a3_pl: "DZA",
        adm0_a3_gr: "DZA",
        adm0_a3_it: "DZA",
        adm0_a3_nl: "DZA",
        adm0_a3_se: "DZA",
        adm0_a3_bd: "DZA",
        adm0_a3_ua: "DZA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 2.808241,
        label_y: 27.397406,
        ne_id: 1159320565,
        wikidataid: "Q262",
        name_ar: "الجزائر",
        name_bn: "আলজেরিয়া",
        name_de: "Algerien",
        name_en: "Algeria",
        name_es: "Argelia",
        name_fa: "الجزایر",
        name_fr: "Algérie",
        name_el: "Αλγερία",
        name_he: "אלג'יריה",
        name_hi: "अल्जीरिया",
        name_hu: "Algéria",
        name_id: "Aljazair",
        name_it: "Algeria",
        name_ja: "アルジェリア",
        name_ko: "알제리",
        name_nl: "Algerije",
        name_pl: "Algieria",
        name_pt: "Argélia",
        name_ru: "Алжир",
        name_sv: "Algeriet",
        name_tr: "Cezayir",
        name_uk: "Алжир",
        name_ur: "الجزائر",
        name_vi: "Algérie",
        name_zh: "阿尔及利亚",
        name_zht: "阿爾及利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DZA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.684399786809053, 27.395744126896005],
            [-8.665124477564191, 27.589479071558227],
            [-8.665589565454809, 27.656425889592356],
            [-8.674116176782974, 28.84128896739658],
            [-7.059227667661958, 29.5792284205246],
            [-6.060632290053774, 29.731699734001694],
            [-5.242129278982787, 30.00044302013559],
            [-4.859646165374471, 30.501187649043846],
            [-3.690441046554724, 30.896951605751156],
            [-3.647497931320146, 31.637294012980675],
            [-3.068980271812648, 31.724497992473218],
            [-2.616604783529567, 32.09434621838619],
            [-1.30789913573787, 32.2628889023061],
            [-1.124551153966308, 32.65152151135713],
            [-1.388049282222596, 32.86401500094138],
            [-1.733454555661467, 33.91971283623212],
            [-1.792985805661715, 34.527918606091305],
            [-2.169913702798624, 35.16839630791668],
            [-1.208602871089056, 35.7148487411871],
            [-0.127454392894606, 35.888662421200806],
            [0.503876580415209, 36.30127289483528],
            [1.466918572606545, 36.605647081034405],
            [3.161698846050825, 36.78390493422522],
            [4.81575809084913, 36.86503693292346],
            [5.320120070017794, 36.71651886651662],
            [6.261819695672613, 37.11065501560674],
            [7.330384962603971, 37.11838064223437],
            [7.737078484741005, 36.885707505840216],
            [8.420964389691676, 36.94642731378316],
            [8.217824334352315, 36.433176988260286],
            [8.376367628623768, 35.479876003555944],
            [8.140981479534304, 34.65514598239379],
            [7.524481642292244, 34.09737641045146],
            [7.612641635782182, 33.34411489514896],
            [8.430472853233368, 32.74833730725595],
            [8.439102817426118, 32.50628489840082],
            [9.05560265466815, 32.10269196220129],
            [9.482139926805274, 30.307556057246188],
            [9.805634392952356, 29.424638373323376],
            [9.859997999723447, 28.959989732371014],
            [9.683884718472768, 28.1441738957792],
            [9.756128370816782, 27.688258571884205],
            [9.629056023811074, 27.14095347748092],
            [9.716285841519664, 26.512206325785655],
            [9.319410841518163, 26.094324856057455],
            [9.910692579801776, 25.365454616796796],
            [9.94826134607797, 24.936953640232517],
            [10.303846876678362, 24.379313259370917],
            [10.771363559622927, 24.56253205006175],
            [11.560669386449005, 24.097909247325518],
            [11.999505649471613, 23.47166840259645],
            [8.572893100629784, 21.565660712159143],
            [5.677565952180686, 19.601206976799716],
            [4.267419467800039, 19.155265204337],
            [3.158133172222705, 19.057364203360038],
            [3.1466610042539, 19.693578599521445],
            [2.683588494486429, 19.856230170160117],
            [2.06099083823392, 20.142233384679486],
            [1.823227573259032, 20.610809434486043],
            [-1.550054897457613, 22.792665920497384],
            [-4.923337368174231, 24.974574082941],
            [-8.684399786809053, 27.395744126896005],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mozambique",
        sov_a3: "MOZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mozambique",
        adm0_a3: "MOZ",
        geou_dif: 0,
        geounit: "Mozambique",
        gu_a3: "MOZ",
        su_dif: 0,
        subunit: "Mozambique",
        su_a3: "MOZ",
        brk_diff: 0,
        name: "Mozambique",
        name_long: "Mozambique",
        brk_a3: "MOZ",
        brk_name: "Mozambique",
        brk_group: null,
        abbrev: "Moz.",
        postal: "MZ",
        formal_en: "Republic of Mozambique",
        formal_fr: null,
        name_ciawf: "Mozambique",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mozambique",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 30366036,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 15291,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MZ",
        iso_a2: "MZ",
        iso_a2_eh: "MZ",
        iso_a3: "MOZ",
        iso_a3_eh: "MOZ",
        iso_n3: "508",
        iso_n3_eh: "508",
        un_a3: "508",
        wb_a2: "MZ",
        wb_a3: "MOZ",
        woe_id: 23424902,
        woe_id_eh: 23424902,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MOZ",
        adm0_diff: null,
        adm0_tlc: "MOZ",
        adm0_a3_us: "MOZ",
        adm0_a3_fr: "MOZ",
        adm0_a3_ru: "MOZ",
        adm0_a3_es: "MOZ",
        adm0_a3_cn: "MOZ",
        adm0_a3_tw: "MOZ",
        adm0_a3_in: "MOZ",
        adm0_a3_np: "MOZ",
        adm0_a3_pk: "MOZ",
        adm0_a3_de: "MOZ",
        adm0_a3_gb: "MOZ",
        adm0_a3_br: "MOZ",
        adm0_a3_il: "MOZ",
        adm0_a3_ps: "MOZ",
        adm0_a3_sa: "MOZ",
        adm0_a3_eg: "MOZ",
        adm0_a3_ma: "MOZ",
        adm0_a3_pt: "MOZ",
        adm0_a3_ar: "MOZ",
        adm0_a3_jp: "MOZ",
        adm0_a3_ko: "MOZ",
        adm0_a3_vn: "MOZ",
        adm0_a3_tr: "MOZ",
        adm0_a3_id: "MOZ",
        adm0_a3_pl: "MOZ",
        adm0_a3_gr: "MOZ",
        adm0_a3_it: "MOZ",
        adm0_a3_nl: "MOZ",
        adm0_a3_se: "MOZ",
        adm0_a3_bd: "MOZ",
        adm0_a3_ua: "MOZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 37.83789,
        label_y: -13.94323,
        ne_id: 1159321073,
        wikidataid: "Q1029",
        name_ar: "موزمبيق",
        name_bn: "মোজাম্বিক",
        name_de: "Mosambik",
        name_en: "Mozambique",
        name_es: "Mozambique",
        name_fa: "موزامبیک",
        name_fr: "Mozambique",
        name_el: "Μοζαμβίκη",
        name_he: "מוזמביק",
        name_hi: "मोज़ाम्बीक",
        name_hu: "Mozambik",
        name_id: "Mozambik",
        name_it: "Mozambico",
        name_ja: "モザンビーク",
        name_ko: "모잠비크",
        name_nl: "Mozambique",
        name_pl: "Mozambik",
        name_pt: "Moçambique",
        name_ru: "Мозамбик",
        name_sv: "Moçambique",
        name_tr: "Mozambik",
        name_uk: "Мозамбік",
        name_ur: "موزمبیق",
        name_vi: "Mozambique",
        name_zh: "莫桑比克",
        name_zht: "莫三比克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MOZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.55998904799935, -11.520020033415925],
            [35.31239790216904, -11.439146416879147],
            [36.51408165868426, -11.720938002166735],
            [36.775150994622805, -11.594537448780805],
            [37.47129, -11.56876],
            [37.82764, -11.26879],
            [38.42755659358775, -11.285202325081656],
            [39.521, -10.89688],
            [40.31659, -10.317099999999868],
            [40.316586229110854, -10.317097752817492],
            [40.31658857601719, -10.317096042525698],
            [40.47838748552303, -10.765440769089993],
            [40.437253045418686, -11.761710707245015],
            [40.56081139502857, -12.639176527561027],
            [40.59962039567975, -14.201975192931862],
            [40.775475294768995, -14.691764418194241],
            [40.4772506040126, -15.406294447493972],
            [40.08926395036522, -16.10077402106446],
            [39.45255862809705, -16.72089120856694],
            [38.53835086442152, -17.101023044505958],
            [37.41113284683888, -17.586368096591237],
            [36.28127933120936, -18.65968759529345],
            [35.89649661636406, -18.842260430580637],
            [35.198399692533144, -19.552811374593894],
            [34.78638349787005, -19.784011732667736],
            [34.70189253107284, -20.49704314543101],
            [35.176127150215365, -21.25436126066841],
            [35.37342776870574, -21.840837090748877],
            [35.385848253705404, -22.14],
            [35.562545536369086, -22.09],
            [35.533934767404304, -23.070787855727758],
            [35.37177412287238, -23.5353589820317],
            [35.60747033055563, -23.706563002214683],
            [35.45874555841962, -24.12260995859655],
            [35.04073489761066, -24.478350518493805],
            [34.21582400893547, -24.81631438568266],
            [33.01321007663901, -25.357573337507738],
            [32.574632195777866, -25.727318210556092],
            [32.66036339695009, -26.148584486599447],
            [32.91595503106569, -26.215867201443466],
            [32.830120477028885, -26.742191664336197],
            [32.07166548028107, -26.73382008230491],
            [31.98577924981197, -26.291779880480227],
            [31.83777794772806, -25.84333180105135],
            [31.75240848158188, -25.484283949487413],
            [31.930588820124253, -24.36941659922254],
            [31.670397983534656, -23.658969008073864],
            [31.19140913262129, -22.2515096981724],
            [32.244988234188014, -21.116488539313693],
            [32.50869306817344, -20.395292250248307],
            [32.65974327976258, -20.304290052982317],
            [32.772707960752626, -19.715592136313298],
            [32.61199425632489, -19.419382826416275],
            [32.65488569512715, -18.672089939043495],
            [32.84986087416439, -17.97905730557718],
            [32.847638787575846, -16.713398125884616],
            [32.32823896661023, -16.392074069893752],
            [31.8520406430406, -16.319417006091378],
            [31.636498243951195, -16.071990248277885],
            [31.17306399915768, -15.860943698797874],
            [30.338954705534544, -15.880839125230246],
            [30.27425581230511, -15.507786960515213],
            [30.17948123548183, -14.796099134991529],
            [33.214024692525214, -13.971860039936153],
            [33.789700148256685, -14.45183074306307],
            [34.064825473778626, -14.35995004644812],
            [34.45963341648854, -14.613009535381423],
            [34.51766604995231, -15.013708591372612],
            [34.307291294092096, -15.478641452702597],
            [34.38129194513405, -16.183559665596043],
            [35.033810255683534, -16.801299737213093],
            [35.339062941231646, -16.10744028083011],
            [35.77190473810836, -15.896858819240727],
            [35.68684533055594, -14.611045830954332],
            [35.26795617039801, -13.887834161029566],
            [34.907151320136165, -13.565424899960568],
            [34.55998904799935, -13.579997653866876],
            [34.28000613784198, -12.280025323132506],
            [34.55998904799935, -11.520020033415925],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "eSwatini",
        sov_a3: "SWZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "eSwatini",
        adm0_a3: "SWZ",
        geou_dif: 0,
        geounit: "eSwatini",
        gu_a3: "SWZ",
        su_dif: 0,
        subunit: "eSwatini",
        su_a3: "SWZ",
        brk_diff: 0,
        name: "eSwatini",
        name_long: "Kingdom of eSwatini",
        brk_a3: "SWZ",
        brk_name: "eSwatini",
        brk_group: null,
        abbrev: "eSw.",
        postal: "ES",
        formal_en: "Kingdom of eSwatini",
        formal_fr: null,
        name_ciawf: "eSwatini",
        note_adm0: null,
        note_brk: null,
        name_sort: "eSwatini",
        name_alt: "Swaziland",
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 1148130,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 4471,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "WZ",
        iso_a2: "SZ",
        iso_a2_eh: "SZ",
        iso_a3: "SWZ",
        iso_a3_eh: "SWZ",
        iso_n3: "748",
        iso_n3_eh: "748",
        un_a3: "748",
        wb_a2: "SZ",
        wb_a3: "SWZ",
        woe_id: 23424993,
        woe_id_eh: 23424993,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SWZ",
        adm0_diff: null,
        adm0_tlc: "SWZ",
        adm0_a3_us: "SWZ",
        adm0_a3_fr: "SWZ",
        adm0_a3_ru: "SWZ",
        adm0_a3_es: "SWZ",
        adm0_a3_cn: "SWZ",
        adm0_a3_tw: "SWZ",
        adm0_a3_in: "SWZ",
        adm0_a3_np: "SWZ",
        adm0_a3_pk: "SWZ",
        adm0_a3_de: "SWZ",
        adm0_a3_gb: "SWZ",
        adm0_a3_br: "SWZ",
        adm0_a3_il: "SWZ",
        adm0_a3_ps: "SWZ",
        adm0_a3_sa: "SWZ",
        adm0_a3_eg: "SWZ",
        adm0_a3_ma: "SWZ",
        adm0_a3_pt: "SWZ",
        adm0_a3_ar: "SWZ",
        adm0_a3_jp: "SWZ",
        adm0_a3_ko: "SWZ",
        adm0_a3_vn: "SWZ",
        adm0_a3_tr: "SWZ",
        adm0_a3_id: "SWZ",
        adm0_a3_pl: "SWZ",
        adm0_a3_gr: "SWZ",
        adm0_a3_it: "SWZ",
        adm0_a3_nl: "SWZ",
        adm0_a3_se: "SWZ",
        adm0_a3_bd: "SWZ",
        adm0_a3_ua: "SWZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 19,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 31.467264,
        label_y: -26.533676,
        ne_id: 1159321289,
        wikidataid: "Q1050",
        name_ar: "إسواتيني",
        name_bn: "ইসোয়াতিনি",
        name_de: "Eswatini",
        name_en: "Eswatini",
        name_es: "Suazilandia",
        name_fa: "اسواتینی",
        name_fr: "Eswatini",
        name_el: "Εσουατίνι",
        name_he: "אסוואטיני",
        name_hi: "एस्वातीनी",
        name_hu: "Szváziföld",
        name_id: "Eswatini",
        name_it: "eSwatini",
        name_ja: "エスワティニ",
        name_ko: "에스와티니",
        name_nl: "Swaziland",
        name_pl: "Eswatini",
        name_pt: "Essuatíni",
        name_ru: "Эсватини",
        name_sv: "Swaziland",
        name_tr: "Esvatini",
        name_uk: "Есватіні",
        name_ur: "اسواتینی",
        name_vi: "Eswatini",
        name_zh: "斯威士兰",
        name_zht: "史瓦帝尼",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SWZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.07166548028107, -26.73382008230491],
            [31.86806033705108, -27.177927341421277],
            [31.28277306491333, -27.285879408478998],
            [30.68596194837448, -26.743845310169533],
            [30.67660851412964, -26.398078301704608],
            [30.949666782359913, -26.02264902110415],
            [31.04407962415715, -25.731452325139443],
            [31.333157586397903, -25.66019052500895],
            [31.83777794772806, -25.84333180105135],
            [31.98577924981197, -26.291779880480227],
            [32.07166548028107, -26.73382008230491],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Burundi",
        sov_a3: "BDI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Burundi",
        adm0_a3: "BDI",
        geou_dif: 0,
        geounit: "Burundi",
        gu_a3: "BDI",
        su_dif: 0,
        subunit: "Burundi",
        su_a3: "BDI",
        brk_diff: 0,
        name: "Burundi",
        name_long: "Burundi",
        brk_a3: "BDI",
        brk_name: "Burundi",
        brk_group: null,
        abbrev: "Bur.",
        postal: "BI",
        formal_en: "Republic of Burundi",
        formal_fr: null,
        name_ciawf: "Burundi",
        note_adm0: null,
        note_brk: null,
        name_sort: "Burundi",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 11530580,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 3012,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BY",
        iso_a2: "BI",
        iso_a2_eh: "BI",
        iso_a3: "BDI",
        iso_a3_eh: "BDI",
        iso_n3: "108",
        iso_n3_eh: "108",
        un_a3: "108",
        wb_a2: "BI",
        wb_a3: "BDI",
        woe_id: 23424774,
        woe_id_eh: 23424774,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BDI",
        adm0_diff: null,
        adm0_tlc: "BDI",
        adm0_a3_us: "BDI",
        adm0_a3_fr: "BDI",
        adm0_a3_ru: "BDI",
        adm0_a3_es: "BDI",
        adm0_a3_cn: "BDI",
        adm0_a3_tw: "BDI",
        adm0_a3_in: "BDI",
        adm0_a3_np: "BDI",
        adm0_a3_pk: "BDI",
        adm0_a3_de: "BDI",
        adm0_a3_gb: "BDI",
        adm0_a3_br: "BDI",
        adm0_a3_il: "BDI",
        adm0_a3_ps: "BDI",
        adm0_a3_sa: "BDI",
        adm0_a3_eg: "BDI",
        adm0_a3_ma: "BDI",
        adm0_a3_pt: "BDI",
        adm0_a3_ar: "BDI",
        adm0_a3_jp: "BDI",
        adm0_a3_ko: "BDI",
        adm0_a3_vn: "BDI",
        adm0_a3_tr: "BDI",
        adm0_a3_id: "BDI",
        adm0_a3_pl: "BDI",
        adm0_a3_gr: "BDI",
        adm0_a3_it: "BDI",
        adm0_a3_nl: "BDI",
        adm0_a3_se: "BDI",
        adm0_a3_bd: "BDI",
        adm0_a3_ua: "BDI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 29.917086,
        label_y: -3.332836,
        ne_id: 1159320387,
        wikidataid: "Q967",
        name_ar: "بوروندي",
        name_bn: "বুরুন্ডি",
        name_de: "Burundi",
        name_en: "Burundi",
        name_es: "Burundi",
        name_fa: "بوروندی",
        name_fr: "Burundi",
        name_el: "Μπουρούντι",
        name_he: "בורונדי",
        name_hi: "बुरुण्डी",
        name_hu: "Burundi",
        name_id: "Burundi",
        name_it: "Burundi",
        name_ja: "ブルンジ",
        name_ko: "부룬디",
        name_nl: "Burundi",
        name_pl: "Burundi",
        name_pt: "Burundi",
        name_ru: "Бурунди",
        name_sv: "Burundi",
        name_tr: "Burundi",
        name_uk: "Бурунді",
        name_ur: "برونڈی",
        name_vi: "Burundi",
        name_zh: "布隆迪",
        name_zht: "蒲隆地",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BDI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.469673645761223, -2.41385475710134],
            [30.52766, -2.80762],
            [30.74301, -3.03431],
            [30.75224, -3.35931],
            [30.50554, -3.56858],
            [30.11632, -4.09012],
            [29.753512404099865, -4.452389418153302],
            [29.339997592900346, -4.499983412294092],
            [29.276383904749053, -3.293907159034063],
            [29.024926385216787, -2.839257907730158],
            [29.632176141078588, -2.917857761246097],
            [29.93835900240794, -2.348486830254238],
            [30.469673645761223, -2.41385475710134],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Rwanda",
        sov_a3: "RWA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Rwanda",
        adm0_a3: "RWA",
        geou_dif: 0,
        geounit: "Rwanda",
        gu_a3: "RWA",
        su_dif: 0,
        subunit: "Rwanda",
        su_a3: "RWA",
        brk_diff: 0,
        name: "Rwanda",
        name_long: "Rwanda",
        brk_a3: "RWA",
        brk_name: "Rwanda",
        brk_group: null,
        abbrev: "Rwa.",
        postal: "RW",
        formal_en: "Republic of Rwanda",
        formal_fr: null,
        name_ciawf: "Rwanda",
        note_adm0: null,
        note_brk: null,
        name_sort: "Rwanda",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 10,
        pop_est: 12626950,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 10354,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "RW",
        iso_a2: "RW",
        iso_a2_eh: "RW",
        iso_a3: "RWA",
        iso_a3_eh: "RWA",
        iso_n3: "646",
        iso_n3_eh: "646",
        un_a3: "646",
        wb_a2: "RW",
        wb_a3: "RWA",
        woe_id: 23424937,
        woe_id_eh: 23424937,
        woe_note: "Exact WOE match as country",
        adm0_iso: "RWA",
        adm0_diff: null,
        adm0_tlc: "RWA",
        adm0_a3_us: "RWA",
        adm0_a3_fr: "RWA",
        adm0_a3_ru: "RWA",
        adm0_a3_es: "RWA",
        adm0_a3_cn: "RWA",
        adm0_a3_tw: "RWA",
        adm0_a3_in: "RWA",
        adm0_a3_np: "RWA",
        adm0_a3_pk: "RWA",
        adm0_a3_de: "RWA",
        adm0_a3_gb: "RWA",
        adm0_a3_br: "RWA",
        adm0_a3_il: "RWA",
        adm0_a3_ps: "RWA",
        adm0_a3_sa: "RWA",
        adm0_a3_eg: "RWA",
        adm0_a3_ma: "RWA",
        adm0_a3_pt: "RWA",
        adm0_a3_ar: "RWA",
        adm0_a3_jp: "RWA",
        adm0_a3_ko: "RWA",
        adm0_a3_vn: "RWA",
        adm0_a3_tr: "RWA",
        adm0_a3_id: "RWA",
        adm0_a3_pl: "RWA",
        adm0_a3_gr: "RWA",
        adm0_a3_it: "RWA",
        adm0_a3_nl: "RWA",
        adm0_a3_se: "RWA",
        adm0_a3_bd: "RWA",
        adm0_a3_ua: "RWA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 30.103894,
        label_y: -1.897196,
        ne_id: 1159321219,
        wikidataid: "Q1037",
        name_ar: "رواندا",
        name_bn: "রুয়ান্ডা",
        name_de: "Ruanda",
        name_en: "Rwanda",
        name_es: "Ruanda",
        name_fa: "رواندا",
        name_fr: "Rwanda",
        name_el: "Ρουάντα",
        name_he: "רואנדה",
        name_hi: "रवाण्डा",
        name_hu: "Ruanda",
        name_id: "Rwanda",
        name_it: "Ruanda",
        name_ja: "ルワンダ",
        name_ko: "르완다",
        name_nl: "Rwanda",
        name_pl: "Rwanda",
        name_pt: "Ruanda",
        name_ru: "Руанда",
        name_sv: "Rwanda",
        name_tr: "Ruanda",
        name_uk: "Руанда",
        name_ur: "روانڈا",
        name_vi: "Rwanda",
        name_zh: "卢旺达",
        name_zht: "盧旺達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "RWA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.419104852019245, -1.134659112150416],
            [30.816134881317712, -1.698914076345389],
            [30.75830895358311, -2.287250257988369],
            [30.46967, -2.41383],
            [30.469673645761223, -2.41385475710134],
            [29.93835900240794, -2.348486830254238],
            [29.632176141078588, -2.917857761246097],
            [29.024926385216787, -2.839257907730158],
            [29.117478875451553, -2.292211195488385],
            [29.254834832483343, -2.215109958508911],
            [29.291886834436614, -1.620055840667987],
            [29.579466180140884, -1.341313164885626],
            [29.821518588996014, -1.443322442229785],
            [30.419104852019245, -1.134659112150416],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Uganda",
        sov_a3: "UGA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Uganda",
        adm0_a3: "UGA",
        geou_dif: 0,
        geounit: "Uganda",
        gu_a3: "UGA",
        su_dif: 0,
        subunit: "Uganda",
        su_a3: "UGA",
        brk_diff: 0,
        name: "Uganda",
        name_long: "Uganda",
        brk_a3: "UGA",
        brk_name: "Uganda",
        brk_group: null,
        abbrev: "Uga.",
        postal: "UG",
        formal_en: "Republic of Uganda",
        formal_fr: null,
        name_ciawf: "Uganda",
        note_adm0: null,
        note_brk: null,
        name_sort: "Uganda",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 4,
        pop_est: 44269594,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 35165,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "UG",
        iso_a2: "UG",
        iso_a2_eh: "UG",
        iso_a3: "UGA",
        iso_a3_eh: "UGA",
        iso_n3: "800",
        iso_n3_eh: "800",
        un_a3: "800",
        wb_a2: "UG",
        wb_a3: "UGA",
        woe_id: 23424974,
        woe_id_eh: 23424974,
        woe_note: "Exact WOE match as country",
        adm0_iso: "UGA",
        adm0_diff: null,
        adm0_tlc: "UGA",
        adm0_a3_us: "UGA",
        adm0_a3_fr: "UGA",
        adm0_a3_ru: "UGA",
        adm0_a3_es: "UGA",
        adm0_a3_cn: "UGA",
        adm0_a3_tw: "UGA",
        adm0_a3_in: "UGA",
        adm0_a3_np: "UGA",
        adm0_a3_pk: "UGA",
        adm0_a3_de: "UGA",
        adm0_a3_gb: "UGA",
        adm0_a3_br: "UGA",
        adm0_a3_il: "UGA",
        adm0_a3_ps: "UGA",
        adm0_a3_sa: "UGA",
        adm0_a3_eg: "UGA",
        adm0_a3_ma: "UGA",
        adm0_a3_pt: "UGA",
        adm0_a3_ar: "UGA",
        adm0_a3_jp: "UGA",
        adm0_a3_ko: "UGA",
        adm0_a3_vn: "UGA",
        adm0_a3_tr: "UGA",
        adm0_a3_id: "UGA",
        adm0_a3_pl: "UGA",
        adm0_a3_gr: "UGA",
        adm0_a3_it: "UGA",
        adm0_a3_nl: "UGA",
        adm0_a3_se: "UGA",
        adm0_a3_bd: "UGA",
        adm0_a3_ua: "UGA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 32.948555,
        label_y: 1.972589,
        ne_id: 1159321343,
        wikidataid: "Q1036",
        name_ar: "أوغندا",
        name_bn: "উগান্ডা",
        name_de: "Uganda",
        name_en: "Uganda",
        name_es: "Uganda",
        name_fa: "اوگاندا",
        name_fr: "Ouganda",
        name_el: "Ουγκάντα",
        name_he: "אוגנדה",
        name_hi: "युगाण्डा",
        name_hu: "Uganda",
        name_id: "Uganda",
        name_it: "Uganda",
        name_ja: "ウガンダ",
        name_ko: "우간다",
        name_nl: "Oeganda",
        name_pl: "Uganda",
        name_pt: "Uganda",
        name_ru: "Уганда",
        name_sv: "Uganda",
        name_tr: "Uganda",
        name_uk: "Уганда",
        name_ur: "یوگنڈا",
        name_vi: "Uganda",
        name_zh: "乌干达",
        name_zht: "烏干達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "UGA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.90371119710453, -0.95],
            [31.86617, -1.02736],
            [30.769860000000108, -1.01455],
            [30.419104852019245, -1.134659112150416],
            [29.821518588996014, -1.443322442229785],
            [29.579466180140884, -1.341313164885626],
            [29.58783776217217, -0.587405694179381],
            [29.819503208136638, -0.205310153813372],
            [29.875778842902434, 0.597379868976361],
            [30.086153598762706, 1.062312730306289],
            [30.46850752129029, 1.583805446779706],
            [30.85267011894806, 1.849396470543809],
            [31.174149204235817, 2.204465236821264],
            [30.77334679538004, 2.339883327642127],
            [30.83385989759381, 3.509165961110341],
            [30.833852421715427, 3.509171604222463],
            [31.24556, 3.7819],
            [31.88145, 3.55827],
            [32.68642, 3.79232],
            [33.3900000000001, 3.79],
            [34.005, 4.249884947362048],
            [34.47913, 3.5556],
            [34.59607, 3.053740000000118],
            [35.03599, 1.90584],
            [34.6721, 1.17694],
            [34.18, 0.515],
            [33.893568969666944, 0.109813537861896],
            [33.90371119710453, -0.95],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Lesotho",
        sov_a3: "LSO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Lesotho",
        adm0_a3: "LSO",
        geou_dif: 0,
        geounit: "Lesotho",
        gu_a3: "LSO",
        su_dif: 0,
        subunit: "Lesotho",
        su_a3: "LSO",
        brk_diff: 0,
        name: "Lesotho",
        name_long: "Lesotho",
        brk_a3: "LSO",
        brk_name: "Lesotho",
        brk_group: null,
        abbrev: "Les.",
        postal: "LS",
        formal_en: "Kingdom of Lesotho",
        formal_fr: null,
        name_ciawf: "Lesotho",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lesotho",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 8,
        pop_est: 2125268,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 2376,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "LT",
        iso_a2: "LS",
        iso_a2_eh: "LS",
        iso_a3: "LSO",
        iso_a3_eh: "LSO",
        iso_n3: "426",
        iso_n3_eh: "426",
        un_a3: "426",
        wb_a2: "LS",
        wb_a3: "LSO",
        woe_id: 23424880,
        woe_id_eh: 23424880,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LSO",
        adm0_diff: null,
        adm0_tlc: "LSO",
        adm0_a3_us: "LSO",
        adm0_a3_fr: "LSO",
        adm0_a3_ru: "LSO",
        adm0_a3_es: "LSO",
        adm0_a3_cn: "LSO",
        adm0_a3_tw: "LSO",
        adm0_a3_in: "LSO",
        adm0_a3_np: "LSO",
        adm0_a3_pk: "LSO",
        adm0_a3_de: "LSO",
        adm0_a3_gb: "LSO",
        adm0_a3_br: "LSO",
        adm0_a3_il: "LSO",
        adm0_a3_ps: "LSO",
        adm0_a3_sa: "LSO",
        adm0_a3_eg: "LSO",
        adm0_a3_ma: "LSO",
        adm0_a3_pt: "LSO",
        adm0_a3_ar: "LSO",
        adm0_a3_jp: "LSO",
        adm0_a3_ko: "LSO",
        adm0_a3_vn: "LSO",
        adm0_a3_tr: "LSO",
        adm0_a3_id: "LSO",
        adm0_a3_pl: "LSO",
        adm0_a3_gr: "LSO",
        adm0_a3_it: "LSO",
        adm0_a3_nl: "LSO",
        adm0_a3_se: "LSO",
        adm0_a3_bd: "LSO",
        adm0_a3_ua: "LSO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 28.246639,
        label_y: -29.480158,
        ne_id: 1159321027,
        wikidataid: "Q1013",
        name_ar: "ليسوتو",
        name_bn: "লেসোথো",
        name_de: "Lesotho",
        name_en: "Lesotho",
        name_es: "Lesoto",
        name_fa: "لسوتو",
        name_fr: "Lesotho",
        name_el: "Λεσότο",
        name_he: "לסוטו",
        name_hi: "लेसोथो",
        name_hu: "Lesotho",
        name_id: "Lesotho",
        name_it: "Lesotho",
        name_ja: "レソト",
        name_ko: "레소토",
        name_nl: "Lesotho",
        name_pl: "Lesotho",
        name_pt: "Lesoto",
        name_ru: "Лесото",
        name_sv: "Lesotho",
        name_tr: "Lesotho",
        name_uk: "Лесото",
        name_ur: "لیسوتھو",
        name_vi: "Lesotho",
        name_zh: "莱索托",
        name_zht: "賴索托",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LSO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.978262566857243, -28.95559661226171],
            [29.32516645683259, -29.257386976846256],
            [29.018415154748027, -29.74376555757737],
            [28.84839969250774, -30.070050551068256],
            [28.29106937023991, -30.2262167294543],
            [28.107204624145425, -30.54573211031495],
            [27.749397006956485, -30.645105889612225],
            [26.999261915807637, -29.875953871379984],
            [27.532511020627478, -29.24271087007536],
            [28.074338413207784, -28.851468601193588],
            [28.541700066855498, -28.64750172293757],
            [28.978262566857243, -28.95559661226171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Cameroon",
        sov_a3: "CMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cameroon",
        adm0_a3: "CMR",
        geou_dif: 0,
        geounit: "Cameroon",
        gu_a3: "CMR",
        su_dif: 0,
        subunit: "Cameroon",
        su_a3: "CMR",
        brk_diff: 0,
        name: "Cameroon",
        name_long: "Cameroon",
        brk_a3: "CMR",
        brk_name: "Cameroon",
        brk_group: null,
        abbrev: "Cam.",
        postal: "CM",
        formal_en: "Republic of Cameroon",
        formal_fr: null,
        name_ciawf: "Cameroon",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cameroon",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 3,
        pop_est: 25876380,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 39007,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CM",
        iso_a2: "CM",
        iso_a2_eh: "CM",
        iso_a3: "CMR",
        iso_a3_eh: "CMR",
        iso_n3: "120",
        iso_n3_eh: "120",
        un_a3: "120",
        wb_a2: "CM",
        wb_a3: "CMR",
        woe_id: 23424785,
        woe_id_eh: 23424785,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CMR",
        adm0_diff: null,
        adm0_tlc: "CMR",
        adm0_a3_us: "CMR",
        adm0_a3_fr: "CMR",
        adm0_a3_ru: "CMR",
        adm0_a3_es: "CMR",
        adm0_a3_cn: "CMR",
        adm0_a3_tw: "CMR",
        adm0_a3_in: "CMR",
        adm0_a3_np: "CMR",
        adm0_a3_pk: "CMR",
        adm0_a3_de: "CMR",
        adm0_a3_gb: "CMR",
        adm0_a3_br: "CMR",
        adm0_a3_il: "CMR",
        adm0_a3_ps: "CMR",
        adm0_a3_sa: "CMR",
        adm0_a3_eg: "CMR",
        adm0_a3_ma: "CMR",
        adm0_a3_pt: "CMR",
        adm0_a3_ar: "CMR",
        adm0_a3_jp: "CMR",
        adm0_a3_ko: "CMR",
        adm0_a3_vn: "CMR",
        adm0_a3_tr: "CMR",
        adm0_a3_id: "CMR",
        adm0_a3_pl: "CMR",
        adm0_a3_gr: "CMR",
        adm0_a3_it: "CMR",
        adm0_a3_nl: "CMR",
        adm0_a3_se: "CMR",
        adm0_a3_bd: "CMR",
        adm0_a3_ua: "CMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 12.473488,
        label_y: 4.585041,
        ne_id: 1159320509,
        wikidataid: "Q1009",
        name_ar: "الكاميرون",
        name_bn: "ক্যামেরুন",
        name_de: "Kamerun",
        name_en: "Cameroon",
        name_es: "Camerún",
        name_fa: "کامرون",
        name_fr: "Cameroun",
        name_el: "Καμερούν",
        name_he: "קמרון",
        name_hi: "कैमरुन",
        name_hu: "Kamerun",
        name_id: "Kamerun",
        name_it: "Camerun",
        name_ja: "カメルーン",
        name_ko: "카메룬",
        name_nl: "Kameroen",
        name_pl: "Kamerun",
        name_pt: "Camarões",
        name_ru: "Камерун",
        name_sv: "Kamerun",
        name_tr: "Kamerun",
        name_uk: "Камерун",
        name_ur: "کیمرون",
        name_vi: "Cameroon",
        name_zh: "喀麦隆",
        name_zht: "喀麥隆",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CMR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.495787387762846, 12.85939626713733],
            [14.89336, 12.21905],
            [14.9601518083376, 11.555574042197224],
            [14.92356489427496, 10.891325181517473],
            [15.467872755605242, 9.982336737503545],
            [14.909353875394716, 9.992129421422732],
            [14.62720055508106, 9.920919297724538],
            [14.171466098699028, 10.021378282099931],
            [13.954218377344006, 9.549494940626687],
            [14.54446658698177, 8.965861314322268],
            [14.97999555833769, 8.796104234243472],
            [15.120865512765306, 8.382150173369439],
            [15.436091749745742, 7.692812404811889],
            [15.279460483469109, 7.421924546737969],
            [14.776545444404576, 6.408498033062045],
            [14.536560092841114, 6.22695872642069],
            [14.459407179429348, 5.4517605656103],
            [14.558935988023507, 5.03059764243153],
            [14.47837243008047, 4.732605495620447],
            [14.950953403389661, 4.210389309094921],
            [15.036219516671252, 3.851367295747124],
            [15.405395948964383, 3.33530060466434],
            [15.862732374747482, 3.013537298998983],
            [15.907380812247652, 2.557389431158612],
            [16.012852410555354, 2.267639675298085],
            [15.940918816805066, 1.727672634280296],
            [15.146341993885244, 1.964014797367184],
            [14.33781253424658, 2.227874660649491],
            [13.075822381246752, 2.267097072759015],
            [12.951333855855609, 2.32161570882694],
            [12.359380323952221, 2.19281220133945],
            [11.75166548019979, 2.326757513839993],
            [11.276449008843713, 2.261050930180872],
            [9.649158155972628, 2.283866075037736],
            [9.795195753629457, 3.073404445809117],
            [9.404366896206, 3.734526882335203],
            [8.948115675501072, 3.904128933117136],
            [8.744923943729418, 4.35221527751996],
            [8.48881554529089, 4.495617377129918],
            [8.500287713259695, 4.771982937026849],
            [8.757532993208628, 5.479665839047911],
            [9.233162876023044, 6.444490668153335],
            [9.522705926154401, 6.453482367372117],
            [10.118276808318257, 7.03876963950988],
            [10.497375115611419, 7.055357774275564],
            [11.058787876030351, 6.644426784690594],
            [11.74577436691851, 6.981382961449754],
            [11.839308709366803, 7.397042344589437],
            [12.063946160539558, 7.799808457872302],
            [12.218872104550599, 8.305824082874324],
            [12.753671502339216, 8.717762762888995],
            [12.955467970438974, 9.417771714714704],
            [13.167599724997103, 9.640626328973411],
            [13.308676385153918, 10.160362046748928],
            [13.572949659894562, 10.798565985553566],
            [14.415378859116684, 11.572368882692075],
            [14.468192172918975, 11.904751695193411],
            [14.577177768622533, 12.085360826053503],
            [14.181336297266794, 12.483656927943116],
            [14.213530714584635, 12.802035427293347],
            [14.495787387762846, 12.85939626713733],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Gabon",
        sov_a3: "GAB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Gabon",
        adm0_a3: "GAB",
        geou_dif: 0,
        geounit: "Gabon",
        gu_a3: "GAB",
        su_dif: 0,
        subunit: "Gabon",
        su_a3: "GAB",
        brk_diff: 0,
        name: "Gabon",
        name_long: "Gabon",
        brk_a3: "GAB",
        brk_name: "Gabon",
        brk_group: null,
        abbrev: "Gabon",
        postal: "GA",
        formal_en: "Gabonese Republic",
        formal_fr: null,
        name_ciawf: "Gabon",
        note_adm0: null,
        note_brk: null,
        name_sort: "Gabon",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 2172579,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 16874,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "GB",
        iso_a2: "GA",
        iso_a2_eh: "GA",
        iso_a3: "GAB",
        iso_a3_eh: "GAB",
        iso_n3: "266",
        iso_n3_eh: "266",
        un_a3: "266",
        wb_a2: "GA",
        wb_a3: "GAB",
        woe_id: 23424822,
        woe_id_eh: 23424822,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GAB",
        adm0_diff: null,
        adm0_tlc: "GAB",
        adm0_a3_us: "GAB",
        adm0_a3_fr: "GAB",
        adm0_a3_ru: "GAB",
        adm0_a3_es: "GAB",
        adm0_a3_cn: "GAB",
        adm0_a3_tw: "GAB",
        adm0_a3_in: "GAB",
        adm0_a3_np: "GAB",
        adm0_a3_pk: "GAB",
        adm0_a3_de: "GAB",
        adm0_a3_gb: "GAB",
        adm0_a3_br: "GAB",
        adm0_a3_il: "GAB",
        adm0_a3_ps: "GAB",
        adm0_a3_sa: "GAB",
        adm0_a3_eg: "GAB",
        adm0_a3_ma: "GAB",
        adm0_a3_pt: "GAB",
        adm0_a3_ar: "GAB",
        adm0_a3_jp: "GAB",
        adm0_a3_ko: "GAB",
        adm0_a3_vn: "GAB",
        adm0_a3_tr: "GAB",
        adm0_a3_id: "GAB",
        adm0_a3_pl: "GAB",
        adm0_a3_gr: "GAB",
        adm0_a3_it: "GAB",
        adm0_a3_nl: "GAB",
        adm0_a3_se: "GAB",
        adm0_a3_bd: "GAB",
        adm0_a3_ua: "GAB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 11.835939,
        label_y: -0.437739,
        ne_id: 1159320693,
        wikidataid: "Q1000",
        name_ar: "الغابون",
        name_bn: "গ্যাবন",
        name_de: "Gabun",
        name_en: "Gabon",
        name_es: "Gabón",
        name_fa: "گابن",
        name_fr: "Gabon",
        name_el: "Γκαμπόν",
        name_he: "גבון",
        name_hi: "गबॉन",
        name_hu: "Gabon",
        name_id: "Gabon",
        name_it: "Gabon",
        name_ja: "ガボン",
        name_ko: "가봉",
        name_nl: "Gabon",
        name_pl: "Gabon",
        name_pt: "Gabão",
        name_ru: "Габон",
        name_sv: "Gabon",
        name_tr: "Gabon",
        name_uk: "Габон",
        name_ur: "گیبون",
        name_vi: "Gabon",
        name_zh: "加蓬",
        name_zht: "加彭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GAB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.276449008843713, 2.261050930180872],
            [11.75166548019979, 2.326757513839993],
            [12.359380323952221, 2.19281220133945],
            [12.951333855855609, 2.32161570882694],
            [13.075822381246752, 2.267097072759015],
            [13.003113641012078, 1.83089630778332],
            [13.282631463278818, 1.31418366129688],
            [14.026668735417218, 1.395677395021153],
            [14.276265903386957, 1.196929836426619],
            [13.843320753645656, 0.038757635901149],
            [14.316418491277744, -0.552627455247048],
            [14.425455763413595, -1.333406670744971],
            [14.299210239324566, -1.998275648612214],
            [13.99240726080771, -2.4708049454891],
            [13.109618767965628, -2.428740329603514],
            [12.575284458067642, -1.948511244315135],
            [12.495702752338161, -2.391688327650243],
            [11.820963575903193, -2.514161472181982],
            [11.478038771214303, -2.765618991714241],
            [11.855121697648116, -3.426870619321051],
            [11.093772820691925, -3.978826592630547],
            [10.06613528813574, -2.969482517105682],
            [9.40524539555497, -2.144313246269043],
            [8.79799563969317, -1.111301364754496],
            [8.830086704146424, -0.779073581550037],
            [9.048419630579588, -0.459351494960217],
            [9.29135053878369, 0.268666083167687],
            [9.492888624721985, 1.010119533691494],
            [9.830284051155644, 1.067893784993799],
            [11.285078973036462, 1.057661851400013],
            [11.276449008843713, 2.261050930180872],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Niger",
        sov_a3: "NER",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Niger",
        adm0_a3: "NER",
        geou_dif: 0,
        geounit: "Niger",
        gu_a3: "NER",
        su_dif: 0,
        subunit: "Niger",
        su_a3: "NER",
        brk_diff: 0,
        name: "Niger",
        name_long: "Niger",
        brk_a3: "NER",
        brk_name: "Niger",
        brk_group: null,
        abbrev: "Niger",
        postal: "NE",
        formal_en: "Republic of Niger",
        formal_fr: null,
        name_ciawf: "Niger",
        note_adm0: null,
        note_brk: null,
        name_sort: "Niger",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 23310715,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 12911,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "NG",
        iso_a2: "NE",
        iso_a2_eh: "NE",
        iso_a3: "NER",
        iso_a3_eh: "NER",
        iso_n3: "562",
        iso_n3_eh: "562",
        un_a3: "562",
        wb_a2: "NE",
        wb_a3: "NER",
        woe_id: 23424906,
        woe_id_eh: 23424906,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NER",
        adm0_diff: null,
        adm0_tlc: "NER",
        adm0_a3_us: "NER",
        adm0_a3_fr: "NER",
        adm0_a3_ru: "NER",
        adm0_a3_es: "NER",
        adm0_a3_cn: "NER",
        adm0_a3_tw: "NER",
        adm0_a3_in: "NER",
        adm0_a3_np: "NER",
        adm0_a3_pk: "NER",
        adm0_a3_de: "NER",
        adm0_a3_gb: "NER",
        adm0_a3_br: "NER",
        adm0_a3_il: "NER",
        adm0_a3_ps: "NER",
        adm0_a3_sa: "NER",
        adm0_a3_eg: "NER",
        adm0_a3_ma: "NER",
        adm0_a3_pt: "NER",
        adm0_a3_ar: "NER",
        adm0_a3_jp: "NER",
        adm0_a3_ko: "NER",
        adm0_a3_vn: "NER",
        adm0_a3_tr: "NER",
        adm0_a3_id: "NER",
        adm0_a3_pl: "NER",
        adm0_a3_gr: "NER",
        adm0_a3_it: "NER",
        adm0_a3_nl: "NER",
        adm0_a3_se: "NER",
        adm0_a3_bd: "NER",
        adm0_a3_ua: "NER",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 9.504356,
        label_y: 17.446195,
        ne_id: 1159321087,
        wikidataid: "Q1032",
        name_ar: "النيجر",
        name_bn: "নাইজার",
        name_de: "Niger",
        name_en: "Niger",
        name_es: "Níger",
        name_fa: "نیجر",
        name_fr: "Niger",
        name_el: "Νίγηρας",
        name_he: "ניז'ר",
        name_hi: "नाइजर",
        name_hu: "Niger",
        name_id: "Niger",
        name_it: "Niger",
        name_ja: "ニジェール",
        name_ko: "니제르",
        name_nl: "Niger",
        name_pl: "Niger",
        name_pt: "Níger",
        name_ru: "Нигер",
        name_sv: "Niger",
        name_tr: "Nijer",
        name_uk: "Нігер",
        name_ur: "نائجر",
        name_vi: "Niger",
        name_zh: "尼日尔",
        name_zht: "尼日",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NER.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.8513, 22.862950000000126],
            [15.096887648181848, 21.30851878507491],
            [15.47106, 21.04845],
            [15.487148064850146, 20.730414537025638],
            [15.903246697664315, 20.387618923417506],
            [15.685740594147774, 19.957180080642388],
            [15.30044111497972, 17.927949937405003],
            [15.247731154041844, 16.627305813050782],
            [13.97217, 15.68437],
            [13.540393507550789, 14.367133693901224],
            [13.956698846094127, 13.996691189016929],
            [13.95447675950561, 13.353448798063766],
            [14.595781284247607, 13.33042694747786],
            [14.495787387762846, 12.85939626713733],
            [14.213530714584635, 12.802035427293347],
            [14.181336297266794, 12.483656927943116],
            [13.995352817448293, 12.461565253138303],
            [13.318701613018561, 13.556356309457826],
            [13.083987257548813, 13.596147162322495],
            [12.302071160540523, 13.037189032437524],
            [11.527803175511394, 13.328980007373588],
            [10.989593133191534, 13.387322699431195],
            [10.701031935273706, 13.246917832894084],
            [10.114814487354693, 13.27725189864941],
            [9.524928012742947, 12.851102199754479],
            [9.014933302454438, 12.826659247280418],
            [7.804671258178786, 13.343526923063747],
            [7.330746697630019, 13.0980380314612],
            [6.820441928747754, 13.115091254117518],
            [6.445426059605637, 13.492768459522678],
            [5.443058302440136, 13.865923977102227],
            [4.368343540066007, 13.747481594289411],
            [4.107945997747322, 13.531215725147831],
            [3.967282749048849, 12.956108710171575],
            [3.680633579125811, 12.552903347214226],
            [3.611180454125559, 11.660167141155968],
            [2.848643019226586, 12.23563589115821],
            [2.49016360841793, 12.233052069543675],
            [2.154473504249921, 11.940150051313339],
            [2.177107781593776, 12.625017808477535],
            [1.024103224297477, 12.851825669806574],
            [0.993045688490071, 13.335749620003824],
            [0.429927605805517, 13.988733018443924],
            [0.295646396495101, 14.444234930880654],
            [0.374892205414682, 14.92890818934613],
            [1.015783318698482, 14.96818227788799],
            [1.385528191746858, 15.323561102759172],
            [2.749992709981484, 15.409524847876696],
            [3.638258904646477, 15.568119818580456],
            [3.723421665063483, 16.184283759012615],
            [4.270209995143802, 16.852227484601215],
            [4.267419467800039, 19.155265204337],
            [5.677565952180686, 19.601206976799716],
            [8.572893100629784, 21.565660712159143],
            [11.999505649471613, 23.47166840259645],
            [13.581424594790462, 23.04050608976928],
            [14.143870883855243, 22.491288967371133],
            [14.8513, 22.862950000000126],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Burkina Faso",
        sov_a3: "BFA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Burkina Faso",
        adm0_a3: "BFA",
        geou_dif: 0,
        geounit: "Burkina Faso",
        gu_a3: "BFA",
        su_dif: 0,
        subunit: "Burkina Faso",
        su_a3: "BFA",
        brk_diff: 0,
        name: "Burkina Faso",
        name_long: "Burkina Faso",
        brk_a3: "BFA",
        brk_name: "Burkina Faso",
        brk_group: null,
        abbrev: "B.F.",
        postal: "BF",
        formal_en: "Burkina Faso",
        formal_fr: null,
        name_ciawf: "Burkina Faso",
        note_adm0: null,
        note_brk: null,
        name_sort: "Burkina Faso",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 11,
        pop_est: 20321378,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 15990,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "UV",
        iso_a2: "BF",
        iso_a2_eh: "BF",
        iso_a3: "BFA",
        iso_a3_eh: "BFA",
        iso_n3: "854",
        iso_n3_eh: "854",
        un_a3: "854",
        wb_a2: "BF",
        wb_a3: "BFA",
        woe_id: 23424978,
        woe_id_eh: 23424978,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BFA",
        adm0_diff: null,
        adm0_tlc: "BFA",
        adm0_a3_us: "BFA",
        adm0_a3_fr: "BFA",
        adm0_a3_ru: "BFA",
        adm0_a3_es: "BFA",
        adm0_a3_cn: "BFA",
        adm0_a3_tw: "BFA",
        adm0_a3_in: "BFA",
        adm0_a3_np: "BFA",
        adm0_a3_pk: "BFA",
        adm0_a3_de: "BFA",
        adm0_a3_gb: "BFA",
        adm0_a3_br: "BFA",
        adm0_a3_il: "BFA",
        adm0_a3_ps: "BFA",
        adm0_a3_sa: "BFA",
        adm0_a3_eg: "BFA",
        adm0_a3_ma: "BFA",
        adm0_a3_pt: "BFA",
        adm0_a3_ar: "BFA",
        adm0_a3_jp: "BFA",
        adm0_a3_ko: "BFA",
        adm0_a3_vn: "BFA",
        adm0_a3_tr: "BFA",
        adm0_a3_id: "BFA",
        adm0_a3_pl: "BFA",
        adm0_a3_gr: "BFA",
        adm0_a3_it: "BFA",
        adm0_a3_nl: "BFA",
        adm0_a3_se: "BFA",
        adm0_a3_bd: "BFA",
        adm0_a3_ua: "BFA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -1.36388,
        label_y: 12.673048,
        ne_id: 1159320405,
        wikidataid: "Q965",
        name_ar: "بوركينا فاسو",
        name_bn: "বুর্কিনা ফাসো",
        name_de: "Burkina Faso",
        name_en: "Burkina Faso",
        name_es: "Burkina Faso",
        name_fa: "بورکینافاسو",
        name_fr: "Burkina Faso",
        name_el: "Μπουρκίνα Φάσο",
        name_he: "בורקינה פאסו",
        name_hi: "बुर्किना फासो",
        name_hu: "Burkina Faso",
        name_id: "Burkina Faso",
        name_it: "Burkina Faso",
        name_ja: "ブルキナファソ",
        name_ko: "부르키나파소",
        name_nl: "Burkina Faso",
        name_pl: "Burkina Faso",
        name_pt: "Burkina Faso",
        name_ru: "Буркина-Фасо",
        name_sv: "Burkina Faso",
        name_tr: "Burkina Faso",
        name_uk: "Буркіна-Фасо",
        name_ur: "برکینا فاسو",
        name_vi: "Burkina Faso",
        name_zh: "布基纳法索",
        name_zht: "布基納法索",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BFA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.404341599946974, 10.370736802609146],
            [-5.470564947929006, 10.951269842976048],
            [-5.197842576508648, 11.37514577885014],
            [-5.220941941743121, 11.713858954307227],
            [-4.427166103523803, 12.542645575404295],
            [-4.28040503581488, 13.228443508349741],
            [-4.006390753587226, 13.472485459848116],
            [-3.522802700199861, 13.337661647998615],
            [-3.10370683431276, 13.541266791228594],
            [-2.967694464520577, 13.79815033615151],
            [-2.191824510090385, 14.246417548067356],
            [-2.001035122068771, 14.559008287000893],
            [-1.066363491205664, 14.973815009007765],
            [-0.515854458000348, 15.116157741755728],
            [-0.26625729003058, 14.924308986872148],
            [0.374892205414682, 14.92890818934613],
            [0.295646396495101, 14.444234930880654],
            [0.429927605805517, 13.988733018443924],
            [0.993045688490071, 13.335749620003824],
            [1.024103224297477, 12.851825669806574],
            [2.177107781593776, 12.625017808477535],
            [2.154473504249921, 11.940150051313339],
            [1.935985548519881, 11.641150214072553],
            [1.447178175471066, 11.547719224488858],
            [1.243469679376489, 11.110510769083461],
            [0.899563022474069, 10.99733938236426],
            [0.023802524423701, 11.018681748900804],
            [-0.438701544588582, 11.098340969278722],
            [-0.761575893548183, 10.936929633015055],
            [-1.203357713211432, 11.009819240762738],
            [-2.940409308270461, 10.962690334512558],
            [-2.963896246747112, 10.395334784380083],
            [-2.827496303712707, 9.642460842319778],
            [-3.511898972986273, 9.90032623945622],
            [-3.980449184576685, 9.8623440617217],
            [-4.330246954760383, 9.610834865757141],
            [-4.779883592131966, 9.821984768101743],
            [-4.954653286143099, 10.152713934769736],
            [-5.404341599946974, 10.370736802609146],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Togo",
        sov_a3: "TGO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Togo",
        adm0_a3: "TGO",
        geou_dif: 0,
        geounit: "Togo",
        gu_a3: "TGO",
        su_dif: 0,
        subunit: "Togo",
        su_a3: "TGO",
        brk_diff: 0,
        name: "Togo",
        name_long: "Togo",
        brk_a3: "TGO",
        brk_name: "Togo",
        brk_group: null,
        abbrev: "Togo",
        postal: "TG",
        formal_en: "Togolese Republic",
        formal_fr: "République Togolaise",
        name_ciawf: "Togo",
        note_adm0: null,
        note_brk: null,
        name_sort: "Togo",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 5,
        pop_est: 8082366,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 5490,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "TO",
        iso_a2: "TG",
        iso_a2_eh: "TG",
        iso_a3: "TGO",
        iso_a3_eh: "TGO",
        iso_n3: "768",
        iso_n3_eh: "768",
        un_a3: "768",
        wb_a2: "TG",
        wb_a3: "TGO",
        woe_id: 23424965,
        woe_id_eh: 23424965,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TGO",
        adm0_diff: null,
        adm0_tlc: "TGO",
        adm0_a3_us: "TGO",
        adm0_a3_fr: "TGO",
        adm0_a3_ru: "TGO",
        adm0_a3_es: "TGO",
        adm0_a3_cn: "TGO",
        adm0_a3_tw: "TGO",
        adm0_a3_in: "TGO",
        adm0_a3_np: "TGO",
        adm0_a3_pk: "TGO",
        adm0_a3_de: "TGO",
        adm0_a3_gb: "TGO",
        adm0_a3_br: "TGO",
        adm0_a3_il: "TGO",
        adm0_a3_ps: "TGO",
        adm0_a3_sa: "TGO",
        adm0_a3_eg: "TGO",
        adm0_a3_ma: "TGO",
        adm0_a3_pt: "TGO",
        adm0_a3_ar: "TGO",
        adm0_a3_jp: "TGO",
        adm0_a3_ko: "TGO",
        adm0_a3_vn: "TGO",
        adm0_a3_tr: "TGO",
        adm0_a3_id: "TGO",
        adm0_a3_pl: "TGO",
        adm0_a3_gr: "TGO",
        adm0_a3_it: "TGO",
        adm0_a3_nl: "TGO",
        adm0_a3_se: "TGO",
        adm0_a3_bd: "TGO",
        adm0_a3_ua: "TGO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 1.058113,
        label_y: 8.80722,
        ne_id: 1159321303,
        wikidataid: "Q945",
        name_ar: "توغو",
        name_bn: "টোগো",
        name_de: "Togo",
        name_en: "Togo",
        name_es: "Togo",
        name_fa: "توگو",
        name_fr: "Togo",
        name_el: "Τόγκο",
        name_he: "טוגו",
        name_hi: "टोगो",
        name_hu: "Togo",
        name_id: "Togo",
        name_it: "Togo",
        name_ja: "トーゴ",
        name_ko: "토고",
        name_nl: "Togo",
        name_pl: "Togo",
        name_pt: "Togo",
        name_ru: "Того",
        name_sv: "Togo",
        name_tr: "Togo",
        name_uk: "Того",
        name_ur: "ٹوگو",
        name_vi: "Togo",
        name_zh: "多哥",
        name_zht: "多哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TGO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.899563022474069, 10.99733938236426],
            [0.772335646171484, 10.470808213742359],
            [1.077795037448738, 10.175606594275024],
            [1.425060662450136, 9.825395412633],
            [1.46304284018467, 9.334624335157088],
            [1.664477573258381, 9.12859039960938],
            [1.618950636409238, 6.832038072126238],
            [1.865240512712319, 6.142157701029731],
            [1.060121697604927, 5.928837388528876],
            [0.836931186536333, 6.279978745952149],
            [0.570384148774849, 6.914358628767189],
            [0.490957472342245, 7.411744289576475],
            [0.712029249686879, 8.31246450442383],
            [0.461191847342121, 8.677222601756014],
            [0.365900506195885, 9.465003973829482],
            [0.367579990245389, 10.19121287682718],
            [-0.049784715159944, 10.706917832883931],
            [0.023802524423701, 11.018681748900804],
            [0.899563022474069, 10.99733938236426],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ghana",
        sov_a3: "GHA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ghana",
        adm0_a3: "GHA",
        geou_dif: 0,
        geounit: "Ghana",
        gu_a3: "GHA",
        su_dif: 0,
        subunit: "Ghana",
        su_a3: "GHA",
        brk_diff: 0,
        name: "Ghana",
        name_long: "Ghana",
        brk_a3: "GHA",
        brk_name: "Ghana",
        brk_group: null,
        abbrev: "Ghana",
        postal: "GH",
        formal_en: "Republic of Ghana",
        formal_fr: null,
        name_ciawf: "Ghana",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ghana",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 30417856,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 66983,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GH",
        iso_a2: "GH",
        iso_a2_eh: "GH",
        iso_a3: "GHA",
        iso_a3_eh: "GHA",
        iso_n3: "288",
        iso_n3_eh: "288",
        un_a3: "288",
        wb_a2: "GH",
        wb_a3: "GHA",
        woe_id: 23424824,
        woe_id_eh: 23424824,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GHA",
        adm0_diff: null,
        adm0_tlc: "GHA",
        adm0_a3_us: "GHA",
        adm0_a3_fr: "GHA",
        adm0_a3_ru: "GHA",
        adm0_a3_es: "GHA",
        adm0_a3_cn: "GHA",
        adm0_a3_tw: "GHA",
        adm0_a3_in: "GHA",
        adm0_a3_np: "GHA",
        adm0_a3_pk: "GHA",
        adm0_a3_de: "GHA",
        adm0_a3_gb: "GHA",
        adm0_a3_br: "GHA",
        adm0_a3_il: "GHA",
        adm0_a3_ps: "GHA",
        adm0_a3_sa: "GHA",
        adm0_a3_eg: "GHA",
        adm0_a3_ma: "GHA",
        adm0_a3_pt: "GHA",
        adm0_a3_ar: "GHA",
        adm0_a3_jp: "GHA",
        adm0_a3_ko: "GHA",
        adm0_a3_vn: "GHA",
        adm0_a3_tr: "GHA",
        adm0_a3_id: "GHA",
        adm0_a3_pl: "GHA",
        adm0_a3_gr: "GHA",
        adm0_a3_it: "GHA",
        adm0_a3_nl: "GHA",
        adm0_a3_se: "GHA",
        adm0_a3_bd: "GHA",
        adm0_a3_ua: "GHA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -1.036941,
        label_y: 7.717639,
        ne_id: 1159320793,
        wikidataid: "Q117",
        name_ar: "غانا",
        name_bn: "ঘানা",
        name_de: "Ghana",
        name_en: "Ghana",
        name_es: "Ghana",
        name_fa: "غنا",
        name_fr: "Ghana",
        name_el: "Γκάνα",
        name_he: "גאנה",
        name_hi: "घाना",
        name_hu: "Ghána",
        name_id: "Ghana",
        name_it: "Ghana",
        name_ja: "ガーナ",
        name_ko: "가나",
        name_nl: "Ghana",
        name_pl: "Ghana",
        name_pt: "Gana",
        name_ru: "Гана",
        name_sv: "Ghana",
        name_tr: "Gana",
        name_uk: "Гана",
        name_ur: "گھانا",
        name_vi: "Ghana",
        name_zh: "加纳",
        name_zht: "迦納",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GHA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.023802524423701, 11.018681748900804],
            [-0.049784715159944, 10.706917832883931],
            [0.367579990245389, 10.19121287682718],
            [0.365900506195885, 9.465003973829482],
            [0.461191847342121, 8.677222601756014],
            [0.712029249686879, 8.31246450442383],
            [0.490957472342245, 7.411744289576475],
            [0.570384148774849, 6.914358628767189],
            [0.836931186536333, 6.279978745952149],
            [1.060121697604927, 5.928837388528876],
            [-0.507637905265938, 5.343472601742675],
            [-1.063624640294194, 5.000547797053812],
            [-1.964706590167594, 4.710462144383371],
            [-2.856125047202397, 4.994475816259509],
            [-2.81070146321784, 5.38905121502411],
            [-3.244370083011262, 6.250471503113502],
            [-2.983584967450327, 7.379704901555513],
            [-2.562189500326241, 8.219627793811483],
            [-2.827496303712707, 9.642460842319778],
            [-2.963896246747112, 10.395334784380083],
            [-2.940409308270461, 10.962690334512558],
            [-1.203357713211432, 11.009819240762738],
            [-0.761575893548183, 10.936929633015055],
            [-0.438701544588582, 11.098340969278722],
            [0.023802524423701, 11.018681748900804],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Guinea-Bissau",
        sov_a3: "GNB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guinea-Bissau",
        adm0_a3: "GNB",
        geou_dif: 0,
        geounit: "Guinea-Bissau",
        gu_a3: "GNB",
        su_dif: 0,
        subunit: "Guinea-Bissau",
        su_a3: "GNB",
        brk_diff: 0,
        name: "Guinea-Bissau",
        name_long: "Guinea-Bissau",
        brk_a3: "GNB",
        brk_name: "Guinea-Bissau",
        brk_group: null,
        abbrev: "GnB.",
        postal: "GW",
        formal_en: "Republic of Guinea-Bissau",
        formal_fr: null,
        name_ciawf: "Guinea-Bissau",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guinea-Bissau",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 1920922,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 1339,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "PU",
        iso_a2: "GW",
        iso_a2_eh: "GW",
        iso_a3: "GNB",
        iso_a3_eh: "GNB",
        iso_n3: "624",
        iso_n3_eh: "624",
        un_a3: "624",
        wb_a2: "GW",
        wb_a3: "GNB",
        woe_id: 23424929,
        woe_id_eh: 23424929,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GNB",
        adm0_diff: null,
        adm0_tlc: "GNB",
        adm0_a3_us: "GNB",
        adm0_a3_fr: "GNB",
        adm0_a3_ru: "GNB",
        adm0_a3_es: "GNB",
        adm0_a3_cn: "GNB",
        adm0_a3_tw: "GNB",
        adm0_a3_in: "GNB",
        adm0_a3_np: "GNB",
        adm0_a3_pk: "GNB",
        adm0_a3_de: "GNB",
        adm0_a3_gb: "GNB",
        adm0_a3_br: "GNB",
        adm0_a3_il: "GNB",
        adm0_a3_ps: "GNB",
        adm0_a3_sa: "GNB",
        adm0_a3_eg: "GNB",
        adm0_a3_ma: "GNB",
        adm0_a3_pt: "GNB",
        adm0_a3_ar: "GNB",
        adm0_a3_jp: "GNB",
        adm0_a3_ko: "GNB",
        adm0_a3_vn: "GNB",
        adm0_a3_tr: "GNB",
        adm0_a3_id: "GNB",
        adm0_a3_pl: "GNB",
        adm0_a3_gr: "GNB",
        adm0_a3_it: "GNB",
        adm0_a3_nl: "GNB",
        adm0_a3_se: "GNB",
        adm0_a3_bd: "GNB",
        adm0_a3_ua: "GNB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 13,
        long_len: 13,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -14.52413,
        label_y: 12.163712,
        ne_id: 1159320799,
        wikidataid: "Q1007",
        name_ar: "غينيا بيساو",
        name_bn: "গিনি-বিসাউ",
        name_de: "Guinea-Bissau",
        name_en: "Guinea-Bissau",
        name_es: "Guinea-Bisáu",
        name_fa: "گینه بیسائو",
        name_fr: "Guinée-Bissau",
        name_el: "Γουινέα-Μπισσάου",
        name_he: "גינאה ביסאו",
        name_hi: "गिनी-बिसाऊ",
        name_hu: "Bissau-Guinea",
        name_id: "Guinea-Bissau",
        name_it: "Guinea-Bissau",
        name_ja: "ギニアビサウ",
        name_ko: "기니비사우",
        name_nl: "Guinee-Bissau",
        name_pl: "Gwinea Bissau",
        name_pt: "Guiné-Bissau",
        name_ru: "Гвинея-Бисау",
        name_sv: "Guinea-Bissau",
        name_tr: "Gine-Bissau",
        name_uk: "Гвінея-Бісау",
        name_ur: "گنی بساؤ",
        name_vi: "Guiné-Bissau",
        name_zh: "几内亚比绍",
        name_zht: "幾內亞比索",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GNB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.677451951554573, 12.384851589401052],
            [-16.147716844130585, 12.547761542201187],
            [-15.816574266004254, 12.515567124883347],
            [-15.54847693527401, 12.628170070847347],
            [-13.700476040084325, 12.586182969610194],
            [-13.718743658899513, 12.24718557377551],
            [-13.828271857142125, 12.142644151249044],
            [-13.743160773157413, 11.811269029177412],
            [-13.900799729863776, 11.678718980348748],
            [-14.121406419317779, 11.677117010947697],
            [-14.382191534878729, 11.509271958863692],
            [-14.685687221728898, 11.527823798056488],
            [-15.130311245168173, 11.040411688679526],
            [-15.664180467175527, 11.458474025920795],
            [-16.085214199273565, 11.52459402103824],
            [-16.314786749730203, 11.80651479740655],
            [-16.30894731288123, 11.95870189050612],
            [-16.61383826340328, 12.170911159712702],
            [-16.677451951554573, 12.384851589401052],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mauritania",
        sov_a3: "MRT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mauritania",
        adm0_a3: "MRT",
        geou_dif: 0,
        geounit: "Mauritania",
        gu_a3: "MRT",
        su_dif: 0,
        subunit: "Mauritania",
        su_a3: "MRT",
        brk_diff: 0,
        name: "Mauritania",
        name_long: "Mauritania",
        brk_a3: "MRT",
        brk_name: "Mauritania",
        brk_group: null,
        abbrev: "Mrt.",
        postal: "MR",
        formal_en: "Islamic Republic of Mauritania",
        formal_fr: null,
        name_ciawf: "Mauritania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mauritania",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 1,
        pop_est: 4525696,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 7600,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MR",
        iso_a2: "MR",
        iso_a2_eh: "MR",
        iso_a3: "MRT",
        iso_a3_eh: "MRT",
        iso_n3: "478",
        iso_n3_eh: "478",
        un_a3: "478",
        wb_a2: "MR",
        wb_a3: "MRT",
        woe_id: 23424896,
        woe_id_eh: 23424896,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MRT",
        adm0_diff: null,
        adm0_tlc: "MRT",
        adm0_a3_us: "MRT",
        adm0_a3_fr: "MRT",
        adm0_a3_ru: "MRT",
        adm0_a3_es: "MRT",
        adm0_a3_cn: "MRT",
        adm0_a3_tw: "MRT",
        adm0_a3_in: "MRT",
        adm0_a3_np: "MRT",
        adm0_a3_pk: "MRT",
        adm0_a3_de: "MRT",
        adm0_a3_gb: "MRT",
        adm0_a3_br: "MRT",
        adm0_a3_il: "MRT",
        adm0_a3_ps: "MRT",
        adm0_a3_sa: "MRT",
        adm0_a3_eg: "MRT",
        adm0_a3_ma: "MRT",
        adm0_a3_pt: "MRT",
        adm0_a3_ar: "MRT",
        adm0_a3_jp: "MRT",
        adm0_a3_ko: "MRT",
        adm0_a3_vn: "MRT",
        adm0_a3_tr: "MRT",
        adm0_a3_id: "MRT",
        adm0_a3_pl: "MRT",
        adm0_a3_gr: "MRT",
        adm0_a3_it: "MRT",
        adm0_a3_nl: "MRT",
        adm0_a3_se: "MRT",
        adm0_a3_bd: "MRT",
        adm0_a3_ua: "MRT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -9.740299,
        label_y: 19.587062,
        ne_id: 1159321075,
        wikidataid: "Q1025",
        name_ar: "موريتانيا",
        name_bn: "মৌরিতানিয়া",
        name_de: "Mauretanien",
        name_en: "Mauritania",
        name_es: "Mauritania",
        name_fa: "موریتانی",
        name_fr: "Mauritanie",
        name_el: "Μαυριτανία",
        name_he: "מאוריטניה",
        name_hi: "मॉरीतानिया",
        name_hu: "Mauritánia",
        name_id: "Mauritania",
        name_it: "Mauritania",
        name_ja: "モーリタニア",
        name_ko: "모리타니",
        name_nl: "Mauritanië",
        name_pl: "Mauretania",
        name_pt: "Mauritânia",
        name_ru: "Мавритания",
        name_sv: "Mauretanien",
        name_tr: "Moritanya",
        name_uk: "Мавританія",
        name_ur: "موریتانیہ",
        name_vi: "Mauritanie",
        name_zh: "毛里塔尼亚",
        name_zht: "茅利塔尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MRT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.06342322434257, 20.999752102130827],
            [-16.845193650773993, 21.33332347257488],
            [-12.929101935263532, 21.327070624267563],
            [-13.118754441774712, 22.771220201096256],
            [-12.874221564169575, 23.284832261645178],
            [-11.937224493853321, 23.374594224536168],
            [-11.96941891117116, 25.933352769468268],
            [-8.6872936670174, 25.881056219988906],
            [-8.684399786809053, 27.395744126896005],
            [-4.923337368174231, 24.974574082941],
            [-6.453786586930335, 24.956590684503425],
            [-5.971128709324248, 20.64083344164763],
            [-5.488522508150439, 16.325102037007966],
            [-5.315277268891933, 16.20185374599184],
            [-5.537744309908447, 15.501689764869257],
            [-9.55023840985939, 15.486496893775438],
            [-9.700255092802706, 15.264107367407362],
            [-10.086846482778213, 15.330485744686271],
            [-10.650791388379417, 15.132745876521426],
            [-11.349095017939504, 15.411256008358478],
            [-11.666078253617854, 15.388208319556298],
            [-11.834207526079467, 14.79909699142894],
            [-12.170750291380301, 14.616834214735505],
            [-12.830658331747516, 15.303691514542948],
            [-13.435737677453062, 16.03938304286619],
            [-14.099521450242179, 16.304302273010492],
            [-14.577347581428981, 16.59826365810281],
            [-15.135737270558819, 16.587282416240782],
            [-15.623666144258692, 16.369337063049812],
            [-16.12069007004193, 16.455662543193384],
            [-16.463098110407884, 16.13503611903846],
            [-16.549707810929064, 16.673892116761962],
            [-16.270551723688357, 17.166962795474873],
            [-16.14634741867485, 18.108481553616656],
            [-16.256883307347167, 19.096715806550307],
            [-16.37765112961327, 19.593817246981985],
            [-16.277838100641517, 20.0925206568147],
            [-16.536323614965468, 20.567866319251493],
            [-17.06342322434257, 20.999752102130827],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Egypt",
        sov_a3: "EGY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Egypt",
        adm0_a3: "EGY",
        geou_dif: 0,
        geounit: "Egypt",
        gu_a3: "EGY",
        su_dif: 0,
        subunit: "Egypt",
        su_a3: "EGY",
        brk_diff: 0,
        name: "Egypt",
        name_long: "Egypt",
        brk_a3: "EGY",
        brk_name: "Egypt",
        brk_group: null,
        abbrev: "Egypt",
        postal: "EG",
        formal_en: "Arab Republic of Egypt",
        formal_fr: null,
        name_ciawf: "Egypt",
        note_adm0: null,
        note_brk: null,
        name_sort: "Egypt, Arab Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 100388073,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 303092,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "EG",
        iso_a2: "EG",
        iso_a2_eh: "EG",
        iso_a3: "EGY",
        iso_a3_eh: "EGY",
        iso_n3: "818",
        iso_n3_eh: "818",
        un_a3: "818",
        wb_a2: "EG",
        wb_a3: "EGY",
        woe_id: 23424802,
        woe_id_eh: 23424802,
        woe_note: "Exact WOE match as country",
        adm0_iso: "EGY",
        adm0_diff: null,
        adm0_tlc: "EGY",
        adm0_a3_us: "EGY",
        adm0_a3_fr: "EGY",
        adm0_a3_ru: "EGY",
        adm0_a3_es: "EGY",
        adm0_a3_cn: "EGY",
        adm0_a3_tw: "EGY",
        adm0_a3_in: "EGY",
        adm0_a3_np: "EGY",
        adm0_a3_pk: "EGY",
        adm0_a3_de: "EGY",
        adm0_a3_gb: "EGY",
        adm0_a3_br: "EGY",
        adm0_a3_il: "EGY",
        adm0_a3_ps: "EGY",
        adm0_a3_sa: "EGY",
        adm0_a3_eg: "EGY",
        adm0_a3_ma: "EGY",
        adm0_a3_pt: "EGY",
        adm0_a3_ar: "EGY",
        adm0_a3_jp: "EGY",
        adm0_a3_ko: "EGY",
        adm0_a3_vn: "EGY",
        adm0_a3_tr: "EGY",
        adm0_a3_id: "EGY",
        adm0_a3_pl: "EGY",
        adm0_a3_gr: "EGY",
        adm0_a3_it: "EGY",
        adm0_a3_nl: "EGY",
        adm0_a3_se: "EGY",
        adm0_a3_bd: "EGY",
        adm0_a3_ua: "EGY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 29.445837,
        label_y: 26.186173,
        ne_id: 1159320575,
        wikidataid: "Q79",
        name_ar: "مصر",
        name_bn: "মিশর",
        name_de: "Ägypten",
        name_en: "Egypt",
        name_es: "Egipto",
        name_fa: "مصر",
        name_fr: "Égypte",
        name_el: "Αίγυπτος",
        name_he: "מצרים",
        name_hi: "मिस्र",
        name_hu: "Egyiptom",
        name_id: "Mesir",
        name_it: "Egitto",
        name_ja: "エジプト",
        name_ko: "이집트",
        name_nl: "Egypte",
        name_pl: "Egipt",
        name_pt: "Egito",
        name_ru: "Египет",
        name_sv: "Egypten",
        name_tr: "Mısır",
        name_uk: "Єгипет",
        name_ur: "مصر",
        name_vi: "Ai Cập",
        name_zh: "埃及",
        name_zht: "埃及",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "EGY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.86623, 22],
            [32.9, 22],
            [29.02, 22],
            [25, 22],
            [25, 25.682499996361],
            [25, 29.23865452953346],
            [24.70007, 30.04419],
            [24.95762, 30.6616],
            [24.80287, 31.08929],
            [25.16482, 31.56915],
            [26.49533, 31.58568],
            [27.45762, 31.32126],
            [28.45048, 31.02577],
            [28.91353, 30.87005],
            [29.68342, 31.18686],
            [30.09503, 31.4734],
            [30.97693, 31.55586],
            [31.68796, 31.4296],
            [31.96041, 30.9336],
            [32.19247, 31.26034],
            [32.99392, 31.02407],
            [33.7734, 30.96746],
            [34.26543474464621, 31.21935730952032],
            [34.26544, 31.21936],
            [34.823243288783814, 29.76108076171822],
            [34.9226, 29.50133],
            [34.64174, 29.09942],
            [34.42655, 28.34399],
            [34.15451, 27.8233],
            [33.92136, 27.6487],
            [33.58811, 27.97136],
            [33.13676, 28.41765],
            [32.42323, 29.85108],
            [32.32046, 29.76043],
            [32.73482, 28.70523],
            [33.34876, 27.69989],
            [34.10455, 26.14227],
            [34.47387, 25.59856],
            [34.79507, 25.03375],
            [35.69241, 23.92671],
            [35.49372, 23.75237],
            [35.52598, 23.10244],
            [36.69069, 22.20485],
            [36.86623, 22],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Equatorial Guinea",
        sov_a3: "GNQ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Equatorial Guinea",
        adm0_a3: "GNQ",
        geou_dif: 0,
        geounit: "Equatorial Guinea",
        gu_a3: "GNQ",
        su_dif: 0,
        subunit: "Equatorial Guinea",
        su_a3: "GNQ",
        brk_diff: 0,
        name: "Eq. Guinea",
        name_long: "Equatorial Guinea",
        brk_a3: "GNQ",
        brk_name: "Eq. Guinea",
        brk_group: null,
        abbrev: "Eq. G.",
        postal: "GQ",
        formal_en: "Republic of Equatorial Guinea",
        formal_fr: null,
        name_ciawf: "Equatorial Guinea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Equatorial Guinea",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 1355986,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 11026,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "2. High income: nonOECD",
        fips_10: "EK",
        iso_a2: "GQ",
        iso_a2_eh: "GQ",
        iso_a3: "GNQ",
        iso_a3_eh: "GNQ",
        iso_n3: "226",
        iso_n3_eh: "226",
        un_a3: "226",
        wb_a2: "GQ",
        wb_a3: "GNQ",
        woe_id: 23424804,
        woe_id_eh: 23424804,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GNQ",
        adm0_diff: null,
        adm0_tlc: "GNQ",
        adm0_a3_us: "GNQ",
        adm0_a3_fr: "GNQ",
        adm0_a3_ru: "GNQ",
        adm0_a3_es: "GNQ",
        adm0_a3_cn: "GNQ",
        adm0_a3_tw: "GNQ",
        adm0_a3_in: "GNQ",
        adm0_a3_np: "GNQ",
        adm0_a3_pk: "GNQ",
        adm0_a3_de: "GNQ",
        adm0_a3_gb: "GNQ",
        adm0_a3_br: "GNQ",
        adm0_a3_il: "GNQ",
        adm0_a3_ps: "GNQ",
        adm0_a3_sa: "GNQ",
        adm0_a3_eg: "GNQ",
        adm0_a3_ma: "GNQ",
        adm0_a3_pt: "GNQ",
        adm0_a3_ar: "GNQ",
        adm0_a3_jp: "GNQ",
        adm0_a3_ko: "GNQ",
        adm0_a3_vn: "GNQ",
        adm0_a3_tr: "GNQ",
        adm0_a3_id: "GNQ",
        adm0_a3_pl: "GNQ",
        adm0_a3_gr: "GNQ",
        adm0_a3_it: "GNQ",
        adm0_a3_nl: "GNQ",
        adm0_a3_se: "GNQ",
        adm0_a3_bd: "GNQ",
        adm0_a3_ua: "GNQ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 17,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 8.9902,
        label_y: 2.333,
        ne_id: 1159320801,
        wikidataid: "Q983",
        name_ar: "غينيا الاستوائية",
        name_bn: "বিষুবীয় গিনি",
        name_de: "Äquatorialguinea",
        name_en: "Equatorial Guinea",
        name_es: "Guinea Ecuatorial",
        name_fa: "گینه استوایی",
        name_fr: "Guinée équatoriale",
        name_el: "Ισημερινή Γουινέα",
        name_he: "גינאה המשוונית",
        name_hi: "भूमध्यरेखीय गिनी",
        name_hu: "Egyenlítői-Guinea",
        name_id: "Guinea Khatulistiwa",
        name_it: "Guinea Equatoriale",
        name_ja: "赤道ギニア",
        name_ko: "적도 기니",
        name_nl: "Equatoriaal-Guinea",
        name_pl: "Gwinea Równikowa",
        name_pt: "Guiné Equatorial",
        name_ru: "Экваториальная Гвинея",
        name_sv: "Ekvatorialguinea",
        name_tr: "Ekvator Ginesi",
        name_uk: "Екваторіальна Гвінея",
        name_ur: "استوائی گنی",
        name_vi: "Guinea Xích Đạo",
        name_zh: "赤道几内亚",
        name_zht: "赤道幾內亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GNQ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.649158155972628, 2.283866075037736],
            [11.276449008843713, 2.261050930180872],
            [11.285078973036462, 1.057661851400013],
            [9.830284051155644, 1.067893784993799],
            [9.492888624721985, 1.010119533691494],
            [9.305613234096256, 1.160911363119183],
            [9.649158155972628, 2.283866075037736],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Gambia",
        sov_a3: "GMB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Gambia",
        adm0_a3: "GMB",
        geou_dif: 0,
        geounit: "Gambia",
        gu_a3: "GMB",
        su_dif: 0,
        subunit: "Gambia",
        su_a3: "GMB",
        brk_diff: 0,
        name: "Gambia",
        name_long: "The Gambia",
        brk_a3: "GMB",
        brk_name: "Gambia",
        brk_group: null,
        abbrev: "Gambia",
        postal: "GM",
        formal_en: "Republic of the Gambia",
        formal_fr: null,
        name_ciawf: "Gambia, The",
        note_adm0: null,
        note_brk: null,
        name_sort: "Gambia, The",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 2347706,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 1826,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "GA",
        iso_a2: "GM",
        iso_a2_eh: "GM",
        iso_a3: "GMB",
        iso_a3_eh: "GMB",
        iso_n3: "270",
        iso_n3_eh: "270",
        un_a3: "270",
        wb_a2: "GM",
        wb_a3: "GMB",
        woe_id: 23424821,
        woe_id_eh: 23424821,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GMB",
        adm0_diff: null,
        adm0_tlc: "GMB",
        adm0_a3_us: "GMB",
        adm0_a3_fr: "GMB",
        adm0_a3_ru: "GMB",
        adm0_a3_es: "GMB",
        adm0_a3_cn: "GMB",
        adm0_a3_tw: "GMB",
        adm0_a3_in: "GMB",
        adm0_a3_np: "GMB",
        adm0_a3_pk: "GMB",
        adm0_a3_de: "GMB",
        adm0_a3_gb: "GMB",
        adm0_a3_br: "GMB",
        adm0_a3_il: "GMB",
        adm0_a3_ps: "GMB",
        adm0_a3_sa: "GMB",
        adm0_a3_eg: "GMB",
        adm0_a3_ma: "GMB",
        adm0_a3_pt: "GMB",
        adm0_a3_ar: "GMB",
        adm0_a3_jp: "GMB",
        adm0_a3_ko: "GMB",
        adm0_a3_vn: "GMB",
        adm0_a3_tr: "GMB",
        adm0_a3_id: "GMB",
        adm0_a3_pl: "GMB",
        adm0_a3_gr: "GMB",
        adm0_a3_it: "GMB",
        adm0_a3_nl: "GMB",
        adm0_a3_se: "GMB",
        adm0_a3_bd: "GMB",
        adm0_a3_ua: "GMB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 10,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -14.998318,
        label_y: 13.641721,
        ne_id: 1159320797,
        wikidataid: "Q1005",
        name_ar: "غامبيا",
        name_bn: "গাম্বিয়া",
        name_de: "Gambia",
        name_en: "The Gambia",
        name_es: "Gambia",
        name_fa: "گامبیا",
        name_fr: "Gambie",
        name_el: "Γκάμπια",
        name_he: "גמביה",
        name_hi: "गाम्बिया",
        name_hu: "Gambia",
        name_id: "Gambia",
        name_it: "Gambia",
        name_ja: "ガンビア",
        name_ko: "감비아",
        name_nl: "Gambia",
        name_pl: "Gambia",
        name_pt: "Gâmbia",
        name_ru: "Гамбия",
        name_sv: "Gambia",
        name_tr: "Gambiya",
        name_uk: "Гамбія",
        name_ur: "گیمبیا",
        name_vi: "Gambia",
        name_zh: "冈比亚",
        name_zht: "甘比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GMB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.71372880702347, 13.594958604379855],
            [-15.624596320039942, 13.62358734786956],
            [-15.39877031092446, 13.86036876063092],
            [-15.08173539881382, 13.876491807505985],
            [-14.687030808968487, 13.630356960499784],
            [-14.376713833055788, 13.625680243377374],
            [-14.046992356817482, 13.79406789800045],
            [-13.844963344772408, 13.505041612192002],
            [-14.277701788784555, 13.280585028532244],
            [-14.712197231494628, 13.298206691943777],
            [-15.141163295949468, 13.509511623585238],
            [-15.511812506562933, 13.278569647672867],
            [-15.691000535534995, 13.270353094938457],
            [-15.931295945692211, 13.130284125211332],
            [-16.841524624081273, 13.15139394780256],
            [-16.71372880702347, 13.594958604379855],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Madagascar",
        sov_a3: "MDG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Madagascar",
        adm0_a3: "MDG",
        geou_dif: 0,
        geounit: "Madagascar",
        gu_a3: "MDG",
        su_dif: 0,
        subunit: "Madagascar",
        su_a3: "MDG",
        brk_diff: 0,
        name: "Madagascar",
        name_long: "Madagascar",
        brk_a3: "MDG",
        brk_name: "Madagascar",
        brk_group: null,
        abbrev: "Mad.",
        postal: "MG",
        formal_en: "Republic of Madagascar",
        formal_fr: null,
        name_ciawf: "Madagascar",
        note_adm0: null,
        note_brk: null,
        name_sort: "Madagascar",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 3,
        pop_est: 26969307,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 14114,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MA",
        iso_a2: "MG",
        iso_a2_eh: "MG",
        iso_a3: "MDG",
        iso_a3_eh: "MDG",
        iso_n3: "450",
        iso_n3_eh: "450",
        un_a3: "450",
        wb_a2: "MG",
        wb_a3: "MDG",
        woe_id: 23424883,
        woe_id_eh: 23424883,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MDG",
        adm0_diff: null,
        adm0_tlc: "MDG",
        adm0_a3_us: "MDG",
        adm0_a3_fr: "MDG",
        adm0_a3_ru: "MDG",
        adm0_a3_es: "MDG",
        adm0_a3_cn: "MDG",
        adm0_a3_tw: "MDG",
        adm0_a3_in: "MDG",
        adm0_a3_np: "MDG",
        adm0_a3_pk: "MDG",
        adm0_a3_de: "MDG",
        adm0_a3_gb: "MDG",
        adm0_a3_br: "MDG",
        adm0_a3_il: "MDG",
        adm0_a3_ps: "MDG",
        adm0_a3_sa: "MDG",
        adm0_a3_eg: "MDG",
        adm0_a3_ma: "MDG",
        adm0_a3_pt: "MDG",
        adm0_a3_ar: "MDG",
        adm0_a3_jp: "MDG",
        adm0_a3_ko: "MDG",
        adm0_a3_vn: "MDG",
        adm0_a3_tr: "MDG",
        adm0_a3_id: "MDG",
        adm0_a3_pl: "MDG",
        adm0_a3_gr: "MDG",
        adm0_a3_it: "MDG",
        adm0_a3_nl: "MDG",
        adm0_a3_se: "MDG",
        adm0_a3_bd: "MDG",
        adm0_a3_ua: "MDG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 46.704241,
        label_y: -18.628288,
        ne_id: 1159321051,
        wikidataid: "Q1019",
        name_ar: "مدغشقر",
        name_bn: "মাদাগাস্কার",
        name_de: "Madagaskar",
        name_en: "Madagascar",
        name_es: "Madagascar",
        name_fa: "ماداگاسکار",
        name_fr: "Madagascar",
        name_el: "Μαδαγασκάρη",
        name_he: "מדגסקר",
        name_hi: "मेडागास्कर",
        name_hu: "Madagaszkár",
        name_id: "Madagaskar",
        name_it: "Madagascar",
        name_ja: "マダガスカル",
        name_ko: "마다가스카르",
        name_nl: "Madagaskar",
        name_pl: "Madagaskar",
        name_pt: "Madagáscar",
        name_ru: "Мадагаскар",
        name_sv: "Madagaskar",
        name_tr: "Madagaskar",
        name_uk: "Мадагаскар",
        name_ur: "مڈغاسکر",
        name_vi: "Madagascar",
        name_zh: "马达加斯加",
        name_zht: "馬達加斯加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MDG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [49.54351891459575, -12.469832858940554],
            [49.808980747279094, -12.895284925999555],
            [50.056510857957164, -13.555761407121985],
            [50.21743126811407, -14.758788750876796],
            [50.47653689962553, -15.226512139550543],
            [50.377111443895956, -15.706069431219127],
            [50.20027469259318, -16.000263360256767],
            [49.86060550313868, -15.414252618066918],
            [49.67260664246086, -15.710203545802479],
            [49.863344354050156, -16.451036879138776],
            [49.77456424337271, -16.8750420060936],
            [49.49861209493412, -17.106035658438273],
            [49.435618523970305, -17.953064060134366],
            [49.041792433473944, -19.118781019774445],
            [48.54854088724801, -20.496888116134127],
            [47.93074913919867, -22.391501153251085],
            [47.54772342305131, -23.781958916928517],
            [47.095761346226595, -24.941629733990453],
            [46.282477654817086, -25.178462823184105],
            [45.40950768411045, -25.60143442149309],
            [44.833573846217554, -25.34610116953894],
            [44.03972049334976, -24.988345228782308],
            [43.76376834491117, -24.46067717864999],
            [43.697777540874455, -23.574116306250602],
            [43.345654331237625, -22.776903985283873],
            [43.254187046081, -22.057413018484123],
            [43.43329756040464, -21.33647511158019],
            [43.893682895692926, -21.16330738697013],
            [43.896370070172104, -20.830459486578174],
            [44.37432539243966, -20.07236622485639],
            [44.46439741392439, -19.435454196859048],
            [44.23242190936617, -18.961994724200906],
            [44.042976108584156, -18.33138722094317],
            [43.96308434426091, -17.409944756746782],
            [44.31246870298628, -16.850495700754955],
            [44.4465173683514, -16.216219170804507],
            [44.94493655780653, -16.1793738745804],
            [45.50273196796499, -15.97437346767854],
            [45.87299360533626, -15.793454278224687],
            [46.31224327981721, -15.780018405828798],
            [46.882182651564285, -15.210182386946315],
            [47.70512983581236, -14.594302666891764],
            [48.005214878131255, -14.091232598530375],
            [47.869047479042166, -13.663868503476586],
            [48.29382775248138, -13.784067884987486],
            [48.84506025573879, -13.089174899958664],
            [48.86350874206698, -12.48786793381042],
            [49.194651320193316, -12.04055673589197],
            [49.54351891459575, -12.469832858940554],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Indonesia",
        sov_a3: "IDN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Indonesia",
        adm0_a3: "IDN",
        geou_dif: 0,
        geounit: "Indonesia",
        gu_a3: "IDN",
        su_dif: 0,
        subunit: "Indonesia",
        su_a3: "IDN",
        brk_diff: 0,
        name: "Indonesia",
        name_long: "Indonesia",
        brk_a3: "IDN",
        brk_name: "Indonesia",
        brk_group: null,
        abbrev: "Indo.",
        postal: "INDO",
        formal_en: "Republic of Indonesia",
        formal_fr: null,
        name_ciawf: "Indonesia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Indonesia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 11,
        pop_est: 270625568,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1119190,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "4. Lower middle income",
        fips_10: "ID",
        iso_a2: "ID",
        iso_a2_eh: "ID",
        iso_a3: "IDN",
        iso_a3_eh: "IDN",
        iso_n3: "360",
        iso_n3_eh: "360",
        un_a3: "360",
        wb_a2: "ID",
        wb_a3: "IDN",
        woe_id: 23424846,
        woe_id_eh: 23424846,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IDN",
        adm0_diff: null,
        adm0_tlc: "IDN",
        adm0_a3_us: "IDN",
        adm0_a3_fr: "IDN",
        adm0_a3_ru: "IDN",
        adm0_a3_es: "IDN",
        adm0_a3_cn: "IDN",
        adm0_a3_tw: "IDN",
        adm0_a3_in: "IDN",
        adm0_a3_np: "IDN",
        adm0_a3_pk: "IDN",
        adm0_a3_de: "IDN",
        adm0_a3_gb: "IDN",
        adm0_a3_br: "IDN",
        adm0_a3_il: "IDN",
        adm0_a3_ps: "IDN",
        adm0_a3_sa: "IDN",
        adm0_a3_eg: "IDN",
        adm0_a3_ma: "IDN",
        adm0_a3_pt: "IDN",
        adm0_a3_ar: "IDN",
        adm0_a3_jp: "IDN",
        adm0_a3_ko: "IDN",
        adm0_a3_vn: "IDN",
        adm0_a3_tr: "IDN",
        adm0_a3_id: "IDN",
        adm0_a3_pl: "IDN",
        adm0_a3_gr: "IDN",
        adm0_a3_it: "IDN",
        adm0_a3_nl: "IDN",
        adm0_a3_se: "IDN",
        adm0_a3_bd: "IDN",
        adm0_a3_ua: "IDN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 101.892949,
        label_y: -0.954404,
        ne_id: 1159320845,
        wikidataid: "Q252",
        name_ar: "إندونيسيا",
        name_bn: "ইন্দোনেশিয়া",
        name_de: "Indonesien",
        name_en: "Indonesia",
        name_es: "Indonesia",
        name_fa: "اندونزی",
        name_fr: "Indonésie",
        name_el: "Ινδονησία",
        name_he: "אינדונזיה",
        name_hi: "इंडोनेशिया",
        name_hu: "Indonézia",
        name_id: "Indonesia",
        name_it: "Indonesia",
        name_ja: "インドネシア",
        name_ko: "인도네시아",
        name_nl: "Indonesië",
        name_pl: "Indonezja",
        name_pt: "Indonésia",
        name_ru: "Индонезия",
        name_sv: "Indonesien",
        name_tr: "Endonezya",
        name_uk: "Індонезія",
        name_ur: "انڈونیشیا",
        name_vi: "Indonesia",
        name_zh: "印度尼西亚",
        name_zht: "印度尼西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IDN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [141.00021040259185, -2.60015105551566],
              [141.01705691951895, -5.859021905138071],
              [141.03385176001382, -9.117892754760483],
              [140.14341515519254, -8.29716765710095],
              [139.1277665549281, -8.096042982620979],
              [138.881476678625, -8.380935153846075],
              [137.61447391169287, -8.41168263105974],
              [138.03909915583517, -7.597882175327321],
              [138.66862145401478, -7.320224704623087],
              [138.4079138531023, -6.232849216337485],
              [137.92783979711078, -5.393365573756],
              [135.98925011611345, -4.54654387778907],
              [135.16459760959975, -4.462931410340822],
              [133.66288048719787, -3.538853448097541],
              [133.36770470594672, -4.024818617370315],
              [132.98395551974727, -4.112978610860253],
              [132.75694095268904, -3.746282647317123],
              [132.75378869031925, -3.31178720460705],
              [131.98980431531618, -2.820551039240499],
              [133.0668445171434, -2.460417982598436],
              [133.78003095920354, -2.479848321140182],
              [133.69621178602614, -2.214541517753702],
              [132.23237348849426, -2.212526136894319],
              [131.83622195854474, -1.617161960459647],
              [130.94283979708285, -1.432522067880783],
              [130.5195581401801, -0.937720228686089],
              [131.8675378765136, -0.695461114101789],
              [132.3801164084167, -0.369537855636949],
              [133.98554813042836, -0.780210463060456],
              [134.14336795464772, -1.151867364103623],
              [134.42262739475302, -2.769184665542376],
              [135.45760298069467, -3.367752780779149],
              [136.29331424371884, -2.307042331556154],
              [137.44073774632756, -1.703513278819365],
              [138.3297274110447, -1.702686455902693],
              [139.18492068904288, -2.051295668143673],
              [139.92668419816044, -2.409051608900313],
              [141.00021040259185, -2.60015105551566],
            ],
          ],
          [
            [
              [124.96868248911623, -8.892790215697083],
              [125.07001997284061, -9.089987481322872],
              [125.08852013560109, -9.393173109579294],
              [124.43595014861933, -10.140000909061449],
              [123.57998172413669, -10.359987481327956],
              [123.459989048355, -10.239994805546223],
              [123.55000939340744, -9.900015557497987],
              [123.9800089865081, -9.290026950724716],
              [124.96868248911623, -8.892790215697083],
            ],
          ],
          [
            [
              [134.21013390516885, -6.89523772545472],
              [134.11277550673094, -6.142467136259],
              [134.29033572808584, -5.783057549669017],
              [134.49962527886788, -5.445042006047871],
              [134.72700158095216, -5.737582289252167],
              [134.7246244650667, -6.214400730009288],
              [134.21013390516885, -6.89523772545472],
            ],
          ],
          [
            [
              [117.88203494677018, 4.137551377779516],
              [117.3132324565335, 3.234428208830593],
              [118.0483297058854, 2.287690131027333],
              [117.87562706916597, 1.827640692548925],
              [118.99674726773816, 0.902219143066063],
              [117.8118583517178, 0.784241848143708],
              [117.47833865770603, 0.102474676917026],
              [117.52164350796664, -0.803723239753268],
              [116.56004845587947, -1.48766082113621],
              [116.5337968282752, -2.483517347832901],
              [116.14808393764866, -4.012726332214022],
              [116.00085778204911, -3.657037448749058],
              [114.86480309454456, -4.106984144714396],
              [114.46865156459506, -3.495703627133828],
              [113.75567182826407, -3.43916961020652],
              [113.25699425664752, -3.118775729996905],
              [112.06812625534067, -3.478392022316051],
              [111.70329064336005, -2.994442233902654],
              [111.04824018762824, -3.049425957861211],
              [110.223846063276, -2.934032484553455],
              [110.07093550012434, -1.592874037282463],
              [109.571947869914, -1.314906507984475],
              [109.0918738139225, -0.459506524257094],
              [108.9526575053282, 0.415375474444318],
              [109.06913618371408, 1.341933905437614],
              [109.66326012577375, 2.006466986494956],
              [109.83022667850881, 1.338135687664163],
              [110.51406090702716, 0.773131415200965],
              [111.15913781132662, 0.976478176269481],
              [111.79754845586041, 0.904441229654608],
              [112.38025190638359, 1.410120957846743],
              [112.85980919805218, 1.497790025229904],
              [113.8058496440195, 1.217548732911069],
              [114.62135542201753, 1.430688177898901],
              [115.13403730678522, 2.821481838386234],
              [115.51907840379198, 3.169238389494396],
              [115.8655172058767, 4.3065591495901],
              [117.01521447150628, 4.306094061699469],
              [117.88203494677018, 4.137551377779516],
            ],
          ],
          [
            [
              [129.37099775606094, -2.802154229344595],
              [130.47134402885177, -3.093764336767634],
              [130.83483605359282, -3.858472181822776],
              [129.99054650280817, -3.446300957862796],
              [129.15524865124235, -3.362636813982248],
              [128.59068362845363, -3.428679294451264],
              [127.89889122936235, -3.393435967628207],
              [128.13587934785284, -2.843650404474971],
              [129.37099775606094, -2.802154229344595],
            ],
          ],
          [
            [
              [126.87492272349886, -3.790982761249587],
              [126.18380211802736, -3.607376397316564],
              [125.98903364471926, -3.177273451351305],
              [127.00065148326497, -3.129317722184446],
              [127.24921512258891, -3.45906503663889],
              [126.87492272349886, -3.790982761249587],
            ],
          ],
          [
            [
              [127.93237755748748, 2.174596258956569],
              [128.00415612194087, 1.628531398928345],
              [128.5945593608755, 1.540810655112878],
              [128.6882487326207, 1.132385972494063],
              [128.63595218314134, 0.258485826006194],
              [128.1201697124361, 0.356412665199286],
              [127.96803429576886, -0.252077325037519],
              [128.3799988139997, -0.7800037573313],
              [128.1000159038423, -0.899996433113031],
              [127.69647464407507, -0.266598402511534],
              [127.39949018769369, 1.011721503092545],
              [127.60051150930906, 1.810690822757195],
              [127.93237755748748, 2.174596258956569],
            ],
          ],
          [
            [
              [122.9275667664518, 0.875192368977409],
              [124.07752241424288, 0.917101955566125],
              [125.0659892111218, 1.64325918213153],
              [125.2405005229715, 1.419836127117605],
              [124.4370353536974, 0.427881171058957],
              [123.6855049988767, 0.235593166500891],
              [122.72308312387287, 0.431136786293337],
              [121.05672488818911, 0.381217352699394],
              [120.18308312386272, 0.237246812334234],
              [120.04086958219548, -0.519657891444837],
              [120.93590538949073, -1.408905938323393],
              [121.4758207540762, -0.95596200928513],
              [123.34056481332846, -0.615672702643138],
              [123.25839928598441, -1.076213067228309],
              [122.82271528533161, -0.930950616055853],
              [122.38852990121529, -1.516858005381117],
              [121.50827355355551, -1.904482924002458],
              [122.4545723816843, -3.186058444840924],
              [122.2718961935325, -3.529500013852712],
              [123.17096276254655, -4.683693129091701],
              [123.1623327983538, -5.340603936385996],
              [122.62851525277875, -5.634591159694466],
              [122.23639448454801, -5.282933037948268],
              [122.71956912647701, -4.464171644715826],
              [121.73823367725436, -4.851331475446543],
              [121.48946333220127, -4.574552504091265],
              [121.61917117725386, -4.188477878438682],
              [120.89818159391766, -3.602105401222794],
              [120.97238895068878, -2.627642917494939],
              [120.30545291552986, -2.931603692235733],
              [120.39004723519167, -4.097579034037274],
              [120.43071658740537, -5.528241062037793],
              [119.79654341031949, -5.673400160345665],
              [119.36690555224489, -5.37987802492782],
              [119.65360639860017, -4.459417412944973],
              [119.49883548388601, -3.494411716326532],
              [119.07834435432704, -3.487021986508793],
              [118.76776899625287, -2.801999200047718],
              [119.18097374885869, -2.147103773612805],
              [119.3233939962551, -1.353147067880464],
              [119.82599897672587, 0.154254462073482],
              [120.0357019389663, 0.566477362465761],
              [120.88577925016762, 1.30922272379685],
              [121.66681684782696, 1.013943589681091],
              [122.9275667664518, 0.875192368977409],
            ],
          ],
          [
            [
              [120.29501427620689, -10.258649997603591],
              [118.96780846565471, -9.557969252158074],
              [119.90030968636157, -9.361340427287502],
              [120.42575564990534, -9.665921319215798],
              [120.77550174365675, -9.96967538822743],
              [120.71560875863045, -10.239581394087885],
              [120.29501427620689, -10.258649997603591],
            ],
          ],
          [
            [
              [121.34166873584651, -8.536739597206072],
              [122.00736453663043, -8.460620212440148],
              [122.90353722543607, -8.094234307490765],
              [122.75698286345632, -8.649807631060696],
              [121.2544905945701, -8.933666273639957],
              [119.92439090380958, -8.81041798262384],
              [119.92092858284605, -8.444858900591122],
              [120.71509199430757, -8.236964613480914],
              [121.34166873584651, -8.536739597206072],
            ],
          ],
          [
            [
              [118.26061648974044, -8.362383314653293],
              [118.87845991422208, -8.280682875199844],
              [119.12650678922307, -8.705824883665088],
              [117.97040164598928, -8.906639499551304],
              [117.27773074754901, -9.040894870645594],
              [116.74014082241665, -9.032936700072646],
              [117.0837374207253, -8.45715789147659],
              [117.6320243673421, -8.449303073768228],
              [117.90001834520776, -8.09568124759494],
              [118.26061648974044, -8.362383314653293],
            ],
          ],
          [
            [
              [108.48684614464926, -6.42198495852574],
              [108.62347863162896, -6.777673841990705],
              [110.53922732955328, -6.877357679881726],
              [110.75957563684585, -6.465186455921747],
              [112.6148112325564, -6.946035658397626],
              [112.97876834518806, -7.594213148634594],
              [114.47893517462114, -7.776527601760328],
              [115.70552697150106, -8.370806573116873],
              [114.56451134649649, -8.751816908404855],
              [113.46473351446085, -8.348947442257405],
              [112.55967247930097, -8.376180922075221],
              [111.52206139531245, -8.302128594600973],
              [110.58614953007432, -8.122604668819001],
              [109.42766727095511, -7.740664157749762],
              [108.69365522668133, -7.641600437046243],
              [108.27776329959633, -7.766657403192576],
              [106.45410200401612, -7.354899590690934],
              [106.28062422081231, -6.924899997590252],
              [105.36548628135552, -6.851416110871206],
              [106.05164594932702, -5.895918877794472],
              [107.2650085795402, -5.954985039904081],
              [108.07209109907467, -6.345762220895224],
              [108.48684614464926, -6.42198495852574],
            ],
          ],
          [
            [
              [104.36999148968489, -1.084843031421059],
              [104.53949018760221, -1.782371514496766],
              [104.88789269411402, -2.340425306816705],
              [105.62211144411697, -2.428843682468099],
              [106.10859337771265, -3.061776625178965],
              [105.85744591677414, -4.305524997579774],
              [105.8176550639094, -5.85235564537242],
              [104.71038414919144, -5.873284600450632],
              [103.86821333213078, -5.037314955264996],
              [102.5842606954069, -4.220258884298183],
              [102.156173130301, -3.614146009946801],
              [101.39911339722507, -2.799777113459164],
              [100.90250288290015, -2.050262139497832],
              [100.14198082886065, -0.650347588710986],
              [99.26373986206028, 0.183141587724634],
              [98.97001102091326, 1.042882391764536],
              [98.60135135294306, 1.823506577965574],
              [97.69959760944985, 2.45318390544206],
              [97.17694217324984, 3.308790594898596],
              [96.42401655475726, 3.868859768077925],
              [95.3808760925135, 4.970782172053688],
              [95.29302615761729, 5.479820868344788],
              [95.93686282754174, 5.439513251157123],
              [97.4848820332771, 5.246320909033955],
              [98.36916914265566, 4.268370266126396],
              [99.1425586283358, 3.590349636240873],
              [99.69399783732241, 3.174328518075143],
              [100.64143354696162, 2.099381211755741],
              [101.65801232300734, 2.083697414555161],
              [102.49827111207323, 1.398700466310231],
              [103.07684044801303, 0.561361395668868],
              [103.83839603069836, 0.104541734208695],
              [103.4376452982749, -0.711945896002902],
              [104.01078860882404, -1.059211521004286],
              [104.36999148968489, -1.084843031421059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Malaysia",
        sov_a3: "MYS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Malaysia",
        adm0_a3: "MYS",
        geou_dif: 0,
        geounit: "Malaysia",
        gu_a3: "MYS",
        su_dif: 0,
        subunit: "Malaysia",
        su_a3: "MYS",
        brk_diff: 0,
        name: "Malaysia",
        name_long: "Malaysia",
        brk_a3: "MYS",
        brk_name: "Malaysia",
        brk_group: null,
        abbrev: "Malay.",
        postal: "MY",
        formal_en: "Malaysia",
        formal_fr: null,
        name_ciawf: "Malaysia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Malaysia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 31949777,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 364681,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "MY",
        iso_a2: "MY",
        iso_a2_eh: "MY",
        iso_a3: "MYS",
        iso_a3_eh: "MYS",
        iso_n3: "458",
        iso_n3_eh: "458",
        un_a3: "458",
        wb_a2: "MY",
        wb_a3: "MYS",
        woe_id: 23424901,
        woe_id_eh: 23424901,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MYS",
        adm0_diff: null,
        adm0_tlc: "MYS",
        adm0_a3_us: "MYS",
        adm0_a3_fr: "MYS",
        adm0_a3_ru: "MYS",
        adm0_a3_es: "MYS",
        adm0_a3_cn: "MYS",
        adm0_a3_tw: "MYS",
        adm0_a3_in: "MYS",
        adm0_a3_np: "MYS",
        adm0_a3_pk: "MYS",
        adm0_a3_de: "MYS",
        adm0_a3_gb: "MYS",
        adm0_a3_br: "MYS",
        adm0_a3_il: "MYS",
        adm0_a3_ps: "MYS",
        adm0_a3_sa: "MYS",
        adm0_a3_eg: "MYS",
        adm0_a3_ma: "MYS",
        adm0_a3_pt: "MYS",
        adm0_a3_ar: "MYS",
        adm0_a3_jp: "MYS",
        adm0_a3_ko: "MYS",
        adm0_a3_vn: "MYS",
        adm0_a3_tr: "MYS",
        adm0_a3_id: "MYS",
        adm0_a3_pl: "MYS",
        adm0_a3_gr: "MYS",
        adm0_a3_it: "MYS",
        adm0_a3_nl: "MYS",
        adm0_a3_se: "MYS",
        adm0_a3_bd: "MYS",
        adm0_a3_ua: "MYS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 113.83708,
        label_y: 2.528667,
        ne_id: 1159321083,
        wikidataid: "Q833",
        name_ar: "ماليزيا",
        name_bn: "মালয়েশিয়া",
        name_de: "Malaysia",
        name_en: "Malaysia",
        name_es: "Malasia",
        name_fa: "مالزی",
        name_fr: "Malaisie",
        name_el: "Μαλαισία",
        name_he: "מלזיה",
        name_hi: "मलेशिया",
        name_hu: "Malajzia",
        name_id: "Malaysia",
        name_it: "Malaysia",
        name_ja: "マレーシア",
        name_ko: "말레이시아",
        name_nl: "Maleisië",
        name_pl: "Malezja",
        name_pt: "Malásia",
        name_ru: "Малайзия",
        name_sv: "Malaysia",
        name_tr: "Malezya",
        name_uk: "Малайзія",
        name_ur: "ملائیشیا",
        name_vi: "Malaysia",
        name_zh: "马来西亚",
        name_zht: "馬來西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MYS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [100.0857568705271, 6.464489447450291],
              [100.25959638875696, 6.642824815289543],
              [101.07551557821336, 6.204867051615921],
              [101.15421878459387, 5.691384182147715],
              [101.81428185425798, 5.810808417174242],
              [102.14118696493638, 6.221636053894628],
              [102.37114708863521, 6.128205064310919],
              [102.96170535686673, 5.524495144061106],
              [103.38121463421217, 4.855001125503748],
              [103.43857547405612, 4.181605536308339],
              [103.33212202353486, 3.726697902842986],
              [103.42942874554049, 3.382868760589005],
              [103.50244754436889, 2.791018581550176],
              [103.8546741068703, 2.515454006353764],
              [104.24793175661145, 1.631141058759084],
              [104.22881147666348, 1.293048000489492],
              [103.51970747275439, 1.226333726400682],
              [102.57361535035477, 1.967115383304687],
              [101.39063846232919, 2.760813706875581],
              [101.27353966675582, 3.270291652841152],
              [100.69543541870664, 3.93913971599487],
              [100.55740766805502, 4.767280381688295],
              [100.19670617065775, 5.312492580583708],
              [100.30626020711652, 6.040561835143905],
              [100.0857568705271, 6.464489447450291],
            ],
          ],
          [
            [
              [117.88203494677018, 4.137551377779516],
              [117.01521447150628, 4.306094061699469],
              [115.8655172058767, 4.3065591495901],
              [115.51907840379198, 3.169238389494396],
              [115.13403730678522, 2.821481838386234],
              [114.62135542201753, 1.430688177898901],
              [113.8058496440195, 1.217548732911069],
              [112.85980919805218, 1.497790025229904],
              [112.38025190638359, 1.410120957846743],
              [111.79754845586041, 0.904441229654608],
              [111.15913781132662, 0.976478176269481],
              [110.51406090702716, 0.773131415200965],
              [109.83022667850881, 1.338135687664163],
              [109.66326012577375, 2.006466986494956],
              [110.3961352885371, 1.663774725751395],
              [111.16885298059748, 1.850636704918813],
              [111.37008100794205, 2.697303371588859],
              [111.7969283386729, 2.885896511238059],
              [112.99561486211522, 3.102394924324855],
              [113.71293541875868, 3.893509426281156],
              [114.20401655482837, 4.525873928236805],
              [114.65959598191353, 4.007636826997754],
              [114.8695573263154, 4.348313706881925],
              [115.34746097215066, 4.316636053887009],
              [115.40570031134361, 4.955227565933839],
              [115.45071048386981, 5.447729803891534],
              [116.22074100145102, 6.143191229675566],
              [116.72510298061971, 6.924771429873999],
              [117.12962609260047, 6.92805288332454],
              [117.64339318244627, 6.422166449403249],
              [117.68907514859231, 5.987490139180154],
              [118.34769127815224, 5.708695786965492],
              [119.18190392463997, 5.407835598162207],
              [119.11069380094176, 5.016128241389808],
              [118.43972700406408, 4.966518866389606],
              [118.6183207540648, 4.478202419447555],
              [117.88203494677018, 4.137551377779516],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Cyprus",
        sov_a3: "CYP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cyprus",
        adm0_a3: "CYP",
        geou_dif: 0,
        geounit: "Cyprus",
        gu_a3: "CYP",
        su_dif: 0,
        subunit: "Cyprus",
        su_a3: "CYP",
        brk_diff: 0,
        name: "Cyprus",
        name_long: "Cyprus",
        brk_a3: "CYP",
        brk_name: "Cyprus",
        brk_group: null,
        abbrev: "Cyp.",
        postal: "CY",
        formal_en: "Republic of Cyprus",
        formal_fr: null,
        name_ciawf: "Cyprus",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cyprus",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 7,
        pop_est: 1198575,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 24948,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "CY",
        iso_a2: "CY",
        iso_a2_eh: "CY",
        iso_a3: "CYP",
        iso_a3_eh: "CYP",
        iso_n3: "196",
        iso_n3_eh: "196",
        un_a3: "196",
        wb_a2: "CY",
        wb_a3: "CYP",
        woe_id: -90,
        woe_id_eh: 23424994,
        woe_note: "WOE lists as subunit of united Cyprus",
        adm0_iso: "CYP",
        adm0_diff: null,
        adm0_tlc: "CYP",
        adm0_a3_us: "CYP",
        adm0_a3_fr: "CYP",
        adm0_a3_ru: "CYP",
        adm0_a3_es: "CYP",
        adm0_a3_cn: "CYP",
        adm0_a3_tw: "CYP",
        adm0_a3_in: "CYP",
        adm0_a3_np: "CYP",
        adm0_a3_pk: "CYP",
        adm0_a3_de: "CYP",
        adm0_a3_gb: "CYP",
        adm0_a3_br: "CYP",
        adm0_a3_il: "CYP",
        adm0_a3_ps: "CYP",
        adm0_a3_sa: "CYP",
        adm0_a3_eg: "CYP",
        adm0_a3_ma: "CYP",
        adm0_a3_pt: "CYP",
        adm0_a3_ar: "CYP",
        adm0_a3_jp: "CYP",
        adm0_a3_ko: "CYP",
        adm0_a3_vn: "CYP",
        adm0_a3_tr: "CYP",
        adm0_a3_id: "CYP",
        adm0_a3_pl: "CYP",
        adm0_a3_gr: "CYP",
        adm0_a3_it: "CYP",
        adm0_a3_nl: "CYP",
        adm0_a3_se: "CYP",
        adm0_a3_bd: "CYP",
        adm0_a3_ua: "CYP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: 33.084182,
        label_y: 34.913329,
        ne_id: 1159320533,
        wikidataid: "Q229",
        name_ar: "قبرص",
        name_bn: "সাইপ্রাস",
        name_de: "Republik Zypern",
        name_en: "Cyprus",
        name_es: "Chipre",
        name_fa: "قبرس",
        name_fr: "Chypre",
        name_el: "Κύπρος",
        name_he: "קפריסין",
        name_hi: "साइप्रस",
        name_hu: "Ciprus",
        name_id: "Siprus",
        name_it: "Cipro",
        name_ja: "キプロス",
        name_ko: "키프로스",
        name_nl: "Cyprus",
        name_pl: "Cypr",
        name_pt: "Chipre",
        name_ru: "Кипр",
        name_sv: "Cypern",
        name_tr: "Kıbrıs Cumhuriyeti",
        name_uk: "Кіпр",
        name_ur: "قبرص",
        name_vi: "Cộng hòa Síp",
        name_zh: "塞浦路斯",
        name_zht: "賽普勒斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CYP.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.73178022637745, 35.14002594658844],
            [32.919572381326134, 35.08783274997364],
            [33.19097700372305, 35.17312470147138],
            [33.3838334490363, 35.16271190036457],
            [33.45592207208347, 35.10142365166641],
            [33.47581749851585, 35.000344550103506],
            [33.5256852556775, 35.03868846286407],
            [33.675391880027064, 35.01786286065045],
            [33.86643965021011, 35.09359467217419],
            [33.97361657078346, 35.058506374648005],
            [34.00488081232004, 34.97809784600186],
            [32.97982710137845, 34.57186941175544],
            [32.49029625827754, 34.701654771456475],
            [32.25666710788596, 35.10323232679663],
            [32.73178022637745, 35.14002594658844],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "India",
        sov_a3: "IND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "India",
        adm0_a3: "IND",
        geou_dif: 0,
        geounit: "India",
        gu_a3: "IND",
        su_dif: 0,
        subunit: "India",
        su_a3: "IND",
        brk_diff: 0,
        name: "India",
        name_long: "India",
        brk_a3: "IND",
        brk_name: "India",
        brk_group: null,
        abbrev: "India",
        postal: "IND",
        formal_en: "Republic of India",
        formal_fr: null,
        name_ciawf: "India",
        note_adm0: null,
        note_brk: null,
        name_sort: "India",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 1366417754,
        pop_rank: 18,
        pop_year: 2019,
        gdp_md: 2868929,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "4. Lower middle income",
        fips_10: "IN",
        iso_a2: "IN",
        iso_a2_eh: "IN",
        iso_a3: "IND",
        iso_a3_eh: "IND",
        iso_n3: "356",
        iso_n3_eh: "356",
        un_a3: "356",
        wb_a2: "IN",
        wb_a3: "IND",
        woe_id: 23424848,
        woe_id_eh: 23424848,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IND",
        adm0_diff: null,
        adm0_tlc: "IND",
        adm0_a3_us: "IND",
        adm0_a3_fr: "IND",
        adm0_a3_ru: "IND",
        adm0_a3_es: "IND",
        adm0_a3_cn: "IND",
        adm0_a3_tw: "IND",
        adm0_a3_in: "IND",
        adm0_a3_np: "IND",
        adm0_a3_pk: "IND",
        adm0_a3_de: "IND",
        adm0_a3_gb: "IND",
        adm0_a3_br: "IND",
        adm0_a3_il: "IND",
        adm0_a3_ps: "IND",
        adm0_a3_sa: "IND",
        adm0_a3_eg: "IND",
        adm0_a3_ma: "IND",
        adm0_a3_pt: "IND",
        adm0_a3_ar: "IND",
        adm0_a3_jp: "IND",
        adm0_a3_ko: "IND",
        adm0_a3_vn: "IND",
        adm0_a3_tr: "IND",
        adm0_a3_id: "IND",
        adm0_a3_pl: "IND",
        adm0_a3_gr: "IND",
        adm0_a3_it: "IND",
        adm0_a3_nl: "IND",
        adm0_a3_se: "IND",
        adm0_a3_bd: "IND",
        adm0_a3_ua: "IND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 79.358105,
        label_y: 22.686852,
        ne_id: 1159320847,
        wikidataid: "Q668",
        name_ar: "الهند",
        name_bn: "ভারত",
        name_de: "Indien",
        name_en: "India",
        name_es: "India",
        name_fa: "هند",
        name_fr: "Inde",
        name_el: "Ινδία",
        name_he: "הודו",
        name_hi: "भारत",
        name_hu: "India",
        name_id: "India",
        name_it: "India",
        name_ja: "インド",
        name_ko: "인도",
        name_nl: "India",
        name_pl: "Indie",
        name_pt: "Índia",
        name_ru: "Индия",
        name_sv: "Indien",
        name_tr: "Hindistan",
        name_uk: "Індія",
        name_ur: "بھارت",
        name_vi: "Ấn Độ",
        name_zh: "印度",
        name_zht: "印度",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IND.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [97.32711388549004, 28.26158274994634],
            [97.40256147663614, 27.882536119085444],
            [97.0519885599681, 27.69905894623315],
            [97.1339990580153, 27.083773505149964],
            [96.41936567585097, 27.264589341739224],
            [95.12476769407496, 26.5735720891323],
            [95.1551534362626, 26.001307277932085],
            [94.60324913938538, 25.162495428970402],
            [94.55265791217164, 24.675238348890336],
            [94.10674197792507, 23.85074087167348],
            [93.3251876159428, 24.078556423432204],
            [93.28632693885928, 23.043658352139005],
            [93.06029422401463, 22.70311066333557],
            [93.16612755734837, 22.278459580977103],
            [92.67272098182556, 22.041238918541254],
            [92.14603478390681, 23.627498684172593],
            [91.86992760617132, 23.624346421802784],
            [91.70647505083211, 22.985263983649187],
            [91.15896325069973, 23.50352692310439],
            [91.46772993364368, 24.072639471934792],
            [91.91509280799443, 24.130413723237112],
            [92.37620161333481, 24.976692816664965],
            [91.79959598182208, 25.147431748957317],
            [90.87221072791212, 25.132600612889547],
            [89.92069258012185, 25.26974986419218],
            [89.83248091019962, 25.96508209889548],
            [89.35509402868729, 26.014407253518073],
            [88.56304935094977, 26.446525580342723],
            [88.2097892598025, 25.768065700782714],
            [88.93155398962308, 25.238692328384776],
            [88.30637251175602, 24.866079413344206],
            [88.08442223506242, 24.501657212821925],
            [88.69994022009092, 24.23371491138856],
            [88.52976972855379, 23.631141872649167],
            [88.87631188350309, 22.87914642993783],
            [89.03196129756623, 22.055708319582976],
            [88.88876590368542, 21.690588487224748],
            [88.20849734899522, 21.703171698487807],
            [86.97570438024027, 21.49556163175521],
            [87.03316857294887, 20.743307806882413],
            [86.49935102737379, 20.151638495356607],
            [85.0602657409097, 19.4785788029711],
            [83.94100589390001, 18.302009792549725],
            [83.18921715691785, 17.67122142177898],
            [82.19279218946592, 17.016636053937816],
            [82.19124189649719, 16.556664130107848],
            [81.69271935417748, 16.310219224507904],
            [80.79199913933014, 15.951972357644493],
            [80.32489586784388, 15.89918488205835],
            [80.02506920768644, 15.136414903214147],
            [80.2332735533904, 13.835770778859981],
            [80.28629357292186, 13.006260687710835],
            [79.8625468281285, 12.056215318240888],
            [79.85799930208682, 10.35727509199711],
            [79.340511509116, 10.30885427493962],
            [78.88534549348918, 9.546135972527722],
            [79.1897196796883, 9.216543687370148],
            [78.2779407083305, 8.933046779816934],
            [77.94116539908435, 8.252959092639742],
            [77.53989790233794, 7.965534776232332],
            [76.59297895702167, 8.89927623131419],
            [76.13006147655108, 10.299630031775521],
            [75.7464673196485, 11.308250637248307],
            [75.39610110870959, 11.781245022015824],
            [74.86481570831683, 12.741935736537897],
            [74.61671715688354, 13.99258291264968],
            [74.44385949086723, 14.617221787977698],
            [73.5341992532334, 15.99065216721496],
            [73.11990929554943, 17.928570054592498],
            [72.82090945830865, 19.208233547436166],
            [72.8244751321368, 20.419503282141534],
            [72.6305334817454, 21.356009426351008],
            [71.17527347197395, 20.757441311114235],
            [70.4704586119451, 20.877330634031384],
            [69.16413008003883, 22.0892980005727],
            [69.6449276060824, 22.450774644454338],
            [69.34959679553435, 22.84317963306269],
            [68.1766451353734, 23.69196503345671],
            [68.84259931831878, 24.35913361256094],
            [71.04324018746823, 24.3565239527302],
            [70.84469933460284, 25.21510203704352],
            [70.2828731627256, 25.72222870533983],
            [70.16892662952202, 26.491871649678842],
            [69.51439293811313, 26.940965684511372],
            [70.61649620960193, 27.989196275335868],
            [71.77766564320032, 27.913180243434525],
            [72.8237516620847, 28.961591701772054],
            [73.45063846221743, 29.97641347911987],
            [74.42138024282028, 30.979814764931177],
            [74.40592898956501, 31.69263947196528],
            [75.25864179881322, 32.2711054550405],
            [74.45155927927871, 32.7648996038055],
            [74.10429365427734, 33.44147329358685],
            [73.74994835805197, 34.31769887952785],
            [74.24020267120497, 34.748887030571254],
            [75.75706098826834, 34.50492259372132],
            [76.87172163280403, 34.65354401299274],
            [77.83745079947457, 35.494009507787766],
            [78.91226891471322, 34.32193634697579],
            [78.81108646028574, 33.50619802503242],
            [79.20889163606859, 32.994394639613716],
            [79.17612877799553, 32.48377981213771],
            [78.45844648632601, 32.61816437431273],
            [78.73889448437401, 31.515906073527063],
            [79.7213668151071, 30.88271474865473],
            [81.11125613802932, 30.183480943313402],
            [80.4767212259174, 29.72986522065534],
            [80.08842451367627, 28.79447011974014],
            [81.05720258985203, 28.416095282499043],
            [81.99998742058497, 27.925479234319994],
            [83.30424889519955, 27.36450572357556],
            [84.6750179381738, 27.234901231387536],
            [85.25177859898338, 26.726198431906344],
            [86.02439293817918, 26.63098460540857],
            [87.2274719583663, 26.397898057556077],
            [88.06023766474982, 26.41461538340249],
            [88.17480431514092, 26.81040517832595],
            [88.04313276566123, 27.445818589786825],
            [88.12044070836987, 27.876541652939594],
            [88.73032596227856, 28.086864732367516],
            [88.81424848832056, 27.299315904239364],
            [88.83564253128938, 27.098966376243762],
            [89.74452762243885, 26.719402981059957],
            [90.37327477413407, 26.87572418874288],
            [91.21751264848643, 26.808648179628022],
            [92.03348351437509, 26.83831045176356],
            [92.10371178585974, 27.452614040633208],
            [91.69665652869668, 27.771741848251665],
            [92.50311893104364, 27.89687632904645],
            [93.41334760943269, 28.640629380807226],
            [94.56599043170294, 29.277438055939985],
            [95.40480228066464, 29.03171662039213],
            [96.11767866413103, 29.452802028922466],
            [96.58659061074749, 28.83097951915434],
            [96.24883344928779, 28.411030992134442],
            [97.32711388549004, 28.26158274994634],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Israel",
        sov_a3: "IS1",
        adm0_dif: 1,
        level: 2,
        type: "Disputed",
        tlc: "1",
        admin: "Israel",
        adm0_a3: "ISR",
        geou_dif: 0,
        geounit: "Israel",
        gu_a3: "ISR",
        su_dif: 0,
        subunit: "Israel",
        su_a3: "ISR",
        brk_diff: 1,
        name: "Israel",
        name_long: "Israel",
        brk_a3: "ISR",
        brk_name: "Israel",
        brk_group: null,
        abbrev: "Isr.",
        postal: "IS",
        formal_en: "State of Israel",
        formal_fr: null,
        name_ciawf: "Israel",
        note_adm0: null,
        note_brk: null,
        name_sort: "Israel",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 9,
        pop_est: 9053300,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 394652,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "-99",
        iso_a2: "IL",
        iso_a2_eh: "IL",
        iso_a3: "ISR",
        iso_a3_eh: "ISR",
        iso_n3: "376",
        iso_n3_eh: "376",
        un_a3: "376",
        wb_a2: "IL",
        wb_a3: "ISR",
        woe_id: 23424852,
        woe_id_eh: 23424852,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ISR",
        adm0_diff: null,
        adm0_tlc: "ISR",
        adm0_a3_us: "ISR",
        adm0_a3_fr: "ISR",
        adm0_a3_ru: "ISR",
        adm0_a3_es: "ISR",
        adm0_a3_cn: "ISR",
        adm0_a3_tw: "ISR",
        adm0_a3_in: "ISR",
        adm0_a3_np: "ISR",
        adm0_a3_pk: "PSX",
        adm0_a3_de: "ISR",
        adm0_a3_gb: "ISR",
        adm0_a3_br: "ISR",
        adm0_a3_il: "ISR",
        adm0_a3_ps: "ISR",
        adm0_a3_sa: "PSX",
        adm0_a3_eg: "ISR",
        adm0_a3_ma: "ISR",
        adm0_a3_pt: "ISR",
        adm0_a3_ar: "ISR",
        adm0_a3_jp: "ISR",
        adm0_a3_ko: "ISR",
        adm0_a3_vn: "ISR",
        adm0_a3_tr: "ISR",
        adm0_a3_id: "ISR",
        adm0_a3_pl: "ISR",
        adm0_a3_gr: "ISR",
        adm0_a3_it: "ISR",
        adm0_a3_nl: "ISR",
        adm0_a3_se: "ISR",
        adm0_a3_bd: "PSX",
        adm0_a3_ua: "ISR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 34.847915,
        label_y: 30.911148,
        ne_id: 1159320895,
        wikidataid: "Q801",
        name_ar: "إسرائيل",
        name_bn: "ইসরায়েল",
        name_de: "Israel",
        name_en: "Israel",
        name_es: "Israel",
        name_fa: "اسرائیل",
        name_fr: "Israël",
        name_el: "Ισραήλ",
        name_he: "ישראל",
        name_hi: "इज़राइल",
        name_hu: "Izrael",
        name_id: "Israel",
        name_it: "Israele",
        name_ja: "イスラエル",
        name_ko: "이스라엘",
        name_nl: "Israël",
        name_pl: "Izrael",
        name_pt: "Israel",
        name_ru: "Израиль",
        name_sv: "Israel",
        name_tr: "İsrail",
        name_uk: "Ізраїль",
        name_ur: "اسرائیل",
        name_vi: "Israel",
        name_zh: "以色列",
        name_zht: "以色列",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: "Unrecognized",
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: "Unrecognized",
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: "Unrecognized",
        fclass_ua: null,
        filename: "ISR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.71991824722275, 32.709192409794866],
            [35.54566531753454, 32.393992011030576],
            [35.183930291491436, 32.53251068778894],
            [34.97464074070933, 31.866582343059722],
            [35.22589155451243, 31.754341132121766],
            [34.970506626125996, 31.61677846936081],
            [34.92740848159457, 31.353435370401414],
            [35.397560662586045, 31.489086005167582],
            [35.420918409981965, 31.100065822874356],
            [34.92260257339143, 29.501326198844524],
            [34.823243288783814, 29.76108076171822],
            [34.26544, 31.21936],
            [34.26543474464621, 31.21935730952032],
            [34.26543338393569, 31.219360866820153],
            [34.55637169773891, 31.548823960896996],
            [34.48810713068136, 31.60553884533732],
            [34.75258711115117, 32.07292633720117],
            [34.95541710789678, 32.82737641044638],
            [35.098457472480675, 33.080539252244265],
            [35.126052687324545, 33.09090037691878],
            [35.460709262846706, 33.08904002535628],
            [35.55279666519081, 33.26427480725802],
            [35.82110070165024, 33.2774264592763],
            [35.836396925608625, 32.86812327730851],
            [35.70079796727475, 32.71601369885738],
            [35.71991824722275, 32.709192409794866],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "China",
        sov_a3: "CH1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "China",
        adm0_a3: "CHN",
        geou_dif: 0,
        geounit: "China",
        gu_a3: "CHN",
        su_dif: 0,
        subunit: "China",
        su_a3: "CHN",
        brk_diff: 0,
        name: "China",
        name_long: "China",
        brk_a3: "CHN",
        brk_name: "China",
        brk_group: null,
        abbrev: "China",
        postal: "CN",
        formal_en: "People's Republic of China",
        formal_fr: null,
        name_ciawf: "China",
        note_adm0: null,
        note_brk: null,
        name_sort: "China",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 1397715000,
        pop_rank: 18,
        pop_year: 2019,
        gdp_md: 14342903,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        fips_10: "CH",
        iso_a2: "CN",
        iso_a2_eh: "CN",
        iso_a3: "CHN",
        iso_a3_eh: "CHN",
        iso_n3: "156",
        iso_n3_eh: "156",
        un_a3: "156",
        wb_a2: "CN",
        wb_a3: "CHN",
        woe_id: 23424781,
        woe_id_eh: 23424781,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CHN",
        adm0_diff: null,
        adm0_tlc: "CHN",
        adm0_a3_us: "CHN",
        adm0_a3_fr: "CHN",
        adm0_a3_ru: "CHN",
        adm0_a3_es: "CHN",
        adm0_a3_cn: "CHN",
        adm0_a3_tw: "TWN",
        adm0_a3_in: "CHN",
        adm0_a3_np: "CHN",
        adm0_a3_pk: "CHN",
        adm0_a3_de: "CHN",
        adm0_a3_gb: "CHN",
        adm0_a3_br: "CHN",
        adm0_a3_il: "CHN",
        adm0_a3_ps: "CHN",
        adm0_a3_sa: "CHN",
        adm0_a3_eg: "CHN",
        adm0_a3_ma: "CHN",
        adm0_a3_pt: "CHN",
        adm0_a3_ar: "CHN",
        adm0_a3_jp: "CHN",
        adm0_a3_ko: "CHN",
        adm0_a3_vn: "CHN",
        adm0_a3_tr: "CHN",
        adm0_a3_id: "CHN",
        adm0_a3_pl: "CHN",
        adm0_a3_gr: "CHN",
        adm0_a3_it: "CHN",
        adm0_a3_nl: "CHN",
        adm0_a3_se: "CHN",
        adm0_a3_bd: "CHN",
        adm0_a3_ua: "CHN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: 106.337289,
        label_y: 32.498178,
        ne_id: 1159320471,
        wikidataid: "Q148",
        name_ar: "الصين",
        name_bn: "গণচীন",
        name_de: "Volksrepublik China",
        name_en: "People's Republic of China",
        name_es: "China",
        name_fa: "جمهوری خلق چین",
        name_fr: "République populaire de Chine",
        name_el: "Λαϊκή Δημοκρατία της Κίνας",
        name_he: "הרפובליקה העממית של סין",
        name_hi: "चीनी जनवादी गणराज्य",
        name_hu: "Kína",
        name_id: "Republik Rakyat Tiongkok",
        name_it: "Cina",
        name_ja: "中華人民共和国",
        name_ko: "중화인민공화국",
        name_nl: "Volksrepubliek China",
        name_pl: "Chińska Republika Ludowa",
        name_pt: "China",
        name_ru: "Китайская Народная Республика",
        name_sv: "Kina",
        name_tr: "Çin Halk Cumhuriyeti",
        name_uk: "Китайська Народна Республіка",
        name_ur: "عوامی جمہوریہ چین",
        name_vi: "Trung Quốc",
        name_zh: "中华人民共和国",
        name_zht: "中華人民共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: "Unrecognized",
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CHN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.47520958866365, 18.197700913968575],
              [108.65520796105616, 18.507681993071387],
              [108.62621748254044, 19.367887885001906],
              [109.11905561730804, 19.821038519769345],
              [110.21159874882281, 20.101253973872033],
              [110.78655073450221, 20.077534491450052],
              [111.01005130416458, 19.69592987719072],
              [110.57064660038677, 19.25587921800927],
              [110.33918786015147, 18.678395087147592],
              [109.47520958866365, 18.197700913968575],
            ],
          ],
          [
            [
              [80.2599902688853, 42.34999929459906],
              [80.1801501809943, 42.92006785742694],
              [80.86620649610126, 43.18036204688101],
              [79.96610639844141, 44.91751699480463],
              [81.9470707539181, 45.31702749285312],
              [82.45892581576906, 45.539649563166506],
              [83.18048383986047, 47.33003123635086],
              [85.16429039911324, 47.0009557155161],
              [85.72048383987067, 47.452969468773105],
              [85.7682328633083, 48.45575063739699],
              [86.59877648310336, 48.549181626980626],
              [87.35997033076265, 49.21498078062912],
              [87.75126427607671, 49.297197984405486],
              [88.01383222855173, 48.599462795600616],
              [88.85429772334676, 48.069081732772965],
              [90.28082563676392, 47.69354909930793],
              [90.97080936072501, 46.88814606382293],
              [90.58576826371828, 45.71971609148753],
              [90.9455395853343, 45.28607330991028],
              [92.13389082231822, 45.11507599545646],
              [93.4807336771413, 44.975472113619965],
              [94.68892866412533, 44.35233185482842],
              [95.30687544147153, 44.24133087826547],
              [95.76245486855669, 43.319449164394605],
              [96.34939578652781, 42.725635280928685],
              [97.45175744017801, 42.74888967546002],
              [99.51581749878004, 42.524691473961724],
              [100.84586551310827, 42.66380442969145],
              [101.83304039917994, 42.51487295182628],
              [103.31227827353482, 41.9074681666676],
              [104.52228193564899, 41.908346666016556],
              [104.96499393109347, 41.59740957291635],
              [106.12931562706169, 42.13432770442891],
              [107.74477257693795, 42.48151581478187],
              [109.24359581913146, 42.5194463160841],
              [110.41210330611528, 42.87123362891103],
              [111.12968224492023, 43.40683401140015],
              [111.82958784388137, 43.74311839453952],
              [111.66773725794323, 44.07317576758771],
              [111.34837690637946, 44.45744171811009],
              [111.8733061056003, 45.10207937273506],
              [112.43606245325881, 45.01164561622429],
              [113.46390669154417, 44.80889313412712],
              [114.46033165899607, 45.339816799493825],
              [115.98509647020009, 45.727235012386004],
              [116.71786828009886, 46.38820241961521],
              [117.42170128791419, 46.67273285581426],
              [118.87432579963873, 46.80541209572365],
              [119.66326989143876, 46.69267995867892],
              [119.7728239278975, 47.04805878355013],
              [118.86657433479495, 47.74706004494617],
              [118.06414269416672, 48.06673045510369],
              [117.29550744025741, 47.69770905210743],
              [116.30895267137323, 47.85341014260284],
              [115.74283735561579, 47.72654450132629],
              [115.48528201707306, 48.13538259540344],
              [116.19180219936757, 49.134598090199106],
              [116.67880089728612, 49.888531399121405],
              [117.8792444194264, 49.510983384796965],
              [119.2884607280258, 50.142882798862004],
              [119.27939, 50.58292],
              [120.18208000000016, 51.64355000000012],
              [120.7382, 51.96411],
              [120.72578901579197, 52.516226304730935],
              [120.17708865771681, 52.75388621684124],
              [121.00308475147017, 53.25140106873119],
              [122.24574791879283, 53.431725979213695],
              [123.57147, 53.4588],
              [125.06821129771038, 53.16104482686886],
              [125.94634891164617, 52.79279857035698],
              [126.56439904185697, 51.7842554795327],
              [126.93915652883766, 51.35389415140592],
              [127.28745568248485, 50.73979726826548],
              [127.65740000000028, 49.760270000000105],
              [129.39781782442043, 49.44060008401544],
              [130.58229332898242, 48.72968740497614],
              [130.9872600000001, 47.79013],
              [132.50669, 47.78896],
              [133.37359581922794, 48.18344167743487],
              [135.02631147678665, 48.478229885443874],
              [134.50081, 47.57845],
              [134.1123500000001, 47.21248],
              [133.76964399631288, 46.116926988299014],
              [133.09712000000013, 45.14409],
              [131.8834542176595, 45.32116160743652],
              [131.02519000000018, 44.96796],
              [131.28855512911548, 44.11151968034828],
              [131.1446879416149, 42.92998973242689],
              [130.63386640840974, 42.90301463477053],
              [130.63999970690955, 42.39502427522179],
              [129.99426720593323, 42.985386867843786],
              [129.59666873587952, 42.42498179785456],
              [128.0522152039723, 41.99428457291795],
              [128.20843305879066, 41.46677155208249],
              [127.34378299368302, 41.50315176041597],
              [126.86908328664987, 41.81656932226619],
              [126.18204511932943, 41.10733612727637],
              [125.07994184784064, 40.56982371679245],
              [124.26562462778531, 39.928493353834156],
              [122.86757042856095, 39.63778758397622],
              [122.1313879741309, 39.170451768544666],
              [121.05455447803286, 38.897471014962846],
              [121.58599490772248, 39.36085358332407],
              [121.37675703337268, 39.75026133885949],
              [122.16859500538106, 40.422442531896024],
              [121.64035851449353, 40.94638987890333],
              [120.76862877816194, 40.59338816991754],
              [119.63960208544907, 39.898055935214245],
              [119.02346398323306, 39.252333075511146],
              [118.04274865119791, 39.2042739934797],
              [117.53270226447711, 38.73763580988408],
              [118.05969852098964, 38.06147553156106],
              [118.87814985562832, 37.89732534438593],
              [118.91163618375344, 37.44846385349871],
              [119.70280236214205, 37.15638865818505],
              [120.8234574728236, 37.87042776137801],
              [121.711258579598, 37.48112335870718],
              [122.35793745329849, 37.45448415786072],
              [122.5199947449658, 36.93061432550185],
              [121.10416385303307, 36.651329047180425],
              [120.63700890511453, 36.111439520811075],
              [119.66456180224606, 35.60979055433772],
              [119.1512081238586, 34.90985911716044],
              [120.22752485563373, 34.36033193616865],
              [120.62036909391655, 33.37672272392514],
              [121.22901411345023, 32.46031871187719],
              [121.90814578663003, 31.692174384074647],
              [121.89191938689038, 30.949351508095106],
              [121.26425744027328, 30.6762674016487],
              [121.50351932178467, 30.14291494396429],
              [122.0921138855891, 29.83252045340315],
              [121.93842817595306, 29.01802236583478],
              [121.68443851123851, 28.225512600206617],
              [121.12566124886649, 28.135673122667185],
              [120.39547326058232, 27.05320689544932],
              [119.5854968608395, 25.740780544532612],
              [118.65687137255452, 24.54739085540024],
              [117.28160647997088, 23.624501451099658],
              [115.89073530483512, 22.78287323657807],
              [114.76382734584624, 22.668074042241667],
              [114.15254682826564, 22.22376007739615],
              [113.80677981980074, 22.5483397486214],
              [113.24107791550159, 22.051367499270455],
              [111.84359215703248, 21.550493679281473],
              [110.78546552942407, 21.397143866455338],
              [110.44403934127168, 20.34103261970633],
              [109.88986128137361, 20.282457383703488],
              [109.62765506392466, 21.008227037026703],
              [109.86448815311832, 21.395050970947523],
              [108.5228129415244, 21.715212307211814],
              [108.05018029178294, 21.55237986906012],
              [107.04342003787264, 21.811898912029914],
              [106.56727339073532, 22.21820486092477],
              [106.72540327354847, 22.79426788989842],
              [105.81124718630522, 22.976892401617903],
              [105.32920942588663, 23.352063300056912],
              [104.47685835166448, 22.819150092046968],
              [103.50451460166056, 22.70375661873921],
              [102.7069922221001, 22.708795070887675],
              [102.17043582561358, 22.464753119389304],
              [101.65201785686152, 22.318198757409547],
              [101.80311974488293, 21.17436676684507],
              [101.27002566935997, 21.201651923095184],
              [101.18000532430754, 21.436572984294028],
              [101.15003299357825, 21.849984442629022],
              [100.41653771362738, 21.558839423096614],
              [99.98348921102149, 21.7429367131364],
              [99.24089887898725, 22.11831431730458],
              [99.5319922220874, 22.94903880461258],
              [98.89874922078278, 23.14272207284253],
              [98.66026248575577, 24.063286037689966],
              [97.60471967976198, 23.897404690033042],
              [97.72460900267914, 25.083637193293],
              [98.67183800658916, 25.918702500913525],
              [98.71209394734451, 26.743535874940267],
              [98.68269005737046, 27.50881216075062],
              [98.2462309102333, 27.74722138112918],
              [97.91198774616944, 28.335945136014345],
              [97.32711388549004, 28.26158274994634],
              [96.24883344928779, 28.411030992134442],
              [96.58659061074749, 28.83097951915434],
              [96.11767866413103, 29.452802028922466],
              [95.40480228066464, 29.03171662039213],
              [94.56599043170294, 29.277438055939985],
              [93.41334760943269, 28.640629380807226],
              [92.50311893104364, 27.89687632904645],
              [91.69665652869668, 27.771741848251665],
              [91.25885379431992, 28.040614325466294],
              [90.7305139505678, 28.064953925075756],
              [90.01582889197118, 28.296438503527217],
              [89.47581017452111, 28.042758897406397],
              [88.81424848832056, 27.299315904239364],
              [88.73032596227856, 28.086864732367516],
              [88.12044070836987, 27.876541652939594],
              [86.9545170430006, 27.974261786403517],
              [85.82331994013151, 28.203575954698707],
              [85.01163821812304, 28.642773952747344],
              [84.23457970575015, 28.839893703724698],
              [83.89899295444673, 29.320226141877658],
              [83.33711510613719, 29.463731594352197],
              [82.32751264845088, 30.115268052688137],
              [81.52580447787474, 30.42271698660863],
              [81.11125613802932, 30.183480943313402],
              [79.7213668151071, 30.88271474865473],
              [78.73889448437401, 31.515906073527063],
              [78.45844648632601, 32.61816437431273],
              [79.17612877799553, 32.48377981213771],
              [79.20889163606859, 32.994394639613716],
              [78.81108646028574, 33.50619802503242],
              [78.91226891471322, 34.32193634697579],
              [77.83745079947457, 35.494009507787766],
              [76.19284834178569, 35.89840342868783],
              [75.89689741405013, 36.666806138651836],
              [75.15802778514092, 37.13303091078912],
              [74.98000247589542, 37.419990139305895],
              [74.8299857929521, 37.9900070257014],
              [74.86481570831683, 38.3788463404816],
              [74.25751427602273, 38.60650686294345],
              [73.92885216664644, 38.50581533462274],
              [73.6753792662548, 39.4312368841056],
              [73.96001305531843, 39.660008449861735],
              [73.8222436868283, 39.893973497063186],
              [74.77686242055606, 40.36642527929163],
              [75.4678279967307, 40.56207225194867],
              [76.52636803579745, 40.42794607193512],
              [76.90448449087708, 41.06648590754965],
              [78.18719689322597, 41.18531586360481],
              [78.54366092317531, 41.58224254003869],
              [80.11943037305139, 42.12394074153825],
              [80.2599902688853, 42.34999929459906],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Israel",
        sov_a3: "IS1",
        adm0_dif: 1,
        level: 2,
        type: "Indeterminate",
        tlc: "1",
        admin: "Palestine",
        adm0_a3: "PSX",
        geou_dif: 0,
        geounit: "Palestine",
        gu_a3: "PSX",
        su_dif: 0,
        subunit: "Palestine",
        su_a3: "PSX",
        brk_diff: 0,
        name: "Palestine",
        name_long: "Palestine",
        brk_a3: "PSX",
        brk_name: "Palestine",
        brk_group: null,
        abbrev: "Pal.",
        postal: "PAL",
        formal_en: "West Bank and Gaza",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: null,
        note_brk: "Partial self-admin.",
        name_sort: "Palestine (West Bank and Gaza)",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 4685306,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 16276,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "-99",
        iso_a2: "PS",
        iso_a2_eh: "PS",
        iso_a3: "PSE",
        iso_a3_eh: "PSE",
        iso_n3: "275",
        iso_n3_eh: "275",
        un_a3: "275",
        wb_a2: "GZ",
        wb_a3: "WBG",
        woe_id: 28289408,
        woe_id_eh: 28289408,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PSX",
        adm0_diff: null,
        adm0_tlc: "PSX",
        adm0_a3_us: "PSX",
        adm0_a3_fr: "PSX",
        adm0_a3_ru: "PSX",
        adm0_a3_es: "PSX",
        adm0_a3_cn: "PSX",
        adm0_a3_tw: "PSX",
        adm0_a3_in: "PSX",
        adm0_a3_np: "PSX",
        adm0_a3_pk: "PSX",
        adm0_a3_de: "PSX",
        adm0_a3_gb: "PSX",
        adm0_a3_br: "PSX",
        adm0_a3_il: "PSX",
        adm0_a3_ps: "PSX",
        adm0_a3_sa: "PSX",
        adm0_a3_eg: "PSX",
        adm0_a3_ma: "PSX",
        adm0_a3_pt: "PSX",
        adm0_a3_ar: "PSX",
        adm0_a3_jp: "PSX",
        adm0_a3_ko: "PSX",
        adm0_a3_vn: "PSX",
        adm0_a3_tr: "PSX",
        adm0_a3_id: "PSX",
        adm0_a3_pl: "PSX",
        adm0_a3_gr: "PSX",
        adm0_a3_it: "PSX",
        adm0_a3_nl: "PSX",
        adm0_a3_se: "PSX",
        adm0_a3_bd: "PSX",
        adm0_a3_ua: "PSX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 7,
        min_label: 4.5,
        max_label: 9.5,
        label_x: 35.291341,
        label_y: 32.047431,
        ne_id: 1159320899,
        wikidataid: "Q23792",
        name_ar: "فلسطين",
        name_bn: "ফিলিস্তিন অঞ্চল",
        name_de: "Palästina",
        name_en: "Palestine",
        name_es: "Palestina",
        name_fa: "فلسطین",
        name_fr: "Palestine",
        name_el: "Παλαιστίνη",
        name_he: "ארץ ישראל",
        name_hi: "फ़िलिस्तीनी राज्यक्षेत्र",
        name_hu: "Palesztina",
        name_id: "Palestina",
        name_it: "Palestina",
        name_ja: "パレスチナ",
        name_ko: "팔레스타인",
        name_nl: "Palestina",
        name_pl: "Palestyna",
        name_pt: "Palestina",
        name_ru: "Палестина",
        name_sv: "Palestina",
        name_tr: "Filistin",
        name_uk: "Палестина",
        name_ur: "فلسطین",
        name_vi: "Palestine",
        name_zh: "巴勒斯坦",
        name_zht: "巴勒斯坦地區",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: "Admin-0 country",
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: "Admin-0 country",
        fclass_sa: "Admin-0 country",
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: "Admin-0 country",
        fclass_ua: null,
        filename: "PSE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.397560662586045, 31.489086005167582],
            [34.92740848159457, 31.353435370401414],
            [34.970506626125996, 31.61677846936081],
            [35.22589155451243, 31.754341132121766],
            [34.97464074070933, 31.866582343059722],
            [35.183930291491436, 32.53251068778894],
            [35.54566531753454, 32.393992011030576],
            [35.5452519060762, 31.78250478772084],
            [35.397560662586045, 31.489086005167582],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Lebanon",
        sov_a3: "LBN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Lebanon",
        adm0_a3: "LBN",
        geou_dif: 0,
        geounit: "Lebanon",
        gu_a3: "LBN",
        su_dif: 0,
        subunit: "Lebanon",
        su_a3: "LBN",
        brk_diff: 0,
        name: "Lebanon",
        name_long: "Lebanon",
        brk_a3: "LBN",
        brk_name: "Lebanon",
        brk_group: null,
        abbrev: "Leb.",
        postal: "LB",
        formal_en: "Lebanese Republic",
        formal_fr: null,
        name_ciawf: "Lebanon",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lebanon",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 12,
        pop_est: 6855713,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 51991,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "LE",
        iso_a2: "LB",
        iso_a2_eh: "LB",
        iso_a3: "LBN",
        iso_a3_eh: "LBN",
        iso_n3: "422",
        iso_n3_eh: "422",
        un_a3: "422",
        wb_a2: "LB",
        wb_a3: "LBN",
        woe_id: 23424873,
        woe_id_eh: 23424873,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LBN",
        adm0_diff: null,
        adm0_tlc: "LBN",
        adm0_a3_us: "LBN",
        adm0_a3_fr: "LBN",
        adm0_a3_ru: "LBN",
        adm0_a3_es: "LBN",
        adm0_a3_cn: "LBN",
        adm0_a3_tw: "LBN",
        adm0_a3_in: "LBN",
        adm0_a3_np: "LBN",
        adm0_a3_pk: "LBN",
        adm0_a3_de: "LBN",
        adm0_a3_gb: "LBN",
        adm0_a3_br: "LBN",
        adm0_a3_il: "LBN",
        adm0_a3_ps: "LBN",
        adm0_a3_sa: "LBN",
        adm0_a3_eg: "LBN",
        adm0_a3_ma: "LBN",
        adm0_a3_pt: "LBN",
        adm0_a3_ar: "LBN",
        adm0_a3_jp: "LBN",
        adm0_a3_ko: "LBN",
        adm0_a3_vn: "LBN",
        adm0_a3_tr: "LBN",
        adm0_a3_id: "LBN",
        adm0_a3_pl: "LBN",
        adm0_a3_gr: "LBN",
        adm0_a3_it: "LBN",
        adm0_a3_nl: "LBN",
        adm0_a3_se: "LBN",
        adm0_a3_bd: "LBN",
        adm0_a3_ua: "LBN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 35.992892,
        label_y: 34.133368,
        ne_id: 1159321013,
        wikidataid: "Q822",
        name_ar: "لبنان",
        name_bn: "লেবানন",
        name_de: "Libanon",
        name_en: "Lebanon",
        name_es: "Líbano",
        name_fa: "لبنان",
        name_fr: "Liban",
        name_el: "Λίβανος",
        name_he: "לבנון",
        name_hi: "लेबनान",
        name_hu: "Libanon",
        name_id: "Lebanon",
        name_it: "Libano",
        name_ja: "レバノン",
        name_ko: "레바논",
        name_nl: "Libanon",
        name_pl: "Liban",
        name_pt: "Líbano",
        name_ru: "Ливан",
        name_sv: "Libanon",
        name_tr: "Lübnan",
        name_uk: "Ліван",
        name_ur: "لبنان",
        name_vi: "Liban",
        name_zh: "黎巴嫩",
        name_zht: "黎巴嫩",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LBN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.82110070165024, 33.2774264592763],
            [35.55279666519081, 33.26427480725802],
            [35.460709262846706, 33.08904002535628],
            [35.126052687324545, 33.09090037691878],
            [35.48220665868013, 33.90545014091944],
            [35.9795923194894, 34.61005829521913],
            [35.99840254084364, 34.644914048800004],
            [36.4481942075121, 34.59393524834407],
            [36.61175011571589, 34.20178864189718],
            [36.066460402172055, 33.82491242119255],
            [35.82110070165024, 33.2774264592763],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Syria",
        sov_a3: "SYR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Syria",
        adm0_a3: "SYR",
        geou_dif: 0,
        geounit: "Syria",
        gu_a3: "SYR",
        su_dif: 0,
        subunit: "Syria",
        su_a3: "SYR",
        brk_diff: 0,
        name: "Syria",
        name_long: "Syria",
        brk_a3: "SYR",
        brk_name: "Syria",
        brk_group: null,
        abbrev: "Syria",
        postal: "SYR",
        formal_en: "Syrian Arab Republic",
        formal_fr: null,
        name_ciawf: "Syria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Syrian Arab Republic",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 6,
        pop_est: 17070135,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 98830,
        gdp_year: 2015,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "SY",
        iso_a2: "SY",
        iso_a2_eh: "SY",
        iso_a3: "SYR",
        iso_a3_eh: "SYR",
        iso_n3: "760",
        iso_n3_eh: "760",
        un_a3: "760",
        wb_a2: "SY",
        wb_a3: "SYR",
        woe_id: 23424956,
        woe_id_eh: 23424956,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SYR",
        adm0_diff: null,
        adm0_tlc: "SYR",
        adm0_a3_us: "SYR",
        adm0_a3_fr: "SYR",
        adm0_a3_ru: "SYR",
        adm0_a3_es: "SYR",
        adm0_a3_cn: "SYR",
        adm0_a3_tw: "SYR",
        adm0_a3_in: "SYR",
        adm0_a3_np: "SYR",
        adm0_a3_pk: "SYR",
        adm0_a3_de: "SYR",
        adm0_a3_gb: "SYR",
        adm0_a3_br: "SYR",
        adm0_a3_il: "SYR",
        adm0_a3_ps: "SYR",
        adm0_a3_sa: "SYR",
        adm0_a3_eg: "SYR",
        adm0_a3_ma: "SYR",
        adm0_a3_pt: "SYR",
        adm0_a3_ar: "SYR",
        adm0_a3_jp: "SYR",
        adm0_a3_ko: "SYR",
        adm0_a3_vn: "SYR",
        adm0_a3_tr: "SYR",
        adm0_a3_id: "SYR",
        adm0_a3_pl: "SYR",
        adm0_a3_gr: "SYR",
        adm0_a3_it: "SYR",
        adm0_a3_nl: "SYR",
        adm0_a3_se: "SYR",
        adm0_a3_bd: "SYR",
        adm0_a3_ua: "SYR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 38.277783,
        label_y: 35.006636,
        ne_id: 1159321295,
        wikidataid: "Q858",
        name_ar: "سوريا",
        name_bn: "সিরিয়া",
        name_de: "Syrien",
        name_en: "Syria",
        name_es: "Siria",
        name_fa: "سوریه",
        name_fr: "Syrie",
        name_el: "Συρία",
        name_he: "סוריה",
        name_hi: "सीरिया",
        name_hu: "Szíria",
        name_id: "Suriah",
        name_it: "Siria",
        name_ja: "シリア",
        name_ko: "시리아",
        name_nl: "Syrië",
        name_pl: "Syria",
        name_pt: "Síria",
        name_ru: "Сирия",
        name_sv: "Syrien",
        name_tr: "Suriye",
        name_uk: "Сирія",
        name_ur: "سوریہ",
        name_vi: "Syria",
        name_zh: "叙利亚",
        name_zht: "敘利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SYR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.71991824722275, 32.709192409794866],
            [35.70079796727475, 32.71601369885738],
            [35.836396925608625, 32.86812327730851],
            [35.82110070165024, 33.2774264592763],
            [36.066460402172055, 33.82491242119255],
            [36.61175011571589, 34.20178864189718],
            [36.4481942075121, 34.59393524834407],
            [35.99840254084364, 34.644914048800004],
            [35.905023227692226, 35.410009467097325],
            [36.149762811026534, 35.82153473565367],
            [36.417550083163036, 36.04061697035506],
            [36.6853890317318, 36.259699205056464],
            [36.7394942563414, 36.81752045343109],
            [37.06676110204583, 36.62303620050062],
            [38.1677274920242, 36.90121043552777],
            [38.6998913917659, 36.71292735447234],
            [39.52258019385255, 36.71605377862599],
            [40.67325931169569, 37.09127635349729],
            [41.21208947120305, 37.074352321921694],
            [42.34959109881177, 37.2298725449041],
            [41.83706424334096, 36.605853786763575],
            [41.289707472505455, 36.35881460219227],
            [41.383965285005814, 35.628316555314356],
            [41.006158888519934, 34.41937226006212],
            [38.792340529136084, 33.378686428352225],
            [36.834062127435544, 32.312937526980775],
            [35.71991824722275, 32.709192409794866],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "South Korea",
        sov_a3: "KOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "South Korea",
        adm0_a3: "KOR",
        geou_dif: 0,
        geounit: "South Korea",
        gu_a3: "KOR",
        su_dif: 0,
        subunit: "South Korea",
        su_a3: "KOR",
        brk_diff: 0,
        name: "South Korea",
        name_long: "Republic of Korea",
        brk_a3: "KOR",
        brk_name: "Republic of Korea",
        brk_group: null,
        abbrev: "S.K.",
        postal: "KR",
        formal_en: "Republic of Korea",
        formal_fr: null,
        name_ciawf: "Korea, South",
        note_adm0: null,
        note_brk: null,
        name_sort: "Korea, Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 5,
        pop_est: 51709098,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 1646739,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "1. High income: OECD",
        fips_10: "KS",
        iso_a2: "KR",
        iso_a2_eh: "KR",
        iso_a3: "KOR",
        iso_a3_eh: "KOR",
        iso_n3: "410",
        iso_n3_eh: "410",
        un_a3: "410",
        wb_a2: "KR",
        wb_a3: "KOR",
        woe_id: 23424868,
        woe_id_eh: 23424868,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KOR",
        adm0_diff: null,
        adm0_tlc: "KOR",
        adm0_a3_us: "KOR",
        adm0_a3_fr: "KOR",
        adm0_a3_ru: "KOR",
        adm0_a3_es: "KOR",
        adm0_a3_cn: "KOR",
        adm0_a3_tw: "KOR",
        adm0_a3_in: "KOR",
        adm0_a3_np: "KOR",
        adm0_a3_pk: "KOR",
        adm0_a3_de: "KOR",
        adm0_a3_gb: "KOR",
        adm0_a3_br: "KOR",
        adm0_a3_il: "KOR",
        adm0_a3_ps: "KOR",
        adm0_a3_sa: "KOR",
        adm0_a3_eg: "KOR",
        adm0_a3_ma: "KOR",
        adm0_a3_pt: "KOR",
        adm0_a3_ar: "KOR",
        adm0_a3_jp: "KOR",
        adm0_a3_ko: "KOR",
        adm0_a3_vn: "KOR",
        adm0_a3_tr: "KOR",
        adm0_a3_id: "KOR",
        adm0_a3_pl: "KOR",
        adm0_a3_gr: "KOR",
        adm0_a3_it: "KOR",
        adm0_a3_nl: "KOR",
        adm0_a3_se: "KOR",
        adm0_a3_bd: "KOR",
        adm0_a3_ua: "KOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 17,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 128.129504,
        label_y: 36.384924,
        ne_id: 1159320985,
        wikidataid: "Q884",
        name_ar: "كوريا الجنوبية",
        name_bn: "দক্ষিণ কোরিয়া",
        name_de: "Südkorea",
        name_en: "South Korea",
        name_es: "Corea del Sur",
        name_fa: "کره جنوبی",
        name_fr: "Corée du Sud",
        name_el: "Νότια Κορέα",
        name_he: "קוריאה הדרומית",
        name_hi: "दक्षिण कोरिया",
        name_hu: "Dél-Korea",
        name_id: "Korea Selatan",
        name_it: "Corea del Sud",
        name_ja: "大韓民国",
        name_ko: "대한민국",
        name_nl: "Zuid-Korea",
        name_pl: "Korea Południowa",
        name_pt: "Coreia do Sul",
        name_ru: "Республика Корея",
        name_sv: "Sydkorea",
        name_tr: "Güney Kore",
        name_uk: "Південна Корея",
        name_ur: "جنوبی کوریا",
        name_vi: "Hàn Quốc",
        name_zh: "大韩民国",
        name_zht: "大韓民國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KOR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [126.17475874237624, 37.74968577732804],
            [126.23733890188176, 37.84037791600028],
            [126.68371992401893, 37.80477285415118],
            [127.07330854706737, 38.2561148137884],
            [127.78003543509101, 38.30453563084589],
            [128.20574588431145, 38.37039724380189],
            [128.34971642467661, 38.61224294692785],
            [129.21291954968007, 37.43239248305595],
            [129.46044966035817, 36.78418915460283],
            [129.4683044780665, 35.63214061130395],
            [129.0913765809296, 35.082484239231434],
            [128.1858504578791, 34.89037710218639],
            [127.3865194031884, 34.47567373304412],
            [126.48574751190876, 34.39004588473648],
            [126.37391971242914, 34.934560451795946],
            [126.55923139862779, 35.6845405136479],
            [126.11739790253229, 36.72548472751926],
            [126.86014326386339, 36.893924058574626],
            [126.17475874237624, 37.74968577732804],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Bhutan",
        sov_a3: "BTN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bhutan",
        adm0_a3: "BTN",
        geou_dif: 0,
        geounit: "Bhutan",
        gu_a3: "BTN",
        su_dif: 0,
        subunit: "Bhutan",
        su_a3: "BTN",
        brk_diff: 0,
        name: "Bhutan",
        name_long: "Bhutan",
        brk_a3: "BTN",
        brk_name: "Bhutan",
        brk_group: null,
        abbrev: "Bhutan",
        postal: "BT",
        formal_en: "Kingdom of Bhutan",
        formal_fr: null,
        name_ciawf: "Bhutan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bhutan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 763092,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 2530,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "BT",
        iso_a2: "BT",
        iso_a2_eh: "BT",
        iso_a3: "BTN",
        iso_a3_eh: "BTN",
        iso_n3: "064",
        iso_n3_eh: "064",
        un_a3: "064",
        wb_a2: "BT",
        wb_a3: "BTN",
        woe_id: 23424770,
        woe_id_eh: 23424770,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BTN",
        adm0_diff: null,
        adm0_tlc: "BTN",
        adm0_a3_us: "BTN",
        adm0_a3_fr: "BTN",
        adm0_a3_ru: "BTN",
        adm0_a3_es: "BTN",
        adm0_a3_cn: "BTN",
        adm0_a3_tw: "BTN",
        adm0_a3_in: "BTN",
        adm0_a3_np: "BTN",
        adm0_a3_pk: "BTN",
        adm0_a3_de: "BTN",
        adm0_a3_gb: "BTN",
        adm0_a3_br: "BTN",
        adm0_a3_il: "BTN",
        adm0_a3_ps: "BTN",
        adm0_a3_sa: "BTN",
        adm0_a3_eg: "BTN",
        adm0_a3_ma: "BTN",
        adm0_a3_pt: "BTN",
        adm0_a3_ar: "BTN",
        adm0_a3_jp: "BTN",
        adm0_a3_ko: "BTN",
        adm0_a3_vn: "BTN",
        adm0_a3_tr: "BTN",
        adm0_a3_id: "BTN",
        adm0_a3_pl: "BTN",
        adm0_a3_gr: "BTN",
        adm0_a3_it: "BTN",
        adm0_a3_nl: "BTN",
        adm0_a3_se: "BTN",
        adm0_a3_bd: "BTN",
        adm0_a3_ua: "BTN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 90.040294,
        label_y: 27.536685,
        ne_id: 1159320453,
        wikidataid: "Q917",
        name_ar: "بوتان",
        name_bn: "ভুটান",
        name_de: "Bhutan",
        name_en: "Bhutan",
        name_es: "Bután",
        name_fa: "بوتان",
        name_fr: "Bhoutan",
        name_el: "Μπουτάν",
        name_he: "בהוטן",
        name_hi: "भूटान",
        name_hu: "Bhután",
        name_id: "Bhutan",
        name_it: "Bhutan",
        name_ja: "ブータン",
        name_ko: "부탄",
        name_nl: "Bhutan",
        name_pl: "Bhutan",
        name_pt: "Butão",
        name_ru: "Бутан",
        name_sv: "Bhutan",
        name_tr: "Bhutan",
        name_uk: "Бутан",
        name_ur: "بھوٹان",
        name_vi: "Bhutan",
        name_zh: "不丹",
        name_zht: "不丹",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BTN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.69665652869668, 27.771741848251665],
            [92.10371178585974, 27.452614040633208],
            [92.03348351437509, 26.83831045176356],
            [91.21751264848643, 26.808648179628022],
            [90.37327477413407, 26.87572418874288],
            [89.74452762243885, 26.719402981059957],
            [88.83564253128938, 27.098966376243762],
            [88.81424848832056, 27.299315904239364],
            [89.47581017452111, 28.042758897406397],
            [90.01582889197118, 28.296438503527217],
            [90.7305139505678, 28.064953925075756],
            [91.25885379431992, 28.040614325466294],
            [91.69665652869668, 27.771741848251665],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "North Korea",
        sov_a3: "PRK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "North Korea",
        adm0_a3: "PRK",
        geou_dif: 0,
        geounit: "North Korea",
        gu_a3: "PRK",
        su_dif: 0,
        subunit: "North Korea",
        su_a3: "PRK",
        brk_diff: 0,
        name: "North Korea",
        name_long: "Dem. Rep. Korea",
        brk_a3: "PRK",
        brk_name: "Dem. Rep. Korea",
        brk_group: null,
        abbrev: "N.K.",
        postal: "KP",
        formal_en: "Democratic People's Republic of Korea",
        formal_fr: null,
        name_ciawf: "Korea, North",
        note_adm0: null,
        note_brk: null,
        name_sort: "Korea, Dem. Rep.",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 25666161,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 40000,
        gdp_year: 2016,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "KN",
        iso_a2: "KP",
        iso_a2_eh: "KP",
        iso_a3: "PRK",
        iso_a3_eh: "PRK",
        iso_n3: "408",
        iso_n3_eh: "408",
        un_a3: "408",
        wb_a2: "KP",
        wb_a3: "PRK",
        woe_id: 23424865,
        woe_id_eh: 23424865,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PRK",
        adm0_diff: null,
        adm0_tlc: "PRK",
        adm0_a3_us: "PRK",
        adm0_a3_fr: "PRK",
        adm0_a3_ru: "PRK",
        adm0_a3_es: "PRK",
        adm0_a3_cn: "PRK",
        adm0_a3_tw: "PRK",
        adm0_a3_in: "PRK",
        adm0_a3_np: "PRK",
        adm0_a3_pk: "PRK",
        adm0_a3_de: "PRK",
        adm0_a3_gb: "PRK",
        adm0_a3_br: "PRK",
        adm0_a3_il: "PRK",
        adm0_a3_ps: "PRK",
        adm0_a3_sa: "PRK",
        adm0_a3_eg: "PRK",
        adm0_a3_ma: "PRK",
        adm0_a3_pt: "PRK",
        adm0_a3_ar: "PRK",
        adm0_a3_jp: "PRK",
        adm0_a3_ko: "PRK",
        adm0_a3_vn: "PRK",
        adm0_a3_tr: "PRK",
        adm0_a3_id: "PRK",
        adm0_a3_pl: "PRK",
        adm0_a3_gr: "PRK",
        adm0_a3_it: "PRK",
        adm0_a3_nl: "PRK",
        adm0_a3_se: "PRK",
        adm0_a3_bd: "PRK",
        adm0_a3_ua: "PRK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 15,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 126.444516,
        label_y: 39.885252,
        ne_id: 1159321181,
        wikidataid: "Q423",
        name_ar: "كوريا الشمالية",
        name_bn: "উত্তর কোরিয়া",
        name_de: "Nordkorea",
        name_en: "North Korea",
        name_es: "Corea del Norte",
        name_fa: "کره شمالی",
        name_fr: "Corée du Nord",
        name_el: "Βόρεια Κορέα",
        name_he: "קוריאה הצפונית",
        name_hi: "उत्तर कोरिया",
        name_hu: "Észak-Korea",
        name_id: "Korea Utara",
        name_it: "Corea del Nord",
        name_ja: "朝鮮民主主義人民共和国",
        name_ko: "조선민주주의인민공화국",
        name_nl: "Noord-Korea",
        name_pl: "Korea Północna",
        name_pt: "Coreia do Norte",
        name_ru: "КНДР",
        name_sv: "Nordkorea",
        name_tr: "Kuzey Kore",
        name_uk: "Корейська Народно-Демократична Республіка",
        name_ur: "شمالی کوریا",
        name_vi: "Cộng hòa Dân chủ Nhân dân Triều Tiên",
        name_zh: "朝鲜民主主义人民共和国",
        name_zht: "朝鮮民主主義人民共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [130.78000366004676, 42.220007813203225],
              [130.78000485358513, 42.22001036108258],
              [130.78000735893113, 42.22000722916885],
              [130.78000366004676, 42.220007813203225],
            ],
          ],
          [
            [
              [130.63999970690955, 42.39502427522179],
              [130.6400000000001, 42.395],
              [130.77999231657833, 42.22000960427719],
              [130.40003055228902, 42.28000356705971],
              [129.96594852103726, 41.94136790625106],
              [129.66736209525482, 41.60110443782523],
              [129.70518924369247, 40.88282786718433],
              [129.18811486218, 40.66180776627199],
              [129.01039961152821, 40.485436102859815],
              [128.63336836152672, 40.18984691015031],
              [127.96741417858135, 40.02541250259756],
              [127.53343550019417, 39.7568500839767],
              [127.5021195822253, 39.32393077245153],
              [127.38543419811027, 39.213472398427655],
              [127.78334272675772, 39.05089834243742],
              [128.34971642467661, 38.61224294692785],
              [128.20574588431145, 38.37039724380189],
              [127.78003543509101, 38.30453563084589],
              [127.07330854706737, 38.2561148137884],
              [126.68371992401893, 37.80477285415118],
              [126.23733890188176, 37.84037791600028],
              [126.17475874237624, 37.74968577732804],
              [125.68910363169721, 37.94001007745902],
              [125.5684391622957, 37.75208873142962],
              [125.2753304383362, 37.669070542952724],
              [125.24008711151316, 37.85722443292744],
              [124.98103315643397, 37.94882090916478],
              [124.71216067921938, 38.10834605564979],
              [124.98599409393398, 38.54847422947968],
              [125.2219486837787, 38.66585724543067],
              [125.13285851450752, 38.84855927179859],
              [125.3865897970606, 39.387957872061165],
              [125.32111575734682, 39.55138458918421],
              [124.7374821310424, 39.66034434667162],
              [124.26562462778531, 39.928493353834156],
              [125.07994184784064, 40.56982371679245],
              [126.18204511932943, 41.10733612727637],
              [126.86908328664987, 41.81656932226619],
              [127.34378299368302, 41.50315176041597],
              [128.20843305879066, 41.46677155208249],
              [128.0522152039723, 41.99428457291795],
              [129.59666873587952, 42.42498179785456],
              [129.99426720593323, 42.985386867843786],
              [130.63999970690955, 42.39502427522179],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Oman",
        sov_a3: "OMN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Oman",
        adm0_a3: "OMN",
        geou_dif: 0,
        geounit: "Oman",
        gu_a3: "OMN",
        su_dif: 0,
        subunit: "Oman",
        su_a3: "OMN",
        brk_diff: 0,
        name: "Oman",
        name_long: "Oman",
        brk_a3: "OMN",
        brk_name: "Oman",
        brk_group: null,
        abbrev: "Oman",
        postal: "OM",
        formal_en: "Sultanate of Oman",
        formal_fr: null,
        name_ciawf: "Oman",
        note_adm0: null,
        note_brk: null,
        name_sort: "Oman",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 4974986,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 76331,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "MU",
        iso_a2: "OM",
        iso_a2_eh: "OM",
        iso_a3: "OMN",
        iso_a3_eh: "OMN",
        iso_n3: "512",
        iso_n3_eh: "512",
        un_a3: "512",
        wb_a2: "OM",
        wb_a3: "OMN",
        woe_id: 23424898,
        woe_id_eh: 23424898,
        woe_note: "Exact WOE match as country",
        adm0_iso: "OMN",
        adm0_diff: null,
        adm0_tlc: "OMN",
        adm0_a3_us: "OMN",
        adm0_a3_fr: "OMN",
        adm0_a3_ru: "OMN",
        adm0_a3_es: "OMN",
        adm0_a3_cn: "OMN",
        adm0_a3_tw: "OMN",
        adm0_a3_in: "OMN",
        adm0_a3_np: "OMN",
        adm0_a3_pk: "OMN",
        adm0_a3_de: "OMN",
        adm0_a3_gb: "OMN",
        adm0_a3_br: "OMN",
        adm0_a3_il: "OMN",
        adm0_a3_ps: "OMN",
        adm0_a3_sa: "OMN",
        adm0_a3_eg: "OMN",
        adm0_a3_ma: "OMN",
        adm0_a3_pt: "OMN",
        adm0_a3_ar: "OMN",
        adm0_a3_jp: "OMN",
        adm0_a3_ko: "OMN",
        adm0_a3_vn: "OMN",
        adm0_a3_tr: "OMN",
        adm0_a3_id: "OMN",
        adm0_a3_pl: "OMN",
        adm0_a3_gr: "OMN",
        adm0_a3_it: "OMN",
        adm0_a3_nl: "OMN",
        adm0_a3_se: "OMN",
        adm0_a3_bd: "OMN",
        adm0_a3_ua: "OMN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 57.336553,
        label_y: 22.120427,
        ne_id: 1159321151,
        wikidataid: "Q842",
        name_ar: "سلطنة عمان",
        name_bn: "ওমান",
        name_de: "Oman",
        name_en: "Oman",
        name_es: "Omán",
        name_fa: "عمان",
        name_fr: "Oman",
        name_el: "Ομάν",
        name_he: "עומאן",
        name_hi: "ओमान",
        name_hu: "Omán",
        name_id: "Oman",
        name_it: "Oman",
        name_ja: "オマーン",
        name_ko: "오만",
        name_nl: "Oman",
        name_pl: "Oman",
        name_pt: "Omã",
        name_ru: "Оман",
        name_sv: "Oman",
        name_tr: "Umman",
        name_uk: "Оман",
        name_ur: "عمان",
        name_vi: "Oman",
        name_zh: "阿曼",
        name_zht: "阿曼",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "OMN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [55.208341098863194, 22.708329982997046],
              [55.234489373602884, 23.110992743415324],
              [55.525841098864475, 23.524869289640932],
              [55.52863162620824, 23.933604030853502],
              [55.98121382022046, 24.13054291431783],
              [55.804118686756226, 24.269604193615265],
              [55.88623253766801, 24.920830593357447],
              [56.396847365144005, 24.924732163995486],
              [56.845140415276006, 24.241673081961505],
              [57.4034525897574, 23.878594468678813],
              [58.13694786970825, 23.74793060962881],
              [58.72921146020542, 23.56566783293536],
              [59.18050174341033, 22.992395331305474],
              [59.45009769067703, 22.6602709009656],
              [59.80806033716286, 22.533611965418217],
              [59.806148309168066, 22.310524807214193],
              [59.442191196536385, 21.714540513592027],
              [59.28240766788991, 21.43388580981485],
              [58.86114139184656, 21.114034532144302],
              [58.48798587426694, 20.428985907467094],
              [58.034318475176605, 20.481437486243337],
              [57.826372511634105, 20.24300242764863],
              [57.665762160070955, 19.73600495043307],
              [57.788700392493325, 19.067570298737678],
              [57.694390903560645, 18.94470958096376],
              [57.234263950433814, 18.947991034414287],
              [56.60965091332193, 18.574267076079465],
              [56.51218916201947, 18.087113348863966],
              [56.28352094912793, 17.876066799383963],
              [55.66149173363064, 17.884128322821496],
              [55.26993940615512, 17.632309068263197],
              [55.27490034365513, 17.22835439703762],
              [54.79100223167404, 16.95069692633338],
              [54.23925296409371, 17.044980577049913],
              [53.570508253804576, 16.707662665264706],
              [53.10857262554751, 16.651051133688952],
              [52.78218427919205, 17.349742336491232],
              [52.00000980002224, 19.000003363516058],
              [54.99998172386236, 19.999994004796108],
              [55.666659376859826, 22.00000112557234],
              [55.208341098863194, 22.708329982997046],
            ],
          ],
          [
            [
              [56.261041701080956, 25.71460643157677],
              [56.07082075381456, 26.05546417897398],
              [56.36201744977927, 26.39593435312898],
              [56.48567915225374, 26.309117946878633],
              [56.39142133975335, 25.895990708921246],
              [56.261041701080956, 25.71460643157677],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Uzbekistan",
        sov_a3: "UZB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Uzbekistan",
        adm0_a3: "UZB",
        geou_dif: 0,
        geounit: "Uzbekistan",
        gu_a3: "UZB",
        su_dif: 0,
        subunit: "Uzbekistan",
        su_a3: "UZB",
        brk_diff: 0,
        name: "Uzbekistan",
        name_long: "Uzbekistan",
        brk_a3: "UZB",
        brk_name: "Uzbekistan",
        brk_group: null,
        abbrev: "Uzb.",
        postal: "UZ",
        formal_en: "Republic of Uzbekistan",
        formal_fr: null,
        name_ciawf: "Uzbekistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Uzbekistan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 33580650,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 57921,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "UZ",
        iso_a2: "UZ",
        iso_a2_eh: "UZ",
        iso_a3: "UZB",
        iso_a3_eh: "UZB",
        iso_n3: "860",
        iso_n3_eh: "860",
        un_a3: "860",
        wb_a2: "UZ",
        wb_a3: "UZB",
        woe_id: 23424980,
        woe_id_eh: 23424980,
        woe_note: "Exact WOE match as country",
        adm0_iso: "UZB",
        adm0_diff: null,
        adm0_tlc: "UZB",
        adm0_a3_us: "UZB",
        adm0_a3_fr: "UZB",
        adm0_a3_ru: "UZB",
        adm0_a3_es: "UZB",
        adm0_a3_cn: "UZB",
        adm0_a3_tw: "UZB",
        adm0_a3_in: "UZB",
        adm0_a3_np: "UZB",
        adm0_a3_pk: "UZB",
        adm0_a3_de: "UZB",
        adm0_a3_gb: "UZB",
        adm0_a3_br: "UZB",
        adm0_a3_il: "UZB",
        adm0_a3_ps: "UZB",
        adm0_a3_sa: "UZB",
        adm0_a3_eg: "UZB",
        adm0_a3_ma: "UZB",
        adm0_a3_pt: "UZB",
        adm0_a3_ar: "UZB",
        adm0_a3_jp: "UZB",
        adm0_a3_ko: "UZB",
        adm0_a3_vn: "UZB",
        adm0_a3_tr: "UZB",
        adm0_a3_id: "UZB",
        adm0_a3_pl: "UZB",
        adm0_a3_gr: "UZB",
        adm0_a3_it: "UZB",
        adm0_a3_nl: "UZB",
        adm0_a3_se: "UZB",
        adm0_a3_bd: "UZB",
        adm0_a3_ua: "UZB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 64.005429,
        label_y: 41.693603,
        ne_id: 1159321405,
        wikidataid: "Q265",
        name_ar: "أوزبكستان",
        name_bn: "উজবেকিস্তান",
        name_de: "Usbekistan",
        name_en: "Uzbekistan",
        name_es: "Uzbekistán",
        name_fa: "ازبکستان",
        name_fr: "Ouzbékistan",
        name_el: "Ουζμπεκιστάν",
        name_he: "אוזבקיסטן",
        name_hi: "उज़्बेकिस्तान",
        name_hu: "Üzbegisztán",
        name_id: "Uzbekistan",
        name_it: "Uzbekistan",
        name_ja: "ウズベキスタン",
        name_ko: "우즈베키스탄",
        name_nl: "Oezbekistan",
        name_pl: "Uzbekistan",
        name_pt: "Uzbequistão",
        name_ru: "Узбекистан",
        name_sv: "Uzbekistan",
        name_tr: "Özbekistan",
        name_uk: "Узбекистан",
        name_ur: "ازبکستان",
        name_vi: "Uzbekistan",
        name_zh: "乌兹别克斯坦",
        name_zht: "烏茲別克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "UZB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.96819135928291, 41.30864166926936],
            [55.928917270741096, 44.99585846615911],
            [58.50312706892845, 45.586804307632974],
            [58.68998904809581, 45.50001373959873],
            [60.23997195825834, 44.78403677019473],
            [61.05831994003245, 44.40581696225051],
            [62.01330040878625, 43.50447663021565],
            [63.18578698105657, 43.650074978198006],
            [64.90082441595928, 43.72808055274258],
            [66.09801232286509, 42.997660020513095],
            [66.02339155463562, 41.99464630794404],
            [66.51064863471572, 41.987644151368556],
            [66.71404707221652, 41.1684435084615],
            [67.98585574735182, 41.13599070898222],
            [68.25989586779562, 40.6623245305949],
            [68.63248294462002, 40.66868073176681],
            [69.07002729683524, 41.38424428971234],
            [70.3889648782208, 42.08130768489745],
            [70.96231489449914, 42.266154283205495],
            [71.25924767444823, 42.16771067968946],
            [70.42002241402821, 41.51999827734314],
            [71.1578585142916, 41.14358714452912],
            [71.87011478057047, 41.392900092121266],
            [73.05541710804917, 40.866033026689465],
            [71.77487511585656, 40.14584442805378],
            [71.01419803252017, 40.24436554621823],
            [70.60140669137269, 40.21852733007229],
            [70.45815962105962, 40.49649485937029],
            [70.66662234892505, 40.960213324541414],
            [69.32949466337283, 40.72782440852485],
            [69.0116329283455, 40.08615814875667],
            [68.53641645698943, 39.53345286717894],
            [67.70142866401736, 39.58047842056453],
            [67.44221967964131, 39.140143541005486],
            [68.17602501818592, 38.901553453113905],
            [68.39203250516596, 38.15702525486874],
            [67.82999962755952, 37.144994004864685],
            [67.07578209825962, 37.35614390720929],
            [66.51860680528867, 37.36278432875879],
            [66.54615034370022, 37.97468496352687],
            [65.2159989765074, 38.4026950139843],
            [64.17022301621677, 38.892406724598246],
            [63.51801476426103, 39.36325653742564],
            [62.374260288345006, 40.05388621679039],
            [61.88271406438469, 41.084856879229406],
            [61.54717898951356, 41.266370347654615],
            [60.465952996670694, 41.22032664648255],
            [60.083340691981675, 41.425146185871405],
            [59.976422153569786, 42.22308197689021],
            [58.62901085799146, 42.75155101172305],
            [57.78652998233708, 42.17055288346552],
            [56.932215203687804, 41.826026109375604],
            [57.0963912290791, 41.32231008561057],
            [55.96819135928291, 41.30864166926936],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Kazakhstan",
        sov_a3: "KA1",
        adm0_dif: 1,
        level: 1,
        type: "Sovereignty",
        tlc: "1",
        admin: "Kazakhstan",
        adm0_a3: "KAZ",
        geou_dif: 0,
        geounit: "Kazakhstan",
        gu_a3: "KAZ",
        su_dif: 0,
        subunit: "Kazakhstan",
        su_a3: "KAZ",
        brk_diff: 0,
        name: "Kazakhstan",
        name_long: "Kazakhstan",
        brk_a3: "KAZ",
        brk_name: "Kazakhstan",
        brk_group: null,
        abbrev: "Kaz.",
        postal: "KZ",
        formal_en: "Republic of Kazakhstan",
        formal_fr: null,
        name_ciawf: "Kazakhstan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kazakhstan",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 1,
        pop_est: 18513930,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 181665,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "KZ",
        iso_a2: "KZ",
        iso_a2_eh: "KZ",
        iso_a3: "KAZ",
        iso_a3_eh: "KAZ",
        iso_n3: "398",
        iso_n3_eh: "398",
        un_a3: "398",
        wb_a2: "KZ",
        wb_a3: "KAZ",
        woe_id: -90,
        woe_id_eh: 23424871,
        woe_note: "Includes Baykonur Cosmodrome as an Admin-1 states provinces",
        adm0_iso: "KAZ",
        adm0_diff: null,
        adm0_tlc: "KAZ",
        adm0_a3_us: "KAZ",
        adm0_a3_fr: "KAZ",
        adm0_a3_ru: "KAZ",
        adm0_a3_es: "KAZ",
        adm0_a3_cn: "KAZ",
        adm0_a3_tw: "KAZ",
        adm0_a3_in: "KAZ",
        adm0_a3_np: "KAZ",
        adm0_a3_pk: "KAZ",
        adm0_a3_de: "KAZ",
        adm0_a3_gb: "KAZ",
        adm0_a3_br: "KAZ",
        adm0_a3_il: "KAZ",
        adm0_a3_ps: "KAZ",
        adm0_a3_sa: "KAZ",
        adm0_a3_eg: "KAZ",
        adm0_a3_ma: "KAZ",
        adm0_a3_pt: "KAZ",
        adm0_a3_ar: "KAZ",
        adm0_a3_jp: "KAZ",
        adm0_a3_ko: "KAZ",
        adm0_a3_vn: "KAZ",
        adm0_a3_tr: "KAZ",
        adm0_a3_id: "KAZ",
        adm0_a3_pl: "KAZ",
        adm0_a3_gr: "KAZ",
        adm0_a3_it: "KAZ",
        adm0_a3_nl: "KAZ",
        adm0_a3_se: "KAZ",
        adm0_a3_bd: "KAZ",
        adm0_a3_ua: "KAZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 68.685548,
        label_y: 49.054149,
        ne_id: 1159320967,
        wikidataid: "Q232",
        name_ar: "كازاخستان",
        name_bn: "কাজাখস্তান",
        name_de: "Kasachstan",
        name_en: "Kazakhstan",
        name_es: "Kazajistán",
        name_fa: "قزاقستان",
        name_fr: "Kazakhstan",
        name_el: "Καζακστάν",
        name_he: "קזחסטן",
        name_hi: "कज़ाख़िस्तान",
        name_hu: "Kazahsztán",
        name_id: "Kazakhstan",
        name_it: "Kazakistan",
        name_ja: "カザフスタン",
        name_ko: "카자흐스탄",
        name_nl: "Kazachstan",
        name_pl: "Kazachstan",
        name_pt: "Cazaquistão",
        name_ru: "Казахстан",
        name_sv: "Kazakstan",
        name_tr: "Kazakistan",
        name_uk: "Казахстан",
        name_ur: "قازقستان",
        name_vi: "Kazakhstan",
        name_zh: "哈萨克斯坦",
        name_zht: "哈薩克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KAZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.35997033076265, 49.21498078062912],
            [86.59877648310336, 48.549181626980626],
            [85.7682328633083, 48.45575063739699],
            [85.72048383987067, 47.452969468773105],
            [85.16429039911324, 47.0009557155161],
            [83.18048383986047, 47.33003123635086],
            [82.45892581576906, 45.539649563166506],
            [81.9470707539181, 45.31702749285312],
            [79.96610639844141, 44.91751699480463],
            [80.86620649610126, 43.18036204688101],
            [80.1801501809943, 42.92006785742694],
            [80.2599902688853, 42.34999929459906],
            [79.64364546094012, 42.49668284765953],
            [79.14217736197978, 42.85609243424952],
            [77.65839196158322, 42.96068553320826],
            [76.00035363149846, 42.98802236589067],
            [75.63696495962202, 42.87789988867668],
            [74.21286583852256, 43.29833934180337],
            [73.64530358266092, 43.09127187760987],
            [73.48975752146237, 42.50089447689132],
            [71.8446382994506, 42.8453954127651],
            [71.18628055205212, 42.70429291439214],
            [70.96231489449914, 42.266154283205495],
            [70.3889648782208, 42.08130768489745],
            [69.07002729683524, 41.38424428971234],
            [68.63248294462002, 40.66868073176681],
            [68.25989586779562, 40.6623245305949],
            [67.98585574735182, 41.13599070898222],
            [66.71404707221652, 41.1684435084615],
            [66.51064863471572, 41.987644151368556],
            [66.02339155463562, 41.99464630794404],
            [66.09801232286509, 42.997660020513095],
            [64.90082441595928, 43.72808055274258],
            [63.18578698105657, 43.650074978198006],
            [62.01330040878625, 43.50447663021565],
            [61.05831994003245, 44.40581696225051],
            [60.23997195825834, 44.78403677019473],
            [58.68998904809581, 45.50001373959873],
            [58.50312706892845, 45.586804307632974],
            [55.928917270741096, 44.99585846615911],
            [55.96819135928291, 41.30864166926936],
            [55.45525109235377, 41.25985911718584],
            [54.75534549339264, 42.043971462566574],
            [54.07941775901495, 42.32410940202083],
            [52.944293247291654, 42.11603424739759],
            [52.50245975119615, 41.78331553808637],
            [52.44633914572722, 42.027150783855575],
            [52.692112257707265, 42.44389537207337],
            [52.50142622255032, 42.7922978785852],
            [51.342427199108215, 43.132974758469345],
            [50.89129194520024, 44.03103363705378],
            [50.339129266161365, 44.284015611338475],
            [50.30564293803627, 44.609835516938915],
            [51.278503452363225, 44.51485423438646],
            [51.31689904155604, 45.2459982366679],
            [52.16738976421573, 45.40839142514511],
            [53.0408764992452, 45.25904653582177],
            [53.220865512917726, 46.234645901059935],
            [53.042736850807785, 46.85300608986449],
            [52.04202273947561, 46.80463694923924],
            [51.191945428274266, 47.048704738953916],
            [50.03408328634248, 46.60898997658222],
            [49.10116000000011, 46.399330000000134],
            [48.59325000000018, 46.56104],
            [48.694733514201744, 47.07562816017793],
            [48.05725, 47.74377],
            [47.31524000000013, 47.7158500000001],
            [46.46644575377627, 48.39415233010493],
            [47.04367150247651, 49.152038886097614],
            [46.75159630716274, 49.35600576435377],
            [47.54948042174931, 50.454698391311126],
            [48.57784142435753, 49.87475962991567],
            [48.70238162618102, 50.60512848571284],
            [50.76664839051216, 51.6927623561599],
            [52.32872358583097, 51.718652248738124],
            [54.532878452376224, 51.02623973245932],
            [55.71694, 50.62171000000018],
            [56.77798000000013, 51.04355],
            [58.36332, 51.06364],
            [59.642282342370606, 50.545442206415714],
            [59.93280724471549, 50.842194118851864],
            [61.337424350840934, 50.79907013610426],
            [61.58800337102417, 51.272658799843214],
            [59.967533807215545, 51.9604204372157],
            [60.92726850774028, 52.44754832621504],
            [60.73999311711458, 52.71998647725775],
            [61.699986199800605, 52.97999644633427],
            [60.978066440683165, 53.66499339457914],
            [61.43660000000017, 54.00625],
            [65.17853356309593, 54.35422781027211],
            [65.66687, 54.60125000000011],
            [68.16910037625883, 54.97039175070432],
            [69.06816694527288, 55.38525014914353],
            [70.86526655465514, 55.169733588270105],
            [71.18013105660941, 54.13328522400826],
            [72.22415001820218, 54.376655381886735],
            [73.5085160663844, 54.0356167669766],
            [73.42567874542044, 53.489810289109755],
            [74.38482000000016, 53.54685000000012],
            [76.89110029491343, 54.49052440044193],
            [76.52517947785475, 54.17700348572714],
            [77.80091556184425, 53.404414984747575],
            [80.03555952344169, 50.86475088154725],
            [80.56844689323549, 51.38833649352847],
            [81.94598554883993, 50.81219594990637],
            [83.38300377801238, 51.069182847693924],
            [83.93511478061885, 50.88924551045358],
            [84.41637739455308, 50.311399644565824],
            [85.11555952346203, 50.11730296487764],
            [85.54126997268247, 49.69285858824816],
            [86.82935672398963, 49.82667470966817],
            [87.35997033076265, 49.21498078062912],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Tajikistan",
        sov_a3: "TJK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Tajikistan",
        adm0_a3: "TJK",
        geou_dif: 0,
        geounit: "Tajikistan",
        gu_a3: "TJK",
        su_dif: 0,
        subunit: "Tajikistan",
        su_a3: "TJK",
        brk_diff: 0,
        name: "Tajikistan",
        name_long: "Tajikistan",
        brk_a3: "TJK",
        brk_name: "Tajikistan",
        brk_group: null,
        abbrev: "Tjk.",
        postal: "TJ",
        formal_en: "Republic of Tajikistan",
        formal_fr: null,
        name_ciawf: "Tajikistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tajikistan",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 9321018,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 8116,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "5. Low income",
        fips_10: "TI",
        iso_a2: "TJ",
        iso_a2_eh: "TJ",
        iso_a3: "TJK",
        iso_a3_eh: "TJK",
        iso_n3: "762",
        iso_n3_eh: "762",
        un_a3: "762",
        wb_a2: "TJ",
        wb_a3: "TJK",
        woe_id: 23424961,
        woe_id_eh: 23424961,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TJK",
        adm0_diff: null,
        adm0_tlc: "TJK",
        adm0_a3_us: "TJK",
        adm0_a3_fr: "TJK",
        adm0_a3_ru: "TJK",
        adm0_a3_es: "TJK",
        adm0_a3_cn: "TJK",
        adm0_a3_tw: "TJK",
        adm0_a3_in: "TJK",
        adm0_a3_np: "TJK",
        adm0_a3_pk: "TJK",
        adm0_a3_de: "TJK",
        adm0_a3_gb: "TJK",
        adm0_a3_br: "TJK",
        adm0_a3_il: "TJK",
        adm0_a3_ps: "TJK",
        adm0_a3_sa: "TJK",
        adm0_a3_eg: "TJK",
        adm0_a3_ma: "TJK",
        adm0_a3_pt: "TJK",
        adm0_a3_ar: "TJK",
        adm0_a3_jp: "TJK",
        adm0_a3_ko: "TJK",
        adm0_a3_vn: "TJK",
        adm0_a3_tr: "TJK",
        adm0_a3_id: "TJK",
        adm0_a3_pl: "TJK",
        adm0_a3_gr: "TJK",
        adm0_a3_it: "TJK",
        adm0_a3_nl: "TJK",
        adm0_a3_se: "TJK",
        adm0_a3_bd: "TJK",
        adm0_a3_ua: "TJK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 72.587276,
        label_y: 38.199835,
        ne_id: 1159321307,
        wikidataid: "Q863",
        name_ar: "طاجيكستان",
        name_bn: "তাজিকিস্তান",
        name_de: "Tadschikistan",
        name_en: "Tajikistan",
        name_es: "Tayikistán",
        name_fa: "تاجیکستان",
        name_fr: "Tadjikistan",
        name_el: "Τατζικιστάν",
        name_he: "טג׳יקיסטן",
        name_hi: "ताजिकिस्तान",
        name_hu: "Tádzsikisztán",
        name_id: "Tajikistan",
        name_it: "Tagikistan",
        name_ja: "タジキスタン",
        name_ko: "타지키스탄",
        name_nl: "Tadzjikistan",
        name_pl: "Tadżykistan",
        name_pt: "Tajiquistão",
        name_ru: "Таджикистан",
        name_sv: "Tadzjikistan",
        name_tr: "Tacikistan",
        name_uk: "Таджикистан",
        name_ur: "تاجکستان",
        name_vi: "Tajikistan",
        name_zh: "塔吉克斯坦",
        name_zht: "塔吉克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TJK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.82999962755952, 37.144994004864685],
            [68.39203250516596, 38.15702525486874],
            [68.17602501818592, 38.901553453113905],
            [67.44221967964131, 39.140143541005486],
            [67.70142866401736, 39.58047842056453],
            [68.53641645698943, 39.53345286717894],
            [69.0116329283455, 40.08615814875667],
            [69.32949466337283, 40.72782440852485],
            [70.66662234892505, 40.960213324541414],
            [70.45815962105962, 40.49649485937029],
            [70.60140669137269, 40.21852733007229],
            [71.01419803252017, 40.24436554621823],
            [70.64801883329997, 39.93575389257117],
            [69.55960981636852, 40.10321137141298],
            [69.46488691597753, 39.5266832545487],
            [70.54916181832562, 39.6041979029865],
            [71.784693637992, 39.27946320246437],
            [73.6753792662548, 39.4312368841056],
            [73.92885216664644, 38.50581533462274],
            [74.25751427602273, 38.60650686294345],
            [74.86481570831683, 38.3788463404816],
            [74.8299857929521, 37.9900070257014],
            [74.98000247589542, 37.419990139305895],
            [73.9486959166465, 37.4215662704908],
            [73.26005577992501, 37.495256862939],
            [72.63688968291729, 37.047558091778356],
            [72.1930408059624, 36.948287665345674],
            [71.8446382994506, 36.73817129164692],
            [71.44869347523024, 37.06564484308052],
            [71.54191775908478, 37.905774441065645],
            [71.23940392444817, 37.953265082341886],
            [71.34813113799026, 38.25890534113216],
            [70.80682050973289, 38.486281643216415],
            [70.3763041523093, 38.13839590102752],
            [70.27057417184014, 37.735164699854025],
            [70.11657840361033, 37.58822276463209],
            [69.51878543485796, 37.60899669041342],
            [69.19627282092438, 37.15114350030743],
            [68.85944583524594, 37.344335842430596],
            [68.13556237170138, 37.02311513930431],
            [67.82999962755952, 37.144994004864685],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mongolia",
        sov_a3: "MNG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mongolia",
        adm0_a3: "MNG",
        geou_dif: 0,
        geounit: "Mongolia",
        gu_a3: "MNG",
        su_dif: 0,
        subunit: "Mongolia",
        su_a3: "MNG",
        brk_diff: 0,
        name: "Mongolia",
        name_long: "Mongolia",
        brk_a3: "MNG",
        brk_name: "Mongolia",
        brk_group: null,
        abbrev: "Mong.",
        postal: "MN",
        formal_en: "Mongolia",
        formal_fr: null,
        name_ciawf: "Mongolia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mongolia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 6,
        pop_est: 3225167,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 13996,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MG",
        iso_a2: "MN",
        iso_a2_eh: "MN",
        iso_a3: "MNG",
        iso_a3_eh: "MNG",
        iso_n3: "496",
        iso_n3_eh: "496",
        un_a3: "496",
        wb_a2: "MN",
        wb_a3: "MNG",
        woe_id: 23424887,
        woe_id_eh: 23424887,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MNG",
        adm0_diff: null,
        adm0_tlc: "MNG",
        adm0_a3_us: "MNG",
        adm0_a3_fr: "MNG",
        adm0_a3_ru: "MNG",
        adm0_a3_es: "MNG",
        adm0_a3_cn: "MNG",
        adm0_a3_tw: "MNG",
        adm0_a3_in: "MNG",
        adm0_a3_np: "MNG",
        adm0_a3_pk: "MNG",
        adm0_a3_de: "MNG",
        adm0_a3_gb: "MNG",
        adm0_a3_br: "MNG",
        adm0_a3_il: "MNG",
        adm0_a3_ps: "MNG",
        adm0_a3_sa: "MNG",
        adm0_a3_eg: "MNG",
        adm0_a3_ma: "MNG",
        adm0_a3_pt: "MNG",
        adm0_a3_ar: "MNG",
        adm0_a3_jp: "MNG",
        adm0_a3_ko: "MNG",
        adm0_a3_vn: "MNG",
        adm0_a3_tr: "MNG",
        adm0_a3_id: "MNG",
        adm0_a3_pl: "MNG",
        adm0_a3_gr: "MNG",
        adm0_a3_it: "MNG",
        adm0_a3_nl: "MNG",
        adm0_a3_se: "MNG",
        adm0_a3_bd: "MNG",
        adm0_a3_ua: "MNG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 104.150405,
        label_y: 45.997488,
        ne_id: 1159321071,
        wikidataid: "Q711",
        name_ar: "منغوليا",
        name_bn: "মঙ্গোলিয়া",
        name_de: "Mongolei",
        name_en: "Mongolia",
        name_es: "Mongolia",
        name_fa: "مغولستان",
        name_fr: "Mongolie",
        name_el: "Μογγολία",
        name_he: "מונגוליה",
        name_hi: "मंगोलिया",
        name_hu: "Mongólia",
        name_id: "Mongolia",
        name_it: "Mongolia",
        name_ja: "モンゴル国",
        name_ko: "몽골",
        name_nl: "Mongolië",
        name_pl: "Mongolia",
        name_pt: "Mongólia",
        name_ru: "Монголия",
        name_sv: "Mongoliet",
        name_tr: "Moğolistan",
        name_uk: "Монголія",
        name_ur: "منگولیا",
        name_vi: "Mông Cổ",
        name_zh: "蒙古国",
        name_zht: "蒙古國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MNG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.75126427607671, 49.297197984405486],
            [88.80556684769552, 49.47052073831242],
            [90.71366743364068, 50.33181183532109],
            [92.23471154171968, 50.80217072204172],
            [93.10421, 50.49529],
            [94.14756635943559, 50.48053660745717],
            [94.81594933469873, 50.01343333597085],
            [95.8140200000002, 49.97746000000012],
            [97.25976000000014, 49.72605],
            [98.23176150919156, 50.422400621128745],
            [97.82573978067431, 51.01099518493318],
            [98.86149051310034, 52.04736603454669],
            [99.98173221232354, 51.63400625264399],
            [100.88948042196262, 51.51685578063832],
            [102.06521, 51.25991],
            [102.25589, 50.51056000000011],
            [103.67654544476022, 50.089966132195116],
            [104.62158, 50.27532000000019],
            [105.88659142458675, 50.406019192092224],
            [106.8888041524553, 50.27429596618032],
            [107.86817589725095, 49.793705145865815],
            [108.47516727095129, 49.28254771585074],
            [109.40244917199664, 49.292960516957635],
            [110.66201053267878, 49.13012807880585],
            [111.58123091028662, 49.37796824807769],
            [112.89773969935436, 49.54356537535699],
            [114.36245649623527, 50.24830272073741],
            [114.96210981655018, 50.140247300815126],
            [115.48569542853136, 49.80517731383475],
            [116.67880089728612, 49.888531399121405],
            [116.19180219936757, 49.134598090199106],
            [115.48528201707306, 48.13538259540344],
            [115.74283735561579, 47.72654450132629],
            [116.30895267137323, 47.85341014260284],
            [117.29550744025741, 47.69770905210743],
            [118.06414269416672, 48.06673045510369],
            [118.86657433479495, 47.74706004494617],
            [119.7728239278975, 47.04805878355013],
            [119.66326989143876, 46.69267995867892],
            [118.87432579963873, 46.80541209572365],
            [117.42170128791419, 46.67273285581426],
            [116.71786828009886, 46.38820241961521],
            [115.98509647020009, 45.727235012386004],
            [114.46033165899607, 45.339816799493825],
            [113.46390669154417, 44.80889313412712],
            [112.43606245325881, 45.01164561622429],
            [111.8733061056003, 45.10207937273506],
            [111.34837690637946, 44.45744171811009],
            [111.66773725794323, 44.07317576758771],
            [111.82958784388137, 43.74311839453952],
            [111.12968224492023, 43.40683401140015],
            [110.41210330611528, 42.87123362891103],
            [109.24359581913146, 42.5194463160841],
            [107.74477257693795, 42.48151581478187],
            [106.12931562706169, 42.13432770442891],
            [104.96499393109347, 41.59740957291635],
            [104.52228193564899, 41.908346666016556],
            [103.31227827353482, 41.9074681666676],
            [101.83304039917994, 42.51487295182628],
            [100.84586551310827, 42.66380442969145],
            [99.51581749878004, 42.524691473961724],
            [97.45175744017801, 42.74888967546002],
            [96.34939578652781, 42.725635280928685],
            [95.76245486855669, 43.319449164394605],
            [95.30687544147153, 44.24133087826547],
            [94.68892866412533, 44.35233185482842],
            [93.4807336771413, 44.975472113619965],
            [92.13389082231822, 45.11507599545646],
            [90.9455395853343, 45.28607330991028],
            [90.58576826371828, 45.71971609148753],
            [90.97080936072501, 46.88814606382293],
            [90.28082563676392, 47.69354909930793],
            [88.85429772334676, 48.069081732772965],
            [88.01383222855173, 48.599462795600616],
            [87.75126427607671, 49.297197984405486],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Vietnam",
        sov_a3: "VNM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Vietnam",
        adm0_a3: "VNM",
        geou_dif: 0,
        geounit: "Vietnam",
        gu_a3: "VNM",
        su_dif: 0,
        subunit: "Vietnam",
        su_a3: "VNM",
        brk_diff: 0,
        name: "Vietnam",
        name_long: "Vietnam",
        brk_a3: "VNM",
        brk_name: "Vietnam",
        brk_group: null,
        abbrev: "Viet.",
        postal: "VN",
        formal_en: "Socialist Republic of Vietnam",
        formal_fr: null,
        name_ciawf: "Vietnam",
        note_adm0: null,
        note_brk: null,
        name_sort: "Vietnam",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 96462106,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 261921,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "VM",
        iso_a2: "VN",
        iso_a2_eh: "VN",
        iso_a3: "VNM",
        iso_a3_eh: "VNM",
        iso_n3: "704",
        iso_n3_eh: "704",
        un_a3: "704",
        wb_a2: "VN",
        wb_a3: "VNM",
        woe_id: 23424984,
        woe_id_eh: 23424984,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VNM",
        adm0_diff: null,
        adm0_tlc: "VNM",
        adm0_a3_us: "VNM",
        adm0_a3_fr: "VNM",
        adm0_a3_ru: "VNM",
        adm0_a3_es: "VNM",
        adm0_a3_cn: "VNM",
        adm0_a3_tw: "VNM",
        adm0_a3_in: "VNM",
        adm0_a3_np: "VNM",
        adm0_a3_pk: "VNM",
        adm0_a3_de: "VNM",
        adm0_a3_gb: "VNM",
        adm0_a3_br: "VNM",
        adm0_a3_il: "VNM",
        adm0_a3_ps: "VNM",
        adm0_a3_sa: "VNM",
        adm0_a3_eg: "VNM",
        adm0_a3_ma: "VNM",
        adm0_a3_pt: "VNM",
        adm0_a3_ar: "VNM",
        adm0_a3_jp: "VNM",
        adm0_a3_ko: "VNM",
        adm0_a3_vn: "VNM",
        adm0_a3_tr: "VNM",
        adm0_a3_id: "VNM",
        adm0_a3_pl: "VNM",
        adm0_a3_gr: "VNM",
        adm0_a3_it: "VNM",
        adm0_a3_nl: "VNM",
        adm0_a3_se: "VNM",
        adm0_a3_bd: "VNM",
        adm0_a3_ua: "VNM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 105.387292,
        label_y: 21.715416,
        ne_id: 1159321417,
        wikidataid: "Q881",
        name_ar: "فيتنام",
        name_bn: "ভিয়েতনাম",
        name_de: "Vietnam",
        name_en: "Vietnam",
        name_es: "Vietnam",
        name_fa: "ویتنام",
        name_fr: "Viêt Nam",
        name_el: "Βιετνάμ",
        name_he: "וייטנאם",
        name_hi: "वियतनाम",
        name_hu: "Vietnám",
        name_id: "Vietnam",
        name_it: "Vietnam",
        name_ja: "ベトナム",
        name_ko: "베트남",
        name_nl: "Vietnam",
        name_pl: "Wietnam",
        name_pt: "Vietname",
        name_ru: "Вьетнам",
        name_sv: "Vietnam",
        name_tr: "Vietnam",
        name_uk: "В'єтнам",
        name_ur: "ویتنام",
        name_vi: "Việt Nam",
        name_zh: "越南",
        name_zht: "越南",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VNM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.33433475140347, 10.48654368737523],
            [105.19991499229235, 10.889309800658097],
            [106.24967003786946, 10.961811835163587],
            [105.81052371625313, 11.567614650921229],
            [107.49140302941089, 12.337205918827948],
            [107.61454796756243, 13.535530707244206],
            [107.38272749230109, 14.202440904186972],
            [107.5645251811039, 15.20217316330556],
            [107.3127059265456, 15.90853831630318],
            [106.55600792849569, 16.604283962464805],
            [105.92576216026403, 17.48531545660896],
            [105.09459842328152, 18.66697459561108],
            [103.89653201702671, 19.265180975821806],
            [104.18338789267894, 19.62466807706022],
            [104.8225736836971, 19.886641750563882],
            [104.43500044150805, 20.75873322192153],
            [103.20386111858645, 20.76656220141375],
            [102.75489627483466, 21.675137233969465],
            [102.17043582561358, 22.464753119389304],
            [102.7069922221001, 22.708795070887675],
            [103.50451460166056, 22.70375661873921],
            [104.47685835166448, 22.819150092046968],
            [105.32920942588663, 23.352063300056912],
            [105.81124718630522, 22.976892401617903],
            [106.72540327354847, 22.79426788989842],
            [106.56727339073532, 22.21820486092477],
            [107.04342003787264, 21.811898912029914],
            [108.05018029178294, 21.55237986906012],
            [106.7150679870901, 20.69685069425202],
            [105.88168216351903, 19.752050482659698],
            [105.66200564984631, 19.05816518806057],
            [106.42681684776602, 18.004120998603227],
            [107.36195356651974, 16.697456569887052],
            [108.26949507042963, 16.07974233648615],
            [108.87710656131748, 15.27669057867044],
            [109.33526981001722, 13.426028347217724],
            [109.20013593957398, 11.666859239137764],
            [108.36612999881545, 11.008320624226272],
            [107.22092858279524, 10.364483954301832],
            [106.40511274620343, 9.53083974856932],
            [105.15826378786511, 8.599759629750494],
            [104.79518517458239, 9.241038316276502],
            [105.07620161338562, 9.918490505406808],
            [104.33433475140347, 10.48654368737523],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Cambodia",
        sov_a3: "KHM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cambodia",
        adm0_a3: "KHM",
        geou_dif: 0,
        geounit: "Cambodia",
        gu_a3: "KHM",
        su_dif: 0,
        subunit: "Cambodia",
        su_a3: "KHM",
        brk_diff: 0,
        name: "Cambodia",
        name_long: "Cambodia",
        brk_a3: "KHM",
        brk_name: "Cambodia",
        brk_group: null,
        abbrev: "Camb.",
        postal: "KH",
        formal_en: "Kingdom of Cambodia",
        formal_fr: null,
        name_ciawf: "Cambodia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cambodia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 5,
        pop_est: 16486542,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 27089,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CB",
        iso_a2: "KH",
        iso_a2_eh: "KH",
        iso_a3: "KHM",
        iso_a3_eh: "KHM",
        iso_n3: "116",
        iso_n3_eh: "116",
        un_a3: "116",
        wb_a2: "KH",
        wb_a3: "KHM",
        woe_id: 23424776,
        woe_id_eh: 23424776,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KHM",
        adm0_diff: null,
        adm0_tlc: "KHM",
        adm0_a3_us: "KHM",
        adm0_a3_fr: "KHM",
        adm0_a3_ru: "KHM",
        adm0_a3_es: "KHM",
        adm0_a3_cn: "KHM",
        adm0_a3_tw: "KHM",
        adm0_a3_in: "KHM",
        adm0_a3_np: "KHM",
        adm0_a3_pk: "KHM",
        adm0_a3_de: "KHM",
        adm0_a3_gb: "KHM",
        adm0_a3_br: "KHM",
        adm0_a3_il: "KHM",
        adm0_a3_ps: "KHM",
        adm0_a3_sa: "KHM",
        adm0_a3_eg: "KHM",
        adm0_a3_ma: "KHM",
        adm0_a3_pt: "KHM",
        adm0_a3_ar: "KHM",
        adm0_a3_jp: "KHM",
        adm0_a3_ko: "KHM",
        adm0_a3_vn: "KHM",
        adm0_a3_tr: "KHM",
        adm0_a3_id: "KHM",
        adm0_a3_pl: "KHM",
        adm0_a3_gr: "KHM",
        adm0_a3_it: "KHM",
        adm0_a3_nl: "KHM",
        adm0_a3_se: "KHM",
        adm0_a3_bd: "KHM",
        adm0_a3_ua: "KHM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 104.50487,
        label_y: 12.647584,
        ne_id: 1159320979,
        wikidataid: "Q424",
        name_ar: "كمبوديا",
        name_bn: "কম্বোডিয়া",
        name_de: "Kambodscha",
        name_en: "Cambodia",
        name_es: "Camboya",
        name_fa: "کامبوج",
        name_fr: "Cambodge",
        name_el: "Καμπότζη",
        name_he: "קמבודיה",
        name_hi: "कम्बोडिया",
        name_hu: "Kambodzsa",
        name_id: "Kamboja",
        name_it: "Cambogia",
        name_ja: "カンボジア",
        name_ko: "캄보디아",
        name_nl: "Cambodja",
        name_pl: "Kambodża",
        name_pt: "Camboja",
        name_ru: "Камбоджа",
        name_sv: "Kambodja",
        name_tr: "Kamboçya",
        name_uk: "Камбоджа",
        name_ur: "کمبوڈیا",
        name_vi: "Campuchia",
        name_zh: "柬埔寨",
        name_zht: "柬埔寨",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KHM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.5849324890267, 12.186594956913282],
            [102.34809939983302, 13.394247341358223],
            [102.98842207236163, 14.225721136934467],
            [104.28141808473661, 14.416743068901367],
            [105.21877689007889, 14.273211778210694],
            [106.04394616091552, 13.881091009979956],
            [106.49637332563088, 14.570583807834282],
            [107.38272749230109, 14.202440904186972],
            [107.61454796756243, 13.535530707244206],
            [107.49140302941089, 12.337205918827948],
            [105.81052371625313, 11.567614650921229],
            [106.24967003786946, 10.961811835163587],
            [105.19991499229235, 10.889309800658097],
            [104.33433475140347, 10.48654368737523],
            [103.4972799011397, 10.632555446815928],
            [103.09068973186726, 11.153660590047165],
            [102.5849324890267, 12.186594956913282],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "United Arab Emirates",
        sov_a3: "ARE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "United Arab Emirates",
        adm0_a3: "ARE",
        geou_dif: 0,
        geounit: "United Arab Emirates",
        gu_a3: "ARE",
        su_dif: 0,
        subunit: "United Arab Emirates",
        su_a3: "ARE",
        brk_diff: 0,
        name: "United Arab Emirates",
        name_long: "United Arab Emirates",
        brk_a3: "ARE",
        brk_name: "United Arab Emirates",
        brk_group: null,
        abbrev: "U.A.E.",
        postal: "AE",
        formal_en: "United Arab Emirates",
        formal_fr: null,
        name_ciawf: "United Arab Emirates",
        note_adm0: null,
        note_brk: null,
        name_sort: "United Arab Emirates",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 3,
        pop_est: 9770529,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 421142,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "AE",
        iso_a2: "AE",
        iso_a2_eh: "AE",
        iso_a3: "ARE",
        iso_a3_eh: "ARE",
        iso_n3: "784",
        iso_n3_eh: "784",
        un_a3: "784",
        wb_a2: "AE",
        wb_a3: "ARE",
        woe_id: 23424738,
        woe_id_eh: 23424738,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ARE",
        adm0_diff: null,
        adm0_tlc: "ARE",
        adm0_a3_us: "ARE",
        adm0_a3_fr: "ARE",
        adm0_a3_ru: "ARE",
        adm0_a3_es: "ARE",
        adm0_a3_cn: "ARE",
        adm0_a3_tw: "ARE",
        adm0_a3_in: "ARE",
        adm0_a3_np: "ARE",
        adm0_a3_pk: "ARE",
        adm0_a3_de: "ARE",
        adm0_a3_gb: "ARE",
        adm0_a3_br: "ARE",
        adm0_a3_il: "ARE",
        adm0_a3_ps: "ARE",
        adm0_a3_sa: "ARE",
        adm0_a3_eg: "ARE",
        adm0_a3_ma: "ARE",
        adm0_a3_pt: "ARE",
        adm0_a3_ar: "ARE",
        adm0_a3_jp: "ARE",
        adm0_a3_ko: "ARE",
        adm0_a3_vn: "ARE",
        adm0_a3_tr: "ARE",
        adm0_a3_id: "ARE",
        adm0_a3_pl: "ARE",
        adm0_a3_gr: "ARE",
        adm0_a3_it: "ARE",
        adm0_a3_nl: "ARE",
        adm0_a3_se: "ARE",
        adm0_a3_bd: "ARE",
        adm0_a3_ua: "ARE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 20,
        long_len: 20,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 54.547256,
        label_y: 23.466285,
        ne_id: 1159320329,
        wikidataid: "Q878",
        name_ar: "الإمارات العربية المتحدة",
        name_bn: "সংযুক্ত আরব আমিরাত",
        name_de: "Vereinigte Arabische Emirate",
        name_en: "United Arab Emirates",
        name_es: "Emiratos Árabes Unidos",
        name_fa: "امارات متحده عربی",
        name_fr: "Émirats arabes unis",
        name_el: "Ηνωμένα Αραβικά Εμιράτα",
        name_he: "איחוד האמירויות הערביות",
        name_hi: "संयुक्त अरब अमीरात",
        name_hu: "Egyesült Arab Emírségek",
        name_id: "Uni Emirat Arab",
        name_it: "Emirati Arabi Uniti",
        name_ja: "アラブ首長国連邦",
        name_ko: "아랍에미리트",
        name_nl: "Verenigde Arabische Emiraten",
        name_pl: "Zjednoczone Emiraty Arabskie",
        name_pt: "Emirados Árabes Unidos",
        name_ru: "Объединённые Арабские Эмираты",
        name_sv: "Förenade Arabemiraten",
        name_tr: "Birleşik Arap Emirlikleri",
        name_uk: "Об'єднані Арабські Емірати",
        name_ur: "متحدہ عرب امارات",
        name_vi: "Các Tiểu vương quốc Ả Rập Thống nhất",
        name_zh: "阿拉伯联合酋长国",
        name_zht: "阿拉伯聯合大公國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ARE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.57951867046327, 24.245497137951105],
            [51.757440626844186, 24.29407298430547],
            [51.79438927593287, 24.019826158132506],
            [52.57708051942561, 24.177439276622707],
            [53.404006788960146, 24.15131684009917],
            [54.00800092958758, 24.121757920828216],
            [54.69302371604863, 24.79789236093509],
            [55.43902469261414, 25.43914520924494],
            [56.07082075381456, 26.05546417897398],
            [56.261041701080956, 25.71460643157677],
            [56.396847365144005, 24.924732163995486],
            [55.88623253766801, 24.920830593357447],
            [55.804118686756226, 24.269604193615265],
            [55.98121382022046, 24.13054291431783],
            [55.52863162620824, 23.933604030853502],
            [55.525841098864475, 23.524869289640932],
            [55.234489373602884, 23.110992743415324],
            [55.208341098863194, 22.708329982997046],
            [55.006803012924905, 22.496947536707136],
            [52.000733270074335, 23.00115448657894],
            [51.61770755392698, 24.01421926522883],
            [51.57951867046327, 24.245497137951105],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Georgia",
        sov_a3: "GEO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Georgia",
        adm0_a3: "GEO",
        geou_dif: 0,
        geounit: "Georgia",
        gu_a3: "GEO",
        su_dif: 0,
        subunit: "Georgia",
        su_a3: "GEO",
        brk_diff: 0,
        name: "Georgia",
        name_long: "Georgia",
        brk_a3: "GEO",
        brk_name: "Georgia",
        brk_group: null,
        abbrev: "Geo.",
        postal: "GE",
        formal_en: "Georgia",
        formal_fr: null,
        name_ciawf: "Georgia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Georgia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 2,
        pop_est: 3720382,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 17477,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GG",
        iso_a2: "GE",
        iso_a2_eh: "GE",
        iso_a3: "GEO",
        iso_a3_eh: "GEO",
        iso_n3: "268",
        iso_n3_eh: "268",
        un_a3: "268",
        wb_a2: "GE",
        wb_a3: "GEO",
        woe_id: 23424823,
        woe_id_eh: 23424823,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GEO",
        adm0_diff: null,
        adm0_tlc: "GEO",
        adm0_a3_us: "GEO",
        adm0_a3_fr: "GEO",
        adm0_a3_ru: "GEO",
        adm0_a3_es: "GEO",
        adm0_a3_cn: "GEO",
        adm0_a3_tw: "GEO",
        adm0_a3_in: "GEO",
        adm0_a3_np: "GEO",
        adm0_a3_pk: "GEO",
        adm0_a3_de: "GEO",
        adm0_a3_gb: "GEO",
        adm0_a3_br: "GEO",
        adm0_a3_il: "GEO",
        adm0_a3_ps: "GEO",
        adm0_a3_sa: "GEO",
        adm0_a3_eg: "GEO",
        adm0_a3_ma: "GEO",
        adm0_a3_pt: "GEO",
        adm0_a3_ar: "GEO",
        adm0_a3_jp: "GEO",
        adm0_a3_ko: "GEO",
        adm0_a3_vn: "GEO",
        adm0_a3_tr: "GEO",
        adm0_a3_id: "GEO",
        adm0_a3_pl: "GEO",
        adm0_a3_gr: "GEO",
        adm0_a3_it: "GEO",
        adm0_a3_nl: "GEO",
        adm0_a3_se: "GEO",
        adm0_a3_bd: "GEO",
        adm0_a3_ua: "GEO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 43.735724,
        label_y: 41.870087,
        ne_id: 1159320779,
        wikidataid: "Q230",
        name_ar: "جورجيا",
        name_bn: "জর্জিয়া",
        name_de: "Georgien",
        name_en: "Georgia",
        name_es: "Georgia",
        name_fa: "گرجستان",
        name_fr: "Géorgie",
        name_el: "Γεωργία",
        name_he: "גאורגיה",
        name_hi: "जॉर्जिया",
        name_hu: "Grúzia",
        name_id: "Georgia",
        name_it: "Georgia",
        name_ja: "ジョージア",
        name_ko: "조지아",
        name_nl: "Georgië",
        name_pl: "Gruzja",
        name_pt: "Geórgia",
        name_ru: "Грузия",
        name_sv: "Georgien",
        name_tr: "Gürcistan",
        name_uk: "Грузія",
        name_ur: "جارجیا",
        name_vi: "Gruzia",
        name_zh: "格鲁吉亚",
        name_zht: "喬治亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GEO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.955008579270924, 43.43499766699922],
            [40.07696495947977, 43.553104153002316],
            [40.922190000000114, 43.38215000000014],
            [42.39440000000013, 43.2203],
            [43.75599000000011, 42.74083],
            [43.931210000000135, 42.55496000000011],
            [44.537622918481986, 42.71199270280363],
            [45.47027916848572, 42.50278066666998],
            [45.7764, 42.09244000000018],
            [46.404950799348825, 41.860675157227305],
            [46.14543175637902, 41.72280243587258],
            [46.63790815612058, 41.181672675128226],
            [46.50163740416693, 41.06444468847411],
            [45.96260053893039, 41.123872585609774],
            [45.217426385281584, 41.41145193131405],
            [44.97248009621808, 41.248128567055595],
            [43.58274580259273, 41.09214325618257],
            [42.61954878110449, 41.58317271581994],
            [41.55408410011066, 41.53565623632757],
            [41.70317060727271, 41.96294281673292],
            [41.45347008643839, 42.64512339941794],
            [40.87546919125379, 43.013628038091284],
            [40.32139448422032, 43.128633938156845],
            [39.955008579270924, 43.43499766699922],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Turkey",
        sov_a3: "TUR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Turkey",
        adm0_a3: "TUR",
        geou_dif: 0,
        geounit: "Turkey",
        gu_a3: "TUR",
        su_dif: 0,
        subunit: "Turkey",
        su_a3: "TUR",
        brk_diff: 0,
        name: "Turkey",
        name_long: "Turkey",
        brk_a3: "TUR",
        brk_name: "Turkey",
        brk_group: null,
        abbrev: "Tur.",
        postal: "TR",
        formal_en: "Republic of Turkey",
        formal_fr: null,
        name_ciawf: "Turkey",
        note_adm0: null,
        note_brk: null,
        name_sort: "Turkey",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 4,
        pop_est: 83429615,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 761425,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "3. Upper middle income",
        fips_10: "TU",
        iso_a2: "TR",
        iso_a2_eh: "TR",
        iso_a3: "TUR",
        iso_a3_eh: "TUR",
        iso_n3: "792",
        iso_n3_eh: "792",
        un_a3: "792",
        wb_a2: "TR",
        wb_a3: "TUR",
        woe_id: 23424969,
        woe_id_eh: 23424969,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TUR",
        adm0_diff: null,
        adm0_tlc: "TUR",
        adm0_a3_us: "TUR",
        adm0_a3_fr: "TUR",
        adm0_a3_ru: "TUR",
        adm0_a3_es: "TUR",
        adm0_a3_cn: "TUR",
        adm0_a3_tw: "TUR",
        adm0_a3_in: "TUR",
        adm0_a3_np: "TUR",
        adm0_a3_pk: "TUR",
        adm0_a3_de: "TUR",
        adm0_a3_gb: "TUR",
        adm0_a3_br: "TUR",
        adm0_a3_il: "TUR",
        adm0_a3_ps: "TUR",
        adm0_a3_sa: "TUR",
        adm0_a3_eg: "TUR",
        adm0_a3_ma: "TUR",
        adm0_a3_pt: "TUR",
        adm0_a3_ar: "TUR",
        adm0_a3_jp: "TUR",
        adm0_a3_ko: "TUR",
        adm0_a3_vn: "TUR",
        adm0_a3_tr: "TUR",
        adm0_a3_id: "TUR",
        adm0_a3_pl: "TUR",
        adm0_a3_gr: "TUR",
        adm0_a3_it: "TUR",
        adm0_a3_nl: "TUR",
        adm0_a3_se: "TUR",
        adm0_a3_bd: "TUR",
        adm0_a3_ua: "TUR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 34.508268,
        label_y: 39.345388,
        ne_id: 1159321331,
        wikidataid: "Q43",
        name_ar: "تركيا",
        name_bn: "তুরস্ক",
        name_de: "Türkei",
        name_en: "Turkey",
        name_es: "Turquía",
        name_fa: "ترکیه",
        name_fr: "Turquie",
        name_el: "Τουρκία",
        name_he: "טורקיה",
        name_hi: "तुर्की",
        name_hu: "Törökország",
        name_id: "Turki",
        name_it: "Turchia",
        name_ja: "トルコ",
        name_ko: "터키",
        name_nl: "Turkije",
        name_pl: "Turcja",
        name_pt: "Turquia",
        name_ru: "Турция",
        name_sv: "Turkiet",
        name_tr: "Türkiye",
        name_uk: "Туреччина",
        name_ur: "ترکی",
        name_vi: "Thổ Nhĩ Kỳ",
        name_zh: "土耳其",
        name_zht: "土耳其",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TUR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [44.77267710159504, 37.17043692561684],
              [44.29345177590287, 37.0015143906063],
              [43.9422587420473, 37.25622752537295],
              [42.77912560402183, 37.385263576805755],
              [42.34959109881177, 37.2298725449041],
              [41.21208947120305, 37.074352321921694],
              [40.67325931169569, 37.09127635349729],
              [39.52258019385255, 36.71605377862599],
              [38.6998913917659, 36.71292735447234],
              [38.1677274920242, 36.90121043552777],
              [37.06676110204583, 36.62303620050062],
              [36.7394942563414, 36.81752045343109],
              [36.6853890317318, 36.259699205056464],
              [36.417550083163036, 36.04061697035506],
              [36.149762811026534, 35.82153473565367],
              [35.782084995269855, 36.27499542901485],
              [36.160821567537, 36.65060557712833],
              [35.55093631362831, 36.565442816711325],
              [34.714553256984345, 36.7955321314909],
              [34.026894972476384, 36.219960028624016],
              [32.509158156064075, 36.107563788389186],
              [31.699595167779567, 36.64427521417258],
              [30.621624790171097, 36.6778648951623],
              [30.391096225717064, 36.26298065850699],
              [29.69997562024554, 36.144357408180994],
              [28.732902866335422, 36.67683136651647],
              [27.641186557737317, 36.65882212986274],
              [27.048767937943264, 37.653360907536],
              [26.318218214633045, 38.208133246405396],
              [26.804700148228733, 38.985760199533516],
              [26.17078535330433, 39.463612168936464],
              [27.28001997244939, 40.42001373957831],
              [28.81997765474722, 40.46001129817221],
              [29.240003696415584, 41.21999074967267],
              [31.14593387220444, 41.08762156835702],
              [32.34797936374571, 41.736264146484615],
              [33.51328291192746, 42.01896006933731],
              [35.167703891751785, 42.040224921225416],
              [36.9131270688421, 41.33535838476427],
              [38.34766482926452, 40.94858612727573],
              [39.512606642420195, 41.10276276301855],
              [40.373432651538224, 41.01367259374735],
              [41.55408410011066, 41.53565623632757],
              [42.61954878110449, 41.58317271581994],
              [43.58274580259273, 41.09214325618257],
              [43.75265791196841, 40.74020091405876],
              [43.65643639504094, 40.253563951166186],
              [44.4000085792887, 40.00500031184228],
              [44.79398969908195, 39.71300263117705],
              [44.10922529478234, 39.4281362981681],
              [44.421402622257546, 38.28128123631454],
              [44.22575564960053, 37.97158437758935],
              [44.77267, 37.17045],
              [44.77267710159504, 37.17043692561684],
            ],
          ],
          [
            [
              [26.1170418637208, 41.82690460872456],
              [27.13573937349048, 42.14148489030134],
              [27.99672041190539, 42.00735871028779],
              [28.115524529744416, 41.62288605403624],
              [28.988442824018733, 41.29993419042819],
              [28.806438429486743, 41.05496206314856],
              [27.61901736828409, 40.99982330989314],
              [27.19237674328238, 40.69056570084242],
              [26.35800906749779, 40.151993923496505],
              [26.04335127127257, 40.61775360774317],
              [26.05694217296534, 40.82412344010076],
              [26.294602085075667, 40.93626129817413],
              [26.60419559093623, 41.562114569661105],
              [26.1170418637208, 41.82690460872456],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Laos",
        sov_a3: "LAO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Laos",
        adm0_a3: "LAO",
        geou_dif: 0,
        geounit: "Laos",
        gu_a3: "LAO",
        su_dif: 0,
        subunit: "Laos",
        su_a3: "LAO",
        brk_diff: 0,
        name: "Laos",
        name_long: "Lao PDR",
        brk_a3: "LAO",
        brk_name: "Laos",
        brk_group: null,
        abbrev: "Laos",
        postal: "LA",
        formal_en: "Lao People's Democratic Republic",
        formal_fr: null,
        name_ciawf: "Laos",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lao PDR",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 7169455,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 18173,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "LA",
        iso_a2: "LA",
        iso_a2_eh: "LA",
        iso_a3: "LAO",
        iso_a3_eh: "LAO",
        iso_n3: "418",
        iso_n3_eh: "418",
        un_a3: "418",
        wb_a2: "LA",
        wb_a3: "LAO",
        woe_id: 23424872,
        woe_id_eh: 23424872,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LAO",
        adm0_diff: null,
        adm0_tlc: "LAO",
        adm0_a3_us: "LAO",
        adm0_a3_fr: "LAO",
        adm0_a3_ru: "LAO",
        adm0_a3_es: "LAO",
        adm0_a3_cn: "LAO",
        adm0_a3_tw: "LAO",
        adm0_a3_in: "LAO",
        adm0_a3_np: "LAO",
        adm0_a3_pk: "LAO",
        adm0_a3_de: "LAO",
        adm0_a3_gb: "LAO",
        adm0_a3_br: "LAO",
        adm0_a3_il: "LAO",
        adm0_a3_ps: "LAO",
        adm0_a3_sa: "LAO",
        adm0_a3_eg: "LAO",
        adm0_a3_ma: "LAO",
        adm0_a3_pt: "LAO",
        adm0_a3_ar: "LAO",
        adm0_a3_jp: "LAO",
        adm0_a3_ko: "LAO",
        adm0_a3_vn: "LAO",
        adm0_a3_tr: "LAO",
        adm0_a3_id: "LAO",
        adm0_a3_pl: "LAO",
        adm0_a3_gr: "LAO",
        adm0_a3_it: "LAO",
        adm0_a3_nl: "LAO",
        adm0_a3_se: "LAO",
        adm0_a3_bd: "LAO",
        adm0_a3_ua: "LAO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 102.533912,
        label_y: 19.431821,
        ne_id: 1159321011,
        wikidataid: "Q819",
        name_ar: "لاوس",
        name_bn: "লাওস",
        name_de: "Laos",
        name_en: "Laos",
        name_es: "Laos",
        name_fa: "لائوس",
        name_fr: "Laos",
        name_el: "Λάος",
        name_he: "לאוס",
        name_hi: "लाओस",
        name_hu: "Laosz",
        name_id: "Laos",
        name_it: "Laos",
        name_ja: "ラオス",
        name_ko: "라오스",
        name_nl: "Laos",
        name_pl: "Laos",
        name_pt: "Laos",
        name_ru: "Лаос",
        name_sv: "Laos",
        name_tr: "Laos",
        name_uk: "Лаос",
        name_ur: "لاؤس",
        name_vi: "Lào",
        name_zh: "老挝",
        name_zht: "寮國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LAO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [107.38272749230109, 14.202440904186972],
            [106.49637332563088, 14.570583807834282],
            [106.04394616091552, 13.881091009979956],
            [105.21877689007889, 14.273211778210694],
            [105.54433841351769, 14.723933620660418],
            [105.58903852745016, 15.570316066952858],
            [104.7793205098688, 16.44186493577145],
            [104.7169470560925, 17.42885895433008],
            [103.9564766784853, 18.24095408779688],
            [103.20019209189374, 18.309632066312773],
            [102.99870568238771, 17.9616946476916],
            [102.41300499879162, 17.932781683824288],
            [102.11359175009248, 18.109101670804165],
            [101.05954756063517, 17.51249725999449],
            [101.03593143107777, 18.408928330961615],
            [101.2820146016517, 19.462584947176765],
            [100.60629357300316, 19.508344427971224],
            [100.54888105672688, 20.109237982661128],
            [100.11598758341785, 20.417849636308187],
            [100.32910119018953, 20.786121731036232],
            [101.18000532430754, 21.436572984294028],
            [101.27002566935997, 21.201651923095184],
            [101.80311974488293, 21.17436676684507],
            [101.65201785686152, 22.318198757409547],
            [102.17043582561358, 22.464753119389304],
            [102.75489627483466, 21.675137233969465],
            [103.20386111858645, 20.76656220141375],
            [104.43500044150805, 20.75873322192153],
            [104.8225736836971, 19.886641750563882],
            [104.18338789267894, 19.62466807706022],
            [103.89653201702671, 19.265180975821806],
            [105.09459842328152, 18.66697459561108],
            [105.92576216026403, 17.48531545660896],
            [106.55600792849569, 16.604283962464805],
            [107.3127059265456, 15.90853831630318],
            [107.5645251811039, 15.20217316330556],
            [107.38272749230109, 14.202440904186972],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Kyrgyzstan",
        sov_a3: "KGZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kyrgyzstan",
        adm0_a3: "KGZ",
        geou_dif: 0,
        geounit: "Kyrgyzstan",
        gu_a3: "KGZ",
        su_dif: 0,
        subunit: "Kyrgyzstan",
        su_a3: "KGZ",
        brk_diff: 0,
        name: "Kyrgyzstan",
        name_long: "Kyrgyzstan",
        brk_a3: "KGZ",
        brk_name: "Kyrgyzstan",
        brk_group: null,
        abbrev: "Kgz.",
        postal: "KG",
        formal_en: "Kyrgyz Republic",
        formal_fr: null,
        name_ciawf: "Kyrgyzstan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kyrgyz Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 7,
        mapcolor9: 7,
        mapcolor13: 6,
        pop_est: 6456900,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 8454,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "5. Low income",
        fips_10: "KG",
        iso_a2: "KG",
        iso_a2_eh: "KG",
        iso_a3: "KGZ",
        iso_a3_eh: "KGZ",
        iso_n3: "417",
        iso_n3_eh: "417",
        un_a3: "417",
        wb_a2: "KG",
        wb_a3: "KGZ",
        woe_id: 23424864,
        woe_id_eh: 23424864,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KGZ",
        adm0_diff: null,
        adm0_tlc: "KGZ",
        adm0_a3_us: "KGZ",
        adm0_a3_fr: "KGZ",
        adm0_a3_ru: "KGZ",
        adm0_a3_es: "KGZ",
        adm0_a3_cn: "KGZ",
        adm0_a3_tw: "KGZ",
        adm0_a3_in: "KGZ",
        adm0_a3_np: "KGZ",
        adm0_a3_pk: "KGZ",
        adm0_a3_de: "KGZ",
        adm0_a3_gb: "KGZ",
        adm0_a3_br: "KGZ",
        adm0_a3_il: "KGZ",
        adm0_a3_ps: "KGZ",
        adm0_a3_sa: "KGZ",
        adm0_a3_eg: "KGZ",
        adm0_a3_ma: "KGZ",
        adm0_a3_pt: "KGZ",
        adm0_a3_ar: "KGZ",
        adm0_a3_jp: "KGZ",
        adm0_a3_ko: "KGZ",
        adm0_a3_vn: "KGZ",
        adm0_a3_tr: "KGZ",
        adm0_a3_id: "KGZ",
        adm0_a3_pl: "KGZ",
        adm0_a3_gr: "KGZ",
        adm0_a3_it: "KGZ",
        adm0_a3_nl: "KGZ",
        adm0_a3_se: "KGZ",
        adm0_a3_bd: "KGZ",
        adm0_a3_ua: "KGZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 74.532637,
        label_y: 41.66854,
        ne_id: 1159320977,
        wikidataid: "Q813",
        name_ar: "قيرغيزستان",
        name_bn: "কিরগিজস্তান",
        name_de: "Kirgisistan",
        name_en: "Kyrgyzstan",
        name_es: "Kirguistán",
        name_fa: "قرقیزستان",
        name_fr: "Kirghizistan",
        name_el: "Κιργιζία",
        name_he: "קירגיזסטן",
        name_hi: "किर्गिज़स्तान",
        name_hu: "Kirgizisztán",
        name_id: "Kirgizstan",
        name_it: "Kirghizistan",
        name_ja: "キルギス",
        name_ko: "키르기스스탄",
        name_nl: "Kirgizië",
        name_pl: "Kirgistan",
        name_pt: "Quirguistão",
        name_ru: "Киргизия",
        name_sv: "Kirgizistan",
        name_tr: "Kırgızistan",
        name_uk: "Киргизстан",
        name_ur: "کرغیزستان",
        name_vi: "Kyrgyzstan",
        name_zh: "吉尔吉斯斯坦",
        name_zht: "吉爾吉斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KGZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.96231489449914, 42.266154283205495],
            [71.18628055205212, 42.70429291439214],
            [71.8446382994506, 42.8453954127651],
            [73.48975752146237, 42.50089447689132],
            [73.64530358266092, 43.09127187760987],
            [74.21286583852256, 43.29833934180337],
            [75.63696495962202, 42.87789988867668],
            [76.00035363149846, 42.98802236589067],
            [77.65839196158322, 42.96068553320826],
            [79.14217736197978, 42.85609243424952],
            [79.64364546094012, 42.49668284765953],
            [80.2599902688853, 42.34999929459906],
            [80.11943037305139, 42.12394074153825],
            [78.54366092317531, 41.58224254003869],
            [78.18719689322597, 41.18531586360481],
            [76.90448449087708, 41.06648590754965],
            [76.52636803579745, 40.42794607193512],
            [75.4678279967307, 40.56207225194867],
            [74.77686242055606, 40.36642527929163],
            [73.8222436868283, 39.893973497063186],
            [73.96001305531843, 39.660008449861735],
            [73.6753792662548, 39.4312368841056],
            [71.784693637992, 39.27946320246437],
            [70.54916181832562, 39.6041979029865],
            [69.46488691597753, 39.5266832545487],
            [69.55960981636852, 40.10321137141298],
            [70.64801883329997, 39.93575389257117],
            [71.01419803252017, 40.24436554621823],
            [71.77487511585656, 40.14584442805378],
            [73.05541710804917, 40.866033026689465],
            [71.87011478057047, 41.392900092121266],
            [71.1578585142916, 41.14358714452912],
            [70.42002241402821, 41.51999827734314],
            [71.25924767444823, 42.16771067968946],
            [70.96231489449914, 42.266154283205495],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Armenia",
        sov_a3: "ARM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Armenia",
        adm0_a3: "ARM",
        geou_dif: 0,
        geounit: "Armenia",
        gu_a3: "ARM",
        su_dif: 0,
        subunit: "Armenia",
        su_a3: "ARM",
        brk_diff: 0,
        name: "Armenia",
        name_long: "Armenia",
        brk_a3: "ARM",
        brk_name: "Armenia",
        brk_group: null,
        abbrev: "Arm.",
        postal: "ARM",
        formal_en: "Republic of Armenia",
        formal_fr: null,
        name_ciawf: "Armenia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Armenia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 2957731,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 13672,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "AM",
        iso_a2: "AM",
        iso_a2_eh: "AM",
        iso_a3: "ARM",
        iso_a3_eh: "ARM",
        iso_n3: "051",
        iso_n3_eh: "051",
        un_a3: "051",
        wb_a2: "AM",
        wb_a3: "ARM",
        woe_id: 23424743,
        woe_id_eh: 23424743,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ARM",
        adm0_diff: null,
        adm0_tlc: "ARM",
        adm0_a3_us: "ARM",
        adm0_a3_fr: "ARM",
        adm0_a3_ru: "ARM",
        adm0_a3_es: "ARM",
        adm0_a3_cn: "ARM",
        adm0_a3_tw: "ARM",
        adm0_a3_in: "ARM",
        adm0_a3_np: "ARM",
        adm0_a3_pk: "ARM",
        adm0_a3_de: "ARM",
        adm0_a3_gb: "ARM",
        adm0_a3_br: "ARM",
        adm0_a3_il: "ARM",
        adm0_a3_ps: "ARM",
        adm0_a3_sa: "ARM",
        adm0_a3_eg: "ARM",
        adm0_a3_ma: "ARM",
        adm0_a3_pt: "ARM",
        adm0_a3_ar: "ARM",
        adm0_a3_jp: "ARM",
        adm0_a3_ko: "ARM",
        adm0_a3_vn: "ARM",
        adm0_a3_tr: "ARM",
        adm0_a3_id: "ARM",
        adm0_a3_pl: "ARM",
        adm0_a3_gr: "ARM",
        adm0_a3_it: "ARM",
        adm0_a3_nl: "ARM",
        adm0_a3_se: "ARM",
        adm0_a3_bd: "ARM",
        adm0_a3_ua: "ARM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 44.800564,
        label_y: 40.459077,
        ne_id: 1159320333,
        wikidataid: "Q399",
        name_ar: "أرمينيا",
        name_bn: "আর্মেনিয়া",
        name_de: "Armenien",
        name_en: "Armenia",
        name_es: "Armenia",
        name_fa: "ارمنستان",
        name_fr: "Arménie",
        name_el: "Αρμενία",
        name_he: "ארמניה",
        name_hi: "आर्मीनिया",
        name_hu: "Örményország",
        name_id: "Armenia",
        name_it: "Armenia",
        name_ja: "アルメニア",
        name_ko: "아르메니아",
        name_nl: "Armenië",
        name_pl: "Armenia",
        name_pt: "Arménia",
        name_ru: "Армения",
        name_sv: "Armenien",
        name_tr: "Ermenistan",
        name_uk: "Вірменія",
        name_ur: "آرمینیا",
        name_vi: "Armenia",
        name_zh: "亚美尼亚",
        name_zht: "亞美尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ARM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.50571984231797, 38.770605373686294],
            [46.14362308124882, 38.74120148371222],
            [45.73537926614301, 39.31971914321974],
            [45.73997846861698, 39.47399913182713],
            [45.29814497252146, 39.471751207022436],
            [45.00198733905675, 39.740003567049555],
            [44.79398969908195, 39.71300263117705],
            [44.4000085792887, 40.00500031184228],
            [43.65643639504094, 40.253563951166186],
            [43.75265791196841, 40.74020091405876],
            [43.58274580259273, 41.09214325618257],
            [44.97248009621808, 41.248128567055595],
            [45.17949588397934, 40.98535390885141],
            [45.56035118997045, 40.812289537105926],
            [45.35917483905817, 40.56150381119346],
            [45.89190717955509, 40.21847565364],
            [45.61001224140293, 39.89999380142518],
            [46.034534132680676, 39.628020738273065],
            [46.48349897643246, 39.464154771475535],
            [46.50571984231797, 38.770605373686294],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Azerbaijan",
        sov_a3: "AZE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Azerbaijan",
        adm0_a3: "AZE",
        geou_dif: 0,
        geounit: "Azerbaijan",
        gu_a3: "AZE",
        su_dif: 0,
        subunit: "Azerbaijan",
        su_a3: "AZE",
        brk_diff: 0,
        name: "Azerbaijan",
        name_long: "Azerbaijan",
        brk_a3: "AZE",
        brk_name: "Azerbaijan",
        brk_group: null,
        abbrev: "Aze.",
        postal: "AZ",
        formal_en: "Republic of Azerbaijan",
        formal_fr: null,
        name_ciawf: "Azerbaijan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Azerbaijan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 10023318,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 48047,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AJ",
        iso_a2: "AZ",
        iso_a2_eh: "AZ",
        iso_a3: "AZE",
        iso_a3_eh: "AZE",
        iso_n3: "031",
        iso_n3_eh: "031",
        un_a3: "031",
        wb_a2: "AZ",
        wb_a3: "AZE",
        woe_id: 23424741,
        woe_id_eh: 23424741,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AZE",
        adm0_diff: null,
        adm0_tlc: "AZE",
        adm0_a3_us: "AZE",
        adm0_a3_fr: "AZE",
        adm0_a3_ru: "AZE",
        adm0_a3_es: "AZE",
        adm0_a3_cn: "AZE",
        adm0_a3_tw: "AZE",
        adm0_a3_in: "AZE",
        adm0_a3_np: "AZE",
        adm0_a3_pk: "AZE",
        adm0_a3_de: "AZE",
        adm0_a3_gb: "AZE",
        adm0_a3_br: "AZE",
        adm0_a3_il: "AZE",
        adm0_a3_ps: "AZE",
        adm0_a3_sa: "AZE",
        adm0_a3_eg: "AZE",
        adm0_a3_ma: "AZE",
        adm0_a3_pt: "AZE",
        adm0_a3_ar: "AZE",
        adm0_a3_jp: "AZE",
        adm0_a3_ko: "AZE",
        adm0_a3_vn: "AZE",
        adm0_a3_tr: "AZE",
        adm0_a3_id: "AZE",
        adm0_a3_pl: "AZE",
        adm0_a3_gr: "AZE",
        adm0_a3_it: "AZE",
        adm0_a3_nl: "AZE",
        adm0_a3_se: "AZE",
        adm0_a3_bd: "AZE",
        adm0_a3_ua: "AZE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 47.210994,
        label_y: 40.402387,
        ne_id: 1159320381,
        wikidataid: "Q227",
        name_ar: "أذربيجان",
        name_bn: "আজারবাইজান",
        name_de: "Aserbaidschan",
        name_en: "Azerbaijan",
        name_es: "Azerbaiyán",
        name_fa: "جمهوری آذربایجان",
        name_fr: "Azerbaïdjan",
        name_el: "Αζερμπαϊτζάν",
        name_he: "אזרבייג'ן",
        name_hi: "अज़रबैजान",
        name_hu: "Azerbajdzsán",
        name_id: "Azerbaijan",
        name_it: "Azerbaigian",
        name_ja: "アゼルバイジャン",
        name_ko: "아제르바이잔",
        name_nl: "Azerbeidzjan",
        name_pl: "Azerbejdżan",
        name_pt: "Azerbaijão",
        name_ru: "Азербайджан",
        name_sv: "Azerbajdzjan",
        name_tr: "Azerbaycan",
        name_uk: "Азербайджан",
        name_ur: "آذربائیجان",
        name_vi: "Azerbaijan",
        name_zh: "阿塞拜疆",
        name_zht: "亞塞拜然",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AZE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [46.404950799348825, 41.860675157227305],
              [46.68607059101661, 41.82713715266988],
              [47.37331546406623, 41.2197323675112],
              [47.81566572448463, 41.15141612402135],
              [47.98728315612598, 41.40581920019423],
              [48.58435339611342, 41.80886879162067],
              [49.110263706260696, 41.28228668880051],
              [49.6189148293096, 40.57292430272996],
              [50.08482954285313, 40.52615713150581],
              [50.39282107931268, 40.25656118423912],
              [49.569202101444795, 40.176100979160665],
              [49.395259230350376, 39.39948171646225],
              [49.22322838725071, 39.04921885838793],
              [48.85653242370759, 38.81548635513178],
              [48.88324913920249, 38.32024526626262],
              [48.63437544128482, 38.27037750910097],
              [48.01074425638648, 38.79401479751452],
              [48.35552941263788, 39.28876496027691],
              [48.06009524922524, 39.58223541926246],
              [47.685079380083096, 39.50836395930122],
              [46.50571984231797, 38.770605373686294],
              [46.48349897643246, 39.464154771475535],
              [46.034534132680676, 39.628020738273065],
              [45.61001224140293, 39.89999380142518],
              [45.89190717955509, 40.21847565364],
              [45.35917483905817, 40.56150381119346],
              [45.56035118997045, 40.812289537105926],
              [45.17949588397934, 40.98535390885141],
              [44.97248009621808, 41.248128567055595],
              [45.217426385281584, 41.41145193131405],
              [45.96260053893039, 41.123872585609774],
              [46.50163740416693, 41.06444468847411],
              [46.63790815612058, 41.181672675128226],
              [46.14543175637902, 41.72280243587258],
              [46.404950799348825, 41.860675157227305],
            ],
          ],
          [
            [
              [46.14362308124882, 38.74120148371222],
              [45.45772179543877, 38.87413910578306],
              [44.95268802265031, 39.33576467544637],
              [44.79398969908195, 39.71300263117705],
              [45.00198733905675, 39.740003567049555],
              [45.29814497252146, 39.471751207022436],
              [45.73997846861698, 39.47399913182713],
              [45.73537926614301, 39.31971914321974],
              [46.14362308124882, 38.74120148371222],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Iraq",
        sov_a3: "IRQ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Iraq",
        adm0_a3: "IRQ",
        geou_dif: 0,
        geounit: "Iraq",
        gu_a3: "IRQ",
        su_dif: 0,
        subunit: "Iraq",
        su_a3: "IRQ",
        brk_diff: 0,
        name: "Iraq",
        name_long: "Iraq",
        brk_a3: "IRQ",
        brk_name: "Iraq",
        brk_group: null,
        abbrev: "Iraq",
        postal: "IRQ",
        formal_en: "Republic of Iraq",
        formal_fr: null,
        name_ciawf: "Iraq",
        note_adm0: null,
        note_brk: null,
        name_sort: "Iraq",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 39309783,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 234094,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "IZ",
        iso_a2: "IQ",
        iso_a2_eh: "IQ",
        iso_a3: "IRQ",
        iso_a3_eh: "IRQ",
        iso_n3: "368",
        iso_n3_eh: "368",
        un_a3: "368",
        wb_a2: "IQ",
        wb_a3: "IRQ",
        woe_id: 23424855,
        woe_id_eh: 23424855,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IRQ",
        adm0_diff: null,
        adm0_tlc: "IRQ",
        adm0_a3_us: "IRQ",
        adm0_a3_fr: "IRQ",
        adm0_a3_ru: "IRQ",
        adm0_a3_es: "IRQ",
        adm0_a3_cn: "IRQ",
        adm0_a3_tw: "IRQ",
        adm0_a3_in: "IRQ",
        adm0_a3_np: "IRQ",
        adm0_a3_pk: "IRQ",
        adm0_a3_de: "IRQ",
        adm0_a3_gb: "IRQ",
        adm0_a3_br: "IRQ",
        adm0_a3_il: "IRQ",
        adm0_a3_ps: "IRQ",
        adm0_a3_sa: "IRQ",
        adm0_a3_eg: "IRQ",
        adm0_a3_ma: "IRQ",
        adm0_a3_pt: "IRQ",
        adm0_a3_ar: "IRQ",
        adm0_a3_jp: "IRQ",
        adm0_a3_ko: "IRQ",
        adm0_a3_vn: "IRQ",
        adm0_a3_tr: "IRQ",
        adm0_a3_id: "IRQ",
        adm0_a3_pl: "IRQ",
        adm0_a3_gr: "IRQ",
        adm0_a3_it: "IRQ",
        adm0_a3_nl: "IRQ",
        adm0_a3_se: "IRQ",
        adm0_a3_bd: "IRQ",
        adm0_a3_ua: "IRQ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7.5,
        label_x: 43.26181,
        label_y: 33.09403,
        ne_id: 1159320887,
        wikidataid: "Q796",
        name_ar: "العراق",
        name_bn: "ইরাক",
        name_de: "Irak",
        name_en: "Iraq",
        name_es: "Irak",
        name_fa: "عراق",
        name_fr: "Irak",
        name_el: "Ιράκ",
        name_he: "עיראק",
        name_hi: "इराक",
        name_hu: "Irak",
        name_id: "Irak",
        name_it: "Iraq",
        name_ja: "イラク",
        name_ko: "이라크",
        name_nl: "Irak",
        name_pl: "Irak",
        name_pt: "Iraque",
        name_ru: "Ирак",
        name_sv: "Irak",
        name_tr: "Irak",
        name_uk: "Ірак",
        name_ur: "عراق",
        name_vi: "Iraq",
        name_zh: "伊拉克",
        name_zht: "伊拉克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IRQ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.19546837744497, 32.16100881604267],
            [38.792340529136084, 33.378686428352225],
            [41.006158888519934, 34.41937226006212],
            [41.383965285005814, 35.628316555314356],
            [41.289707472505455, 36.35881460219227],
            [41.83706424334096, 36.605853786763575],
            [42.34959109881177, 37.2298725449041],
            [42.77912560402183, 37.385263576805755],
            [43.9422587420473, 37.25622752537295],
            [44.29345177590287, 37.0015143906063],
            [44.77267710159504, 37.17043692561684],
            [45.42061811705321, 35.977545884742824],
            [46.0763403664048, 35.67738332777549],
            [46.15178795755094, 35.09325877536429],
            [45.64845950702809, 34.748137722303014],
            [45.41669070819904, 33.967797756479584],
            [46.10936160663932, 33.017287299119005],
            [47.33466149271191, 32.46915538179911],
            [47.8492037290421, 31.70917593029867],
            [47.68528608581227, 30.984853217079632],
            [48.004698113808324, 30.985137437457244],
            [48.0145683123761, 30.452456773392598],
            [48.567971225789755, 29.926778265903522],
            [47.974519077349896, 29.975819200148504],
            [47.30262210469096, 30.059069932570722],
            [46.568713413281756, 29.09902517345229],
            [44.70949873228474, 29.178891099559383],
            [41.889980910007836, 31.19000865327837],
            [40.399994337736246, 31.889991766887935],
            [39.19546837744497, 32.16100881604267],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Iran",
        sov_a3: "IRN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Iran",
        adm0_a3: "IRN",
        geou_dif: 0,
        geounit: "Iran",
        gu_a3: "IRN",
        su_dif: 0,
        subunit: "Iran",
        su_a3: "IRN",
        brk_diff: 0,
        name: "Iran",
        name_long: "Iran",
        brk_a3: "IRN",
        brk_name: "Iran",
        brk_group: null,
        abbrev: "Iran",
        postal: "IRN",
        formal_en: "Islamic Republic of Iran",
        formal_fr: null,
        name_ciawf: "Iran",
        note_adm0: null,
        note_brk: null,
        name_sort: "Iran, Islamic Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 13,
        pop_est: 82913906,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 453996,
        gdp_year: 2018,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "IR",
        iso_a2: "IR",
        iso_a2_eh: "IR",
        iso_a3: "IRN",
        iso_a3_eh: "IRN",
        iso_n3: "364",
        iso_n3_eh: "364",
        un_a3: "364",
        wb_a2: "IR",
        wb_a3: "IRN",
        woe_id: 23424851,
        woe_id_eh: 23424851,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IRN",
        adm0_diff: null,
        adm0_tlc: "IRN",
        adm0_a3_us: "IRN",
        adm0_a3_fr: "IRN",
        adm0_a3_ru: "IRN",
        adm0_a3_es: "IRN",
        adm0_a3_cn: "IRN",
        adm0_a3_tw: "IRN",
        adm0_a3_in: "IRN",
        adm0_a3_np: "IRN",
        adm0_a3_pk: "IRN",
        adm0_a3_de: "IRN",
        adm0_a3_gb: "IRN",
        adm0_a3_br: "IRN",
        adm0_a3_il: "IRN",
        adm0_a3_ps: "IRN",
        adm0_a3_sa: "IRN",
        adm0_a3_eg: "IRN",
        adm0_a3_ma: "IRN",
        adm0_a3_pt: "IRN",
        adm0_a3_ar: "IRN",
        adm0_a3_jp: "IRN",
        adm0_a3_ko: "IRN",
        adm0_a3_vn: "IRN",
        adm0_a3_tr: "IRN",
        adm0_a3_id: "IRN",
        adm0_a3_pl: "IRN",
        adm0_a3_gr: "IRN",
        adm0_a3_it: "IRN",
        adm0_a3_nl: "IRN",
        adm0_a3_se: "IRN",
        adm0_a3_bd: "IRN",
        adm0_a3_ua: "IRN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 6.7,
        label_x: 54.931495,
        label_y: 32.166225,
        ne_id: 1159320881,
        wikidataid: "Q794",
        name_ar: "إيران",
        name_bn: "ইরান",
        name_de: "Iran",
        name_en: "Iran",
        name_es: "Irán",
        name_fa: "ایران",
        name_fr: "Iran",
        name_el: "Ιράν",
        name_he: "איראן",
        name_hi: "ईरान",
        name_hu: "Irán",
        name_id: "Iran",
        name_it: "Iran",
        name_ja: "イラン",
        name_ko: "이란",
        name_nl: "Iran",
        name_pl: "Iran",
        name_pt: "Irão",
        name_ru: "Иран",
        name_sv: "Iran",
        name_tr: "İran",
        name_uk: "Іран",
        name_ur: "ایران",
        name_vi: "Iran",
        name_zh: "伊朗",
        name_zht: "伊朗",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IRN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.567971225789755, 29.926778265903522],
            [48.0145683123761, 30.452456773392598],
            [48.004698113808324, 30.985137437457244],
            [47.68528608581227, 30.984853217079632],
            [47.8492037290421, 31.70917593029867],
            [47.33466149271191, 32.46915538179911],
            [46.10936160663932, 33.017287299119005],
            [45.41669070819904, 33.967797756479584],
            [45.64845950702809, 34.748137722303014],
            [46.15178795755094, 35.09325877536429],
            [46.0763403664048, 35.67738332777549],
            [45.42061811705321, 35.977545884742824],
            [44.77267710159504, 37.17043692561684],
            [44.77267, 37.17045],
            [44.22575564960053, 37.97158437758935],
            [44.421402622257546, 38.28128123631454],
            [44.10922529478234, 39.4281362981681],
            [44.79398969908195, 39.71300263117705],
            [44.95268802265031, 39.33576467544637],
            [45.45772179543877, 38.87413910578306],
            [46.14362308124882, 38.74120148371222],
            [46.50571984231797, 38.770605373686294],
            [47.685079380083096, 39.50836395930122],
            [48.06009524922524, 39.58223541926246],
            [48.35552941263788, 39.28876496027691],
            [48.01074425638648, 38.79401479751452],
            [48.63437544128482, 38.27037750910097],
            [48.88324913920249, 38.32024526626262],
            [49.19961225769334, 37.582874253889884],
            [50.14777143738462, 37.37456655532134],
            [50.84235436381971, 36.8728142359834],
            [52.264024692601424, 36.7004216578577],
            [53.82578982932642, 36.965030829408235],
            [53.92159793479556, 37.19891836196126],
            [54.800303989486565, 37.392420762678185],
            [55.51157840355191, 37.96411713312317],
            [56.18037479027333, 37.93512665460743],
            [56.61936608259282, 38.121394354803485],
            [57.330433790928986, 38.02922943781094],
            [58.436154412678206, 37.5223094752438],
            [59.23476199731681, 37.41298798273034],
            [60.37763797388388, 36.52738312432837],
            [61.123070509694145, 36.491597194966246],
            [61.210817091725744, 35.650072333309225],
            [60.80319339380745, 34.40410187431986],
            [60.52842980331158, 33.676446031218006],
            [60.963700392506006, 33.52883230237626],
            [60.536077915290775, 32.98126882581157],
            [60.863654819588966, 32.18291962333443],
            [60.94194461451113, 31.548074652628753],
            [61.69931440618083, 31.379506130492672],
            [61.781221551363444, 30.735850328081238],
            [60.87424848820879, 29.829238999952608],
            [61.36930870956494, 29.303276272085924],
            [61.77186811711863, 28.6993338078908],
            [62.72783043808599, 28.25964488373539],
            [62.75542565292986, 27.378923448184988],
            [63.2338977395203, 27.21704702403071],
            [63.31663170761959, 26.756532497661667],
            [61.87418745305655, 26.239974880472104],
            [61.49736290878419, 25.0782370061185],
            [59.616134067630846, 25.380156561783778],
            [58.525761346272304, 25.60996165618573],
            [57.39725141788239, 25.73990204518364],
            [56.970765822177555, 26.966106268821363],
            [56.492138706290206, 27.143304755150197],
            [55.723710158110066, 26.964633490501043],
            [54.71508955263727, 26.480657863871514],
            [53.49309695823135, 26.81236888275305],
            [52.48359785340961, 27.580849107365495],
            [51.52076256694742, 27.865689602158298],
            [50.85294803243954, 28.814520575469388],
            [50.115008579311585, 30.147772528599717],
            [49.576850213423995, 29.985715236932407],
            [48.94133344909855, 30.317090359004037],
            [48.567971225789755, 29.926778265903522],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Qatar",
        sov_a3: "QAT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Qatar",
        adm0_a3: "QAT",
        geou_dif: 0,
        geounit: "Qatar",
        gu_a3: "QAT",
        su_dif: 0,
        subunit: "Qatar",
        su_a3: "QAT",
        brk_diff: 0,
        name: "Qatar",
        name_long: "Qatar",
        brk_a3: "QAT",
        brk_name: "Qatar",
        brk_group: null,
        abbrev: "Qatar",
        postal: "QA",
        formal_en: "State of Qatar",
        formal_fr: null,
        name_ciawf: "Qatar",
        note_adm0: null,
        note_brk: null,
        name_sort: "Qatar",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 4,
        pop_est: 2832067,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 175837,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "QA",
        iso_a2: "QA",
        iso_a2_eh: "QA",
        iso_a3: "QAT",
        iso_a3_eh: "QAT",
        iso_n3: "634",
        iso_n3_eh: "634",
        un_a3: "634",
        wb_a2: "QA",
        wb_a3: "QAT",
        woe_id: 23424930,
        woe_id_eh: 23424930,
        woe_note: "Exact WOE match as country",
        adm0_iso: "QAT",
        adm0_diff: null,
        adm0_tlc: "QAT",
        adm0_a3_us: "QAT",
        adm0_a3_fr: "QAT",
        adm0_a3_ru: "QAT",
        adm0_a3_es: "QAT",
        adm0_a3_cn: "QAT",
        adm0_a3_tw: "QAT",
        adm0_a3_in: "QAT",
        adm0_a3_np: "QAT",
        adm0_a3_pk: "QAT",
        adm0_a3_de: "QAT",
        adm0_a3_gb: "QAT",
        adm0_a3_br: "QAT",
        adm0_a3_il: "QAT",
        adm0_a3_ps: "QAT",
        adm0_a3_sa: "QAT",
        adm0_a3_eg: "QAT",
        adm0_a3_ma: "QAT",
        adm0_a3_pt: "QAT",
        adm0_a3_ar: "QAT",
        adm0_a3_jp: "QAT",
        adm0_a3_ko: "QAT",
        adm0_a3_vn: "QAT",
        adm0_a3_tr: "QAT",
        adm0_a3_id: "QAT",
        adm0_a3_pl: "QAT",
        adm0_a3_gr: "QAT",
        adm0_a3_it: "QAT",
        adm0_a3_nl: "QAT",
        adm0_a3_se: "QAT",
        adm0_a3_bd: "QAT",
        adm0_a3_ua: "QAT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 51.143509,
        label_y: 25.237383,
        ne_id: 1159321197,
        wikidataid: "Q846",
        name_ar: "قطر",
        name_bn: "কাতার",
        name_de: "Katar",
        name_en: "Qatar",
        name_es: "Catar",
        name_fa: "قطر",
        name_fr: "Qatar",
        name_el: "Κατάρ",
        name_he: "קטר",
        name_hi: "क़तर",
        name_hu: "Katar",
        name_id: "Qatar",
        name_it: "Qatar",
        name_ja: "カタール",
        name_ko: "카타르",
        name_nl: "Qatar",
        name_pl: "Katar",
        name_pt: "Catar",
        name_ru: "Катар",
        name_sv: "Qatar",
        name_tr: "Katar",
        name_uk: "Катар",
        name_ur: "قطر",
        name_vi: "Qatar",
        name_zh: "卡塔尔",
        name_zht: "卡達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "QAT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.81010827006958, 24.754742539971378],
            [50.74391076030369, 25.482424221289396],
            [51.013351678273494, 26.006991685484195],
            [51.28646162293606, 26.11458201751587],
            [51.58907881043726, 25.80111277923338],
            [51.60670047384881, 25.21567047779874],
            [51.38960778179063, 24.62738597258806],
            [51.11241539897702, 24.556330878186724],
            [50.81010827006958, 24.754742539971378],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Saudi Arabia",
        sov_a3: "SAU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Saudi Arabia",
        adm0_a3: "SAU",
        geou_dif: 0,
        geounit: "Saudi Arabia",
        gu_a3: "SAU",
        su_dif: 0,
        subunit: "Saudi Arabia",
        su_a3: "SAU",
        brk_diff: 0,
        name: "Saudi Arabia",
        name_long: "Saudi Arabia",
        brk_a3: "SAU",
        brk_name: "Saudi Arabia",
        brk_group: null,
        abbrev: "Saud.",
        postal: "SA",
        formal_en: "Kingdom of Saudi Arabia",
        formal_fr: null,
        name_ciawf: "Saudi Arabia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Saudi Arabia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 7,
        pop_est: 34268528,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 792966,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "SA",
        iso_a2: "SA",
        iso_a2_eh: "SA",
        iso_a3: "SAU",
        iso_a3_eh: "SAU",
        iso_n3: "682",
        iso_n3_eh: "682",
        un_a3: "682",
        wb_a2: "SA",
        wb_a3: "SAU",
        woe_id: 23424938,
        woe_id_eh: 23424938,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SAU",
        adm0_diff: null,
        adm0_tlc: "SAU",
        adm0_a3_us: "SAU",
        adm0_a3_fr: "SAU",
        adm0_a3_ru: "SAU",
        adm0_a3_es: "SAU",
        adm0_a3_cn: "SAU",
        adm0_a3_tw: "SAU",
        adm0_a3_in: "SAU",
        adm0_a3_np: "SAU",
        adm0_a3_pk: "SAU",
        adm0_a3_de: "SAU",
        adm0_a3_gb: "SAU",
        adm0_a3_br: "SAU",
        adm0_a3_il: "SAU",
        adm0_a3_ps: "SAU",
        adm0_a3_sa: "SAU",
        adm0_a3_eg: "SAU",
        adm0_a3_ma: "SAU",
        adm0_a3_pt: "SAU",
        adm0_a3_ar: "SAU",
        adm0_a3_jp: "SAU",
        adm0_a3_ko: "SAU",
        adm0_a3_vn: "SAU",
        adm0_a3_tr: "SAU",
        adm0_a3_id: "SAU",
        adm0_a3_pl: "SAU",
        adm0_a3_gr: "SAU",
        adm0_a3_it: "SAU",
        adm0_a3_nl: "SAU",
        adm0_a3_se: "SAU",
        adm0_a3_bd: "SAU",
        adm0_a3_ua: "SAU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 7,
        label_x: 44.6996,
        label_y: 23.806908,
        ne_id: 1159321225,
        wikidataid: "Q851",
        name_ar: "السعودية",
        name_bn: "সৌদি আরব",
        name_de: "Saudi-Arabien",
        name_en: "Saudi Arabia",
        name_es: "Arabia Saudita",
        name_fa: "عربستان سعودی",
        name_fr: "Arabie saoudite",
        name_el: "Σαουδική Αραβία",
        name_he: "ערב הסעודית",
        name_hi: "सउदी अरब",
        name_hu: "Szaúd-Arábia",
        name_id: "Arab Saudi",
        name_it: "Arabia Saudita",
        name_ja: "サウジアラビア",
        name_ko: "사우디아라비아",
        name_nl: "Saoedi-Arabië",
        name_pl: "Arabia Saudyjska",
        name_pt: "Arábia Saudita",
        name_ru: "Саудовская Аравия",
        name_sv: "Saudiarabien",
        name_tr: "Suudi Arabistan",
        name_uk: "Саудівська Аравія",
        name_ur: "سعودی عرب",
        name_vi: "Ả Rập Saudi",
        name_zh: "沙特阿拉伯",
        name_zht: "沙烏地阿拉伯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SAU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.95603722508426, 29.356554673778845],
            [36.06894087092206, 29.197494615184453],
            [36.50121422704359, 29.505253607698705],
            [36.74052778498725, 29.86528331147619],
            [37.503581984209035, 30.003776150018407],
            [37.66811974462638, 30.3386652694859],
            [37.998848911294374, 30.508499864213135],
            [37.00216556168101, 31.508412990844747],
            [39.00488569515255, 32.01021698661498],
            [39.19546837744497, 32.16100881604267],
            [40.399994337736246, 31.889991766887935],
            [41.889980910007836, 31.19000865327837],
            [44.70949873228474, 29.178891099559383],
            [46.568713413281756, 29.09902517345229],
            [47.45982181172283, 29.002519436147224],
            [47.708850538937384, 28.526062730416143],
            [48.416094191283946, 28.55200429942667],
            [48.80759484232718, 27.689627997339883],
            [49.29955447774583, 27.46121816660981],
            [49.47091352722566, 27.109999294538085],
            [50.15242231629088, 26.689663194275997],
            [50.212935418504685, 26.277026882425375],
            [50.11330325704594, 25.94397227630425],
            [50.239858839728754, 25.608049628190926],
            [50.527386509000735, 25.327808335872103],
            [50.66055667501689, 24.999895534764022],
            [50.81010827006958, 24.754742539971378],
            [51.11241539897702, 24.556330878186724],
            [51.38960778179063, 24.62738597258806],
            [51.57951867046327, 24.245497137951105],
            [51.61770755392698, 24.01421926522883],
            [52.000733270074335, 23.00115448657894],
            [55.006803012924905, 22.496947536707136],
            [55.208341098863194, 22.708329982997046],
            [55.666659376859826, 22.00000112557234],
            [54.99998172386236, 19.999994004796108],
            [52.00000980002224, 19.000003363516058],
            [49.11667158386487, 18.616667588774945],
            [48.18334354024134, 18.166669216377315],
            [47.46669477721763, 17.116681626854884],
            [47.000004917189756, 16.949999294497445],
            [46.74999433776165, 17.283338120996177],
            [46.366658563020536, 17.233315334537636],
            [45.39999922056876, 17.333335069238558],
            [45.21665123879719, 17.433328965723334],
            [44.06261315285508, 17.410358791569593],
            [43.79151858905192, 17.31997671149111],
            [43.380794305196105, 17.57998668056767],
            [43.11579756040336, 17.088440456607373],
            [43.21837527850275, 16.66688996018641],
            [42.77933230975097, 16.347891343648683],
            [42.649572788266084, 16.774635321514964],
            [42.34798912941071, 17.075805568912003],
            [42.270887892431226, 17.474721787989125],
            [41.75438195167396, 17.833046169500975],
            [41.22139122901558, 18.67159963630121],
            [40.93934126156654, 19.486485297111756],
            [40.247652215339826, 20.17463450772649],
            [39.80168460466095, 20.338862209550058],
            [39.139399448408284, 21.291904812092934],
            [39.023695916506796, 21.986875311770195],
            [39.06632897314759, 22.57965566659027],
            [38.49277225114008, 23.688451036060854],
            [38.02386030452362, 24.078685614512935],
            [37.483634881344386, 24.285494696545015],
            [37.154817742671185, 24.85848297779731],
            [37.209491408036, 25.084541530858107],
            [36.93162723160259, 25.60295949961018],
            [36.639603712721225, 25.826227525327223],
            [36.249136590323815, 26.57013560638488],
            [35.64018151219639, 27.376520494083422],
            [35.13018680190788, 28.06335195567472],
            [34.63233605320798, 28.058546047471566],
            [34.787778761541944, 28.6074272730597],
            [34.832220493312946, 28.957483425404845],
            [34.95603722508426, 29.356554673778845],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Pakistan",
        sov_a3: "PAK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Pakistan",
        adm0_a3: "PAK",
        geou_dif: 0,
        geounit: "Pakistan",
        gu_a3: "PAK",
        su_dif: 0,
        subunit: "Pakistan",
        su_a3: "PAK",
        brk_diff: 0,
        name: "Pakistan",
        name_long: "Pakistan",
        brk_a3: "PAK",
        brk_name: "Pakistan",
        brk_group: null,
        abbrev: "Pak.",
        postal: "PK",
        formal_en: "Islamic Republic of Pakistan",
        formal_fr: null,
        name_ciawf: "Pakistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Pakistan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 216565318,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 278221,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "PK",
        iso_a2: "PK",
        iso_a2_eh: "PK",
        iso_a3: "PAK",
        iso_a3_eh: "PAK",
        iso_n3: "586",
        iso_n3_eh: "586",
        un_a3: "586",
        wb_a2: "PK",
        wb_a3: "PAK",
        woe_id: 23424922,
        woe_id_eh: 23424922,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PAK",
        adm0_diff: null,
        adm0_tlc: "PAK",
        adm0_a3_us: "PAK",
        adm0_a3_fr: "PAK",
        adm0_a3_ru: "PAK",
        adm0_a3_es: "PAK",
        adm0_a3_cn: "PAK",
        adm0_a3_tw: "PAK",
        adm0_a3_in: "PAK",
        adm0_a3_np: "PAK",
        adm0_a3_pk: "PAK",
        adm0_a3_de: "PAK",
        adm0_a3_gb: "PAK",
        adm0_a3_br: "PAK",
        adm0_a3_il: "PAK",
        adm0_a3_ps: "PAK",
        adm0_a3_sa: "PAK",
        adm0_a3_eg: "PAK",
        adm0_a3_ma: "PAK",
        adm0_a3_pt: "PAK",
        adm0_a3_ar: "PAK",
        adm0_a3_jp: "PAK",
        adm0_a3_ko: "PAK",
        adm0_a3_vn: "PAK",
        adm0_a3_tr: "PAK",
        adm0_a3_id: "PAK",
        adm0_a3_pl: "PAK",
        adm0_a3_gr: "PAK",
        adm0_a3_it: "PAK",
        adm0_a3_nl: "PAK",
        adm0_a3_se: "PAK",
        adm0_a3_bd: "PAK",
        adm0_a3_ua: "PAK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 68.545632,
        label_y: 29.328389,
        ne_id: 1159321153,
        wikidataid: "Q843",
        name_ar: "باكستان",
        name_bn: "পাকিস্তান",
        name_de: "Pakistan",
        name_en: "Pakistan",
        name_es: "Pakistán",
        name_fa: "پاکستان",
        name_fr: "Pakistan",
        name_el: "Πακιστάν",
        name_he: "פקיסטן",
        name_hi: "पाकिस्तान",
        name_hu: "Pakisztán",
        name_id: "Pakistan",
        name_it: "Pakistan",
        name_ja: "パキスタン",
        name_ko: "파키스탄",
        name_nl: "Pakistan",
        name_pl: "Pakistan",
        name_pt: "Paquistão",
        name_ru: "Пакистан",
        name_sv: "Pakistan",
        name_tr: "Pakistan",
        name_uk: "Пакистан",
        name_ur: "پاکستان",
        name_vi: "Pakistan",
        name_zh: "巴基斯坦",
        name_zht: "巴基斯坦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PAK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.83745079947457, 35.494009507787766],
            [76.87172163280403, 34.65354401299274],
            [75.75706098826834, 34.50492259372132],
            [74.24020267120497, 34.748887030571254],
            [73.74994835805197, 34.31769887952785],
            [74.10429365427734, 33.44147329358685],
            [74.45155927927871, 32.7648996038055],
            [75.25864179881322, 32.2711054550405],
            [74.40592898956501, 31.69263947196528],
            [74.42138024282028, 30.979814764931177],
            [73.45063846221743, 29.97641347911987],
            [72.8237516620847, 28.961591701772054],
            [71.77766564320032, 27.913180243434525],
            [70.61649620960193, 27.989196275335868],
            [69.51439293811313, 26.940965684511372],
            [70.16892662952202, 26.491871649678842],
            [70.2828731627256, 25.72222870533983],
            [70.84469933460284, 25.21510203704352],
            [71.04324018746823, 24.3565239527302],
            [68.84259931831878, 24.35913361256094],
            [68.1766451353734, 23.69196503345671],
            [67.44366661974547, 23.94484365487699],
            [67.14544192898907, 24.663611151624647],
            [66.37282758979327, 25.42514089609385],
            [64.53040774929113, 25.23703868255143],
            [62.90570071803461, 25.21840932871021],
            [61.49736290878419, 25.0782370061185],
            [61.87418745305655, 26.239974880472104],
            [63.31663170761959, 26.756532497661667],
            [63.2338977395203, 27.21704702403071],
            [62.75542565292986, 27.378923448184988],
            [62.72783043808599, 28.25964488373539],
            [61.77186811711863, 28.6993338078908],
            [61.36930870956494, 29.303276272085924],
            [60.87424848820879, 29.829238999952608],
            [62.54985680527278, 29.31857249604431],
            [63.55026085801117, 29.468330796826166],
            [64.14800215033125, 29.340819200145972],
            [64.35041873561852, 29.560030625928093],
            [65.0468620136161, 29.472180691031905],
            [66.34647260932442, 29.887943427036177],
            [66.38145755398602, 30.738899237586452],
            [66.93889122911847, 31.304911200479353],
            [67.68339358914747, 31.30315420178142],
            [67.79268924344478, 31.58293040620963],
            [68.55693200060932, 31.713310044882018],
            [68.92667687365767, 31.620189113892067],
            [69.31776411324256, 31.901412258424443],
            [69.26252200712256, 32.5019440780883],
            [69.68714725126486, 33.105498969041236],
            [70.3235941913716, 33.35853261975839],
            [69.9305432473596, 34.02012014417511],
            [70.8818030129884, 33.98885590263852],
            [71.15677330921346, 34.34891144463215],
            [71.11501875192164, 34.733125718722235],
            [71.61307620635071, 35.153203436822864],
            [71.49876793812109, 35.650563259416],
            [71.26234826038575, 36.074387518857804],
            [71.84629194528392, 36.50994232842986],
            [72.92002485544447, 36.72000702569632],
            [74.06755171091783, 36.83617564548845],
            [74.57589277537298, 37.02084137628346],
            [75.15802778514092, 37.13303091078912],
            [75.89689741405013, 36.666806138651836],
            [76.19284834178569, 35.89840342868783],
            [77.83745079947457, 35.494009507787766],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Kuwait",
        sov_a3: "KWT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kuwait",
        adm0_a3: "KWT",
        geou_dif: 0,
        geounit: "Kuwait",
        gu_a3: "KWT",
        su_dif: 0,
        subunit: "Kuwait",
        su_a3: "KWT",
        brk_diff: 0,
        name: "Kuwait",
        name_long: "Kuwait",
        brk_a3: "KWT",
        brk_name: "Kuwait",
        brk_group: null,
        abbrev: "Kwt.",
        postal: "KW",
        formal_en: "State of Kuwait",
        formal_fr: null,
        name_ciawf: "Kuwait",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kuwait",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 4207083,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 134628,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "KU",
        iso_a2: "KW",
        iso_a2_eh: "KW",
        iso_a3: "KWT",
        iso_a3_eh: "KWT",
        iso_n3: "414",
        iso_n3_eh: "414",
        un_a3: "414",
        wb_a2: "KW",
        wb_a3: "KWT",
        woe_id: 23424870,
        woe_id_eh: 23424870,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KWT",
        adm0_diff: null,
        adm0_tlc: "KWT",
        adm0_a3_us: "KWT",
        adm0_a3_fr: "KWT",
        adm0_a3_ru: "KWT",
        adm0_a3_es: "KWT",
        adm0_a3_cn: "KWT",
        adm0_a3_tw: "KWT",
        adm0_a3_in: "KWT",
        adm0_a3_np: "KWT",
        adm0_a3_pk: "KWT",
        adm0_a3_de: "KWT",
        adm0_a3_gb: "KWT",
        adm0_a3_br: "KWT",
        adm0_a3_il: "KWT",
        adm0_a3_ps: "KWT",
        adm0_a3_sa: "KWT",
        adm0_a3_eg: "KWT",
        adm0_a3_ma: "KWT",
        adm0_a3_pt: "KWT",
        adm0_a3_ar: "KWT",
        adm0_a3_jp: "KWT",
        adm0_a3_ko: "KWT",
        adm0_a3_vn: "KWT",
        adm0_a3_tr: "KWT",
        adm0_a3_id: "KWT",
        adm0_a3_pl: "KWT",
        adm0_a3_gr: "KWT",
        adm0_a3_it: "KWT",
        adm0_a3_nl: "KWT",
        adm0_a3_se: "KWT",
        adm0_a3_bd: "KWT",
        adm0_a3_ua: "KWT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 47.313999,
        label_y: 29.413628,
        ne_id: 1159321009,
        wikidataid: "Q817",
        name_ar: "الكويت",
        name_bn: "কুয়েত",
        name_de: "Kuwait",
        name_en: "Kuwait",
        name_es: "Kuwait",
        name_fa: "کویت",
        name_fr: "Koweït",
        name_el: "Κουβέιτ",
        name_he: "כווית",
        name_hi: "कुवैत",
        name_hu: "Kuvait",
        name_id: "Kuwait",
        name_it: "Kuwait",
        name_ja: "クウェート",
        name_ko: "쿠웨이트",
        name_nl: "Koeweit",
        name_pl: "Kuwejt",
        name_pt: "Kuwait",
        name_ru: "Кувейт",
        name_sv: "Kuwait",
        name_tr: "Kuveyt",
        name_uk: "Кувейт",
        name_ur: "کویت",
        name_vi: "Kuwait",
        name_zh: "科威特",
        name_zht: "科威特",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KWT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [47.974519077349896, 29.975819200148504],
            [48.18318851094449, 29.534476630159762],
            [48.09394331237642, 29.306299343375002],
            [48.416094191283946, 28.55200429942667],
            [47.708850538937384, 28.526062730416143],
            [47.45982181172283, 29.002519436147224],
            [46.568713413281756, 29.09902517345229],
            [47.30262210469096, 30.059069932570722],
            [47.974519077349896, 29.975819200148504],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Thailand",
        sov_a3: "THA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Thailand",
        adm0_a3: "THA",
        geou_dif: 0,
        geounit: "Thailand",
        gu_a3: "THA",
        su_dif: 0,
        subunit: "Thailand",
        su_a3: "THA",
        brk_diff: 0,
        name: "Thailand",
        name_long: "Thailand",
        brk_a3: "THA",
        brk_name: "Thailand",
        brk_group: null,
        abbrev: "Thai.",
        postal: "TH",
        formal_en: "Kingdom of Thailand",
        formal_fr: null,
        name_ciawf: "Thailand",
        note_adm0: null,
        note_brk: null,
        name_sort: "Thailand",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 8,
        mapcolor13: 1,
        pop_est: 69625582,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 543548,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "TH",
        iso_a2: "TH",
        iso_a2_eh: "TH",
        iso_a3: "THA",
        iso_a3_eh: "THA",
        iso_n3: "764",
        iso_n3_eh: "764",
        un_a3: "764",
        wb_a2: "TH",
        wb_a3: "THA",
        woe_id: 23424960,
        woe_id_eh: 23424960,
        woe_note: "Exact WOE match as country",
        adm0_iso: "THA",
        adm0_diff: null,
        adm0_tlc: "THA",
        adm0_a3_us: "THA",
        adm0_a3_fr: "THA",
        adm0_a3_ru: "THA",
        adm0_a3_es: "THA",
        adm0_a3_cn: "THA",
        adm0_a3_tw: "THA",
        adm0_a3_in: "THA",
        adm0_a3_np: "THA",
        adm0_a3_pk: "THA",
        adm0_a3_de: "THA",
        adm0_a3_gb: "THA",
        adm0_a3_br: "THA",
        adm0_a3_il: "THA",
        adm0_a3_ps: "THA",
        adm0_a3_sa: "THA",
        adm0_a3_eg: "THA",
        adm0_a3_ma: "THA",
        adm0_a3_pt: "THA",
        adm0_a3_ar: "THA",
        adm0_a3_jp: "THA",
        adm0_a3_ko: "THA",
        adm0_a3_vn: "THA",
        adm0_a3_tr: "THA",
        adm0_a3_id: "THA",
        adm0_a3_pl: "THA",
        adm0_a3_gr: "THA",
        adm0_a3_it: "THA",
        adm0_a3_nl: "THA",
        adm0_a3_se: "THA",
        adm0_a3_bd: "THA",
        adm0_a3_ua: "THA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: 101.073198,
        label_y: 15.45974,
        ne_id: 1159321305,
        wikidataid: "Q869",
        name_ar: "تايلاند",
        name_bn: "থাইল্যান্ড",
        name_de: "Thailand",
        name_en: "Thailand",
        name_es: "Tailandia",
        name_fa: "تایلند",
        name_fr: "Thaïlande",
        name_el: "Ταϊλάνδη",
        name_he: "תאילנד",
        name_hi: "थाईलैण्ड",
        name_hu: "Thaiföld",
        name_id: "Thailand",
        name_it: "Thailandia",
        name_ja: "タイ王国",
        name_ko: "태국",
        name_nl: "Thailand",
        name_pl: "Tajlandia",
        name_pt: "Tailândia",
        name_ru: "Таиланд",
        name_sv: "Thailand",
        name_tr: "Tayland",
        name_uk: "Таїланд",
        name_ur: "تھائی لینڈ",
        name_vi: "Thái Lan",
        name_zh: "泰国",
        name_zht: "泰國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "THA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [105.21877689007889, 14.273211778210694],
            [104.28141808473661, 14.416743068901367],
            [102.98842207236163, 14.225721136934467],
            [102.34809939983302, 13.394247341358223],
            [102.5849324890267, 12.186594956913282],
            [101.68715783081996, 12.645740057826572],
            [100.83180952352487, 12.627084865769206],
            [100.9784672383692, 13.412721665902566],
            [100.09779747925111, 13.406856390837433],
            [100.01873253784456, 12.307001044153354],
            [99.47892052612363, 10.846366685423547],
            [99.15377241414316, 9.963061428258555],
            [99.22239871622676, 9.239255479362427],
            [99.87383182169813, 9.20786204674512],
            [100.27964684448622, 8.295152899606052],
            [100.45927412313276, 7.429572658717177],
            [101.01732791545273, 6.856868597842478],
            [101.62307905477806, 6.74062246340192],
            [102.14118696493638, 6.221636053894628],
            [101.81428185425798, 5.810808417174242],
            [101.15421878459387, 5.691384182147715],
            [101.07551557821336, 6.204867051615921],
            [100.25959638875696, 6.642824815289543],
            [100.0857568705271, 6.464489447450291],
            [99.69069054565576, 6.848212795433597],
            [99.51964155476963, 7.34345388430276],
            [98.9882528015123, 7.907993068875328],
            [98.503786248776, 8.382305202666288],
            [98.339661899817, 7.794511623562386],
            [98.15000939330582, 8.350007432483878],
            [98.25915001830626, 8.973922837759801],
            [98.55355065307305, 9.932959906448545],
            [99.03812055867398, 10.960545762572437],
            [99.58728600463972, 11.892762762901697],
            [99.19635379435167, 12.80474843998867],
            [99.21201175333609, 13.269293728076464],
            [99.09775516153876, 13.827502549693278],
            [98.43081912637987, 14.622027696180837],
            [98.1920740091914, 15.123702500870351],
            [98.53737592976572, 15.308497422746084],
            [98.90334842325676, 16.17782420497612],
            [98.49376102091135, 16.83783559820793],
            [97.85912275593486, 17.567946071843664],
            [97.37589643757354, 18.445437730375815],
            [97.79778283080441, 18.627080389881755],
            [98.25372399291561, 19.708203029860044],
            [98.95967573445488, 19.752980658440947],
            [99.54330936075931, 20.186597601802063],
            [100.11598758341785, 20.417849636308187],
            [100.54888105672688, 20.109237982661128],
            [100.60629357300316, 19.508344427971224],
            [101.2820146016517, 19.462584947176765],
            [101.03593143107777, 18.408928330961615],
            [101.05954756063517, 17.51249725999449],
            [102.11359175009248, 18.109101670804165],
            [102.41300499879162, 17.932781683824288],
            [102.99870568238771, 17.9616946476916],
            [103.20019209189374, 18.309632066312773],
            [103.9564766784853, 18.24095408779688],
            [104.7169470560925, 17.42885895433008],
            [104.7793205098688, 16.44186493577145],
            [105.58903852745016, 15.570316066952858],
            [105.54433841351769, 14.723933620660418],
            [105.21877689007889, 14.273211778210694],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "East Timor",
        sov_a3: "TLS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "East Timor",
        adm0_a3: "TLS",
        geou_dif: 0,
        geounit: "East Timor",
        gu_a3: "TLS",
        su_dif: 0,
        subunit: "East Timor",
        su_a3: "TLS",
        brk_diff: 0,
        name: "Timor-Leste",
        name_long: "Timor-Leste",
        brk_a3: "TLS",
        brk_name: "Timor-Leste",
        brk_group: null,
        abbrev: "T.L.",
        postal: "TL",
        formal_en: "Democratic Republic of Timor-Leste",
        formal_fr: null,
        name_ciawf: "Timor-Leste",
        note_adm0: null,
        note_brk: null,
        name_sort: "Timor-Leste",
        name_alt: "East Timor",
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 1293119,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 2017,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "TT",
        iso_a2: "TL",
        iso_a2_eh: "TL",
        iso_a3: "TLS",
        iso_a3_eh: "TLS",
        iso_n3: "626",
        iso_n3_eh: "626",
        un_a3: "626",
        wb_a2: "TP",
        wb_a3: "TMP",
        woe_id: 23424968,
        woe_id_eh: 23424968,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TLS",
        adm0_diff: null,
        adm0_tlc: "TLS",
        adm0_a3_us: "TLS",
        adm0_a3_fr: "TLS",
        adm0_a3_ru: "TLS",
        adm0_a3_es: "TLS",
        adm0_a3_cn: "TLS",
        adm0_a3_tw: "TLS",
        adm0_a3_in: "TLS",
        adm0_a3_np: "TLS",
        adm0_a3_pk: "TLS",
        adm0_a3_de: "TLS",
        adm0_a3_gb: "TLS",
        adm0_a3_br: "TLS",
        adm0_a3_il: "TLS",
        adm0_a3_ps: "TLS",
        adm0_a3_sa: "TLS",
        adm0_a3_eg: "TLS",
        adm0_a3_ma: "TLS",
        adm0_a3_pt: "TLS",
        adm0_a3_ar: "TLS",
        adm0_a3_jp: "TLS",
        adm0_a3_ko: "TLS",
        adm0_a3_vn: "TLS",
        adm0_a3_tr: "TLS",
        adm0_a3_id: "TLS",
        adm0_a3_pl: "TLS",
        adm0_a3_gr: "TLS",
        adm0_a3_it: "TLS",
        adm0_a3_nl: "TLS",
        adm0_a3_se: "TLS",
        adm0_a3_bd: "TLS",
        adm0_a3_ua: "TLS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 125.854679,
        label_y: -8.803705,
        ne_id: 1159321313,
        wikidataid: "Q574",
        name_ar: "تيمور الشرقية",
        name_bn: "পূর্ব তিমুর",
        name_de: "Osttimor",
        name_en: "East Timor",
        name_es: "Timor Oriental",
        name_fa: "تیمور شرقی",
        name_fr: "Timor oriental",
        name_el: "Ανατολικό Τιμόρ",
        name_he: "מזרח טימור",
        name_hi: "पूर्वी तिमोर",
        name_hu: "Kelet-Timor",
        name_id: "Timor Leste",
        name_it: "Timor Est",
        name_ja: "東ティモール",
        name_ko: "동티모르",
        name_nl: "Oost-Timor",
        name_pl: "Timor Wschodni",
        name_pt: "Timor-Leste",
        name_ru: "Восточный Тимор",
        name_sv: "Östtimor",
        name_tr: "Doğu Timor",
        name_uk: "Східний Тимор",
        name_ur: "مشرقی تیمور",
        name_vi: "Đông Timor",
        name_zh: "东帝汶",
        name_zht: "東帝汶",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TLS.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [124.96868248911623, -8.892790215697083],
            [125.08624637258026, -8.65688730228468],
            [125.94707238169826, -8.432094821815035],
            [126.64470421763855, -8.398246758663852],
            [126.95724328013983, -8.273344821814398],
            [127.33592817597463, -8.397316582882603],
            [126.96799197805655, -8.668256117388893],
            [125.9258850444586, -9.106007175333353],
            [125.08852013560109, -9.393173109579294],
            [125.07001997284061, -9.089987481322872],
            [124.96868248911623, -8.892790215697083],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Brunei",
        sov_a3: "BRN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Brunei",
        adm0_a3: "BRN",
        geou_dif: 0,
        geounit: "Brunei",
        gu_a3: "BRN",
        su_dif: 0,
        subunit: "Brunei",
        su_a3: "BRN",
        brk_diff: 0,
        name: "Brunei",
        name_long: "Brunei Darussalam",
        brk_a3: "BRN",
        brk_name: "Brunei",
        brk_group: null,
        abbrev: "Brunei",
        postal: "BN",
        formal_en: "Negara Brunei Darussalam",
        formal_fr: null,
        name_ciawf: "Brunei",
        note_adm0: null,
        note_brk: null,
        name_sort: "Brunei",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 12,
        pop_est: 433285,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 13469,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "BX",
        iso_a2: "BN",
        iso_a2_eh: "BN",
        iso_a3: "BRN",
        iso_a3_eh: "BRN",
        iso_n3: "096",
        iso_n3_eh: "096",
        un_a3: "096",
        wb_a2: "BN",
        wb_a3: "BRN",
        woe_id: 23424773,
        woe_id_eh: 23424773,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BRN",
        adm0_diff: null,
        adm0_tlc: "BRN",
        adm0_a3_us: "BRN",
        adm0_a3_fr: "BRN",
        adm0_a3_ru: "BRN",
        adm0_a3_es: "BRN",
        adm0_a3_cn: "BRN",
        adm0_a3_tw: "BRN",
        adm0_a3_in: "BRN",
        adm0_a3_np: "BRN",
        adm0_a3_pk: "BRN",
        adm0_a3_de: "BRN",
        adm0_a3_gb: "BRN",
        adm0_a3_br: "BRN",
        adm0_a3_il: "BRN",
        adm0_a3_ps: "BRN",
        adm0_a3_sa: "BRN",
        adm0_a3_eg: "BRN",
        adm0_a3_ma: "BRN",
        adm0_a3_pt: "BRN",
        adm0_a3_ar: "BRN",
        adm0_a3_jp: "BRN",
        adm0_a3_ko: "BRN",
        adm0_a3_vn: "BRN",
        adm0_a3_tr: "BRN",
        adm0_a3_id: "BRN",
        adm0_a3_pl: "BRN",
        adm0_a3_gr: "BRN",
        adm0_a3_it: "BRN",
        adm0_a3_nl: "BRN",
        adm0_a3_se: "BRN",
        adm0_a3_bd: "BRN",
        adm0_a3_ua: "BRN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 17,
        abbrev_len: 6,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 114.551943,
        label_y: 4.448298,
        ne_id: 1159320451,
        wikidataid: "Q921",
        name_ar: "بروناي",
        name_bn: "ব্রুনাই",
        name_de: "Brunei",
        name_en: "Brunei",
        name_es: "Brunéi",
        name_fa: "برونئی",
        name_fr: "Brunei",
        name_el: "Μπρουνέι",
        name_he: "ברוניי",
        name_hi: "ब्रुनेई",
        name_hu: "Brunei",
        name_id: "Brunei Darussalam",
        name_it: "Brunei",
        name_ja: "ブルネイ",
        name_ko: "브루나이",
        name_nl: "Brunei",
        name_pl: "Brunei",
        name_pt: "Brunei",
        name_ru: "Бруней",
        name_sv: "Brunei",
        name_tr: "Brunei",
        name_uk: "Бруней",
        name_ur: "برونائی دار السلام",
        name_vi: "Brunei",
        name_zh: "文莱",
        name_zht: "汶萊",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BRN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [115.45071048386981, 5.447729803891534],
            [115.40570031134361, 4.955227565933839],
            [115.34746097215066, 4.316636053887009],
            [114.8695573263154, 4.348313706881925],
            [114.65959598191353, 4.007636826997754],
            [114.20401655482837, 4.525873928236805],
            [114.59996137904872, 4.900011298029966],
            [115.45071048386981, 5.447729803891534],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Myanmar",
        sov_a3: "MMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Myanmar",
        adm0_a3: "MMR",
        geou_dif: 0,
        geounit: "Myanmar",
        gu_a3: "MMR",
        su_dif: 0,
        subunit: "Myanmar",
        su_a3: "MMR",
        brk_diff: 0,
        name: "Myanmar",
        name_long: "Myanmar",
        brk_a3: "MMR",
        brk_name: "Myanmar",
        brk_group: null,
        abbrev: "Myan.",
        postal: "MM",
        formal_en: "Republic of the Union of Myanmar",
        formal_fr: null,
        name_ciawf: "Burma",
        note_adm0: null,
        note_brk: null,
        name_sort: "Myanmar",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 13,
        pop_est: 54045420,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 76085,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BM",
        iso_a2: "MM",
        iso_a2_eh: "MM",
        iso_a3: "MMR",
        iso_a3_eh: "MMR",
        iso_n3: "104",
        iso_n3_eh: "104",
        un_a3: "104",
        wb_a2: "MM",
        wb_a3: "MMR",
        woe_id: 23424763,
        woe_id_eh: 23424763,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MMR",
        adm0_diff: null,
        adm0_tlc: "MMR",
        adm0_a3_us: "MMR",
        adm0_a3_fr: "MMR",
        adm0_a3_ru: "MMR",
        adm0_a3_es: "MMR",
        adm0_a3_cn: "MMR",
        adm0_a3_tw: "MMR",
        adm0_a3_in: "MMR",
        adm0_a3_np: "MMR",
        adm0_a3_pk: "MMR",
        adm0_a3_de: "MMR",
        adm0_a3_gb: "MMR",
        adm0_a3_br: "MMR",
        adm0_a3_il: "MMR",
        adm0_a3_ps: "MMR",
        adm0_a3_sa: "MMR",
        adm0_a3_eg: "MMR",
        adm0_a3_ma: "MMR",
        adm0_a3_pt: "MMR",
        adm0_a3_ar: "MMR",
        adm0_a3_jp: "MMR",
        adm0_a3_ko: "MMR",
        adm0_a3_vn: "MMR",
        adm0_a3_tr: "MMR",
        adm0_a3_id: "MMR",
        adm0_a3_pl: "MMR",
        adm0_a3_gr: "MMR",
        adm0_a3_it: "MMR",
        adm0_a3_nl: "MMR",
        adm0_a3_se: "MMR",
        adm0_a3_bd: "MMR",
        adm0_a3_ua: "MMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 95.804497,
        label_y: 21.573855,
        ne_id: 1159321067,
        wikidataid: "Q836",
        name_ar: "ميانمار",
        name_bn: "মিয়ানমার",
        name_de: "Myanmar",
        name_en: "Myanmar",
        name_es: "Birmania",
        name_fa: "میانمار",
        name_fr: "Birmanie",
        name_el: "Μιανμάρ",
        name_he: "מיאנמר",
        name_hi: "म्यान्मार",
        name_hu: "Mianmar",
        name_id: "Myanmar",
        name_it: "Birmania",
        name_ja: "ミャンマー",
        name_ko: "미얀마",
        name_nl: "Myanmar",
        name_pl: "Mjanma",
        name_pt: "Myanmar",
        name_ru: "Мьянма",
        name_sv: "Myanmar",
        name_tr: "Myanmar",
        name_uk: "М'янма",
        name_ur: "میانمار",
        name_vi: "Myanma",
        name_zh: "缅甸",
        name_zht: "緬甸",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MMR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [100.11598758341785, 20.417849636308187],
            [99.54330936075931, 20.186597601802063],
            [98.95967573445488, 19.752980658440947],
            [98.25372399291561, 19.708203029860044],
            [97.79778283080441, 18.627080389881755],
            [97.37589643757354, 18.445437730375815],
            [97.85912275593486, 17.567946071843664],
            [98.49376102091135, 16.83783559820793],
            [98.90334842325676, 16.17782420497612],
            [98.53737592976572, 15.308497422746084],
            [98.1920740091914, 15.123702500870351],
            [98.43081912637987, 14.622027696180837],
            [99.09775516153876, 13.827502549693278],
            [99.21201175333609, 13.269293728076464],
            [99.19635379435167, 12.80474843998867],
            [99.58728600463972, 11.892762762901697],
            [99.03812055867398, 10.960545762572437],
            [98.55355065307305, 9.932959906448545],
            [98.45717410684873, 10.67526601810515],
            [98.76454552612078, 11.441291612183749],
            [98.42833865762985, 12.032986761925685],
            [98.50957400919268, 13.122377631070677],
            [98.1036039571077, 13.640459703012851],
            [97.77773237507517, 14.837285874892642],
            [97.59707156778276, 16.10056793869977],
            [97.1645398294998, 16.92873444260934],
            [96.505768670643, 16.42724050543285],
            [95.3693522481124, 15.7143899601826],
            [94.80840457558412, 15.80345429123764],
            [94.18880415240454, 16.037936102762018],
            [94.53348595579135, 17.277240301985728],
            [94.32481652219676, 18.2135139022499],
            [93.54098839719364, 19.366492621330025],
            [93.66325483599621, 19.726961574781996],
            [93.07827762245219, 19.855144965081976],
            [92.36855350135562, 20.670883287025347],
            [92.30323449093868, 21.47548533780982],
            [92.65225711463799, 21.324047552978485],
            [92.67272098182556, 22.041238918541254],
            [93.16612755734837, 22.278459580977103],
            [93.06029422401463, 22.70311066333557],
            [93.28632693885928, 23.043658352139005],
            [93.3251876159428, 24.078556423432204],
            [94.10674197792507, 23.85074087167348],
            [94.55265791217164, 24.675238348890336],
            [94.60324913938538, 25.162495428970402],
            [95.1551534362626, 26.001307277932085],
            [95.12476769407496, 26.5735720891323],
            [96.41936567585097, 27.264589341739224],
            [97.1339990580153, 27.083773505149964],
            [97.0519885599681, 27.69905894623315],
            [97.40256147663614, 27.882536119085444],
            [97.32711388549004, 28.26158274994634],
            [97.91198774616944, 28.335945136014345],
            [98.2462309102333, 27.74722138112918],
            [98.68269005737046, 27.50881216075062],
            [98.71209394734451, 26.743535874940267],
            [98.67183800658916, 25.918702500913525],
            [97.72460900267914, 25.083637193293],
            [97.60471967976198, 23.897404690033042],
            [98.66026248575577, 24.063286037689966],
            [98.89874922078278, 23.14272207284253],
            [99.5319922220874, 22.94903880461258],
            [99.24089887898725, 22.11831431730458],
            [99.98348921102149, 21.7429367131364],
            [100.41653771362738, 21.558839423096614],
            [101.15003299357825, 21.849984442629022],
            [101.18000532430754, 21.436572984294028],
            [100.32910119018953, 20.786121731036232],
            [100.11598758341785, 20.417849636308187],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Bangladesh",
        sov_a3: "BGD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bangladesh",
        adm0_a3: "BGD",
        geou_dif: 0,
        geounit: "Bangladesh",
        gu_a3: "BGD",
        su_dif: 0,
        subunit: "Bangladesh",
        su_a3: "BGD",
        brk_diff: 0,
        name: "Bangladesh",
        name_long: "Bangladesh",
        brk_a3: "BGD",
        brk_name: "Bangladesh",
        brk_group: null,
        abbrev: "Bang.",
        postal: "BD",
        formal_en: "People's Republic of Bangladesh",
        formal_fr: null,
        name_ciawf: "Bangladesh",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bangladesh",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 4,
        mapcolor9: 7,
        mapcolor13: 7,
        pop_est: 163046161,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 302571,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BG",
        iso_a2: "BD",
        iso_a2_eh: "BD",
        iso_a3: "BGD",
        iso_a3_eh: "BGD",
        iso_n3: "050",
        iso_n3_eh: "050",
        un_a3: "050",
        wb_a2: "BD",
        wb_a3: "BGD",
        woe_id: 23424759,
        woe_id_eh: 23424759,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BGD",
        adm0_diff: null,
        adm0_tlc: "BGD",
        adm0_a3_us: "BGD",
        adm0_a3_fr: "BGD",
        adm0_a3_ru: "BGD",
        adm0_a3_es: "BGD",
        adm0_a3_cn: "BGD",
        adm0_a3_tw: "BGD",
        adm0_a3_in: "BGD",
        adm0_a3_np: "BGD",
        adm0_a3_pk: "BGD",
        adm0_a3_de: "BGD",
        adm0_a3_gb: "BGD",
        adm0_a3_br: "BGD",
        adm0_a3_il: "BGD",
        adm0_a3_ps: "BGD",
        adm0_a3_sa: "BGD",
        adm0_a3_eg: "BGD",
        adm0_a3_ma: "BGD",
        adm0_a3_pt: "BGD",
        adm0_a3_ar: "BGD",
        adm0_a3_jp: "BGD",
        adm0_a3_ko: "BGD",
        adm0_a3_vn: "BGD",
        adm0_a3_tr: "BGD",
        adm0_a3_id: "BGD",
        adm0_a3_pl: "BGD",
        adm0_a3_gr: "BGD",
        adm0_a3_it: "BGD",
        adm0_a3_nl: "BGD",
        adm0_a3_se: "BGD",
        adm0_a3_bd: "BGD",
        adm0_a3_ua: "BGD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 89.684963,
        label_y: 24.214956,
        ne_id: 1159320407,
        wikidataid: "Q902",
        name_ar: "بنغلاديش",
        name_bn: "বাংলাদেশ",
        name_de: "Bangladesch",
        name_en: "Bangladesh",
        name_es: "Bangladés",
        name_fa: "بنگلادش",
        name_fr: "Bangladesh",
        name_el: "Μπανγκλαντές",
        name_he: "בנגלדש",
        name_hi: "बांग्लादेश",
        name_hu: "Banglades",
        name_id: "Bangladesh",
        name_it: "Bangladesh",
        name_ja: "バングラデシュ",
        name_ko: "방글라데시",
        name_nl: "Bangladesh",
        name_pl: "Bangladesz",
        name_pt: "Bangladesh",
        name_ru: "Бангладеш",
        name_sv: "Bangladesh",
        name_tr: "Bangladeş",
        name_uk: "Бангладеш",
        name_ur: "بنگلہ دیش",
        name_vi: "Bangladesh",
        name_zh: "孟加拉国",
        name_zht: "孟加拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BGD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [92.67272098182556, 22.041238918541254],
            [92.65225711463799, 21.324047552978485],
            [92.30323449093868, 21.47548533780982],
            [92.36855350135562, 20.670883287025347],
            [92.08288618364614, 21.19219513598577],
            [92.02521528520839, 21.701569729086767],
            [91.83489098507744, 22.182935695885565],
            [91.41708702999766, 22.76501902922122],
            [90.49600630082728, 22.80501658781513],
            [90.58695682166098, 22.392793687422866],
            [90.27297081905556, 21.83636770272011],
            [89.84746707556428, 22.039146023033425],
            [89.70204959509493, 21.857115790285306],
            [89.41886274613549, 21.9661789006373],
            [89.03196129756623, 22.055708319582976],
            [88.87631188350309, 22.87914642993783],
            [88.52976972855379, 23.631141872649167],
            [88.69994022009092, 24.23371491138856],
            [88.08442223506242, 24.501657212821925],
            [88.30637251175602, 24.866079413344206],
            [88.93155398962308, 25.238692328384776],
            [88.2097892598025, 25.768065700782714],
            [88.56304935094977, 26.446525580342723],
            [89.35509402868729, 26.014407253518073],
            [89.83248091019962, 25.96508209889548],
            [89.92069258012185, 25.26974986419218],
            [90.87221072791212, 25.132600612889547],
            [91.79959598182208, 25.147431748957317],
            [92.37620161333481, 24.976692816664965],
            [91.91509280799443, 24.130413723237112],
            [91.46772993364368, 24.072639471934792],
            [91.15896325069973, 23.50352692310439],
            [91.70647505083211, 22.985263983649187],
            [91.86992760617132, 23.624346421802784],
            [92.14603478390681, 23.627498684172593],
            [92.67272098182556, 22.041238918541254],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Afghanistan",
        sov_a3: "AFG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Afghanistan",
        adm0_a3: "AFG",
        geou_dif: 0,
        geounit: "Afghanistan",
        gu_a3: "AFG",
        su_dif: 0,
        subunit: "Afghanistan",
        su_a3: "AFG",
        brk_diff: 0,
        name: "Afghanistan",
        name_long: "Afghanistan",
        brk_a3: "AFG",
        brk_name: "Afghanistan",
        brk_group: null,
        abbrev: "Afg.",
        postal: "AF",
        formal_en: "Islamic State of Afghanistan",
        formal_fr: null,
        name_ciawf: "Afghanistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Afghanistan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 8,
        mapcolor13: 7,
        pop_est: 38041754,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 19291,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "AF",
        iso_a2: "AF",
        iso_a2_eh: "AF",
        iso_a3: "AFG",
        iso_a3_eh: "AFG",
        iso_n3: "004",
        iso_n3_eh: "004",
        un_a3: "004",
        wb_a2: "AF",
        wb_a3: "AFG",
        woe_id: 23424739,
        woe_id_eh: 23424739,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AFG",
        adm0_diff: null,
        adm0_tlc: "AFG",
        adm0_a3_us: "AFG",
        adm0_a3_fr: "AFG",
        adm0_a3_ru: "AFG",
        adm0_a3_es: "AFG",
        adm0_a3_cn: "AFG",
        adm0_a3_tw: "AFG",
        adm0_a3_in: "AFG",
        adm0_a3_np: "AFG",
        adm0_a3_pk: "AFG",
        adm0_a3_de: "AFG",
        adm0_a3_gb: "AFG",
        adm0_a3_br: "AFG",
        adm0_a3_il: "AFG",
        adm0_a3_ps: "AFG",
        adm0_a3_sa: "AFG",
        adm0_a3_eg: "AFG",
        adm0_a3_ma: "AFG",
        adm0_a3_pt: "AFG",
        adm0_a3_ar: "AFG",
        adm0_a3_jp: "AFG",
        adm0_a3_ko: "AFG",
        adm0_a3_vn: "AFG",
        adm0_a3_tr: "AFG",
        adm0_a3_id: "AFG",
        adm0_a3_pl: "AFG",
        adm0_a3_gr: "AFG",
        adm0_a3_it: "AFG",
        adm0_a3_nl: "AFG",
        adm0_a3_se: "AFG",
        adm0_a3_bd: "AFG",
        adm0_a3_ua: "AFG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 66.496586,
        label_y: 34.164262,
        ne_id: 1159320319,
        wikidataid: "Q889",
        name_ar: "أفغانستان",
        name_bn: "আফগানিস্তান",
        name_de: "Afghanistan",
        name_en: "Afghanistan",
        name_es: "Afganistán",
        name_fa: "افغانستان",
        name_fr: "Afghanistan",
        name_el: "Αφγανιστάν",
        name_he: "אפגניסטן",
        name_hi: "अफ़्गानिस्तान",
        name_hu: "Afganisztán",
        name_id: "Afganistan",
        name_it: "Afghanistan",
        name_ja: "アフガニスタン",
        name_ko: "아프가니스탄",
        name_nl: "Afghanistan",
        name_pl: "Afganistan",
        name_pt: "Afeganistão",
        name_ru: "Афганистан",
        name_sv: "Afghanistan",
        name_tr: "Afganistan",
        name_uk: "Афганістан",
        name_ur: "افغانستان",
        name_vi: "Afghanistan",
        name_zh: "阿富汗",
        name_zht: "阿富汗",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AFG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.51860680528867, 37.36278432875879],
            [67.07578209825962, 37.35614390720929],
            [67.82999962755952, 37.144994004864685],
            [68.13556237170138, 37.02311513930431],
            [68.85944583524594, 37.344335842430596],
            [69.19627282092438, 37.15114350030743],
            [69.51878543485796, 37.60899669041342],
            [70.11657840361033, 37.58822276463209],
            [70.27057417184014, 37.735164699854025],
            [70.3763041523093, 38.13839590102752],
            [70.80682050973289, 38.486281643216415],
            [71.34813113799026, 38.25890534113216],
            [71.23940392444817, 37.953265082341886],
            [71.54191775908478, 37.905774441065645],
            [71.44869347523024, 37.06564484308052],
            [71.8446382994506, 36.73817129164692],
            [72.1930408059624, 36.948287665345674],
            [72.63688968291729, 37.047558091778356],
            [73.26005577992501, 37.495256862939],
            [73.9486959166465, 37.4215662704908],
            [74.98000247589542, 37.419990139305895],
            [75.15802778514092, 37.13303091078912],
            [74.57589277537298, 37.02084137628346],
            [74.06755171091783, 36.83617564548845],
            [72.92002485544447, 36.72000702569632],
            [71.84629194528392, 36.50994232842986],
            [71.26234826038575, 36.074387518857804],
            [71.49876793812109, 35.650563259416],
            [71.61307620635071, 35.153203436822864],
            [71.11501875192164, 34.733125718722235],
            [71.15677330921346, 34.34891144463215],
            [70.8818030129884, 33.98885590263852],
            [69.9305432473596, 34.02012014417511],
            [70.3235941913716, 33.35853261975839],
            [69.68714725126486, 33.105498969041236],
            [69.26252200712256, 32.5019440780883],
            [69.31776411324256, 31.901412258424443],
            [68.92667687365767, 31.620189113892067],
            [68.55693200060932, 31.713310044882018],
            [67.79268924344478, 31.58293040620963],
            [67.68339358914747, 31.30315420178142],
            [66.93889122911847, 31.304911200479353],
            [66.38145755398602, 30.738899237586452],
            [66.34647260932442, 29.887943427036177],
            [65.0468620136161, 29.472180691031905],
            [64.35041873561852, 29.560030625928093],
            [64.14800215033125, 29.340819200145972],
            [63.55026085801117, 29.468330796826166],
            [62.54985680527278, 29.31857249604431],
            [60.87424848820879, 29.829238999952608],
            [61.781221551363444, 30.735850328081238],
            [61.69931440618083, 31.379506130492672],
            [60.94194461451113, 31.548074652628753],
            [60.863654819588966, 32.18291962333443],
            [60.536077915290775, 32.98126882581157],
            [60.963700392506006, 33.52883230237626],
            [60.52842980331158, 33.676446031218006],
            [60.80319339380745, 34.40410187431986],
            [61.210817091725744, 35.650072333309225],
            [62.230651483005886, 35.270663967422294],
            [62.98466230657661, 35.40404083916762],
            [63.19353844590035, 35.857165635718914],
            [63.98289594915871, 36.0079574651466],
            [64.5464791197339, 36.31207326918427],
            [64.7461051776774, 37.111817735333304],
            [65.58894778835784, 37.30521678318564],
            [65.74563073106683, 37.66116404881207],
            [66.21738488145934, 37.39379018813392],
            [66.51860680528867, 37.36278432875879],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Turkmenistan",
        sov_a3: "TKM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Turkmenistan",
        adm0_a3: "TKM",
        geou_dif: 0,
        geounit: "Turkmenistan",
        gu_a3: "TKM",
        su_dif: 0,
        subunit: "Turkmenistan",
        su_a3: "TKM",
        brk_diff: 0,
        name: "Turkmenistan",
        name_long: "Turkmenistan",
        brk_a3: "TKM",
        brk_name: "Turkmenistan",
        brk_group: null,
        abbrev: "Turkm.",
        postal: "TM",
        formal_en: "Turkmenistan",
        formal_fr: null,
        name_ciawf: "Turkmenistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Turkmenistan",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 5942089,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 40761,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "TX",
        iso_a2: "TM",
        iso_a2_eh: "TM",
        iso_a3: "TKM",
        iso_a3_eh: "TKM",
        iso_n3: "795",
        iso_n3_eh: "795",
        un_a3: "795",
        wb_a2: "TM",
        wb_a3: "TKM",
        woe_id: 23424972,
        woe_id_eh: 23424972,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TKM",
        adm0_diff: null,
        adm0_tlc: "TKM",
        adm0_a3_us: "TKM",
        adm0_a3_fr: "TKM",
        adm0_a3_ru: "TKM",
        adm0_a3_es: "TKM",
        adm0_a3_cn: "TKM",
        adm0_a3_tw: "TKM",
        adm0_a3_in: "TKM",
        adm0_a3_np: "TKM",
        adm0_a3_pk: "TKM",
        adm0_a3_de: "TKM",
        adm0_a3_gb: "TKM",
        adm0_a3_br: "TKM",
        adm0_a3_il: "TKM",
        adm0_a3_ps: "TKM",
        adm0_a3_sa: "TKM",
        adm0_a3_eg: "TKM",
        adm0_a3_ma: "TKM",
        adm0_a3_pt: "TKM",
        adm0_a3_ar: "TKM",
        adm0_a3_jp: "TKM",
        adm0_a3_ko: "TKM",
        adm0_a3_vn: "TKM",
        adm0_a3_tr: "TKM",
        adm0_a3_id: "TKM",
        adm0_a3_pl: "TKM",
        adm0_a3_gr: "TKM",
        adm0_a3_it: "TKM",
        adm0_a3_nl: "TKM",
        adm0_a3_se: "TKM",
        adm0_a3_bd: "TKM",
        adm0_a3_ua: "TKM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 12,
        long_len: 12,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 58.676647,
        label_y: 39.855246,
        ne_id: 1159321309,
        wikidataid: "Q874",
        name_ar: "تركمانستان",
        name_bn: "তুর্কমেনিস্তান",
        name_de: "Turkmenistan",
        name_en: "Turkmenistan",
        name_es: "Turkmenistán",
        name_fa: "ترکمنستان",
        name_fr: "Turkménistan",
        name_el: "Τουρκμενιστάν",
        name_he: "טורקמניסטן",
        name_hi: "तुर्कमेनिस्तान",
        name_hu: "Türkmenisztán",
        name_id: "Turkmenistan",
        name_it: "Turkmenistan",
        name_ja: "トルクメニスタン",
        name_ko: "투르크메니스탄",
        name_nl: "Turkmenistan",
        name_pl: "Turkmenistan",
        name_pt: "Turquemenistão",
        name_ru: "Туркмения",
        name_sv: "Turkmenistan",
        name_tr: "Türkmenistan",
        name_uk: "Туркменістан",
        name_ur: "ترکمانستان",
        name_vi: "Turkmenistan",
        name_zh: "土库曼斯坦",
        name_zht: "土庫曼",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TKM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [52.50245975119615, 41.78331553808637],
            [52.944293247291654, 42.11603424739759],
            [54.07941775901495, 42.32410940202083],
            [54.75534549339264, 42.043971462566574],
            [55.45525109235377, 41.25985911718584],
            [55.96819135928291, 41.30864166926936],
            [57.0963912290791, 41.32231008561057],
            [56.932215203687804, 41.826026109375604],
            [57.78652998233708, 42.17055288346552],
            [58.62901085799146, 42.75155101172305],
            [59.976422153569786, 42.22308197689021],
            [60.083340691981675, 41.425146185871405],
            [60.465952996670694, 41.22032664648255],
            [61.54717898951356, 41.266370347654615],
            [61.88271406438469, 41.084856879229406],
            [62.374260288345006, 40.05388621679039],
            [63.51801476426103, 39.36325653742564],
            [64.17022301621677, 38.892406724598246],
            [65.2159989765074, 38.4026950139843],
            [66.54615034370022, 37.97468496352687],
            [66.51860680528867, 37.36278432875879],
            [66.21738488145934, 37.39379018813392],
            [65.74563073106683, 37.66116404881207],
            [65.58894778835784, 37.30521678318564],
            [64.7461051776774, 37.111817735333304],
            [64.5464791197339, 36.31207326918427],
            [63.98289594915871, 36.0079574651466],
            [63.19353844590035, 35.857165635718914],
            [62.98466230657661, 35.40404083916762],
            [62.230651483005886, 35.270663967422294],
            [61.210817091725744, 35.650072333309225],
            [61.123070509694145, 36.491597194966246],
            [60.37763797388388, 36.52738312432837],
            [59.23476199731681, 37.41298798273034],
            [58.436154412678206, 37.5223094752438],
            [57.330433790928986, 38.02922943781094],
            [56.61936608259282, 38.121394354803485],
            [56.18037479027333, 37.93512665460743],
            [55.51157840355191, 37.96411713312317],
            [54.800303989486565, 37.392420762678185],
            [53.92159793479556, 37.19891836196126],
            [53.73551110211252, 37.90613617609169],
            [53.880928582581845, 38.95209300389536],
            [53.1010278664329, 39.29057363540713],
            [53.35780805849123, 39.97528636327445],
            [52.69397260926982, 40.03362905533197],
            [52.915251092343624, 40.87652334244473],
            [53.858139275941134, 40.63103445084218],
            [54.73684533063215, 40.95101491959346],
            [54.008310988181314, 41.55121084244742],
            [53.72171349469059, 42.12319143327003],
            [52.916749708880076, 41.86811656347733],
            [52.81468875510362, 41.13537059179471],
            [52.50245975119615, 41.78331553808637],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Jordan",
        sov_a3: "JOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Jordan",
        adm0_a3: "JOR",
        geou_dif: 0,
        geounit: "Jordan",
        gu_a3: "JOR",
        su_dif: 0,
        subunit: "Jordan",
        su_a3: "JOR",
        brk_diff: 0,
        name: "Jordan",
        name_long: "Jordan",
        brk_a3: "JOR",
        brk_name: "Jordan",
        brk_group: null,
        abbrev: "Jord.",
        postal: "J",
        formal_en: "Hashemite Kingdom of Jordan",
        formal_fr: null,
        name_ciawf: "Jordan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Jordan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 10101694,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 44502,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "JO",
        iso_a2: "JO",
        iso_a2_eh: "JO",
        iso_a3: "JOR",
        iso_a3_eh: "JOR",
        iso_n3: "400",
        iso_n3_eh: "400",
        un_a3: "400",
        wb_a2: "JO",
        wb_a3: "JOR",
        woe_id: 23424860,
        woe_id_eh: 23424860,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JOR",
        adm0_diff: null,
        adm0_tlc: "JOR",
        adm0_a3_us: "JOR",
        adm0_a3_fr: "JOR",
        adm0_a3_ru: "JOR",
        adm0_a3_es: "JOR",
        adm0_a3_cn: "JOR",
        adm0_a3_tw: "JOR",
        adm0_a3_in: "JOR",
        adm0_a3_np: "JOR",
        adm0_a3_pk: "JOR",
        adm0_a3_de: "JOR",
        adm0_a3_gb: "JOR",
        adm0_a3_br: "JOR",
        adm0_a3_il: "JOR",
        adm0_a3_ps: "JOR",
        adm0_a3_sa: "JOR",
        adm0_a3_eg: "JOR",
        adm0_a3_ma: "JOR",
        adm0_a3_pt: "JOR",
        adm0_a3_ar: "JOR",
        adm0_a3_jp: "JOR",
        adm0_a3_ko: "JOR",
        adm0_a3_vn: "JOR",
        adm0_a3_tr: "JOR",
        adm0_a3_id: "JOR",
        adm0_a3_pl: "JOR",
        adm0_a3_gr: "JOR",
        adm0_a3_it: "JOR",
        adm0_a3_nl: "JOR",
        adm0_a3_se: "JOR",
        adm0_a3_bd: "JOR",
        adm0_a3_ua: "JOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 36.375991,
        label_y: 30.805025,
        ne_id: 1159320935,
        wikidataid: "Q810",
        name_ar: "الأردن",
        name_bn: "জর্ডান",
        name_de: "Jordanien",
        name_en: "Jordan",
        name_es: "Jordania",
        name_fa: "اردن",
        name_fr: "Jordanie",
        name_el: "Ιορδανία",
        name_he: "ירדן",
        name_hi: "जॉर्डन",
        name_hu: "Jordánia",
        name_id: "Yordania",
        name_it: "Giordania",
        name_ja: "ヨルダン",
        name_ko: "요르단",
        name_nl: "Jordanië",
        name_pl: "Jordania",
        name_pt: "Jordânia",
        name_ru: "Иордания",
        name_sv: "Jordanien",
        name_tr: "Ürdün",
        name_uk: "Йорданія",
        name_ur: "اردن",
        name_vi: "Jordan",
        name_zh: "约旦",
        name_zht: "約旦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JOR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.54566531753454, 32.393992011030576],
            [35.71991824722275, 32.709192409794866],
            [36.834062127435544, 32.312937526980775],
            [38.792340529136084, 33.378686428352225],
            [39.19546837744497, 32.16100881604267],
            [39.00488569515255, 32.01021698661498],
            [37.00216556168101, 31.508412990844747],
            [37.998848911294374, 30.508499864213135],
            [37.66811974462638, 30.3386652694859],
            [37.503581984209035, 30.003776150018407],
            [36.74052778498725, 29.86528331147619],
            [36.50121422704359, 29.505253607698705],
            [36.06894087092206, 29.197494615184453],
            [34.95603722508426, 29.356554673778845],
            [34.92260257339143, 29.501326198844524],
            [35.420918409981965, 31.100065822874356],
            [35.397560662586045, 31.489086005167582],
            [35.5452519060762, 31.78250478772084],
            [35.54566531753454, 32.393992011030576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Nepal",
        sov_a3: "NPL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nepal",
        adm0_a3: "NPL",
        geou_dif: 0,
        geounit: "Nepal",
        gu_a3: "NPL",
        su_dif: 0,
        subunit: "Nepal",
        su_a3: "NPL",
        brk_diff: 0,
        name: "Nepal",
        name_long: "Nepal",
        brk_a3: "NPL",
        brk_name: "Nepal",
        brk_group: null,
        abbrev: "Nepal",
        postal: "NP",
        formal_en: "Nepal",
        formal_fr: null,
        name_ciawf: "Nepal",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nepal",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 28608710,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 30641,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "NP",
        iso_a2: "NP",
        iso_a2_eh: "NP",
        iso_a3: "NPL",
        iso_a3_eh: "NPL",
        iso_n3: "524",
        iso_n3_eh: "524",
        un_a3: "524",
        wb_a2: "NP",
        wb_a3: "NPL",
        woe_id: 23424911,
        woe_id_eh: 23424911,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NPL",
        adm0_diff: null,
        adm0_tlc: "NPL",
        adm0_a3_us: "NPL",
        adm0_a3_fr: "NPL",
        adm0_a3_ru: "NPL",
        adm0_a3_es: "NPL",
        adm0_a3_cn: "NPL",
        adm0_a3_tw: "NPL",
        adm0_a3_in: "NPL",
        adm0_a3_np: "NPL",
        adm0_a3_pk: "NPL",
        adm0_a3_de: "NPL",
        adm0_a3_gb: "NPL",
        adm0_a3_br: "NPL",
        adm0_a3_il: "NPL",
        adm0_a3_ps: "NPL",
        adm0_a3_sa: "NPL",
        adm0_a3_eg: "NPL",
        adm0_a3_ma: "NPL",
        adm0_a3_pt: "NPL",
        adm0_a3_ar: "NPL",
        adm0_a3_jp: "NPL",
        adm0_a3_ko: "NPL",
        adm0_a3_vn: "NPL",
        adm0_a3_tr: "NPL",
        adm0_a3_id: "NPL",
        adm0_a3_pl: "NPL",
        adm0_a3_gr: "NPL",
        adm0_a3_it: "NPL",
        adm0_a3_nl: "NPL",
        adm0_a3_se: "NPL",
        adm0_a3_bd: "NPL",
        adm0_a3_ua: "NPL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 83.639914,
        label_y: 28.297925,
        ne_id: 1159321121,
        wikidataid: "Q837",
        name_ar: "نيبال",
        name_bn: "নেপাল",
        name_de: "Nepal",
        name_en: "Nepal",
        name_es: "Nepal",
        name_fa: "نپال",
        name_fr: "Népal",
        name_el: "Νεπάλ",
        name_he: "נפאל",
        name_hi: "नेपाल",
        name_hu: "Nepál",
        name_id: "Nepal",
        name_it: "Nepal",
        name_ja: "ネパール",
        name_ko: "네팔",
        name_nl: "Nepal",
        name_pl: "Nepal",
        name_pt: "Nepal",
        name_ru: "Непал",
        name_sv: "Nepal",
        name_tr: "Nepal",
        name_uk: "Непал",
        name_ur: "نیپال",
        name_vi: "Nepal",
        name_zh: "尼泊尔",
        name_zht: "尼泊爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NPL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.12044070836987, 27.876541652939594],
            [88.04313276566123, 27.445818589786825],
            [88.17480431514092, 26.81040517832595],
            [88.06023766474982, 26.41461538340249],
            [87.2274719583663, 26.397898057556077],
            [86.02439293817918, 26.63098460540857],
            [85.25177859898338, 26.726198431906344],
            [84.6750179381738, 27.234901231387536],
            [83.30424889519955, 27.36450572357556],
            [81.99998742058497, 27.925479234319994],
            [81.05720258985203, 28.416095282499043],
            [80.08842451367627, 28.79447011974014],
            [80.4767212259174, 29.72986522065534],
            [81.11125613802932, 30.183480943313402],
            [81.52580447787474, 30.42271698660863],
            [82.32751264845088, 30.115268052688137],
            [83.33711510613719, 29.463731594352197],
            [83.89899295444673, 29.320226141877658],
            [84.23457970575015, 28.839893703724698],
            [85.01163821812304, 28.642773952747344],
            [85.82331994013151, 28.203575954698707],
            [86.9545170430006, 27.974261786403517],
            [88.12044070836987, 27.876541652939594],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Yemen",
        sov_a3: "YEM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Yemen",
        adm0_a3: "YEM",
        geou_dif: 0,
        geounit: "Yemen",
        gu_a3: "YEM",
        su_dif: 0,
        subunit: "Yemen",
        su_a3: "YEM",
        brk_diff: 0,
        name: "Yemen",
        name_long: "Yemen",
        brk_a3: "YEM",
        brk_name: "Yemen",
        brk_group: null,
        abbrev: "Yem.",
        postal: "YE",
        formal_en: "Republic of Yemen",
        formal_fr: null,
        name_ciawf: "Yemen",
        note_adm0: null,
        note_brk: null,
        name_sort: "Yemen, Rep.",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 29161922,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 22581,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "YM",
        iso_a2: "YE",
        iso_a2_eh: "YE",
        iso_a3: "YEM",
        iso_a3_eh: "YEM",
        iso_n3: "887",
        iso_n3_eh: "887",
        un_a3: "887",
        wb_a2: "RY",
        wb_a3: "YEM",
        woe_id: 23425002,
        woe_id_eh: 23425002,
        woe_note: "Exact WOE match as country",
        adm0_iso: "YEM",
        adm0_diff: null,
        adm0_tlc: "YEM",
        adm0_a3_us: "YEM",
        adm0_a3_fr: "YEM",
        adm0_a3_ru: "YEM",
        adm0_a3_es: "YEM",
        adm0_a3_cn: "YEM",
        adm0_a3_tw: "YEM",
        adm0_a3_in: "YEM",
        adm0_a3_np: "YEM",
        adm0_a3_pk: "YEM",
        adm0_a3_de: "YEM",
        adm0_a3_gb: "YEM",
        adm0_a3_br: "YEM",
        adm0_a3_il: "YEM",
        adm0_a3_ps: "YEM",
        adm0_a3_sa: "YEM",
        adm0_a3_eg: "YEM",
        adm0_a3_ma: "YEM",
        adm0_a3_pt: "YEM",
        adm0_a3_ar: "YEM",
        adm0_a3_jp: "YEM",
        adm0_a3_ko: "YEM",
        adm0_a3_vn: "YEM",
        adm0_a3_tr: "YEM",
        adm0_a3_id: "YEM",
        adm0_a3_pl: "YEM",
        adm0_a3_gr: "YEM",
        adm0_a3_it: "YEM",
        adm0_a3_nl: "YEM",
        adm0_a3_se: "YEM",
        adm0_a3_bd: "YEM",
        adm0_a3_ua: "YEM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 45.874383,
        label_y: 15.328226,
        ne_id: 1159321425,
        wikidataid: "Q805",
        name_ar: "اليمن",
        name_bn: "ইয়েমেন",
        name_de: "Jemen",
        name_en: "Yemen",
        name_es: "Yemen",
        name_fa: "یمن",
        name_fr: "Yémen",
        name_el: "Υεμένη",
        name_he: "תימן",
        name_hi: "यमन",
        name_hu: "Jemen",
        name_id: "Yaman",
        name_it: "Yemen",
        name_ja: "イエメン",
        name_ko: "예멘",
        name_nl: "Jemen",
        name_pl: "Jemen",
        name_pt: "Iémen",
        name_ru: "Йемен",
        name_sv: "Jemen",
        name_tr: "Yemen",
        name_uk: "Ємен",
        name_ur: "یمن",
        name_vi: "Yemen",
        name_zh: "也门",
        name_zht: "葉門",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "YEM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [52.00000980002224, 19.000003363516058],
            [52.78218427919205, 17.349742336491232],
            [53.10857262554751, 16.651051133688952],
            [52.38520592632588, 16.382411200419654],
            [52.19172936382509, 15.93843313238402],
            [52.1681649107, 15.597420355689948],
            [51.172515089732485, 15.175249742081492],
            [49.57457645040315, 14.708766587782748],
            [48.67923058451416, 14.00320241948566],
            [48.23894738138742, 13.948089504446372],
            [47.938914015500785, 14.007233181204427],
            [47.354453566279716, 13.592219753468383],
            [46.717076450391744, 13.39969920496502],
            [45.87759280781027, 13.347764390511685],
            [45.62505008319988, 13.290946153206763],
            [45.406458774605255, 13.026905422411433],
            [45.14435591002086, 12.95393830001531],
            [44.989533318874415, 12.69958690027471],
            [44.49457645038285, 12.721652736863348],
            [44.17511274595449, 12.585950425664876],
            [43.48295861183713, 12.636800035040084],
            [43.22287112811213, 13.220950425667425],
            [43.25144819516953, 13.767583726450852],
            [43.08794396339806, 14.06263031662131],
            [42.892245314308724, 14.802249253798749],
            [42.60487267433362, 15.213335272680595],
            [42.80501549660005, 15.261962795467255],
            [42.70243777850066, 15.718885809791999],
            [42.823670688657415, 15.911742255105267],
            [42.77933230975097, 16.347891343648683],
            [43.21837527850275, 16.66688996018641],
            [43.11579756040336, 17.088440456607373],
            [43.380794305196105, 17.57998668056767],
            [43.79151858905192, 17.31997671149111],
            [44.06261315285508, 17.410358791569593],
            [45.21665123879719, 17.433328965723334],
            [45.39999922056876, 17.333335069238558],
            [46.366658563020536, 17.233315334537636],
            [46.74999433776165, 17.283338120996177],
            [47.000004917189756, 16.949999294497445],
            [47.46669477721763, 17.116681626854884],
            [48.18334354024134, 18.166669216377315],
            [49.11667158386487, 18.616667588774945],
            [52.00000980002224, 19.000003363516058],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Northern Cyprus",
        sov_a3: "CYN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Northern Cyprus",
        adm0_a3: "CYN",
        geou_dif: 0,
        geounit: "Northern Cyprus",
        gu_a3: "CYN",
        su_dif: 0,
        subunit: "Northern Cyprus",
        su_a3: "CYN",
        brk_diff: 0,
        name: "N. Cyprus",
        name_long: "Northern Cyprus",
        brk_a3: "CYN",
        brk_name: "N. Cyprus",
        brk_group: null,
        abbrev: "N. Cy.",
        postal: "CN",
        formal_en: "Turkish Republic of Northern Cyprus",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Self admin.",
        note_brk: "Self admin.; Claimed by Cyprus",
        name_sort: "Cyprus, Northern",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 326000,
        pop_rank: 10,
        pop_year: 2017,
        gdp_md: 3600,
        gdp_year: 2013,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "-99",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -90,
        woe_id_eh: 23424995,
        woe_note: "WOE lists as subunit of united Cyprus",
        adm0_iso: "CYP",
        adm0_diff: "1",
        adm0_tlc: "CYN",
        adm0_a3_us: "CYP",
        adm0_a3_fr: "CYP",
        adm0_a3_ru: "CYP",
        adm0_a3_es: "CYP",
        adm0_a3_cn: "CYP",
        adm0_a3_tw: "CYP",
        adm0_a3_in: "CYP",
        adm0_a3_np: "CYP",
        adm0_a3_pk: "CYP",
        adm0_a3_de: "CYP",
        adm0_a3_gb: "CYP",
        adm0_a3_br: "CYP",
        adm0_a3_il: "CYP",
        adm0_a3_ps: "CYP",
        adm0_a3_sa: "CYP",
        adm0_a3_eg: "CYP",
        adm0_a3_ma: "CYP",
        adm0_a3_pt: "CYP",
        adm0_a3_ar: "CYP",
        adm0_a3_jp: "CYP",
        adm0_a3_ko: "CYP",
        adm0_a3_vn: "CYP",
        adm0_a3_tr: "CYN",
        adm0_a3_id: "CYP",
        adm0_a3_pl: "CYP",
        adm0_a3_gr: "CYP",
        adm0_a3_it: "CYP",
        adm0_a3_nl: "CYP",
        adm0_a3_se: "CYP",
        adm0_a3_bd: "CYP",
        adm0_a3_ua: "CYP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 15,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 6,
        max_label: 10,
        label_x: 33.692434,
        label_y: 35.216071,
        ne_id: 1159320531,
        wikidataid: "Q23681",
        name_ar: "قبرص الشمالية",
        name_bn: "উত্তর সাইপ্রাস",
        name_de: "Türkische Republik Nordzypern",
        name_en: "Turkish Republic of Northern Cyprus",
        name_es: "República Turca del Norte de Chipre",
        name_fa: "جمهوری ترک قبرس شمالی",
        name_fr: "Chypre du Nord",
        name_el: "Τουρκική Δημοκρατία της Βόρειας Κύπρου",
        name_he: "הרפובליקה הטורקית של צפון קפריסין",
        name_hi: "उत्तरी साइप्रस",
        name_hu: "Észak-Ciprus",
        name_id: "Republik Turki Siprus Utara",
        name_it: "Cipro del Nord",
        name_ja: "北キプロス・トルコ共和国",
        name_ko: "북키프로스",
        name_nl: "Noord-Cyprus",
        name_pl: "Cypr Północny",
        name_pt: "República Turca do Chipre do Norte",
        name_ru: "Турецкая Республика Северного Кипра",
        name_sv: "Nordcypern",
        name_tr: "Kuzey Kıbrıs Türk Cumhuriyeti",
        name_uk: "Турецька Республіка Північного Кіпру",
        name_ur: "ترک جمہوریہ شمالی قبرص",
        name_vi: "Bắc Síp",
        name_zh: "北塞浦路斯土耳其共和国",
        name_zht: "北賽普勒斯土耳其共和國",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: "Admin-0 breakaway and disputed",
        fclass_fr: "Unrecognized",
        fclass_ru: "Unrecognized",
        fclass_es: "Unrecognized",
        fclass_cn: "Unrecognized",
        fclass_tw: "Unrecognized",
        fclass_in: "Unrecognized",
        fclass_np: "Unrecognized",
        fclass_pk: "Unrecognized",
        fclass_de: "Unrecognized",
        fclass_gb: "Unrecognized",
        fclass_br: "Unrecognized",
        fclass_il: "Unrecognized",
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: "Unrecognized",
        fclass_ma: "Unrecognized",
        fclass_pt: "Unrecognized",
        fclass_ar: "Unrecognized",
        fclass_jp: "Unrecognized",
        fclass_ko: "Unrecognized",
        fclass_vn: "Unrecognized",
        fclass_tr: "Admin-0 country",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: "Unrecognized",
        fclass_it: "Unrecognized",
        fclass_nl: "Unrecognized",
        fclass_se: "Unrecognized",
        fclass_bd: "Unrecognized",
        fclass_ua: "Unrecognized",
        filename: "CYN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.73178022637745, 35.14002594658844],
            [32.80247358575275, 35.14550364841138],
            [32.946960890440806, 35.3867033961337],
            [33.667227003724946, 35.37321584730552],
            [34.576473829900465, 35.67159556735879],
            [33.900804477684204, 35.245755927057616],
            [33.97361657078346, 35.058506374648005],
            [33.86643965021011, 35.09359467217419],
            [33.675391880027064, 35.01786286065045],
            [33.5256852556775, 35.03868846286407],
            [33.47581749851585, 35.000344550103506],
            [33.45592207208347, 35.10142365166641],
            [33.3838334490363, 35.16271190036457],
            [33.19097700372305, 35.17312470147138],
            [32.919572381326134, 35.08783274997364],
            [32.73178022637745, 35.14002594658844],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Philippines",
        sov_a3: "PHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Philippines",
        adm0_a3: "PHL",
        geou_dif: 0,
        geounit: "Philippines",
        gu_a3: "PHL",
        su_dif: 0,
        subunit: "Philippines",
        su_a3: "PHL",
        brk_diff: 0,
        name: "Philippines",
        name_long: "Philippines",
        brk_a3: "PHL",
        brk_name: "Philippines",
        brk_group: null,
        abbrev: "Phil.",
        postal: "PH",
        formal_en: "Republic of the Philippines",
        formal_fr: null,
        name_ciawf: "Philippines",
        note_adm0: null,
        note_brk: null,
        name_sort: "Philippines",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 8,
        pop_est: 108116615,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 376795,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "RP",
        iso_a2: "PH",
        iso_a2_eh: "PH",
        iso_a3: "PHL",
        iso_a3_eh: "PHL",
        iso_n3: "608",
        iso_n3_eh: "608",
        un_a3: "608",
        wb_a2: "PH",
        wb_a3: "PHL",
        woe_id: 23424934,
        woe_id_eh: 23424934,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PHL",
        adm0_diff: null,
        adm0_tlc: "PHL",
        adm0_a3_us: "PHL",
        adm0_a3_fr: "PHL",
        adm0_a3_ru: "PHL",
        adm0_a3_es: "PHL",
        adm0_a3_cn: "PHL",
        adm0_a3_tw: "PHL",
        adm0_a3_in: "PHL",
        adm0_a3_np: "PHL",
        adm0_a3_pk: "PHL",
        adm0_a3_de: "PHL",
        adm0_a3_gb: "PHL",
        adm0_a3_br: "PHL",
        adm0_a3_il: "PHL",
        adm0_a3_ps: "PHL",
        adm0_a3_sa: "PHL",
        adm0_a3_eg: "PHL",
        adm0_a3_ma: "PHL",
        adm0_a3_pt: "PHL",
        adm0_a3_ar: "PHL",
        adm0_a3_jp: "PHL",
        adm0_a3_ko: "PHL",
        adm0_a3_vn: "PHL",
        adm0_a3_tr: "PHL",
        adm0_a3_id: "PHL",
        adm0_a3_pl: "PHL",
        adm0_a3_gr: "PHL",
        adm0_a3_it: "PHL",
        adm0_a3_nl: "PHL",
        adm0_a3_se: "PHL",
        adm0_a3_bd: "PHL",
        adm0_a3_ua: "PHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 122.465,
        label_y: 11.198,
        ne_id: 1159321169,
        wikidataid: "Q928",
        name_ar: "الفلبين",
        name_bn: "ফিলিপাইন",
        name_de: "Philippinen",
        name_en: "Philippines",
        name_es: "Filipinas",
        name_fa: "فیلیپین",
        name_fr: "Philippines",
        name_el: "Φιλιππίνες",
        name_he: "הפיליפינים",
        name_hi: "फ़िलीपीन्स",
        name_hu: "Fülöp-szigetek",
        name_id: "Filipina",
        name_it: "Filippine",
        name_ja: "フィリピン",
        name_ko: "필리핀",
        name_nl: "Filipijnen",
        name_pl: "Filipiny",
        name_pt: "Filipinas",
        name_ru: "Филиппины",
        name_sv: "Filippinerna",
        name_tr: "Filipinler",
        name_uk: "Філіппіни",
        name_ur: "فلپائن",
        name_vi: "Philippines",
        name_zh: "菲律宾",
        name_zht: "菲律賓",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [120.83389611214656, 12.704496161342433],
              [120.32343631396745, 13.466413479053825],
              [121.18012820850211, 13.429697373910443],
              [121.5273938335035, 13.06959015548452],
              [121.26219038298159, 12.205560207564403],
              [120.83389611214656, 12.704496161342433],
            ],
          ],
          [
            [
              [122.5860889018671, 9.981044826696134],
              [122.83708133350875, 10.261156927934225],
              [122.9474105164519, 10.88186839440806],
              [123.49884972543845, 10.94062449792392],
              [123.33777428598472, 10.267383938025404],
              [124.0779358257012, 11.232725531453738],
              [123.98243777882584, 10.278778591345755],
              [123.62318322153271, 9.950090643753299],
              [123.30992068897939, 9.318268744336706],
              [122.99588300994166, 9.022188625520414],
              [122.38005496631942, 9.713360907424217],
              [122.5860889018671, 9.981044826696134],
            ],
          ],
          [
            [
              [126.37681359263745, 8.414706325713297],
              [126.47851281138789, 7.750354112168978],
              [126.53742394420061, 7.189380601424545],
              [126.19677290253259, 6.274294338400054],
              [125.83142052622907, 7.293715318221842],
              [125.36385216685221, 6.786485297060949],
              [125.68316084198369, 6.049656887227272],
              [125.39651167206063, 5.581003322772276],
              [124.21978763234239, 6.161355495626154],
              [123.9387195171069, 6.885135606306136],
              [124.24366214406128, 7.360610459823661],
              [123.61021243702749, 7.83352732994274],
              [123.29607140512519, 7.418875637232759],
              [122.82550581267537, 7.457374579290204],
              [122.08549930225573, 6.899424139834836],
              [121.91992801319256, 7.192119452336015],
              [122.31235884001705, 8.034962063016465],
              [122.94239790251959, 8.316236883981134],
              [123.48768761606347, 8.69300975182118],
              [123.84115441293983, 8.240324204944372],
              [124.60146976125021, 8.514157619659002],
              [124.76461225799562, 8.960409450715488],
              [125.47139082245155, 8.986996975129657],
              [125.41211795461277, 9.760334784377534],
              [126.2227144715431, 9.286074327018866],
              [126.30663699758514, 8.78248749433456],
              [126.37681359263745, 8.414706325713297],
            ],
          ],
          [
            [
              [118.50458092659036, 9.316382554558047],
              [117.17427453010066, 8.367499904814679],
              [117.66447716682137, 9.066888739452892],
              [118.38691369026175, 9.68449961998921],
              [118.98734215706105, 10.376292019080523],
              [119.51149620979751, 11.3696680770272],
              [119.68967654833989, 10.554291490109875],
              [119.02945844937892, 10.003653265823829],
              [118.50458092659036, 9.316382554558047],
            ],
          ],
          [
            [
              [122.336956821788, 18.224882717354106],
              [122.17427941293317, 17.810282701076403],
              [122.5156539246533, 17.093504746971973],
              [122.25231082569393, 16.26244436285407],
              [121.66278608610821, 15.931017564350142],
              [121.50506961475342, 15.124813544164622],
              [121.7288285665772, 14.328376369682275],
              [122.25892540902726, 14.21820221603599],
              [122.70127566944569, 14.336541245984378],
              [123.9502950379403, 13.782130642141027],
              [123.85510704965867, 13.237771104378425],
              [124.18128869028493, 12.9975273706535],
              [124.0774190613783, 12.536676947474575],
              [123.29803510955225, 13.02752553959894],
              [122.92865197152994, 13.552919826710422],
              [122.6713550151487, 13.185836289925092],
              [122.03464969288052, 13.784481919810304],
              [121.12638471891857, 13.636687323455547],
              [120.62863732308324, 13.857655747935596],
              [120.67938357959386, 14.271015529838294],
              [120.99181928923053, 14.52539276779504],
              [120.69333621631267, 14.756670640517312],
              [120.56414513558299, 14.396279201713796],
              [120.07042850146644, 14.970869452367126],
              [119.92092858284605, 15.406346747290739],
              [119.88377322802819, 16.363704331929995],
              [120.28648766487875, 16.034628811095345],
              [120.39004723519167, 17.599081122299523],
              [120.71586714079191, 18.505227362537525],
              [121.32130822152351, 18.504064642810945],
              [121.9376013530364, 18.218552354398355],
              [122.24600630095429, 18.478949896717097],
              [122.336956821788, 18.224882717354106],
            ],
          ],
          [
            [
              [122.03837039600555, 11.415840969279998],
              [121.88354780485909, 11.891755072471994],
              [122.48382124236149, 11.582187404827538],
              [123.12021650603594, 11.583660183147856],
              [123.10083784392646, 11.16593374271649],
              [122.63771365772669, 10.74130849857417],
              [122.00261030485957, 10.441016750526089],
              [121.96736697803652, 10.905691229694625],
              [122.03837039600555, 11.415840969279998],
            ],
          ],
          [
            [
              [125.50255171112354, 12.162694606978292],
              [125.78346479706221, 11.046121934447783],
              [125.01188398651226, 11.311454576050409],
              [125.03276126515817, 10.975816148314692],
              [125.27744917206019, 10.358722032101284],
              [124.80181928924577, 10.134678859899864],
              [124.76016808481853, 10.837995103392258],
              [124.45910119028605, 10.889929917845592],
              [124.30252160044171, 11.495370998577187],
              [124.89101281138153, 11.415582587118536],
              [124.87799035044401, 11.794189968304947],
              [124.26676150929569, 12.557760931849671],
              [125.22711632700788, 12.53572093347718],
              [125.50255171112354, 12.162694606978292],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Sri Lanka",
        sov_a3: "LKA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sri Lanka",
        adm0_a3: "LKA",
        geou_dif: 0,
        geounit: "Sri Lanka",
        gu_a3: "LKA",
        su_dif: 0,
        subunit: "Sri Lanka",
        su_a3: "LKA",
        brk_diff: 0,
        name: "Sri Lanka",
        name_long: "Sri Lanka",
        brk_a3: "LKA",
        brk_name: "Sri Lanka",
        brk_group: null,
        abbrev: "Sri L.",
        postal: "LK",
        formal_en: "Democratic Socialist Republic of Sri Lanka",
        formal_fr: null,
        name_ciawf: "Sri Lanka",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sri Lanka",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 21803000,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 84008,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CE",
        iso_a2: "LK",
        iso_a2_eh: "LK",
        iso_a3: "LKA",
        iso_a3_eh: "LKA",
        iso_n3: "144",
        iso_n3_eh: "144",
        un_a3: "144",
        wb_a2: "LK",
        wb_a3: "LKA",
        woe_id: 23424778,
        woe_id_eh: 23424778,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LKA",
        adm0_diff: null,
        adm0_tlc: "LKA",
        adm0_a3_us: "LKA",
        adm0_a3_fr: "LKA",
        adm0_a3_ru: "LKA",
        adm0_a3_es: "LKA",
        adm0_a3_cn: "LKA",
        adm0_a3_tw: "LKA",
        adm0_a3_in: "LKA",
        adm0_a3_np: "LKA",
        adm0_a3_pk: "LKA",
        adm0_a3_de: "LKA",
        adm0_a3_gb: "LKA",
        adm0_a3_br: "LKA",
        adm0_a3_il: "LKA",
        adm0_a3_ps: "LKA",
        adm0_a3_sa: "LKA",
        adm0_a3_eg: "LKA",
        adm0_a3_ma: "LKA",
        adm0_a3_pt: "LKA",
        adm0_a3_ar: "LKA",
        adm0_a3_jp: "LKA",
        adm0_a3_ko: "LKA",
        adm0_a3_vn: "LKA",
        adm0_a3_tr: "LKA",
        adm0_a3_id: "LKA",
        adm0_a3_pl: "LKA",
        adm0_a3_gr: "LKA",
        adm0_a3_it: "LKA",
        adm0_a3_nl: "LKA",
        adm0_a3_se: "LKA",
        adm0_a3_bd: "LKA",
        adm0_a3_ua: "LKA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 80.704823,
        label_y: 7.581097,
        ne_id: 1159321025,
        wikidataid: "Q854",
        name_ar: "سريلانكا",
        name_bn: "শ্রীলঙ্কা",
        name_de: "Sri Lanka",
        name_en: "Sri Lanka",
        name_es: "Sri Lanka",
        name_fa: "سریلانکا",
        name_fr: "Sri Lanka",
        name_el: "Σρι Λάνκα",
        name_he: "סרי לנקה",
        name_hi: "श्रीलंका",
        name_hu: "Srí Lanka",
        name_id: "Sri Lanka",
        name_it: "Sri Lanka",
        name_ja: "スリランカ",
        name_ko: "스리랑카",
        name_nl: "Sri Lanka",
        name_pl: "Sri Lanka",
        name_pt: "Sri Lanka",
        name_ru: "Шри-Ланка",
        name_sv: "Sri Lanka",
        name_tr: "Sri Lanka",
        name_uk: "Шрі-Ланка",
        name_ur: "سری لنکا",
        name_vi: "Sri Lanka",
        name_zh: "斯里兰卡",
        name_zht: "斯里蘭卡",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LKA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.7879590188914, 7.523055324733164],
            [81.63732221876059, 6.481775214051922],
            [81.21801964714433, 6.197141424988288],
            [80.34835696810441, 5.968369859232155],
            [79.87246870312853, 6.76346344647493],
            [79.69516686393513, 8.200843410673386],
            [80.14780073437964, 9.824077663609557],
            [80.83881798698656, 9.268426825391188],
            [81.30431928907177, 8.56420624433369],
            [81.7879590188914, 7.523055324733164],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Taiwan",
        sov_a3: "TWN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Taiwan",
        adm0_a3: "TWN",
        geou_dif: 0,
        geounit: "Taiwan",
        gu_a3: "TWN",
        su_dif: 0,
        subunit: "Taiwan",
        su_a3: "TWN",
        brk_diff: 0,
        name: "Taiwan",
        name_long: "Taiwan",
        brk_a3: "TWN",
        brk_name: "Taiwan",
        brk_group: null,
        abbrev: "Taiwan",
        postal: "TW",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Taiwan",
        note_adm0: null,
        note_brk: "Self admin.; Claimed by China",
        name_sort: "Taiwan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 23568378,
        pop_rank: 15,
        pop_year: 2020,
        gdp_md: 1127000,
        gdp_year: 2016,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "TW",
        iso_a2: "CN-TW",
        iso_a2_eh: "TW",
        iso_a3: "TWN",
        iso_a3_eh: "TWN",
        iso_n3: "158",
        iso_n3_eh: "158",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424971,
        woe_id_eh: 23424971,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TWN",
        adm0_diff: null,
        adm0_tlc: "TWN",
        adm0_a3_us: "TWN",
        adm0_a3_fr: "TWN",
        adm0_a3_ru: "CHN",
        adm0_a3_es: "TWN",
        adm0_a3_cn: "CHN",
        adm0_a3_tw: "TWN",
        adm0_a3_in: "TWN",
        adm0_a3_np: "CHN",
        adm0_a3_pk: "CHN",
        adm0_a3_de: "TWN",
        adm0_a3_gb: "TWN",
        adm0_a3_br: "TWN",
        adm0_a3_il: "TWN",
        adm0_a3_ps: "TWN",
        adm0_a3_sa: "TWN",
        adm0_a3_eg: "CHN",
        adm0_a3_ma: "CHN",
        adm0_a3_pt: "TWN",
        adm0_a3_ar: "TWN",
        adm0_a3_jp: "TWN",
        adm0_a3_ko: "TWN",
        adm0_a3_vn: "TWN",
        adm0_a3_tr: "TWN",
        adm0_a3_id: "CHN",
        adm0_a3_pl: "TWN",
        adm0_a3_gr: "TWN",
        adm0_a3_it: "TWN",
        adm0_a3_nl: "TWN",
        adm0_a3_se: "TWN",
        adm0_a3_bd: "CHN",
        adm0_a3_ua: "TWN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 8,
        label_x: 120.868204,
        label_y: 23.652408,
        ne_id: 1159321335,
        wikidataid: "Q865",
        name_ar: "تايوان",
        name_bn: "তাইওয়ান",
        name_de: "Republik China",
        name_en: "Taiwan",
        name_es: "República de China",
        name_fa: "تایوان",
        name_fr: "Taïwan",
        name_el: "Δημοκρατία της Κίνας",
        name_he: "טאיוואן",
        name_hi: "चीनी गणराज्य",
        name_hu: "Kínai Köztársaság",
        name_id: "Taiwan",
        name_it: "Taiwan",
        name_ja: "中華民国",
        name_ko: "중화민국",
        name_nl: "Taiwan",
        name_pl: "Republika Chińska",
        name_pt: "Taiwan",
        name_ru: "Тайвань",
        name_sv: "Taiwan",
        name_tr: "Çin Cumhuriyeti",
        name_uk: "Республіка Китай",
        name_ur: "تائیوان",
        name_vi: "Đài Loan",
        name_zh: "中华民国",
        name_zht: "中華民國",
        fclass_iso: "Admin-1 states provinces",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: "Admin-1 states provinces",
        fclass_tw: "Admin-0 country",
        fclass_in: null,
        fclass_np: "Admin-1 states provinces",
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: "Admin-1 states provinces",
        fclass_ma: "Admin-1 states provinces",
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: "Admin-1 states provinces",
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: "Admin-1 states provinces",
        fclass_ua: null,
        filename: "TWN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.77781782438993, 24.3942735865194],
            [121.17563235889274, 22.790857245367167],
            [120.74707970589623, 21.970571397382113],
            [120.22008344938368, 22.81486094816674],
            [120.1061885926124, 23.556262722258236],
            [120.69467980355225, 24.538450832613737],
            [121.49504438688878, 25.295458889257386],
            [121.95124393116146, 24.997595933527037],
            [121.77781782438993, 24.3942735865194],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Japan",
        sov_a3: "JPN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Japan",
        adm0_a3: "JPN",
        geou_dif: 0,
        geounit: "Japan",
        gu_a3: "JPN",
        su_dif: 0,
        subunit: "Japan",
        su_a3: "JPN",
        brk_diff: 0,
        name: "Japan",
        name_long: "Japan",
        brk_a3: "JPN",
        brk_name: "Japan",
        brk_group: null,
        abbrev: "Japan",
        postal: "J",
        formal_en: "Japan",
        formal_fr: null,
        name_ciawf: "Japan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Japan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 126264931,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 5081769,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "JA",
        iso_a2: "JP",
        iso_a2_eh: "JP",
        iso_a3: "JPN",
        iso_a3_eh: "JPN",
        iso_n3: "392",
        iso_n3_eh: "392",
        un_a3: "392",
        wb_a2: "JP",
        wb_a3: "JPN",
        woe_id: 23424856,
        woe_id_eh: 23424856,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JPN",
        adm0_diff: null,
        adm0_tlc: "JPN",
        adm0_a3_us: "JPN",
        adm0_a3_fr: "JPN",
        adm0_a3_ru: "JPN",
        adm0_a3_es: "JPN",
        adm0_a3_cn: "JPN",
        adm0_a3_tw: "JPN",
        adm0_a3_in: "JPN",
        adm0_a3_np: "JPN",
        adm0_a3_pk: "JPN",
        adm0_a3_de: "JPN",
        adm0_a3_gb: "JPN",
        adm0_a3_br: "JPN",
        adm0_a3_il: "JPN",
        adm0_a3_ps: "JPN",
        adm0_a3_sa: "JPN",
        adm0_a3_eg: "JPN",
        adm0_a3_ma: "JPN",
        adm0_a3_pt: "JPN",
        adm0_a3_ar: "JPN",
        adm0_a3_jp: "JPN",
        adm0_a3_ko: "JPN",
        adm0_a3_vn: "JPN",
        adm0_a3_tr: "JPN",
        adm0_a3_id: "JPN",
        adm0_a3_pl: "JPN",
        adm0_a3_gr: "JPN",
        adm0_a3_it: "JPN",
        adm0_a3_nl: "JPN",
        adm0_a3_se: "JPN",
        adm0_a3_bd: "JPN",
        adm0_a3_ua: "JPN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 7,
        label_x: 138.44217,
        label_y: 36.142538,
        ne_id: 1159320937,
        wikidataid: "Q17",
        name_ar: "اليابان",
        name_bn: "জাপান",
        name_de: "Japan",
        name_en: "Japan",
        name_es: "Japón",
        name_fa: "ژاپن",
        name_fr: "Japon",
        name_el: "Ιαπωνία",
        name_he: "יפן",
        name_hi: "जापान",
        name_hu: "Japán",
        name_id: "Jepang",
        name_it: "Giappone",
        name_ja: "日本",
        name_ko: "일본",
        name_nl: "Japan",
        name_pl: "Japonia",
        name_pt: "Japão",
        name_ru: "Япония",
        name_sv: "Japan",
        name_tr: "Japonya",
        name_uk: "Японія",
        name_ur: "جاپان",
        name_vi: "Nhật Bản",
        name_zh: "日本",
        name_zht: "日本",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JPN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [141.88460086483497, 39.180864569651476],
              [140.95948937394581, 38.17400096287662],
              [140.97638756730527, 37.14207428644019],
              [140.5997697287621, 36.3439834661245],
              [140.77407433488258, 35.842877102190215],
              [140.2532792502451, 35.138113918593646],
              [138.9755277853962, 34.66760000257614],
              [137.21759891169125, 34.60628591566183],
              [135.79298302626893, 33.46480520276663],
              [135.1209827007454, 33.849071153289],
              [135.0794348491827, 34.596544908174806],
              [133.34031619683202, 34.375938218720805],
              [132.15677086805124, 33.90493337659652],
              [130.98614464734345, 33.88576142021624],
              [132.00003624890996, 33.149992377244544],
              [131.33279015515734, 31.450354519164822],
              [130.686317987186, 31.029579169228246],
              [130.2024198752049, 31.418237616495432],
              [130.44767622286213, 32.319474595665696],
              [129.81469160371893, 32.61030955660436],
              [129.40846316947255, 33.29605581311752],
              [130.35393517468464, 33.60415070244167],
              [130.87845096244718, 34.23274282484002],
              [131.8842293641439, 34.74971385348792],
              [132.61767296766243, 35.433393052709405],
              [134.6083008159777, 35.731617743465804],
              [135.67753787652885, 35.52713410088687],
              [136.72383060114242, 37.304984239240326],
              [137.39061160700447, 36.82739065199884],
              [138.8576021669062, 37.82748464614346],
              [139.42640465714283, 38.21596222589761],
              [140.054790073812, 39.43880748143637],
              [139.88337934789985, 40.56331248632368],
              [140.30578250545364, 41.19500519465953],
              [141.3689734234266, 41.378559882160275],
              [141.91426313697048, 39.991616115878685],
              [141.88460086483497, 39.180864569651476],
            ],
          ],
          [
            [
              [144.61342654843963, 43.96088288021748],
              [145.32082523008307, 44.38473297787541],
              [145.5431372418027, 43.26208832455062],
              [144.05966189999987, 42.98835826270056],
              [143.18384972551723, 41.9952147486992],
              [141.6114909201724, 42.6787905950561],
              [141.06728641170668, 41.58459381770797],
              [139.955106235921, 41.56955597591103],
              [139.81754357315998, 42.563758856774385],
              [140.31208703019325, 43.33327261003269],
              [141.38054894426, 43.38882477474644],
              [141.67195234595386, 44.77212535255146],
              [141.96764489152798, 45.55148346616134],
              [143.14287031470974, 44.51035838477697],
              [143.91016198137947, 44.17409983985374],
              [144.61342654843963, 43.96088288021748],
            ],
          ],
          [
            [
              [132.37117638563024, 33.46364248304006],
              [132.92437259331473, 34.06029857028204],
              [133.4929683778222, 33.94462087659667],
              [133.90410607313635, 34.36493113864263],
              [134.63842817600386, 34.149233710256354],
              [134.76637902235854, 33.80633474378362],
              [134.2034159689709, 33.20117788342964],
              [133.79295006727654, 33.5219851750976],
              [133.28026818250885, 33.28957042086489],
              [133.0148580262578, 32.70456736910474],
              [132.36311486219267, 32.989382025681394],
              [132.37117638563024, 33.46364248304006],
            ],
          ],
        ],
      },
    },
  ],
};

export default data;
