/* eslint-disable react/prop-types */
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import ProfilePopUP from "./ProfilePopUp";
import "./App.css";

function Header() {     
  
  return (
    <div>
      <Container maxWidth={false} sx={{ bgcolor: "background.default" }}>
        <Toolbar>          
            {<ProfilePopUP />} 
        </Toolbar>
      </Container>
    </div>  
    
  );  
}

export default Header;
