import * as React from 'react';
import { styled,  useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import bulb from './images_mainpage/bulb.svg';
import automate from './images_mainpage/automate.svg';
import connect from './images_mainpage/connect.svg';
import deas_installer from './images_mainpage/deas_installer.svg';
import guides from './images_mainpage/guides.svg';
import workspaces from './images_mainpage/workspaces.svg';
import usermanagement from './images_mainpage/user_management.svg';
import communications from './images_mainpage/communications.svg';
import homepng from './images_mainpage/home.svg';
import ideas from './images_mainpage/ideas.svg';
import {useState, useEffect} from 'react';
import {useMsal} from "@azure/msal-react";
import logoWhite from './images_mainpage/logoWhite.png';
import { Theme, Typography, Tooltip} from '@mui/material';
import Header from '../Header';
import {withRouter} from 'react-router-dom';
import BaseUrl from "../Globals/Environment";

const drawerWidth = 200;

const openedMixin = (theme:Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme:Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});  

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop:any) => prop !== 'open',
})(({ theme, open }: {theme:any, open:boolean} ) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

 const Drawer = styled(MuiDrawer, { 
  shouldForwardProp: (prop: any) => prop !== 'open',
 })
 (( { theme, open }: { theme: any, open: boolean } ) => ({
  width: drawerWidth,
  flexShrink:0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

...(open && {
  "& .MuiDrawer-paper" : openedMixin(theme)
}),

...(!open && {
  "& .MuiDrawer-paper" : closedMixin(theme)
}),
  }),
  ); 
  
  const MainPage =(props) =>  { 

    const history = {props}.props.history;  
        
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [leftmargin, setLeftmargin] = useState("0px");
 
  const handleDrawerOpen = () => {
    setOpen(true);    
    setLeftmargin("0px");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setLeftmargin("-150px");
  };

  const home_tooltip="Home";
  const controlroom_tooltip="A dashboard where we can in near"+
  " real-time see how design automation"+
  " is being implemented at team, project,"+
  " country or GBA level and correlated"+
  " with D&E project revenues";
  const skillcontact_tooltip="A space for the D&E community"+ 
  " to determine how to develop" + 
  " their digital capabilities within the"+ 
  " context of design automation or"+ 
  "whom the local expert could be."

//get special rights
  const [ws, setWorkspacesVisible] = useState<boolean>(false);  
  const [comm, setCommunicationsVisible] = useState<boolean>(false);
  const [userm, setUserManagementVisible] = useState<boolean>(false);  
  const [activePage, setActivePage] = useState<string | null>();  
  const { instance, accounts } = useMsal();

  function getPageName ()  {
    const url = window.location.pathname;
    if(url)
    {
      const pageUrl = url.split("/")[1];
      const pageName =pageUrl.replace(/[^a-zA-Z0-9]/g, ''); 
      if(pageName)
      {
        setActivePage(pageName);
      }
      else{
        setActivePage("Home");
      }
    }
  }

  function setBackgroundColor(item) {
    if(item.menu)
    {
      const currentPage = item.menu.replace(/[^a-zA-Z0-9]/g, '');
      return {
        backgroundColor: currentPage=== activePage ? "#f0f0f0" : "#ffffff"
      };
    }
    else{
      const currentPage = item.replace(/[^a-zA-Z0-9]/g, '');
      return {
        backgroundColor: currentPage === activePage ? "#f0f0f0" : "#ffffff"
      };
    }
  }
  useEffect(()=>{
    getPageName();
  },[window.location.pathname])
  
  useEffect(() => {    
    const accessTokenRequest = {
      scopes: ["api://ccc0414d-73c7-44d1-a3f6-947e591fed42/deas_access_to_api"],
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(async (accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + accessToken);
        myHeaders.append("Content-Type", "application/json");

        const raw = `{"email" : "${accounts[0].username}"}`;

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        fetch(
          BaseUrl() + "/api/v2/users",
          requestOptions
        )
          .then((response) => response.json())
          .then( (result) => { 
            if(result.userStatus){
              sessionStorage.setItem("UserStatus", result.userStatus)

            // eslint-disable-next-line react/prop-types
                if (result.userStatus.includes('SuperUser') || result.userStatus.includes('Workspaces'))           
                {
                  setWorkspacesVisible(true);              
                }
                if (result.userStatus.includes('SuperUser') || result.userStatus.includes('Communications'))           
                {
                  setCommunicationsVisible(true);              
                }
                if (result.userStatus.includes('SuperUser') || result.userStatus.includes('UserManagement'))           
                {
                  setUserManagementVisible(true);             
                }
          }
            
          })
          .catch((error) => console.log("error", error));
      });
  }, [accounts]);

  useEffect(() => {
      // Retrieve the original URL from session storage
      const redirectUrl = sessionStorage.getItem('postLoginRedirectUrl');
      if (redirectUrl) {
        sessionStorage.removeItem("postLoginRedirectUrl");
        history.push(redirectUrl); 
      }
    
}, []);

    const home_menu_Icons = [
      {menu:"Home", icon:homepng , onclick: () =>  {history.push('/'); window.location.reload(); setActivePage("Home")}, tooltip:home_tooltip },
      ];

    const top_menu_Icons = [
      {menu:"Connect", icon:connect, onclick: () => {history.push('/Connect');}, tooltip:skillcontact_tooltip},
      {menu:"Automate", icon:automate, onclick: () => {history.push('/Automate')}, tooltip:"Available plugins"},
      {menu:"Insights", icon:ideas, onclick: () =>  {history.push('/Insights'); }, tooltip:controlroom_tooltip },
    ];

    const middle_menu_Icons = [   
      {menu:"About DEAS", icon:deas_installer, 
      onclick: () =>{ history.push('/AboutDEAS');}, 
        tooltip:"About DEAS"},    
      {menu:"Guides", icon:guides, onclick: () =>{ history.push('/Guides');}, tooltip: "Guides"},    
      {menu:"Automation Ideas", icon:bulb, 
      onclick: () => window.open("https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/Lists/Automation%20Ideas/AllItems.aspx", "_blank", "noopener") , 
      tooltip:"Automation Ideas"},   
    ]; 

   return (
    
    <Box sx={{ display: 'flex' }} {...props} >      
      <CssBaseline />
      <AppBar position="fixed" open={open} color='inherit' elevation={0} theme={theme}  >      
        <Toolbar >
          <IconButton 
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}            
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          > 
            <MenuIcon />
          </IconButton>           
          <div style={{paddingTop:"5px", width:'100%'}} >
            <div style={{ display:'inline', width:'5%', cursor:'pointer'}} 
            onClick={() =>  history.push("/") } 
            > 
              {/* eslint-disable-next-line */}  
              <img src={logoWhite} width={'225px'} alt=""/>              
            </div>             
            <div style={{ paddingTop:"1px",  float:'right'}}><Header /></div>
          </div>
        </Toolbar>
        <Divider/> 
      </AppBar>         
      
      <Drawer variant="permanent" open={open} theme={theme}  >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}             
          </IconButton>
        </DrawerHeader>
        <Divider sx={{paddingTop:'6px'}}/>

        {/* Home */}
        <List>                  
           {home_menu_Icons.map((item) => ( 
                      
             <ListItem  key={item.menu}  
                      disablePadding sx={{ display: 'block' }} 
                      onClick={item.onclick}
                      style={setBackgroundColor(item)}              
                      >  
               <Tooltip title={item.tooltip} arrow placement='right-end'>                              
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}                 
              >                    
                <ListItemIcon
                  sx={{
                    minWidth: 0, 
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    paddingLeft:'8.5px',
                  }}
                >
                  {
                    // eslint-disable-next-line
                    <img src={item.icon} width={'24px'} height={'24px'} alt="" />                                        
                  }    
                </ListItemIcon>   
                <ListItemText primary={item.menu} sx={{ opacity: open ? 1 : 0}} primaryTypographyProps={{fontSize:'14px'}}/>
              </ListItemButton></Tooltip>
                  {!open && (<Typography  fontSize={'8px'} align='center' fontWeight='600'>
                      {item.menu}
                  </Typography>)} 
            </ListItem>                     
          ))}                           
        </List>
        <Divider />

        {/* Top */}
        <List>                  
           {top_menu_Icons.map((item) => ( 
                      
             <ListItem  key={item.menu}  
                      disablePadding sx={{ display: 'block' }} 
                      onClick={item.onclick}
                      style={setBackgroundColor(item)}              
                      >  
               <Tooltip title={item.tooltip} arrow placement='right-end'>                              
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}                 
              >                    
                <ListItemIcon
                  sx={{
                    minWidth: 0, 
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    paddingLeft:'8.5px',
                  }}
                >
                  {
                    // eslint-disable-next-line
                    <img src={item.icon}  width={'24px'} height={'24px'} alt=""  />                                                          
                  }    
                </ListItemIcon>   
                <ListItemText primary={item.menu} sx={{ opacity: open ? 1 : 0}} primaryTypographyProps={{fontSize:'14px'}}/>
              </ListItemButton></Tooltip>
                  {!open && (<Typography  fontSize={'8px'} align='center' fontWeight='600'>
                      {item.menu}
                  </Typography>)} 
            </ListItem>                     
          ))} 
                          
        </List>
        <Divider />

        {/* Middle */}
        <List>                  
           {middle_menu_Icons.map((item) => ( 
                      
             <ListItem  key={item.menu}  
                      disablePadding sx={{ display: 'block' }} 
                      onClick={item.onclick}
                      style={setBackgroundColor(item)}              
                      >  
               <Tooltip title={item.tooltip} arrow placement='right-end'>                              
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}                 
              >                    
                <ListItemIcon
                  sx={{
                    minWidth: 0, 
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    paddingLeft:'8.5px',
                  }}
                >
                  {
                    // eslint-disable-next-line
                    <img src={item.icon} width={'24px'} height={'24px'} alt=""/>                                        
                  }    
                </ListItemIcon>   
                <ListItemText primary={item.menu} sx={{ opacity: open ? 1 : 0}} primaryTypographyProps={{fontSize:'14px'}}/>
              </ListItemButton></Tooltip>
                  {!open && (<Typography  fontSize={'8px'} align='center' fontWeight='600'>
                      {item.menu}
                  </Typography>)} 
            </ListItem>                     
          ))} 
                          
        </List>
        <Divider />
        
        {/*workspaces tab*/}
        <List>
          { ws && ['Workspaces'].map((text) => (
            <ListItem  key={text} disablePadding sx={{ display: 'block' }}   
             onClick={()=>history.push('/Workspaces', {ws:ws}) } 
             style={setBackgroundColor(text)}
            >
              <Tooltip title="Workspaces" arrow placement='right-end'> 
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    paddingLeft:'5px',
                  }}
                >     &nbsp;                
                  {
                  // eslint-disable-next-line
                    <img src={workspaces} width={'24px'} alt=""/>
                  }
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} primaryTypographyProps={{fontSize:'14px'}}/>
              </ListItemButton></Tooltip>
              {!open && (<Typography  fontSize={'8px'} align='center' fontWeight={'bold'}>
                      {text}
                  </Typography>)}
            </ListItem>
          ))}
        
        {/*usermanagement tab*/}        
          {userm&&['User Management'].map((text) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}  
            onClick={()=>history.push('/UserManagement')} 
            style={setBackgroundColor(text)}
            >
              <Tooltip title="User Management" arrow placement='right-end'> 
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    paddingLeft:'5px',
                  }}
                >    &nbsp;                 
                  {
                  // eslint-disable-next-line
                    <img src={usermanagement} width={'24px'} alt=""/>
                  }
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} primaryTypographyProps={{fontSize:'14px'}}/>
              </ListItemButton></Tooltip>
              {!open && (<Typography  fontSize={'8px'} align='center' fontWeight={'bold'}>
                      {text}
                  </Typography>)}
            </ListItem>
          ))}
       
        {/*communication tab*/}       
          {comm&&['Communications'].map((text) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }} 
            onClick={()=>history.push('/Communications')}
            style={setBackgroundColor(text)}
            > 
            <Tooltip title="Communications" arrow placement='right-end'>              
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    paddingLeft:'5px',
                  }}
                >   &nbsp;                  
                  {
                  // eslint-disable-next-line
                    <img src={communications} width={'24px'} alt=""/>
                  }
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} primaryTypographyProps={{fontSize:'14px'}}/>
              </ListItemButton></Tooltip>
              {!open && (<Typography  fontSize={'8px'} align='center' fontWeight={'bold'}>
                      {text}
                  </Typography>)}
            </ListItem>
          ))}
        </List>
        
      {(ws || userm || comm) && <Divider/>}
      
      </Drawer>
      
        <Box component="main"  sx={{  flexGrow: 1, p: 2 }} marginLeft={leftmargin} />
               
        </Box>
       
  )
  };

  export default withRouter(MainPage);