import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  Button,  
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions
} from "@mui/material";
import React, 
{ 
  useState, 
  useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import WorkspacesContent from "./WorkspacesContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import BaseUrl from "../../Globals/Environment";
import uuid from "react-uuid";
import TokenReusable from '../../ReusableToken';
import { DEASCircularProgress } from "./DEASCircularProgress";

const boxStyle = {
  border: '1px solid',
  borderColor: "grey.400",
  borderRadius: '5px',
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },

};
const dictRegion = {
  "AGL": "Global",
  "AAU": "Australia",
  "ABE": "Belgium",
  "ABR": "Brazil",
  "ACA": "Canada",
  "ACL": "Chile",
  "ACN": "China",
  "AFR": "France",
  "ADE": "Germany",
  "AHK": "Hong Kong",
  "AIN": "India",
  "AIE": "Ireland",
  "AIT": "Italy",
  "ANL": "Netherlands",
  "APH": "Philippines",
  "APL": "Poland",
  "APT": "Portugal",
  "ARO": "Romania",
  "AES": "Spain",
  "AGB": "United Kingdom",
  "AUS": "United States",
}

export const dictDisciplines = {
  "ARCH": "Architecture",
  "BIM": "BIM Management",
  "BSV": "Building Services",
  "CVL": "Civil Engineering",
  "RL": "Rail",
  "HV": "High Voltage",
  "PRE": "Process Engineering",
  "RDS": "Roads",
  "UP": "Urban Planning",
  "STR": "Structure",
  "MEP": "Mechanical Engineering",
  "BGS": "Bridges"
}

export const dictDisciplinesACC = {
  "IFM": "BIM Management",
  "ARG": "Architecture",
  "CES": "Civil Engineering",
  "RAI": "Rail",
  "ELG": "High Voltage",
  "PEN": "Process Engineering",
  "ROA": "Roads",
  "UPL": "Urban Planning",
  "STG": "Structure",
  "MEP": "Building Services",
}

export default function Workspaces() {

  //State hooks
  const { instance, inProgress, accounts } = useMsal();
  const [data, setData] = useState<WorkspaceData[]>([]);
 
  const [gba, setGba] = useState<any>([]);
  const [userEmail, setUserEmail] = useState<string[]>([]);
  const [userNames, setUserNames] = useState<Object>([]);
  const [disciplines, setDisciplines] = useState<any>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [toogleDialog, setToogleDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let accessToken = TokenReusable();

  const toggleOpenDialog = () => setToogleDialog((prev) => !prev);

  //User name functions
  const getUserNameByEmail = async (email: string) => {
    try {
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0],
      };
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      let accessToken = accessTokenResponse.accessToken;

      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(
        `https://graph.microsoft.com/v1.0/users/${email}?$select=displayName`,
        options
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const user = await response.json();
      if(user.displayName)
      {
        return user.displayName;
      }
      else{
        return "No Name";
      }

    } catch (error) {
      console.error('UserName:', error);
    }
  };
  const fetchUserNames = async () => {
    try {
      const userDictionary = {}; // Initialize dictionary here
      const userNamePromises = data.map(row => getUserNameByEmail(row.userEmail));
      const userNamesArray = await Promise.all(userNamePromises);

      userNamesArray.forEach((userName, index) => {
        const userEmailData = data[index].userEmail;
        if (userName) {
          userDictionary[userEmailData] = userName;
        }
      });
      setUserNames(userDictionary);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //Fetching operations
  const postWorkspaces = (updatedRaw) => {
    try {
      if (accessToken && inProgress === InteractionStatus.None) {
        fetch(BaseUrl() + '/api/v2/workspaces/', {
          headers: { Authorization: 'Bearer ' + accessToken },
          method: 'POST',
          body: updatedRaw,
          redirect: 'follow',
        })
          .then((res) => res.json())
          .then((data) => {
            const newUrl = `${window.location.origin}/workspace?id=${data.id}`;
            window.location.href = newUrl;
          });
      }
    } catch (err: any) {
      setToogleDialog(true);
    }
  };
 
  const getWorkSpaces = async () => {
    try {
      if (accessToken && inProgress === InteractionStatus.None) {
        fetch(BaseUrl() + '/api/v2/workspaces', {
          headers: { Authorization: 'Bearer ' + accessToken },
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
        })
          .then((res) => res.json())
          .then((data) => {
            setIsLoading(true);
            setData(data);
          });
      } 
    } catch (err: any) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      fetchUserNames();
    }
  }, [data]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      getWorkSpaces();
    }
  }, [data.length, instance, accounts, inProgress]);

  const handleNew = async () => {
    const dummyData = {
      Name: uuid(),
      Schema: "{'Name' : 'Test'}",
    };
    let updatedRaw = JSON.stringify(dummyData)
    postWorkspaces(updatedRaw);
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };
  const handleChangeGba = (event) => {
    const selectedValuesGba = event.target.value;
    setGba(selectedValuesGba);
  };
  const handleChangeUserEmail = (event) => {
    const checked = event.target.value;
    setUserEmail(checked);
  };
  const handleChangeDisciplines = (event) => {
    const selectedValuesDisciplines = event.target.value;
    setDisciplines(selectedValuesDisciplines);
  };
  const handleWorkspaceDelete = (deletedWorkspaceId) => {
    setData(prevData => prevData.filter(workspace => workspace.id !== deletedWorkspaceId));
  };
  const handleWorkspaceDuplicate = (duplicateData) => {
    setData(prevData => [...prevData, duplicateData]);
  };
  const sortedData = [...data].sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());

  return (
      <Table >
        <TableHead>
          <TableRow >
            <TableCell style={{ border: "0px" }} align="right">
              <FormControl
                size="small"
                sx={{ width: "20%" }}
                variant="outlined"
              >
                <InputLabel color='warning' id="outlined-adornment-search">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-search"
                  type="text"
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
              <FormControl size="small" sx={{ ml: 2, width: "12%", alignItems: 'left' }}>
                <InputLabel color='warning' id="demo-multiple-checkbox-user">Users</InputLabel>
                <Select
                  sx={{
                    borderRadius: "6px", textAlign: "left"
                  }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  name="Users"
                  multiple
                  size="small"
                  value={userEmail}
                  onChange={handleChangeUserEmail}
                  input={<OutlinedInput label="Users" />}
                  renderValue={(selected) => selected.join("/ ")}
                  MenuProps={MenuProps}
                >
                  {Object.entries(userNames)?.map(([email, name]) => (
                    <MenuItem sx={{ textAlign: "left" }} key={email} value={name}>
                      <Checkbox style={{ color: "#e4610f" }} checked={userEmail.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ ml: 2, width: "12%" }}>
                <InputLabel color='warning' id="demo-multiple-checkbox-label">Regions</InputLabel>
                <Select
                  sx={{
                    borderRadius: "6px", textAlign: "left"
                  }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  name="regions"
                  multiple
                  size="small"
                  value={gba}
                  onChange={handleChangeGba}
                  input={<OutlinedInput label="Regions" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {Array.from(
                    new Set(
                      data
                        .flatMap(row => row.regions)
                        .flatMap(item => item ? item.split(",") : [])
                    )
                  ).sort((a, b) => a.localeCompare(b)).map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox style={{ color: "#e4610f" }} checked={gba.indexOf(item) > -1} />
                      <ListItemText primary={dictRegion[item]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ ml: 2, width: "12%", }}>
                <InputLabel color='warning' id="demo-multiple-checkbox-label">Disciplines</InputLabel>
                <Select
                  sx={{
                    borderRadius: "6px", textAlign: "left"
                  }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  name="disciplines"
                  multiple
                  size="small"
                  value={disciplines}
                  onChange={handleChangeDisciplines}
                  input={<OutlinedInput label="disciplines" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {Array.from(
                    new Set(
                      data
                        .flatMap(row => row.disciplines)
                        .flatMap(item => item ? item?.split(",") : [])
                    )
                  ).sort((a, b) => a.localeCompare(b)).map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox style={{ color: "#e4610f" }} checked={disciplines.indexOf(item) > -1} />
                      <ListItemText primary={dictDisciplines[item]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell style={{ border: "0px", padding:"0px",width:"0px" }} align="right" >
              <Tooltip title="New Workspace">
                <Button
                  sx={{marginRight:"50px"}}
                  onClick={handleNew}
                  className="new-element-button"
                >
                  <Typography fontSize={"40px"}>
                    +
                  </Typography>
                </Button>
              </Tooltip>
            </TableCell>
          </TableRow>
          <TableCell sx={{ border: "0px" }} colSpan={3}>
            <Box overflow={'auto'} maxWidth={"100vw"} marginRight={"30px"} height={`calc(100vh - 210px)`} sx={{ ...boxStyle, borderRadius: "0px" }} >
              {isLoading ? (sortedData.map((row) => {
                const dataWorkspace = row.name.concat(row.longDescription);
                const matchedSearch = dataWorkspace.toLowerCase().includes(searchString.toLowerCase());
                const matchedGBA = gba.length === 0 || gba.some(item => row.regions.includes(item));
                const matchedDisciplines = disciplines.length === 0 || disciplines.some(item => row.disciplines.includes(item));
                const matchedUsers = userEmail.length === 0 || userEmail.includes(userNames[row.userEmail]);
                if (matchedSearch && matchedGBA && matchedDisciplines && matchedUsers) {
                  return (
                    <WorkspacesContent key={row.name} row={row} onWorkspaceDelete={handleWorkspaceDelete} onWorkspaceDuplicate={handleWorkspaceDuplicate} />
                  )
                } else {
                  return null;
                }
              })) : 
              <DEASCircularProgress/>
              }
            </Box>
          </TableCell>
        </TableHead>
        <Dialog open={toogleDialog}>
        <DialogTitle
          className='dialog-title'
          id='customized-dialog-title'
        >
          <Typography
            className='standard-text'
            color={'#ffffff'}
          >
            WORKSPACE EDITOR
          </Typography>
        </DialogTitle>
          <DialogContent className="dialog-popup">
            <Typography className='standard-text'>A workspace with that name already exists.</Typography>
          </DialogContent>
          <DialogActions className='dialog-actions'>
          <Button
            onClick={toggleOpenDialog}
            className='save-button standard-text'
          >
            OK
          </Button>
        </DialogActions>
        </Dialog>
      </Table>

  );
}

export interface WorkspaceData {
  name: string,
  id: string,
  longDescription: string,
  disciplines: string,
  regions: string,
  shortDescription: string,
  icon: string,
  versions: string,
  schema: string,
  userEmail: string,
  lastModified: string,
  application: string
};
