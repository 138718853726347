import React, { useState, useEffect } from "react";
import Grid  from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import yes from './images_mainpage/Yes.svg';
import no from './images_mainpage/No.svg';
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import '../App.css';
import TokenReusable from "../ReusableToken";
import BaseUrl from "../Globals/Environment";
import { getCurrentDimension } from "./../GetWindowSize";
import tool_catalog from './images_mainpage/tool_catalog.svg';
import workspaces from './images_mainpage/workspaces.svg';
import usermanagement from './images_mainpage/user_management.svg';
import communications from './images_mainpage/communications.svg';
import TableSortLabel from "@mui/material/TableSortLabel";


const useStyles = makeStyles({
    tableRow: {
      height: "30px",
      "&:hover": {        
        backgroundColor :"#fbfbfb !important",                        
      }
    },
    tableCell: {
      padding: "0px 16px"
    },
    headercolor: {
      height: "60px",
      "& .MuiTableCell-head": {
          color: "#000",
          backgroundColor: "#f0f0f0"
      },
    }    
  });

   export interface UsersData {    
    Email: string,           
    UserStatus: string,
    isSuperUser:boolean,    
    isWorkspaces:boolean,
    isUserManagement:boolean, 
    isCommunications:boolean,
    isToolEditor:boolean,          
  }; 

export default function UserManagement() {

const classes = useStyles();
const myusers: UsersData[] = [];  
const [myresult, setMyresult]=useState<UsersData[]>([]);
const [search, setSearch] = useState("");
const [filteredUsers, setfilteredUsers] = useState<UsersData[]>([]);
const [changeItem, setchangeItem] = useState<boolean>(false);

    //getwindows dimensions Main Menu
const [screenSize, setScreenSize] = useState(getCurrentDimension());
useEffect(() => {      
  const updateDimension = () => {
      setScreenSize(getCurrentDimension())
  }
  window.addEventListener('resize', updateDimension); 
    return(() => {
      window.removeEventListener('resize' , updateDimension)          
  })     
    
}, [screenSize]);


const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearch(event.target.value);
};

useEffect(() => { 
        const accessToken = TokenReusable();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + accessToken);
        myHeaders.append("Content-Type", "application/json");

        const raw = '{"Proficiencies":"false"}';

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        fetch(
          BaseUrl() +"/api/v2/getusers",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {               
            // eslint-disable-next-line react/prop-types
            setMyresult(result);                          
          })
          .catch((error) => console.log("error on users", error));     
  }, [changeItem]);

  for (const element of myresult) {
    
  const people: UsersData = { 
    Email: element["email"].toLowerCase(),    
    UserStatus: element["userStatus"],
    isSuperUser: element["userStatus"]?.includes('SuperUser'),    
    isWorkspaces: element["userStatus"]?.includes('SuperUser') ? true : element["userStatus"]?.includes('Workspaces'),
    isUserManagement: element["userStatus"]?.includes('SuperUser') ? true : element["userStatus"]?.includes('UserManagement'),
    isCommunications: element["userStatus"]?.includes('SuperUser') ? true : element["userStatus"]?.includes('Communications'),
    isToolEditor: element["userStatus"]?.includes('SuperUser') ? true : element["userStatus"]?.includes('ToolEditor')
  };  
    myusers.push(people);
  }
  
  useEffect(() => {
    setfilteredUsers(
      myusers.filter(function (item) {
        if (
          //name search
          (!search || item.Email.toUpperCase().includes(search.toUpperCase())) 
          ) {
          return item;
        }
      })      
    );    
  }, [search,changeItem,myresult]);

  function ChangeStatus(userUserStatus, itemChanged:string) {
    
    switch (true) {
      case (userUserStatus == null || userUserStatus.length<3):{ 
        setchangeItem(true);
        return itemChanged;
      } 

      case (userUserStatus.includes(itemChanged)):{
          const arr_currentStatus = userUserStatus.replace("[","").replace("]","").split(',');
          const newStatus = arr_currentStatus.filter(function(item) {
          return (item !==itemChanged && item!=="") }) 
          setchangeItem(true);
          return String(newStatus);
      }

      case (userUserStatus.includes("SuperUser")):{          
          setchangeItem(true);
          return 'SuperUser';
      }

      case (userUserStatus != null && !userUserStatus.includes(itemChanged) ):{        
        const arr_currentStatus = userUserStatus.replace("[","").replace("]","").split(',').filter(function(item){
          return item!=="" });        
        arr_currentStatus.push(itemChanged);        
        if (itemChanged=='SuperUser'){
          setchangeItem(true);
          return 'SuperUser';
        }
        else {
          setchangeItem(true);
          return arr_currentStatus;
        }
      } 
    }
  }

  function UpdateUserStatus(userEmail, userUserStatus, item) {

  if (confirm("Do you want to change this access right for: "+userEmail+"?")) {
    const newStatus = ChangeStatus(userUserStatus, item);

    const accessToken = TokenReusable();

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);
    myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify ({
      userStatus: String("["+newStatus+"]"),
        })      

      const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      BaseUrl() + "/api/v2/users/"+userEmail,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => { 
        console.log(result) ;        
        setchangeItem(false);        
        // eslint-disable-next-line react/prop-types                                 
      })
      .catch((error) => console.log("error on users", error)); 
    } 
}

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [getdirection, setdirection] = useState<boolean>(true);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      setdirection(!getdirection);
    } else {
      setSortBy(column);
      setSortOrder('desc');
      setdirection(!getdirection);
    }
  };

  const sortuserbyemail = [...myusers].sort((a,b) => {
    if (a.Email < b.Email) {return -1}
    if (a.Email > b.Email) {return 1}
    return 0;
  } );

  const mydataset = search ? filteredUsers : sortuserbyemail;
  
  const sortedData = [ ...mydataset].sort((a, b) => {
    if (sortBy) {
      if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });
  

  function ArrowDirection() {
    return getdirection ? 'asc' : 'desc'; 
  }

return (  
      <Grid item xs={12} display='flex' 
            container 
            marginRight={'25px'} marginTop={'12px'} > 
         
         <Grid item xs={12} display='flex'                       
              justifyContent='flex-end'
              container marginRight={'45px'} marginLeft={'5px'}
              >
             <FormControl
             size="small"
             sx={{ m: 1, width: "250px" }}
             variant="outlined"
             >
                <InputLabel id="outlined-adornment-search" color="warning">Search</InputLabel>
                <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                onChange={handleSearchChange}
                endAdornment={
                <InputAdornment position="end">
                    <SearchIcon />
                </InputAdornment>
                }
                label="Search"
                /> 
           </FormControl> 
         <Grid/>
         <Grid item xs={12} marginRight={'8px'}>
          <Paper sx={{overflow:'hidden'}}>     
             <TableContainer sx={{ maxHeight: screenSize.height-190}}>
             <Table stickyHeader aria-label="sticky table" size="small" >
             <TableHead >
                <TableRow className={classes.headercolor}>
                    <TableCell width={'800px'}>
                      <TableSortLabel
                      active={sortBy == 'Email'}
                      direction={ArrowDirection()}
                      onClick={()=>handleSort('Email')}
                      >                                       
                      <b>User</b>
                      </TableSortLabel>                      
                    </TableCell>

                    <TableCell width={'100px'} align="center">
                      <TableSortLabel
                      active={sortBy == 'isSuperUser'}
                      direction={ArrowDirection()}
                      onClick={()=>handleSort('isSuperUser')}
                      >
                      <p style={{paddingLeft:'25px'}}><b>Admin</b></p>
                      </TableSortLabel>
                    </TableCell>

                    <Tooltip title="User Management" placement="top" arrow> 
                      <TableCell width={'100px'} align="center" >
                        <TableSortLabel
                          active={sortBy == 'isUserManagement'}
                          direction={ArrowDirection()}
                          onClick={()=>handleSort('isUserManagement')}
                          >
                          <img src={usermanagement} style={{paddingLeft:'25px'}} alt=""/>
                        </TableSortLabel>
                      </TableCell>
                    </Tooltip>
                    
                    <Tooltip title="Workspaces" placement="top" arrow> 
                      <TableCell width={'100px'} align="center" >
                        <TableSortLabel
                        active={sortBy == 'isWorkspaces'}
                        direction={ArrowDirection()}
                        onClick={()=>handleSort('isWorkspaces')}
                        >
                        <img src={workspaces} style={{paddingLeft:'25px'}}  alt="" />
                        </TableSortLabel>
                      </TableCell>
                    </Tooltip>                    

                    <Tooltip title="Communications" placement="top" arrow> 
                      <TableCell width={'100px'} align="center" >
                        <TableSortLabel
                            active={sortBy == 'isCommunications'}
                            direction={ArrowDirection()}
                            onClick={()=>handleSort('isCommunications')}
                            >
                          <img src={communications} style={{paddingLeft:'25px'}} alt=""/>
                        </TableSortLabel>
                      </TableCell>
                    </Tooltip>

                    <Tooltip title="ToolEditor" placement="top" arrow> 
                      <TableCell width={'100px'} align="center" >                      
                        <TableSortLabel
                            active={sortBy == 'isToolEditor'}
                            direction={ArrowDirection()}
                            onClick={()=>handleSort('isToolEditor')}
                            >
                          <img src={tool_catalog} style={{paddingLeft:'25px'}} alt=""/>
                        </TableSortLabel>
                      </TableCell>
                    </Tooltip>                    
                </TableRow>
             </TableHead>
            <TableBody>
                {sortedData          
                .map((user)=> {
                return (
                <TableRow hover className={classes.tableRow} key={user.Email} >                     
                    <TableCell>
                        {user.Email}
                    </TableCell>
                    <TableCell align="center" >                                                    
                      <div style={{cursor:"pointer", width:"30px", margin:"0 auto"}} 
                        onClick={()=> UpdateUserStatus( user.Email, user.UserStatus, 'SuperUser')}
                        >
                        <img src={user.isSuperUser ? yes : no} alt="" />
                      </div>
                    </TableCell>
                    <TableCell align="center" >
                      <div style={{cursor:"pointer", width:"30px", margin:"0 auto"}} 
                        onClick={()=> UpdateUserStatus( user.Email, user.UserStatus, 'UserManagement')}
                        >
                        <img src={user.isUserManagement || user.isSuperUser ? yes : no} alt=""/>
                      </div>
                    </TableCell >
                    <TableCell align="center" >
                      <div style={{cursor:"pointer", width:"30px", margin:"0 auto"}}
                        onClick={()=> UpdateUserStatus( user.Email, user.UserStatus, 'Workspaces')}
                        >
                        <img src={user.isWorkspaces || user.isSuperUser ? yes : no} alt=""/>
                      </div>
                    </TableCell>
                    <TableCell align="center" >
                      <div style={{cursor:"pointer", width:"30px", margin:"0 auto"}}
                        onClick={()=> UpdateUserStatus( user.Email, user.UserStatus, 'Communications')}
                        >
                        <img src={user.isCommunications || user.isSuperUser ? yes : no} alt=""/>
                      </div>
                    </TableCell>
                    <TableCell align="center" >
                      <div style={{cursor:"pointer", width:"30px", margin:"0 auto"}}
                        onClick={()=> UpdateUserStatus( user.Email, user.UserStatus, 'ToolEditor')}
                        >
                        <img src={user.isToolEditor || user.isSuperUser ? yes : no} alt=""/>
                      </div> 
                    </TableCell>
                </TableRow>
                )}
                )}
            </TableBody>
            </Table>
            </TableContainer>
          </Paper>
         </Grid>
     </Grid>
      </Grid>
  )
}