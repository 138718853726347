import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Checkbox,
  OutlinedInput,
  Select,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Menu,
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useRef, useState } from "react";
import BaseUrl from "../../Globals/Environment";
import TokenReusable from "../../ReusableToken";
import { dictDisciplinesACC } from "./Workspaces";
import { Table, Column, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import civil3DIcon from "../../assets/ToolCatalog/civil-icon.png";
import revitIcon from "../../assets/ToolCatalog/revit-icon.jpg";
import newRevitIcon from "../../assets/ToolCatalog/new-revit.png";
import newCivilIcon from "../../assets/ToolCatalog/new-civil.png";
import { boxStyle, checkboxStyle } from "./WorkspaceEditorStyles";
import MediaContent from "./MediaContent";
import uuid from "react-uuid";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import CircleEmpty from "@mui/icons-material/RadioButtonUnchecked";
import CircleFilled from "@mui/icons-material/Lens";
import ThreeDots from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import JSZip from "jszip";
import { useMsal } from "@azure/msal-react";
import { DEASCircularProgress } from "./DEASCircularProgress";

export interface Tool {
  id: string;
  toolKitId: string;
  toolkit: string;
  workspace: string;
  software: string;
  gba: string;
  assetType: string;
  icon: string;
  iconId: string;
  name: string;
  commandName: string;
  description: string;
  media: string;
  createdDate: string;
  lastModified: string;
}
export interface Media {
  id: string;
  media: string | ArrayBuffer | null | undefined;
  mediaType: string | null;
}
export const dictGba = {
  MOB: "Mobility",
  RES: "Resilience",
  PLA: "Places",
};

const softwares = ["Revit", "Civil3D"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const contentAdd: Media = {
  id: uuid(),
  media: null,
  mediaType: null,
};
function findKeyByValue(dictionary, searchValue) {
  for (const key in dictionary) {
    if (dictionary[key] === searchValue) {
      return key;
    }
  }
  return null;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function Automate() {
  //State hooks
  const [searchString, setSearchString] = useState("");
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [application, setApplication] = useState<any>([]);
  const [filter, setFilter] = useState<Tool[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);
  const [gba, setGba] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [row, setRow] = useState<Tool>();
  const [discipline, setDiscipline] = useState<any>([]);
  const [toolkit, setToolkit] = useState<any>([]);
  const [workspace, setWorkspace] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openPrimary, setOpenPrimary] = useState(false);
  const [openSecondary, setOpenSecondary] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [mediaItems, setMediaItems] = useState<Media[]>([]);
  const [deletedMedia, setDeletedMedia] = useState<Media[]>([]);
  const [description, setDescription] = useState("");
  const [checkedGBA, setCheckedGBA] = useState({});
  const [checkedAssetType, setCheckedAssetType] = useState({});
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [menuOpen, setMenuOpen] = useState(null);
  const [userStatus, setUserStatus] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [toolEditPermission, setToolEditPermission] = useState(false);
  const [deleteTool, setDeleteTool] = useState<boolean>(false);

  //Additional hooks
  const { instance, accounts } = useMsal();
  const tableRef = useRef<any>();
  const accessToken = TokenReusable();

  // map toolkit name to workspace name
  const toolkitToWorkspace = [
    { toolkit: "PIMSConnectorRevit.dll", workspace: "OTL Connector Revit" },
    { toolkit: "PIMSConnectorCivil3d.dll", workspace: "OTL Connector Civil3D" },
    { toolkit: "UT31_ToolBar2022.dll", workspace: "UT31 2022" },
    { toolkit: "bbTools_DEAS.dll", workspace: "AU Workspaces" },
    { toolkit: "OtlLocalizedContent.dll", workspace: "OTL Localized Content" },
    { toolkit: "PipeInsulation.dll", workspace: "MEP Toolkit" },
    { toolkit: "ThermalProperties.dll", workspace: "MEP Toolkit" },
    { toolkit: "HeatLossCalculation.dll", workspace: "MEP Toolkit" },
    { toolkit: "OtlFamilyEditor.dll", workspace: "OTL Family Editor Tool" },
  ];

  toolkitToWorkspace.map((element) => {
    tools.forEach((item, index) => {
      if (item.toolkit === element.toolkit) {
        tools[index].workspace = element.workspace;
      }
    });
  });

  // //Effect Hooks
  useEffect(() => {
    getTools();
  }, [isSaving]);
  useEffect(() => {
    const url = window.location.href;
    const urlParams = new URLSearchParams(new URL(url).search);
    const id = urlParams.get("id");
    if (tools.length > 0) {
      openActiveTool(id);
    }
  }, [tools]);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    if (!row) return;
    if (!isSaving) {
      getMediaFiles(row);
    }
  }, [row]);
  useEffect(() => {
    handleResizeRow();
  }, [filter]);
  useEffect(() => {
    fetchToolData();
  }, [row]);
  useEffect(() => {
    checkUserStatus();
  }, [userStatus]);
  useEffect(() => {
    setUserStatus(sessionStorage.getItem("UserStatus"));
  }, [sessionStorage.getItem("UserStatus")]);

  // In case user is not authenticated
  if (accounts.length === 0) {
    return <div style={{ marginLeft: "20px" }}>No authenticated user found, initiating login...</div>;
  }
  const checkUserStatus = () => {
    if (userStatus?.includes("SuperUser") || userStatus?.includes("ToolCatalog")) {
      setToolEditPermission(true);
    }
  };

  //Responsive layout
  const isScreenLarge = () => {
    if (windowWidth) return windowWidth >= 1600;
  };
  const formControlWidth = (width) => {
    const result = isScreenLarge() ? `${width}%` : `${2 * width}%`;
    return result;
  };

  //Functions for tool tablecells rendering
  const renderTextWithTooltip = ({ cellData }, bool = false) => {
    return cellData ? (
      <Tooltip title={cellData}>
        <Typography
          className="standard-text"
          style={{
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: bool ? "center" : "left", // Center text if bool is true
          }}
        >
          {cellData}
        </Typography>
      </Tooltip>
    ) : (
      "-"
    );
  };
  const renderArrayWithTooltip = (data, className) => {
    return (
      <div className={className}>
        {data ? (
          <Tooltip
            title={data
              .split(",")
              .map((item) => dictGba[item] || dictDisciplinesACC[item])
              .join(", ")}
          >
            {data.split(",").map((item) => {
              let newBgColor = "";
              let textColor = "";

              if (item === "MOB") {
                newBgColor = "#326295";
                textColor = "#FFFFFF";
              } else if (item === "PLA") {
                newBgColor = "#9C266E";
                textColor = "#FFFFFF";
              } else if (item === "RES") {
                newBgColor = "#F1B434";
                textColor = "#FFFFFF";
              } else {
                newBgColor = "#FFFFFF";
                textColor = "#0E7DF3";
              }
              return (
                <div
                  key={item}
                  style={{
                    padding: "2px 8px",
                    borderRadius: "50px",
                    display: "inline-block",
                    border: "1px solid",
                    fontSize: "10px",
                    marginRight: "5px",
                    color: textColor,
                    backgroundColor: newBgColor,
                  }}
                >
                  {item}
                </div>
              );
            })}
          </Tooltip>
        ) : (
          "-"
        )}
      </div>
    );
  };
  const renderToolIcon = ({ cellData }) => {
    return cellData ? (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={`data:image/png;base64, ${cellData}`}
          alt="tool-icon"
          style={{
            objectFit: "contain",
            width: "24px",
            height: "24px",
            margin: "auto",
          }}
        />
      </div>
    ) : (
      "-"
    );
  };
  const renderApplicationIcon = (data, bool) => {
    if (!data) return;
    const icons = bool
      ? {
          Revit: revitIcon,
          Civil3D: civil3DIcon,
        }
      : {
          Revit: newRevitIcon,
          Civil3D: newCivilIcon,
        };

    const renderSoftwareIcon = (icon, altText) => (
      <img
        src={icon}
        alt={altText}
        style={{
          objectFit: "contain",
          maxWidth: "24px",
          maxHeight: "24px",
          margin: "auto",
        }}
      />
    );

    const iconContainerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    };

    if (data === "Revit" || data === "Civil3D") {
      return <div style={iconContainerStyle}>{renderSoftwareIcon(icons[data], `${data} Icon`)}</div>;
    } else if (data.toLowerCase().includes("revit") && data.toLowerCase().includes("civil3d")) {
      return (
        <div
          style={{
            ...iconContainerStyle,
            flexDirection: "row",
          }}
        >
          {renderSoftwareIcon(civil3DIcon, "Civil3D Icon")}
          {renderSoftwareIcon(revitIcon, "Revit Icon")}
        </div>
      );
    }
  };
  const renderThreeDots = (rowData) =>
    toolEditPermission ? (
      <ThreeDots
        onClick={(event) => {
          event.stopPropagation();
          handleMenuOpen(event);
          setRow(rowData);
        }}
        style={{
          transition: "color 0.3s ease",
          maxWidth: "24px",
          maxHeight: "24px",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.color = "#e77229")}
        onMouseLeave={(e) => (e.currentTarget.style.color = "")}
      />
    ) : null;

  //Utility function to render tool data
  const dialogRowIcon = (row, style) => {
    if (row?.icon) {
      return <img src={`data:image/png;base64, ${row.icon}`} alt="tool-icon" className={style} />;
    } else {
      return "-";
    }
  };
  const createCircle = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {(() => {
          let elements: any = []; // Array to store the JSX elements
          let counter = 0; // Counter to act as a key

          mediaItems.forEach(() => {
            if (activeIndex !== counter) {
              elements.push(
                <CircleEmpty
                  className="circle-info"
                  key={counter}
                  style={{ fill: "#BDBDBD" }} // Equivalent to MUI's 'grey.400'
                />
              );
            } else {
              elements.push(<CircleFilled className="circle-info" key={counter} style={{ fill: "#e77229" }} />);
            }
            counter++;
          });

          return elements;
        })()}
      </div>
    );
  };

  // Function to filter tools based on multiple criteria
  const filterTools = (newSearchString, newGba, newDiscipline, newApplication, newWorkspace, newToolkit) => {
    return tools.filter((tool) => {
      const conditions = [
        !newApplication || newApplication.length === 0 || newApplication.includes(tool.software),
        !newGba || newGba.length === 0 || tool.gba?.includes(newGba),
        !newDiscipline || newDiscipline.length === 0 || tool.assetType?.includes(newDiscipline),
        !newWorkspace || newWorkspace.length === 0 || newWorkspace.includes(tool.workspace),
        !newToolkit || newToolkit.length === 0 || newToolkit.includes(tool.toolkit),
        !newSearchString ||
          tool.name
            .concat(tool.commandName)
            .concat(tool.description)
            .toLowerCase()
            .includes(newSearchString.toLowerCase()),
      ];

      return conditions.every(Boolean);
    });
  };

  const sortArray = (arr) => arr.sort((a, b) => a.commandName.localeCompare(b.commandName));
  const getArrayToUse = () => {
    return searchString.length > 0 ||
      application.length > 0 ||
      workspace.length > 0 ||
      toolkit.length > 0 ||
      discipline.length > 0 ||
      gba.length > 0
      ? filter
      : tools;
  };
  const sortedArray = sortArray(getArrayToUse());

  //Data fetching functions
  const getTools = async () => {
    const fetchWithToken = async (token) => {
      const response = await fetch(BaseUrl() + "/api/v2/tools", {
        headers: {
          Authorization: "Bearer " + token,
        },
        method: "GET",
        mode: "cors",
        credentials: "include",
      });
      return response;
    };

    const refreshTokenAndGetTools = async () => {
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0],
      };

      try {
        const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
        const newToken = accessTokenResponse.accessToken;
        return await fetchWithToken(newToken);
      } catch (error) {
        console.error("Error acquiring token silently:", error);
        // You can either throw an error here or return a response indicating a failure
        throw new Error("Failed to refresh token");
      }
    };

    try {
      let accessToken = await TokenReusable(); // Assuming this gets the initial token
      let response = await fetchWithToken(accessToken);
      console.log(response);
      if (response.status === 401) {
        response = await refreshTokenAndGetTools();
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Refresh token and retry request
      }

      if (!response.ok) {
        throw new Error("API call failed with HTTP status " + response.status);
      }

      const data = await response.json();
      setTools(data);
      setFilter(data);
      setIsLoading(false);
    } catch (err: any) {
      console.error("Error fetching tools:", err.message);
    }
  };

  const updateTool = async (keyValue, id) => {
    try {
      const response = await fetch(`${BaseUrl()}/api/v2/tools/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: keyValue,
        redirect: "follow",
      });
      return response.json();
    } catch (err: any) {
      console.log("UpdateTool", err.message);
    }
  };

  //Dialogue box handlers/ toggles
  const openActiveTool = (id) => {
    if (id && !openInfo) {
      const activeTool = tools.filter((tool) => tool.id === id)[0];
      setRow(activeTool);
      if (window.location.href.includes("Edit")) {
        togglePrimary();
      } else {
        toggleInfo();
      }
    }
  };
  const toggleDeleteTool = () => setDeleteTool((prev) => !prev);
  const togglePrimary = () => {
    setOpenPrimary((prev) => !prev);
  };
  const toggleSecondary = () => setOpenSecondary((prev) => !prev);
  const toggleInfo = () => setOpenInfo((prev) => !prev);

  //Event Handlers for Dialogs

  //Function for updating filter form data
  const updateChecked = (prevChecked: {}, name: string | number, checked: any) => {
    const updated = { ...prevChecked };
    if (checked) {
      updated[name] = true;
    } else {
      delete updated[name];
    }
    return updated;
  };

  //Function for updating tool gba and asset data
  const createUpdatedList = (checkedItems, dict) => {
    return Object.keys(checkedItems)
      .map((key) => findKeyByValue(dict, key))
      .filter(Boolean)
      .join(",");
  };
  const createUpdatedMediaList = async (files, id) => {
    const accessToken = TokenReusable();
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("", file);
    });
    try {
      const response = await fetch(`${BaseUrl()}/api/v2/tools/${id}/media`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: formData,
        redirect: "follow",
      });

      return response.json();
    } catch (err: any) {
      console.log("UpdateMedia", err.message);
    }
  };
  const checkMediaItems = () => {
    return mediaItems[activeIndex] ? (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <ArrowBack className="arrow-icon" onClick={handleBackward} />
          <Box
            height={280}
            width={480}
            border={1}
            borderColor={"#f0f0f0"}
            marginRight={"10px"}
            marginLeft={"5px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <MediaContent
              key={mediaItems[activeIndex].id}
              content={mediaItems[activeIndex]}
              handleMediaAdd={() => {}}
              handleMediaDelete={() => {}}
              showDeleteIcon={false}
              addMedia={false}
            />
          </Box>
          <ArrowForward className="arrow-icon" onClick={handleForward} />
        </div>
        {createCircle()}
      </div>
    ) : (
      <Typography paddingLeft={"10px"} paddingTop={"10px"} color={"grey.400"}>
        No media has been uploaded yet.
      </Typography>
    );
  };

  //Function for filling the dialogue box
  const fetchToolData = async () => {
    if (!row) {
      setDescription("");
      setCheckedGBA({});
      setCheckedAssetType({});
      return;
    }
    setDescription(row.description);

    const gbaState = {};
    row.gba?.split(",").forEach((gba) => {
      gbaState[dictGba[gba.trim()]] = true;
    });
    setCheckedGBA(gbaState);

    const assetTypeState = {};
    row.assetType?.split(",").forEach((assetType) => {
      assetTypeState[dictDisciplinesACC[assetType.trim()]] = true;
    });
    setCheckedAssetType(assetTypeState);
  };
  const deleteMediaFiles = (toolId, mediaId) => {
    const accessToken = TokenReusable();

    fetch(`${BaseUrl()}/api/v2/tools/${toolId}/media/${mediaId}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${accessToken}` },
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
      })
      .then(() => {
        return delay(500);
      })
      .then(() => {
        console.log("Media file deleted successfully");
      })
      .catch((error) => {
        console.error("DeleteMediaFiles error:", error.message);
      });
  };

  //Function for media files
  async function getMediaFiles(rowData) {
    const accessToken = TokenReusable();
    setIsMediaLoading(true);

    try {
      const response = await fetch(`${BaseUrl()}/api/v2/tools/${rowData.id}/media`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        method: "GET",
        redirect: "follow",
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const rowMedia = rowData?.media;
      const zipBlob = await response.blob();
      await processZipFile(zipBlob, rowMedia);
    } catch (error: any) {
      console.error("GetMediaFiles error:", error.message);
    } finally {
      await delay(500);
      setIsMediaLoading(false);
    }
  }
  const processZipFile = async (zipBlob, rowMedia) => {
    try {
      const zip = await JSZip.loadAsync(zipBlob);
      const filePromises: any[] = [];
      const parsedRowMedia = JSON.parse(rowMedia || "[]");
      for (const [relativePath, zipEntry] of Object.entries(zip.files)) {
        // Check if the zip entry is a file and not a directory
        if (!zipEntry.dir) {
          // Find the corresponding media item
          const mediaItem = parsedRowMedia.find((item) => relativePath.includes(item.Name));
          if (mediaItem) {
            let filePromise = processZipEntry(zipEntry, mediaItem.Id);
            filePromises.push(filePromise);
          }
        } else {
          console.log("Zip is a directory!");
        }
      }
      const existingMediaItems = await Promise.all(filePromises);
      setMediaItems(existingMediaItems);
      setIsMediaLoading(false);
    } catch (error) {
      console.error("Error processing zip file:", error);
    } finally {
      await delay(500);
    }
  };
  const processZipEntry = async (zipEntry, mediaId) => {
    try {
      const content = await zipEntry.async("blob");
      const filePromise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Extract the file extension and convert it to lower case.
          const fileExtension = zipEntry.name.split(".").pop().toLowerCase();

          // Check the file extension against known image/video extensions.
          const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
          const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv"];

          // Determine media type based on the file extension.
          let mediaType;
          let dataURL: any = reader.result;
          if (imageExtensions.includes(fileExtension)) {
            mediaType = "image";
          } else if (videoExtensions.includes(fileExtension)) {
            mediaType = "video";
            // Replace the MIME type in the data URL if it's incorrectly set to 'application/octet-stream'
            if (dataURL.startsWith("data:application/octet-stream")) {
              dataURL = dataURL.replace("data:application/octet-stream", `data:video/${fileExtension}`);
            }
          } else {
            mediaType = "unknown"; // or default to 'image' if preferred
          }
          resolve({
            media: dataURL,
            mediaType: mediaType,
            id: mediaId,
          });
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(content);
      });
      await filePromise;
      return filePromise;
    } catch (error) {
      console.error("Error processing zip entry:", error);
    }
  };

  //Calculation of tool data row height
  const wordsPerLine = isScreenLarge() ? 8 : 3;
  const lineHeight = 20;
  const dynamicRowHeight = ({ index }) => {
    const row = sortedArray[index];
    const baseHeight = 30; // Minimum height for a row
    let estimatedHeight = baseHeight;

    // Increase height based on the length of the description
    let longest = row.description.split(" ").length > row.name.split(" ").length ? row.description : row.name;
    if (row.description || row.name) {
      const lines = longest.split(" ").length / wordsPerLine; // Estimate lines
      estimatedHeight += lines * lineHeight; // Add height based on number of lines
    }

    return Math.max(estimatedHeight, baseHeight); // Ensure minimum height
  };

  //Function to render tool data with virtualized library
  const rowFilter = () => {
    if (filter.length === 0) {
      return <Typography sx={{ my: 2, ml: 2, color: "#808080" }}>No item found...</Typography>;
    }

    return (
      <AutoSizer onResize={handleResizeRow}>
        {({ width, height }) => (
          <Table
            key={windowWidth}
            rowClassName={({ index }) => (index === -1 ? null : "row-style")}
            onRowClick={({ rowData }) => {
              toggleInfo();
              setRow(rowData);
              window.history.pushState({}, "", "/Automate?id=" + rowData.id);
            }}
            headerClassName="header-style"
            ref={tableRef}
            width={width}
            height={height}
            headerHeight={55}
            rowHeight={dynamicRowHeight}
            rowCount={filter.length}
            rowGetter={({ index }) => sortedArray[index]}
          >
            <Column className="standard-text" cellRenderer={renderToolIcon} dataKey="icon" width={width * 0.05} />
            <Column
              headerRenderer={leftHeaderRenderer}
              cellRenderer={renderTextWithTooltip}
              label="Command Name"
              dataKey="commandName"
              width={width * 0.17}
            />
            <Column
              headerRenderer={leftHeaderRenderer}
              cellRenderer={renderTextWithTooltip}
              label="Display Name"
              dataKey="name"
              width={width * 0.17}
            />
            <Column
              cellRenderer={renderTextWithTooltip}
              headerRenderer={leftHeaderRenderer}
              label="Description"
              dataKey="description"
              width={width * 0.24}
            />
            <Column
              headerRenderer={centerHeaderRenderer}
              cellRenderer={({ cellData }) => renderArrayWithTooltip(cellData, "ellipse-tool")}
              label="GBA"
              dataKey="gba"
              width={width * 0.10}
            />
            <Column
              cellRenderer={({ cellData }) => renderArrayWithTooltip(cellData, "ellipse-tool")}
              headerRenderer={centerHeaderRenderer}
              label="Discipline"
              dataKey="assetType"
              width={width * 0.10}
            />
            <Column
              cellRenderer={({ cellData }) => renderApplicationIcon(cellData, true)}
              headerRenderer={centerHeaderRenderer}
              label="Application"
              dataKey="software"
              width={width * 0.066}
            />
            <Column
              cellRenderer={({ cellData }) => renderTextWithTooltip({ cellData }, true)}
              headerRenderer={centerHeaderRenderer}
              label="Workspace"
              dataKey="workspace"
              width={width * 0.097}
            />
            <Column
              cellRenderer={({ cellData }) => renderTextWithTooltip({ cellData }, true)}
              headerRenderer={centerHeaderRenderer}
              label="Toolkit"
              dataKey="toolkit"
              width={width * 0.108}
            />
            <Column cellRenderer={({ rowData }) => renderThreeDots(rowData)} dataKey="" width={width * 0.024} />
          </Table>
        )}
      </AutoSizer>
    );
  };

  //Functions for tool data headers
  const centerHeaderRenderer = ({ label }) => <div className="standard-text table-header-style">{label}</div>;
  const leftHeaderRenderer = ({ label }) => (
    <div className="standard-text table-header-style" style={{ textAlign: "left" }}>
      {label}
    </div>
  );

  //Handlers
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleForward = () => {
    setActiveIndex((prev) => (prev + 1) % mediaItems?.length);
  };
  const handleBackward = () => {
    if (activeIndex !== 0) {
      setActiveIndex((prev) => (prev - 1) % mediaItems?.length);
    }
  };
  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuOpen(null);
  };
  const handleGBASelect = (e) => setCheckedGBA((prev) => updateChecked(prev, e.target.name, e.target.checked));
  const handleAssetSelect = (e) => setCheckedAssetType((prev) => updateChecked(prev, e.target.name, e.target.checked));
  const handleSearch = (event) => {
    setSearchString(event.target.value);
  };
  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newSearchString = event.currentTarget.value;
    setSearchString(newSearchString);
    const filtered = filterTools(newSearchString, gba, discipline, application, workspace, toolkit);
    setFilter(filtered);
  };
  const handleApplicationChange = (event) => {
    const newApplication = event.target.value;
    setApplication(newApplication);
    const filtered = filterTools(searchString, gba, discipline, newApplication, workspace, toolkit);
    setFilter(filtered);
  };
  const handleGbaChange = (event) => {
    const newGba = event.target.value;
    setGba(newGba);
    const filtered = filterTools(searchString, newGba, discipline, application, workspace, toolkit);
    setFilter(filtered);
  };
  const handleDisciplineChange = (event) => {
    const newDiscipline = event.target.value;
    setDiscipline(newDiscipline);
    const filtered = filterTools(searchString, gba, newDiscipline, application, workspace, toolkit);
    setFilter(filtered);
  };
  const handleWorkspaceChange = (event) => {
    const newWorkspace = event.target.value;
    setWorkspace(newWorkspace);
    const filtered = filterTools(searchString, gba, discipline, application, newWorkspace, toolkit);
    setFilter(filtered);
  };
  const handleToolkitChange = (event) => {
    const newToolkit = event.target.value;
    setToolkit(newToolkit);
    const filtered = filterTools(searchString, gba, discipline, application, workspace, newToolkit);
    setFilter(filtered);
  };
  const handleMediaAdd = (content, event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    setFiles((prevFiles) => [...prevFiles, file]);

    const reader = new FileReader();
    reader.onload = () => {
      const updatedContent = {
        ...content,
        media: reader.result,
        mediaType: file.type.startsWith("video/") ? "video" : "image",
        id: uuid(),
      };
      handleFileLoad(updatedContent);
    };
    reader.onerror = () => console.error("Error reading file");
    reader.readAsDataURL(file);
  };
  const handleFileLoad = (updatedContent) => {
    setMediaItems((previousMedia) => {
      const existingItemIndex = previousMedia.findIndex((item) => item.id === updatedContent.id);
      if (existingItemIndex !== -1) {
        return previousMedia.map((item, index) =>
          index === existingItemIndex ? { ...item, ...updatedContent } : item
        );
      } else {
        return [...previousMedia, updatedContent];
      }
    });
  };
  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  const handleResizeRow = () => {
    tableRef.current?.recomputeRowHeights();
  };
  const handleMediaDelete = (deletedContent) => {
    setMediaItems((prevData) => prevData.filter((content) => content.id !== deletedContent.id));
    setDeletedMedia((prev) => [...prev, deletedContent]);
  };
  const handleCancelMain = (row) => {
    if (row) {
      setDescription(row.description || "");
      const gbaState = {};
      row.gba?.split(",").forEach((gba) => {
        gbaState[dictGba[gba.trim()]] = true;
      });
      setCheckedGBA(gbaState);

      const assetTypeState = {};
      row.assetType?.split(",").forEach((assetType) => {
        assetTypeState[dictDisciplinesACC[assetType.trim()]] = true;
      });

      setMediaItems((prev) => [...prev, ...deletedMedia]);
      setCheckedAssetType(assetTypeState);
    }
    togglePrimary();
    window.history.pushState({}, "", "/Automate/");
  };
  const handleDeleteTool = async (id) => {
    const newToolList = tools.filter((tool) => tool.id !== id);
    setTools(newToolList);
    const accessToken = TokenReusable();
    try {
      const response = await fetch(`${BaseUrl()}/api/v2/tools/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "DELETE",
        redirect: "follow",
      });
      console.log("Delete", response.status);
    } catch (err: any) {
      console.log("DeleteTool", err.message);
    }
  };
  const handleSave = async () => {
    setIsSaving(true);
    if (!row) return;

    try {
      await createUpdatedMediaList(files, row.id);

      const updatedData = {
        description,
        gba: createUpdatedList(checkedGBA, dictGba),
        assetType: createUpdatedList(checkedAssetType, dictDisciplinesACC),
      };

      //Clean unneccasary updates
      Object.keys(updatedData).forEach((key) => {
        if (updatedData[key] === row[key]) {
          delete updatedData[key];
        }
      });
      await updateTool(JSON.stringify(updatedData), row.id);
      const updatedTools = [...tools];

      // Find the index of the item to update
      const index = updatedTools.findIndex((t) => t.id === row.id);

      // Update the item at that index
      if (index !== -1) {
        updatedTools[index] = {
          ...updatedTools[index],
          ...updatedData,
        };
      }
      if (deletedMedia.length > 0) {
        for (const mediaItem of deletedMedia) {
          deleteMediaFiles(row.id, mediaItem.id);
        }
      }
      // Update the state
      setTools(updatedTools);
      setActiveIndex(0);
      window.history.pushState({}, "", "/Automate");
      toggleSecondary();
      setOpenPrimary(false);
      setMenuOpen(null);
      setDeletedMedia([]);
      setFiles([]);
    } catch (err: any) {
      console.log("Save", err.message);
    } finally {
      await delay(1000);
      setIsSaving(false);
    }
  };

  return (
    <MuiTable
      style={{
        marginLeft: "20px",
        marginRight: "50px",
      }}
    >
      <TableBody>
        <TableRow>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px 0px 10px 0px",
              border: "0px",
              alignItems: "end",
              width: "100%",
            }}
          >
            <FormControl
              sx={{
                marginRight: "10px",
                width: formControlWidth(16.7),
              }}
              size="small"
              variant="outlined"
              color="warning"
            >
              <InputLabel id="input-label-search">Search</InputLabel>
              <OutlinedInput
                id="outlined-input-search"
                label="Search"
                type="text"
                value={searchString}
                onChange={handleSearch}
                onKeyDown={handleSearchKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              sx={{
                marginRight: "10px",
                width: formControlWidth(17.4),
              }}
              size="small"
              variant="outlined"
              color="warning"
            >
              <InputLabel id="input-label-application">Application</InputLabel>
              <Select
                id="select-application"
                labelId="select-label-application"
                name="Application"
                multiple
                size="small"
                input={<OutlinedInput label="Application" />}
                value={application}
                onChange={handleApplicationChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {softwares.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox
                      style={{
                        color: "#e4610f",
                      }}
                      checked={application.indexOf(item) > -1}
                    />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              color="warning"
              sx={{
                marginRight: "10px",
                width: formControlWidth(12.7),
              }}
            >
              <InputLabel id="input-label-gba">GBA</InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                  textAlign: "left",
                }}
                id="select-gba"
                name="GBA"
                multiple
                size="small"
                value={gba}
                onChange={handleGbaChange}
                input={<OutlinedInput label="GBA" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {Array.from(new Set(tools.flatMap((row) => row.gba).flatMap((item) => (item ? item.split(",") : []))))
                  .sort((a, b) => a.localeCompare(b))
                  .map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        style={{
                          color: "#e4610f",
                        }}
                        checked={gba.includes(item)}
                      />
                      <ListItemText primary={dictGba[item]} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              color="warning"
              sx={{
                marginRight: "10px",
                width: formControlWidth(12.7),
              }}
            >
              <InputLabel id="input-label-discipline">Discipline</InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                  textAlign: "left",
                }}
                id="select-discipline"
                name="Discipline"
                multiple
                size="small"
                value={discipline}
                onChange={handleDisciplineChange}
                input={<OutlinedInput label="Discipline" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {Array.from(
                  new Set(tools.flatMap((row) => row.assetType).flatMap((item) => (item ? item.split(",") : [])))
                )
                  .sort((a, b) => a.localeCompare(b))
                  .map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        style={{
                          color: "#e4610f",
                        }}
                        checked={discipline.includes(item)}
                      />
                      <ListItemText primary={dictDisciplinesACC[item]} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              sx={{
                marginRight: "10px",
                width: formControlWidth(17.4),
              }}
              color="warning"
            >
              <InputLabel id="input-label-workspace">Workspace</InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                  textAlign: "left",
                }}
                id="select-workspace"
                name="Workspace"
                multiple
                size="small"
                value={workspace}
                onChange={handleWorkspaceChange}
                input={<OutlinedInput label="Workspace" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {Array.from(
                  new Set(
                    tools.map((row) => {
                      return row.workspace;
                    })
                  )
                ).map((item) => (
                  <MenuItem
                    sx={{
                      textAlign: "left",
                    }}
                    key={item}
                    value={item}
                  >
                    <Checkbox
                      style={{
                        color: "#e4610f",
                      }}
                      checked={workspace.indexOf(item) > -1}
                    />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              sx={{
                width: formControlWidth(17.4),
              }}
              color="warning"
            >
              <InputLabel id="input-label-toolkit">Toolkit</InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                  textAlign: "left",
                }}
                id="select-toolkit"
                name="Toolkit"
                multiple
                size="small"
                value={toolkit}
                onChange={handleToolkitChange}
                input={<OutlinedInput label="Toolkit" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {Array.from(
                  new Set(
                    tools.map((row) => {
                      return row.toolkit;
                    })
                  )
                ).map((item) => (
                  <MenuItem
                    sx={{
                      textAlign: "left",
                    }}
                    key={item}
                    value={item}
                  >
                    <Checkbox
                      style={{
                        color: "#e4610f",
                      }}
                      checked={toolkit.indexOf(item) > -1}
                    />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>

        <TableRow
          style={{
            display: "flex",
            flexDirection: "column",
            height: windowHeight - 190,
          }}
        >
          <Box
            sx={{
              border: "1px solid ",
              borderColor: "grey.200",
              width: "100%",
              flexGrow: 1,
              height: "100%",
              overflow: "hidden",
            }}
          >
            {isLoading ? <DEASCircularProgress /> : rowFilter()}
          </Box>
        </TableRow>
      </TableBody>
      <Dialog
        className="arcadis-edit-dialog"
        onClose={togglePrimary}
        aria-labelledby="customized-dialog-title"
        open={openPrimary}
      >
        <DialogTitle className="dialog-title" id="customized-dialog-title">
          <Typography className="standard-text" color={"#ffffff"}>
            SAVE CHANGES
          </Typography>
        </DialogTitle>
        <DialogContent className="dialog-content" style={{ overflowY: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "8.4%",
            }}
          >
            {dialogRowIcon(row, "icon-tool")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "5.2%",
              marginTop: "20px",
            }}
          >
            <TextField
              id="outlined-basic-command"
              className="standard-text"
              label="Command Name"
              variant="outlined"
              color="warning"
              fullWidth
              value={row?.commandName}
              inputProps={{
                readOnly: true,
                className: "standard-text",
                style: {
                  height: "20px",
                  padding: "8px 12px 8px 12px",
                  color: "#00000099",
                },
              }}
            ></TextField>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "5.2%",
              marginTop: "20px",
            }}
          >
            <TextField
              id="outlined-basic-name"
              className="standard-text"
              label="Display Name"
              variant="outlined"
              color="warning"
              fullWidth
              value={row?.name}
              inputProps={{
                readOnly: true,
                className: "standard-text",
                style: {
                  padding: "8px 12px 8px 12px",
                  height: "20px",
                  color: "#00000099",
                },
              }}
            ></TextField>
          </div>
          <div
            id="div-description"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "13.1%",
              marginTop: "20px",
            }}
          >
            <TextField
              id="outlined-basic-description"
              className="standard-text"
              label="Description"
              variant="outlined"
              color="warning"
              fullWidth
              minRows={4}
              multiline
              InputProps={{
                className: "standard-text",
                style: {
                  padding: "8px 12px 8px 12px",
                },
              }}
              value={description}
              onChange={handleDescriptionChange}
            ></TextField>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "36.8%",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingRight: "5px",
              }}
            >
              <Typography className="standard-text">GBA</Typography>
              <Box
                sx={{
                  ...boxStyle,
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <FormGroup>
                  {Object.values(dictGba).map((label) => (
                    <FormControlLabel
                      key={label}
                      sx={{
                        ...checkboxStyle,
                        paddingLeft: "5px",
                        my: "-4px",
                      }}
                      control={
                        <Checkbox
                          name={label}
                          checked={checkedGBA[label] || false}
                          style={{ color: "#e4610f" }}
                          onChange={handleGBASelect}
                        />
                      }
                      label={label}
                    />
                  ))}
                </FormGroup>
              </Box>
            </div>
            <div
              style={{
                width: "50%",
                paddingLeft: "5px",
              }}
            >
              <Typography className="standard-text">Disciplines</Typography>
              <Box
                sx={{
                  ...boxStyle,
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <FormGroup>
                  {Object.values(dictDisciplinesACC).map((label) => (
                    <FormControlLabel
                      key={label}
                      sx={{
                        ...checkboxStyle,
                        paddingLeft: "5px",
                        my: "-4px",
                      }}
                      control={<Checkbox checked={checkedAssetType[label] || false} style={{ color: "#e4610f" }} />}
                      label={label}
                      name={label}
                      onChange={handleAssetSelect}
                    />
                  ))}
                </FormGroup>
              </Box>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "28.3%",
              marginTop: "20px",
            }}
          >
            <Typography
              className="standard-text"
              sx={{
                width: "100%",
              }}
            >
              Screenshots and Videos
            </Typography>
            <div
              className="scrollable-container"
              style={{
                marginTop: "20px",
                paddingRight: "20px",
                overflowY: "hidden",
              }}
            >
              {isMediaLoading ? (
                <DEASCircularProgress />
              ) : (
                mediaItems.map((content) => (
                  <Box key={content.id} className="media-content-box">
                    <MediaContent
                      key={content.id}
                      content={content}
                      handleMediaAdd={(event) => {
                        handleMediaAdd(content, event);
                      }}
                      addMedia={false}
                      showDeleteIcon={true}
                      handleMediaDelete={() => handleMediaDelete(content)}
                    />
                  </Box>
                ))
              )}

              <Box className="media-content-box">
                <MediaContent
                  key={contentAdd.id}
                  content={contentAdd}
                  handleMediaAdd={(event) => handleMediaAdd({}, event)}
                  handleMediaDelete={() => {}}
                  showDeleteIcon={false}
                  addMedia={true}
                />
              </Box>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={() => {
              handleCancelMain(row);
              handleMenuClose();
            }}
            className="cancel-button standard-text"
          >
            CANCEL
          </Button>
          <Button className="save-button standard-text" onClick={toggleSecondary}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="arcadis-save-dialog"
        onClose={toggleSecondary}
        aria-labelledby="customized-dialog-title"
        open={openSecondary}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle className="dialog-title" id="customized-dialog-title">
          <Typography className="standard-text" color={"#ffffff"}>
            SAVE CHANGES
          </Typography>
        </DialogTitle>
        <DialogContent className="dialog-popup">
          <Typography className="standard-text">Are you sure?</Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={() => {
              toggleSecondary();
              handleMenuClose();
            }}
            className="cancel-button standard-text"
          >
            CANCEL
          </Button>
          <Button onClick={handleSave} className="save-button standard-text">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={toggleInfo}
        aria-labelledby="customized-dialog-title"
        open={openInfo}
        maxWidth="sm"
        className="arcadis-edit-dialog"
        fullWidth={true}
      >
        <DialogTitle className="dialog-title" id="customized-dialog-title">
          <Typography className="standard-text" color={"#ffffff"}>
            TOOL INFORMATION
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "20px 30px 20px 30px",
            overflowY: "hidden",
          }}
        >
          {row && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                  }}
                >
                  {dialogRowIcon(row, "dialog-icon-tool")}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Typography className="standard-title" paddingBottom={"5px"}>
                    {row.name}
                  </Typography>
                  <Typography className="standard-text" paddingBottom={"17px"}>
                    {row.commandName}
                  </Typography>
                  {renderArrayWithTooltip(row.gba, "dialog-ellipse-tool")}
                  {renderArrayWithTooltip(row.assetType, "dialog-ellipse-tool")}
                </div>
              </div>
              <hr color="#f0f0f0" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography className="standard-text" paddingBottom={"20px"} paddingLeft={"10px"} width={"138px"}>
                  Application:
                </Typography>
                {renderApplicationIcon(row.software, false)}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography className="standard-text" width={"138px"} paddingLeft={"10px"} paddingBottom={"15px"}>
                  Toolkit:
                </Typography>
                <Typography>{row.toolkit}</Typography>
              </div>
              <hr color="#f0f0f0" />
              <div
                style={{
                  height: "150px",
                }}
              >
                <Typography className="standard-title" paddingLeft={"10px"}>
                  Description
                </Typography>
                <div
                  style={{
                    overflowY: "auto",
                    height: "80%",
                  }}
                >
                  <Typography className="standard-text" paddingTop={"10px"} paddingLeft={"10px"} paddingRight={"10px"}>
                    {row.description}
                  </Typography>
                </div>
              </div>
              <hr color="#f0f0f0" />

              <div>
                <Typography className="standard-title" paddingLeft={"10px"}>
                  Screenshots and Videos
                </Typography>

                {isMediaLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <DEASCircularProgress />
                  </div>
                ) : (
                  checkMediaItems()
                )}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={() => {
              toggleInfo();
              window.history.pushState({}, "", "/Automate/");
              setActiveIndex(0);
            }}
            className="save-button standard-text"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteTool}>
        <DialogTitle className="dialog-title" id="customized-dialog-title">
          <Typography className="standard-text" color={"#ffffff"}>
            TOOL CATALOG
          </Typography>
        </DialogTitle>
        <DialogContent className="dialog-popup">
          <Typography className="standard-text">Are you sure you want to delete the tool: {row?.name}?</Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={toggleDeleteTool} className="save-button standard-text">
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleDeleteTool(row?.id);
              toggleDeleteTool();
            }}
            className="save-button standard-text"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Menu anchorEl={menuOpen} open={Boolean(menuOpen)} onClose={handleMenuClose}>
        <MenuItem
          style={{ width: "150px" }}
          onClick={() => {
            if (row) {
              window.history.pushState({}, "", "/Automate/Edit?id=" + row.id);
            }

            togglePrimary();
          }}
        >
          <EditIcon />
          <span className="standard-text" style={{ marginLeft: "8px" }}>
            Edit
          </span>
        </MenuItem>

        <hr color="#f0f0f0" />
        <MenuItem onClick={toggleDeleteTool}>
          <DeleteIcon />
          <span className="standard-text" style={{ marginLeft: "8px" }}>
            Delete
          </span>
        </MenuItem>
      </Menu>
    </MuiTable>
  );
}
