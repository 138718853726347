import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import HomePage from './HomePage';
import Communications from './Communications';
import Workspaces from './components/AddIns/Workspaces';
import Message from './Message';
import React from 'react';
import WorkspaceEditor from './components/AddIns/WorkspaceEditor';
import Automate from './components/AddIns/Automate';
import Insights from './components/Insights';
import Connect from './components/Connect';
import MainHome from './components/MainHome';
import UserManagement from './components/UserManagement';
import MainPage from './components/MainPage';
import Guides from './components/Guides';
import DEASCore from './components/DEASCore';
import RevitConnector from './components/RevitConnector';
import Civil3DConnector from './components/Civil3DConnector';
import {
  AuthenticatedTemplate /* , UnauthenticatedTemplate  */
} from '@azure/msal-react';
import CustomTools from './components/InsightsPages/CustomTools';
import DynamoUsers from './components/InsightsPages/DynamoUsers';
import DeasCore from './components/InsightsPages/Deas-Core';
import Revit from './components/InsightsPages/Revit';
import DynamoExecutions from './components/InsightsPages/DynamoExecutions';
import WorkSpaces from './components/InsightsPages/WorkSpaces';
import ProtectedRoute from './components/ProtectedRoute';
import Civil3D from "./components/InsightsPages/Civil3D";
import ToolDeveloper from './components/ToolDeveloper';
import Unifi from './components/InsightsPages/Unifi';
import Orkestra from './components/InsightsPages/Orkestra';
import DiRoots from './components/InsightsPages/DiRoots';
import Magicad from './components/InsightsPages/Magicad';
import Enscape from './components/InsightsPages/Enscape';
import PyRevit from './components/InsightsPages/PyRevit';
import AboutDEAS from './components/AboutDEAS';
import DeasStandards from './components/InsightsPages/DeasStandards';
import Viktor from './components/InsightsPages/Viktor';

const useStyles = makeStyles({
  container: {
    paddingTop: '70px',
    display: 'flex',
  },

  display_full: {
    width: '100%',
    paddingLeft: '15px',
  },

  display_home: {
    width: '60%',
    paddingLeft: '300px',
    justifyContent: 'center',
  },
});

export default function App() {
  const classes = useStyles();
  return (
    <Router>
      <Switch>
        <Route
          path='/'
          exact
          component={HomePage}
        />
      </Switch>

      <div className={classes.container}>
        <AuthenticatedTemplate>
          <MainPage />
          <Route
            path={`/`}
            exact
            component={MainHome}
          />
        </AuthenticatedTemplate>

        <Switch>
          <Route
            path='/Automate'
            render={() => (
              <ProtectedRoute>
                <Automate />
              </ProtectedRoute>
            )}
          />
          <div className={classes.display_full}>
            <Route
              path={`/Connect`}
              render={() => (
                <ProtectedRoute>
                  <Connect />
                </ProtectedRoute>
              )}
            ></Route>
            <Route
              path={`/AboutDEAS`}
              render={() => (
                <ProtectedRoute>
                  <AboutDEAS />
                </ProtectedRoute>
              )}
            ></Route>
            <Route
              path={`/Workspaces`}
              render={() => (
                <ProtectedRoute>
                  <Workspaces />
                </ProtectedRoute>
              )}
            />

            <Route
              path={`/UserManagement`}
              render={() => (
                <ProtectedRoute>
                  <UserManagement />
                </ProtectedRoute>
              )}
            />
            <Route
              path={`/workspace`}
              render={() => (
                <ProtectedRoute>
                  <WorkspaceEditor />
                </ProtectedRoute>
              )}
            >
            </Route>
            
            <Route
              path={`/Communications`}
              render={() => (
                <ProtectedRoute>
                  <Communications />
                </ProtectedRoute>
              )}
            />
            <Route path={`/Message/id=:id`}>
              <Message />
            </Route>

            <Switch>
              <Route
                path={`/Guides`}
                exact
                render={() => (
                  <ProtectedRoute>
                    <Guides />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Guides/DEASCore`}
                render={() => (
                  <ProtectedRoute>
                    <DEASCore />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Guides/RevitConnector`}
                render={() => (
                  <ProtectedRoute>
                    <RevitConnector />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Guides/Civil3DConnector`}
                render={() => (
                  <ProtectedRoute>
                    <Civil3DConnector />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Guides/ToolDeveloper`}
                render={() => (
                  <ProtectedRoute>
                    <ToolDeveloper />
                  </ProtectedRoute>
                )}
              />
            </Switch>

            <Switch>
              <Route
                path={`/Insights`}
                exact
                render={() => (
                  <ProtectedRoute>
                    <Insights />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Insights/CustomTools`}
                render={() => (
                  <ProtectedRoute>
                    <CustomTools />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Insights/DynamoUsers`}
                render={() => (
                  <ProtectedRoute>
                    <DynamoUsers />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Insights/WorkSpaces`}
                render={() => (
                  <ProtectedRoute>
                    <WorkSpaces />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Insights/DeasCore`}
                render={() => (
                  <ProtectedRoute>
                    <DeasCore />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Insights/Revit`}
                render={() => (
                  <ProtectedRoute>
                    <Revit />
                  </ProtectedRoute>
                )}
              />

              <Route
                path={`/Insights/DynamoExecutions`}
                render={() => (
                  <ProtectedRoute>
                    <DynamoExecutions />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/Civil3D`}
                render={() => (
                  <ProtectedRoute>
                    <Civil3D />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/Unifi`}
                render={() => (
                  <ProtectedRoute>
                    <Unifi />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/Orkestra`}
                render={() => (
                  <ProtectedRoute>
                    <Orkestra />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/DiRoots`}
                render={() => (
                  <ProtectedRoute>
                    <DiRoots />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/Magicad`}
                render={() => (
                  <ProtectedRoute>
                    <Magicad />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/Enscape`}
                render={() => (
                  <ProtectedRoute>
                    <Enscape />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/PyRevit`}
                render={() => (
                  <ProtectedRoute>
                    <PyRevit />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/DeasStandards`}
                render={() => (
                  <ProtectedRoute>
                    <DeasStandards />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={`/Insights/Viktor`}
                render={() => (
                  <ProtectedRoute>
                    <Viktor/>
                  </ProtectedRoute>
                )}
              />              
            </Switch>
          </div>
        </Switch>
      </div>
    </Router>
  );
}
