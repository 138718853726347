import styled from 'styled-components';
import {TextField} from '@mui/material';

export const titleStyle = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '22px',
  lineHeight: '100%',
  color: '#252423',
  whiteSpace: 'nowrap',
  alignItems: 'center',
};

export const checkboxStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
  },
}

export const labelStyle = {
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#414141',
    whiteSpace: 'nowrap',
    alignItems: 'center',
}

export const buttonStyle = {
  fontSize:'14px  !important', 
  border: '1px solid #e77229 !important',
  borderRadius:'5px !important',
  width: '100px !important',
  outerHeight: '50px !important',
  textAlign: 'center !important',
  marginBottom: '20px !important',
}
// Styled components
export const Container = styled.div`
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-columns: 2fr 2fr  2fr 2fr  2fr 2fr;
  grid-template-rows: 2fr 2fr 1fr 1fr 3fr 3fr 1fr 5fr 2fr; 
  grid-template-areas:
    "account          account           account         account    account    account"
    "workspace        workspace         workspace       workspace  workspace  workspace"
    "displayName      displayName       icon            definition definition definition"
    "owner            owner             icon            definition definition definition"
    "shortDescription shortDescription  icon            definition definition definition"
    "longDescription  longDescription   longDescription definition definition definition"
    "region           discipline        application     definition definition definition"
    "region           discipline        version         definition definition definition"
    "save             save              save            save       save       save";

  grid-gap: 20px;
  
`;

export const Account = styled.div`
  // background-color: #797AF2;
  grid-area: account;
`;


export const Workspace = styled.div`
  // background-color: #FFADAD;
  grid-area: workspace;
`;

export const ShortDescription = styled.div`
  // background-color: #FFADAD;
  grid-area: shortDescription;
  margin-left: 20px;
`;

export const DisplayName = styled.div`
  // background-color: #749DC0;
  grid-area: displayName;
  margin-left: 20px;
`;
export const Owner = styled.div`
  // background-color: #749DC0;
  grid-area: owner;
  margin-left: 20px;
`;

export const Icon = styled.div`
  // background-color: #FFD6A5;
  grid-area: icon;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LongDescription = styled.div`
  // background-color: #BDB2FF;
  grid-area: longDescription;
  margin-left: 20px;
`;
export const Region = styled.div`
  // background-color: #FFC6FF;
  grid-area: region;
  margin-left: 20px;
`;
export const Discipline = styled.div`
  // background-color: #9BF6FF;
  grid-area: discipline;
`;
export const Application = styled.div`
  // background-color: #FDFFB6;
  grid-area: application;
`;
export const Version = styled.div`
  // background-color: #FFD6A5;
  grid-area: version;
`;
export const Definition = styled.div`
  // background-color: #FFADAD;
  grid-area: definition;
  margin-right: 20px;
  height: 100%
`;
export const Save = styled.div`
  margin-bottom: 20px;
  grid-area: save;
`;

interface StyledTextFieldProps {
    'data-area': string;
    labelheight?: string;
    marginLeft?: string;
    marginRight?: string;
}

export const StyledTextField = styled(TextField) <StyledTextFieldProps>`
grid-area: ${props => props['data-area']};
width: calc(100% - ${props => props.marginLeft ?? '0px'} - ${props => props.marginRight ?? '0px'});
margin-left: ${props => props.marginLeft ?? '0px'}; 
margin-right: ${props => props.marginRight ?? '0px'}; 
height: ${props => props.labelheight ? `calc(100% - ${props.labelheight})` : '100%'};
display: flex;
flex-direction: column;
.MuiInputBase-root {
  flex-grow: 1;
  display: flex;
  align-items: flex-start; 
}
`;



export const boxStyle = {
  border:'1px solid ',
  borderColor:"grey.400",
  borderRadius:'5px',
  display:"flex",
  flexDirection:"column",
  height: 'calc(100% - 24px)'

};