import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import '../App.css';
import {withRouter} from 'react-router-dom';
import ScrollToAnchor from './ScrollToAnchor';
import revit from './ControlRoomImages/Revit.svg';
import civil3d from './ControlRoomImages/civil3d.svg';
import header from './AboutDEASContent/header_about_deas.png';
import create_new_repo from './ToolDeveloperContent/create-new-repo.png';
import build_and_deploy_to_blob from './ToolDeveloperContent/build-and-deply-to-blob.png';
import debug1 from './ToolDeveloperContent/debug1.png';
import debug2 from './ToolDeveloperContent/debug2.png';
import netload from './ToolDeveloperContent/netload.png';
import deascomm1 from './ToolDeveloperContent/deascommand1.png';
import deascomm2 from './ToolDeveloperContent/deascommand2.png';
import r_create_new_repo from './ToolDeveloperContent/r_create-new-repo.png';
import r_build_and_deploy_to_blob from './ToolDeveloperContent/r_build-and-deply-to-blob.png';
import r_debug1 from './ToolDeveloperContent/r_debug1.png';
import r_debug2 from './ToolDeveloperContent/r_debug2.png';
import r_addin_manager from './ToolDeveloperContent/r_addin-manager.png';
import r_load_dll from './ToolDeveloperContent/r_load-dll.png';
import r_uiapp from './ToolDeveloperContent/r_uiapp.png';

const ToolDeveloper=() => {
    ScrollToAnchor(); 

    return (
        <div>
    <Grid paddingRight={'20px'}>
    <Grid container spacing={'1px'} item xs={12} >
    <Grid item xs={12}
        style={{backgroundImage: `url(${header})`, backgroundSize:'cover', height:"180px"  }} >
            <Grid paddingTop={'100px'} paddingLeft={'20px'}>
                <p style={{color:'#e4610f', fontSize:'25px', fontWeight:'500' }} >Guide - Tool Developer</p>
            </Grid>
    </Grid>

            <Grid item xs={12}>
                <p style={{fontSize:'24px', fontWeight:'500',
                    color:'#e4610f',
                    height:'80px',
                    background:'#f0f0f0', paddingTop:'25px'}} >
                     I want to create a tool in DEAS</p>
            </Grid>
            <Grid container item xs={8} paddingBottom={'10px'}>
            <p className="standard-text">To add a tool to DEAS, use the following basic process:
            <ol className="standard-text" style={{paddingInlineStart:'20px', paddingBottom:'20px'}}>
                <li><b>Create</b>
                    <ul>
                        <li>Create an 
                            <span><a href="https://arcadiso365.sharepoint.com/teams/CitizenDevelopment/SitePages/Script-Governance-GitHub.aspx"
                            rel="noreferrer" className="standard-text" target="_blank" style={{color:'#e4610f'}}> Arcadis GitHub account.</a></span></li>
                        <li>For Autodesk create your tool in C# or .NET</li>
                        <li>Follow the software specific guidelines, templates and GitHub setup beneath</li>
                    </ul>
                </li>
                <li><b>Publish</b>
                    <ul>
                        <li>Publish the tool in DEAS (first time requires help from the DEAS team)</li>
                        <li>Add information about the tool (owner, description, images, video’s, etc.)</li>
                        <li>Have a workspace created, as a collection of tools</li>
                    </ul>
                </li>
                <li><b>Inform</b>
                    <ul>
                        <li>Inform relevant Digital leads, Service leads, CoP leads etc. about the added functionality</li>                        
                    </ul>
                </li>                
            </ol> 
            </p>                      
            </Grid>

            <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
            <Grid item xs={0.25} />

            <Grid  item xs={3.5} >
                <Grid item xs={11} paddingBottom='10px'>
                <Button variant="contained" fullWidth
                    className="mainhome-button" 
                    onClick={() => window.location.href="#revit-template"}>
                    <Grid item xs={2} paddingTop={'5px'}><img src={revit} className='svg_tool' alt=""/></Grid>
                    <Grid item xs={9} display='flex' direction='row' alignContent='left'>GO TO REVIT APPLICATIONS TEMPLATE</Grid>
                </Button>
                </Grid>

                <Grid item xs={11} paddingBottom='10px'>
                <Button variant="contained" fullWidth 
                    className="mainhome-button" 
                    onClick={() => window.location.href="#civil3d-template"}>
                    <Grid item xs={2} paddingTop={'10px'}><img src={civil3d} className='svg_tool' alt=""/></Grid>
                    <Grid item xs={9} display='flex' direction='row' alignContent='left'>GO TO CIVIL 3D APPLICATIONS TEMPLATE</Grid>
                </Button>
                </Grid>
            </Grid>  
        
        {/*Autocad/Civil 3D Template*/}                 
        <Grid item xs={12}>
            <p style={{fontSize:'24px', fontWeight:'500',
                color:'#e4610f',
                height:'80px',
                background:'#f0f0f0', paddingTop:'25px'}} id="revit-template">
                Arcadis Revit Application Template</p>
        </Grid>

        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>This template is for building custom plugins for Revit using the DEAS Framework.</p>
       {/*  new content */}
       <h2>Environment Setup</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        The first step to start creating your Revit DEAS plugin is creating a new repo using Github and selecting this repository as the template:
        </p>
        <img src={r_create_new_repo} alt="" className="img"/>
        <p className="standard-text" style={{alignContent:'flex-end'}}>After that there are few additional steps needed to get up and running.</p>
        <h2>Generating a GitHub Personal Access Token to get access to the Arcadis nuget packages</h2>
        <p className='standard-text'>
        This template uses several Arcadis libraries that are distributed through Nuget, it is important that you can get access to those in your local environment. 
        This is a one off operation, so you have done this before for a different project you can skip this step! 
        A great step by step guide on how to do this can be found in <span><a href="https://dev.azure.com/Arcadis/Arcadis%20Technology/_wiki/wikis/Arcadis-Technology.wiki/1041/Install-PIMSConnectorCore-as-a-developer"
        rel="noreferrer" className="standard-text" target="_blank" style={{color:'#e4610f'}}>here</a>.</span>
        </p>   
        <h2>Renaming the solution and updating the name in the build action</h2>
        <p className='standard-text'>
        You probably want to give your new plugin a descriptive and cachy name rather than &quot;ArcadisRevitApplicationTemplate&quot;,
         so it is time to rename the files in your new repo.
        </p>
        <ul className='standard-text'>
            <li>Rename the solution. Sadly there is no one-click operation to rename a project in VS so a bit of thinkering is needed:</li>
            <ul className='standard-text'>
                <li>In Solution Explorer, right click on Main solution → Rename</li>
                <li>In Solution Explorer, right click on project (under solution) → Rename</li>
                <li>In Solution Explorer, double click, or right click on Properties → goto Application Tab, rename Assembly name and Default namespace to match.</li>
                <li>Rename the namespace, open one of your class files. Right click the namespace → Rename.... This should search and replace all references to your namespace throughout the project.</li>
                <li>Close Visual Studio, go to the folder where your solution is and rename the folder name with your new name.</li>
                <li>Edit the .sln file in Notepad, and change the path to the csproj, i.e., oldName\newName.csproj → newName\newName.csproj.</li>
            </ul>
            <li>You also have to update the name in the BlobBuilder.yml file that is found in the .github/workflow folder. 
                The name you write there must be the same as the name you gave to the .sln in your project solution!</li>
        </ul>
        <img src={r_build_and_deploy_to_blob} className="img" alt="" />
        <h3>Plugin Name Notes</h3>
        <p className='standard-text'>There are a couple of requirements for plugin names:</p>
        <ul className='standard-text'>
            <li>Must not contain spaces or special characters</li>
            <li>Must be unique within the DEAS Framework</li>
        </ul>
        <h2>On-Boarding into DEAS Framework</h2>
        <p className='standard-text'>
        This template includes GitHub actions to build and publish your plugin in the DEAS Framework. 
        However, the actions <b>will not work</b> without a little bit of help from the DEAS and/or DevOps team.   
        </p>
        <p className='standard-text'>Once you are ready to publish your plugin, you&apos;ll need to follow a couple steps:</p>
        <ol className='standard-text'>
            <li>Create some metadata about your plugin. This is used to display to users things like information about the status of the plugin, who wrote it, a short description, and usage documentation.</li>
            <li>Notify the DEAS team that you have a plugin that you wish to publish.</li>
        </ol>
        <p className='standard-text'>
        Once you notify the DEAS team, they will review your plugin and the metadata you created and finalize the settings necessary for the GitHub actions to run. Once that&apos;s complete, your plugin will be published!
        </p>
        
        <h2>Creating a workspace</h2>
        <p className='standard-text'>
        The DEAS framework allows to create customizable ribbon menus in Revit by &quot;mixing and matching&quot; tools from 
        different solutions. Each of these custom configurations is called a workspace. This means that as opposed to
        &quot;standard&quot; Revit plugins the different panels, split buttons, and other UI components are not defined in the
        tool DLL (even if you that it will be ignored by DEAS), instead a workspace for your plugin must be defined using
        the DEAS website. The DEAS teams will guide you on how to set your first workspace successfully.   
        </p>

        <h2>Updating Your Plugin</h2>
        <p className='standard-text'>
        Once your plugin has been on-boarded, any updates you make to your plugin will trigger an automatic deployment.
        Changes in the branch &quot;develop&quot; will be deployed to the development environment, which is accessible to just a few
        users. Changes in the &quot;main&quot; branch will be deployed to the production environment and will be available to anyone
        company wide. Changes in any other branch will not trigger any deployment. If you are adding or removing tools
        remember to update the workspace accordingly.    
        </p>

        <h2>Update this file</h2>
        <p className='standard-text'>
        Once everything is set up feel free to delete all the content in this README file and replace it with the relevant
        information about your plugin. What your plugin is about, limitations, main contact, release notes or anything you
        consider relevant!   
        </p>
        <h2>Debugging</h2>
        <p className='standard-text'>
        At some point you will probably need to debug your plugin, doing so for a DEAS plugin will be slightly different than
        for a &quot;standard&quot; Revit plugin. There are different ways of doing this, here we show you the simplest way of doing 
        this using a plugin called Addin-Manager (you can get the install here:
        <span><a href="https://github.com/chuongmep/RevitAddInManager/releases" rel="noreferrer" className="standard-text" target="_blank" style={{color:'#e4610f'}}>
            https://github.com/chuongmep/RevitAddInManager/releases
            </a></span>).
             You must have open both VS and Revit to follow this process:   
        </p>
        <ul className='standard-text'>
            <li>Build you application for debugging.</li>
            <li>Attach the debugger to the running instance of Revit as shown in the images:<br/>
            <img src={r_debug1} className="img" alt=""/><br/>
            <img src={r_debug2} className="img" alt=""/>
            </li>
            <li>Place break points inside the command that you want to debug.</li>
            <li>In Revit open the Addin Manager tool:<br/>
            <img src={r_addin_manager}  className="img" alt=""/>
            </li>
            <li>In the menu load your .dll, select the command you want to debug and click on run:<br/>
            <img src={r_load_dll} className="img" alt="" />    
            </li>
            <li>After doing this any break point in your code will be triggered and you will be able to debug your tool step by step:<br/>
            <img src={r_uiapp} className="img" alt="" />
            </li>
        </ul>

        </Grid>
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
        <Grid item xs={3.5}>
            <h3>Useful Links:</h3>
            <Grid item xs={11} >
            <Button variant="contained" fullWidth
                 className="mainhome-button" 
                 onClick={() =>window.open("https://github.com/Arcadis/DEAS_ArcadisRevitApplicationTemplate", "_blank", "noopener")}>
                 <Grid item xs={2} paddingTop={'5px'}><img src={revit} className='svg_tool' alt="" /></Grid>
                 <Grid item xs={9} display='flex' direction='row' alignContent='leftGrid'>REVIT APPLICATION TEMPLATE</Grid>
            </Button>
            </Grid>            
        </Grid>
       
    {/*Autocad/Civil 3D Template*/}         
        <Grid item xs={12}>
        <p style={{fontSize:'24px', fontWeight:'500',
            color:'#e4610f',
            height:'80px',
            background:'#f0f0f0', paddingTop:'25px'}} id="civil3d-template">
            Arcadis Autocad / Civil 3D Application Template</p>
        </Grid> 

        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>This template is for building custom plugins for AutoCAD and AutoCAD Civil 3D using the DEAS Framework.</p>
        
        <h2>Environment Setup</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        The first step to start creating your AutoCAD DEAS plguin is creating a new repo using Github and selecting this repository as the template:
        </p>
        <img src={create_new_repo} alt="" className="img"/>
        <p className="standard-text" style={{alignContent:'flex-end'}}>After that there are few additional steps needed to get up and running.</p>
        <h2>Generating a GitHub Personal Access Token to get access to the Arcadis nuget packages</h2>
        <p className='standard-text'>
        This template uses several Arcadis libraries that are distributed through Nuget, it is important that you can get access to those in your local environment. 
        This is a one off operation, so you have done this before for a different project you can skip this step!
        A great step by step guide on how to do this can be found in <span><a href="https://dev.azure.com/Arcadis/Arcadis%20Technology/_wiki/wikis/Arcadis-Technology.wiki/1041/Install-PIMSConnectorCore-as-a-developer"
        rel="noreferrer" className="standard-text" target="_blank" style={{color:'#e4610f'}}>here</a>.</span>
        </p>
        <h2>Renaming the solution and updating the name in the build action</h2>
        <p className='standard-text'>
        You probably want to give your new plugin a descriptive and cachy name rather than &quot;ArcadisAutocadApplicationTemplate&quot;,
         so it is time to rename the files in your new repo.
        </p>
        <ul className='standard-text'>
            <li>Rename the solution. Sadly there is no one-click operation to rename a project in VS so a bit of thinkering is needed:</li>
            <ul className='standard-text'>
                <li>In Solution Explorer, right click on Main solution → Rename</li>
                <li>In Solution Explorer, right click on project (under solution) → Rename</li>
                <li>In Solution Explorer, double click, or right click on Properties → goto Application Tab, rename Assembly name and Default namespace to match.</li>
                <li>Rename the namespace, open one of your class files. Right click the namespace → Rename.... This should search and replace all references to your namespace throughout the project.</li>
                <li>Close Visual Studio, go to the folder where your solution is and rename the folder name with your new name.</li>
                <li>Edit the .sln file in Notepad, and change the path to the csproj, i.e., oldName\newName.csproj → newName\newName.csproj.</li>
            </ul>
            <li>You also have to update the name in the BuildAndDeployToBlob.yml file that is found in the .github/workflow folder. The name you write there must be the same as the name you gave to the .sln in your project solution!</li>
        </ul>
        <img src={build_and_deploy_to_blob} className="img" alt="" />
        <h3>Plugin Name Notes</h3>
        <p className='standard-text'>There are a couple of requirements for plugin names:</p>
        <ul className='standard-text'>
            <li>Must not contain spaces or special characters</li>
            <li>Must be unique within the DEAS Framework</li>
        </ul>
        <h2>On-Boarding into DEAS Framework</h2>
        <p className='standard-text'>
        This template includes GitHub actions to build and publish your plugin in the DEAS Framework. 
        However, the actions <b>will not work</b> without a little bit of help from the DEAS and/or DevOps team.   
        </p>
        <p className='standard-text'>Once you are ready to publish your plugin, you&apos;ll need to follow a couple steps:</p>
        <ol className='standard-text'>
            <li>Create some metadata about your plugin. This is used to display to users things like information about the status of the plugin, who wrote it, a short description, and usage documentation.</li>
            <li>Notify the DEAS team that you have a plugin that you wish to publish.</li>
        </ol>
        <p className='standard-text'>
        Once you notify the DEAS team, they will review your plugin and the metadata you created and finalize the settings necessary for the GitHub actions to run. Once that&apos;s complete, your plugin will be published!
        </p>
        
        <h2>Creating a workspace</h2>
        <p className='standard-text'>
        The DEAS framework allows to create customizable ribbon menus in Autocad by &quot;mixing and matching&quot; tools from 
        different solutions. Each of these custom configurations is called a workspace. This means that as opposed to
        &quot;standard&quot; AutoCAD plugins the different panels, split buttons, and other UI components are not defined in the
        tool DLL (even if you that it will be ignored by DEAS), instead a workspace for your plugin must be defined using
        the DEAS website. The DEAS teams will guide you on how to set your first workspace successfully.   
        </p>

        <h2>Updating Your Plugin</h2>
        <p className='standard-text'>
        Once your plugin has been on-boarded, any updates you make to your plugin will trigger an automatic deployment.
        Changes in the branch &quot;develop&quot; will be deployed to the development environment, which is accessible to just a few
        users. Changes in the &quot;main&quot; branch will be deployed to the production environment and will be available to anyone
        company wide. Changes in any other branch will not trigger any deployment. If you are adding or removing tools
        remember to update the workspace accordingly.    
        </p>

        <h2>Update this file</h2>
        <p className='standard-text'>
        Once everything is set up feel free to delete all the content in this README file and replace it with the relevant
        information about your plugin. What your plugin is about, limitations, main contact, release notes or anything you
        consider relevant!   
        </p>

        <h2>Debugging</h2>
        <p className='standard-text'>
        At some point you will probably need to debug your plugin, doing so for a DEAS plugin will be slightly different than
        for a &quot;standard&quot; AutoCAD plugin. There are different ways of doing this, here we show you the simplest way of doing 
        this. You must have open both VS and AutoCAD to follow this process:   
        </p>
        <ul className='standard-text'>
            <li>Build you application for debugging.</li>
            <li>Attach the debugger to the running instance of Autocad as shown in the images:<br/>
            <img src={debug1} className="img" alt=""/><br/>
            <img src={debug2} className="img" alt=""/>
            </li>
            <li>Place break points inside the command that you want to debug.</li>
            <li>In Autocad run the command &quot;NETLOAD&quot; and pick your plugin .DLL file:<br/>
            <img src={netload}  className="img" alt=""/>
            </li>
            <li>Run your command using its name in the command line. After doing this any break point in your code will be triggered and you will be 
                able to debug your tool step by step:<br/>
                <img src={deascomm1} className="img" alt=""/>
                <img src={deascomm2} className="img" alt=""/>
            </li>
        </ul>
        </Grid>

        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
        <Grid item xs={3.5}>
                         <Grid container >
                        <Grid container > 
                        
                        </Grid>
                    </Grid>
                    <h3>Useful Links:</h3>
                    <Grid item xs={11}>                    
                    <Button variant="contained" fullWidth
                        className="mainhome-button"                                                
                        onClick={() => window.open("https://github.com/Arcadis/DEAS_ArcadisAutocadApplicationTemplate?tab=readme-ov-file", "_blank", "noopener" )}>
                        <Grid item xs={2} paddingTop={'10px'}><img src={civil3d} className='svg_tool' alt=""/></Grid>
                        <Grid item xs={9} display='flex' direction='row' alignContent='left'>AUTOCAD / CIVIL 3D APPLICATION TEMPLATE</Grid>
                    </Button>                       
                    </Grid>
            </Grid>
        </Grid>        
    </Grid>
    
    </div>
    )
}

export default withRouter(ToolDeveloper);
