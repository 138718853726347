import communications from './HomePageContent/communications.svg';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import header from './AboutDEASContent/header_about_deas.png';
import dashboard from './DEASCoreContent/dashboard.png';
import accescomm from './DEASCoreContent/accescomm1.png';
import message from './DEASCoreContent/message.png';
import josha from './DEASCoreContent/joshavanreij.png';
import '../App.css';
import {withRouter, useHistory} from 'react-router-dom';
import React from 'react';
import ScrollToAnchor from './ScrollToAnchor';
import { Tooltip } from '@mui/material';
import update from './RevitConnectorContent/update.png';
import connect from './images_mainpage/connect.svg';
import metrics from './images_mainpage/ideas.svg';
import oldversion from './HomePageContent/oldversion.svg';

const DEASCore=() => {
    
    const history = useHistory();    
        ScrollToAnchor();   
     
    return (
        <div>
    <Grid paddingRight={'20px'}>
    <Grid container spacing={'1px'} item xs={500} >
        <Grid container spacing={'1px'} item xs={12} > 
        <Grid item xs={12}
            style={{backgroundImage: `url(${header})`, backgroundSize:'cover', height:"180px"  }} >
                <Grid paddingTop={'100px'} paddingLeft={'20px'}>
                    <p style={{color:'#e4610f', fontSize:'25px', fontWeight:'500' }} >Guide - DEAS Core</p>
                </Grid>
            </Grid>
            </Grid>
            
            <Grid  item xs={5} paddingRight='10px' paddingTop='20px'>                
            <p className="standard-text" style={{alignContent:'flex-end', paddingTop:'10px'}}>
            <span style={{fontWeight:'600'}}>Summary<br></br></span>
            DEAS Core is a collection of common tools that are useful in any Design & Engineering software. 
            These can be integrated into our engineering applications by creating application connectors.</p>
            </Grid>

            <Grid  item xs={3} paddingLeft={'10px'}>                
            </Grid>

            <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
            <Grid item xs={0.25} />            
                
            <Grid  item xs={3.5}  justifyContent={'center'} paddingTop='20px'>            

            <Grid item xs={10}  paddingBottom='10px' paddingTop='20px'>
            <Button variant="contained" fullWidth 
                 className="mainhome-button" 
                 onClick={() => window.location.href="#application-metrics"}>
                <Grid item xs={2} paddingTop='5px'>
                <img src={metrics} className='svg' alt=""/></Grid>
                <Grid item xs={8} display='flex' direction='row' alignContent='left'>APPLICATION METRICS</Grid>
            </Button>            
            </Grid>

            <Grid item xs={10} paddingBottom='10px'  >
            <Button variant="contained" fullWidth 
                 className="mainhome-button" 
                 onClick={() => window.location.href="#application-messaging"}>
                <Grid item xs={2} paddingTop='5px'>
                <img src={communications} className='svg' alt=""/></Grid>                
                <Grid item xs={8} display='flex' direction='row' alignContent='left'>APPLICATION MESSAGING</Grid>
            </Button>
            </Grid>

            <Grid item xs={10} paddingBottom='10px' >
            <Button variant="contained" fullWidth 
                 className="mainhome-button" 
                 onClick={() => window.location.href="#connect"}>
                <Grid item xs={2} paddingTop='5px'>
                <img src={connect} className='svg' alt=""/></Grid>
                <Grid item xs={8} display='flex' direction='row' alignContent='left'>CONNECT</Grid>
            </Button>    
            </Grid>

            <Grid item xs={10} paddingBottom='1px' >
            <Button variant="contained" fullWidth 
                 className="mainhome-button" 
                 onClick={() => window.location.href="#old-version-warning"}>
                <Grid item xs={2} paddingTop='5px'>
                <img src={oldversion} className='svg' alt=""/></Grid>
                <Grid item xs={8} display='flex' direction='row' alignContent='left'>OLD VERSION WARNING</Grid>
            </Button>    
            </Grid>            
        </Grid>        

        <Grid item xs={12} >
        <Grid id="application-metrics" style={{paddingBottom:'40px'}}/> 
            <p style={{fontSize:'24px', fontWeight:'500',
                color:'#e4610f',
                height:'80px',
                background:'#f0f0f0', paddingTop:'25px'}} >
                Application Metrics</p>
        </Grid>
        
        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>Using application connectors, DEAS captures details of any custom tools that are being used 
        and correlates them with the associated project and team.</p>
        <p  className="standard-text" style={{alignContent:'flex-end'}}>
        The result is a data set that can provide:</p> 
        <ul className="standard-text">
            <li>Project Team Insights</li>
            <li>Process Insights</li>
            <li>Asset Class Insights</li>
            <li>Country Business Insights</li>
            <li>GBA Insights</li>
            <li>Strategic Global D&E Project Initiatives</li>
            <li>Strategic Regional Initiatives</li>
        </ul>
        
        <h2 >Is My Personal Data Being Tracked?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        No! The DEAS team take your privacy very seriously, data reported by DEAS is anonymised.
        This means that it is not possible to identify which tools an individual user has executed.
        </p>
        <h2 >What Data is Being Captured?</h2>
        <p  className="standard-text" style={{alignContent:'flex-end'}}>DEAS captures</p>
        <ul className="standard-text">
            <li>Country where the user is located</li>
            <li>Business area that the user belongs to</li>
            <li>Name of the model or file that the tool was executed in</li>
            <li>Name of the tool and the toolkit it originates from</li>
            <li>Time and date of when the tool was executed.</li>            
        </ul>
        <h2>How Can I View the Data?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        The data is available in an easy-to-use Power Bi dashboard. 
        Anyone within Arcadis can view the dashboard via the DEAS Insights  
        <span style={{color:'#e4610f',cursor:'pointer'}} onClick={() =>history.push('/Insights') } >
                &nbsp;<u>here</u></span>. 
        </p>
        {/* eslint-disable-next-line */}  
        <p><img src={dashboard} width='90%' alt=""/></p>
        </Grid> 

        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
        <Grid item xs={0.25} />

           
        <Grid item xs={3.5}  justifyContent='center' paddingTop={'20px'}>
            <p style={{fontSize:'20px', fontWeight:'500', textAlign:'start'}}>Useful Links:</p>
            <Grid item xs={10} paddingTop={'5px'} >
            <Button variant="contained" fullWidth
                 className="mainhome-button" 
                 onClick={() =>history.push('/Insights') }>
                 <Grid item xs={2} paddingTop={'5px'}>
                 <img src={metrics} className='svg' alt=""/></Grid>
                 <Grid item xs={8} display='flex' direction={'row'} alignContent={'left'}>DEAS INSIGHTS</Grid>
            </Button>
            </Grid>            
         </Grid> 

        
        <Grid item xs={12} >
        <Grid id="application-messaging" style={{paddingBottom:'40px'}}/>       
        <p style={{fontSize:'24px', fontWeight:'500',
            color:'#e4610f',
            height:'80px',
            background:'#f0f0f0', paddingTop:'25px'}}>
            Application Messaging</p>
        </Grid> 

        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>DEAS enables BIM or Team leads to send users in their region an in-application message.
         Additionally, Global Leads can send messages to ALL users of an application in Arcadis.</p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        When a message is sent it is displayed to the application user once during the specified time period. 
        Unread messages will pop up for the user when the application starts and old messages can be reviewed via a separate DEAS tool.        
        </p>
        <p  className="standard-text" style={{alignContent:'flex-end'}}>
        A user is automatically subscribed to receive messages for their region. In the future, features will be added to allow a user to choose additional regions to receive messages from.
        </p>

        <h2>How Do I Send a Message?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        To send a message you first need to be assigned the correct permissions. Please request access by contacting <span style={{color:'#e4610f'}}>
                <a href="mailto:josha.vanreij@arcadis.com" target="_blank" 
                rel="noreferrer" style={{color:'#e4610f'}}>Josha Van Reij</a></span>. 
        </p>

        <h3 style={{color:'#e4610f', fontWeight:'bold'}}>Step 1</h3>
        <p className="standard-text" style={{alignContent:'flex-end'}}>Log on to the DEAS webpage <span style={{color:'#e4610f'}}>
                <a href="https://deas.arcadis.com" target="_blank" 
                rel="noreferrer" style={{color:'#e4610f'}}>here</a></span>.</p>


        <h3 style={{color:'#e4610f', fontWeight:'bold'}}>Step 2</h3>
        <p  className="standard-text" style={{alignContent:'flex-end'}}>
        Go to the communications page by clicking on <b>Communications</b> from left side menu:   
        </p>
        {/* eslint-disable-next-line */}  
        <p><img src={accescomm} width={'40%'} alt=""/></p>
        <h3 style={{color:'#e4610f', fontWeight:'bold'}}>Step 3</h3>
        <p className="standard-text" style={{alignContent:'flex-end'}}>Create your message by filling out the send message form</p>
        <ul className="standard-text">
            <li>
            <b>Select Region</b> Select one or more regions to which you want to send the message. 
            Global leads will have the option to send a message to all regions by selecting the <span style={{color:'#e4610f', fontStyle:'italic'}}>Global</span> option.
            </li>
            <li>
            <b>Select Software</b> Currently only Revit is supported. Additional software will be added in the future.
            </li>
            <li>
            <b>Select Timeframe</b> Select the timeframe for the message to be displayed. 
            Select the start date, then whilst holding the <i>&lt;ctrl&gt;</i> key select the end date. 
            Once sent the message will be displayed only once to the application user during the selected timeframe. If the user does not open the application during the timeframe they will not see the message.
            </li>
            <li>
            <b>Add Message Title</b> Add a descriptive title for your message. 
            </li>
            <li>
            <b>Create Message</b> Type your message in the message box.
            </li>            
        </ul>
        <h3 style={{color:'#e4610f', fontWeight:'bold'}}>Step 4</h3>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        Click the send button, a confirmation dialogue will be displayed, and then a few moments later the message will be displayed in the 
        communications list. Congratulations your message has been sent!   
        </p>
        <h2>How do I Read a Message?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        Any unread messages will be displayed to the user in a pop-up the next time the application starts:    
        </p>        
        {/* eslint-disable-next-line */}  
        <p><img src={message} width={'50%'} alt=""/></p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        Additionally, if a user would like to review or re-read a previous message they can display the pop-up
         at any time using the comms button (in the application ribbon)  
        </p> 
        
        </Grid> 
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
        <Grid item xs={3.5}>
        <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>To access DEAS messaging contact:</p>
                    {/* eslint-disable-next-line */} 
                    <Grid container >
                        <Grid container > 
                        <Grid paddingRight={'10px'}><img src={josha} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                            <Grid >
                                <Grid style={{fontSize:'12px', fontWeight:'600',  textAlign:'start'}} paddingTop={'10px'}>van Reij, Josha</Grid>
                                <Grid style={{fontSize:'12px', fontWeight:'500',  textAlign:'start'}}>Consultant</Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                    <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', paddingTop:'50px'}}>Useful Links:</p>
                    <Grid xs={10} >
                    <Tooltip title="Use the Communications item from the left side menu for real action (if is available for you)" arrow>
                    <Button variant="contained" fullWidth
                        className="mainhome-button"                                                
                        onClick={() => history.push('/')}>
                        <Grid item xs={2} paddingTop={'5px'}><img src={communications} className='svg' alt=""/></Grid>
                        <Grid item xs={8} display='flex' direction='row' alignContent='left'>SEND A MESSAGE NOW</Grid>
                    </Button>
                        </Tooltip>
                    </Grid>
        </Grid>
        </Grid> 
       
        <Grid item xs={12}>
        <Grid id="connect" style={{paddingBottom:'40px'}}/>       
        <p  style={{fontSize:'24px', fontWeight:'500',
            color:'#e4610f',
            height:'80px',
            background:'#f0f0f0', paddingTop:'25px'}} >
            Connect</p>
        </Grid> 
        <Grid item xs={12} container paddingBottom={'50px'}>
        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>The skills and contacts page is a place to go to connect with people with design automation skills.</p>
    
        <h2>How Do I Get Added to the Page?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>The skills and contacts page is a place to go to connect with people with design automation skills.</p>
        <p className="standard-text"  style={{alignContent:'flex-end'}}>
        The functionality to add people to the page is still in development. 
        In the meantime please get in contact and the dev team can get your details added. 
        </p>
        </Grid> 
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
        <Grid item xs={3.5}>        
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start'}}>Useful Links:</p>
        <Grid xs={10}>
        <Button variant="contained" fullWidth
            className="mainhome-button" 
            onClick={() => history.push('/Connect')}>
            <Grid item xs={2} paddingTop={'5px'}><img src={connect} className='svg' alt=""/></Grid>
            <Grid item xs={8} display='flex' direction='row' alignContent='left'>CONNECT</Grid>
        </Button>
        </Grid>                    
        </Grid>
        </Grid>
      
        <Grid item xs={12}>
        <Grid id="old-version-warning" style={{paddingBottom:'40px'}}/>       
        <p style={{fontSize:'24px', fontWeight:'500',
            color:'#e4610f',
            height:'80px',
            background:'#f0f0f0', paddingTop:'25px'}}>
            Old Version Warning</p>
        </Grid> 
        
        <Grid item xs={12} container paddingBottom={'20px'}>
        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>Autodesk only provides support for the past 3 versions of Revit (current year minus 3).</p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        Using unsupported software may cause issues to occur on your project and potentially create unnecessary Arcadis internal support needs to be raised.        
        </p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        To prevent this, if a user starts an unsupported version of Revit a popup message will be shown recommending the user migrate to a newer version.
        This message will continue to pop up as a constant reminder  to move to a newer version.
        </p>
        {/* eslint-disable-next-line */}  
        <p className="standard-text" style={{alignContent:'flex-end'}}><img src={update} width={'60%'} alt=""/><br></br>
        <span style={{ fontSize:"12px"}}>example message popup</span></p>
       </Grid>
        {/*right*/}         
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
        <Grid item xs={0.25} />
        <Grid item xs={3.5}>
        <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>For more information on Autodesk Support contact:</p>
                    {/* eslint-disable-next-line */} 
                    <Grid container >
                    <Grid container >             
                        <Grid style={{fontSize:'20px', fontWeight:'500',  textAlign:'start'}}>
                            <a href="https://arcadiso365.sharepoint.com/teams/autodeskbim360/SitePages/LearningTeamHome.aspx" target="_blank" 
                            rel="noreferrer" style={{color:'#e4610f'}}>Autodesk Platform Team</a>
                        </Grid>
                    </Grid>                   
                    </Grid>                    
        </Grid>
        </Grid>
        </Grid> 
               
        
        <Grid bgcolor={'#e4610f'} >
        <canvas id="myCanvas"  height="30px" style={{border:'1px solid #e4610f'}}></canvas>
    </Grid> 
    
    
    </div>
    )
}

export default withRouter(DEASCore);