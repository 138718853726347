import customtools from './ControlRoomImages/CustomTools.svg';
import workspaces from './ControlRoomImages/workspaces.svg';
import deascore from './ControlRoomImages/deascore.svg';
import revit from './ControlRoomImages/Revit.svg';
import sd_orange from './HomePageContent/sd_orange.svg';
import React from 'react';
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {withRouter} from 'react-router-dom';
import civil3d from './ControlRoomImages/civil3d.svg';
import unifi from './ControlRoomImages/unifi.svg';
import orkestra from './ControlRoomImages/orkestra.svg';
import diroots from './ControlRoomImages/diroots.png';
import magicad from './ControlRoomImages/magicad.png';
import enscape from './ControlRoomImages/enscape.png';
import pyrevit from './ControlRoomImages/pyrevit.svg';
import viktor from './ControlRoomImages/viktor.png';

import '../App.css';

const Insights=(props) => {

    const history = {props}.props.history;    
    
    return ( 
               
     <div>
        {/*DEAS Functionality*/}
    <p><b>DEAS Functionality</b></p>
    <Grid sx={{flexWrap: "wrap",
                display: "flex",
                justifyContent: "left",
                gap: "50px",                
                width: "80vw"}} paddingBottom={'30px'}>
                    
    
      {/*  Grid DEASCore */}
      <Grid item xs={4} onClick={() => history.push('/Insights/DeasCore')} >
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
            <img src={deascore} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Core Functionality</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

        {/*  Grid Standards */}
        <Grid item xs={4} onClick={() => history.push('/Insights/DeasStandards')} >
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'60px'} height={'150px'} paddingTop={'40px'} >
            <img src={sd_orange} width='60%'  alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Standards</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

        {/*  Grid Workspaces */}
        <Grid item xs={4} onClick={() => history.push('/Insights/WorkSpaces')}>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
            <img src={workspaces} width={'64x'} height={'64px'} alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Workspaces</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

       {/*  Grid CustomTools */}
        <Grid item xs={4} onClick={() => history.push('/Insights/CustomTools') }>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                     
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'}>
            <img src={customtools} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Custom Tools</Grid>
            </Grid>            
        </Grid>
        </CardContent>
        </Card>      
        </Grid>
    </Grid>
      
    
    {/*  Software Metrics */}
    <p><b>Software Metrics</b></p>
    <Grid sx={{flexWrap: "wrap",
                display: "flex",
                justifyContent: "left",
                gap: "50px",                
                width: "80vw"}} paddingBottom={'30px'}>
        {/*  Grid Revit */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Revit')}>
                <Card
                sx={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid #f0f0f0",            
                    boxShadow: "none"                      
                }}
                >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <Grid style={{cursor:'pointer'}}>
                    <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
                    <img src={revit} width={'64px'} height={'64px'} alt=""/>
                    </Grid>
                    <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                            style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                        <Grid paddingLeft={'10px'}>Revit</Grid>
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
        </Grid>

        {/*  Grid Civil3D */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Civil3D')}>
            <Card
            sx={{
                width: "200px",
                height: "200px",
                border: "1px solid #f0f0f0",            
                boxShadow: "none"                      
            }}
            >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
            <img src={civil3d} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Civil3D</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>  
    </Grid>

    {/*  Other Tools */}
    <p><b>Other automations tools</b></p>
    <Grid sx={{flexWrap: "wrap",
                display: "flex",
                justifyContent: "left",
                gap: "50px",                
                width: "80vw" }} paddingBottom={'30px'}>

        {/*  Grid Viktor */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Viktor')}>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
            <img src={viktor} width={'64px'}  alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>Viktor</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid></Grid>        

    {/*  3rd party Plugins (Beta) */}
    <p><b>3rd party Plugins (Beta)</b></p>
    <Grid sx={{flexWrap: "wrap",
                display: "flex",
                justifyContent: "left",
                gap: "50px",                
                width: "80vw" }} paddingBottom={'30px'}>

        {/*  Grid UNIFI */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Unifi')}>
        <Card
        sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #f0f0f0",            
            boxShadow: "none"                      
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid style={{cursor:'pointer'}}>
            <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
            <img src={unifi} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                    style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                <Grid paddingLeft={'10px'}>UNIFI</Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>

        {/*  Grid Orkestra */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Orkestra')}>
                <Card
                sx={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid #f0f0f0",            
                    boxShadow: "none"                      
                }}
                >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <Grid style={{cursor:'pointer'}}>
                    <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
                    <img src={orkestra} width={'64px'} height={'64px'} alt=""/>
                    </Grid>
                    <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                            style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                        <Grid paddingLeft={'10px'}>Orkestra</Grid>
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
        </Grid>

        {/*  Grid DiRoots */}
        <Grid item xs={4} onClick={() => history.push('/Insights/DiRoots')}>
                <Card
                sx={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid #f0f0f0",            
                    boxShadow: "none"                      
                }}
                >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <Grid style={{cursor:'pointer'}}>
                    <Grid alignContent={'center'} paddingLeft={'60px'} height={'150px'} paddingTop={'55px'} >
                    <img src={diroots} width={'80px'} height={'40px'} alt=""/>
                    </Grid>
                    <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                            style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                        <Grid paddingLeft={'10px'}>DiRoots</Grid>
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
        </Grid>

        {/*  Grid MagiCAD */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Magicad')}>
                <Card
                sx={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid #f0f0f0",            
                    boxShadow: "none"                      
                }}
                >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <Grid style={{cursor:'pointer'}}>
                    <Grid alignContent={'center'} paddingLeft={'50px'} height={'150px'} paddingTop={'60px'} >
                    <img src={magicad} width={'100px'} height={'30px'} alt=""/>
                    </Grid>
                    <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                            style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                        <Grid paddingLeft={'10px'}>MagiCAD</Grid>
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
        </Grid>

        {/*  Grid Enscape */}
        <Grid item xs={4} onClick={() => history.push('/Insights/Enscape')}>
                <Card
                sx={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid #f0f0f0",            
                    boxShadow: "none"                      
                }}
                >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <Grid style={{cursor:'pointer'}}>
                    <Grid alignContent={'center'} paddingLeft={'60px'} height={'150px'} paddingTop={'40px'} >
                    <img src={enscape} width={'80px'} height={'80px'} alt=""/>
                    </Grid>
                    <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                            style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                        <Grid paddingLeft={'10px'}>Enscape</Grid>
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
        </Grid>

        {/*  Grid PyRevit */}
        <Grid item xs={4} onClick={() => history.push('/Insights/PyRevit')}>
                <Card
                sx={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid #f0f0f0",            
                    boxShadow: "none"                      
                }}
                >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                <Grid style={{cursor:'pointer'}}>
                    <Grid alignContent={'center'} paddingLeft={'70px'} height={'150px'} paddingTop={'40px'} >
                    <img src={pyrevit} width={'64px'} height={'64px'} alt=""/>
                    </Grid>
                    <Grid className="standard-text" bgcolor={"#f0f0f0"} height={'50px'}
                            style={{fontSize:'16px', fontWeight:'500',  textAlign:'left', paddingTop:'15px'}}>
                        <Grid paddingLeft={'10px'}>PyRevit</Grid>
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
        </Grid>

        
    </Grid>
    </div>      
    )
}

export default withRouter(Insights);