import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  Container,
  Icon,
  StyledTextField,
  Workspace,
  checkboxStyle,
  titleStyle,
  labelStyle,
  Application,
  Definition,
  LongDescription,
  Discipline,
  DisplayName,
  Owner,
  Region,
  Version,
  Save,
  ShortDescription,
  boxStyle,
} from './WorkspaceEditorStyles';
import BaseUrl from '../../Globals/Environment';
import { WorkspaceData } from './Workspaces';
import TokenReusable from '../../ReusableToken';
import '../../App.css';
import { AuthenticatedTemplate } from '@azure/msal-react';

function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}
//Checkbox items
const dictRegion = {
  Global: 'AGL',
  Australia: 'AAU',
  Belgium: 'ABE',
  Brazil: 'ABR',
  Canada: 'ACA',
  Chile: 'ACL',
  China: 'ACN',
  France: 'AFR',
  Germany: 'ADE',
  'Hong Kong': 'AHK',
  India: 'AIN',
  Ireland: 'AIE',
  Italy: 'AIT',
  Netherlands: 'ANL',
  Philippines: 'APH',
  Poland: 'APL',
  Portugal: 'APT',
  Romania: 'ARO',
  Spain: 'AES',
  'United Kingdom': 'AGB',
  'United States': 'AUS',
};

const dictDisciplines = {
  Architecture: 'ARCH',
  'BIM Management': 'BIM',
  'Building Services': 'BSV',
  'Civil Engineering': 'CVL',
  Rail: 'RL',
  'High Voltage': 'HV',
  'Process Engineering': 'PRE',
  Roads: 'RDS',
  'Urban Planning': 'UP',
  Structure: 'STR',
  'Mechanical Engineering': 'MEP',
  Bridges: 'BGS',
};

const regions = Object.keys(dictRegion);
const versions = [
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
];
const disciplines = Object.keys(dictDisciplines);
let accessToken = TokenReusable();
function isGuid(stringToTest) {
  const regexGuid =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  return regexGuid.test(stringToTest);
}

const WorkspaceEditor = () => {
  //Workspace variables
  const fileInputRef = React.useRef<any>();
  const [selectedImage, setSelectedImage] = useState<any>('');
  const [selectedBase64, setSelectedBase64] = useState<any>('');
  const [icon, setIcon] = useState('');

  const [checkedRegions, setCheckedRegions] = useState({});
  const [checkedDisciplines, setCheckedDisciplines] = useState({});
  const [checkedVersions, setCheckedVersions] = useState({});

  const [schema, setSchema] = useState('');
  const [selectApplication, setSelectApplication] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [name, setName] = useState('');
  const [toogleDialog, setToogleDialog] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [cancelApproval, setCancelApproval] = useState<boolean>(false);

  const [original, setOriginal] = useState<WorkspaceData>();
  //Save button variables
  const [buttonName, setButtonName] = useState('SAVE');
  const [buttonColor, setButtonColor] = useState('#e77229');

  //To get the id and name from URL
  const urlParams = new URLSearchParams(window.location.search);
  console.log("Location", window.location);
  const paramsId = urlParams.get('id');
  const paramsName = urlParams.get('name');

  const toggleOpenDialog = () => setToogleDialog((prev) => !prev);
  const toggleOpenUpdate = () => setUpdate((prev) => !prev);
  const toggleCancelApproval = () => setCancelApproval((prev) => !prev);

  //API calls
  const deleteWorkspace = async (id) => {
    try {
      let response, data;
      response = await fetch(BaseUrl() + '/api/v2/workspaces/' + id, {
        headers: { Authorization: 'Bearer ' + accessToken },
        method: 'DELETE',
        redirect: 'follow',
      });
      data = await response.json();
      return data;
    } catch (err: any) {
      console.error(err.message);
    }
  };
  const getWorkspace = async (id) => {
    try {
      let response, data;
      response = await fetch(BaseUrl() + '/api/v2/workspaces/' + id, {
        headers: { Authorization: 'Bearer ' + accessToken },
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      });

      data = await response.json();
      return data;
    } catch (err: any) {
      console.error(err.message);
    }
  };
  const updateWorkspace = async (keyValue, id) => {
    
    try {
        const response = await fetch(BaseUrl() + '/api/v2/workspaces/' + id, {
          headers: { Authorization: 'Bearer ' + accessToken },
          method: 'POST',
          body: keyValue,
          redirect: 'follow',
        });
        await response.json();

        if (response.ok) {
          setButtonColor('#4ad93b');
          setButtonName('Success!');
        }
      
    } catch (err: any) {
      console.log(err.message);
      if (err.message.includes('A works')) {
        toggleOpenDialog();
      }
      setButtonName('Failed');
      setButtonColor('#821d27');
      setTimeout(() => {
        setButtonColor('#e77229');
        setButtonName('SAVE');
      }, 2000);
    }
  };

  //event components
  const onButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        // the base64 string
        const base64String = reader.result;

        // Use base64 string to display the image
        if (typeof base64String === 'string') {
          setSelectedBase64(base64String);
          setSelectedImage(base64String);
          setIcon(base64String);
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const setWorkspaceData = (workspaceData) => {
    setOriginal(workspaceData);
    let name;
    if (isGuid(workspaceData.Name)) {
      name = paramsName ? paramsName.replace('%20', ' ') : 'New Workspace';
    } else {
      name = workspaceData.Name;
    }
    setName(name);
    setSelectApplication(workspaceData.Application);
    setOwnerName(workspaceData.UserEmail);
    setShortDescription(workspaceData.ShortDescription);
    setLongDescription(workspaceData.LongDescription);
    const schema = workspaceData.Schema
      ? workspaceData.Schema
      : "{'Name': 'New Workspace'}";
    setSchema(schema);

    if (workspaceData.Icon) {
      setIcon(workspaceData.Icon);
      setSelectedBase64(workspaceData.Icon);
    }
  };
  const getCheckedData = (data, dictionary = {}) => {
    const defaultCheckedData = data?.split(',').map((item) => item.trim());
    let checkedDataArray: any[] = [];

    for (let val of defaultCheckedData) {
      checkedDataArray.push(dictionary ? getKeyByValue(dictionary, val) : val);
    }

    return checkedDataArray.reduce(
      (acc, item) => ({ ...acc, [item]: true }),
      {}
    );
  };
  const setCheckboxValues = (workspaceData) => {
    if (workspaceData.Regions) {
      const defaultRegion = getCheckedData(workspaceData.Regions, dictRegion);
      setCheckedRegions(defaultRegion);
    }

    if (workspaceData.Disciplines) {
      const defaultDiscipline = getCheckedData(
        workspaceData.Disciplines,
        dictDisciplines
      );
      setCheckedDisciplines(defaultDiscipline);
    }

    if (workspaceData.Versions) {
      const defaultCheckedVersions = workspaceData.Versions.split(',').map(
        (version) => version.trim()
      );
      const defaultVersion = defaultCheckedVersions.reduce(
        (acc, version) => ({ ...acc, [version]: true }),
        {}
      );
      setCheckedVersions(defaultVersion);
    }
  };
  const fetchWorkspaceData = async () => {
    try {
      const workspaceData = await getWorkspace(paramsId);

      if (workspaceData) {
        setWorkspaceData(workspaceData);
        setCheckboxValues(workspaceData);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  //Set form values to workspace data
  useEffect(() => {
    fetchWorkspaceData();
  }, []);

  useEffect(() => {
    if (buttonColor === '#4ad93b') {
      setTimeout(() => {
        const newUrl = `${window.location.origin}/Workspaces`;
        window.location.href = newUrl;
      }, 1000);
    }
  }, [buttonColor]);

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleOwnerName = (event) => {
    setOwnerName(event.target.value);
  };
  const handleShort = (event) => {
    if (event.target.value.length <= 75) {
      setShortDescription(event.target.value);
    }
  };
  const handleLong = (event) => {
    if (event.target.value.length <= 150) {
      setLongDescription(event.target.value);
    }
  };
  const handleSchema = (event) => {
    setSchema(event.target.value);
  };
  const handleRegionSelect = (event) => {
    const { name, checked } = event.target;

    setCheckedRegions((prevCheckedRegions) => {
      const updatedRegions = { ...prevCheckedRegions };
      if (checked) {
        updatedRegions[name] = true;
      } else {
        delete updatedRegions[name];
      }
      return updatedRegions;
    });
  };
  const handleDisciplineSelect = (event) => {
    const { name, checked } = event.target;
    setCheckedDisciplines((prevCheckedDisciplines) => {
      const updatedDisciplines = { ...prevCheckedDisciplines };
      if (checked) {
        updatedDisciplines[name] = true;
      } else {
        delete updatedDisciplines[name];
      }
      return updatedDisciplines;
    });
  };
  const handleVersionSelect = (event) => {
    const { name, checked } = event.target;

    setCheckedVersions((prevCheckedVersions) => ({
      ...prevCheckedVersions,
      [name]: checked,
    }));
  };
  const handleApplication = (event) => {
    setSelectApplication(event.target.value);
  };
  const handleSave = async () => {
    let formattedRegionData = [] as any;
    for (let region of Object.keys(checkedRegions)) {
      const key = dictRegion[region];
      formattedRegionData.push(key);
    }
    let formattedRegions = formattedRegionData.join(',');
    if (formattedRegions.includes(',,')) {
      formattedRegions = formattedRegions.replace(',,', ','); //debugging empty data when the shorthand code for a country is updated
    }

    let formattedDisciplineData = [] as any;
    for (let discipline of Object.keys(checkedDisciplines)) {
      const key = dictDisciplines[discipline];
      formattedDisciplineData.push(key);
    }
    let formattedDisciplines = formattedDisciplineData.join(',');
    if (formattedDisciplines.includes(',,')) {
      formattedDisciplines = formattedDisciplines.replace(',,', ',');
    }
    if (formattedDisciplines.includes(',,')) {
      //debugging empty data when the shorthand code for a checkbox is updated
      formattedDisciplines.replace(',,', ',');
    }

    const selectedVersions = Object.keys(checkedVersions).filter(
      (version) => checkedVersions[version] && version.trim() !== ''
    );
    const formattedVersions = selectedVersions
      .filter((item) => item !== 'undefined')
      .join(',');

    //Last version of workspace data to be updated or post
    const data = {
      Icon: icon,
      Name: name,
      OwnerName: ownerName,
      ShortDescription: shortDescription,
      LongDescription: longDescription,
      Regions: formattedRegions,
      Disciplines: formattedDisciplines,
      Versions: formattedVersions,
      Application: selectApplication,
      Schema: schema,
      Active: true,
    };

    let newData = { ...data }; // a copy of the data for not deleting while iterating over keys

    if (original) {
      // To keep only updated fields
      for (const key in newData) {
        const value = newData[key];

        // If value matches the original value
        if (value === original[key] && key !== 'Icon') {
          delete newData[key];
        }
      }
    }
    newData.Active = true;

    let updatedRaw = JSON.stringify(newData);
    await updateWorkspace(updatedRaw, paramsId);
    toggleOpenUpdate();
  };

  const handleCancel = () => {
    if (paramsName === null || paramsName === undefined) {
      deleteWorkspace(paramsId);
      const newUrl = `${window.location.origin}/Workspaces`;
      window.location.href = newUrl;
    } else {
      const newUrl = `${window.location.origin}/Workspaces`;
      window.location.href = newUrl;
    }
  };

  return (
    <div>
      <AuthenticatedTemplate>
        <Container>
          <Workspace>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      marginLeft={2}
                      sx={titleStyle}
                    >
                      Workspace Builder
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Workspace>
          <DisplayName>
            <InputLabel sx={{ ...labelStyle }}> Display Name</InputLabel>
            <StyledTextField
              onChange={handleName}
              labelheight='22px'
              data-area='name'
              value={name}
            />
          </DisplayName>
          <Owner>
            <InputLabel sx={{ ...labelStyle }}> Owner Name</InputLabel>
            <StyledTextField
              onChange={handleOwnerName}
              labelheight='22px'
              data-area='owner'
              value={ownerName}
              InputProps={{
                readOnly: true,
                style: { backgroundColor: '#f0f0f0' },
              }}
            />
          </Owner>
          <Icon>
            <InputLabel sx={{ ...labelStyle }}>Icon</InputLabel>
            <Box sx={{ ...boxStyle, justifyContent: 'space-between' }}>
              <div
                onClick={onButtonClick}
                onKeyUp={onButtonClick}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}
                >
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={onFileChange}
                  />
                  {(() => {
                    if (selectedImage) {
                      return (
                        <img
                          style={{
                            objectFit: 'fill',
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                          key={selectedImage}
                          src={selectedImage}
                          alt='Selected'
                        />
                      );
                    } else if (selectedBase64) {
                      return (
                        <img
                          style={{
                            objectFit: 'fill',
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                          key={selectedBase64}
                          src={selectedBase64}
                          alt='Icon'
                        />
                      );
                    } else {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#f1f1f1',
                            color: '#999',
                            borderRadius: '5px',
                          }}
                        >
                          Click to add icon...
                        </div>
                      );
                    }
                  })()}
                </div>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={onFileChange}
                />
              </div>
            </Box>
          </Icon>
          <ShortDescription>
            <InputLabel sx={{ ...labelStyle }}>Short Description</InputLabel>
            <StyledTextField
              onChange={handleShort}
              minRows={2}
              sx={{ overflowY: 'auto' }}
              labelheight='22px'
              data-area='shortDescription'
              multiline
              value={shortDescription}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {shortDescription.length}/75
                  </InputAdornment>
                ),
              }}
            />
          </ShortDescription>
          <LongDescription>
            <InputLabel sx={{ ...labelStyle }}>Long Description</InputLabel>
            <StyledTextField
              onChange={handleLong}
              minRows={2}
              multiline
              sx={{ overflowY: 'auto' }}
              labelheight='22px'
              data-area='longDescription'
              value={longDescription}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {longDescription.length}/150
                  </InputAdornment>
                ),
              }}
            />
          </LongDescription>
          <Region>
            <InputLabel sx={{ ...labelStyle }}>Region</InputLabel>
            <Box sx={{ ...boxStyle, overflow: 'auto' }}>
              {regions?.map((region) => (
                <FormControlLabel
                  label={region}
                  sx={{
                    ...checkboxStyle,
                    marginLeft: '1px',
                    my: '-4px',
                  }}
                  key={region}
                  control={
                    <Checkbox
                      size='small'
                      checked={checkedRegions[region] || false}
                      onChange={handleRegionSelect}
                      name={region}
                      style={{ color: '#e4610f' }}
                    />
                  }
                />
              ))}
            </Box>
          </Region>
          <Discipline>
            <InputLabel sx={labelStyle}>Discipline</InputLabel>
            <Box sx={{ ...boxStyle, overflow: 'auto' }}>
              {disciplines?.map((disciplines) => (
                <FormControlLabel
                  label={disciplines}
                  sx={{
                    ...checkboxStyle,
                    marginLeft: '1px',
                    my: '-4px',
                  }}
                  key={disciplines}
                  control={
                    <Checkbox
                      size='small'
                      checked={checkedDisciplines[disciplines] || false}
                      onChange={handleDisciplineSelect}
                      name={disciplines}
                      style={{ color: '#e4610f' }}
                    />
                  }
                />
              ))}
            </Box>
          </Discipline>
          <Application>
            <InputLabel sx={{ ...labelStyle }}>Application</InputLabel>
            <Box
              border={1}
              borderColor='grey.400'
              borderRadius={1}
              display='flex'
              flexDirection='column'
            >
              <Select
                value={selectApplication}
                onChange={handleApplication}
              >
                <MenuItem
                  style={{ marginLeft: '10px' }}
                  value={'Revit'}
                >
                  Revit
                </MenuItem>
                <MenuItem
                  style={{ marginLeft: '10px' }}
                  value={'Civil 3D'}
                >
                  Civil 3D
                </MenuItem>
              </Select>
            </Box>
          </Application>
          <Version>
            <InputLabel sx={{ ...labelStyle }}>Version</InputLabel>

            <Box sx={{ ...boxStyle, overflow: 'auto' }}>
              {versions?.map((versions) => (
                <FormControlLabel
                  label={versions}
                  sx={{
                    ...checkboxStyle,
                    marginLeft: '1px',
                    my: '-4px',
                  }}
                  key={versions}
                  control={
                    <Checkbox
                      size='small'
                      checked={checkedVersions[versions] || false}
                      onChange={handleVersionSelect}
                      name={versions}
                      style={{ color: '#e4610f' }}
                    />
                  }
                />
              ))}
            </Box>
          </Version>
          <Definition>
            <InputLabel sx={{ ...labelStyle }}>Definition</InputLabel>
            <StyledTextField
              onChange={handleSchema}
              sx={{ overflowY: 'auto' }}
              multiline
              labelheight='22px'
              data-area='schema'
              value={schema}
            />
          </Definition>
          <Save style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={toggleCancelApproval}
              className='secondary-button'
              sx={{
                marginRight: '20px !important',
                marginBottom: '20px'
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={toggleOpenUpdate}
              className='primary-button'
              sx={{
                marginRight: '20px !important',
              }}
            >
              {buttonName}
            </Button>
          </Save>
        </Container>
      </AuthenticatedTemplate>
      <Dialog open={toogleDialog}>
        <DialogTitle
          className='dialog-title'
          id='customized-dialog-title'
        >
          <Typography
            className='standard-text'
            color={'#ffffff'}
          >
            WORKSPACE EDITOR
          </Typography>
        </DialogTitle>
        <DialogContent className='dialog-popup'>
          <Typography className='standard-text'>
            Oops! A workspace with the same name already exists in our database.
            Please modify the name and try again.
          </Typography>
        </DialogContent>

        <DialogActions className='dialog-actions'>
          <Button
            onClick={toggleOpenDialog}
            className='save-button standard-text'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={update}>
        <DialogTitle
          className='dialog-title'
          id='customized-dialog-title'
        >
          <Typography
            className='standard-text'
            color={'#ffffff'}
          >
            WORKSPACE EDITOR
          </Typography>
        </DialogTitle>
        <DialogContent className='dialog-popup'>
          <Typography className='standard-text'>
            Are you sure you want to save your changes for the workspace: {name}?
          </Typography>
        </DialogContent>

        <DialogActions className='dialog-actions'>
          <Button
            onClick={toggleOpenUpdate}
            className='save-button standard-text'
          >
            CANCEL
          </Button>
          <Button
            onClick={handleSave}
            className='save-button standard-text'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cancelApproval}>
        <DialogTitle
          className='dialog-title'
          id='customized-dialog-title'
        >
          <Typography
            className='standard-text'
            color={'#ffffff'}
          >
            WORKSPACE EDITOR
          </Typography>
        </DialogTitle>
        <DialogContent  className='dialog-popup'>
          <Typography className='standard-text'>
            Executing this action will permanently erase the workspace. Do you wish to proceed?
          </Typography>
        </DialogContent>

        <DialogActions className='dialog-actions'>
          <Button
            onClick={toggleCancelApproval}
            className='save-button standard-text'
          >
            CANCEL
          </Button>
          <Button
            onClick={handleCancel}
            className='save-button standard-text'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkspaceEditor;
