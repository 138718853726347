/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@mui/material/Tooltip";
import BaseUrl from "../../Globals/Environment";
import {useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Menu, MenuItem, Table, TableBody } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PreviewIcon from '@material-ui/icons/Visibility';
import Person from '@material-ui/icons/Person';
import AccessTime from '@material-ui/icons/AccessTime';
import ThreeDots from '@material-ui/icons/MoreVert';
import { useMsal } from "@azure/msal-react";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import uuid from "react-uuid";

const borderStyles = {
  padding: "4px 15px",
  borderRadius: "50px",
  display: "inline-block", // This makes the div inline
  border: "1px solid",
};

export default function WorkspacesContent(props) {
  let i = 1; //for the duplication of workspace
  const keyValue = { Active: false }
  const { row } = props;
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance, inProgress, accounts } = useMsal();
  const [toogleDialog, setToogleDialog] = useState<boolean>(false);

  const toggleOpenDialog = () => setToogleDialog((prev) => !prev);

  const postWorkspaces = async (updatedRaw) => {
    try {
      const accessTokenRequest = {
        scopes: [
          "api://ccc0414d-73c7-44d1-a3f6-947e591fed42/deas_access_to_api",
        ],
        account: accounts[0],
      };
      if (inProgress === InteractionStatus.None) {
        const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
        let accessToken = accessTokenResponse.accessToken;
        const response = await fetch(BaseUrl() + "/api/v2/workspaces/", {
          headers: { Authorization: "Bearer " + accessToken },
          method: "POST",
          body: updatedRaw,
          redirect: "follow",
        });

        if (!response.ok) {
          const error = await response.text();
          throw new Error(error);
        }

        const data = await response.json();
        return data;
      }
    } catch (err: any) {
      console.error("Post", err.message);
    }
  };
  const handleDelete = async (keyValue, id) => {
    try {
      const accessTokenRequest = {
        scopes: ["api://ccc0414d-73c7-44d1-a3f6-947e591fed42/deas_access_to_api"],
        account: accounts[0],
      };
      let response;
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      let accessToken = accessTokenResponse.accessToken;

      if (accessToken && inProgress === InteractionStatus.None) {

       
          response = await fetch(
            BaseUrl() + "/api/v2/workspaces/" + id,
            {
              headers: { Authorization: "Bearer " + accessToken },
              method: "POST",
              body: JSON.stringify(keyValue),
              redirect: "follow",
            }
          );

          await response.json();
          props.onWorkspaceDelete(id);
      
      }
    } catch (err: any) {
      alert(err.message);
    }

  };

  const handleEdit = () => {
    const newUrl = `${window.location.origin}/workspace?id=${row.id}&name=${row.name}`;
    window.location.href = newUrl;

  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicate = async () => {

    const data = {
      name: uuid(),
      schema: row.schema,
      versions: row.versions,
      disciplines: row.disciplines,
      regions: row.regions,
      addins: row.addins,
      shortDescription: row.shortDescription,
      longDescription: row.longDescription,
      icon: row.icon,
      application: row.application
    }
    const updatedRaw = JSON.stringify(data);
    const duplicateData = await postWorkspaces(updatedRaw);
    if(duplicateData)
    {
      props.onWorkspaceDuplicate(duplicateData);
      const newUrl = `${window.location.origin}/workspace?id=${duplicateData.id}&name=${row.name+ " Duplicate"}`;
      window.location.href = newUrl;
    }
  }
  
  return (
    <Table size='small'>
      <TableBody>
        <TableRow>
          <TableCell
            rowSpan={4}
            align='center'
            sx={{ width: '10%', maxWidth: '150px' }}
          >
            {row.icon ? (
              <img
                src={row.icon}
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                  objectFit: 'contain',
                }}
              />
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell sx={{ border: 'none' }}>
            <Typography variant='h6'>{row.name}</Typography>
          </TableCell>
          <TableCell
            rowSpan={1}
            align='right'
            sx={{ border: 'none' }}
          >
            <Button
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: '#FFFFFF',
                alignItems: 'center',
              }}
              onClick={handleClick}
            >
              <ThreeDots
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{ color: hover ? '#e77229' : '#5ca3fc' }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                style={{ width: '150px' }}
                onClick={handleEdit}
              >
                <EditIcon />
                <span
                  className='standard-text'
                  style={{ marginLeft: '8px' }}
                >
                  Edit
                </span>
              </MenuItem>
              <MenuItem onClick={handleDuplicate}>
                <FileCopyIcon />
                <span
                  className='standard-text'
                  style={{ marginLeft: '8px' }}
                >
                  Duplicate
                </span>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <PreviewIcon />
                <span
                  className='standard-text'
                  style={{ marginLeft: '8px' }}
                >
                  Preview
                </span>
              </MenuItem>
              <Divider />
              <MenuItem onClick={toggleOpenDialog}>
                <DeleteIcon />
                <span
                  className='standard-text'
                  style={{ marginLeft: '8px' }}
                >
                  Delete
                </span>
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ border: 'none' }}>
            <Typography
              style={{ minHeight: '40px' }}
              variant='body2'
            >
              {row.longDescription}{' '}
            </Typography>
          </TableCell>
          <TableCell
            sx={{ border: 'none' }}
            align='right'
          >
            <div style={{ marginRight: '20px' }}>
              <span
                className='standard-text'
                style={{ verticalAlign: '7px' }}
              >
                {row.versions ? row.versions : '-'}&nbsp;&nbsp;
              </span>
              <Tooltip title='Versions'>
                <PublishedWithChangesIcon
                  style={{ color: '#e77229' }}
                ></PublishedWithChangesIcon>
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ border: 'none' }}>
            <Typography className='standard-text'>
              {row.regions
                ? row.regions.split(',').map((item) => (
                    <div
                      key={item}
                      style={{
                        ...borderStyles,
                        color: '#ffffff',
                        backgroundColor: '#e77229',
                      }}
                    >
                      {item}
                    </div>
                  ))
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{ width: '30%', border: 'none' }}
            align='right'
          >
            <div style={{ marginRight: '20px' }}>
              <span
                className='standard-text'
                style={{ marginRight: '8px', verticalAlign: '7px' }}
              >
                {row.userEmail ? row.userEmail : '-'}
              </span>
              <Tooltip title='Owner'>
                <Person style={{ color: '#e77229' }}></Person>
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography className='standard-text'>
              {row.disciplines
                ? row.disciplines.split(',').map((item) => (
                    <div
                      key={item}
                      style={{
                        ...borderStyles,
                        color: '#ffffff',
                        backgroundColor: '#5ca3fc',
                      }}
                    >
                      {item}
                    </div>
                  ))
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            style={{ marginRight: '10px' }}
            align='right'
          >
            <div style={{ marginRight: '20px' }}>
              <span
                className='standard-text'
                style={{ verticalAlign: '7px', marginRight: '8px' }}
              >
                {row.lastModified
                  ? new Date(row.lastModified).toLocaleString(undefined, {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })
                  : '-'}
              </span>
              <Tooltip title='Last Update'>
                <AccessTime style={{ color: '#e77229' }}></AccessTime>
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
      <Dialog open={toogleDialog}>
        <DialogTitle
          className='dialog-title'
          id='customized-dialog-title'
        >
          <Typography
            className='standard-text'
            color={'#ffffff'}
          >
            WORKSPACE EDITOR
          </Typography>
        </DialogTitle>
        <DialogContent className='dialog-popup'>
          <Typography className='standard-text'>
            Are you sure you want to delete the workspace: {row.name}
          </Typography>
        </DialogContent>
        <DialogActions className='dialog-actions'>
          <Button
            onClick={toggleOpenDialog}
            className='save-button standard-text'
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleDelete(keyValue, row.id);
            }}
            className='save-button standard-text'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Table>
  );
};
