import ContactCard from "./ContactCard";
import Item from "@mui/material/Grid";

export default function Contacts(props: any) {
  return (
    <>
      {props.contacts.map((element) => {
        return (
          <Item
            key={element.index}
            sx={{ width: "400px", marginLeft: "30px", marginRight: "30px" }}
          >
            <ContactCard
              radioState={props.radioState}
              contact={element}
            ></ContactCard>
          </Item>
        );
      })}
    </>
  );
}

