// @ts-nocheck
//import { Rectangle, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import data from "./countries";
import { Rectangle, Marker, Popup, MapContainer, GeoJSON } from "react-leaflet";
import React, { useState, useEffect } from "react";

import pins from "./pins.js";

const marked = [
  "Australia",
  "United Kingdom",
  "Netherlands",
  "Romania",
  "United States of America",
  "Philippines",
  "Belgium",
  "Germany",
  "France",
  "India",
];

const Markers = (props: any) => {
  const generateMarkers = (country) => {
    return pins
      .filter((element) => element.Country == country)
      .map((element) => {
        return (
          <Marker
            key={element.index}
            position={[element.Lat, element.Long]}
            icon={streamingIcon}
          >
            {" "}
            <Popup>
              <b>{element.Country} Office:</b> {element.City}
            </Popup>
          </Marker>
        );
      });
  };

  const LeafIcon = L.Icon.extend({
    options: {
      iconSize: [18.3 * 2, 24 * 2],
      shadowSize: [18.3 * 2, 24 * 2],
      iconAnchor: [18.3, 24 * 2],
    },
  });

  const streamingIcon = new LeafIcon({
    // eslint-disable-next-line no-undef
    iconUrl: require("./marker.png"),
  });

  if (props.country) {
    return generateMarkers(props.country);
  } else {
    return null;
  }
};

/* eslint-disable react/prop-types */
export default function MyMap(props) {
  const [map, setMap] = useState<any>(null);

  const geoJSONRef = React.useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryBounds1, setSelectedCountryBounds1] =
    useState<number>(0);
  const [selectedCountryBounds2, setSelectedCountryBounds2] =
    useState<number>(0);
  const [selectedCountryBounds3, setSelectedCountryBounds3] =
    useState<number>(0);
  const [selectedCountryBounds4, setSelectedCountryBounds4] =
    useState<number>(0);

  const [allBounds, setAllBounds]=useState<boolean>(false); 

  useEffect(() => {
    if (props.countryPass) {
      // @ts-ignore: Object is possibly 'null'.
      const layer = geoJSONRef.current
        .getLayers()
        .find((layer) => layer.feature.properties.name === props.countryPass);
      setSelectedCountryBounds1(layer.getBounds()._northEast.lat);
      setSelectedCountryBounds2(layer.getBounds()._northEast.lng);
      setSelectedCountryBounds3(layer.getBounds()._southWest.lat);
      setSelectedCountryBounds4(layer.getBounds()._southWest.lng);
      if ((selectedCountryBounds1) &&
          (selectedCountryBounds2) &&
          (selectedCountryBounds3) &&
          (selectedCountryBounds4)) {
          setAllBounds(true); }
      map.flyToBounds(layer.getBounds(), { duration: 0.8 });
    } else {
      
      setSelectedCountryBounds1(0);

      if (map) {
        setTimeout(function () {
          map.setView([45, 0], 2);
        }, 200);
      }
    }
  }, [props.countryPass]);

  function MyComponent() {
    
    map.flyToBounds(
      [
        [selectedCountryBounds1, selectedCountryBounds2],
        [selectedCountryBounds3, selectedCountryBounds4],
      ],
      { duration: 0.8 }
    );

    return null;
  }

  const zoomToFeature = (e) => {
    if (marked.includes(e.target.feature.properties.name)) {
      setSelectedCountry(e.target.feature.properties.name);

      props.setCountryPass(e.target.feature.properties.name);

      setSelectedCountryBounds1(e.target._bounds._northEast.lat);
      setSelectedCountryBounds2(e.target._bounds._northEast.lng);
      setSelectedCountryBounds3(e.target._bounds._southWest.lat);
      setSelectedCountryBounds4(e.target._bounds._southWest.lng);
    }
  };

  const highlightFeature = (e) => {
    if (marked.includes(e.target.feature.properties.name)) {
      const layer = e.target;

      if (e.target._map._zoom == 2) {
        layer.setStyle({
          weight: 1,
          color: "#DB6822",
          opacity: 0.7,
          fillOpacity: 0.7,
          fillColor: "#DB6822",
        });
      }
    }
  };

  const resetHighlight = (e) => {
    if (e.target._map._zoom == 2) {
      e.target.setStyle(style(e.target.feature));
    }
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature,
    });
  };

  const mapPolygonColor = (name) => {
    if (marked.includes(name)) {
      if (props.countryPass) {
        if (props.countryPass == name) {
          return "rgb(228, 97, 15)";
        } else {
          return "#E49464";
        }
      } else {
        return "rgb(228, 97, 15)";
      }
    } else {
      return "#A7B3C2";
    }
  };

  const style = (feature) => {
    return {
      fillColor: mapPolygonColor(feature.properties.name),
      weight: 0.4,
      opacity: 1,
      color: "white",
      fillOpacity: 1,
    };
  };

  const feature = data.features.map((feature) => {
    return feature;
  });
  const blackOptions = { color: "#F9FAFF", fillOpacity: 1 };

    
  return (
    <>
      <div style={{ display: "none" }}>{selectedCountry}</div>

      <MapContainer
        center={{ lat: 45, lng: 0 }}
        zoom={2}
        style={{ height: "75vh", width: "100%" }}
        scrollWheelZoom={false}
        zoomControl={false}
        doubleClickZoom={false}
        dragging={false}
        ref={setMap}
      >
   
        {allBounds && <MyComponent/>}
       
        <Rectangle
          bounds={[
            [-90, 500],
            [90, -500],
          ]}
          pathOptions={blackOptions}
        />
        <Markers country={props.countryPass}></Markers>

        {feature && (
          <GeoJSON
            // @ts-expect-error
            data={feature}
            style={style}
            ref={geoJSONRef}
            onEachFeature={onEachFeature}
          ></GeoJSON>
        )}
      </MapContainer>
    </>
  );
}
