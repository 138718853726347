/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import React, { useState, useRef } from 'react';
import addIcon from '../../assets/ToolCatalog/add.svg';
import deleteIcon from '../../assets/ToolCatalog/delete.svg';
import ReactPlayer from "react-player";

export default function MediaContent(
  { content, handleMediaAdd, handleMediaDelete, showDeleteIcon, addMedia }
) {
  const [isHovering, setIsHovering] = useState(false); // Hover state
  const fileInputRef = useRef<any>();

  const onButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const renderMedia = () => {
    if (!content.media) {
      return (
        <img
          src={addIcon}
          alt='add-icon'
        />
      );
    } else {
      return content.mediaType === 'video' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <ReactPlayer
            controls={true}
            width="100%" height="100%"
            url={content.media}
          />
          {isHovering && showDeleteIcon && (
            <img
              src={deleteIcon}
              style={{
                color: '#e4610f',
                position: 'absolute', // Absolutely position the DeleteIcon inside the Box
                borderRadius: '50%', // Optional: if you want a circular background
                backgroundColor: 'white',
                borderColor: '#e4610f',
                padding: '4px',
                top: '40%',
                right: '40%',
                cursor: 'pointer',
              }}
              onClick={() => handleMediaDelete(content)}
              onKeyDown={() => handleMediaDelete(content)}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            src={content.media}
            alt='Selected'
          />
          {isHovering && showDeleteIcon && (
            <img
              src={deleteIcon}
              style={{
                color: '#e4610f',
                position: 'absolute', // Absolutely position the DeleteIcon inside the Box
                borderRadius: '50%', // Optional: if you want a circular background
                backgroundColor: 'white',
                borderColor: '#e4610f',
                padding: '4px',
                top: '40%',
                right: '40%',
                cursor: 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation(); // Stop event from bubbling up
                handleMediaDelete(content);
              }}
              onKeyDown={() => handleMediaDelete(content)}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Button
        fullWidth
        sx={{ height: '100%' }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={addMedia ? onButtonClick: undefined}
      >
        {renderMedia()}
      </Button>

      <input
        type='file'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleMediaAdd}
        accept='image/*,video/*'
      />
    </div>
  );
}
