import MyMap from "./SkillsContacts/Map";
import Filters from "./SkillsContacts/Filters";
import "leaflet/dist/leaflet.css";
import {Grid} from "@mui/material";
import { useEffect, useState } from "react";
import Item from "@mui/material/Grid";
import ContactsComponent from "./SkillsContacts/ContactsComponent";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import BaseUrl from "../Globals/Environment";


export interface UsersData {    
  Email: string,
  Country: string,
  GBA: string,
  Asset: string, 
  Role: string,
  UserStatus: string,
  RevitAPI: string,
  Dynamo: string,
  Grasshopper: string,
  csharp: string,
  cplusplus: string,
  Javascript: string,
  Revit: string,
  Civil3D: string,
  Rhinoceros: string,
  BentleyOpenSuite: string    
};

export default function Connect() {   
 
  //get all user properties
    const { instance, accounts } = useMsal();
    const contacts: UsersData[] = [];     
    const [myresult, setMyresult]=useState<UsersData[]>([]);    
  
    useEffect(() => {    
      const accessTokenRequest = {
        scopes: ["api://ccc0414d-73c7-44d1-a3f6-947e591fed42/deas_access_to_api"],
        account: accounts[0],
      };
  
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
  
          const myHeaders = new Headers();
          myHeaders.append("Authorization", "Bearer " + accessToken);
          myHeaders.append("Content-Type", "application/json");
  
          const raw = '{"Proficiencies":"true"}';
  
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
          };
  
          fetch(
            BaseUrl() +"/api/v2/getusers",            
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {               
              // eslint-disable-next-line react/prop-types
              setMyresult(result);                          
            })
            .catch((error) => console.log("error on users", error));
        });
    }, []);
  
  for (const element of myresult) {       
   
    const profi = JSON.parse(element["proficiencies"].substring(0,element["proficiencies"].length-2)+"}");
    const country=(JSON.parse(element["oracleData"]))["Country"]=='India - GD'?"India":(JSON.parse(element["oracleData"]))["Country"];
    
    const people: UsersData = { 
        Email: element["email"],
        Country: country,
        GBA: (JSON.parse(element["oracleData"]))["GlobalBusinessArea"],
        Asset: "-",
        Role: element["role"],
        UserStatus: element["userStatus"],
        RevitAPI: profi["RevitAPI"],
        Dynamo: profi["Dynamo"],
        Grasshopper: profi["Grasshopper"],
        csharp: profi["csharp"],
        cplusplus: profi["cplusplus"],
        Javascript: profi["Javascript"],
        Revit: profi["Revit"],
        Civil3D: profi["Civil3D"],
        Rhinoceros: profi["Rhinoceros"],
        BentleyOpenSuite: profi["BentleyOpenSuite"]             
    };  
    contacts.push(people);   
  } 

  const [countryState, setCountryState] = useState("");
  const [assetState, setAssetState] = useState("");
  const [gbaState, setGBAState] = useState("");
  const [skillsState, setSkillsState] = useState("");
  const [searchState, setSearchState] = useState("");
  const [sortByState, setSortByState] = useState("");
  const [radioState, setRadioState] = useState("mapView");
  const [contactsState, setcontactsState] = useState<any>(contacts);

  // eslint-disable-next-line no-unused-vars
  function bool_getDynamo(item) {
    if (parseInt(item.Dynamo!) > 1 && skillsState.includes("Dynamo"))
    return true;
  }
  function bool_getCivil3D(item) {
    if (parseInt(item.Civil3D!) > 1 && skillsState.includes("Civil3D"))
    return true;
  }
  function bool_getRevitAPI(item) {
    if (parseInt(item.RevitAPI!) > 1 && skillsState.includes("Revit API"))
    return true;
  }
  function bool_getGrasshopper(item) {
    if (parseInt(item.Grasshopper!) > 1 && skillsState.includes("Grasshopper"))
    return true;
  }
  function bool_getcsharp(item) {
    if (parseInt(item.csharp!) > 1 && skillsState.includes("C#"))
    return true;
  }
  function bool_getcplusplus(item) {
    if (parseInt(item.cplusplus!) > 1 && skillsState.includes("C++"))
    return true;
  }
  function bool_getjavascript(item) {
    if (parseInt(item.Javascript!) > 1 && skillsState.includes("Javascript"))
    return true;
  }
  function bool_getRevit(item) {
    if (parseInt(item.Revit!) > 1 && skillsState.includes("Revit"))
    return true;
  }
  function bool_getRhinoceros(item) {
    if (parseInt(item.Rhinoceros!) > 1 && skillsState.includes("Rhinoceros"))
    return true;
  }
  function bool_getBentley(item) {
    if (parseInt(item.BentleyOpenSuite!) > 1 && skillsState.includes("Bentley OpenSuite"))
    return true;
  }  
  
   //new filter
  useEffect(() => {
    
    setcontactsState(
      contacts.filter(function (item) {
        // prettier-ignore       
        if (
          //name search
          (!searchState || item.Email.toUpperCase().includes(searchState.toUpperCase())) && 
          //country selected
          (item.Country === countryState || !countryState) &&
          //asset selected or not
           (assetState.includes(item.Asset) || assetState.length == 0) &&
          //gba selected or not
          (gbaState.includes(item.GBA) || gbaState.length == 0) &&

          //skills
          (
            //nothing selected
            (skillsState.length == 0) ||
            
            //something selected
            (
              //Dynamo Selected
              bool_getDynamo(item) ||

              //Civil3d Selected
              bool_getCivil3D(item) ||

              //Revit API Selected
              bool_getRevitAPI(item) ||

              //Grasshopper Selected
              bool_getGrasshopper(item) ||              

              //C# Selected
              bool_getcsharp(item) ||  

              //C++ Selected
              bool_getcplusplus(item) ||  

              //Javascript Selected
              bool_getjavascript(item) || 

              //Revit Selected
              bool_getRevit(item) || 

              //Rhinoceros Selected
              bool_getRhinoceros(item) || 

              //Bentley OpenSuite Selected
              bool_getBentley(item)
            )
          )
          ) {
          return item;
        }
      })      
    );    
  }, [countryState, assetState, gbaState, skillsState, searchState]);
  
  //sort by
  useEffect(() => {
    const key = sortByState[0];

    const sortByData = [...contactsState];

    sortByData.sort(function (a, b) {
      if (a[key] !== undefined && b[key] !== undefined) {
        return a[key] > b[key] ? -1 : 1;
      } else {
        return a[key] !== undefined ? -1 : 1;
      }
    });
    setcontactsState(sortByData);
  }, [sortByState]); 
  
  return (
    <AuthenticatedTemplate>
    <div>
      <Grid container justifyContent="flex-end">
        <Filters
          setSkills={setSkillsState}
          skills={skillsState}
          setGBA={setGBAState}
          GBA={gbaState}
          setAsset={setAssetState}
          asset={assetState}
          countryPass={countryState}
          setCountryPass={setCountryState}
          searchPass={searchState}
          setSearchPass={setSearchState}
          sortByPass={sortByState}
          setSortByPass={setSortByState}
          setRadioPass={setRadioState}
        />
      </Grid>

      {radioState == "mapView" && (
        <Grid container spacing={2}>
          <Grid item lg={8.5} xs={12} order={{ lg: 1, xs: 2 }}>
            <Item>
              <MyMap
                setSkills={setSkillsState}
                skills={skillsState}
                setGBA={setGBAState}
                GBA={gbaState}
                setAsset={setAssetState}
                asset={assetState}
                setCountryPass={setCountryState}
                countryPass={countryState}
                contactsOnCards={contactsState}
              />
            </Item>
          </Grid>

          <Grid item lg={3} xs={12} order={{ lg: 2, xs: 1 }}>
            <ContactsComponent contacts={contactsState.length==0?contacts:contactsState}></ContactsComponent>
          </Grid>
        </Grid>
      )}

      {radioState == "listView" && (
        <Grid container flexDirection="row" spacing={2}>
          <Grid
            item
            display="flex"
            justifyContent="space-around"
            sx={{ flexWrap: "wrap" }}
          >
            <ContactsComponent
              radioState={radioState}
              contacts={contactsState.length==0?contacts:contactsState}
            ></ContactsComponent>
          </Grid>
        </Grid>
      )}
    </div>
    </AuthenticatedTemplate>
  );
}
