/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import BaseUrl from "./Globals/Environment";
import { useParams} from "react-router-dom";
import "./QStyle.css";

export default function Message() { 

  const { id }: any = useParams();  
  const [message, setMessage] = useState<any>(""); 
  
  //get message properties
  useEffect(() => { 
    
     const cookieValue = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)Token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    ); 
    const myHeaders = new Headers(); 
    myHeaders.append("Authorization", "Bearer " + cookieValue);
    myHeaders.append("Content-Type", "application/json");  
     
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      BaseUrl()+'/api/v2/messages/'+id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {  
               
        setMessage(result);                
      })
      .catch((error) => console.log("error:", error));  

  }, [id]); 
  
  function ParseMessageDate()
  {            
    let endD = Date.parse(message.endDate)
    return "Expires: " + new Intl.DateTimeFormat('en', { year: 'numeric', month:"short", day:"2-digit" }).format(endD);
  }  
  
  return (    
    <> 
      {message &&  
      (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ borderRadius: "50%" , marginLeft: "10px"}}
              width="50px"
              height="50px"
              id="avatar-image"              
              src= {message.image}
            ></img>
            <div style={{ display: "flex", flexDirection:"column"}}>
              <h4 style={{ marginLeft: "10px", marginBottom:"-9px"}}>{message.author.split(" ")[1]+" "+ message.author.split(" ")[0]}</h4>
              <p className="standard-text" style={{ marginLeft: "10px", marginTop:"7px" }}>{ParseMessageDate()}</p>
            </div > 
          </div>

          <h3 style={{ margin: "10px", color:"#E4601F"}}
            dangerouslySetInnerHTML={{              
              __html: JSON.parse(message.content).title,
            }}
          />
          <div style={{ margin: "10px"}}
            dangerouslySetInnerHTML={{
              __html: JSON.parse(message.content).content,
            }}
          />
        </>
      )
     }     
    </>        
  );
}
