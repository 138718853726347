/* eslint-disable react/prop-types */
// @ts-ignore: Object is possibly 'null'.

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import "../../App.css";
import BaseUrl from "../../Globals/Environment";
import TokenReusable from "../../ReusableToken";
import { getCurrentDimension } from "../../GetWindowSize";
import ThreeDots from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, Switch} from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles({
  menuItem: {
    "&:hover": {
      background: "#F5F5F5",
      cursor: "pointer",
    },
  },  
});

export default function MessageCards (props:any) {

  
  const classes = useStyles();
  const [messages, setMessages] = useState<
    {
      id: number;
      author: string;
      content: string;
      startDate: string;
      endDate: string;
      countryCode: string;
      redirectUrl: string;
      image: string | null;
    }[]    
  >([]);
  
  
//getwindows dimensions left Menu
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  useEffect(() => {      
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension); 
     return(() => {
        window.removeEventListener('resize' , updateDimension)          
    })     
    
}, [screenSize]);
const offset=150;
const dHeight=screenSize.height-offset+"px";
  
  function setCookie(c_name, value, exdays) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    const c_value =
      encodeURI(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
  } 
  
  function deleteMessage(itemId) { 
   
        const myToken=TokenReusable();
        setCookie("Token", myToken, 10);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + myToken);

        const requestOptions = {
          method: "delete",
          headers: myHeaders,
        };

        fetch(
          BaseUrl()+`/api/v2/messages/${itemId}`,
          requestOptions
        )
          .then((response) => {
          if(response.status === 204)
          {
            let newMessages = messages.filter(function(item){
              return item.redirectUrl !== itemId;
            })
            setMessages(newMessages);            
          }
          })
          .catch((error) => {
            console.log("error:", error);});   
  }

  const today=new Date().toISOString();
  const [activeOnOff, setActiveOnOff]=useState<boolean>(true);

  //get all messages
  useEffect(() => { 
    
        const myToken=TokenReusable();
        setCookie("Token", myToken, 10);
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + myToken);
        myHeaders.append("Content-Type", "application/json");        
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        }; 
        fetch(
          BaseUrl()+"/api/v2/messages",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setMessages(result);   
          })
          .catch((error) => {
            console.log("error:", error);           
          }); 
        
  }, [props.render, props.update]); 

  function filterMessages() {    
    setActiveOnOff(activeOnOff => !activeOnOff); 
  } 

  //filter messages for endDate - expired messages
  const filtermess = activeOnOff ? messages.filter(a=> {
  const date = new Date(a.endDate).toISOString();  
  return (today<=date);       
  }) : messages ; 

  //filter messages for startDate - future messages
  const filtermess2 = activeOnOff ? filtermess.filter(b => {
    const date = new Date(b.startDate).toISOString();            
    return (today>date );       
    }) : filtermess ;


  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, mess) => {
    setAnchorEl(event.currentTarget);
    props.setMessageId (mess);        
  };

  const handleClose = (mess) => {
    console.log(`Closing msg ${mess}`);
    setAnchorEl(null);
    props.setMessageId(null);     
  };  

   function EditItem( myitem ) {   
            
        const myToken=TokenReusable();
        setCookie("Token", myToken, 10);
        const myHeaders = new Headers(); 
        myHeaders.append("Authorization", "Bearer " + myToken);
        myHeaders.append("Content-Type", "application/json");  
        
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        fetch(
          BaseUrl()+'/api/v2/messages/'+myitem,
          requestOptions
        )
          .then((response) => response.json())          
          .then((result) => { 
            props.setupdateMessage(result);
          })
          .catch((error) => console.log("error:", error));
     
  } 

  function getColor(itemstart, itemend) {
    if (itemstart <= today && itemend>today)
    return '#509E2F';
    if (itemstart<=today && itemend<today)
    return '#F0F0F0';
    if (itemstart>today)
    return '#FFD700'
    
  }
 
  if (!filtermess2.length) return (

  <Typography className="standard-text" sx={{ fontWeight: 600, }} color="#27272E" paddingTop={2.5}  >
               
              <Switch  defaultChecked={false} color="warning" value={activeOnOff} 
                       onChange={filterMessages} name="activeOnOff"                       
              />                          
              Show All Messages 
              <p style={{fontWeight:'bold', textAlign:'center'}}>No Messages!</p>               
   </Typography>  
   ); 
  
   
  return (  
    <>  
    {/* {accesscomm && */}
    
    <Grid  style={{overflowY: 'auto',                 
                  height: dHeight,
                  paddingBottom: '5px',
                  paddingLeft : '2.5px',
                  paddingRight: '2.5px',
                  border: "1px solid  #f0f0f0 ",
                  minWidth:'250px',  
                  width: '100%',
                  overflowX: 'hidden',                             
                }}                 
      >
      <Typography className="standard-text" sx={{ fontWeight: 600,}} color="#27272E" paddingTop={1.5} paddingBottom={2}>
                
                <Switch  defaultChecked={false} color="warning" value={activeOnOff} 
                        onChange={filterMessages} name="activeOnOff"                       
                />                          
                Show All Messages               
      </Typography>    
   
      {filtermess2
        .slice(0)
        .reverse()        
        .map((item) => {
          return ( 
            
            <Grid container 
              className={classes.menuItem}
              key={item.id} 
              xs={12} 
              sx={{
                 borderTop: "1.5px solid #EBF2FA",
                 borderBottom: "1.5px solid #EBF2FA",
                 borderLeft: "0.1px solid #EBF2FA",
              }}
              >              
              {/*left side color mark*/}              
              <Grid container item width='10px' 
               sx={{
                    background: getColor(item.startDate, item.endDate)
                  }}>
              </Grid>

              {/*message's author*/}             
              <Grid  container item xs={9.5} paddingLeft={'5px'}
                onClick={() => {
                  window.open(
                      `/Message/id=${item.redirectUrl}`,
                  "_self",
                  "noreferrer"                
                  );
                }}
                >
                <Grid>                  
                    <Typography className="standard-text" id="ownerName"
                      sx={{ fontWeight: 600,}}
                      color="#252423"
                    >
                      {item.author.split(" ")[1]+" "+ item.author.split(" ")[0]} 
                    </Typography>                  
                </Grid>

                {/*message's startdate-enddate*/} 
                <Grid >
                    <Typography className="standard-text" color="#73787E">
                      {item.startDate.slice(0, 10)}&nbsp; &nbsp; 
                      {item.endDate.slice(0, 10)}
                    </Typography>
                </Grid>

                {/*message's title*/} 
                <Grid xs={12}>                
                    <Typography className="standard-text" sx={{ wordBreak:"break-word"}} color="#73787E">
                      {JSON.parse(item.content).title }
                    </Typography>
                </Grid>
                
               </Grid>
               {/*menu tree dots*/} 
                <Grid item xs={1} >
                     
                    <Button sx={{paddingTop:'50px', '&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                      onClick={(e)=>handleClick(e,item.id)}
                    >
                    <ThreeDots onMouseEnter={() => setHover(true)} 
                      onMouseLeave={() => setHover(false)} style={{ color: hover ? '#e4610f' : '#5ca3fc'  }} />
                    </Button>
                    
                    <Menu 
                    anchorEl={anchorEl} 
                    open={props.messageID===item.id}
                    onClose={()=>handleClose(item.id)}
                    elevation={0}                    
                    >
                    <Box sx={{border:'1px solid #d0d0d0', borderRadius:'5px', boxShadow:2}}>
                      
                    <MenuItem style={{ width: '120px',}}                     
                    onClick={(d)=>{if (window.confirm("Are you sure you want to EDIT this message? "))
                    {
                      props.setEdit(true);
                      EditItem(item.redirectUrl);
                    }   
                    else {props.setEdit(false);}               
                    d.stopPropagation(); handleClose(item.id);  }} 
                    >
                    <EditIcon />
                    <span className="standard-text"   style={{marginLeft:"8px"}}>Edit</span>
                    </MenuItem>
                     <Divider/>
                    <MenuItem 
                    onClick={(d)=>{if (window.confirm("Are you sure you want to DELETE this message? "))
                    {
                      deleteMessage(item.redirectUrl);  
                    }
                    d.stopPropagation(); handleClose(item.id)}}                    
                    >
                    <DeleteIcon />
                    <span className="standard-text" style={{marginLeft:"8px"}} >Delete</span>
                    </MenuItem></Box>
                    </Menu>
                  
                </Grid> 
            </Grid>             
          );
        })}
      </Grid>
      </>
  );
};