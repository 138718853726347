import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

export const DEASCircularProgress = () => {
    return (
      <Box>
        <CircularProgress
          variant='determinate'
          disableShrink
          sx={{
            marginLeft: '50%',
            marginTop: '20px',
            marginBottom: '20px',                  
            color: '#000000',
            position: 'absolute',
            left: 0, 
            border:'InactiveBorder',
          }}
          size={50}
          thickness={8}
          value={100}
        />              
        <CircularProgress
          variant='indeterminate'
          disableShrink
          sx={{
            marginLeft: '50%',
            marginTop: '20px',
            marginBottom: '20px',
            color: '#E4610F',
            animationDuration: '1500ms',
            position: 'absolute',
            left: 0,                  
          }}
          size={50}
          thickness={8}
        /> 
      </Box>     
    )
}