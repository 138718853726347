import * as React from "react";
import {UnauthenticatedTemplate} from "@azure/msal-react";
import { SignInButton } from "./components/SignInButton";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import deas_bg from "./components/StartPageImages/deas-bg.png";
import connect from "./components/StartPageImages/connect.png";
import automate from "./components/StartPageImages/automate.png";
import insights from "./components/StartPageImages/insights.png";
import logo from "./components/StartPageImages/PrimaryLightLogo.png";
import deas_logo from "../src/components/images_mainpage/logoWhite.png";

const HomePage = () => {
  
  return (    
    <div style={{ position:'fixed'}}> 
      <UnauthenticatedTemplate>         
          
      <Grid container item xs={12} >
      <Grid item xs={2} paddingTop={'20px'} paddingRight={'10px'} paddingLeft={'20px'}>
        <img src={logo} width={'90%'} alt=""/>
        <Grid justifyContent={'center'} flexDirection={'row'}  display={'flex'} paddingTop={'20px'}>
        <b><span style={{color:"#e4610f"}}>D</span><span>esign & <span style={{color:"#e4610f"}}>E</span>ngineering</span></b>
        </Grid>
        <Grid justifyContent={'center'} flexDirection={'row'}  display={'flex'}>
        <b><span style={{color:"#e4610f"}}>A</span><span>utomation <span style={{color:"#e4610f"}}>S</span>ystem</span></b>
      </Grid>
      <Grid container
            justifyContent={'center'} flexDirection={'row'}  display={'flex'} paddingTop={'300px'}>
            <p style={{fontSize:'16px'}}>
            To start using DEAS, please
            </p>
      </Grid>
      <Grid container
            justifyContent={'center'} flexDirection={'row'}  display={'flex'} paddingTop={'15px'}>
        <SignInButton/>
      </Grid>
      </Grid>
     
      <Grid item xs={10}            
            style={{backgroundImage: `url(${deas_bg})`, backgroundSize:'cover', height:"100vh"  }}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">

        <Card
          sx={{
              width: "900px",
              height: "500px",
              border: "1px solid #f0f0f0",
              borderRadius: "10px",            
              boxShadow: "none" ,
              backgroundColor: "#f0f0f0"
          }}
          >
          <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
          <Grid>
            {/*grid DEAS logo*/}
              <Grid item xs={12} 
                    justifyContent={'flex-end'} 
                    display={'flex'} 
                    flexDirection={'row'}  
                    height={'60px'} 
                    paddingRight={'10px'} 
                    paddingTop={'10px'} >
              <img src={deas_logo} height={'40px'} alt=""/>
              </Grid>
              {/*grid Welcome*/}
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}  
                    height={'10px'} >
              <p style={{fontSize:'20px'}}>Welcome to DEAS!</p>
              </Grid>
              {/*grid DEAS */}
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}  
                    height={'10px'} paddingTop={'10px'}>
              <p style={{fontSize:'26px', color:'#e4610f'}}>Design & Engineering Automation System</p>
              </Grid>

          {/*grid Middle Content*/}
          <Grid sx={{gap: "20px"}}
                justifyContent={'center'} 
                display={'flex'} 
                flexDirection={'row'}
                paddingTop={'100px'} container >

              {/* Grid Connect */}
              <Grid >
              <Grid justifyContent={'center'} 
                display={'flex'} alignItems={'center'} 
                flexDirection={'column'}>
              <Card
              sx={{
                  width: "150px",
                  height: "150px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#f0f0f0"                                     
              }}
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >
              <Grid>
                  <Grid height={'33px'}></Grid>
                  <Grid alignContent={'center'} paddingLeft={'45px'} height={'64px'} >
                  <img src={connect} width={'64px'} height={'64px'} alt=""/>
                  </Grid>
                  <Grid  bgcolor={"#f0f0f0"} height={'53px'}
                          style={{fontSize:'18px', fontStyle:'italic', color: '#707070', textAlign:'center', paddingTop:''}}>
                      <Grid><b>Connect</b></Grid>
                  </Grid>
              </Grid>
              </CardContent>
              </Card></Grid>
              <Grid  width={'200px'} paddingTop={'20px'}>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Skills & Contacts</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>In-App Communications</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Old version warning</Grid>
              </Grid>             
            </Grid>

        {/*  Grid Automate */}
        <Grid >
        <Grid justifyContent={'center'} 
                display={'flex'} alignItems={'center'} 
                flexDirection={'column'} >
        <Card
        sx={{
          width: "150px",
          height: "150px",
          border: "2px solid #e4610f",            
          boxShadow: "none", 
          borderRadius:"50%",
          background: "#f0f0f0"                     
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid>
            <Grid height={'33px'}></Grid>
            <Grid alignContent={'center'} paddingLeft={'45px'} height={'64px'}>
            <img src={automate} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid bgcolor={"#f0f0f0"} height={'53px'}
                    style={{fontSize:'18px', fontStyle:'italic', color: '#707070', textAlign:'center', paddingTop:'0'}}>
                <Grid><b>Automate</b></Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card></Grid>
        <Grid width={'200px'} paddingTop={'20px'}>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Share tools</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Share standards</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Tool catalogue</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Tool explanation</Grid>
              <Grid justifyContent={'center'} 
                  display={'flex'} 
                  flexDirection={'row'}>Idea Capture</Grid>
              </Grid> 
        </Grid>

        {/*  Grid Insight */}
        <Grid >
        <Grid justifyContent={'center'} 
                display={'flex'} alignItems={'center'} 
                flexDirection={'column'}>
        <Card
        sx={{
          width: "150px",
          height: "150px",
          border: "2px solid #e4610f",            
          boxShadow: "none", 
          borderRadius:"50%",
          background: "#f0f0f0"                     
        }}
        >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Grid>
            <Grid height={'33px'}></Grid>
            <Grid alignContent={'center'} paddingLeft={'45px'} height={'64px'}>
            <img src={insights} width={'64px'} height={'64px'} alt=""/>
            </Grid>
            <Grid bgcolor={"#f0f0f0"} height={'53px'}
                    style={{fontSize:'18px', fontStyle:'italic', color: '#707070', textAlign:'center', paddingTop:'0'}}>
                <Grid><b>Insights</b></Grid>
            </Grid>
        </Grid>
        </CardContent>
        </Card></Grid>
        <Grid width={'200px'} paddingTop={'20px'}>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Software metrics</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Tool metrics</Grid>
              <Grid justifyContent={'center'} 
                    display={'flex'} 
                    flexDirection={'row'}>Adoption metrics</Grid>
              </Grid> 
        </Grid>
        </Grid>
          </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </UnauthenticatedTemplate>      
    </div>
  );
};

export default HomePage;

