import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Grid } from "@mui/material";
import "../App.css";
import { loginRequest } from "../authConfig";
import Typography from "@mui/material/Typography";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        // Initiate login process
        instance.loginRedirect(loginRequest).catch((e) => {
           console.error('Login failed:', e);
         });
    };
   
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={4}>
                <Button
                    variant="contained" 
                    className="primary-button" style={{width:'120px', height:'50px'}} 
                    onClick={handleLogin}          
                >
                    <Typography              
                    align="center"
                    variant="h6"
                    component="h6">
                    SIGN IN
                    </Typography>
                    
                </Button>
            </Grid>
        </Grid>
    );
};
