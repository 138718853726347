const pins = [
  {
    Country: "Netherlands",
    City: "Rotterdam",
    Lat: "51.9280712",
    Long: "4.4207888",
  },
  {
    Country: "United Kingdom",
    City: "Croydon",
    Lat: "51.3678765",
    Long: "-0.1172588",
  },
  { Country: "Romania", City: "Iasi", Lat: "47.1562327", Long: "27.5169309" },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  { Country: "Belgium", City: "Antwerp", Lat: "51.219448", Long: "4.402464" },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  {
    Country: "United States of America",
    City: "New York",
    Lat: "40.6973929",
    Long: "-74.266756",
  },
  { Country: "Germany", City: "Munich", Lat: "48.155004", Long: "11.4717963" },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  {
    Country: "Australia",
    City: "Brisbane",
    Lat: "-27.3812533",
    Long: "152.7130155",
  },
  { Country: "France", City: "Paris", Lat: "48.8588787", Long: "2.2035137" },
  {
    Country: "Philippines",
    City: "Manila",
    Lat: "14.5965788",
    Long: "120.9445403",
  },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  {
    Country: "Philippines",
    City: "Manila",
    Lat: "14.5965788",
    Long: "120.9445403",
  },
  {
    Country: "Australia",
    City: "Melbourne",
    Lat: "-37.9716929",
    Long: "144.7729589",
  },
  { Country: "France", City: "Paris", Lat: "48.8588787", Long: "2.2035137" },
  { Country: "Germany", City: "Berlin", Lat: "52.5068441", Long: "13.4247317" },
  { Country: "India", City: "Bangalore", Lat: "12.971599", Long: "77.594566" },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  { Country: "Romania", City: "Iasi", Lat: "47.1562327", Long: "27.5169309" },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  {
    Country: "United Kingdom",
    City: "Manchester",
    Lat: "53.4723272",
    Long: "-2.2935021",
  },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  { Country: "Belgium", City: "Antwerp", Lat: "51.219448", Long: "4.402464" },
  {
    Country: "United States of America",
    City: "Tampa",
    Lat: "27.9947147",
    Long: "-82.5943642",
  },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  {
    Country: "Australia",
    City: "Brisbane",
    Lat: "-27.3812533",
    Long: "152.7130155",
  },
  {
    Country: "United States of America",
    City: "Atlanta",
    Lat: "33.748997",
    Long: "-84.387985",
  },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  { Country: "Germany", City: "Berlin", Lat: "52.5068441", Long: "13.4247317" },
  {
    Country: "Philippines",
    City: "Manila",
    Lat: "14.5965788",
    Long: "120.9445403",
  },
  {
    Country: "Netherlands",
    City: "Rotterdam",
    Lat: "51.9280712",
    Long: "4.4207888",
  },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  {
    Country: "United States of America",
    City: "Novi",
    Lat: "42.4811979",
    Long: "-83.5294604",
  },
  {
    Country: "United States of America",
    City: "Tampa",
    Lat: "27.9947147",
    Long: "-82.5943642",
  },
  {
    Country: "United Kingdom",
    City: "Guildford",
    Lat: "51.2415639",
    Long: "-0.6004426",
  },
  {
    Country: "United Kingdom",
    City: "Bristol",
    Lat: "51.468575",
    Long: "-2.6607568",
  },
  {
    Country: "United Kingdom",
    City: "Croydon",
    Lat: "51.3678765",
    Long: "-0.1172588",
  },
  {
    Country: "Philippines",
    City: "Manila",
    Lat: "14.5965788",
    Long: "120.9445403",
  },
  { Country: "India", City: "Bangalore", Lat: "12.971599", Long: "77.594566" },
  {
    Country: "Germany",
    City: "Leipzig",
    Lat: "51.3419134",
    Long: "12.2535533",
  },
  {
    Country: "Philippines",
    City: "Manila",
    Lat: "14.5965788",
    Long: "120.9445403",
  },
  {
    Country: "Australia",
    City: "Melbourne",
    Lat: "-37.9716929",
    Long: "144.7729589",
  },
  {
    Country: "Australia",
    City: "Sydney",
    Lat: "-33.8473567",
    Long: "150.6517965",
  },
  { Country: "Belgium", City: "Antwerp", Lat: "51.219448", Long: "4.402464" },
  { Country: "Germany", City: "Berlin", Lat: "52.5068441", Long: "13.4247317" },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
  { Country: "Belgium", City: "Hasselt", Lat: "50.9246623", Long: "5.2435061" },
  {
    Country: "Netherlands",
    City: "Amersfoort",
    Lat: "52.156113",
    Long: "5.387827",
  },
];

export default pins;
