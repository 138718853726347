import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import "../../App.css";

/* eslint-disable react/prop-types */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles({
  clearIndicator: {
    visibility: "visible",
    color: "red",
    backgroundColor: "white",
    scale: "1.4",
  },
});


const countries = [
  { label: "Australia" },
  { label: "United Kingdom" },
  { label: "Netherlands" },
  { label: "Romania" },
  { label: "United States of America" },
  { label: "Philippines" },
  { label: "Belgium" },
  { label: "Germany" },
  { label: "France" },
  { label: "India" },
];

const gbas = ["1-Resilience", "2-Places","3-Mobility","GEC/GSSC"];
const assets = [
  "Rails & Highways",
  "Architecture",
  "Structure",
  "MEP",
  "Tunnels & Bridges",
];
const skills = [
  "Revit API",
  "Dynamo",
  "Grasshopper",
  "C#",
  "C++",
  "Javascript",
  "Revit",
  "Civil3D",
  "Rhinoceros",
  "Bentley OpenSuite",
];

export default function TopFilters(props) {
  const classes = useStyles();
  const [gba, setGba] = React.useState<string[]>([]);
  const [asset, setAsset] = React.useState<string[]>([]);
  const [skill, setSkill] = React.useState<string[]>([]);
  const [sortBy, setSortBy] = React.useState<string[]>([]);
  const [radioValue, setRadioValue] = React.useState("mapView");

  const handleCountryChange = (event) => {
    props.setCountryPass(event.target.outerText);
    setSortBy([]);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
    props.setRadioPass((event.target as HTMLInputElement).value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchPass(event.target.value);
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    function getValueType(value) {
      if (typeof value === "string") {
        return value.split(",")
      }
      else {return value;}
    }

    switch (event.target.name) {
      case "gba":
        setSortBy([]);        
        setGba(getValueType(value));
        props.setGBA(getValueType(value));
        break;
      case "asset":
        setSortBy([]);        
        setAsset(getValueType(value));        
        props.setAsset(getValueType(value));
        break;
      case "skill":
        setSortBy([]);        
        setSkill(getValueType(value));       
        props.setSkills(getValueType(value));
        break;
      case "sortby":
        setSortBy([]);        
        setSortBy(getValueType(value));        
        props.setSortByPass(getValueType(value));
        break;
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        sx={{
          padding: "10px",
          borderBottom: "2px solid #EBF2FA",
        }}
      >
        <Grid item xs={2}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="row"
          >
            <Typography sx={{ fontWeight: 600, fontSize: 23 }} color="#27272E">
              Skills & Contacts
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection="row"
          >
            {" "}
            <FormControl size="small" sx={{ m: 1, width: 200 }}>
              <Autocomplete
                disablePortal
                value={props.countryPass || null}
                id="combo-box-demo"
                options={countries}
                size="small"
                disableClearable={false}
                onChange={handleCountryChange}
                sx={{ borderRadius: "6px", color:"E4610F" }}
                classes={{
                  clearIndicator: classes.clearIndicator,
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country" color="warning"/>
                )}
              />
            </FormControl>
            <FormControl size="small" sx={{ m: 1, width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label" color="warning">GBA</InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                }}
                labelId="demo-multiple-checkbox-label" 
                id="demo-multiple-checkbox"
                name="gba"
                multiple
                size="small"
                value={gba}
                onChange={handleChange}
                input={<OutlinedInput label="GBA" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {gbas.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={gba.indexOf(name) > -1} style = {{color: '#E4610f'}}/>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ m: 1, width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label" color="warning">
                Asset Type
              </InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="asset"
                multiple
                size="small"
                value={asset}
                onChange={handleChange}
                input={<OutlinedInput label="Asset Type" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {assets.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={asset.indexOf(name) > -1} style = {{color: '#E4610f'}}/>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ m: 1, width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label" color="warning">
                Skill Type
              </InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="skill"
                multiple
                size="small"
                value={skill}
                onChange={handleChange}
                input={<OutlinedInput label="Skill Type" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {skills.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={skill.indexOf(name) > -1} style = {{color: '#E4610f'}}/>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <Grid item xs={3}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="row"
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="listView"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E4610F",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 16 }}
                      color="#27272E"
                    >
                      List View
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="mapView"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E4610F",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 16 }}
                      color="#27272E"
                    >
                      Map View
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection="row"
          >
            {" "}
            <FormControl
              size="small"
              sx={{ m: 1, width: "400px" }}
              variant="outlined"
            >
              <InputLabel id="outlined-adornment-search" color="warning">Search</InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
            <FormControl size="small" sx={{ m: 1, width: 150 }}>
              <InputLabel id="demo-multiple-checkbox-label" color="warning">Sort By</InputLabel>
              <Select
                sx={{
                  borderRadius: "6px",
                }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="sortby"                
                size="small"
                value={sortBy}
                onChange={handleChange}
                input={<OutlinedInput label="Sort By" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {skills.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={sortBy.indexOf(name) > -1} style = {{color: '#E4610f'}} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
