import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import header from './AboutDEASContent/header_about_deas.png';
import josha from './DEASCoreContent/joshavanreij.png';
import '../App.css';
import {withRouter} from 'react-router-dom';
import React from "react";
import ScrollToAnchor from './ScrollToAnchor';
import sd from './HomePageContent/sd.svg';
import td from './HomePageContent/td.svg';
import newproject from './RevitConnectorContent/newproject.png';
import globe from './RevitConnectorContent/globe.svg';
import pdf from './RevitConnectorContent/document.svg';
import pablo from './RevitConnectorContent/pablo.png';
import u31 from './RevitConnectorContent/u31.png';
import available from './RevitConnectorContent/available.png';
import toon from './RevitConnectorContent/toon.png';
import mep1 from './RevitConnectorContent/mep1.png';
import mep2 from './RevitConnectorContent/mep2.png';
import mep3 from './RevitConnectorContent/mep3.png';
import brett from './HomePageContent/brettburling.png';
import arcadiscontrolroom from './RevitConnectorContent/arccontrolroom.png';
import downloadbutton from './RevitConnectorContent/downloadbutton.png';
import visibilytoogle from './RevitConnectorContent/visibilitytoogle.png';
import otl from './RevitConnectorContent/otl.png';
import learn from './RevitConnectorContent/learn.svg';
import Divider from "@mui/material/Divider";
import au1 from './RevitConnectorContent/au1.png';
import au2 from './RevitConnectorContent/au2.png';
import au3 from './RevitConnectorContent/au3.png';
import au4 from './RevitConnectorContent/au4.png';
import otl_localized from './RevitConnectorContent/otl_localization.png';
import otl_familyeditor from './RevitConnectorContent/otl_familyeditor.png';

const RevitConnector=() => {  
    ScrollToAnchor();     

    return (
        <div>
    <Grid paddingRight={'20px'}>
    <Grid container spacing={'1px'} item xs={500} >
        <Grid container spacing={'1px'} item xs={12} >    
        <Grid item xs={12}
            style={{backgroundImage: `url(${header})`, backgroundSize:'cover', height:"180px"  }} >
                <Grid paddingTop={'100px'} paddingLeft={'20px'}>
                    <p style={{color:'#e4610f', fontSize:'25px', fontWeight:'500' }} >Guide - Revit Connector</p>
                </Grid>
            </Grid>

            <Grid  item xs={5} paddingRight='10px' paddingTop='20px'>                
            <p className="standard-text" style={{alignContent:'flex-end', paddingTop:'10px'}}>
            <span style={{fontWeight:'600'}}>Summary<br></br></span>
            The DEAS Revit Connector is a Revit add-in that provides access to the DEAS Core functionality from within Revit. 
            Additionally, it provides a number of functions specifically for Revit.</p>
            </Grid>

            <Grid  item xs={3} paddingLeft={'10px'}>
                
            </Grid>

            <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
            <Grid item xs={0.25} />

            <Grid  item xs={3.5} container justifyContent={'left'} paddingTop='20px'>
                <Grid item xs={10}  paddingBottom='10px' paddingTop='15px' >
                <Button variant="contained" fullWidth 
                    className="mainhome-button" 
                    onClick={() => window.location.href="#revit-standards-distribution"}>
                        <Grid item xs={2} paddingTop={'5px'} >
                        <img src={sd} className='svg' alt=""/></Grid>
                        <Grid item xs={8} display='flex' direction='row' alignContent='left'>Revit Standard Distribution</Grid>
                    </Button>            
                </Grid>

                <Grid item xs={10} paddingBottom='10px' >
                <Button variant="contained" fullWidth 
                    className="mainhome-button" 
                    onClick={() => window.location.href="#how-do-i-acces-toolkit"}>
                        <Grid item xs={2} paddingTop={'5px'}>                
                        <img src={learn} className='svg' alt=""/></Grid>     
                        <Grid item xs={8} display='flex' direction='row' alignContent='left'>How do I acces a toolkit?</Grid>
                </Button>
                </Grid>

                <Grid item xs={10} paddingBottom='1px'>
                <Button variant="contained" fullWidth 
                    className="mainhome-button" 
                    onClick={() => window.location.href="#toolkits"}>
                        <Grid item xs={2} paddingTop={'5px'}>
                        <img src={td} className='svg' alt=""/></Grid>                    
                        <Grid item xs={8} display='flex' direction='row' alignContent='left'>Revit toolkits</Grid>
                </Button>    
                </Grid>
            </Grid>
       
        <Grid item xs={12} >
        <Grid id="revit-standards-distribution" style={{paddingBottom:'40px'}}/> 
            <p style={{fontSize:'24px', fontWeight:'500',
                color:'#e4610f',
                height:'80px',
                background:'#f0f0f0', paddingTop:'25px'}} >
                Revit Standards Distribution</p>
        </Grid>

        <Grid item xs={8}> 
        <p className="standard-text" style={{alignContent:'flex-end'}}>DEAS enables the distribution of the latest Revit standard documents to your colleagues.
         No more searching, asking, or using out-of-date files.</p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        Currently, the following documents are supported:</p> 
        <ul className="standard-text">
            <li>Revit Template files (<span style={{color:'#e4610f', fontStyle:'italic'}}>.rte</span> and <span style={{color:'#e4610f', fontStyle:'italic'}}>.rvt</span>)</li>
            <li>Revit Shared Parameters</li>            
        </ul>
        <p className="standard-text" style={{alignContent:'flex-end'}}>In the future support will be added for more file types such as Material textures and keynote files</p>
        
        <h2>How Do I Share Documents?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        DEAS distributes documents from a central file store on Autodesk Construction Cloud. 
        When documents are uploaded into the correct ACC project they are instantly available to all Revit users globally.
        </p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>DEAS has been set up so that only content managers (BIM leads or Revit Leads) need access to the ACC file store.
         Please request access by contacting <span style={{color:'#e4610f'}}>
                <a href="mailto:josha.vanreij@arcadis.com" target="_blank" 
                rel="noreferrer" style={{color:'#e4610f'}}>Josha Van Reij</a></span>.
         </p>
         <p className="standard-text" style={{alignContent:'flex-end'}}>For DEAS to correctly process the files it is critical that the files are named using the correct <span style={{color:'#e4610f'}}>
                <a href="https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/Public%20support%20documents/Forms/AllItems.aspx?id=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FRevit%2FStandards%20distribution%2FDEAS%20%2D%20Revit%20standards%20distribution%2Epdf&parent=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FRevit%2FStandards%20distribution" target="_blank" 
                rel="noreferrer" style={{color:'#e4610f'}}>naming convention</a></span> and uploaded 
         into the correct team folder within the ACC project.
         </p>

        <h2>How Do I Access The Documents?</h2>
        <p className="standard-text" style={{alignContent:'flex-end'}}>Revit users can access the latest documents from within Revit via DEAS custom dialogues.
         (e.g. on opening a new project and selecting a template).</p>
        {/* eslint-disable-next-line */}  
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        <img src={newproject} width={'60%'} alt=""/><br></br>
        <span style={{ fontSize:"12px"}}>The DEAS new project dialogue</span></p>
        </Grid>

        {/*right*/}
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
           <Grid item xs={3.5}>
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>To access the ACC project contact:</p>
                    {/* eslint-disable-next-line */} 
                    <Grid container >
                        <Grid container > 
                        <Grid paddingRight={'10px'} ><img src={josha} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                            <Grid >
                                <Grid style={{fontSize:'12px', fontWeight:'600',  textAlign:'start'}} paddingTop={'10px'}>van Reij, Josha</Grid>
                                <Grid style={{fontSize:'12px', fontWeight:'500',  textAlign:'start'}}>Consultant</Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                    <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', paddingTop:'50px'}}>Useful Links:</p>
                    <Grid item xs={10} paddingBottom='20px' >
                    <Button variant="contained" fullWidth
                        className="mainhome-button" 
                        onClick={() => window.open("https://accounts.autodesk.com/logon?resume=%2Fas%2FqZL5faghCB%2Fresume%2Fas%2Fauthorization.ping&spentity=null#username", "_blank", "noopener") }>
                        <Grid item xs={2} paddingTop={'5px'}><img src={globe} className='svg' alt="" /></Grid>
                        <Grid item xs={8} display='flex' direction='row' alignContent='left'>AUTODESK CONSTRUCTION CLOUD</Grid>
                    </Button>
                    </Grid>

                    <Grid item xs={10} paddingBottom='20px'>
                    <Button variant="contained" fullWidth 
                        className="mainhome-button" 
                        onClick={() => window.open("https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/Public%20support%20documents/Forms/AllItems.aspx?id=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FRevit%2FStandards%20distribution%2FDEAS%20%2D%20Revit%20standards%20distribution%2Epdf&parent=%2Fteams%2FDEAS%2DDesignEngineeringAutomationSystem%2FPublic%20support%20documents%2FAutodesk%2FRevit%2FStandards%20distribution", "_blank", "noopener")}>
                        <Grid item xs={2} paddingTop={'5px'}><img src={pdf} className='svg' alt=""/></Grid>
                        <Grid item xs={8} display='flex' direction='row' alignContent='left'>DOCUMENT NAMING CONVENTION</Grid>
                    </Button>    
                    </Grid>        
            </Grid>
        </Grid>

        {/*how do I acces the toolkit*/}        
        <Grid item xs={12}>
        <Grid id="how-do-i-acces-toolkit" style={{paddingBottom:'40px'}}/> 
        <p style={{fontSize:'24px', fontWeight:'500',
            color:'#e4610f',
            height:'80px',
            background:'#f0f0f0', paddingTop:'25px'}} >
            How Do I Access A Toolkit?</p>
        </Grid>
        <Grid item xs={8}>
            <Grid item xs={12}>
            <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>Step 1</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            In Revit go to the <span style={{color:'#e4610f', fontStyle:'italic'}}>Arcadis DEAS </span>
             tab and select the <span style={{color:'#e4610f', fontStyle:'italic'}}>Workspaces</span> button.</p>                        
            </Grid>

        <Grid container justifyContent={'center'} paddingTop={'20px'}>
        <img src={arcadiscontrolroom} width={'50%'} alt=""/>
        </Grid>

        {/*Step2*/}
        <Grid item xs={12} >            
            <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>Step 2</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>In the <span style={{color:'#e4610f', fontStyle:'italic'}}>Workspace Manager</span> window, 
            select the toolkit you would like to use, then click the <span style={{color:'#e4610f', fontStyle:'italic'}}>Install </span> button and wait for it to download and install. 
            </p>
             
        </Grid>
        <Grid container justifyContent={'center'} paddingTop={'20px'}>
            {/* eslint-disable-next-line */}
        <img src={downloadbutton} width={'30%'} alt=""/>
        </Grid>

        {/*Step3*/}
        <Grid item xs={12} >            
            <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>Step 3</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>When downloading is complete set the toolkit to be visible by clicking 
            the <span style={{color:'#e4610f', fontStyle:'italic'}}>Show</span>.</p>

        </Grid>
        <Grid container justifyContent={'center'} paddingTop={'20px'}>
            {/* eslint-disable-next-line */}
        <img src={visibilytoogle} width={'20%'} alt=""/>
        </Grid>

        {/*Step4*/}
        <Grid item xs={12} paddingBottom={'50px'}>            
            <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>Step 4</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>Close the dialogue, the toolkit should now be visible in the ribbon.</p>
            <h2>How do I Add a Toolkit to DEAS?</h2>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            Providing access for developers to add a toolkit to DEAS is still in development. However,
            if you (or your team) have something that you think should be added we would love to hear about it so please get in contact.   
            </p>
            <h2>A tool is Not Working as Expected</h2>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            For this, you can refer to the explanation of the tool on this SharePoint or contact the toolkit owner. 
            Unfortunately, IT ServiceDesk is not yet set up to provide assistance concerning individual tools.   
            </p>
        </Grid></Grid>
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
        <Grid item xs={0.25} />
       
        {/*toolkits*/}
        <Grid item xs={12}>
        <Grid id="toolkits" style={{paddingBottom:'40px'}}/>         
        <p style={{fontSize:'24px', fontWeight:'500',
            color:'#e4610f',
            height:'80px',
            background:'#f0f0f0', paddingTop:'25px'}} >
            Revit toolkits</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>A toolkit is a collection of custom-created buttons that are run from within Revit. 
            DEAS provides the framework to distribute and update toolkits.</p>
            <h2>What Toolkits Are Available?</h2>            
        </Grid>
        <hr></hr>
        {/*pablo u31*/}
        <Grid item xs={12} container >
        <Grid item xs={8}> 
        <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>UK Bridges Tool (UT31)</p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        A collection of tools to simplify the creation of bridges in Revit.
        </p>
        {/* eslint-disable-next-line */}  
        <p style={{alignContent:'flex-end'}}><img src={u31} width={'95%'} alt=""/></p>
        <Grid paddingTop={'20px'} className='standard-text'>
            Available for version of Revit: 2020, 2021, 2022, 2023. 
        </Grid>  
        </Grid>
        
        <Grid item xs={0.25}  borderRight={'1px solid #d0d0d0'} />
           <Grid item xs={0.25} />
           <Grid item xs={3.5}>
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>Toolkit Owner:</p>
                    {/* eslint-disable-next-line */} 
                    <Grid container >
                        <Grid container > 
                        <Grid paddingRight={'10px'}><img src={pablo} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                            <Grid >
                                <Grid style={{fontSize:'12px', fontWeight:'600',  textAlign:'start'}} paddingTop={'10px'}>Alvarez, Pablo</Grid>
                                <Grid style={{fontSize:'12px', fontWeight:'500',  textAlign:'start'}}>Design Manager A</Grid>
                            </Grid>
                            <Grid paddingTop={'20px'}>
                            <img src={available} width="40%" alt=""/> 
                            </Grid> 
                        </Grid>
                    </Grid>                    
        </Grid>
        </Grid>
        
        <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'20px'}>
            <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
        </Grid>   

        {/*toon mep*/}
        <Grid item xs={12} container >
        <Grid item xs={8}> 
        <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>MEP Toolbar</p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        A tool for adding insulation to pipes.
        </p>
        {/* eslint-disable-next-line */}  
        <p style={{alignContent:'flex-end'}}><img src={mep1} width={'60%'} alt=""/></p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        Thermal Properties Addon.
        </p>
        {/* eslint-disable-next-line */}  
        <p style={{alignContent:'flex-end'}}><img src={mep2} width={'60%'} alt=""/></p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        HeatLoss Calcultation Addon.
        </p>
        {/* eslint-disable-next-line */}  
        <p style={{alignContent:'flex-end'}}><img src={mep3} width={'60%'} alt=""/></p>
        <Grid paddingTop={'20px'} className='standard-text'>
            Available for version of Revit: 2020, 2021, 2022, 2023. 
        </Grid>  
        </Grid> 
        <Grid item xs={0.25}  borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
           <Grid item xs={3.5}>
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>Toolkit Owner:</p>
                    {/* eslint-disable-next-line */} 
                    <Grid container >
                        <Grid container > 
                        <Grid paddingRight={'10px'}><img src={toon} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                            <Grid >
                                <Grid style={{fontSize:'12px', fontWeight:'600',  textAlign:'start'}} paddingTop={'10px'}>Demuynck, Toon</Grid>
                                <Grid style={{fontSize:'12px', fontWeight:'500',  textAlign:'start'}}>BIM Coordinator</Grid>
                            </Grid>
                            <Grid paddingTop={'20px'}>
                            <img src={available} width="40%" alt=""/> 
                            </Grid> 
                        </Grid>
                    </Grid>                    
        </Grid>
        </Grid>
         
        <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'20px'}>
            <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
        </Grid> 
        
        {/*australian toolkit*/}
        <Grid item xs={12} container >
        <Grid item xs={8}> 
        <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>Australian Toolkit </p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        A collection of tools for creating and modifying building elements.
        </p>
        <Grid><img src={au1} width={'80%'} alt=""/></Grid>
        <Grid><img src={au2} width={'80%'} alt=""/></Grid>
        <Grid><img src={au3} width={'80%'} alt=""/></Grid>
        <Grid><img src={au4} width={'80%'} alt=""/></Grid>
        <Grid paddingTop={'20px'} className='standard-text'>
            Available for version of Revit: 2020, 2021, 2022, 2023, 2024, 2025. 
        </Grid> 
        </Grid> 
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
           <Grid item xs={3.5}>
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>Toolkit Owner:</p>
                    {/* eslint-disable-next-line */} 
                    <Grid container >
                        <Grid container > 
                        <Grid paddingRight={'10px'}><img src={brett} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                            <Grid >
                                <Grid style={{fontSize:'12px', fontWeight:'600',  textAlign:'start'}} >Burling, Brett</Grid>
                                <Grid style={{fontSize:'12px', fontWeight:'500',  textAlign:'start'}}>Digital Technologies Lead - Computational Design &<br></br> Software Developer</Grid>
                            </Grid>
                            <Grid paddingTop={'20px'}>
                            <img src={available} width="40%" alt=""/> 
                            </Grid> 
                        </Grid>
                    </Grid>                    
        </Grid></Grid></Grid>

        <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'20px'}>
            <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
        </Grid> 

        {/*josha otl*/}
        <Grid item xs={12} container paddingBottom={'30px'}>
        <Grid item xs={8}> 
        <p style={{alignContent:'flex-end', color:"#e4610f", fontWeight:'600', fontSize:'18px'}}>OTL Tools</p>
        <p className="standard-text" style={{alignContent:'flex-end'}}>
        A collection of tools for connecting Revit models to the Arcadis Object Type Library (OTL).
        </p>
        {/* eslint-disable-next-line */} 
        <Grid className="standard-text">OTL Tools:</Grid> 
        <Grid paddingTop={'5px'}>
        <img src={otl} width="40%" alt=""/> 
        </Grid>
        <Grid className="standard-text" paddingTop={'10px'}>OTL Localized content:</Grid> 
        <Grid paddingTop={'5px'}>
        <img src={otl_localized} width="25%" alt=""/> 
        </Grid>
        <Grid className="standard-text" paddingTop={'10px'}>OTL Family Editor tool:</Grid> 
        <Grid paddingTop={'5px'}>
        <img src={otl_familyeditor} width="30%" alt=""/> 
        </Grid>

        <Grid paddingTop={'20px'} className='standard-text'>
            Available for version of Revit: 2020, 2021, 2022, 2023, 2024, 2025. 
        </Grid> 
        </Grid> 
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
           <Grid item xs={3.5}>
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start', flexWrap:'wrap'}}>Toolkit Owner:</p>
                    {/* eslint-disable-next-line */}                     
                    <Grid container >
                        <Grid container > 
                        <Grid paddingRight={'10px'}><img src={josha} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                            <Grid >
                                <Grid style={{fontSize:'12px', fontWeight:'600',  textAlign:'start'}} paddingTop={'10px'}>van Reij, Josha</Grid>
                                <Grid style={{fontSize:'12px', fontWeight:'500',  textAlign:'start'}}>Consultant</Grid>
                            </Grid>                            
                            <Grid paddingTop={'20px'}>
                            <img src={available} width="40%" alt=""/> 
                            </Grid> 
                        </Grid>
                    </Grid>                    
        </Grid>
        </Grid>
        
        <Grid bgcolor={'#e4610f'} >
        <canvas id="myCanvas"  height="30px" style={{border:'1px solid #e4610f'}}></canvas>
    </Grid> 
    </Grid>
    
    </div>
    )
}

export default withRouter(RevitConnector);