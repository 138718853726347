/* eslint-disable react/prop-types */ 
import CardContent from "@mui/material/CardContent";
import {Grid} from "@mui/material";
import Item from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import portrait from "../../assets/SkillsContacts/portrait.png";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import revitIcon from "../../assets/SkillsContacts/revit.png";
import javascriptIcon from "../../assets/SkillsContacts/javascript.png";
import bentleyIcon from "../../assets/SkillsContacts/bentley.png";
import civil3dIcon from "../../assets/SkillsContacts/civil3d.png";
import cplusplusIcon from "../../assets/SkillsContacts/cplusplus.png";
import csharpIcon from "../../assets/SkillsContacts/csharp.png";
import dynamoIcon from "../../assets/SkillsContacts/dynamo.png";
import grasshopperIcon from "../../assets/SkillsContacts/grasshopper.png";
import revitapiIcon from "../../assets/SkillsContacts/revitapi.png";
import rhinocerosIcon from "../../assets/SkillsContacts/rhinoceros.png";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError,} from "@azure/msal-browser";
import { useEffect, useState } from "react";


const skillsIcons = [
  { name: "RevitAPI", icon: revitapiIcon },
  { name: "Dynamo", icon: dynamoIcon },
  { name: "Grasshopper", icon: grasshopperIcon },
  { name: "csharp", icon: csharpIcon },
  { name: "cplusplus", icon: cplusplusIcon },
  { name: "Javascript", icon: javascriptIcon },
  { name: "Revit", icon: revitIcon },
  { name: "Civil3D", icon: civil3dIcon },
  { name: "Rhinoceros", icon: rhinocerosIcon },
  { name: "BentleyOpenSuite", icon: bentleyIcon },
];

// eslint-disable-next-line no-unused-vars
const Skills = (props: any) => {
  let i = 1;

  i += 100;
  return (
    <>
      {/* map over all objects of contact */}
      {Object.keys(props.contact).map((item) => {
        let icon = skillsIcons.filter((obj) => {
          return obj.name === item;
        });

        //if object is a skill and there is an icon for that skill
        if (props.contact[item] > 1 && icon[0]) {
          return (
            <Tooltip
              key={item}
              title={
                <Typography sx={{ fontSize: 14 }}>
                  {item + ": " + props.contact[item] + "/5"}
                </Typography>
              }
            >
              <Box display="flex" order={i ** props.contact[item] * -1}>
                {" "}
                <img src={icon[0].icon} />
              </Box>
            </Tooltip>
          );
        }
      })}
    </>
  );
};

export default function ContactCard(props) {
  const { instance, accounts } = useMsal();
  const [imageIcon, setImg] =useState<any>();
  const [Name, setName] = useState<any>();

  const handleChatClick = (event, param) => {
    window.location.href = "MSTeams:/l/chat/0/0?users=" + param;
    event.preventDefault();
  };

  const handleEmailClick = (event, param) => {
    window.location.href = "mailto:" + param;
    event.preventDefault();
  };

  //get photo
  useEffect(() => {
    getPhotoByEmail()
  },[props.contact])

  const getPhotoByEmail = () => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };

    instance
    .acquireTokenSilent(accessTokenRequest)
    .then(async (accessTokenResponse) => {
      let accessToken = accessTokenResponse.accessToken;

      // Call your API with token
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const headers = new Headers();
      const bearer = "Bearer " + accessToken;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers,
      };      

      await fetch(
       `https://graph.microsoft.com/v1.0/users/${props.contact.Email}/photo/$value`,       
        options
      ).then((response) => { 
        response.blob().then((data) => {           
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {            
            const base64data = reader.result;
            //@ts-ignore  
            //tt
            if (response.status == 404) {
              setImg(portrait);
            } else {
              setImg(base64data);
            }
            //tt
          };
        });
      });
    })
    .catch((error) => {  
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest);
      }
    })
  }

  //get Name
  useEffect(() => {
    getUserNameByEmail()
  },[props.contact])

  const getUserNameByEmail = () => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };
    
    instance
    .acquireTokenSilent(accessTokenRequest)
    .then(async (accessTokenResponse) => {
      let accessToken = accessTokenResponse.accessToken; 
    
      // Call your API with token
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const headers = new Headers();
      const bearer = "Bearer " + accessToken;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers,
      };   
      
      await fetch(             
       `https://graph.microsoft.com/v1.0/users/${props.contact.Email}?$select=displayName`,      
    
         options
      )
      .then((response) => response.json())
      .then ((result) => {                
            if (result.status == 404) {
              setName('Nobody');
            } else {              
              const fullname=  (result["displayName"].split(",")[1]
              +" "+result["displayName"].split(",")[0]).slice(1);              
              setName(fullname);
            }
      })          
    
      .catch((error) => {  
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest);
      }
      })
   
     })
    }

  return (
    <Card
      sx={{
        minWidth: 275,
        border: "2px solid #EEF2F7",
        borderRadius: "8px",
        boxShadow: "none",
        padding: "20px 20px 10px 20px",
        marginBottom: "30px",
        ...(props.radioState == "listView" && {
          height: "215px",
        }),
      }}
    >
      <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        
        <Grid container spacing={1}>
          <Grid item xs={3}>
          <Tooltip
              title={<Typography sx={{ fontSize: 14 }}>{Name}</Typography>}
            >
            <Item>
             { /* // @ts-ignore */}
              <img src={imageIcon} alt="Portrait" 
              width="50px" height="50px" 
              style={{ borderRadius: "50%" }}
              onError={()=>setImg(portrait)} />              
            </Item>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Grid item xs={12}>
                <Item>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 16 }}
                    color="#3A3A3A"
                  >
                    {Name}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  {" "}
                  <Typography
                    sx={{ fontStyle: "italic", fontSize: 14 }}
                    color="#6A7C90"
                  >
                    {props.contact.Role}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  {" "}
                  <Typography sx={{ fontSize: 12 }} color="#6A7C90">
                    {props.contact.Asset}
                  </Typography>
                </Item>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title={<Typography sx={{ fontSize: 14 }}>E-mail</Typography>}
            >
              <IconButton
                sx={{ paddingTop: 0 }}
                onClick={(event) =>
                  handleEmailClick(event, props.contact.Email)
                }
              >
                <MailOutlineIcon htmlColor="#152738" />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={<Typography sx={{ fontSize: 14 }}>MS Teams</Typography>}
            >
              <IconButton
                sx={{ paddingTop: 0 }}
                onClick={(event) => handleChatClick(event, props.contact.Email)}
              >
                <ChatBubbleOutlineIcon htmlColor="#152738" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Divider />
            </Item>
          </Grid>

          <Grid sx={{ display: "flex", flexWrap: "wrap" }} item xs={12}>
            <Skills contact={props.contact}> </Skills>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}




