import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import andrew from './HomePageContent/andrewvictory.png';
import bram from './HomePageContent/bramtegels.png';
import '../App.css';
import {withRouter, useHistory} from 'react-router-dom';
import React from 'react';
import matthe from './AboutDEASContent/matthe.png';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import connect from './StartPageImages/connect.png';
import automate from './StartPageImages/automate.png';
import insights from './StartPageImages/insights.png';
import arcadis_logo from "./images_mainpage/arcadis_logo.svg";
import Divider from "@mui/material/Divider";
import forward from './images_mainpage/forward.svg';
import skills from './images_mainpage/skills.png';
import comm_photo from './images_mainpage/communications.png';
import update from './RevitConnectorContent/update.png';
import u31 from './images_mainpage/home_tools.png';
import toolcatalog from './images_mainpage/toolcatalog.png';
import toolexplanation from './images_mainpage/toolexplanation.png';
import revit_workspaces from './images_mainpage/revit_workspaces.png';
import revit_newproject from './images_mainpage/revit_newproject.png';
import home_idea from './images_mainpage/home_ideas.png';
import sw_metrics from './images_mainpage/sw_metrics.png';
import tool_metric from './images_mainpage/tool_metrics.png';
import adoption_metrics from './images_mainpage/adoption_metrics.png';
import header from './AboutDEASContent/header_about_deas.png';
import revit20tabs from './HomePageContent/Revit20tabs.png';
import ScrollToAnchor from './ScrollToAnchor';

const MainHome =() => {
    
    const history = useHistory();    
    ScrollToAnchor();
    
    return (
     
    <Grid container spacing={'1px'} item xs={500} >
        <Grid container spacing={'1px'} item xs={12}  >    
        <Grid item xs={12}
            style={{backgroundImage: `url(${header})`, backgroundSize:'cover', height:"180px"  }} >
                <Grid paddingTop={'100px'} paddingLeft={'20px'}>
                    <p style={{color:'#e4610f', fontSize:'25px', fontWeight:'500' }} >Design & Engineering Automation System</p>
                </Grid>
            </Grid>

            
            <Grid item xs={8} >
            <Grid item xs={12} paddingLeft={'100px'} paddingRight={'50px'} paddingTop={'30px'}>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            <span style={{fontWeight:'600'}}>Goal<br></br></span>
            DEAS drives <span style={{color:'#e4610f', fontStyle:'italic'}}>efficiency</span> and <span style={{color:'#e4610f', fontStyle:'italic'}}>quality </span> 
            in Arcadis D&E work by offering a platform for improving communication, 
            globally scaling standardization and automation, and providing insight in adoption and usage of software and tooling.</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            <span style={{fontWeight:'600'}}>Audience<br></br></span>
            DEAS helps and connects modellers, tool developers, discipline leads, and business leads to achieve their goals to improve their work.</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            <span style={{fontWeight:'600'}}>Functionality<br></br></span>
            DEAS is represented in this website as well as in software applications, currently Revit, Civil3D and AutoCAD. The website provides information, 
            communication and insights, whereas DEAS in the software&apos;s provides in-App communication, standards and automation tools.</p>
            <p className="standard-text" style={{alignContent:'flex-end'}}>            
            The functionality is arranged around the themes:</p>

            <Grid sx={{gap: "20px"}}
                justifyContent={'center'} 
                display={'flex'} 
                flexDirection={'row'}
                paddingTop={'20px'} container >

              {/* Grid Connect */}              
              <Grid justifyContent={'center'} 
                display={'flex'} alignItems={'center'} 
                flexDirection={'column'}>
              <Card
              sx={{
                  width: "150px",
                  height: "150px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff",
                  cursor: "pointer"
              }} onClick={() => window.location.href="#connect"}
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >
              <Grid>
                  <Grid height={'33px'}></Grid>
                  <Grid alignContent={'center'} paddingLeft={'45px'} height={'64px'} >
                  <img src={connect} width={'64px'} height={'64px'} alt=""/>
                  </Grid>
                  <Grid  bgcolor={"#fff"} height={'53px'}
                          style={{fontSize:'18px', fontStyle:'italic', color: '#707070', textAlign:'center'}}>
                      <Grid><b>Connect</b></Grid>
                  </Grid>
              </Grid>
              </CardContent>
              </Card></Grid>

            {/*  Grid Automate */}            
            <Grid justifyContent={'center'} 
                display={'flex'} alignItems={'center'} 
                flexDirection={'column'} >
            <Card
            sx={{
            width: "150px",
            height: "150px",
            border: "2px solid #e4610f",            
            boxShadow: "none", 
            borderRadius:"50%",
            background: "#fff",
            cursor:"pointer"                     
            }} onClick={() => window.location.href="#automate"}
            >
            <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <Grid>
                <Grid height={'33px'}></Grid>
                <Grid alignContent={'center'} paddingLeft={'45px'} height={'64px'}>
                <img src={automate} width={'64px'} height={'64px'} alt=""/>
                </Grid>
                <Grid bgcolor={"#fff"} height={'53px'}
                        style={{fontSize:'18px', fontStyle:'italic', color: '#707070', textAlign:'center'}}>
                    <Grid><b>Automate</b></Grid>
                </Grid>
            </Grid>
            </CardContent>
            </Card></Grid>

            <Grid justifyContent={'center'} 
                display={'flex'} alignItems={'center'} 
                flexDirection={'column'}>
            <Card
            sx={{
            width: "150px",
            height: "150px",
            border: "2px solid #e4610f",            
            boxShadow: "none", 
            borderRadius:"50%",
            background: "#fff",
            cursor: "pointer"                     
            }} onClick={() => window.location.href="#insights"}
            >
            <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <Grid>
                <Grid height={'33px'}></Grid>
                <Grid alignContent={'center'} paddingLeft={'45px'} height={'64px'}>
                <img src={insights} width={'64px'} height={'64px'} alt=""/>
                </Grid>
                <Grid bgcolor={"#fff"} height={'53px'}
                        style={{fontSize:'18px', fontStyle:'italic', color: '#707070', textAlign:'center'}}>
                    <Grid><b>Insights</b></Grid>
                </Grid>
            </Grid>
            </CardContent>
            </Card></Grid>
            </Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
               
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start',  paddingTop:'30px'}}>Product Owner</p>                    
                <Grid container paddingBottom={'5px'}>
                <Grid container > 
                    <Grid paddingRight={'20px'}><img src={bram} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                    <Grid paddingTop={'5px'}>                            
                        <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}}>Tegels, Bram</Grid>
                        <Grid className = "standard-text" style={{ fontWeight:'500',  textAlign:'start'}}>Senior Consultant</Grid>
                    </Grid>
                </Grid>
            </Grid>

            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start'}}>Product Manager</p>                    
                <Grid container paddingBottom={'5px'}>
                <Grid container > 
                    <Grid paddingRight={'20px'}><img src={matthe} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                    <Grid paddingTop={'5px'}>                            
                        <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}}>van Baalen, Matthe</Grid>
                        <Grid className = "standard-text" style={{ fontWeight:'500',  textAlign:'start'}}>Global Leader Design & Engineering</Grid>

                    </Grid>
                </Grid>
            </Grid>
                
            <p style={{fontSize:'20px', fontWeight:'500',  textAlign:'start'}}>Implementation</p>
            <Grid container paddingBottom={'30px'}>
                <Grid container > 
                    <Grid paddingRight={'20px'}><img src={andrew} width="50px" height="50px" style={{ borderRadius: "50%" }} alt=""/></Grid>
                    <Grid paddingTop={'5px'}>
                        <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}}>Victory, Andrew</Grid>
                        <Grid className = "standard-text" style={{ fontWeight:'500',  textAlign:'start'}}>Global Digital Transformation Lead – D&E</Grid>
                    </Grid>
                </Grid>
            </Grid> 
            <Grid item xs={10} paddingTop={'20px'}>
            <Button variant="contained" fullWidth 
                className="mainhome-button-without-hover" 
                onClick={() => { window.open("https://appstore.arcadis.com/Catalog?view=1&name=All&search=deas", "_blank", "noopener"); }}>
                <Grid item xs={2} paddingTop={'5px'}>
                <img src={arcadis_logo} className='svg' alt=""/></Grid>
                <Grid item xs={8} display='flex' direction='row' alignContent='left'>Download DEAS from the Appstore</Grid>
            </Button> </Grid>                              
            </Grid> 
            <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'50px'} id="connect" >
                <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
            </Grid>  
        
        {/* Skills & Contacts Section */}       
        <Grid item xs={8} container direction={'row'} paddingBottom={'20px'} paddingRight={'50px'}>       
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>
            {/* Grid Connect */}            
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={connect} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>
           
            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} paddingBottom={'20px'}>
            <p className="standard-text" style={{alignContent:'flex-end'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Skills & Contacts<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Find colleagues with required automation skills.</span></p>
            <Grid><img src={skills} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                    
                    <div onClick={()=>history.push("/Connect")} style={{color:'#000', cursor:'pointer'}}>
                    Connect to colleagues
                    </div>  
                    </Grid> 
                </Grid>
            </Grid>

        {/* Communications Section */} 
        <Grid item xs={8} container direction={'row'} paddingBottom={'20px'}  paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>
            {/* Grid Communications */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={connect} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} paddingBottom={'20px'}>
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>In-App targeted messaging<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Focus your D&E message towards a specific usergroup, e.g. 
            Revit users in<br></br> Belgium or Civil3D users in GBA Mobility</span></p>
            <Grid><img src={comm_photo} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                    
                    <div onClick={()=>history.push("/Guides/DEASCore#application-messaging")} style={{color:'#000', cursor:'pointer'}}>
                    Guide to Application Messaging
                    </div> 
                    </Grid> 
                </Grid>
            </Grid>

            {/* Old Version Section */} 
            <Grid item xs={8} container direction={'row'} paddingBottom={'40px'}  paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>
            {/* Grid Old Version */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={connect} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'}>
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Old version warning<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Automated warning to move to a newer version of Software, to prevent
            <br></br> risks and increased costs.</span></p>
            <Grid><img src={update} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                    
                    <div onClick={()=>history.push("/Guides/DEASCore#old-version-warning")} style={{color:'#000', cursor:'pointer'}}>
                        Guide to Old version warning
                    </div> 
                    </Grid> 
                </Grid>
            </Grid>

            {/* Revit maximum tabs */} 
            <Grid item xs={8} container direction={'row'} paddingBottom={'20px'}  paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>
            {/* Grid Old Version */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={connect} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'}>
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Revit 20 tab max warning<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Message to inform Revit users to load not more than 20 custom tabs, to prevent crashes or non visibility of DEAS.</span></p>
            <Grid><img src={revit20tabs} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >            
            
            </Grid>

            {/* Global Sharing Section */}
            <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'50px'} id="automate" >
                <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
            </Grid> 
 
            <Grid item xs={8} container direction={'row'} paddingBottom={'20px'} paddingRight={'50px'}>            
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>            
            {/* Grid Automate */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={automate} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'}>
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Global sharing of automation tools<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Use automation tools in your own software created by Arcadis&apos; colleagues.</span></p>
            <Grid><img src={u31} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                   
                    <div onClick={()=>history.push("/Guides/RevitConnector#toolkits")} style={{color:'#000', cursor:'pointer'}}>
                    Revit Toolkits
                    </div> 
                    </Grid> 
            </Grid>

            <Grid container paddingTop={'5px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                    
                    <div onClick={()=>history.push("/Guides/Civil3DConnector#toolkits")} style={{color:'#000', cursor:'pointer'}}>
                    Civil 3D Toolkits
                    </div> 
                    </Grid> 
            </Grid>

            <Grid container paddingTop={'5px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                   
                    <div onClick={()=>history.push("/Guides/ToolDeveloper")} style={{color:'#000', cursor:'pointer'}}>
                    Tool developer guide
                    </div> 
                    </Grid> 
            </Grid>
            </Grid>
            

            {/* Tool catalog Section */} 
            <Grid item xs={8} container direction={'row'} paddingBottom={'20px'} paddingRight={'50px'} >
            
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'} paddingTop={'30px'}>
            {/* Grid Automate */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={automate} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} paddingTop={'30px'} paddingBottom={'20px'}>
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Tool catalogue<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Find the tool for your software and task.</span></p>
            <Grid><img src={toolcatalog} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'45px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                   
                    <div onClick={()=>history.push("/Automate")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Tool catalogue
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>

            {/* Tool explanation Section */} 
            <Grid item xs={8} container direction={'row'} paddingBottom={'20px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>
            {/* Grid Automate */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={automate} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} paddingBottom={'20px'}>
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Tool explanation<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Add information, images and videos to explain the functionality of your tools.</span></p>
            <Grid><img src={toolexplanation} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>
                    <div onClick={()=>history.push("/Automate")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Tool catalogue
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>

            {/* Create workspaces Section */} 
            <Grid item xs={8} container direction={'row'} paddingBottom={'40px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'}>
            {/* Grid Automate */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={automate} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} >
            <p className="standard-text" style={{alignContent:'flex-start'}}>
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Create workspaces<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>Make a collection of tools fitting your country, discipline or SOP needs.</span></p>
            <Grid><img src={revit_workspaces} width="100%"alt=""/></Grid>
            </Grid>
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>
                    <div onClick={()=>history.push("/Automate")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Tool catalogue
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>

            {/* Standard Distribution Section */} 
            <Grid item xs={8} container direction='row' paddingBottom={'40px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'} >
            {/* Grid Automate */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={automate} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} >
            <p  className="standard-text" style={{alignContent:'flex-start' }} >
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Standard distribution<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>
                Distribute your local or global standard files, like templates and shared parameters.</span></p>
            <Grid><img src={revit_newproject} width="100%"alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                    
                    <div onClick={()=>history.push("/Guides/RevitConnector#revit-standards-distribution")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Revit standards distribution guide
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>

            {/* Idea capture Section */} 
            <Grid item xs={8} container direction='row' paddingBottom={'20px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'} >
            {/* Grid Automate */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={automate} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} >
            <p  className="standard-text" style={{alignContent:'flex-start' }} >
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Idea capture<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>
                Share your idea for automation.</span></p>
            <Grid><img src={home_idea} width="100%"alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                    
                    <div onClick={()=>{window.open("https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/Lists/Automation%20Ideas/AllItems.aspx"), "_blank", "noopener" }}
                     style={{color:'#000', cursor:'pointer'}}>
                    Go to Automation Ideas
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>
            <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'50px'} id="insights" >
                <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
            </Grid>  

            {/* Software Metrics Section */} 
            <Grid item xs={8} container direction='row' paddingBottom={'20px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'} >
            {/* Grid Insights */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={insights} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} >
            <p  className="standard-text" style={{alignContent:'flex-start' }} >
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Software metrics<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>
                Understand how software is used.</span></p>
            <Grid><img src={sw_metrics} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>                   
                    <div onClick={()=>history.push("/Insights")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Insights
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>

            {/* Tool Metrics Section */} 
            <Grid item xs={8} container direction='row' paddingBottom={'20px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'} >
            {/* Grid Insights */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={insights} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} >
            <p  className="standard-text" style={{alignContent:'flex-start' }} >
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Tool metrics<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>
                Understand how automation tools are used.</span></p>
            <Grid><img src={tool_metric} width="100%" alt=""/></Grid>
            </Grid>            
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>
                    <div onClick={()=>history.push("/Insights")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Insights
                    </div>
                    </Grid> 
            </Grid>           
            </Grid>

            {/* Adoption Metrics Section */} 
            <Grid item xs={8} container direction='row' paddingBottom={'20px'} paddingRight={'50px'}>
            <Grid item xs={3} paddingLeft={'100px'} paddingRight={'10px'} >
            {/* Grid Insights */}
              <Card
              sx={{
                  width: "75px",
                  height: "75px",
                  border: "2px solid #e4610f",            
                  boxShadow: "none", 
                  borderRadius:"50%",
                  background: "#fff"                                                      
              }} 
              >
              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} >                             
                  <Grid container justifyContent={'center'} paddingTop={'10px'} >
                  <img src={insights} width={'50px'} height={'50px'} alt=""/>
                  </Grid>
              </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9} container paddingLeft={'40px'} paddingRight={'10px'} >
            <p  className="standard-text" style={{alignContent:'flex-start' }} >
            <span style={{fontWeight:'600' , fontSize:'22px'}}>Adoption metrics<br></br></span>
            <span style={{fontWeight:'600' , fontSize:'14px'}}>
                Understand adoption of automation of your environment.</span></p>
            <Grid><img src={adoption_metrics} width="100%" alt=""/></Grid>
            </Grid>                       
            </Grid>

           <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
           <Grid item xs={0.25} />
            
            <Grid item xs={3.5}  textAlign={'left'} >
            
            <Grid container paddingTop={'15px'}> 
                <Grid paddingRight={'5px'}><img src={forward} width="50px" height="50px" alt=""/></Grid>                                          
                    <Grid className = "standard-text" style={{ fontWeight:'600',  textAlign:'start'}} paddingTop={'15px'}>
                    <div onClick={()=>history.push("/Insights")} style={{color:'#000', cursor:'pointer'}}>
                    Go to Insights
                    </div>
                    </Grid> 
            </Grid>
            </Grid>
       </Grid>

       <Grid container justifyContent={'center'} paddingTop={'20px'} paddingBottom={'50px'}>
            <Divider color="#d0d0d0" sx={{ height: 1, width: '100%' }}/>
        </Grid> 

        {/* Grid Other Ideas */}
       <Grid item xs={8} container direction='row' paddingLeft={'40px'} paddingRight={'10px'} >

        <p style={{fontWeight:'500' , fontSize:'22px', textAlign:'center', width:'100%', paddingBottom:'50px'}} >
            Other Ideas?<br></br><br></br>
            Please contact the Product Owner
        </p>
        </Grid>
        <Grid item xs={0.25} borderRight={'1px solid #d0d0d0'}/>
        <Grid item xs={0.25} />     
        <Grid item xs={3.5}/>
        <Grid item xs={12} paddingBottom={'20px'}/>

        {/* Grid canvas */}
        <Grid bgcolor={'#e4610f'} item xs={12}>
        <canvas id="myCanvas"  height="30px" style={{border:'1px solid #e4610f'}}></canvas>
        </Grid> 
        
    </Grid>
    
    )
}
export default withRouter(MainHome);