/* eslint-disable react/prop-types */
import * as React from "react";
import { InteractionRequiredAuthError,  InteractionStatus,} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import PersonIcon from "./assets/user.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./App.css";
import {useHistory} from 'react-router-dom';


function ProfilePopUP() {  
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState<any>(null);
  const [Image, setImage] = useState<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const signOut = (instance) => {
    history.push("/");
    instance.logoutRedirect().catch((e) => {
      console.error(e);
      console.clear();
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;

          // Call your API with token
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          const headers = new Headers();
          const bearer = "Bearer " + accessToken;
          headers.append("Authorization", bearer);
          const options = {
            method: "GET",
            headers: headers,
          };

          await fetch(            
            "https://graph.microsoft.com/v1.0/me/photo/$value",
            options
          ).then((response) => {
            response.blob().then((data) => {
              const reader = new FileReader();

              reader.readAsDataURL(data);

              reader.onload = () => {
                const base64data = reader.result;

                setApiData(data);

                if (response.status == 404) {
                  setImage(PersonIcon);                  
                } else {
                  setImage(base64data);
                }
              };
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [instance, accounts, inProgress, apiData]);

  const history=useHistory();

  if (Image && accounts[0]) {
    return (
      <>
        <button
          onClick={handleClick}          
          className="profile-pop-up-menu" 
        >
          {accounts[0].name }&nbsp;&nbsp;&nbsp;
          
          <img
            style={{ borderRadius: "50%"}}
            width="30px"
            height="30px"
            id="avatar-image"
            src={Image}
          ></img>
        </button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}          
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          
>             <div style={{width:'170px'}}              
              className='profile-pop-up-item'
              onClick={() => {signOut(instance); }
              }
            >
              <ExitToAppIcon/>&nbsp;&nbsp;Sign Out              
            </div>
         
        </Popover>
      </>
    );
  } else {
    return <div></div>;
  }
}

export default ProfilePopUP;
